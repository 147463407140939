/**
 * ============================================================================================
 * Validation rules based on the Platforms
 * ============================================================================================
 * Facebook only
 * ---------------------------------------------------
 * 1. Video
 * 1a. There can only be one video asset per post.
 * 1b. The video asset cannot be combined with image asset.
 * 1c. Supported Format: MP4
 * 1d. File must be 100MB or smaller in size. (Actual support is 10GB and 4 hours)
 *
 * 2. Image
 * 2a. If no video, a post can contain upto 10 images.
 * 2b. Supported Formats: JPEG, BMP, PNG, GIF, TIFF
 * 2c. Files must be 4MB or smaller in size.
 * 2d. A PNG file should ideally be under 1MB or the image may appear pixelated.
 *
 * 3. Body
 * 3a. Character length for body is 63206 characters
 * ============================================================================================
 * Instagram only
 * ---------------------------------------------------
 * 1. Video
 * 1a. Supported Format: MP4
 * 1b. Files must be 100MB or smaller in size.
 *
 * 2. Image
 * 2a. Supported Format: JPEG
 * 2b. Files must be 8MB or smaller in size.
 *
 * 3. Asset (Video + Image)
 * 3a. A post can contain upto 10 assets, it may be a mix of MP4 and JPEGs.
 *
 * 4. Body
 * 4a. Character length for body is 2200 characters
 * 4b. Text only not allowed
 * ============================================================================================
 * Facebook and Instagram both || No platform selected
 * ---------------------------------------------------
 * 1. Video
 * 1a. Supported Format: MP4 (Enforced by Instagram)
 * 1b. There can only be one video asset per post. (Enforced by Facebook)
 * 1c. The video asset cannot be combined with image asset. (Enforced by Facebook)
 * 1d. Files must be 100MB or smaller in size. (Enforced by Insagram)
 *
 * 2. Image
 * 2a. If no video, a post can contain upto 10 images. (Enforced by Insagram)
 * 2b. Supported Format: JPEG  (Enforced by Insagram)
 * 2c. Files must be 4MB or smaller in size. (Enforced by Facebook)
 *
 * 3. Body
 * 3a. Character length for body is 2200 characters (Enforced by Instagram)
 * 3b. Text-only post is not allowed (Enforced by Instagram)
 * ============================================================================================
 */

import {
  FB_BODY_CHAR_LIMIT,
  FB_BODY_CHAR_TEXT,
  IG_BODY_CHAR_LIMIT,
  IG_BODY_CHAR_TEXT,
  MediaTypes,
  SupportedPlatforms,
} from "constants/editor";
import { CreatePost, PLATFORMS, PostPublishingStatusType } from "types";
import {
  ComponentType,
  MessageType,
  ValidationResult,
  validateImageForFb,
  validateImageForInsta,
  validateImageForInstaFb,
  validateVideoForFb,
  validateVideoForInsta,
  validateVideoForInstaFb,
} from "./assetValidators";

/**
 *
 * @param assets
 * @param platform
 * @param body
 * @returns Three types of results
 * 1. { valid: true, messageType: warn}
 * 2. { valid: false, messageType: warn}
 * 3. { valid: true, messageType: success}
 * 4. { valid: false, messageType: error}
 */
export const validatePostContent = (createPostData: CreatePost): ValidationResult[] => {
  try {
    const videos = createPostData.assets.filter((asset) => asset.type === MediaTypes.MP4);
    const images = createPostData.assets.filter(
      (asset) => asset.type === MediaTypes.JPEG || asset.type === MediaTypes.PNG,
    );
    let results: ValidationResult[] = [];

    if (createPostData.platforms.length <= 0) {
      results.push({
        valid: false,
        type: ComponentType.ACCOUNT,
        messageType: MessageType.WARN,
        message:
          "Wenn keine Plattformen ausgewählt sind, gelten sowohl die Regeln für Facebook als auch für Instagram für deinen Beitrag.",
      });
    }

    if (
      createPostData.platforms.length > 0 &&
      !createPostData.platforms.find((item) => item.includes(SupportedPlatforms.INSTAGRAM))
    ) {
      // Facebook only
      const otherAssets = createPostData.assets.filter(
        (asset) => asset.type !== MediaTypes.JPEG && asset.type !== MediaTypes.PNG && asset.type !== MediaTypes.MP4,
      );
      if (otherAssets.length > 0) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message: "Facebook-Beiträge können nur JPEG/PNG/MP4-Dateien enthalten.",
        });
      }

      if (createPostData.assets.length <= 0 && createPostData.body?.length <= 0) {
        results.push({
          valid: false,
          type: ComponentType.ACTION,
          messageType: MessageType.ERROR,
          message: "Ein Facebook Beitrag muss entweder ein Bild/Video oder einen Text enthalten.",
        });
      }

      if (
        createPostData.publishingStatus === PostPublishingStatusType.SCHEDULING &&
        !createPostData.schedule?.facebookScheduledAt
      ) {
        results.push({
          valid: false,
          type: ComponentType.ACTION,
          messageType: MessageType.ERROR,
          message: "Bitte wählen Sie ein Datum und eine Uhrzeit für Facebook",
        });
      }

      if (videos.length > 1) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message: "Für Facebook-Uploads ist nur ein Video-Upload pro Beitrag erlaubt.",
        });
      } else if (videos.length === 1 && createPostData.assets.length > 1) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message:
            "Du kannst nur eine einzelne Videodatei oder mehrere Bilds für Facebook-Uploads hochladen. Das Mischen verschiedener Medientypen ist nicht möglich.",
        });
      } else if (videos.length === 1) {
        results.push(validateVideoForFb(videos[0]));
      } else {
        // No video attached, no video specific action
        // Check for images
        // Since no video exists, following is true: assets === images
        if (createPostData.assets.length > 10) {
          results.push({
            valid: false,
            type: ComponentType.ASSET,
            messageType: MessageType.ERROR,
            message: "Du kannst nicht mehr als 10 Bilder in einem Facebook-Beitrag hochladen.",
          });
        } else {
          results = results.concat(images.map((image) => validateImageForFb(image)));
        }
      }

      if (createPostData.body?.length > 0 && createPostData.body?.length <= FB_BODY_CHAR_LIMIT) {
        results.push({
          valid: true,
          type: ComponentType.BODY,
          messageType: MessageType.SUCCESS,
          message: `Dein Beitrag erfüllt die Anforderungen für einen Facebook-Beitrag..`,
        });
      } else if (createPostData.body?.length > FB_BODY_CHAR_LIMIT) {
        results.push({
          valid: false,
          type: ComponentType.BODY,
          messageType: MessageType.ERROR,
          message: `Die Textlänge darf in Facebook nicht mehr als ${FB_BODY_CHAR_TEXT} Zeichen betragen.`,
        });
      }
    } else if (
      createPostData.platforms.length > 0 &&
      !createPostData.platforms.find((item) => item.includes(SupportedPlatforms.FACEBOOK))
    ) {
      // Instagram only
      const validAssets = createPostData.assets.filter(
        (asset) => asset.type === MediaTypes.JPEG || asset.type === MediaTypes.PNG || asset.type === MediaTypes.MP4,
      );
      const otherAssets = createPostData.assets.filter(
        (asset) => asset.type !== MediaTypes.JPEG && asset.type !== MediaTypes.MP4 && asset.type !== MediaTypes.PNG,
      );
      if (otherAssets.length > 0) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message: "Instagram-Beiträge können nur JPEG/PNG/MP4-Dateien enthalten.",
        });
      }

      if (
        createPostData.publishingStatus === PostPublishingStatusType.SCHEDULING &&
        !createPostData.schedule?.instagramScheduledAt
      ) {
        results.push({
          valid: false,
          type: ComponentType.ACTION,
          messageType: MessageType.ERROR,
          message: "Bitte wählen Sie ein Datum und eine Uhrzeit für Instagram.",
        });
      }

      if (validAssets.length <= 0) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.WARN,
          message: "Ein Instagram-Beitrag muss mindestens ein Bild oder ein Video enthalten.",
        });
      } else if (validAssets.length > 10) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message: "Du kannst nicht mehr als 10 Bilder in einem Instagram-Beitrag hochladen.",
        });
      } else {
        results = results
          .concat(videos.map((video) => validateVideoForInsta(video)))
          .concat(images.map((image) => validateImageForInsta(image)));
      }

      if (createPostData.body?.length > 0 && createPostData.body?.length <= IG_BODY_CHAR_LIMIT) {
        results.push({
          valid: true,
          type: ComponentType.BODY,
          messageType: MessageType.SUCCESS,
          message: `Dein Beitrag erfüllt die Anforderungen für einen Instagram-Beitrag..`,
        });
      } else if (createPostData.body?.length > IG_BODY_CHAR_LIMIT) {
        results.push({
          valid: false,
          type: ComponentType.BODY,
          messageType: MessageType.ERROR,
          message: `Die Textlänge darf in Instagram nicht mehr als ${IG_BODY_CHAR_TEXT} Zeichen betragen.`,
        });
      }
    } else {
      // Facebook and Instagram both || No platforms selected
      const validAssets = createPostData.assets.filter(
        (asset) => asset.type === MediaTypes.JPEG || asset.type === MediaTypes.PNG || asset.type === MediaTypes.MP4,
      );
      const otherAssets = createPostData.assets.filter(
        (asset) => asset.type !== MediaTypes.JPEG && asset.type !== MediaTypes.MP4 && asset.type !== MediaTypes.PNG,
      );
      if (otherAssets.length > 0) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.ERROR,
          message: "Facebook und Instagram-Beiträge können nur JPEG/MP4-Dateien enthalten.",
        });
      }

      if (
        createPostData.publishingStatus === PostPublishingStatusType.SCHEDULING &&
        !createPostData.schedule?.facebookScheduledAt &&
        !createPostData.schedule?.instagramScheduledAt
      ) {
        results.push({
          valid: false,
          type: ComponentType.ACTION,
          messageType: MessageType.WARN,
          message: "Bitte wählen Sie ein Datum und eine Uhrzeit für Instagram + Facebook.",
        });
      }

      if (
        (validAssets.length <= 0 && createPostData.platforms.length > 0) ||
        (validAssets.length === 0 && createPostData.platforms.length === 0)
      ) {
        results.push({
          valid: false,
          type: ComponentType.ASSET,
          messageType: MessageType.WARN,
          message: "Ein Facebook oder Instagram-Beitrag muss mindestens ein Bild oder ein Video enthalten.",
        });
      } else {
        if (videos.length > 1 || (videos.length === 1 && createPostData.assets.length > 1)) {
          results.push({
            valid: false,
            type: ComponentType.ASSET,
            messageType: MessageType.ERROR,
            message: "Du kannst nicht mehr als eine Videodatei in einem Facebook und Instagram-Beitrag hochladen.",
          });
        } else if (videos.length === 1) {
          results.push(validateVideoForInstaFb(videos[0]));
        } else {
          // No video attached, no video specific action
          // Check for images
          // Since no video exists, following is true: assets === images
          if (createPostData.assets.length > 10) {
            results.push({
              valid: false,
              type: ComponentType.ASSET,
              messageType: MessageType.ERROR,
              message: "Du kannst nicht mehr als 10 Bilder in einem Facebook und Instagram-Beitrag hochladen.",
            });
          } else {
            results = results.concat(images.map((image) => validateImageForInstaFb(image)));
          }
        }
      }

      if (createPostData.body?.length > 0 && createPostData.body?.length <= IG_BODY_CHAR_LIMIT) {
        results.push({
          valid: true,
          type: ComponentType.BODY,
          messageType: MessageType.SUCCESS,
          message: `Dein Beitrag erfüllt die Anforderungen für einen Facebook und Instagram-Beitrag.`,
        });
      } else if (createPostData.body?.length > IG_BODY_CHAR_LIMIT) {
        results.push({
          valid: false,
          type: ComponentType.BODY,
          messageType: MessageType.ERROR,
          message: `Die Textlänge darf in einem Facebook und Instagram-Beitrag nicht mehr als ${IG_BODY_CHAR_LIMIT} Zeichen betragen.`,
        });
      }
    }

    return results;
  } catch (e) {
    console.log(e);
  }

  return [];
};

export const isAssetsProcessed = (localPost: CreatePost, platform: PLATFORMS) => {
  if (
    platform === PLATFORMS.INSTAGRAM &&
    !!localPost.assets?.filter(
      (file) => file.type === MediaTypes.JPEG && file.originFileObj && file.response !== "cropped",
    )?.length
  )
    return false;
  return true;
};
