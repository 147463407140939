import { useQuery, UseQueryOptions } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";

export type TGetAccountUserInvitesParams = {
  accountId: string;
};

type ListAccountUserInvites = Awaited<ReturnType<typeof AccountApi.getInvitedUsers>>;

const useAccountUserInvites = (
  filters: TGetAccountUserInvitesParams,
  options?: UseQueryOptions<ListAccountUserInvites>,
) => {
  return useQuery({
    queryKey: AccountQueryKeys.getUserInvites(filters),
    queryFn: async () => {
      return AccountApi.getInvitedUsers(filters.accountId);
    },
    ...options,
    //Cache for 1 minute
    initialData: options?.initialData ?? [],
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId,
  });
};

export default useAccountUserInvites;
