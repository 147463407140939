import { Card, DatePicker, Divider, Input, Spin } from "antd";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { convertUTCDateToLocal } from "utils/date";
import usePaymentInvoices from "hooks/Payment/usePaymentInvoices";
import { AccountPaymentInvoice } from "types";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import Chart from "react-apexcharts";
import { DollarOutlined, SearchOutlined } from "@ant-design/icons";
import { ApexOptions } from "apexcharts";
import { clampDecimal } from "utils/clampDecimal";
import { mergeClasses } from "utils/mergeClasses";
import CustomAvatar from "components/CustomAvatar";
import formatNumber from "utils/formatNumber";
import CustomTable from "components/CustomTable";
import PageHeading from "components/PageHeading";
import { ReactComponent as DownArrowIcon } from "../../assets/DownArrow.svg";
import classes from "./index.module.css";

function* iterateMonths(startDate: Date | string | Dayjs | number, endDate: Date | string | Dayjs | number) {
  let currMonth = dayjs(startDate).startOf("month");
  const lastMonth = dayjs(endDate).endOf("month");

  while (currMonth.isBefore(lastMonth)) {
    yield currMonth;
    currMonth = currMonth.add(1, "month");
  }
}

type RangePickerValue = Exclude<React.ComponentPropsWithoutRef<typeof DatePicker.RangePicker>["value"], undefined>;

const ChartSection = ({
  title,
  stat,
  children,
  dateRange,
  onDateRangeChange,
  className,
}: React.PropsWithChildren & {
  title: string;
  stat: {
    text: string;
    value: number | string;
    icon: React.ReactNode;
  };
  dateRange?: RangePickerValue;
  onDateRangeChange?: (dateRange: RangePickerValue) => void;
  className?: string;
}) => {
  const formattedDate = useMemo(() => {
    if (!dateRange) return "Unknown Range";
    const [start, end] = dateRange;
    if (!start || !end) return "Unknown Range";
    return `${start?.format("D MMM YYYY")} - ${end?.format("D MMM YYYY")}`;
  }, [dateRange]);

  return (
    <Card className={mergeClasses(classes.chartSection, className)}>
      <div className={classes.chartSectionHeader}>
        <div className={classes.chartSectionHeaderWrapper}>
          <strong>{title}</strong>
          <DatePicker.RangePicker
            className={classes.chartSectionDateSelect}
            format={() => formattedDate}
            suffixIcon={<DownArrowIcon />}
            value={dateRange}
            onChange={onDateRangeChange}
            separator={null}
            size="small"
          />
        </div>
        <Divider className={classes.chartSectionDivider} orientationMargin={0} />
      </div>
      <div className={classes.chartSectionBody}>
        <div className={classes.chartSectionStat}>
          <div className={classes.chartSectionStatText}>
            {stat.icon}
            <span>{stat.text}</span>
          </div>
          <strong>{stat.value}</strong>
        </div>
        <div>{children}</div>
      </div>
    </Card>
  );
};

const initDateRange = () => {
  const now = dayjs().startOf("day");

  return [now.startOf("year"), now.endOf("month")] as [Dayjs, Dayjs];
};

type TopUserAccountData = {
  id: string;
  accountName: string;
  accountPlan: string;
  totalRevenue: number;
  accountProfilePic: string | null;
};

type TopUserData = {
  month: number;
  year: number;
  monthName: string;
  data: TopUserAccountData[];
};

const TopUserListItem = ({ item }: { item: TopUserAccountData }) => {
  const { t } = useTranslation(["common", "pages"]);

  const formattedTotalRevenue = useMemo(() => {
    return formatNumber(item.totalRevenue);
  }, [item.totalRevenue]);

  return (
    <div className={classes.topUsersListItem} key={item.id}>
      <CustomAvatar src={item?.accountProfilePic} alt={`${item.accountName ?? "User"}'s profile picture`} size="large">
        {item?.accountName?.[0] ?? "U"}
      </CustomAvatar>
      <div className={classes.topUsernameWrapper}>
        <span className={classes.topUsername}>{item.accountName ?? t("common:placeholders.unknownUser")}</span>
        <span className={classes.topUsernamePlan}>{item.accountPlan}</span>
      </div>
      <span className={classes.topUsernamePrice}>{formattedTotalRevenue}€</span>
    </div>
  );
};

const RevenueStatsSection = () => {
  const { t } = useTranslation(["common", "pages"]);
  const [dateRange, setDateRange] = useState(initDateRange);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dateRange[0].toDate(),
      endDate: dateRange[1].toDate(),
    };
  }, [dateRange]);

  const { data: _data, isLoading } = usePaymentInvoices({
    startDate,
    endDate,
  });

  // Using select to transform data causes infinite re-rerenders
  //using useMemo to perform transformations instead
  const data = useMemo(
    () =>
      _data?.map((item) => ({
        ...item,
        invoices: item.invoices.map((invoice) => ({
          ...invoice,
          amount_paid: clampDecimal(invoice.amount_paid / 100),
        })),
      })) ?? [],
    [_data],
  );

  const [topUsersData, setTopUsersData] = useState<TopUserData | undefined>();

  const { revenueChartOptions, totalRevenue } = useMemo(() => {
    const categories: string[] = [];
    let totalRevenue = 0;

    //Map of shape {[year]: {[month]: revenue}}
    //2D array of shape where of shape: arr[year][month]
    const revenuePoints = [] as {
      year: number;
      month: number;
      monthName: string;
      revenue: number;
      data: TopUserAccountData[];
    }[];

    const chartData = [] as typeof revenuePoints;

    if (data) {
      for (const item of data) {
        for (const invoice of item.invoices) {
          for (const currMonth of iterateMonths(invoice.period_start * 1000, invoice.period_end * 1000)) {
            const month = currMonth.month();
            const year = currMonth.year();

            let currPoint = revenuePoints.find((item) => item.year === year && item.month === month);

            if (!currPoint) {
              currPoint = {
                year,
                month,
                monthName: currMonth.format("MMMM YYYY"),
                revenue: 0,
                data: [],
              };
              revenuePoints.push(currPoint);
            }

            currPoint.revenue += invoice.amount_paid;

            let dataPoint = currPoint.data.find((x) => x.id === item.id);

            if (!dataPoint) {
              dataPoint = {
                id: item.id,
                accountName: item.name,
                accountPlan: item.type === "CUSTOMER" ? "Basic Abo" : "Pro Abo",
                totalRevenue: 0,
                accountProfilePic: item.account_profile_pic,
              };
              currPoint.data.push(dataPoint);
            }
            dataPoint.totalRevenue += invoice.amount_paid;
          }
        }
      }

      for (const currMonth of iterateMonths(startDate, endDate)) {
        const month = currMonth.month();
        const year = currMonth.year();

        const revenuePoint = revenuePoints.find((item) => item.month === month && item.year === year) ?? {
          month,
          year,
          revenue: 0,
          data: [],
          monthName: currMonth.format("MMMM YYYY"),
        };

        chartData.push(revenuePoint);
        categories.push(currMonth.format("MMMM YYYY"));
        totalRevenue += revenuePoint.revenue;
      }
    }

    for (const item of chartData) {
      item.data.sort((a, b) => b.totalRevenue - a.totalRevenue);
    }

    setTopUsersData(chartData[chartData.length - 1]);

    return {
      totalRevenue,
      revenueChartOptions: {
        chart: {
          type: "line",
          id: "line-chart",
          toolbar: {
            show: false,
          },
          events: {
            markerClick: (
              _e,
              _chart,
              options: {
                dataPointIndex: number;
              },
            ) => {
              if (!data) return;
              const revPoint = chartData[options.dataPointIndex];
              if (!revPoint) return;

              setTopUsersData({
                month: revPoint.month,
                year: revPoint.year,
                monthName: revPoint.monthName,
                data: revPoint.data,
              });
            },
          },
        },
        stroke: {
          width: 3,
        },

        colors: ["#2EBD59"],
        series: [
          {
            name: `${t("pages:adminRevenue.revenueLabel")} (€)`,
            data: chartData.map((item) => item.revenue),
          },
        ],
        xaxis: {
          categories,
        },
        legend: {
          show: true,
          showForNullSeries: true,
          showForSingleSeries: true,
          showForZeroSeries: true,
          fontSize: "14px",
          fontWeight: 400,
        },
      } satisfies ApexOptions,
    };
  }, [data, endDate, startDate, t]);

  const formattedTotalRevenue = useMemo(() => {
    return formatNumber(totalRevenue);
  }, [totalRevenue]);

  return (
    <section className={classes.sectionOne}>
      <ChartSection
        title={t("pages:adminRevenue.revenueReportLabel")}
        stat={{
          text: t("pages:adminDashboard.totalRevenueLabel"),
          value: `${formattedTotalRevenue}€`,
          icon: <DollarOutlined />,
        }}
        dateRange={dateRange}
        onDateRangeChange={setDateRange as Dispatch<SetStateAction<RangePickerValue>>}
        className={classes.revenueChartContainer}
      >
        <Spin
          spinning={isLoading}
          style={{
            width: "100%",
            height: 240,
          }}
        >
          <Chart
            series={revenueChartOptions.series}
            options={revenueChartOptions}
            type="line"
            width="100%"
            height="100%"
          />
        </Spin>
      </ChartSection>
      <div className={classes.topUsers}>
        <Spin spinning={isLoading}>
          <strong className={classes.topUsersTitle}>
            {t("pages:adminRevenue.topUsersLabel", {
              date: topUsersData?.monthName ?? t("pages:adminRevenue.thisMonthPlaceholder"),
            })}
          </strong>

          <Card className={classes.topUsersList}>
            <div className={classes.topUsersListWrapper}>
              {topUsersData?.data.length ? (
                topUsersData.data.map((item) => {
                  return <TopUserListItem item={item} key={item.id} />;
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                    <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                  </span>
                </div>
              )}
            </div>
          </Card>
        </Spin>
      </div>
    </section>
  );
};

const AdminRevenue = () => {
  const { t } = useTranslation(["common", "pages"]);
  const { data: invoices, isLoading } = usePaymentInvoices(undefined, {
    select: (data) =>
      data.map((item) => ({
        ...item,
        invoices: item.invoices.map((invoice) => ({ ...invoice, amount_paid: invoice.amount_paid / 100 })),
      })),
  });

  const [searchText, setSearchText] = useState("");

  const [sorting, setSorting] = useState<{ key: string; order: "ascend" | "descend" } | undefined>();

  const tableData = useMemo(() => {
    if (!invoices) return [];

    const tableData = [];
    for (const item of invoices) {
      if (!item.name.toLowerCase().includes(searchText.toLowerCase())) continue;

      let lastPayment: AccountPaymentInvoice["invoices"][0] | undefined = item.invoices[0];
      let revenue = 0;

      for (const invoice of item.invoices) {
        revenue += invoice.amount_paid;
        if (lastPayment && lastPayment.created < invoice.created) {
          lastPayment = invoice;
        }
      }

      const lastPaymentDate = lastPayment?.created ? new Date(lastPayment.created * 1000) : undefined;

      tableData.push({
        ...item,
        key: item.id,
        revenue,
        formattedRevenue: formatNumber(revenue),
        lastPayment,
        paymentDate: lastPaymentDate
          ? convertUTCDateToLocal(lastPaymentDate.toISOString()).format("DD.MM.YYYY HH.mm[h]")
          : undefined,
        paymentMonth: lastPaymentDate ? convertUTCDateToLocal(lastPaymentDate.toISOString()).format("MMMM") : undefined,
        plan: item.type === "CUSTOMER" ? "Basic" : "Pro",
      });
    }

    if (sorting?.key === "revenue") {
      tableData.sort((a, b) => (sorting.order === "ascend" ? a.revenue - b.revenue : b.revenue - a.revenue));
    } else if (sorting?.key === "name") {
      tableData.sort((a, b) =>
        sorting.order === "ascend" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
    } else if (sorting?.key === "plan") {
      tableData.sort((a, b) =>
        sorting.order === "ascend" ? a.plan.localeCompare(b.plan) : b.plan.localeCompare(a.plan),
      );
    } else if (sorting?.key === "paymentMonth") {
      tableData.sort((a, b) => {
        if (!a.lastPayment) return 1;
        if (!b.lastPayment) return -1;
        return sorting.order === "ascend"
          ? a.lastPayment.created < b.lastPayment.created
            ? -1
            : 1
          : a.lastPayment.created > b.lastPayment.created
            ? -1
            : 1;
      });
    } else if (sorting?.key === "paymentDate") {
      tableData.sort((a, b) => {
        if (!a.lastPayment) return 1;
        if (!b.lastPayment) return -1;
        return sorting.order === "ascend"
          ? a.lastPayment.created < b.lastPayment.created
            ? -1
            : 1
          : a.lastPayment.created > b.lastPayment.created
            ? -1
            : 1;
      });
    } else {
      tableData.sort((a, b) => {
        if (!a.lastPayment) return 1;
        if (!b.lastPayment) return -1;
        return a.lastPayment.created > b.lastPayment.created ? -1 : 1;
      });
    }

    return tableData;
  }, [invoices, searchText, sorting]);

  return (
    <div className={`container ${classes.page}`}>
      <PageHeading>
        <PageHeading.Title>{t("pages:adminRevenue.title")}</PageHeading.Title>
        <PageHeading.Subtitle>{t("pages:adminRevenue.subtitle")}</PageHeading.Subtitle>
      </PageHeading>
      <RevenueStatsSection />

      <section className={classes.sectionTwo}>
        <CustomTable
          headerPrefix={<CustomTable.Title level={3}>{t("pages:adminRevenue.revenueTableLabel")}</CustomTable.Title>}
          headerSuffix={
            <Input
              placeholder={t("common:placeholders.search")}
              prefix={<SearchOutlined style={{ fontSize: "16px" }} />}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          }
          defaultProps={{
            loading: isLoading,
            onChange: (_, _filters, sorter) => {
              sorter = sorter as { column?: { key: string }; order?: "ascend" | "descend" };

              if (!sorter.column?.key || !sorter.order) {
                setSorting(undefined);
                return;
              }
              setSorting({ key: sorter.column.key as string, order: sorter.order });
            },
            columns: [
              {
                title: t("pages:adminRevenue.accountLabel"),
                render: (_, record) => <p className={classes.accountColumn}>{record.name}</p>,
                sorter: () => 0,
                width: 332,
                key: "name",
              },
              {
                title: t("pages:adminRevenue.revenueLabel"),
                render: (_, record) => <p className={classes.revenueColumn}>{record.formattedRevenue}€</p>,
                sorter: () => 0,
                key: "revenue",
                width: 332,
              },
              {
                title: t("pages:adminRevenue.planLabel"),
                render: (_, record) => <p className={classes.planColumn}>{record.plan}</p>,
                sorter: () => 0,
                key: "plan",
                width: 175,
              },
              {
                title: t("pages:adminRevenue.monthLabel"),
                render: (_, record) => <p className={classes.monthColumn}>{record.paymentMonth}</p>,
                sorter: () => 0,
                key: "paymentMonth",
                width: 250,
              },
              {
                title: t("pages:adminRevenue.paymentDateLabel"),
                render: (_, record) => <p className={classes.paymentDateColumn}>{record.paymentDate}</p>,
                sorter: () => 0,
                key: "paymentDate",
                width: 440,
              },
            ],
            dataSource: tableData,
          }}
        />
      </section>
    </div>
  );
};

export default AdminRevenue;
