import { Card, Spin } from "antd";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import dayjs from "dayjs";
import useSelectedAccount from "hooks/useSelectedAccount";
import useInsights, { groupInsightsByMonth } from "hooks/Insight/useInsights";
import {
  FacebookMetrics,
  FacebookPageDemographicMetrics,
  InsightDatePreset,
  InstagramInsightTimeframe,
  InstagramMetrics,
  InstagramPageDemographicMetrics,
  InstaInsightBreakdown,
  MetricPeriod,
} from "types";
import useAccountDemographic from "hooks/Insight/useInsightDemographic";
import { countries } from "types/countries";
import { clampDecimal } from "utils/clampDecimal";
import formatNumber from "utils/formatNumber";
import { ReactComponent as FacebookGray } from "../../../assets/FacebookGray.svg";
import { ReactComponent as InstagramGray } from "../../../assets/InstagramGray.svg";
import StatChartCard, { createStatChartCardOptions } from "../components/StatChartCard";
import classes from "./index.module.css";

const ProgressBar = ({ max = 100, min = 0, value }: { value: number; max?: number; min?: number }) => {
  const percent = useMemo(() => Math.round((value / max) * 100), [value, max]);

  return (
    <div
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={value}
      aria-valuetext={`${percent}%`}
      role="progressbar"
      data-state="loading"
      data-value={value}
      data-max={max}
      className={classes.progressCardProgressbar}
    >
      <div
        data-value={value}
        data-max={max}
        className={classes.progressCardProgressbarFilled}
        style={{ transform: `translateX(-${100 - percent}%)` }}
      />
    </div>
  );
};

const ProgresCard = ({
  icon,
  items,
  title,
  loading,
}: {
  items: DemographicData[];
  title: string;
  icon: React.ReactNode;
  loading: boolean;
}) => {
  return (
    <Card className={classes.progressCard}>
      <Spin spinning={loading}>
        <div className={classes.progressCardWrapper}>
          <div className={classes.progressCardHeader}>
            {icon}
            <strong>{title}</strong>
          </div>
          <div className={classes.progressCardContent}>
            {items.length ? (
              <ul className={classes.progressCardList}>
                {items.map((item) => {
                  return (
                    <li className={classes.progressCardListItem} key={item.country}>
                      <div className={classes.progressCardListItemContent}>
                        <div className={classes.progressCardListItemText}>
                          <strong className={classes.progressCardListItemTitle}>
                            {item.country}
                            <p className={classes.progressCardListItemStat}>({item.value})</p>
                          </strong>
                          <p className={classes.progressCardListItemPercent}>{item.formattedPercent}%</p>
                        </div>

                        <ProgressBar value={item.percent} />
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", flex: "1 1 0" }}>
                <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                  <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                </span>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </Card>
  );
};

type DemographicData = {
  country: string;
  code: string;
  value: number;
  percent: number;
  formattedPercent: string;
};

const calcDemographic = (
  values:
    | {
        value: Record<string, number>;
      }[]
    | undefined,
) => {
  if (!values) return { data: [], total: 0 };
  const data: DemographicData[] = [];
  let total = 0;

  for (const { value: item } of values) {
    for (const [key, value] of Object.entries(item)) {
      const country = countries.find((country) => country.code === key);
      if (!country) continue;
      let dataPoint = data.find((x) => x.code === country.code);
      if (!dataPoint) {
        dataPoint = {
          country: country.name,
          code: country.code,
          value: 0,
          percent: 0,
          formattedPercent: "0",
        };
        data.push(dataPoint);
      }
      dataPoint.value += value;
      total += value;
    }
  }

  for (const item of data) {
    item.percent = clampDecimal((item.value / total) * 100);
    item.formattedPercent = formatNumber(item.percent, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }

  data.sort((a, b) => b.percent - a.percent);

  return { data, total };
};

const StatsTargetGroup = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const { data, isLoading: isLoadingInsights } = useInsights({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [FacebookMetrics.page_follows],
    instagram_metrics: [InstagramMetrics.follower_count],
    date_preset: InsightDatePreset.this_year,
    period: MetricPeriod.day,
  });
  const stats = useMemo(() => (data ? groupInsightsByMonth(data) : undefined), [data]);

  const fbFollowersData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      const monthDate = dayjs().set("month", i);

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.facebook.page_follows?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "blue",
        categories,
        data: monthlyData,
        name: t("pages:statsOverview.fbFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.facebook.page_follows?.total ?? 0,
    };
  }, [stats, t]);

  const igFollowersData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      const monthDate = dayjs().set("month", i);

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.instagram.follower_count?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "red",
        categories,
        data: monthlyData,
        name: t("pages:statsOverview.igFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.instagram.follower_count?.total ?? 0,
    };
  }, [stats, t]);

  const { data: demographicData, isLoading: isLoadingDemographics } = useAccountDemographic({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    date_preset: InsightDatePreset.this_month,
    period: MetricPeriod.month,
    instaInsightBreakdown: InstaInsightBreakdown.country,
    instaInsightTimeframe: InstagramInsightTimeframe.this_month,
    facebook_metrics: [FacebookPageDemographicMetrics.page_fans_country],
    instagram_metrics: [InstagramPageDemographicMetrics.reached_audience_demographics],
  });

  const { fbDemographicData, instaDemographicData } = useMemo(() => {
    if (!demographicData)
      return { fbDemographicData: [], fbDemographicTotal: 0, instaDemographicData: [], instaDemographicTotal: 0 };

    const { data: fbDemographicData, total: fbDemographicTotal } = calcDemographic(
      demographicData.facebook.page_fans_country,
    );
    const { data: instaDemographicData, total: instaDemographicTotal } = calcDemographic(
      demographicData.instagram.reached_audience_demographics,
    );
    return { fbDemographicData, fbDemographicTotal, instaDemographicData, instaDemographicTotal };
  }, [demographicData]);

  return (
    <div className={classes.container}>
      <section className={classes.chartCardSection}>
        <StatChartCard
          title={t("pages:statsOverview.fbFollowersLabel")}
          icon={<FacebookGray />}
          stat={fbFollowersData.total}
          loading={isLoadingInsights}
        >
          <Chart
            series={fbFollowersData.chartData.series}
            options={fbFollowersData.chartData}
            type="line"
            width="100%"
            height="100%"
          />
        </StatChartCard>
        <StatChartCard
          title={t("pages:statsOverview.igFollowersLabel")}
          icon={<InstagramGray />}
          stat={igFollowersData.total}
          loading={isLoadingInsights}
        >
          <Chart
            series={igFollowersData.chartData.series}
            options={igFollowersData.chartData}
            type="line"
            width="100%"
            height="100%"
          />
        </StatChartCard>
      </section>
      <section className={classes.progressCardSection}>
        <ProgresCard
          items={fbDemographicData}
          title={t("pages:statsTargetGroup.topCountriesLabel")}
          icon={<FacebookGray />}
          loading={isLoadingDemographics}
        />
        <ProgresCard
          items={instaDemographicData}
          title={t("pages:statsTargetGroup.topCountriesLabel")}
          icon={<InstagramGray />}
          loading={isLoadingDemographics}
        />
      </section>
    </div>
  );
};

export default StatsTargetGroup;
