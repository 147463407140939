import { DatePicker, Flex, Row, TimePicker, Typography } from "antd";
import dayjs from "dayjs";
import { CreatePost, PLATFORMS, Post, PostPublishingStatusType, ScheduleTiming } from "types";
import locale from "antd/es/date-picker/locale/de_DE";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import { useMemo } from "react";
import { getDisabledTime } from "utils/date";
import InstagramIcon from "../../../../assets/icons/socials/instagramColored.svg";
import FacebookIcon from "../../../../assets/icons/socials/facebookColored.svg";
import { ReactComponent as CalenderIcon } from "../../../../assets/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/clock.svg";
import classes from "./Schedule.module.css";

dayjs.locale("de_DE");
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

export type ScheduleComponentProps = {
  localPost: CreatePost;
  post: Post | undefined;
  setLocalPost: React.Dispatch<React.SetStateAction<CreatePost>>;
  isLoading: boolean;
  callCreatePost: ({ type, publishMobile }: { type: PostPublishingStatusType; publishMobile: boolean }) => void;
  isPostContentValid: boolean;
  schedule: boolean;
};

export type DateTimePickerProps = {
  date: string;
  time: string;
  platformType: PLATFORMS;
  dateTimeSelected: ({ date, time }: { date: string; time: string }) => void;
  disabled: boolean;
};

const DateTimePicker = (props: DateTimePickerProps) => {
  const { date, time, platformType, dateTimeSelected, disabled } = props;

  let icon;
  if (platformType === PLATFORMS.FACEBOOK) {
    icon = FacebookIcon;
  } else {
    icon = InstagramIcon;
  }

  const maxDate = useMemo(() => dayjs.tz(dayjs()).add(364, "day"), []);

  const timeInMins = useMemo(() => {
    const [hrs, mins] = time.split(":");

    const totalMins = +hrs * 60 + +mins;
    return totalMins;
  }, [time]);

  const now = useMemo(() => dayjs.tz(dayjs(`${date} ${time}`, "DD.MM.YYYY HH:mm")), [date, time]);

  const today = useMemo(() => dayjs.tz(), []);

  const minMins = useMemo(() => {
    return (today.hour() + 1) * 60 + today.minute();
  }, [today]);

  return (
    <Row className={classes.picker} justify="center" align="top">
      <div className={classes.dateTimeContainer}>
        <img src={icon} alt="facebook" className={classes.icon} />
        <DatePicker
          disabled={disabled}
          className={classes.datePicker}
          allowClear={false}
          showToday={timeInMins >= minMins}
          placeholder="Datum wählen"
          picker="date"
          locale={locale}
          format="DD.MM.YYYY"
          suffixIcon={<CalenderIcon className={classes.inputPrefixIcon} />}
          disabledDate={(current) => {
            if (!current || current.isBefore(today, "day") || current.isAfter(maxDate)) return true;

            return current.isSame(today, "day") && timeInMins < minMins;
          }}
          value={dayjs(date, "DD.MM.YYYY")}
          onChange={(_, dateStr) => {
            const d = dayjs(dateStr as string, "DD.MM.YYYY").format("YYYY-MM-DD");
            dateTimeSelected({
              date: d,
              time,
            });
          }}
          data-testid={`post-schedule-${platformType.toLowerCase()}-select-date`}
        />
        <TimePicker
          disabled={disabled}
          className={classes.timePicker}
          allowClear={false}
          format="HH:mm"
          placeholder="Zeit wählen"
          locale={locale}
          suffixIcon={<ClockIcon className={classes.inputPrefixIcon} />}
          disabledTime={(_date) => getDisabledTime(now.toString())}
          value={dayjs(`${time}`, "HH:mm")}
          onChange={(_, timeStr) => {
            const d = dayjs(date, "DD.MM.YYYY").format("YYYY-MM-DD");
            dateTimeSelected({
              date: d,
              time: timeStr as string,
            });
          }}
          data-testid={`post-schedule-${platformType.toLowerCase()}-select-time`}
        />
      </div>
    </Row>
  );
};

const ScheduleComponent = (props: ScheduleComponentProps) => {
  const { localPost, setLocalPost, schedule } = props;

  const updateSchedule = (date: string, time: string, platform: PLATFORMS) => {
    let schedule: ScheduleTiming = localPost.schedule ?? {};
    if (platform === PLATFORMS.FACEBOOK) {
      schedule = {
        ...schedule,
        facebookScheduledAt: dayjs.tz(`${date}T${time}:00`).utc().format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    if (platform === PLATFORMS.INSTAGRAM) {
      schedule = {
        ...schedule,
        instagramScheduledAt: dayjs.tz(`${date}T${time}:00`).utc().format("YYYY-MM-DDTHH:mm:ss"),
      };
    }

    setLocalPost((prev: CreatePost) => ({
      ...prev,
      schedule,
    }));
  };

  const onDateTimeUpdated = (date: string, time: string, platform: PLATFORMS) => {
    updateSchedule(date, time, platform);
  };

  return (
    <Flex vertical>
      <Typography.Text className={classes.timePickerLabel}>Fällig am</Typography.Text>
      {(localPost.platforms.length === 0 || localPost.platforms.find((p) => p.includes(PLATFORMS.FACEBOOK))) && (
        <DateTimePicker
          date={(dayjs.utc(localPost.schedule?.facebookScheduledAt).tz() || dayjs).format("DD.MM.YYYY")}
          time={(dayjs.utc(localPost.schedule?.facebookScheduledAt).tz() || dayjs).format("HH:mm")}
          platformType={PLATFORMS.FACEBOOK}
          dateTimeSelected={({ date, time }) => onDateTimeUpdated(date, time, PLATFORMS.FACEBOOK)}
          disabled={!schedule}
        />
      )}
      {(localPost.platforms.length === 0 || localPost.platforms.find((p) => p.includes(PLATFORMS.INSTAGRAM))) && (
        <DateTimePicker
          date={(dayjs.utc(localPost.schedule?.instagramScheduledAt).tz() || dayjs).format("DD.MM.YYYY")}
          time={(dayjs.utc(localPost.schedule?.instagramScheduledAt).tz() || dayjs).format("HH:mm")}
          platformType={PLATFORMS.INSTAGRAM}
          dateTimeSelected={({ date, time }) => onDateTimeUpdated(date, time, PLATFORMS.INSTAGRAM)}
          disabled={!schedule}
        />
      )}
    </Flex>
  );
};

export default ScheduleComponent;
