import ROLES from "constants/user-management";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Button, Row, Spin, Dropdown, Menu, message, Popconfirm, Flex } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { AVAILABLE_ROLES, AccountInvitedUser, AccountUser, User } from "types";
import { convertUTCDateToLocal } from "utils/date";
import { AccountApi } from "services/api";
import PermissionsModal from "components/Permissions";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useUserAccessPolicy from "hooks/UserAccessPolicy/useUserAccessPolicy";
import NotAllowed from "components/NotAllowed";
import { makeSubject } from "utils/access";
import useAccountUsers from "hooks/useAccountUsers";
import useMe from "hooks/useMe";
import useAccountUserInvites from "hooks/useAccountUserInvites";
import CustomTable from "components/CustomTable";
import CustomBadge from "components/CustomBadge";
import { useTranslation } from "react-i18next";
import CustomAvatar from "components/CustomAvatar";
import classes from "./Admin.module.css";
import InviteUser from "./components/InviteUser";

function getRoleKey(value: string) {
  const role = Object.entries(ROLES).find(([_, val]) => val === value);
  return role ? role[0] : "";
}

// const localeTable = {
//   emptyText: (
//     <span>
//       <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
//       <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
//     </span>
//   ),
// };

// const profileColumns: TableProps<{ name: string; description: string }>["columns"] = [
//   {
//     title: "Name",
//     dataIndex: "name",
//     key: "name",
//     width: 220,
//   },
//   {
//     title: "Beschreibung",
//     dataIndex: "description",
//     key: "description",
//   },
// ];

// const profiles = [
//   {
//     name: "Business Admin",
//     description:
//       "Als Benutzer mit diesem Berechtigungsprofil erhältst du vollen Zugriff auf die Administration im Einstellungsbereich.",
//   },
//   {
//     name: "Admin",
//     description:
//       "Als Benutzer mit diesem Berechtigungsprofil erhältst du vollen Zugriff auf die Benutzerverwaltung, Profilverwaltung und Zugriff ….",
//   },
// ];

const isAccountInvitedUser = (record: AccountInvitedUser | AccountUser): record is AccountInvitedUser => {
  return "inviter" in record;
};

export default function AdminSettings() {
  const { t } = useTranslation(["common", "pages"]);

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const { selectedAccountId } = useSelectedAccount();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { ability, isLoading: isLoadingPermissions } = useContext(UserAccessPolicyContext);
  const { data: me, isLoading: isLoadingLoggedInUser } = useMe(selectedAccountId);

  const canViewUsers = ability.can("read", makeSubject("User", { accountId: selectedAccountId }));
  const canViewUserInvites = ability.can("read", makeSubject("UserInvite", { accountId: selectedAccountId }));
  const canRemoveUsers = ability.can("delete", makeSubject("AccountUser", { accountId: selectedAccountId }));
  const canDeleteUserInvite = ability.can("delete", makeSubject("UserInvite", { accountId: selectedAccountId }));
  const canReInviteUser = ability.can("update", makeSubject("UserInvite", { accountId: selectedAccountId }));
  const canUpdateUser = ability.can("update", makeSubject("User", { accountId: selectedAccountId }));
  const canViewUserAccessPolicy = ability.can(
    "read",
    makeSubject("UserAccessPolicy", { accountId: selectedAccountId }),
  );

  const { data, isLoading, refetch } = useAccountUsers(
    { accountId: selectedAccountId },
    {
      enabled: !isLoadingPermissions,
    },
  );

  const {
    data: invitedUsers,
    isLoading: isLoadingInvitedUsers,
    refetch: refetchInvitedUsers,
  } = useAccountUserInvites(
    { accountId: selectedAccountId },
    {
      enabled: !isLoadingPermissions && canViewUserInvites,
    },
  );

  useEffect(() => {
    if (canViewUsers) {
      refetch();
      refetchInvitedUsers();
    }
  }, [canViewUsers, refetch, refetchInvitedUsers]);

  const users = useMemo(
    () =>
      [...(invitedUsers ?? []), ...(data ?? [])].sort(
        (a, b) => dayjs.utc(b.createdAt).unix() - dayjs.utc(a.createdAt).unix(),
      ),
    [data, invitedUsers],
  );

  const { data: selectedUserAccessPolicy, isLoading: isLoadingAccessPolicies } = useUserAccessPolicy({
    accountId: selectedAccountId,
    userId: selectedUser?.id,
  });

  const { isLoading: isDeleteUserLoading, mutate: deleteUser } = useMutation(
    ({ accountId, userId }: { accountId: string; userId: string }) => AccountApi.deleteUser({ accountId, userId }),
    {
      onSuccess: () => {
        message.success("Benutzer wurde gelöscht");
        refetch();
      },
      onError: () => {
        message.error("Benutzer konnte nicht gelöscht werden");
      },
    },
  );

  const { isLoading: isDeleteInvitedUserLoading, mutate: deleteInvitedUser } = useMutation(
    ({ accountId, email }: { accountId: string; email: string }) => AccountApi.deleteInvitedUser({ accountId, email }),
    {
      onSuccess: () => {
        message.success("Einladung wurde gelöscht");
        refetch();
      },
      onError: () => {
        message.error("Einladung konnte nicht gelöscht werden");
      },
    },
  );

  const { isLoading: isMakeAdminLoading, mutate: makeAdmin } = useMutation(
    ({
      accountId,
      userId,
      role,
      currentRole,
    }: {
      accountId: string;
      userId: string;
      role: string;
      currentRole: string;
    }) => {
      if (currentRole === ROLES.EIGENTÜMER) {
        throw new Error("Die Rolle kann für diesen Nutzer nicht aktualisiert werden");
      } else {
        return AccountApi.updateUser({ accountId, userId, role });
      }
    },
    {
      onSuccess: () => {
        message.success("Benutzerrolle erfolgreich aktualisiert");
        refetch();
      },
      onError: () => {
        message.error("Die Rolle kann für diesen Nutzer nicht aktualisiert werden");
      },
    },
  );

  const showUserDropdown = (record: AccountInvitedUser | AccountUser) => {
    const isInvitedUser = isAccountInvitedUser(record);

    return (
      me &&
      ((((isInvitedUser && canDeleteUserInvite) || (!isInvitedUser && canRemoveUsers)) &&
        record.role !== ROLES.EIGENTÜMER &&
        (isInvitedUser || record.user.id !== me.id)) ||
        (!isInvitedUser && canViewUserAccessPolicy) ||
        (isInvitedUser && canReInviteUser) ||
        (canUpdateUser && record.role !== ROLES.EIGENTÜMER && (isInvitedUser || record.user.id !== me.id)))
    );
  };

  return (
    <div className={classes.AdminContainer}>
      {isLoading || isLoadingPermissions || isLoadingLoggedInUser || isLoadingInvitedUsers ? (
        <Spin />
      ) : canViewUsers && me ? (
        <div className={classes.AdminContent}>
          <Flex className={classes.AdminTools} align="middle" gap="4px">
            <Button
              type="primary"
              className={classes.AddUserButton}
              size="middle"
              onClick={() => setShowInviteUserModal(true)}
              data-testid="user-add-btn"
            >
              Benutzer hinzufügen
            </Button>
          </Flex>
          {selectedUser ? (
            <PermissionsModal
              selectedUser={selectedUser}
              accessPolicies={selectedUserAccessPolicy?.items ?? []}
              isLoadingAccessPolicies={isLoadingAccessPolicies}
              open={permissionsModalOpen}
              onCancel={() => {
                setSelectedUser(null);
                setPermissionsModalOpen(false);
              }}
              viewOnly={selectedUser.id === me.id}
            />
          ) : null}
          <CustomTable
            headerPrefix={<CustomTable.Title level={3}>Benutzer</CustomTable.Title>}
            defaultProps={{
              dataSource: users,
              columns: [
                {
                  title: "Benutzer",
                  dataIndex: "user",
                  key: "user",
                  sorter: (a, b) => {
                    if (isAccountInvitedUser(a) && isAccountInvitedUser(b)) {
                      return a.email > b.email ? 1 : -1;
                    }
                    if (!isAccountInvitedUser(a) && !isAccountInvitedUser(b)) {
                      return a.user.name > b.user.name ? 1 : -1;
                    }
                    return 0;
                  },
                  render(_, record) {
                    const isInvitedUser = isAccountInvitedUser(record);

                    return (
                      <Flex align="center" gap={12} className={classes.userColumn}>
                        <CustomAvatar
                          size="large"
                          src={record.user?.user_profile_pic}
                          alt={`${isInvitedUser ? record.email : record.user?.name ?? "User"}'s profile picture`}
                        >
                          {isInvitedUser ? record.email[0] : record.user.name[0]}
                        </CustomAvatar>
                        <div className={classes.userColumnContent}>
                          <span
                            className={classes.userColumnText}
                            title={isInvitedUser ? record.email : record.user?.name}
                          >
                            {isInvitedUser ? record.email : record.user?.name}
                          </span>
                          {showUserDropdown(record) && (
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={(e) => {
                                    if (e.key.includes("update-role") && !isInvitedUser) {
                                      makeAdmin({
                                        accountId: selectedAccountId,
                                        userId: record.userId,
                                        role: e.key.split("__")[1],
                                        currentRole: record.role,
                                      });
                                    } else if (e.key.includes("manage-permissions") && !isInvitedUser) {
                                      setSelectedUser(record.user);
                                      setPermissionsModalOpen(true);
                                    }
                                  }}
                                >
                                  {((isInvitedUser && canDeleteUserInvite) || (!isInvitedUser && canRemoveUsers)) &&
                                    record.role !== ROLES.EIGENTÜMER &&
                                    (isInvitedUser || record.user.id !== me.id) && (
                                      <Popconfirm
                                        title="Bist du dir sicher?"
                                        cancelText="Abbrechen"
                                        okText="Löschen"
                                        className={classes.DeleteConf}
                                        onConfirm={() => {
                                          if (isInvitedUser) {
                                            deleteInvitedUser({
                                              accountId: selectedAccountId,
                                              email: record.email,
                                            });
                                          } else {
                                            deleteUser({
                                              accountId: selectedAccountId,
                                              userId: record.userId,
                                            });
                                          }
                                        }}
                                      >
                                        <Menu.Item key="delete" data-testid="user-delete-option">
                                          Löschen
                                        </Menu.Item>
                                      </Popconfirm>
                                    )}

                                  {canViewUserAccessPolicy && !isInvitedUser ? (
                                    <Menu.Item key="manage-permissions" data-testid="user-manage-permissions-option">
                                      Berechtigungen verwalten
                                    </Menu.Item>
                                  ) : null}
                                  {canUpdateUser &&
                                    record.role !== ROLES.EIGENTÜMER &&
                                    (isInvitedUser || record.user.id !== me.id) && (
                                      <Menu.SubMenu title="Rolle aktualisieren" data-testid="user-change-role-option">
                                        {Object.entries(AVAILABLE_ROLES).map(([roleKey, roleValue]) => (
                                          <Menu.Item
                                            key={`update-role__${roleValue}`}
                                            data-testid={`user-change-role-${roleValue.toLowerCase()}-option`}
                                          >
                                            {roleKey}
                                          </Menu.Item>
                                        ))}
                                      </Menu.SubMenu>
                                    )}
                                </Menu>
                              }
                              placement="bottomRight"
                            >
                              <span>
                                <EllipsisOutlined data-testid="user-three-dots" />
                              </span>
                            </Dropdown>
                          )}
                        </div>
                      </Flex>
                    );
                  },
                },
                {
                  title: "E-Mail",
                  dataIndex: "email",
                  key: "email",
                  width: 220,
                  render(_, record) {
                    return <span>{isAccountInvitedUser(record) ? record?.email : record.user?.email}</span>;
                  },
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  filters: [
                    {
                      text: "AKTIV",
                      value: "AKTIV",
                    },
                    {
                      text: "AUSSTEHEND",
                      value: "PENDING",
                    },
                  ],
                  onFilter(value, record) {
                    if (value === "PENDING") {
                      return isAccountInvitedUser(record);
                    }
                    return !isAccountInvitedUser(record);
                  },
                  render(value) {
                    return (
                      <CustomBadge color={value === "PENDING" ? "gray" : "green"} size="sm" dot>
                        {value === "PENDING" ? t("common:labels.pending") : t("common:labels.active")}
                      </CustomBadge>
                    );
                  },
                  width: 220,
                },
                {
                  title: "Teams",
                  dataIndex: "teams",
                  key: "teams",
                  filters: Object.entries(ROLES).map(([roleKey, roleValue]) => ({
                    text: roleKey,
                    value: roleValue,
                  })),
                  onFilter(value, record) {
                    return record.role === value;
                  },
                  render(_, record) {
                    return (
                      <Row align="middle">
                        <span>{getRoleKey(record.role)}</span>
                      </Row>
                    );
                  },
                  width: 220,
                },
                {
                  title: "Letzter Login",
                  dataIndex: "lastLogin",
                  key: "lastLogin",
                  sorter(a, b) {
                    if (!isAccountInvitedUser(a) && !isAccountInvitedUser(b)) {
                      return dayjs.utc(a.user.lastLogin).isAfter(b.user.lastLogin) ? 1 : -1;
                    }

                    return 0;
                  },
                  render(_, record) {
                    return (
                      <Flex align="middle">
                        <span className={classes.lastLoginColumn}>
                          {isAccountInvitedUser(record)
                            ? "-"
                            : record.user?.lastLogin
                              ? convertUTCDateToLocal(record.user?.lastLogin).format("DD.MM.YYYY HH.mm[h]")
                              : "-"}
                        </span>
                      </Flex>
                    );
                  },
                  width: 220,
                },
                {
                  title: "Erstellt",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  sorter(a, b) {
                    if (isAccountInvitedUser(a) && isAccountInvitedUser(b)) {
                      return dayjs.utc(a.createdAt).isAfter(b.createdAt) ? 1 : -1;
                    }
                    if (!isAccountInvitedUser(a) && !isAccountInvitedUser(b)) {
                      return dayjs.utc(a.user.createdAt).isAfter(b.user.createdAt) ? 1 : -1;
                    }

                    return 0;
                  },
                  render(_, record) {
                    return (
                      <Row align="middle">
                        <span className={classes.createdAtColumn}>
                          {isAccountInvitedUser(record)
                            ? convertUTCDateToLocal(record.createdAt).format("DD.MM.YYYY HH.mm[h]")
                            : convertUTCDateToLocal(record.user?.createdAt).format("DD.MM.YYYY HH.mm[h]")}
                        </span>
                      </Row>
                    );
                  },
                  width: 220,
                },
                {
                  title: "Letzte Änderung",
                  dataIndex: "updatedAt",
                  key: "updatedAt",
                  sorter(a, b) {
                    if (isAccountInvitedUser(a) && isAccountInvitedUser(b)) {
                      return dayjs.utc(a.updatedAt).isAfter(b.updatedAt) ? 1 : -1;
                    }
                    if (!isAccountInvitedUser(a) && !isAccountInvitedUser(b)) {
                      return dayjs.utc(a.user.updatedAt).isAfter(b.user.updatedAt) ? 1 : -1;
                    }

                    return 0;
                  },
                  render(_, record) {
                    return (
                      <Row align="middle">
                        <span className={classes.updatedAtColumn}>
                          {isAccountInvitedUser(record)
                            ? convertUTCDateToLocal(record.updatedAt).format("DD.MM.YYYY HH.mm[h]")
                            : convertUTCDateToLocal(record.user?.updatedAt).format("DD.MM.YYYY HH.mm[h]")}
                        </span>
                      </Row>
                    );
                  },
                  width: 220,
                },
              ],
              loading: isLoading || isDeleteUserLoading || isDeleteInvitedUserLoading || isMakeAdminLoading,
              rowKey: "id",
            }}
          />

          {/* <Table
            title={() => <h2 className={classes.subHeading}>Berechtigungsprofile</h2>}
            dataSource={profiles}
            columns={profileColumns}
            rowKey="name"
            locale={localeTable}
            pagination={{
              position: ["bottomCenter"],
            }}
          /> */}
        </div>
      ) : (
        <NotAllowed />
      )}
      <InviteUser
        visible={showInviteUserModal}
        setVisible={setShowInviteUserModal}
        accountId={selectedAccountId}
        next={refetch}
      />
    </div>
  );
}
