import { CustomBadgeColor } from "components/CustomBadge";
import { POST_EDITING_STATUS, PostPublishingStatusType } from "types";

/* eslint-disable */
export * from "./api";
export * from "./image";
export * from "./init-fb";
export * from "./jwt";

export const validatePassword = (password: string) => {
  // disallow non-english characters but allow everything else
  const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?äöüßÄÖÜẞ]*$/;
  return regex.test(password);
};

export const validateGermanVATId = (vatId: string) => {
  const regex = /^[a-zA-Z0-9]{1,25}$/;
  return regex.test(vatId);
};

export const validateTelephone = (telephone: string) => {
  const regex = /^(\+?\d{1,3})?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return regex.test(telephone);
};

export const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const validateWebsite = (website: string) => {
  const regex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
  return regex.test(website);
};

export const postStatusColorMapping: { [key: string]: string } = {
  [PostPublishingStatusType.SCHEDULED]: "#FEC84B", //Yellow
  [PostPublishingStatusType.PUBLISHED]: "#6CE9A6", //Green
  [PostPublishingStatusType.DRAFT]: "#7CD4FD", //Blue
  [PostPublishingStatusType.PUBLISHING]: "grey",
  [PostPublishingStatusType.SCHEDULING]: "grey",
  [PostPublishingStatusType.FAILED]: "#FF6347", //Red
};

export const postStatusBadgeColorMapping: { [key: string]: CustomBadgeColor } = {
  [PostPublishingStatusType.SCHEDULED]: "yellow",
  [PostPublishingStatusType.PUBLISHED]: "green",
  [PostPublishingStatusType.DRAFT]: "blue",
  [PostPublishingStatusType.PUBLISHING]: "gray",
  [PostPublishingStatusType.SCHEDULING]: "gray",
  [PostPublishingStatusType.FAILED]: "red",
};

export const postEditingStatusBadgeColorMapping: { [key: string]: CustomBadgeColor } = {
  [POST_EDITING_STATUS.APPROVED]: "green",
  [POST_EDITING_STATUS.IN_PROGRESS]: "yellow",
  [POST_EDITING_STATUS.IN_REVIEW]: "yellow",
  [POST_EDITING_STATUS.REJECTED]: "red",
};
