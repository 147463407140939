import { useMutation, UseMutationOptions } from "react-query";
import { AccountApi } from "services/api";
import { saveAs } from "file-saver";

export type DownloadInvoiceResult = Awaited<ReturnType<typeof AccountApi.generateInvoicePdf>>;
export type TDownloadInvoiceParams = {
  accountId: string;
  invoiceId: string;
  invoiceNumber?: string;
};

const useDownloadInvoice = (
  options?: Omit<UseMutationOptions<DownloadInvoiceResult, unknown, TDownloadInvoiceParams, unknown>, "mutationFn">,
) => {
  return useMutation({
    ...options,
    mutationFn: AccountApi.generateInvoicePdf,
    onSuccess: (data, vars, ctx) => {
      const blob = new Blob([data as BlobPart], { type: "application/pdf" });
      saveAs(blob, `Rechnung_${vars.invoiceNumber}.pdf`);

      if (options?.onSuccess) {
        options.onSuccess(data, vars, ctx);
      }
    },
  });
};

export default useDownloadInvoice;
