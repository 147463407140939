import { AdminContext } from "context/AdminContext";
import { useContext } from "react";

const useAdmin = () => {
  const ctx = useContext(AdminContext);
  if (!ctx) throw new Error("useAdmin must be used within a AdminContextProvider");
  return ctx;
};

export default useAdmin;
