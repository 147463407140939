import { Card, Col, Row, Spin, Typography } from "antd";
import {
  BorderOutlined,
  FileAddOutlined,
  HeartOutlined,
  HomeOutlined,
  IdcardOutlined,
  LeftOutlined,
  RightOutlined,
  MenuOutlined,
  SearchOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useMemo, useState } from "react";
import { PostPublishingStatusType, Post } from "types";
import useSelectedAccount from "hooks/useSelectedAccount";
import usePosts from "hooks/posts/usePosts";
import useCompany from "hooks/company/useCompany";
import classes from "./PreivewGrid.module.css";

export type PreviewGridProps = {
  open: boolean;
  pageTitle: string;
  previewGridStartDate: string;
  previewGridEndDate: string;
  onClose: () => void;
};

export const PreviewGridItem = ({ post }: { post: Post }) => {
  if (!post) return <></>;

  if (post.currentVersion.thumbnailUrl) {
    return <img src={post.currentVersion.thumbnailUrl} alt="Preview of post" />;
  }

  const primseAsset = post.currentVersion.content?.assets[0];

  if (!primseAsset) return <></>;

  return primseAsset.type === "VIDEO" ? <video src={primseAsset?.url} /> : <img src={primseAsset?.url} />;
};

export default function PreviewGrid(props: PreviewGridProps) {
  const { open, onClose } = props;
  const [accountPic, setAccountPic] = useState<string>();

  const { selectedCompanyId: companyId, selectedAccountId: accountId } = useSelectedAccount();

  const { data: company } = useCompany(companyId, { include: ["channels"] });

  const instaChannelName = company?.channels?.filter((channel) => channel.type === "INSTAGRAM")[0]?.name;

  const { isLoading: loading, data } = usePosts(
    {
      accountId,
      companyId,
    },

    {
      enabled: !!props.previewGridEndDate && !!props.previewGridStartDate,
      refetchOnWindowFocus: false,
    },
  );

  const instaPosts = useMemo(() => {
    return (
      data
        ?.filter(
          (post) =>
            post.channel.type === "INSTAGRAM" &&
            (post.publishingStatus === PostPublishingStatusType.PUBLISHED ||
              post.publishingStatus === PostPublishingStatusType.SCHEDULED),
        )
        .sort((a, b) => {
          const aDate =
            a.publishingStatus === PostPublishingStatusType.SCHEDULED
              ? new Date(a.currentVersion.scheduledAt as string)
              : new Date(a.currentVersion.postedAt as string);
          const bDate =
            b.publishingStatus === PostPublishingStatusType.SCHEDULED
              ? new Date(b.currentVersion.scheduledAt as string)
              : new Date(b.currentVersion.postedAt as string);
          return bDate.getTime() - aDate.getTime();
        }) ?? []
    );
  }, [data]);

  return (
    <Card
      style={{
        display: open ? "flex" : "none",
        position: "fixed",
        right: "0px",
        top: "0px",
        bottom: "0px",
        zIndex: "1000",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "32px",
      }}
    >
      <div className={classes.closeButton} onClick={onClose}>
        <RightOutlined style={{ color: "white" }} />
      </div>
      <Spin spinning={loading}>
        <div>
          <p style={{ fontSize: "24px", display: "flex", justifyContent: "center" }}>PREVIEW</p>
        </div>
        <div className={classes.deviceOutline}>
          <div className={classes.notificationDrawer}>
            <div className={classes.dynamicIsland} />
          </div>
          <div className={classes.top}>
            <LeftOutlined />
            <Typography.Text>{instaChannelName}</Typography.Text>
            <MenuOutlined />
          </div>
          <div style={{ height: "150px", padding: "8px" }}>
            <Row>
              <Col span={4}>
                <img src={accountPic} style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
              </Col>
              <Col span={20}>
                <Row style={{ width: "100%", marginTop: "4px" }}>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>23</p>
                  </Col>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>1.6K</p>
                  </Col>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "14px", fontWeight: "bold" }}>52</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "12px" }}>Beiträge</p>
                  </Col>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "12px" }}>Followers</p>
                  </Col>
                  <Col span={8} className={classes.accountData}>
                    <p style={{ fontSize: "12px" }}>Gefolgt</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <p style={{ fontSize: "16px", marginTop: "8px", fontWeight: "bold" }}>{instaChannelName}</p>
            </Row>
          </div>
          <Row className={classes.rowData}>
            <Col span={8} className={classes.colData}>
              <TableOutlined />
            </Col>
            <Col span={8} className={classes.colData}>
              <BorderOutlined />
            </Col>
            <Col span={8} className={classes.colData}>
              <IdcardOutlined />
            </Col>
          </Row>
          <div className={classes.img}>
            {[0, 3, 6].map((i) => {
              return (
                <Row key={i}>
                  {[0, 1, 2].map((j) => {
                    return (
                      <Col span={8} className={classes.imgItem} key={instaPosts[i + j]?.id || i + j + 6}>
                        <PreviewGridItem post={instaPosts[i + j]} />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
          <div className={classes.bottomNav}>
            <HomeOutlined />
            <SearchOutlined />
            <FileAddOutlined />
            <HeartOutlined />
            <UserOutlined />
          </div>
        </div>
      </Spin>
    </Card>
  );
}
