import { Row } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import CategoryForm from "./CategoryForm";
import CampaignForm from "./CampaignForm";

export default function CreateCampaign() {
  const params = useRouterContext().useParams<{
    id: string;
    campaignId: string;
    category: string;
    resource: string;
    tab: string;
  }>();

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <h1 className="heading" style={{ paddingBottom: 10 }}>
          Werbeanzeige erstellen
        </h1>
      </Row>
      {!params.category && !params.campaignId ? <CategoryForm /> : <CampaignForm />}
    </div>
  );
}
