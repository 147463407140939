import { useQuery, UseQueryOptions } from "react-query";
import { PostApi } from "services/api";
import { PostQueryKeys } from "services/query-keys";
import { POST_EDITING_STATUS, PostPublishingStatusType } from "types";

export type GetPostVersionParams = {
  accountId: string;
  startDate?: string;
  endDate?: string;
  companyId: string;
  isCurrentVersion?: boolean;
  requiresReview?: boolean;
  editingStatuses?: POST_EDITING_STATUS[];
  publishingStatuses?: PostPublishingStatusType[];
};

type GetPostsResult = Awaited<ReturnType<typeof PostApi.getPostVersions>>;

const usePostVersions = (filters: GetPostVersionParams, options?: UseQueryOptions<GetPostsResult>) => {
  return useQuery({
    queryKey: PostQueryKeys.listVersions(filters),
    queryFn: async () => {
      return PostApi.getPostVersions(filters);
    },
    ...options,
    //Cache for 1 minute
    staleTime: options?.staleTime ?? 60_000,
    initialData: options?.initialData ?? [],
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default usePostVersions;
