import { useQuery } from "react-query";
import { RetryValue } from "react-query/types/core/retryer";
import { UserAccessPolicyApi } from "services/api";
import { UserAccessPolicyKeys } from "services/query-keys";
import { UserAccessPolicyType } from "types";

export type TFindAllUserAccessPolicyParams = {
  accountId: string;
  userId?: string;
  companyId?: string;
  channelId?: string;
  policyType?: UserAccessPolicyType;
  orderBy?: "createdAt" | "updatedAt";
  orderDirection?: "asc" | "desc";
  skip?: string;
  take?: string;
};

const useUserAccessPolicy = (
  params: TFindAllUserAccessPolicyParams,
  options?: {
    onSuccess?: (data: Awaited<ReturnType<typeof UserAccessPolicyApi.findAll>>) => void | Promise<void>;
    onError?: (e: unknown) => void;
    retry?: RetryValue<unknown>;
  },
) => {
  return useQuery({
    queryKey: UserAccessPolicyKeys.list(params),
    queryFn: async () => {
      return await UserAccessPolicyApi.findAll(params);
    },
    onError: options?.onError,
    onSuccess: options?.onSuccess,
    enabled: !!params.accountId && !!params.userId,
    refetchOnWindowFocus: true,
    retry: options?.retry,
  });
};

export default useUserAccessPolicy;
