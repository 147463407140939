import { UploadFile } from "antd";
import { PLATFORMS, POST_EDITING_STATUS, ROLES, SYSTEM_STATUS, TODO_PRIORITY, TODO_STATUS } from "./enum";

export type TFindAllResponse<TData> = {
  items: TData[];
  total: number;
};

export type Channel = {
  accountId: string;
  createdAt: string;
  id: string;
  name: string;
  remoteId: string;
  type: "INSTAGRAM" | "FACEBOOK";
  accessStatus: "HEALTHY" | "UNHEALTHY";
  tokenExpiresAt: Date;
  updatedAt: string;
  usage: string | null;
  userId: string;
  companyIds: string[];
};

export type CheckoutSession = {
  //Partial type of Stripe Checkout Session, for full object refer stripe docs
  //or the postman sample request or backend type definition for the endpoint
  id: string;
  object: "checkout.session";
  amount_subtotal: number;
  amount_total: number;
  client_reference_id: string;
  success_url: string;
  total_details: {
    amount_discount: number;
    amount_shipping: number;
    amount_tax: number;
  };
  url: string;
};

export enum AccountType {
  CUSTOMER = "CUSTOMER",
  AGENCY = "AGENCY",
}

export type Account = {
  id: string;
  name: string;
  type: AccountType;
  channels: Channel[];
  accessExpiresAt: string;
  createdAt: string;
  updatedAt: string;
};

export type AccountSubscription = {
  id: string;
  status: string;
  plan: {
    id: string;
    interval: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type AccountInvoice = {
  id: string;
  effective_at: number;
  invoice_pdf: string;
  number: string;
  status: string;
  total: number;
};

export type PostFormData = {
  images: UploadFile[];
  text: string;
  platform: string[];
  companyId: string;
  accountId: string;
};

export type ZipPostData = {
  accountId: string;
  companyId: string;
  userId: string;
  platform: string[];
  csv: UploadFile;
  zip: UploadFile;
};

export type Assets = {
  url: string;
  type: string;
};

export type EventPost = {
  total: string;
  posts: Post[];
};

export enum UserAccessPolicyType {
  MANAGE_ALL = "MANAGE_ALL",
  MANAGE_ACCOUNT = "MANAGE_ACCOUNT",
  VIEW_ACCOUNT = "VIEW_ACCOUNT",
  MANAGE_USER = "MANAGE_USER",
  VIEW_USER = "VIEW_USER",
  MANAGE_TODO = "MANAGE_TODO",
  VIEW_TODO = "VIEW_TODO",
  MANAGE_COMPANY = "MANAGE_COMPANY",
  VIEW_COMPANY = "VIEW_COMPANY",
  MANAGE_CHANNEL = "MANAGE_CHANNEL",
  VIEW_CHANNEL = "VIEW_CHANNEL",
  MANAGE_POST = "MANAGE_POST",
  EDIT_POST = "EDIT_POST",
  VIEW_POST = "VIEW_POST",
  REVIEW_POST = "REVIEW_POST",
  MANAGE_PLATFORM = "MANAGE_PLATFORM",
}

export type CreateUserAccessPolicy = {
  userId: string;
  user?: User;
  policyType: UserAccessPolicyType;
  accountId: string;
  companyId: string | null;
  channelId: string | null;
};

export type UserAccessPolicy = {
  id: string;
  userId: string;
  user?: User;
  policyType: UserAccessPolicyType | null;

  accountId: string;
  account: { id: string; name: string };

  companyId: string | null;
  company: { id: string; name: string } | null;
  channel: { id: string; name: string } | null;

  channelId: string | null;
};

export type User = {
  _count?: {
    accountUsers?: number;
  };
  id: string;
  email: string;
  name: string;
  role: ROLES;
  lastLogin: string;
  createdAt: string;
  updatedAt: string;
  verified: boolean;
  user_profile_pic: string;
  abilityRules: string;
  accountUsers: AccountUser[];
  companyIds: string[];
  accessPolicies: UserAccessPolicy[];
};

export type AccountUser = {
  accountId: string;
  account?: Account;
  createdAt: string;
  id: string;
  role: string;
  updatedAt: string;
  user: User;
  userId: string;
};

export type AccountInvitedUser = {
  accountId: string;
  createdAt: string;
  email: string;
  expiresAt: string;
  id: string;
  invitedBy: string;
  inviter: User;
  role: string;
  status: string;
  updatedAt: string;
  user?: User;
};

export enum PostPublishingStatusType {
  DRAFT = "DRAFT",
  PUBLISHING = "PUBLISHING",
  PUBLISHED = "PUBLISHED",
  SCHEDULING = "SCHEDULING",
  SCHEDULED = "SCHEDULED",
  FAILED = "FAILED",
}

export type ScheduleTiming = {
  facebookScheduledAt?: string;
  instagramScheduledAt?: string;
};

export type CreatePost = {
  accountId: string;
  companyId: string;
  assets: UploadFile[];
  body: string;
  platforms: string[];
  publishingStatus: PostPublishingStatusType;
  schedule?: ScheduleTiming;
  publishMobile?: boolean;
  isEdit?: boolean;
  channel?: Channel;
  versionId?: string;
};

export type PostReviewComment = {
  id: string;
  postId: string;
  post?: Post;
  postVersionId: string;
  postVersion?: PostVersion;
  userId: string;
  user?: User;
  quotedCommentId?: string;
  quotedComment?: PostReviewComment;
  message: string;
  createdAt?: string;
  updatedAt?: string;
  deleted: boolean;
  deletedAt?: string;
};

export type PostVersion = {
  id: string;
  version: number;
  postId?: string;
  post?: Post;

  creatorId: string;
  creator?: Pick<User, "name" | "user_profile_pic" | "id">;

  reviewer?: Pick<User, "name" | "user_profile_pic" | "id">;
  reviewerId?: string;
  reviewedAt?: string;
  editingStatus: POST_EDITING_STATUS;
  content?: {
    body: string;
    assets: { url: string; type: string }[];
  };
  thumbnailUrl?: string;
  errMessage?: string;
  scheduledAt?: string;
  postedAt?: string;
  remoteId?: string;
  sysMeta: {
    schedulerId?: string;
    sqsId?: string;
    status: SYSTEM_STATUS;
  };
  publishMobile: boolean;

  reviewComments: PostReviewComment[];
  createdAt?: string;
  updatedAt?: string;
  deleted: boolean;
  deletedAt?: string;
};

export type Post = {
  id: string;
  accountId: string;
  account: Account;
  companyId: string;
  channelId: string;
  groupId: string | null;
  publishingStatus: string;
  currentVersion: PostVersion;
  createdAt: string;
  updatedAt: string;
  channel: Channel;
  scheduled: boolean;
  requiresReview: boolean;
};

export type CarouselData = {
  image?: string;
  imageFile?: UploadFile;
  video?: string;
  videoFile?: UploadFile;
  name: string;
  description: string;
  url: string;
};

export type PostData = {
  image?: string;
  imageFile?: UploadFile;
  video?: string;
  videoFile?: UploadFile;
};

export type OptionData = {
  label: string;
};

export type ConversionOptionData = {
  label: string;
  description: string;
};

export type DescriptionOptionData = {
  label: string;
  value: string;
  description?: string;
};

export type ValueOptionData = {
  label: string;
  description: string;
  value: string;
  type: string | "";
};

export type LocationSearchData = {
  key: string;
  name: string;
  type: string;
  country_code: string;
  country_name: string;
};

export type LocationSearchMapData = {
  key: string;
  name: string;
  type: string;
  country_code: string;
  country_name: string;
  region: string;
  primary_city: string | "";
};

export type Location = {
  key: string;
  name: string;
};

export type TargetingDetail = {
  id: string;
  name: string;
};

export type Coordinates = {
  lat: number;
  lng: number;
  radius: number;
};

export type Target = {
  behaviors: TargetingDetail[] | [];
  interests: TargetingDetail[] | [];
  life_events: TargetingDetail[] | [];
  family_statuses: TargetingDetail[] | [];
  relationship_statuses: TargetingDetail[] | [];
  education_majors: TargetingDetail[] | [];
  education_schools: TargetingDetail[] | [];
  work_employers: TargetingDetail[] | [];
  work_positions: TargetingDetail[] | [];
  industries: TargetingDetail[] | [];
  income: TargetingDetail[] | [];
};

export type AdAccount = {
  id: string;
  remoteId: string;
  name: string;
  accountId: string;
  metaBusinessId: string;
};

export type MetaBusiness = {
  id: string;
  accountId: string;
  userId: string;
  name: string;
  remoteId: string;
  tokenUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
  channels: (Channel & { userId: string; metaBusinessId: string })[];
  adAccounts: AdAccount[];
};

export type SocialMediaData = {
  name: string;
  period: string;
  values: [
    {
      value: number;
      end_time: string;
    },
  ];
  title: string;
  description: string;
  id: string;
};

export type CampaignFormData = {
  accountId: string;
  name: string;
  objective: string;
  status: string;
  special_ad_categories: string[];
  special_ad_category_country: string[];
  buying_type: string;
  spend_cap?: number | null;
};

export type CampaignResponseData = {
  id: string;
  name: string;
  objective: string;
  status: string;
  special_ad_categories: string[];
  special_ad_category_country: string[];
  buying_type: string;
  spend_cap?: number | null;
};

export type AdSetFormData = {
  accountId: string;
  campaignId: string;
  name: string;
  destination_type: string;
  promoted_object?: { page_id: string };
  optimization_goal: string;
  bid_amount?: number | null;
  billing_event: string;
  daily_budget?: number;
  bid_strategy?: string;
  targeting: {
    age_min: number;
    age_max: number;
    genders: number[];
    geo_locations: {
      countries: string[];
      regions: Location[];
      cities: Location[];
      zips: Location[];
      custom_locations: Coordinates[];
      location_types: string[];
    };
    excluded_geo_locations: {
      countries: string[];
      regions: Location[];
      cities: Location[];
      zips: Location[];
      custom_locations: Coordinates[];
      location_types: string[];
    };
    device_platforms?: string[];
    publisher_platforms: string[];
    facebook_positions: string[];
    instagram_positions: string[];
    flexible_spec: [Target];
    exclusions: Target;
    audience_network_positions: undefined;
    messenger_positions: undefined;
  };
  start_time: string;
  end_time: string;
  status: string;
  dsa_payor: string;
  dsa_beneficiary: string;
};

export type AdSetResponseData = {
  id: string;
  campaignId: string;
  name: string;
  bid_strategy: string;
  destination_type: string;
  optimization_goal: string;
  bid_amount: number;
  billing_event: string;
  daily_budget?: number;
  status: string;
};

export type AdsResponseData = {
  id: string;
  accountId: string;
  adAccountId: string;
  userId: string;
  remoteId: string;
  campaignId: string;
  adsetId: string;
  name: string;
  creativeId: string;
  leadFormId: string;
  status: string;
};

export type InsightsData = {
  facebook: SocialMediaData[];
  instagram: SocialMediaData[];
};

export type DemographicType = {
  name: string;
  period: string;
  values: {
    value: { [key: string]: number };
    end_time: string;
  }[];
  title: string;
  description: string;
  id: string;
};

export type AccountChannelType = {
  id: string;
  type: "FACEBOOK" | "INSTAGRAM";
  name: string;
  accountId: string;
  userId: string;
  instagramActorId: string;
  remoteId: string;
  metaBusinessId: string;
  usage: null;
};

type CallToAction = {
  type: string;
  value: {
    app_destination?: string;
    app_link?: string;
    event_id?: string;
    lead_gen_form_id?: string;
    link?: string;
    link_caption?: string;
    page?: string;
  };
};

export type ChildLinksData = {
  name?: string;
  description?: string;
  link?: string;
  picture?: string;
  video_id?: string;
  call_to_action?: CallToAction;
};

export type LinkData = {
  name?: string;
  link?: string;
  message?: string;
  description?: string;
  picture?: string;
  call_to_action: CallToAction;
  child_attachments?: ChildLinksData[];
  page_welcome_message?: {
    customer_action_type: string;
    message: {
      text: string;
    };
  };
};

export type VideoData = {
  vidoe_id?: string;
  image_url?: string;
  message: string;
  call_to_action: CallToAction;
  page_welcome_message?: {
    customer_action_type: string;
    message: {
      text: string;
    };
  };
};

export type AdsCreativeData = {
  adAccountId?: string;
  name: string;
  object_id?: string;
  object_type?: string;
  messenger_sponsored_message?: {
    message: {
      text: string;
      attachement: {
        type: string;
        payload: {
          template_type: string;
        };
      };
    };
  };
  object_story_spec: {
    page_id: string;
    instagram_actor_id?: string;
    link_data?: LinkData;
    video_data?: VideoData;
  };
};

export type AdsData = {
  name: string;
  adsetId: string;
  creative_id: string;
  leadFormId?: string;
  status: string;
};

export type AdsCopyData = {
  adId: string;
  adsetId?: string;
  status: string;
};

type QuestionOption = {
  value: string;
  key: string;
};

export type Question = {
  type: "FULL_NAME" | "EMAIL" | "PHONE" | "FIRST_NAME" | "LAST_NAME" | "CUSTOM";
  key: string;
  label?: string;
  options?: QuestionOption[];
};

type PrivacyPolicy = {
  url: string;
  link_text: string;
};

export type LeadFormResponse = {
  name: string;
  questions: Question[];
  privacy_policy: PrivacyPolicy;
  follow_up_action_url: string;
};

export type AccountDetail = {
  name?: string;
  email?: string;
  address?: string;
  postal?: string;
  city?: string;
  website?: string;
  phone?: string;
  vat?: string;
  account_profile_pic?: string;
};

export type Todo = {
  id: string;
  title: string;
  description: string;
  dueAt: string;
  priority: TODO_PRIORITY;
  platform: PLATFORMS;
  assignedTo: string;
  status: TODO_STATUS;
  files?: string[];
  assignee?: User;
  creator?: User;
  accountId: string;
  company?: Company;
  companyId?: string;
  createdBy?: string;
};

export type TodoFormData = {
  title: string;
  description: string;
  dueAt: string;
  priority: TODO_PRIORITY;
  platform: PLATFORMS;
  assignedTo: string;
  status: TODO_STATUS;
  files?: UploadFile[];
};

export type TodoResponse = {
  todos: Todo[];
  count: {
    OPEN: number;
    IN_PROGRESS: number;
    DONE: number;
  };
  total: number;
};

export type AdAccountDetail = {
  name?: string;
  default_dsa_payor?: string;
  default_dsa_beneficiary?: string;
};

export type ConnectedPage = {
  id: string;
  type: string;
  name: string;
};

export enum DevicePlatform {
  mobile = "mobile",
  desktop = "desktop",
  both = "both",
}

export enum NotificationType {
  USER_CREATED = "USER_CREATED",
  ACCOUNT_BILLING_DUE = "ACCOUNT_BILLING_DUE",
  POST_REVIEW_COMMENT = "POST_REVIEW_COMMENT",
  POST_REJECTED = "POST_REJECTED",
  POST_APPROVED = "POST_APPROVED",
  POST_SCHEDULED = "POST_SCHEDULED",
  POST_PUBLISHED = "POST_PUBLISHED",
  POST_FAILED = "POST_FAILED",
}

export enum SourceType {
  USER = "USER",
  POST = "POST",
  ACCOUNT = "ACCOUNT",
}

export enum NotificationStatusType {
  READ = "READ",
  DELETE = "DELETED",
  UNREAD = "UNREAD",
}

export type UserNotificationStatus = {
  id?: string;
  userId?: string;
  notificationId?: string;
  status: NotificationStatusType;
};

export type NotificationData = {
  id: string;
  creatorId: string;
  accountId: string;
  message: string;
  imageUrl?: string;
  sourceType: SourceType;
  sourceId?: string;
  type: NotificationType;
  deviceType: DevicePlatform;
  isAnnouncement: boolean;
  createdAt: string;
  userStatuses: UserNotificationStatus[];
};

export type InvoiceData = {
  number: string;
  created: string;
  periodStart: string;
  periodEnd: string;
  couponName: string;
  couponPercentage: number;
  couponDiscountAmount: number;
  customerName: string;
  customerTaxId: string;
  customerAddress: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  subTotalAmount: number;
  subTotalSubscribedTo: number;
  subTotalSubscribedFrom: number;
  taxAmount: number;
  unitAmount: string;
  unitAmountSubscribedTo: string;
  unitAmountSubscribedFrom: string;
  productSubscribed: string;
  productSubscribedFrom: string;
  productSubscribedTo: string;
  quantity: number;
  quantitySubscribedFrom: number;
  quantitySubscribedTo: number;
  totalAmount: number;
};

export type Company = {
  id: string;
  account?: Account;
  accountId: string;
  name: string;
  requiresPostReview: boolean;
  profilePic: string | null;
  userIds: string[];
  users?: User[];
  channelIds: string[];
  channels?: Channel[];
  posts?: Post[];
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string | null;
};

export type SocketPayload = {
  type: "SUCCESS" | "ERROR";
  message: string;
  parentId: string;
  domain?: string;
};

export type AccountPaymentInvoice = {
  id: string;
  name: string;
  account_profile_pic: string | null;
  accessExpiresAt: string;
  stripeCustomerId: string;
  invoices: {
    id: string;
    amount_paid: number;
    created: number;
    billing_reason: string;
    currency: string;
    customer_email: string;
    customer_name: string;
    status: string;
    period_start: number;
    period_end: number;
    subtotal: number;
    subtotal_excluding_tax: number;
    tax: number;
    total: number;
  }[];
  type: AccountType;
};

export type InsightData = { day: number; month: number; year: number; value: number };

export type InsightResponse = {
  facebook: {
    [k in FacebookMetrics]?: InsightData[];
  };
  instagram: {
    [k in InstagramMetrics]?: InsightData[];
  };
};

export enum InsightDatePreset {
  today = "today",
  yesterday = "yesterday",
  this_month = "this_month",
  last_month = "last_month",
  this_quarter = "this_quarter",
  maximum = "maximum",
  data_maximum = "data_maximum",
  last_3d = "last_3d",
  last_7d = "last_7d",
  last_14d = "last_14d",
  last_28d = "last_28d",
  last_30d = "last_30d",
  last_90d = "last_90d",
  last_week_mon_sun = "last_week_mon_sun",
  last_week_sun_sat = "last_week_sun_sat",
  last_quarter = "last_quarter",
  last_year = "last_year",
  this_week_mon_today = "this_week_mon_today",
  this_week_sun_today = "this_week_sun_today",
  this_year = "this_year",
}

export enum MetricPeriod {
  day = "day",
  month = "month",
}

export enum FacebookMetrics {
  page_impressions_unique = "page_impressions_unique",
  page_follows = "page_follows",
  page_fan_adds = "page_fan_adds",
  page_impressions_paid = "page_impressions_paid",
  page_impressions_paid_unique = "page_impressions_paid_unique",
  page_views_total = "page_views_total",
  page_post_engagements = "page_post_engagements",
}

export enum InstagramMetrics {
  impressions = "impressions",
  reach = "reach",
  follower_count = "follower_count",
  profile_views = "profile_views",
}

export enum FacebookPostMetrics {
  post_engaged_users = "post_engaged_users",
  post_impressions_unique = "post_impressions_unique",
  post_clicks_by_type_unique = "post_clicks_by_type_unique",
  post_clicks = "post_clicks",
}

export enum InstagramPostMetrics {
  impressions = "impressions",
  reach = "reach",
  likes = "likes",
  comments = "comments",
  shares = "shares",
}

export type PostWithInsight = Omit<Post, "currentVersion"> & {
  currentVersion: PostVersion;
  insights: {
    name: FacebookPostMetrics | InstagramPostMetrics;
    values: { value: number }[];
  }[];
};

export enum FacebookConsumptionMetrics {
  page_consumptions_by_consumption_type = "page_consumptions_by_consumption_type",
}

export enum FacebookPageDemographicMetrics {
  page_fans_city = "page_fans_city",
  page_fans_country = "page_fans_country",
}

export enum InstagramPageDemographicMetrics {
  reached_audience_demographics = "reached_audience_demographics",
  engaged_audience_demographics = "engaged_audience_demographics",
  follower_demographics = "follower_demographics",
  threads_follower_demographics = "threads_follower_demographics",
}

export enum InstaInsightBreakdown {
  country = "country",
  city = "city",
  age = "age",
  gender = "gender",
}

export enum InstagramInsightTimeframe {
  last_14_days = "last_14_days",
  last_30_days = "last_30_days",
  last_90_days = "last_90_days",
  prev_month = "prev_month",
  this_month = "this_month",
  this_week = "this_week",
}
