import { useQuery, UseQueryOptions } from "react-query";
import { NotificationApi } from "services/api";
import { NotificationQueryKeys } from "services/query-keys";
import { DevicePlatform } from "types";

export type TGetUnreadNotificationsCountParams = {
  accountId: string;
  platform: DevicePlatform;
  companyId?: string;
};

type GetUnreadNotificationsCountResult = Awaited<ReturnType<typeof NotificationApi.getUnreadCount>>;

const useUnreadNotificationsCount = (
  filters: TGetUnreadNotificationsCountParams,
  options?: UseQueryOptions<GetUnreadNotificationsCountResult>,
) => {
  return useQuery({
    queryKey: NotificationQueryKeys.getUnreadCount(filters),
    queryFn: async () => {
      return NotificationApi.getUnreadCount(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialData: options?.initialData ?? { unreadCount: 0 },
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.platform,
  });
};

export default useUnreadNotificationsCount;
