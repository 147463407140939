import React, { ForwardedRef } from "react";
import { mergeClasses } from "utils/mergeClasses";
import classes from "./index.module.css";

export type CustomBadgeColor = "red" | "green" | "gray" | "yellow" | "blue" | "orange";

const CustomBadge = React.forwardRef(
  (
    {
      children,
      className,
      color,
      dot,
      size = "md",
      ...rest
    }: React.HTMLAttributes<HTMLSpanElement> & {
      color: CustomBadgeColor;
      dot?: boolean;
      size?: "sm" | "md";
    },
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    return (
      <span {...rest} ref={ref} className={mergeClasses(classes.badge, className)} data-color={color} data-size={size}>
        {dot && <span className={classes.badgeDot} />}
        {children}
      </span>
    );
  },
);

export default CustomBadge;
