import { Button, Card, message, Spin } from "antd";
import CustomBadge from "components/CustomBadge";
import CustomTable from "components/CustomTable";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useAccount from "hooks/useAccount";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useContext, useMemo } from "react";
import { makeSubject } from "utils/access";
import useAccountInvoices from "hooks/useAccountInvoices";
import { formatDate } from "utils/date";
import formatNumber from "utils/formatNumber";
import useDownloadInvoice from "hooks/useDownloadInvoice";
import useAccountSubscription from "hooks/useAccountSubscription";
import { useTranslation } from "react-i18next";
import classes from "./index.module.css";

const Billing = () => {
  const { t } = useTranslation(["common", "pages"]);
  const { selectedAccountId } = useSelectedAccount();

  const { ability, isLoading: isLoadingPermissions } = useContext(UserAccessPolicyContext);

  const canViewAccountDetails = ability.can("read", makeSubject("Account", { accountId: selectedAccountId }));

  const canViewAccountSubscription = ability.can(
    "read",
    makeSubject("AccountSubscription", { accountId: selectedAccountId }),
  );
  const canUpdateAccountSubscription = ability.can(
    "update",
    makeSubject("AccountSubscription", { accountId: selectedAccountId }),
  );

  const { data: subscription, isLoading } = useAccountSubscription(
    {
      accountId: selectedAccountId,
    },
    {
      enabled: canViewAccountSubscription,
      refetchOnWindowFocus: false,
    },
  );

  const { data: account, isLoading: isLoadingAccount } = useAccount(selectedAccountId, {
    enabled: !!selectedAccountId && canViewAccountDetails,
  });

  const { data: accountInvoices, isLoading: isLoadingInvoices } = useAccountInvoices({ accountId: selectedAccountId });

  const isLoadingTableData = isLoadingAccount || isLoadingPermissions || isLoadingInvoices;

  const tableData = useMemo(() => {
    if (!accountInvoices || !account) return [];
    return accountInvoices.map((invoice) => {
      return {
        ...invoice,
        name: account.name,
        date: formatDate(new Date(invoice.effective_at * 1000)),
        amount: formatNumber(invoice.total / 100, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        isPaid: invoice.status.toLowerCase() === "paid",
      };
    });
  }, [accountInvoices, account]);

  const { mutate: downloadInvoice } = useDownloadInvoice({
    onSuccess: () => {
      message.success(t("pages:billingSettings.invoiceDownloadSuccessMessage"));
    },
    onError: () => {
      message.error(t("pages:billingSettings.invoiceDownloadErrorMessage"));
    },
  });

  const planType = account?.type === "CUSTOMER" ? "Basic" : "Pro";

  return (
    <div className={classes.page}>
      <Card className={classes.subCard}>
        <Spin spinning={isLoading || isLoadingPermissions}>
          <div className={classes.subCardWrapper}>
            <div className={classes.subCardHeader}>
              <strong className={classes.subCardHeaderTitle}>{t("pages:billingSettings.subscriptionInfoTitle")}</strong>
              <CustomBadge
                className={classes.subStatus}
                color={subscription?.status === "active" ? "green" : "gray"}
                size="sm"
              >
                {subscription?.status === "active" ? t("common:labels.active") : t("common:labels.inActive")}
              </CustomBadge>
            </div>
            <div className={classes.subCardContent}>
              <strong>
                {subscription?.plan.interval === "month"
                  ? `EASYPOSTLAB ${planType} (Monat)`
                  : `EASYPOSTLAB ${planType} (Jahr)`}
              </strong>
              <a
                aria-disabled={!canUpdateAccountSubscription}
                className={classes.updateSubLink}
                href={!canUpdateAccountSubscription ? undefined : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}
                target="_blank"
                rel="noreferrer"
              >
                {t("pages:billingSettings.manageSubscriptionLabel")}
              </a>
            </div>
          </div>
        </Spin>
      </Card>
      <CustomTable
        headerPrefix={<CustomTable.Title>{t("pages:billingSettings.invoicesLabel")}</CustomTable.Title>}
        defaultProps={{
          dataSource: tableData,
          loading: isLoadingTableData,
          columns: [
            {
              title: t("pages:billingSettings.accountNameLabel"),
              width: 300,
              render: (_, record) => {
                return <p className={classes.accountLabel}>{record.name}</p>;
              },
            },
            {
              title: t("pages:billingSettings.invoiceDateLabel"),
              render: (_, record) => {
                return <p>{record.date}</p>;
              },
            },
            {
              title: t("pages:billingSettings.statusLabel"),
              render: (_, record) => {
                return (
                  <div>
                    <CustomBadge color={record.isPaid ? "green" : "gray"} size="sm">
                      {record.isPaid ? t("pages:billingSettings.paidLabel") : t("pages:billingSettings.unpaidLabel")}
                    </CustomBadge>
                  </div>
                );
              },
            },
            {
              title: t("pages:billingSettings.amountLabel"),
              render: (_, record) => {
                return <p>{record.amount}€</p>;
              },
            },
            {
              render: (_, record) => {
                return (
                  <div>
                    <Button
                      className={classes.downloadButton}
                      style={{ textDecoration: "underline" }}
                      type="link"
                      onClick={() =>
                        downloadInvoice({
                          accountId: selectedAccountId,
                          invoiceId: record.id,
                          invoiceNumber: record.number,
                        })
                      }
                    >
                      <p>{t("common:buttons.download")}</p>
                    </Button>
                  </div>
                );
              },
              width: 200,
            },
          ],
        }}
      />
    </div>
  );
};

export default Billing;
