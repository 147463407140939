import React, { useContext, useEffect, useMemo, useState } from "react";
import { EventContentArg, DayCellContentArg, DatesSetArg } from "@fullcalendar/core";
import { useRouterContext } from "@pankod/refine-core";
import { PostApi } from "services/api";
import { AxiosError } from "axios";
import type { MenuProps } from "antd";
import { PostPublishingStatusType } from "types/data";
import FcFullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Row, Dropdown, Spin, Popover, Card } from "antd";
import deLocale from "@fullcalendar/core/locales/de";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { message } from "@pankod/refine-antd";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { convertUTCDateToLocal } from "utils/date";
import useSelectedAccount from "hooks/useSelectedAccount";
import usePosts from "hooks/posts/usePosts";

import { useSearchParams } from "@pankod/refine-react-router-v6";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import useCompany from "hooks/company/useCompany";
import { EllipsisOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { mergeClasses } from "utils/mergeClasses";
import FacebookSmallIcon from "../../../../assets/FacebookSmallIcon.svg";
import InstagramSmallicon from "../../../../assets/InstagramSmallIcon.svg";

import EventModal from "../EventModal/index";
import DeleteModal from "../DeleteModal";
import "./FullCalendar.css";
import plannerClasses from "../../Planner.module.css";
import classes from "./index.module.css";
import "../../../../styles/button.css";
import "../../../../styles/spinner.css";
import "../../../../styles/dropdown.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

dayjs.extend(weekday);
dayjs.extend(localeData);

interface NewCalenderProps {
  setPreviewGridStartDate: React.Dispatch<React.SetStateAction<string>>;
  setPreviewGridEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const convertDateFormat = (dateString: string) => {
  const date = new Date(dateString);
  const convertedDate = new Date(date.getTime()).toISOString();
  return convertedDate;
};

const postsType: { [key: string]: number } = {
  draft: 0,
  publish: 0,
  schedule: 0,
};

function FulLCalendar({ setPreviewGridStartDate, setPreviewGridEndDate }: NewCalenderProps) {
  const [_, setSearchParams] = useSearchParams();

  const prevDate = new Date(new Date().setDate(new Date().getDate() - 7));
  const nextDate = new Date(new Date().setDate(new Date().getDate() + 7));
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [currentGrid, setCurrentGrid] = useState("month");
  const [currentPostId, setCurrentPostId] = useState("");
  const [currentPostVersionId, setCurrentPostVersionId] = useState("");
  const [currentPublishingStatus, setCurrentPublishingStatus] = useState<PostPublishingStatusType | undefined>();
  const [currentEventStatus, setCurrentEventStatus] = useState("");
  const [startDate, setStartDate] = useState(prevDate.toLocaleString("en-ca").split(",")[0]);
  const [endDate, setEndDate] = useState(nextDate.toLocaleString("en-ca").split(",")[0]);
  const { push } = useRouterContext().useHistory();

  const { pathPrefix, selectedAccountId: accountId, selectedCompanyId: companyId } = useSelectedAccount();

  const { ability } = useContext(UserAccessPolicyContext);
  const canCreatePosts = ability.can("create", "Post");

  const { data: company } = useCompany(companyId ?? "");

  // const [expandedCards, setExpandedCards] = useState<string[]>([]);
  const maxDate = dayjs().add(365, "day");

  const initialView = localStorage.getItem("fcDefaultView") ?? "dayGridMonth";
  const initialDate = localStorage.getItem("fcDefaultDate") ?? new Date();

  const { isLoading, refetch, data } = usePosts(
    {
      accountId,
      companyId,
      startDate,
      endDate,
    },
    {
      enabled: !!accountId && !!companyId,
      refetchOnWindowFocus: false,
    },
  );

  const eventData = useMemo(
    () =>
      data?.map((event) => {
        const date: string = event.currentVersion.postedAt
          ? event.currentVersion.postedAt
          : event.currentVersion.scheduledAt
            ? event.currentVersion.scheduledAt
            : event.currentVersion.createdAt ?? event.createdAt;

        const canUpdate = ability.can(
          "update",
          makeSubject("Post", {
            accountId: event.accountId,
            channelId: event.channelId,
            companyId: event.companyId,
          }),
        );

        const canDelete = ability.can(
          "delete",
          makeSubject("Post", {
            accountId: event.accountId,
            channelId: event.channelId,
            companyId: event.companyId,
          }),
        );

        return {
          title: event.currentVersion.content?.body ?? "",
          postId: event.id,
          assetsUrl: event.currentVersion.content?.assets[0] ? event.currentVersion.content?.assets[0]?.url : "",
          thumbnailUrl: event.currentVersion.thumbnailUrl,
          channelType: event.channel.type,
          status: event.publishingStatus,
          start: convertUTCDateToLocal(date).format("YYYY-MM-DDTHH:mm:ss"),
          time: convertUTCDateToLocal(date).format("YYYY-MM-DDTHH:mm:ss"),
          channelId: event.channelId,
          channel: event.channel,
          accountId: event.accountId,
          companyId: event.companyId,
          canUpdate,
          canDelete,
          publishingStatus: event.publishingStatus,
          currentVersion: event.currentVersion,
        };
      }) ?? [],
    [data, ability],
  );

  const { mutate, isLoading: isPublishing } = useMutation(
    "update-schedule",
    (type: PostPublishingStatusType) => {
      return PostApi.updateSchedule(currentPostVersionId ?? "", {
        publishingStatus: type,
      });
    },
    {
      onSuccess: () => {
        message.success("Beitrag veröffentlicht");
        refetch();
        // eslint-disable-next-line
      },
      onError: (error: Error) => {
        if (error instanceof AxiosError) {
          message.error(error.response?.data?.message ?? error.message);
        } else {
          console.error(error);
        }
      },
    },
  );

  const handleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
    refetch();
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "View-Post") {
      setSearchParams({ postVersionId: currentPostVersionId });
      return;
    }
    if (key === "Edit-Post") {
      push(`${pathPrefix}/posts/${currentPostId}`);
    }
    if (key === "Reschedule-Post") {
      setModalVisible(!modalVisible);
    }
    if (key === "Delete-Post") {
      handleDeleteModal();
    }
    if (key === "Publish-Post") {
      mutate(PostPublishingStatusType.PUBLISHING);
    }
  };

  const getItems = ({
    canUpdate,
    canDelete,
    publishingStatus,
  }: {
    canUpdate: boolean;
    canDelete: boolean;
    publishingStatus: PostPublishingStatusType;
  }) =>
    [
      {
        label: "Beitrag ansehen",
        key: "View-Post",
      },
      ...(canUpdate && currentEventStatus !== PostPublishingStatusType.PUBLISHED
        ? [
            {
              label: "Beitrag bearbeiten",
              key: "Edit-Post",
            },
          ]
        : []),
      ...(canUpdate &&
      (currentEventStatus === PostPublishingStatusType.SCHEDULED ||
        currentEventStatus === PostPublishingStatusType.FAILED)
        ? [
            {
              label: "Beitrag neu planen",
              key: "Reschedule-Post",
            },
          ]
        : []),
      ...(canUpdate &&
      !company?.requiresPostReview &&
      (currentEventStatus === PostPublishingStatusType.SCHEDULED ||
        currentEventStatus === PostPublishingStatusType.DRAFT)
        ? [
            {
              label: "Beitrag veröffentlichen",
              key: "Publish-Post",
            },
          ]
        : []),
      ...(canDelete && publishingStatus !== PostPublishingStatusType.PUBLISHED
        ? [
            {
              label: "Beitrag löschen",
              key: "Delete-Post",
            },
          ]
        : []),
    ] satisfies MenuProps["items"];

  const updateDate = (date: DatesSetArg) => {
    const currStartDate = convertDateFormat(date.startStr);
    const currEndDate = convertDateFormat(date.endStr);
    setStartDate(currStartDate);
    setEndDate(currEndDate);
  };

  useEffect(() => {
    setPreviewGridStartDate(startDate);
    setPreviewGridEndDate(endDate);
  }, [startDate, endDate, setPreviewGridStartDate, setPreviewGridEndDate]);

  const handleCloseModal = () => {
    setCurrentDate("");
    setModalVisible(false);
    setCurrentPostVersionId("");
    setCurrentEventStatus("");
    setCurrentPublishingStatus(undefined);
    setCurrentPostId("");
    refetch();
  };

  const renderEventContent = (
    eventContent: EventContentArg & {
      event: {
        _def: {
          extendedProps: (typeof eventData)[number];
        };
      };
    },
  ) => {
    const isWeekly = currentGrid === "week";

    const event = {
      title: eventContent.event._def.title,
      postId: eventContent.event._def.extendedProps.postId,
      imageUrl: eventContent.event._def.extendedProps.assetsUrl,
      thumbnailUrl: eventContent.event._def.extendedProps.thumbnailUrl as string | undefined,
      channelType: eventContent.event._def.extendedProps.channelType,
      publishingStatus: eventContent.event._def.extendedProps.publishingStatus as PostPublishingStatusType,
      time: eventContent.event._def.extendedProps.time,
      channelId: eventContent.event._def.extendedProps.channelId,
      accountId: eventContent.event._def.extendedProps.accountId,
      companyId: eventContent.event._def.extendedProps.companyId,
      canUpdate: eventContent.event._def.extendedProps.canUpdate,
      canDelete: eventContent.event._def.extendedProps.canDelete,
      currentVersion: eventContent.event._def.extendedProps.currentVersion,
      channel: eventContent.event._def.extendedProps.channel,
    };

    const postTypeKeys = Object.keys(postsType);

    if (postTypeKeys.includes(event.publishingStatus)) {
      postsType[event.publishingStatus] += 1;
    }

    const time = dayjs.utc(event.time);
    const date = time.format("YYYY-MM-DD");
    const formattedTime = time.format("HH.mm[h]");

    const items = getItems({
      canUpdate: event.canUpdate,
      canDelete: event.canDelete,
      publishingStatus: event.publishingStatus,
    });

    const thumbnail = event.thumbnailUrl ? (
      <img src={event.thumbnailUrl} />
    ) : event.imageUrl ? (
      event.imageUrl.toLowerCase().endsWith(".mp4") ? (
        <video src={event.imageUrl} />
      ) : (
        <img src={event.imageUrl} />
      )
    ) : null;

    return (
      <>
        <Dropdown
          menu={{
            // condition added to disable delete button for published posts on instagram
            items,

            onClick,
          }}
          placement="bottomRight"
          trigger={["click"]}
          destroyPopupOnHide
          onOpenChange={(open) => {
            if (open) {
              setCurrentEventStatus(event.publishingStatus);
              setCurrentPublishingStatus(event.publishingStatus);
              setCurrentPostId(event.postId);
              setCurrentPostVersionId(event.currentVersion.id);
              setCurrentDate(event.time);
            }
          }}
          disabled={
            event.publishingStatus === PostPublishingStatusType.SCHEDULING ||
            event.publishingStatus === PostPublishingStatusType.PUBLISHING
          }
        >
          <div
            className={mergeClasses(classes.calendarCell, isWeekly ? classes.weeklyCalendarCell : undefined)}
            data-status={PostPublishingStatusType[event.publishingStatus]}
            style={{
              opacity: event.publishingStatus === PostPublishingStatusType.PUBLISHING ? 0.5 : 1,
            }}
            aria-disabled={event.publishingStatus === PostPublishingStatusType.PUBLISHING}
          >
            {isWeekly ? (
              <>
                {thumbnail ? <div className={classes.weeklyImageContainer}>{thumbnail}</div> : null}
                <div className={classes.calendarCellPlatformContainer}>
                  {event.channelType === "FACEBOOK" ? (
                    <img src={FacebookSmallIcon} alt="facebook" className={plannerClasses.icon} />
                  ) : (
                    <img src={InstagramSmallicon} alt="instagram" className={plannerClasses.icon} />
                  )}
                  <p>{event.channel.name ?? "Unknown Platform"}</p>
                </div>
                {event.title ? <p className={classes.calendarCellTitle}>{event.title}</p> : null}
                <span className={classes.calendarCellTime}>{formattedTime}</span>
              </>
            ) : (
              <>
                {event.thumbnailUrl ?? event.imageUrl ? (
                  <div className={classes.calendarCellImageContainer}>
                    <div className={classes.calendarCellImageWrapper}>{thumbnail}</div>
                  </div>
                ) : null}
                <div className={classes.calendarCellTextContainer}>
                  <div className={classes.calendarCellPlatformContainer}>
                    {event.channelType === "FACEBOOK" ? (
                      <img src={FacebookSmallIcon} alt="facebook" className={plannerClasses.icon} />
                    ) : (
                      <img src={InstagramSmallicon} alt="instagram" className={plannerClasses.icon} />
                    )}
                    <p>{event.channel.name ?? "Unknown Platform"}</p>
                  </div>
                  {event.title ? <p className={classes.calendarCellTitle}>{event.title}</p> : null}
                  <span className={classes.calendarCellTime}>{formattedTime}</span>
                </div>
              </>
            )}
          </div>
        </Dropdown>
        <Popover
          content={
            <Dropdown
              menu={{ items, onClick }}
              placement="bottomRight"
              trigger={["hover", "click"]}
              destroyPopupOnHide
              onOpenChange={() => {
                setCurrentEventStatus(event.publishingStatus);
                setCurrentPublishingStatus(event.publishingStatus);
                setCurrentPostId(event.postId);
                setCurrentPostVersionId(event.currentVersion.id);
                setCurrentDate(event.time);
              }}
              disabled
            >
              <div
                style={{
                  width: 200,
                  display: "flex",
                  opacity: event.publishingStatus === PostPublishingStatusType.PUBLISHING ? 0.5 : 1,
                }}
                aria-disabled={event.publishingStatus === PostPublishingStatusType.PUBLISHING}
              >
                <div style={{ width: "50%", overflow: "hidden" }}>
                  {event.thumbnailUrl ? (
                    <img
                      src={event.thumbnailUrl}
                      alt="Your Event"
                      style={{
                        maxWidth: "100%",
                        height: "100%",
                        maxHeight: 80,
                      }}
                    />
                  ) : event.imageUrl ? (
                    <img
                      src={event.imageUrl}
                      alt="Your Event"
                      style={{
                        maxWidth: "100%",
                        height: "100%",
                        maxHeight: 80,
                      }}
                    />
                  ) : (
                    <>
                      <div className={plannerClasses.eventCardText}>{event.title}</div>
                      {event.title.length > 10 && (
                        <label className="readButton" onClick={() => push(`${pathPrefix}/posts/${event.postId}`)}>
                          mehr lesen
                        </label>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: 10,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <div className={classes.popoverIconContainer}>
                    {event.channelType === "FACEBOOK" ? (
                      <img src={FacebookSmallIcon} alt="facebook" className={plannerClasses.icon} />
                    ) : (
                      <img src={InstagramSmallicon} alt="instagram" className={plannerClasses.icon} />
                    )}
                  </div>
                  <div>
                    <div className={classes.time}>
                      <FieldTimeOutlined style={{ marginRight: 2 }} />
                      <span
                        data-testid={`planner-(${date})-${event.publishingStatus}${postsType[event.publishingStatus]}`}
                      >
                        {formattedTime}
                      </span>
                    </div>
                    <div style={{ color: "black", fontSize: 12 }}>
                      <span>
                        <EllipsisOutlined />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown>
          }
        >
          <div className={classes.calendarCellPopover} data-status={PostPublishingStatusType[event.publishingStatus]}>
            {event.channelType === "FACEBOOK" ? (
              <img src={FacebookSmallIcon} alt="facebook" className={plannerClasses.icon} />
            ) : (
              <img src={InstagramSmallicon} alt="instagram" className={plannerClasses.icon} />
            )}

            <div className={classes.calendarCellTime}>
              <span data-testid={`planner-(${date})-${event.publishingStatus}${postsType[event.publishingStatus]}`}>
                {formattedTime}
              </span>
            </div>
          </div>
        </Popover>
      </>
    );
  };

  const renderDayContent = (info: DayCellContentArg) => {
    return <div>{info.dayNumberText}</div>;
  };

  return (
    <Card
      className={classes.calendarContainer}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Row className={classes.calendar}>
        {deleteModalVisible && (
          <DeleteModal
            visible={deleteModalVisible}
            handleCloseModal={handleDeleteModal}
            currentText="Beitrag"
            currentIds={[currentPostId]}
          />
        )}
        {currentDate && currentPublishingStatus && (
          <EventModal
            visible={modalVisible}
            handleCloseModal={handleCloseModal}
            dateSelected={currentDate}
            postVersionId={currentPostVersionId}
            publishingStatus={currentPublishingStatus}
          />
        )}
        <Spin spinning={isLoading || isPublishing} className={plannerClasses.calendarContainer}>
          <FcFullCalendar
            locale={deLocale}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            dayCellContent={renderDayContent}
            eventStartEditable={false}
            eventDurationEditable={false}
            selectAllow={(selectInfo) => {
              return dayjs().diff(selectInfo.start, "day") <= 0;
            }}
            dayMaxEvents={currentGrid === "month" ? 2 : 3}
            data-is-weekly={currentGrid === "weekly"}
            headerToolbar={{
              left: undefined,
              center: "prev title next",
              right: "dayGridMonth,dayGridWeek",
            }}
            moreLinkContent={({ num }) => {
              return <div className={classes.moreLink}>+ {num} mehr</div>;
            }}
            dateClick={(e) => {
              const currDate = dayjs();
              const currentTime = currDate.format("HH:mm");
              const date = dayjs(`${e.dateStr}T${currentTime}:00`);
              if (date.diff(currDate, "day") < 0) {
                setModalVisible(false);
              } else {
                setCurrentDate(`${e.dateStr}T${currentTime}:00`);
                //TODO CHECK WHAT HAPPENS FOR ALREADY CREATED POSTS WHEN ITS CLICKED FROM CALENDAR
                if (!canCreatePosts) return;
                setModalVisible(true);
              }
            }}
            initialView={initialView as any}
            initialDate={initialDate}
            editable
            selectable
            selectMirror
            weekends
            datesSet={(dateInfo) => {
              const startDate = dayjs(dateInfo.startStr);
              const endDate = dayjs(dateInfo.endStr);
              const diffAdd = endDate.diff(startDate, "day") / 2;

              localStorage.setItem("fcDefaultView", dateInfo.view.type);
              localStorage.setItem("fcDefaultDate", dayjs(dateInfo.startStr).add(diffAdd, "day").format());

              if (dateInfo.view.type === "dayGridMonth") {
                setCurrentGrid("month");
              } else {
                setCurrentGrid("week");
              }
              updateDate(dateInfo);
            }}
            events={eventData}
            defaultTimedEventDuration={{ minutes: 1 }}
            eventContent={renderEventContent}
            validRange={{ end: maxDate.format() }}
          />
        </Spin>
      </Row>
    </Card>
  );
}

export default FulLCalendar;
