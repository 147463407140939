import { useEffect, useState } from "react";
import { Button, Form, Input, Row, message, Divider, Flex, Typography, Checkbox } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import { useRouterContext } from "@pankod/refine-core";
import { useQueryClient, useMutation } from "react-query";
import { AuthApi, AccountApi } from "services/api";
import authProvider, { SigninProvider } from "authProvider";
import { JwtToken, parseJwt, validateEmail, validatePassword } from "utils";
import OTPInput from "components/OTPInput";
import SingleSignOn from "components/SSO";
import { Link } from "@pankod/refine-react-router-v6";
import AuthContainer from "components/AuthContainer";
import { useTranslation } from "react-i18next";
import { ReactComponent as MailOutlined } from "../../assets/MailOutlined.svg";
import classes from "./Register.module.css";

type RegisterFormData = {
  accountName?: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  tnc: boolean;
};

const registerFormInitialData = {
  accountName: "",
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  tnc: false,
};

const renderEyeIcon = (visible: boolean) => {
  return visible ? (
    <EyeOutlined data-testid="login-pwd-eye-btn" style={{ fontSize: 20 }} />
  ) : (
    <EyeInvisibleOutlined data-testid="login-pwd-eye-btn" style={{ fontSize: 20 }} />
  );
};

export default function Register() {
  const { t } = useTranslation(["common", "pages"]);
  const [form] = Form.useForm();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const { push } = useRouterContext().useHistory();
  const params = useRouterContext().useParams<{
    token: string;
  }>();
  const [email, setEmail] = useState<string>("");
  const queryClient = useQueryClient();
  const token = params.token;

  useEffect(() => {
    if (token) {
      // If token exists, set email for further use in page
      const decodedToken: JwtToken = parseJwt(token);
      form.setFieldValue("email", decodedToken.email);
      setEmail(decodedToken.email);
    }
  }, [token, form]);

  const { mutate: register, isLoading } = useMutation(
    "register",
    async (data: RegisterFormData) => {
      if (token) {
        await AccountApi.respondInvite({
          token,
          accept: true,
          email: data.email,
          name: data.name,
          password: data.password,
        });

        await authProvider.login({
          email: data.email,
          password: data.password,
        });

        return;
      } else {
        await AuthApi.register({
          accountName: (data.accountName ?? data.name).trim(),
          name: data.name.trim(),
          email: data.email,
          password: data.password,
        });
      }

      queryClient.setQueryData("onboarding", () => ({
        email: data.email,
      }));
    },
    {
      onSuccess: (_, { email }) => {
        if (token) {
          push("/account/-/company/-/dashboard");
          return;
        }
        setEmail(email);
        setShowOtpInput(true);
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const { mutate: ssoSignin, isLoading: isSigningInViaSSO } = useMutation(
    "login",
    (data: { access_token: string; signinProvider: SigninProvider }) => {
      return authProvider.login(data);
    },
    {
      onSuccess: async () => {
        form.setFieldsValue(registerFormInitialData);
        push("/account/-/company/-/dashboard");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <AuthContainer type="REGISTER">
      {showOtpInput ? (
        <OTPInput
          email={email}
          next={() => {
            push("/onboarding?step=1");
          }}
          verify={AuthApi.verifyOtp}
        />
      ) : (
        <Flex vertical align="center" gap={18}>
          <Flex vertical gap={39}>
            <Row>
              <Typography className={classes.registerFormTitle}>{t("pages:register.title")}</Typography>
            </Row>
            <Flex vertical gap={24} className={classes.ssoContainer}>
              <Row>
                <Typography className={classes.registerFormSubtitle}>{t("pages:register.ssoLoginLabel")}</Typography>
              </Row>
              <SingleSignOn mutate={ssoSignin} disabled={isSigningInViaSSO || isLoading} />
              <Row align="middle">
                <Divider className={classes.divider}>{t("common:labels.or")}</Divider>
              </Row>
            </Flex>
          </Flex>
          <Form
            form={form}
            layout="vertical"
            initialValues={registerFormInitialData}
            onFinish={register}
            className={classes.loginForm}
            requiredMark={false}
            validateTrigger={["submit"]}
          >
            <Flex vertical gap={21}>
              <Flex vertical gap={16}>
                <Row>
                  <Form.Item
                    label="Kontoname"
                    name="accountName"
                    className={classes.inputLabel}
                    rules={[{ required: true, whitespace: true, message: t("common:validation.requiredField") }]}
                  >
                    <Input className={classes.input} maxLength={40} />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="Benutzername"
                    name="name"
                    className={classes.inputLabel}
                    rules={[{ required: true, whitespace: true, message: t("common:validation.requiredField") }]}
                  >
                    <Input className={classes.input} />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    className={classes.inputLabel}
                    rules={[
                      { required: true, message: t("common:validation.requiredField") },
                      {
                        validator: (_, value) => {
                          if (value?.length !== 0 && !validateEmail(value))
                            return Promise.reject(new Error(t("common:validation.invalidEmail")));
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      className={classes.input}
                      type="email"
                      suffix={<MailOutlined className={classes.inputIcon} />}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="Passwort"
                    name="password"
                    className={classes.inputLabel}
                    rules={[
                      { required: true, message: t("common:validation.requiredField") },
                      { min: 8, message: t("pages:register.passwordLengthValidationMessage") },
                      {
                        validator: (_, value) => {
                          if (value?.length !== 0 && !validatePassword(value))
                            return Promise.reject(new Error(t("pages:register.invalidPasswordValidationMessage")));
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password className={classes.input} suffix={<EyeOutlined />} iconRender={renderEyeIcon} />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    name="tnc"
                    className={classes.inputLabel}
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error(t("common:validation.requiredField"))),
                      },
                    ]}
                  >
                    <Checkbox className={classes.tncCheckbox}>
                      <p className={classes.tncText}>
                        Ich habe die{" "}
                        <a
                          href="https://easypostlab.com/datenschutzerklaerung"
                          rel="noreferrer noopener"
                          target="_blank"
                          className={classes.tncLink}
                        >
                          Datenschutzrichtlinie
                        </a>{" "}
                        und{" "}
                        <a
                          href="https://easypostlab.com/agb"
                          rel="noreferrer noopener"
                          target="_blank"
                          className={classes.tncLink}
                        >
                          AGB
                        </a>{" "}
                        gelesen und stimme diesen zu.
                      </p>
                    </Checkbox>
                  </Form.Item>
                </Row>
              </Flex>
              <Flex vertical gap={26}>
                <Row>
                  <Button loading={isLoading} className={classes.submitBtn} type="primary" htmlType="submit">
                    {t("pages:register.registerButtonLabel")}
                  </Button>
                </Row>
                <Row>
                  <Typography className={classes.loginLabel}>
                    {t("pages:register.gotoLoginLabel")}{" "}
                    <Link to="/login" className={classes.link}>
                      {t("pages:register.gotoLoginButtonLabel")}
                    </Link>
                  </Typography>
                </Row>
              </Flex>
            </Flex>
          </Form>
        </Flex>
      )}
    </AuthContainer>
  );
}
