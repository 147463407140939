import { TFindAllUserAccessPolicyParams } from "hooks/UserAccessPolicy/useUserAccessPolicy";
import { TGetPostsParams } from "hooks/posts/usePosts";
import { TFindAllCompaniesParams } from "hooks/company/useCompanies";
import { TFindAllChannelsParams } from "hooks/useChannels";
import { TGetTodosParams } from "hooks/todos/useTodos";
import { TGetPostReviewCommentsParams } from "hooks/PostReviewComment/usePostReviewComments";
import { TListUsersParams } from "hooks/Users/useUsers";
import { TListNotificationsParams } from "hooks/Notification/useNotifications";
import { TGetUnreadNotificationsCountParams } from "hooks/Notification/useUnreadNotificationsCount";
import { TGetPostVersionParams } from "hooks/posts/usePostVersion";
import { TPostReviewCommentDetailsParams } from "hooks/PostReviewComment/usePostReviewComment";
import { TGetUserStatsParams } from "hooks/Users/useUserStats";
import { TGetCompanyStatsParams } from "hooks/company/useCompanyStats";
import { TGetAccountStatsParams } from "hooks/useAccountStats";
import { TListInvoicesParams } from "hooks/Payment/usePaymentInvoices";
import { TGetAccountUsersParams } from "hooks/useAccountUsers";
import { GetPostVersionParams } from "hooks/posts/usePostVersions";
import { QueryKey } from "react-query";
import { TGetAccountUserInvitesParams } from "hooks/useAccountUserInvites";
import { TGetInsightsParams } from "hooks/Insight/useInsights";
import { TGetPostStatsParams } from "hooks/posts/usePostStats";
import { TGetInsightPostsParams } from "hooks/Insight/useInsightPosts";
import { TGetInsightConsumptionParams } from "hooks/Insight/useInsightConsumption";
import { TGetAccountInvoicesParams } from "hooks/useAccountInvoices";
import { TGetAccountSubscriptionParams } from "hooks/useAccountSubscription";
import { TInsightDemographicParams } from "hooks/Insight/useInsightDemographic";

export const AuthQueryKeys = {
  me: (accountId?: string) => ["me"] as QueryKey,
};

export const AccountQueryKeys = {
  list: () => ["accounts"] as QueryKey,
  getChannels: (filters: TFindAllChannelsParams) => ["accounts", ...(filters !== undefined ? [filters] : [])],
  details: (accountId: string) => ["accounts", accountId],
  stats: (filters?: TGetAccountStatsParams) => ["accounts", "stats", ...(filters !== undefined ? [filters] : [])],
  getUsers: ({ accountId, ...filters }: TGetAccountUsersParams) => [
    "accounts",
    accountId,
    "users",
    ...(filters !== undefined ? [filters] : []),
  ],
  getUserInvites: ({ accountId, ...filters }: TGetAccountUserInvitesParams) => [
    "accounts",
    accountId,
    "user",
    "invite",
    ...(filters !== undefined ? [filters] : []),
  ],
  getInvoices: ({ accountId, ...filters }: TGetAccountInvoicesParams) =>
    ["accounts", accountId, "invoices", ...(filters !== undefined ? [filters] : [])] as QueryKey,
  getSubscription: ({ accountId, ...filters }: TGetAccountSubscriptionParams) =>
    ["accounts", accountId, "subscription", ...(filters !== undefined ? [filters] : [])] as QueryKey,
};

export const UserQueryKeys = {
  list: (filters?: TListUsersParams) => ["users", ...(filters !== undefined ? [filters] : [])],
  stats: (filters?: TGetUserStatsParams) => ["users", "stats", ...(filters !== undefined ? [filters] : [])],
};

export const CompanyQueryKeys = {
  list: (filters?: TFindAllCompaniesParams) => ["companies", ...(filters !== undefined ? [filters] : [])],
  details: (companyId: string, filters?: { include?: ("users" | "channels" | "posts" | "account")[] }) => [
    "companies",
    companyId,
    ...(filters !== undefined ? [filters] : []),
  ],
  stats: (filters?: TGetCompanyStatsParams) => ["companies", "stats", ...(filters !== undefined ? [filters] : [])],
};

export const UserAccessPolicyKeys = {
  list: (queryParams?: TFindAllUserAccessPolicyParams) => [
    "user-access-policy",
    ...(queryParams !== undefined ? [queryParams] : []),
  ],
};

export const PostQueryKeys = {
  list: (filters?: TGetPostsParams) => ["posts", ...(filters !== undefined ? [filters] : [])],
  stats: (filters?: TGetPostStatsParams) => ["posts", "stats", ...(filters !== undefined ? [filters] : [])] as QueryKey,
  listVersions: (filters?: GetPostVersionParams) => ["posts", "version", ...(filters !== undefined ? [filters] : [])],
  versionDetails: ({ versionId, ...filters }: TGetPostVersionParams) => [
    "posts",
    "version",
    versionId,
    ...(filters !== undefined ? [filters] : []),
  ],
};

export const PaymentQueryKeys = {
  listInvoices: (filters?: TListInvoicesParams) => [
    "payments",
    "invoices",
    ...(filters !== undefined ? [filters] : []),
  ],
};

export const TodoQueryKeys = {
  list: (filters?: TGetTodosParams) => ["todos", ...(filters !== undefined ? [filters] : [])],
};

export const PostReviewCommentQueryKeys = {
  list: (filters?: TGetPostReviewCommentsParams) => [
    "post-review-comments",
    ...(filters !== undefined ? [filters] : []),
  ],
  details: ({ commentId, ...filters }: TPostReviewCommentDetailsParams) => [
    "post-review-comments",
    commentId,
    ...(filters !== undefined ? [filters] : []),
  ],
};

export const NotificationQueryKeys = {
  list: (filters?: TListNotificationsParams) => ["notifications", ...(filters !== undefined ? [filters] : [])],

  getUnreadCount: (filters?: TGetUnreadNotificationsCountParams) => [
    "notifications",
    "unread-count",
    ...(filters !== undefined ? [filters] : []),
  ],
};

export const InsightQueryKeys = {
  getInsights: (filters?: TGetInsightsParams) => ["insights", ...(filters !== undefined ? [filters] : [])] as QueryKey,
  getInsightPosts: (filters?: TGetInsightPostsParams) =>
    ["insights", "post", ...(filters !== undefined ? [filters] : [])] as QueryKey,
  getInsightConsumption: (filters?: TGetInsightConsumptionParams) =>
    ["insights", "consumption", ...(filters !== undefined ? [filters] : [])] as QueryKey,
  getInsightDemographic: (filters: TInsightDemographicParams) =>
    ["insights", "demographic", ...(filters !== undefined ? [filters] : [])] as QueryKey,
};
