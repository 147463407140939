import { useQuery, UseQueryOptions } from "react-query";
import { PostApi } from "services/api";
import { PostQueryKeys } from "services/query-keys";

export type TGetPostVersionParams = {
  versionId: string;
};

type GetPostsResult = Awaited<ReturnType<typeof PostApi.versionDetails>>;

const usePostVersion = (filters: TGetPostVersionParams, options?: UseQueryOptions<GetPostsResult>) => {
  return useQuery({
    queryKey: PostQueryKeys.versionDetails(filters),
    queryFn: async () => {
      const data = await PostApi.versionDetails(filters);

      return data;
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.versionId,
  });
};

export default usePostVersion;
