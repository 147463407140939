import { Modal, Input, Form, message, Select, Row, Button } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import { AVAILABLE_ROLES } from "types";
import { validateEmail } from "utils";

export default function InviteUser({
  visible,
  setVisible,
  accountId,
  next,
}: {
  accountId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  next: () => void;
}) {
  const queryClient = useQueryClient();

  const [inputValue, setInputValue] = useState({
    role: "",
    email: "",
  });

  const { mutate, isLoading } = useMutation(
    ({ accountId }: { accountId: string }) => {
      if (!inputValue.role || !inputValue.email) {
        throw new Error("Bitte fülle alle Felder aus");
      } else if (!validateEmail(inputValue.email)) {
        throw new Error("Ungültige E-Mail-Adresse");
      }

      return AccountApi.inviteUser({
        accountId,
        role: inputValue.role,
        email: inputValue.email,
      });
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: AccountQueryKeys.getUserInvites({ accountId: variables.accountId }),
        });
        message.success("Der Benutzer wurde erfolgreich eingeladen");
        setVisible(false);
        setInputValue({
          role: "",
          email: "",
        });
        next();
      },
    },
  );

  const handleCancel = () => {
    setVisible(false);
    setInputValue({
      email: "",
      role: "",
    });
  };

  return (
    <Modal title="Benutzer einladen" open={visible} footer={null} onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="E-Mail">
          <Input
            value={inputValue.email}
            onChange={(e) => setInputValue({ ...inputValue, email: e.target.value })}
            data-testid="user-email-field"
          />
        </Form.Item>

        <Form.Item label="Rolle">
          <Select
            placeholder="Rolle"
            value={inputValue.role}
            onChange={(value) => setInputValue({ ...inputValue, role: value })}
            data-testid="user-role-dropdown"
          >
            {Object.entries(AVAILABLE_ROLES).map(([roleKey, roleValue]) => (
              <Select.Option key={roleKey} value={roleValue} data-testid={`user-role-${roleValue.toLowerCase()}`}>
                {roleKey}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="end">
          <Button onClick={handleCancel} style={{ marginRight: 10 }}>
            Abbrechen
          </Button>
          <Button
            type="primary"
            onClick={() => mutate({ accountId })}
            loading={isLoading}
            disabled={!inputValue.role || !inputValue.email}
            data-testid="user-invite-btn"
          >
            Einladen
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
