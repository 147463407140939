import useAccounts from "hooks/useAccounts";
import { createContext, useContext } from "react";
import { Account } from "types";

export const OnboardingContext = createContext(
  undefined as unknown as {
    accounts: Account[] | undefined;
    currAccount: Account | undefined;
    isLoadingAccounts: boolean;
  },
);

export const OnboardingContextProvider = ({ children }: React.PropsWithChildren) => {
  const { data: accounts, isLoading } = useAccounts({
    refetchOnWindowFocus: false,
  });

  const currAccount = accounts?.[0];

  return (
    <OnboardingContext.Provider
      value={{
        accounts,
        currAccount,
        isLoadingAccounts: isLoading,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  const ctx = useContext(OnboardingContext);
  if (!ctx) throw new Error("useOnboardingContext can only be used within OnboardingContextProvider");

  return ctx;
};
