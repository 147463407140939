import { useMutation, useQueryClient } from "react-query";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";
import { useNavigate } from "@pankod/refine-react-router-v6";

import useSelectedAccount from "../useSelectedAccount";

export type DeleteCompanyResult = Awaited<ReturnType<typeof CompanyApi.delete>>;
export type TDeleteCompanyParams = { forceDelete?: boolean; companyId: string };

const useDeleteCompany = (options?: {
  onSuccess?: (data: DeleteCompanyResult, variables: TDeleteCompanyParams) => void | Promise<void>;
  onError?: (error: unknown, variables: TDeleteCompanyParams) => void | Promise<void>;
  onSettled?: (data: DeleteCompanyResult, error: unknown, variables: TDeleteCompanyParams) => void | Promise<void>;
}) => {
  const queryClient = useQueryClient();

  const { selectedCompanyId, selectedAccountId, pathWithoutPrefix } = useSelectedAccount();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: CompanyApi.delete,
    onSettled: options?.onSettled,
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey: CompanyQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: CompanyQueryKeys.details(variables.companyId) });

      if (variables.companyId === selectedCompanyId) {
        navigate(`/account/${selectedAccountId}/company/-${pathWithoutPrefix ?? "/dashboard"}`);
      }

      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
    onError: options?.onError,
  });
};

export default useDeleteCompany;
