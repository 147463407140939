import { UserAccessPolicy, UserAccessPolicyType } from "types";

export const getPolicyPermissionsInGerman = (policy: UserAccessPolicy): string[] => {
  const infoItems: string[] = [];
  switch (policy.policyType) {
    case UserAccessPolicyType.MANAGE_ALL:
      infoItems.push("Volle Kontrolle über das Konto zu haben");
      break;

    case UserAccessPolicyType.MANAGE_ACCOUNT:
      infoItems.push(
        ...[
          "Kontoinformationen zu verwalten",
          "Kontoabonnements zu verwalten",
          "Unternehmen innerhalb des Kontos zu verwalten",
          "Social Media Konten zu verbinden und zu verwalten",
        ],
      );
      break;

    case UserAccessPolicyType.VIEW_ACCOUNT:
      infoItems.push(
        ...[
          "Kontoinformationen anzuzeigen",
          "Kontoabonnements und -pläne anzuzeigen",
          "Unternehmen innerhalb des Kontos anzuzeigen",
          "Verbundene Social Media Konten anzuzeigen",
        ],
      );
      break;

    case UserAccessPolicyType.MANAGE_USER:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            "Kontoinformationen anzuzeigen",
            `Benutzer im Unternehmen „${policy.company.name}“ anzuzeigen`,
            `Benutzer im Unternehmen „${policy.company.name}“ hinzuzufügen oder zu entfernen`,
            `Berechtigungen der Benutzer im Unternehmen „${policy.company.name}“ zu verwalten`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...[
            "Kontoinformationen anzuzeigen",
            "Benutzer zum Konto einzuladen",
            "Benutzer in allen Unternehmen zu verwalten",
            "Berechtigungen der Benutzer im Konto und in den Unternehmen zu verwalten",
          ],
        );
      }
      break;

    case UserAccessPolicyType.VIEW_USER:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `Benutzer im Unternehmen „${policy.company.name}“ anzuzeigen`,
            `Berechtigungen der Benutzer im Unternehmen „${policy.company.name}“ anzuzeigen`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(...["Benutzer im Konto anzuzeigen", "Berechtigungen der Benutzer anzuzeigen"]);
      }
      break;

    case UserAccessPolicyType.MANAGE_TODO:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[`Aufgaben an Benutzer im Unternehmen „${policy.company.name}“ zu vergeben und zu verwalten`],
        );
      } else if (policy.account) {
        infoItems.push(...["Aufgaben an Benutzer im Konto und in den Unternehmen zu vergeben und zu verwalten"]);
      }
      break;

    case UserAccessPolicyType.VIEW_TODO:
      if (policy.companyId && policy.company) {
        infoItems.push(...[`Aufgaben im Unternehmen „${policy.company.name}“ anzuzeigen`]);
      } else if (policy.account) {
        infoItems.push(...["Aufgaben im Konto und in den Unternehmen anzuzeigen"]);
      }
      break;

    case UserAccessPolicyType.MANAGE_COMPANY:
      if (policy.companyId && policy.company) {
        infoItems.push(`Informationen des Unternehmens „${policy.company.name}“ zu verwalten`);
      } else if (policy.account) {
        infoItems.push("Informationen der Unternehmen im Konto zu verwalten");
      }
      break;

    case UserAccessPolicyType.VIEW_COMPANY:
      if (policy.companyId && policy.company) {
        infoItems.push(`Informationen des Unternehmens „${policy.company.name}“ anzuzeigen`);
      } else if (policy.account) {
        infoItems.push("Informationen der Unternehmen im Konto anzuzeigen");
      }
      break;

    case UserAccessPolicyType.MANAGE_CHANNEL:
      if (policy.companyId && policy.company) {
        infoItems.push(
          `Manage ${
            policy.channel ? `Das Social Media Konto „${policy.channel.name}“` : "Social Media Konten"
          } im Unternehmen „${policy.company.name}“ zu verwalten`,
        );
      } else {
        infoItems.push("Social Media Konten im Konto zu verbinden und zu verwalten");
      }
      break;

    case UserAccessPolicyType.VIEW_CHANNEL:
      if (policy.companyId && policy.company) {
        infoItems.push(
          `View ${
            policy.channel ? `Das Social Media Konto „${policy.channel.name}“` : "Social Media Konten"
          } im Unternehmen „${policy.company.name}“ anzuzeigen`,
        );
      } else {
        infoItems.push("Social Media Konten im Konto anzuzeigen");
      }
      break;

    case UserAccessPolicyType.MANAGE_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `Das Social Media Konto „${policy.channel.name}“` : "Social Media Konten"
            } im Unternehmen „${policy.company.name}“ anzuzeigen`,
            `Posts ${
              policy.channel ? `Für das Social Media Konto „${policy.channel.name}“` : "Für Social Media Konten"
            } im Unternehmen „${policy.company.name}“ zu verwalten`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...["Social Media Konten im Konto anzuzeigen", "Posts in den Unternehmen im Konto zu verwalten"],
        );
      }
      break;

    case UserAccessPolicyType.EDIT_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `Das Social Media Konto „${policy.channel.name}“` : "Social Media Konten"
            } im Unternehmen „${policy.company.name}“ anzuzeigen`,
            `Posts ${
              policy.channel ? `Für das Social Media Konto „${policy.channel.name}“` : "Für Social Media Konten"
            } im Unternehmen „${policy.company.name}“ zu bearbeiten`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...["Social Media Konten im Konto anzuzeigen", "Posts in den Unternehmen im Konto zu bearbeiten"],
        );
      }
      break;

    case UserAccessPolicyType.VIEW_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `Das Social Media Konto „${policy.channel.name}“` : "Social Media Konten"
            } im Unternehmen „${policy.company.name}“ anzuzeigen`,
            `Posts ${
              policy.channel ? `Für das Social Media Konto „${policy.channel.name}“` : "Für Social Media Konten"
            } im Unternehmen „${policy.company.name}“ anzuzeigen`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(...["Social Media Konten im Konto anzuzeigen", "Posts in den Unternehmen im Konto anzuzeigen"]);
      }
      break;

    case UserAccessPolicyType.REVIEW_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `Posts ${
              policy.channel ? `Für das Social Media Konto „${policy.channel.name}“` : "Für Social Media Konten"
            } im Unternehmen „${policy.company.name}“ zu genehmigen`,
          ],
        );
      } else if (policy.account) {
        infoItems.push("Posts in den Unternehmen im Konto zu genehmigen");
      }
      break;

    default:
      break;
  }
  return infoItems;
};

export const getPolicyPermissionsInEnglish = (policy: UserAccessPolicy): string[] => {
  const infoItems: string[] = [];
  switch (policy.policyType) {
    case UserAccessPolicyType.MANAGE_ALL:
      infoItems.push("Have full control over the account");
      break;

    case UserAccessPolicyType.MANAGE_ACCOUNT:
      infoItems.push(
        ...[
          "Manage account information",
          "Manage account subscriptions",
          "Manage companies within the account",
          "Connect and manage social media accounts",
        ],
      );
      break;

    case UserAccessPolicyType.VIEW_ACCOUNT:
      infoItems.push(
        ...[
          "View account information",
          "View account subscriptions and plans",
          "View companies within the account",
          "View connected social media accounts",
        ],
      );
      break;

    case UserAccessPolicyType.MANAGE_USER:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            "View account information",
            `View users within the company "${policy.company.name}"`,
            `Add or remove users from the company "${policy.company.name}"`,
            `Manage permissions of users within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...[
            "View account information",
            "Invite users to the account",
            "Manage users across all companies",
            "Manage permissions of users within the account and across the companies",
          ],
        );
      }
      break;

    case UserAccessPolicyType.VIEW_USER:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View users within the company "${policy.company.name}"`,
            `View permissions assigned to users within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(...["View users within the account", "View permissions assigned to the users"]);
      }
      break;

    case UserAccessPolicyType.MANAGE_TODO:
      if (policy.companyId && policy.company) {
        infoItems.push(...[`Manage and assign tasks to users in the company "${policy.company.name}"`]);
      } else if (policy.account) {
        infoItems.push(...["Manage and assign tasks to users within the account and across the companies"]);
      }
      break;

    case UserAccessPolicyType.VIEW_TODO:
      if (policy.companyId && policy.company) {
        infoItems.push(...[`View tasks within the company "${policy.company.name}"`]);
      } else if (policy.account) {
        infoItems.push(...["View tasks within the account and across the companies"]);
      }
      break;

    case UserAccessPolicyType.MANAGE_COMPANY:
      if (policy.companyId && policy.company) {
        infoItems.push(`Manage information of the company "${policy.company.name}"`);
      } else if (policy.account) {
        infoItems.push("Manage information of companies within the account");
      }
      break;

    case UserAccessPolicyType.VIEW_COMPANY:
      if (policy.companyId && policy.company) {
        infoItems.push(`View information of the company "${policy.company.name}"`);
      } else if (policy.account) {
        infoItems.push("View information of companies within the account");
      }
      break;

    case UserAccessPolicyType.MANAGE_CHANNEL:
      if (policy.companyId && policy.company) {
        infoItems.push(
          `Manage ${
            policy.channel ? `the social media account "${policy.channel.name}"` : "social media accounts"
          } within the company "${policy.company.name}"`,
        );
      } else {
        infoItems.push("Connect and manage social media accounts within the account");
      }
      break;

    case UserAccessPolicyType.VIEW_CHANNEL:
      if (policy.companyId && policy.company) {
        infoItems.push(
          `View ${
            policy.channel ? `the social media account "${policy.channel.name}"` : "social media accounts"
          } within the company "${policy.company.name}"`,
        );
      } else {
        infoItems.push("View social media accounts within the account");
      }
      break;

    case UserAccessPolicyType.MANAGE_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `the social media account "${policy.channel.name}"` : "social media accounts"
            } within the company "${policy.company.name}"`,
            `Manage posts ${
              policy.channel ? `for the social media account "${policy.channel.name}"` : "for social media accounts"
            } within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...["View social media accounts within the account", "Manage posts across companies within the account"],
        );
      }
      break;

    case UserAccessPolicyType.EDIT_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `the social media account "${policy.channel.name}"` : "social media accounts"
            } within the company "${policy.company.name}"`,
            `Edit posts ${
              policy.channel ? `for the social media account "${policy.channel.name}"` : "for social media accounts"
            } within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...["View social media accounts within the account", "Edit posts across companies within the account"],
        );
      }
      break;

    case UserAccessPolicyType.VIEW_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `View ${
              policy.channel ? `the social media account "${policy.channel.name}"` : "social media accounts"
            } within the company "${policy.company.name}"`,
            `View posts ${
              policy.channel ? `for the social media account "${policy.channel.name}"` : "for social media accounts"
            } within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push(
          ...["View social media accounts within the account", "View posts across companies within the account"],
        );
      }
      break;

    case UserAccessPolicyType.REVIEW_POST:
      if (policy.companyId && policy.company) {
        infoItems.push(
          ...[
            `Approve posts ${
              policy.channel ? `for the social media account "${policy.channel.name}"` : "for social media accounts"
            } within the company "${policy.company.name}"`,
          ],
        );
      } else if (policy.account) {
        infoItems.push("Approve posts across companies within the account");
      }
      break;

    default:
      break;
  }
  return infoItems;
};

export const getPolicyPermissions = (policy: UserAccessPolicy, langKey: "de" | "en" = "de"): string[] => {
  switch (langKey) {
    case "en":
      return getPolicyPermissionsInEnglish(policy);
    case "de":
    default:
      return getPolicyPermissionsInGerman(policy);
  }
};
