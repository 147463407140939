import { useState, useEffect, useRef } from "react";
import { Input, Select, DatePicker, TimePicker, Form, Row, Checkbox, Button, Col, message, Spin } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import { SelectValue } from "antd/lib/select";
import {
  OptionData,
  DescriptionOptionData,
  AdSetFormData,
  Account,
  TargetingDetail,
  Location,
  GOALS,
  CONVERSIONS,
} from "types";
import useQueryCacheData from "hooks/useQueryCacheData";
import { useMutation, useQuery } from "react-query";
import { CampaignApi, AdAccountsAPI } from "services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import countriesNames from "pages/Campaign/countries";
import locale from "antd/es/date-picker/locale/de_DE";
import classes from "../CampaignForm/CampaignForm.module.css";
import Map from "./Map";
import adClasses from "./CampaignAdSet.module.css";
import "../../../../../styles/selector.css";
import "../../../../../styles/modal.css";
import "../../../../../styles/icon.css";
import "../../../../../styles/input.css";
import Conversion from "./Conversion";
import "dayjs/locale/de";
import { getDisabledTime } from "utils/date";

const { Option } = Select;

const categoryType: { [key: string]: string } = {
  OUTCOME_AWARENESS: "bekanntheit",
  OUTCOME_TRAFFIC: "traffic",
  OUTCOME_ENGAGEMENT: "interaktionen",
  OUTCOME_LEADS: "leads",
  OUTCOME_SALES: "umsatz",
};

dayjs.locale("de_DE");
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

type LocationTypesOptionData = {
  label: string;
  description: string;
  value: string;
};

type location = {
  label: string;
  value: string;
  key: string;
  name: string;
  type: string;
  country_code: string;
  country_name: string;
  region: string | "";
  city: string | "";
};

type Coordinates = {
  lat: number;
  lng: number;
  radius: number;
};

const ageOptions: OptionData[] = [{ label: "18" }, { label: "65+" }];

const lockInOptions: DescriptionOptionData[] = [
  { label: "Inkludieren", value: "included" },
  { label: "Exkludieren", value: "excluded" },
];

const searchOptions: OptionData[] = [{ label: "Durchsuchen" }];

const budgetOptions: DescriptionOptionData[] = [
  {
    label: "Tagesbudget",
    value:
      "An einigen Tagen gibst du bis zu 6,25 € aus und an anderen weniger. Durchschnittlich belaufen sich deine Ausgaben auf 5,00 € pro Tag und maximal 35,00 € pro Kalenderwoche.",
  },
];

const targetGroupOptions: DescriptionOptionData[] = [
  {
    label: "Neue Zielgruppe ",
    value: "Verwende eine Custom Audience bzw. Lookalike Audience oder erstelle eine neue",
  },
];

const customAudienceOptions: DescriptionOptionData[] = [
  {
    label: "Neue Zielgruppe ",
    value: "Verwende eine Custom Audience bzw. Lookalike Audience oder erstelle eine neue",
  },
];

const selectedAreaOptions: LocationTypesOptionData[] = [
  {
    label: "Personen, die an diesem Ort wohnen oder kürzlich dort waren",
    description: "Personen, deren Wohnsitz oder letzter Standort sich in der ausgewählten Gegend befindet.",
    value: "",
  },
  {
    label: "Personen, die an diesem Ort wohnen",
    description: "Personen, deren Wohnsitz sich in der ausgewählten Gegend befindet.",
    value: "recent",
  },
  {
    label: "Personen, die kürzlich an diesem Ort waren",
    description: " Personen, deren letzter Standort sich in der ausgewählten Gegend befindet.",
    value: "home",
  },
  {
    label: "Personen, die diesen Ort besuchen",
    description:
      "Personen, deren letzter Standort sich in der ausgewählten Gegend befindet, deren Wohnort jedoch mehr als 125 mi/200 km entfernt liegt.",
    value: "travel_in",
  },
];

const genderOptions = [
  { label: "Alle", value: "All" },
  { label: "Männer", value: "Male" },
  { label: "Frauen", value: "Female" },
];

const socialMediaOptions = [
  { label: "Facebook", value: "facebook" },
  { label: "Instagram", value: "instagram" },
];

const getLocationsSearchValues = (countries: string[], cities: Location[], regions: Location[], zips: Location[]) => {
  const countriesOptions: location[] = countries.map((country) => ({
    label: countriesNames[country],
    value: country,
    type: "country",
    city: "",
    country_code: "",
    country_name: "",
    key: "",
    name: "",
    region: "",
  }));

  const citiesOptions: location[] = cities.map((city) => ({
    label: city.name,
    value: city.name,
    type: "city",
    key: city.key,
    city: "",
    country_code: "",
    country_name: "",
    name: "",
    region: "",
  }));

  const zipsOptions: location[] = zips.map((zip) => ({
    label: zip.name,
    value: zip.name,
    key: zip.key,
    type: "zip",
    city: "",
    country_code: "",
    country_name: "",
    name: "",
    region: "",
  }));

  const regionsOptions: location[] = regions.map((region) => ({
    label: region.name,
    value: region.name,
    key: region.key,
    type: "region",
    city: "",
    country_code: "",
    country_name: "",
    name: "",
    region: "",
  }));

  return [...countriesOptions, ...regionsOptions, ...citiesOptions, ...zipsOptions];
};

export default function CampaignAdSet() {
  const [form] = Form.useForm();
  const params = useRouterContext().useParams<{
    category: string;
    campaignId: string;
    adsetId: string;
  }>();
  const [category, setCategory] = useState<string>(params.category);
  const [conversion, setConversion] = useState("WEBSITE");
  const mounted = useRef(false);
  const [options, setOptions] = useState<location[]>([
    {
      city: "",
      country_code: "DE",
      country_name: "Deutschland",
      key: "DE",
      label: "Deutschland",
      name: "Deutschland",
      region: "",
      type: "country",
      value: "DE",
    },
  ]);
  const [dummyOptions, setDummyOptions] = useState<location[]>([
    {
      city: "",
      country_code: "DE",
      country_name: "Deutschland",
      key: "DE",
      label: "Deutschland",
      name: "Deutschland",
      region: "",
      type: "country",
      value: "DE",
    },
  ]);
  const [interestOptions, setInterestOptions] = useState<TargetingDetail[]>([]);
  const [dummyInterestOptions, setDummyInterestOptions] = useState<TargetingDetail[]>([]);
  const { push } = useRouterContext().useHistory();
  const [tncClose, setTncClose] = useState<boolean>(true);
  const [budgetOption, setBudgetOption] = useState<string>("Tagesbudget");
  const [targetGroupOption, setTargetGroupOption] = useState<SelectValue | undefined>(undefined);
  const [customAudienceOption, setCustomAudienceOption] = useState<SelectValue | undefined>(undefined);
  const [startAge, setStartAge] = useState<string>("18");
  const [endAge, setEndAge] = useState<string>("65+");
  const [selectedAreaOption, setSelectedAreaOption] = useState<string>("");
  const [optimizationGoal, setOptimizationGoal] = useState<string>("IMPRESSIONS");
  const [target, setTarget] = useState<string>("adinterest");
  const [showMap, setShowMap] = useState<boolean>(false);
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [includedLocations, setIncludedLocations] = useState<string[]>(["DE"]);
  const [excludedLocations, setExcludedLocations] = useState<string[]>([]);
  const [interests, setInterests] = useState<string[]>([]);
  const [lifeEvents, setLifeEvents] = useState<string[]>([]);
  const [educationSchool, setEducationSchool] = useState<string[]>([]);
  const [educationMajor, setEducationMajor] = useState<string[]>([]);
  const [workEmployer, setWorkEmployer] = useState<string[]>([]);
  const [workPosition, setWorkPosition] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [income, setIncome] = useState<string[]>([]);
  const [familyStatus, setFamilyStatus] = useState<string[]>([]);
  const [countryName, setCountryName] = useState<string>("");
  const [interestName, setInterestName] = useState<string>("");
  const [locationStatus, setLocationStatus] = useState<string>("included");
  const [bidAmount, setBidAmount] = useState<string>("");
  const [dailyBudget, setDailyBudget] = useState<string>("1");
  form.setFieldValue("dailyBudget", "1");
  const [markerPosition, setMarkerPosition] = useState<Coordinates[]>([]);
  const [remoteId, setRemoteId] = useState<string>("");
  const [startDate, setStartDate] = useState({
    date: dayjs().format("YYYY-MM-DD"),
    time: dayjs().format("HH:mm"),
  });
  const [endDate, setEndDate] = useState({
    date: dayjs().add(30, "day").format("YYYY-MM-DD"),
    time: dayjs().format("HH:mm"),
  });
  const [selectedGenders, setSelectedGenders] = useState<string[]>(["All", "Male", "Female"]);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<string[]>(["facebook"]);
  const [selectedFeed, setSelectedFeed] = useState<boolean>(true);
  const [selectedReels, setSelectedReels] = useState<boolean>(false);
  const [adSetFormData, setAdsetFormData] = useState<AdSetFormData>({
    accountId: accounts ? accounts[0].id : "",
    campaignId: params.campaignId,
    name: form.getFieldValue("adsetName") ?? `${category} Anzeigengruppe`,
    destination_type: conversion,
    optimization_goal: optimizationGoal,
    billing_event: "IMPRESSIONS",
    bid_amount: undefined,
    daily_budget: parseFloat(dailyBudget) * 100,
    targeting: {
      age_min: 18,
      age_max: 65,
      genders: [1, 2],
      geo_locations: {
        countries: ["DE"],
        regions: [],
        cities: [],
        zips: [],
        custom_locations: [],
        location_types: ["recent", "home"],
      },
      excluded_geo_locations: {
        countries: [],
        regions: [],
        cities: [],
        zips: [],
        custom_locations: [],
        location_types: ["home"],
      },
      publisher_platforms: ["facebook", "instagram"],
      facebook_positions: ["feed", "story", "facebook_reels"],
      instagram_positions: ["stream", "story", "reels"],
      flexible_spec: [
        {
          behaviors: [],
          interests: [],
          life_events: [],
          family_statuses: [],
          relationship_statuses: [],
          education_majors: [],
          education_schools: [],
          work_employers: [],
          work_positions: [],
          industries: [],
          income: [],
        },
      ],
      exclusions: {
        behaviors: [],
        interests: [],
        life_events: [],
        family_statuses: [],
        relationship_statuses: [],
        education_majors: [],
        education_schools: [],
        work_employers: [],
        work_positions: [],
        industries: [],
        income: [],
      },
      audience_network_positions: undefined,
      messenger_positions: undefined,
    },
    start_time: "",
    end_time: "",
    status: "ACTIVE",
    dsa_payor: "",
    dsa_beneficiary: "",
  });

  const targetsOptions = [
    {
      label: "Interessen",
      value: "adinterest",
      q: true,
      target: interests,
      testId: "interest",
    },
    {
      label: "Bildungsschule",
      value: "adeducationschool",
      q: true,
      target: educationSchool,
      testId: "educational-school",
    },
    {
      label: "Bildungsgrad",
      value: "adeducationmajor",
      q: true,
      target: educationMajor,
      testId: "educational-major",
    },
    {
      label: "Arbeitgeber",
      value: "adworkemployer",
      q: true,
      target: workEmployer,
      testId: "work-employer",
    },
    {
      label: "Jobposition",
      value: "adworkposition",
      q: true,
      target: workPosition,
      testId: "work-position",
    },
    {
      label: "Lebensereignisse",
      value: "life_events",
      q: false,
      target: lifeEvents,
      testId: "life-events",
    },
    {
      label: "Branchen",
      value: "industries",
      q: false,
      target: industries,
      testId: "industry-sectors",
    },
    { label: "Einkommen", value: "income", q: false, target: income, testId: "income" },
    {
      label: "Familienstatus",
      value: "family_statuses",
      q: false,
      target: familyStatus,
      testId: "marital-status",
    },
  ];

  useQuery("get-adsAccount", () => CampaignApi.getAdsAccountChannels(accounts[0].id), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (data[0]) {
        setRemoteId(data[0].remoteId);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const {
    isLoading: isSearchLoading,
    refetch,
    isFetching: isSearchFetching,
  } = useQuery("getlocation", () => CampaignApi.getLocationOnSearchMap(accounts[0].id, countryName), {
    enabled: !!countryName,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const newOptions: location[] = [];
      for (let i = 0; i < data.length; i += 1) {
        newOptions.push({
          value: data[i].type === "country" ? data[i].country_code : data[i].name,
          label:
            data[i].type === "zip"
              ? `${data[i].primary_city}, ${data[i].country_name}`
              : data[i].type === "country"
                ? `${data[i].name}`
                : `${data[i].name}, ${data[i].country_name}`,
          key: data[i].key,
          name: data[i].name,
          type: data[i].type,
          country_code: data[i].country_code,
          country_name: data[i].country_name,
          region: data[i].type === "region" ? "" : data[i].region,
          city: data[i].type === "zip" ? data[i].primary_city : "",
        });
      }
      setOptions(newOptions);
    },
    onError(error) {
      console.log(error);
    },
  });

  const {
    isLoading: isDetailsTargetingLoading,
    refetch: detailsTargetingReFetch,
    isFetching: isDetailsTargetingFetching,
  } = useQuery(
    "detailedTargeting",
    () => {
      return CampaignApi.getDetailedTargeting(accounts[0].id, target, interestName);
    },
    {
      enabled: !!interestName,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const newOptions: TargetingDetail[] = [];
        for (let i = 0; i < data.length; i += 1) {
          newOptions.push({
            id: data[i].id,
            name: data[i].name,
          });
        }
        setInterestOptions(newOptions);
      },
      onError(error: Error) {
        message.error(error.message);
      },
    },
  );

  const { refetch: getCampaignFetch } = useQuery(
    "getCampaign",
    () => CampaignApi.getCampaignDetails(accounts[0].id, params.campaignId),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCategory(categoryType[data.objective]);
      },
      onError(error) {
        console.log(error);
      },
    },
  );

  const {
    isLoading: isAdsetLoading,
    refetch: getAdsetFetch,
    isFetching: isAdsetFetching,
  } = useQuery("getAdset", () => CampaignApi.getAdsetDetails(accounts[0].id, params.adsetId), {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      form.setFieldValue("adsetName", data.name);
      setConversion(data.destination_type);
      setOptimizationGoal(data.optimization_goal);
      if (data.bid_amount) form.setFieldValue("bidAmount", data.bid_amount / 100);
      if (data.daily_budget !== undefined && data.daily_budget !== null) {
        form.setFieldValue("dailyBudget", (data.daily_budget / 100).toString());
        setDailyBudget((data.daily_budget / 100).toString());
      }
      setStartAge(data.targeting.age_min.toString());
      setEndAge(() => {
        if (data.targeting.age_max.toString() === "65") {
          return "65+";
        }
        return data.targeting.age_max.toString();
      });
      setSelectedGenders(() => {
        if (data.targeting.genders.length === 2) {
          return ["All", "Male", "Female"];
        }
        if (data.targeting.genders[0] === 1) {
          return ["Male"];
        }
        return ["Female"];
      });

      if (data.targeting.geo_locations) {
        const { countries, regions, cities, zips } = data.targeting.geo_locations;

        const allOptions = getLocationsSearchValues(countries, regions, cities, zips);
        setOptions(allOptions);
        setDummyOptions([...dummyOptions, ...allOptions]);

        const combinedIncludedLocations = [
          ...countries,
          ...regions.map((region) => region.name),
          ...cities.map((city) => city.name),
          ...zips.map((zip) => zip.name),
        ];
        setIncludedLocations(combinedIncludedLocations);
        setSelectedAreaOption(() => {
          if (data.targeting.geo_locations.location_types.length === 2) {
            return "";
          }
          return data.targeting.geo_locations.location_types[0];
        });
      }
      if (data.targeting.excluded_geo_locations) {
        const { countries, regions, cities, zips } = data.targeting.excluded_geo_locations;

        const allOptions = getLocationsSearchValues(countries, regions, cities, zips);
        setOptions(allOptions);
        setDummyOptions([...dummyOptions, ...allOptions]);

        const combinedExcludedLocations = [
          ...countries,
          ...regions.map((region) => region.name),
          ...cities.map((city) => city.name),
          ...zips.map((zip) => zip.name),
        ];
        setExcludedLocations(combinedExcludedLocations);
        setSelectedAreaOption(() => {
          if (data.targeting.geo_locations.location_types.length === 2) {
            return "";
          }
          return data.targeting.geo_locations.location_types[0];
        });
      }
      setSelectedSocialMedia(data.targeting.publisher_platforms);
      setSelectedFeed(() => {
        if (
          data.targeting.facebook_positions.includes("feed") ||
          data.targeting.instagram_positions.includes("stream")
        ) {
          return true;
        }
        return false;
      });
      setSelectedReels(() => {
        if (
          data.targeting.facebook_positions.includes("story") ||
          data.targeting.instagram_positions.includes("reels")
        ) {
          return true;
        }
        return false;
      });
      const {
        interests,
        education_majors,
        education_schools,
        work_employers,
        work_positions,
        life_events,
        industries,
        income,
        family_statuses,
      } = data.targeting.flexible_spec[0];
      const allOptions = [
        ...interests,
        ...education_majors,
        ...education_schools,
        ...work_employers,
        ...work_positions,
        ...life_events,
        ...industries,
        ...income,
        ...family_statuses,
      ];
      setInterestOptions(allOptions);
      setDummyInterestOptions(allOptions);
      setInterests(interests.map((obj) => obj.name));
      setEducationSchool(education_schools.map((obj) => obj.name));
      setEducationMajor(education_majors.map((obj) => obj.name));
      setWorkEmployer(work_employers.map((obj) => obj.name));
      setWorkPosition(work_positions.map((obj) => obj.name));
      setLifeEvents(life_events.map((obj) => obj.name));
      setIndustries(industries.map((obj) => obj.name));
      setIncome(income.map((obj) => obj.name));
      setFamilyStatus(family_statuses.map((obj) => obj.name));
      if (data.start_time) {
        form.setFieldValue("startDate", data.start_time);
        setStartDate({
          date: dayjs.utc(data.start_time).tz().format("YYYY-MM-DD"),
          time: dayjs.utc(data.start_time).tz().format("HH:mm"),
        });
      }
      if (data.end_time) {
        form.setFieldValue("endDate", data.end_time);
        setEndDate({
          date: dayjs.utc(data.end_time).tz().format("YYYY-MM-DD"),
          time: dayjs.utc(data.end_time).tz().format("HH:mm"),
        });
      }
      setAdsetFormData({
        accountId: data.accountId,
        ...(data.bid_amount && {
          bid_amount: data.bid_amount,
        }),
        billing_event: data.billing_event,
        campaignId: data.campaignId,
        destination_type: data.destination_type,
        end_time: data.end_time,
        name: data.name,
        optimization_goal: data.optimization_goal,
        start_time: data.start_time,
        status: data.status,
        targeting: data.targeting,
        daily_budget: data.daily_budget,
        ...(data.promoted_object && {
          promoted_object: data.promoted_object,
        }),
        dsa_payor: data.dsa_payor,
        dsa_beneficiary: data.dsa_beneficiary,
      });
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        targeting: {
          ...prevFormData.targeting,
          audience_network_positions: undefined,
          messenger_positions: undefined,
        },
      }));
    },
    onError(error) {
      console.log(error);
    },
  });

  const { mutate, isLoading } = useMutation(
    "Adset",
    (data: AdSetFormData) => {
      if (includedLocations.length === 0) {
        throw new Error("Wähle mindestens ein Land für deine Zielgruppe aus");
      }
      if (!startDate.time) {
        throw new Error("Bitte Startzeit eingeben");
      }
      if (!endDate.time) {
        throw new Error("Bitte Endzeit eingeben");
      }
      let adsetData: AdSetFormData = data;
      if (data.promoted_object?.page_id) {
        if (
          conversion === CONVERSIONS.ON_PAGE ||
          (category === GOALS.INTERACTIONS && conversion === CONVERSIONS.MESSENGER) ||
          category === GOALS.LEADS
        ) {
          adsetData = data;
        } else {
          // eslint-disable-next-line
          const { promoted_object, ...noPromotedAdsetData } = data;
          adsetData = noPromotedAdsetData;
        }
      }

      if (
        conversion === "PHONE_CALL" ||
        form.getFieldValue("bidAmount") === undefined ||
        form.getFieldValue("bidAmount") === ""
      ) {
        adsetData = {
          ...adsetData,
          bid_amount: null,
          bid_strategy: "LOWEST_COST_WITHOUT_CAP",
        };
      }
      if (form.getFieldValue("bidAmount")) {
        adsetData = {
          ...adsetData,
          bid_amount: parseFloat(form.getFieldValue("bidAmount")) * 100,
        };
      }

      if (params.adsetId) {
        return CampaignApi.updateCampaignAdset(adsetData, params.adsetId);
      }
      return CampaignApi.createCampaignAdset(adsetData);
    },
    {
      onSuccess: (data) => {
        if (params.adsetId) {
          message.success("Adset aktualisiert");
          push("/user/adset/dashboard");
        } else {
          message.success("Adset erstellt.");
          push(`/user/campaign/${category}/${params.campaignId}/adset/${data.id}/ad`);
        }
      },
      onError: (error: Error) => {
        setTncClose(true);
        message.error(error.message);
      },
    },
  );

  const { isLoading: getAccountsLoading, refetch: refetchAdAccounts } = useQuery(
    "adAccounts",
    () => AdAccountsAPI.getAll(accounts[0].id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        if (data.length !== 0)
          form.setFieldsValue({
            name: data[0].name,
            payor: data[0].default_dsa_payor,
            beneficiary: data[0].default_dsa_beneficiary,
            id: data[0].id,
          });
        setAdsetFormData((prevFormData) => ({
          ...prevFormData,
          dsa_payor: data[0].default_dsa_payor,
          dsa_beneficiary: data[0].default_dsa_beneficiary,
        }));
      },
    },
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (mounted.current) {
      timeout = setTimeout(() => {
        if (accounts && countryName) {
          refetch();
        }
      }, 1000);
    }
    mounted.current = true;

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryName]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (mounted.current) {
      timeout = setTimeout(() => {
        if (
          accounts &&
          interestName &&
          (target.includes("adinterest") ||
            target.includes("adeducationschool") ||
            target.includes("adeducationmajor") ||
            target.includes("adworkemployer") ||
            target.includes("adworkposition"))
        ) {
          detailsTargetingReFetch();
        } else if (
          accounts &&
          (interestName === "" || interestName) &&
          !target.includes("adinterest") &&
          !target.includes("adeducationschool") &&
          !target.includes("adeducationmajor") &&
          !target.includes("adworkemployer") &&
          !target.includes("adworkposition")
        ) {
          detailsTargetingReFetch();
        }
      }, 1000);
    }
    mounted.current = true;

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestName, target]);

  useEffect(() => {
    if (params.adsetId) {
      getAdsetFetch();
      getCampaignFetch();
    }
    if (category === GOALS.SALES || category === GOALS.INTERACTIONS || category === GOALS.NOTORIETY) {
      setConversion("UNDEFINED");
    }
    const currentDate = dayjs();
    const formattedStartDate = currentDate.format("YYYY-MM-DD HH:mm");
    const formattedEndDate = currentDate.add(30, "day").format("YYYY-MM-DD HH:mm");
    form.setFieldsValue({
      startDate: formattedStartDate,
    });
    form.setFieldsValue({
      endDate: formattedEndDate,
    });
    form.setFieldValue("adsetName", `${category} Anzeigengruppe`);
    if (localStorage.getItem("adsetName")) {
      form.setFieldValue("adsetName", localStorage.getItem("adsetName"));
      localStorage.removeItem("adsetName");
    }
    refetchAdAccounts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let genderArray = [1, 2];
    if (selectedGenders.length === 1 && selectedGenders[0] === "Male") {
      genderArray = [1];
    } else if (selectedGenders.length === 1 && selectedGenders[0] === "Female") {
      genderArray = [2];
    }
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        genders: genderArray,
      },
    }));
  }, [selectedGenders]);

  useEffect(() => {
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      optimization_goal: optimizationGoal,
    }));
  }, [optimizationGoal]);

  useEffect(() => {
    const destinationType = conversion;
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      destination_type: destinationType,
    }));
    if (
      conversion === CONVERSIONS.ON_PAGE ||
      (category === GOALS.INTERACTIONS && conversion === CONVERSIONS.MESSENGER) ||
      category === GOALS.LEADS
    ) {
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        promoted_object: { page_id: remoteId },
        targeting: {
          ...prevFormData.targeting,
          device_platforms: ["mobile", "desktop"],
        },
      }));
    }
    // eslint-disable-next-line
  }, [conversion, remoteId]);

  useEffect(() => {
    let facebook: string[] | [] = [];
    let instagram: string[] | [] = [];
    if (selectedSocialMedia.length === 2) {
      if (selectedFeed && selectedReels) {
        facebook = ["feed", "story", "facebook_reels"];
        instagram = ["stream", "story", "reels"];
      } else if (selectedFeed) {
        facebook = ["feed"];
        instagram = ["stream"];
      } else {
        facebook = ["story", "facebook_reels"];
        instagram = ["story", "reels"];
      }
    } else if (selectedSocialMedia.length === 1 && selectedSocialMedia.includes("facebook")) {
      if (selectedFeed && selectedReels) {
        facebook = ["feed", "story", "facebook_reels"];
      } else if (selectedFeed) {
        facebook = ["feed"];
      } else {
        facebook = ["story", "facebook_reels"];
      }
    } else if (selectedSocialMedia.length === 1 && selectedSocialMedia.includes("instagram")) {
      if (selectedFeed && selectedReels) {
        instagram = ["stream", "story", "reels"];
      } else if (selectedFeed) {
        instagram = ["stream"];
      } else {
        instagram = ["story", "reels"];
      }
    }
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        facebook_positions: facebook,
        instagram_positions: instagram,
      },
    }));
  }, [selectedFeed, selectedReels, selectedSocialMedia]);

  useEffect(() => {
    if (startDate.date && startDate.time && endDate.date && endDate.time) {
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        start_time: dayjs.tz(`${startDate.date}T${startDate.time}:00`).utc().format(),
        end_time: dayjs.tz(`${endDate.date}T${endDate.time}:00`).utc().format(),
      }));
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        age_min: parseInt(startAge, 10),
        age_max: parseInt(endAge, 10),
      },
    }));
  }, [startAge, endAge]);

  const handleGenderChange = (checkedValues: any) => {
    if (
      (checkedValues.length === 2 && checkedValues.includes("Male") && checkedValues.includes("All")) ||
      (checkedValues.length === 2 && checkedValues.includes("Female") && checkedValues.includes("All"))
    ) {
      setSelectedGenders(checkedValues[1]);
    } else if (checkedValues.includes("All")) {
      // If "Alle" is checked, select all options
      setSelectedGenders(["All", "Male", "Female"]);
    } else if (
      selectedGenders.includes("Male") &&
      selectedGenders.includes("Female") &&
      !checkedValues.includes("All") &&
      checkedValues.includes("Male") &&
      checkedValues.includes("Female")
    ) {
      // If All is unchecked, all will be unchecked
      setSelectedGenders([]);
    } else if (
      (selectedGenders.includes("Male") && checkedValues.includes("Female")) ||
      (selectedGenders.includes("Female") && checkedValues.includes("Male"))
    ) {
      // If male and female are selected individually, select all options
      setSelectedGenders(["All", "Male", "Female"]);
    } else {
      setSelectedGenders(checkedValues);
    }
  };

  const handleSocialMediaChange = (checkedValues: any) => {
    setSelectedSocialMedia(checkedValues);
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        publisher_platforms: checkedValues,
      },
    }));
  };

  const handleBudgetOptionChange = (value: string) => {
    setBudgetOption(value);
  };

  const handleStartAgeChange = (value: string) => {
    if (endAge && value > endAge) {
      if (value === "65") {
        setEndAge("65+");
      } else {
        setEndAge(value);
      }
    }
    setStartAge(value);
  };

  const handleEndAgeChange = (value: string) => {
    if (startAge && value < startAge) {
      setStartAge(value);
    }
    setEndAge(value);
  };

  const handleCustomAudienceOptionChange = (value: SelectValue) => {
    setCustomAudienceOption(value);
  };

  const handleChange = (value: string[], location: string) => {
    let newAdded = false;
    const dummyItem = options.find((item) => item.value === value[value.length - 1]);

    if (dummyItem && !dummyOptions.includes(dummyItem)) {
      newAdded = true;
      setDummyOptions((prevDummyOptions) => [...prevDummyOptions, dummyItem]);
    }

    let allLocations: string[] = [];
    let updatedCountry: string[] = [];
    let updatedCities: Location[] = [];
    let updatedRegion: Location[] = [];
    let updatedZips: Location[] = [];
    // eslint-disable-next-line
    value.map((option, index) => {
      let foundItem;
      if (newAdded) {
        foundItem =
          index !== value.length - 1
            ? dummyOptions.find((item) => item.value === option)
            : options.find((item) => item.value === option);
      } else {
        foundItem = dummyOptions.find((item) => item.value === option);
      }
      if (foundItem) {
        switch (foundItem.type) {
          case "country":
            updatedCountry = [...updatedCountry, foundItem.value];
            allLocations = [...allLocations, foundItem.value];
            break;
          case "city":
            updatedCities = [...updatedCities, { key: foundItem.key, name: foundItem.name }];
            allLocations = [...allLocations, foundItem.name];
            break;
          case "region":
            updatedRegion = [...updatedRegion, { key: foundItem.key, name: foundItem.name }];
            allLocations = [...allLocations, foundItem.name];
            break;
          case "zip":
            updatedZips = [...updatedZips, { key: foundItem.key, name: foundItem.name }];
            allLocations = [...allLocations, foundItem.name];
            break;
          default:
            break;
        }
      }
    });
    if (location === "included") {
      const updatedExcludedLocations = excludedLocations.filter(
        (excludedLocation) => !allLocations.includes(excludedLocation),
      );
      setExcludedLocations(updatedExcludedLocations);
      const updatedFormData = { ...adSetFormData };
      updatedFormData.targeting.excluded_geo_locations.countries =
        updatedFormData.targeting.excluded_geo_locations.countries.filter(
          (excludedCountry) => !updatedCountry.includes(excludedCountry),
        );
      updatedFormData.targeting.excluded_geo_locations.regions =
        updatedFormData.targeting.excluded_geo_locations.regions.filter(
          (excludedRegion) =>
            !updatedRegion.some(
              (updated) => updated.key === excludedRegion.key && updated.name === excludedRegion.name,
            ),
        );
      updatedFormData.targeting.excluded_geo_locations.cities =
        updatedFormData.targeting.excluded_geo_locations.cities.filter(
          (excludedCity) =>
            !updatedCities.some((updated) => updated.key === excludedCity.key && updated.name === excludedCity.name),
        );
      updatedFormData.targeting.excluded_geo_locations.zips =
        updatedFormData.targeting.excluded_geo_locations.zips.filter(
          (excludedZip) =>
            !updatedZips.some((updated) => updated.key === excludedZip.key && updated.name === excludedZip.name),
        );
      setAdsetFormData(updatedFormData);
      setIncludedLocations(allLocations);
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        targeting: {
          ...prevFormData.targeting,
          geo_locations: {
            ...prevFormData.targeting.geo_locations,
            countries: updatedCountry,
            regions: updatedRegion,
            cities: updatedCities,
            zips: updatedZips,
          },
        },
      }));
    } else {
      const updatedIncludedLocations = includedLocations.filter(
        (includedLocation) => !allLocations.includes(includedLocation),
      );
      setIncludedLocations(updatedIncludedLocations);
      const updatedFormData = { ...adSetFormData };
      updatedFormData.targeting.geo_locations.countries = updatedFormData.targeting.geo_locations.countries.filter(
        (includedCountry) => !updatedCountry.includes(includedCountry),
      );
      updatedFormData.targeting.geo_locations.regions = updatedFormData.targeting.geo_locations.regions.filter(
        (includedRegion) =>
          !updatedRegion.some((updated) => updated.key === includedRegion.key && updated.name === includedRegion.name),
      );
      updatedFormData.targeting.geo_locations.cities = updatedFormData.targeting.geo_locations.cities.filter(
        (includedCity) =>
          !updatedCities.some((updated) => updated.key === includedCity.key && updated.name === includedCity.name),
      );
      updatedFormData.targeting.geo_locations.zips = updatedFormData.targeting.geo_locations.zips.filter(
        (includedZip) =>
          !updatedZips.some((updated) => updated.key === includedZip.key && updated.name === includedZip.name),
      );
      setAdsetFormData(updatedFormData);
      setExcludedLocations(allLocations);
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        targeting: {
          ...prevFormData.targeting,
          excluded_geo_locations: {
            ...prevFormData.targeting.excluded_geo_locations,
            countries: updatedCountry,
            regions: updatedRegion,
            cities: updatedCities,
            zips: updatedZips,
          },
        },
      }));
    }
  };

  const handleInterestChange = (value: string[], currentTarget: string) => {
    let newAdded = false;
    const dummyItem = interestOptions.find((item) => item.name === value[value.length - 1]);

    if (dummyItem && !dummyInterestOptions.includes(dummyItem)) {
      newAdded = true;
      setDummyInterestOptions((prevDummyOptions) => [...prevDummyOptions, dummyItem]);
    }

    let dummyInterests: TargetingDetail[] | [] = [];
    let dummyEducationSchool: TargetingDetail[] | [] = [];
    let dummyEducationMajor: TargetingDetail[] | [] = [];
    let dummyWorkEmployer: TargetingDetail[] | [] = [];
    let dummyWorkPosition: TargetingDetail[] | [] = [];
    let dummyLifeEvents: TargetingDetail[] | [] = [];
    let dummyIndustries: TargetingDetail[] | [] = [];
    let dummyIncome: TargetingDetail[] | [] = [];
    let dummyFamilyStatuses: TargetingDetail[] | [] = [];
    let dummyBehaviors: TargetingDetail[] | [] = [];

    // eslint-disable-next-line
    value.map((option, index) => {
      let foundItem;
      if (newAdded) {
        foundItem =
          index !== value.length - 1
            ? dummyInterestOptions.find((item) => item.name === option)
            : interestOptions.find((item) => item.name === option);
      } else {
        foundItem = dummyInterestOptions.find((item) => item.name === option);
      }
      if (foundItem) {
        switch (currentTarget) {
          case "adinterest":
            dummyInterests = [
              ...dummyInterests,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "adeducationschool":
            dummyEducationSchool = [
              ...dummyEducationSchool,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "adeducationmajor":
            dummyEducationMajor = [
              ...dummyEducationMajor,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "adworkemployer":
            dummyWorkEmployer = [
              ...dummyWorkEmployer,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "adworkposition":
            dummyWorkPosition = [
              ...dummyWorkPosition,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "life_events":
            dummyLifeEvents = [
              ...dummyLifeEvents,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "industries":
            dummyIndustries = [
              ...dummyIndustries,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "income":
            dummyIncome = [
              ...dummyIncome,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          case "family_statuses":
            dummyFamilyStatuses = [
              ...dummyFamilyStatuses,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
          default:
            dummyBehaviors = [
              ...dummyBehaviors,
              {
                id: foundItem.id,
                name: foundItem.name,
              },
            ];
            break;
        }
      }
    });
    if (currentTarget === "adinterest") {
      setInterests(dummyInterests.map((obj) => obj.name));
    }
    if (currentTarget === "adeducationschool") {
      setEducationSchool(dummyEducationSchool.map((obj) => obj.name));
    }
    if (currentTarget === "adeducationmajor") {
      setEducationMajor(dummyEducationMajor.map((obj) => obj.name));
    }
    if (currentTarget === "adworkemployer") {
      setWorkEmployer(dummyWorkEmployer.map((obj) => obj.name));
    }
    if (currentTarget === "adworkposition") {
      setWorkPosition(dummyWorkPosition.map((obj) => obj.name));
    }
    if (currentTarget === "life_events") {
      setLifeEvents(dummyLifeEvents.map((obj) => obj.name));
    }
    if (currentTarget === "income") {
      setIncome(dummyIncome.map((obj) => obj.name));
    }
    if (currentTarget === "industries") {
      setIndustries(dummyIndustries.map((obj) => obj.name));
    }
    if (currentTarget === "family_statuses") {
      setFamilyStatus(dummyFamilyStatuses.map((obj) => obj.name));
    }
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        flexible_spec: [
          {
            behaviors: dummyBehaviors,
            ...(currentTarget === "adinterest"
              ? { interests: dummyInterests }
              : { interests: prevFormData.targeting.flexible_spec[0].interests }),
            ...(currentTarget === "life_events"
              ? { life_events: dummyLifeEvents }
              : { life_events: prevFormData.targeting.flexible_spec[0].life_events }),
            ...(currentTarget === "family_statuses"
              ? { family_statuses: dummyFamilyStatuses }
              : { family_statuses: prevFormData.targeting.flexible_spec[0].family_statuses }),
            relationship_statuses: [],
            ...(currentTarget === "adeducationmajor"
              ? { education_majors: dummyEducationMajor }
              : { education_majors: prevFormData.targeting.flexible_spec[0].education_majors }),
            ...(currentTarget === "adeducationschool"
              ? { education_schools: dummyEducationSchool }
              : { education_schools: prevFormData.targeting.flexible_spec[0].education_schools }),
            ...(currentTarget === "adworkemployer"
              ? { work_employers: dummyWorkEmployer }
              : { work_employers: prevFormData.targeting.flexible_spec[0].work_employers }),
            ...(currentTarget === "adworkposition"
              ? { work_positions: dummyWorkPosition }
              : { work_positions: prevFormData.targeting.flexible_spec[0].work_positions }),
            ...(currentTarget === "industries"
              ? { industries: dummyIndustries }
              : { industries: prevFormData.targeting.flexible_spec[0].industries }),
            ...(currentTarget === "income"
              ? { income: dummyIncome }
              : { income: prevFormData.targeting.flexible_spec[0].income }),
          },
        ],
      },
    }));
  };

  useEffect(() => {
    setAdsetFormData((prevFormData) => ({
      ...prevFormData,
      targeting: {
        ...prevFormData.targeting,
        geo_locations: {
          ...prevFormData.targeting.geo_locations,
          custom_locations: markerPosition,
        },
      },
    }));
  }, [markerPosition]);

  const handleSelectedAreaOptionChange = (value: string) => {
    let selectedArea: string[] = [];
    if (value === "") {
      selectedArea = ["recent", "home"];
    } else {
      selectedArea = [value];
    }
    setSelectedAreaOption(value);
    if (locationStatus === "included") {
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        targeting: {
          ...prevFormData.targeting,
          geo_locations: {
            ...prevFormData.targeting.geo_locations,
            location_types: selectedArea,
          },
        },
      }));
    } else {
      setAdsetFormData((prevFormData) => ({
        ...prevFormData,
        targeting: {
          ...prevFormData.targeting,
          excluded_geo_locations: {
            ...prevFormData.targeting.excluded_geo_locations,
            location_types: selectedArea,
          },
        },
      }));
    }
  };

  const handleTncClose = () => {
    setTncClose(false);
  };

  return (
    <div className="container">
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <h1 className="heading">Werbeanzeige erstellen</h1>
      </Row>
      <h2 style={{ fontWeight: "bold" }}>Anzeigengruppe</h2>
      <Spin spinning={isAdsetFetching || isAdsetLoading}>
        <Form
          form={form}
          labelAlign="left"
          labelWrap
          colon={false}
          onFinish={() => {
            mutate(adSetFormData);
          }}
        >
          <Col lg={14} md={24} sm={24} xs={24}>
            <Form.Item
              label="Name der Anzeigengruppe"
              name="adsetName"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Namen der Anzeigengruppe ein",
                },
              ]}
              labelCol={{
                lg: { offset: 0, span: 6 },
                md: { offset: 0, span: 24 },
                sm: { offset: 0, span: 24 },
                xs: { offset: 0, span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
            >
              <Input
                data-testid="ads-campaign-group-name"
                className={classes.campaignInput}
                onChange={(e) => {
                  form.setFieldValue("adsetName", e.target.value);
                  setAdsetFormData((prevFormData) => ({
                    ...prevFormData,
                    name: e.target.value,
                  }));
                }}
              />
            </Form.Item>
            {category ? (
              <Conversion
                form={form}
                category={category}
                currentConversion={conversion}
                setCurrentConversion={setConversion}
                bidAmount={bidAmount}
                setBidAmount={setBidAmount}
                optimizationGoal={optimizationGoal}
                setOptimizationGoal={setOptimizationGoal}
                tncClose={tncClose}
                handleTncClose={handleTncClose}
              />
            ) : (
              ""
            )}
            <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Budget und Zeitplan</h2>
            <Form.Item
              wrapperCol={{ span: 28, offset: 0 }}
              name="dailyBudget"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den tägliches Budget ein",
                },
                {
                  validator(rule, value, callback) {
                    if (parseFloat(dailyBudget) < 1) {
                      callback("Tagesbudget darf nicht weniger als 1 betragen.");
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <Select
                  data-testid="ads-campaign-dailyBudget-dropdown"
                  className={adClasses.budgetInput}
                  value={budgetOption}
                  onChange={(e) => handleBudgetOptionChange(e)}
                >
                  {budgetOptions.map((option, index) => (
                    <Option value={option.label} data-testid={`ads-campaign-dailyBudget-options-${index}`}>
                      <div style={{ whiteSpace: "break-spaces" }}>
                        <h4>{option.label}</h4>
                        <p>{option.value}</p>
                      </div>
                    </Option>
                  ))}
                </Select>
                <Input
                  data-testid="ads-campaign-dailyBuget-input"
                  className={adClasses.spendingAdSetInput}
                  value={dailyBudget}
                  style={{ width: "50%" }}
                  prefix="€"
                  type="number"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    const isValidInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                    if (isValidInput || inputValue === "") {
                      form.setFieldValue("dailyBudget", inputValue);
                      setDailyBudget(inputValue);
                      setAdsetFormData((prevFormData) => ({
                        ...prevFormData,
                        daily_budget: parseFloat(inputValue) * 100,
                      }));
                    }
                  }}
                />
              </div>
            </Form.Item>
            <br />
            <Form.Item
              label="Startdatum / Uhrzeit"
              name="startDate"
              labelCol={{
                lg: { offset: 0, span: 6 },
                md: { offset: 0, span: 24 },
                sm: { offset: 0, span: 24 },
                xs: { offset: 0, span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Startdatum ein",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  data-testid="ads-campaign-start-date-input"
                  format="DD.MM.YYYY"
                  className={adClasses.datePicker}
                  locale={locale}
                  disabledDate={(current) => current && current < dayjs().subtract(1, "day")}
                  value={params.campaignId && startDate.date ? dayjs(startDate.date, "YYYY-MM-DD") : undefined}
                  onChange={(_, dateStr) => {
                    const startDateValue = dateStr ? dayjs(dateStr as string, "DD.MM.YYYY").format("YYYY-MM-DD") : "";

                    if (endDate.date && startDateValue && dayjs(startDateValue).isAfter(endDate.date)) {
                      const newEndDate = dayjs(startDateValue).add(30, "day").format("YYYY-MM-DD");
                      form.setFieldValue("endDate", { date: newEndDate });
                      setEndDate({ ...endDate, date: newEndDate });
                    }

                    form.setFieldValue("startDate", { date: startDateValue });
                    setStartDate({ ...startDate, date: startDateValue });
                  }}
                />
                <TimePicker
                  data-testid="ads-campaign-start-time-input"
                  className={adClasses.timePicker}
                  locale={locale}
                  format="HH:mm"
                  disabledTime={() => getDisabledTime(startDate.date)}
                  value={
                    params.campaignId && startDate.time
                      ? dayjs(
                          `${(startDate.date ? dayjs(startDate.date) : dayjs()).format("YYYY-MM-DD")}T${
                            startDate.time
                          }:00`,
                        )
                      : undefined
                  }
                  onChange={(_, timeStr) => {
                    form.setFieldValue("endDate", {
                      time: timeStr,
                    });
                    setStartDate({
                      ...startDate,
                      time: timeStr as string,
                    });
                  }}
                  placeholder="Zeit auswählen"
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Enddatum / Uhrzeit"
              name="endDate"
              labelCol={{
                lg: { offset: 0, span: 6 },
                md: { offset: 0, span: 24 },
                sm: { offset: 0, span: 24 },
                xs: { offset: 0, span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Enddatum ein",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  data-testid="ads-campaign-end-date-input"
                  // style={{ marginLeft: 48 }}
                  format="DD.MM.YYYY"
                  className={adClasses.datePicker}
                  locale={locale}
                  disabledDate={(current) =>
                    (current && current < dayjs().subtract(1, "day")) ||
                    current < dayjs(startDate.date, "YYYY-MM-DD").add(1, "day")
                  }
                  value={params.campaignId && endDate.date ? dayjs(endDate.date, "YYYY-MM-DD") : undefined}
                  onChange={(_, dateStr) => {
                    form.setFieldValue("endDate", {
                      date: dateStr ? dayjs(dateStr as string, "DD.MM.YYYY").format("YYYY-MM-DD") : "",
                    });
                    setEndDate({
                      ...endDate,
                      date: dateStr ? dayjs(dateStr as string, "DD.MM.YYYY").format("YYYY-MM-DD") : "",
                    });
                  }}
                />
                <TimePicker
                  data-testid="ads-campaign-end-time-input"
                  className={adClasses.timePicker}
                  format="HH:mm"
                  locale={locale}
                  disabledTime={() => getDisabledTime(endDate.date)}
                  value={
                    params.campaignId && endDate.time
                      ? dayjs(`${dayjs(endDate.date ? endDate.date : dayjs()).format("YYYY-MM-DD")}T${endDate.time}:00`)
                      : undefined
                  }
                  onChange={(_, timeStr) => {
                    form.setFieldValue("endDate", {
                      time: timeStr,
                    });
                    setEndDate({
                      ...endDate,
                      time: timeStr as string,
                    });
                  }}
                  placeholder="Zeit auswählen"
                />
              </div>
            </Form.Item>
            <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Zielgruppen</h2>
            <Form.Item
              label="Zielgruppen wählen"
              labelCol={{
                lg: { span: 6 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Anzeigengruppe ein",
                },
              ]}
            >
              <Select
                data-testid="ads-campaign-target-group-dropdown"
                className={classes.categoryInput}
                defaultValue={targetGroupOptions[0].label}
                value={targetGroupOption}
              >
                {targetGroupOptions.map((option, index) => (
                  <Option value={index.toString()}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>{option.label}</h4>
                      <p>{option.value}</p>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <div style={{ marginTop: 25 }}>
                    <h2 style={{ fontWeight: 'bold' }}>Custom Audiences</h2>
                    <span style={{ marginTop: '10px' }}>einschließen: Personen, die zu mindestens einer der folgenden Zielgruppen gehören Erreiche Personen, die mit deinem Unternehmen oder ähnlichen Unternehmen interagiert haben.</span>
                </div>
                <div style={{ marginTop: 25 }}>
                    <Select
                        className={adClasses.lockInput}
                        showSearch
                        size="middle"
                        placeholder=""
                        // onChange={(e) => handleChange(e)}
                        // onSearch={(e) => {
                        //     setCountryName(e);
                        // }}
                        options={customAudienceOptions}
                        // filterOption={filterOption}
                        style={{ width: '100%' }}
                        notFoundContent={null}
                    />
                </div> */}
            <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Alter</h2>
            <Form.Item
              label="Alter"
              labelCol={{
                lg: { span: 6 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Anzeigengruppe ein",
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Select
                  data-testid="ads-campaign-age-dropdown1"
                  value={startAge}
                  onChange={handleStartAgeChange}
                  style={{
                    background: "rgb(220, 217, 217)",
                    width: "100px",
                    border: "none",
                    borderRadius: "0",
                    marginRight: 30,
                  }}
                >
                  {Array.from({ length: 48 }, (_, index) => {
                    const age = index + 18;
                    const label = age ? `${age}` : "";
                    return (
                      <Option key={index.toString()} value={label}>
                        <div style={{ whiteSpace: "break-spaces" }}>
                          <h4>{label}</h4>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
                <span style={{ marginRight: 30 }}> - </span>
                <Select
                  data-testid="ads-campaign-age-dropdown2"
                  value={endAge}
                  onChange={handleEndAgeChange}
                  style={{
                    background: "rgb(220, 217, 217)",
                    width: "100px",
                    border: "none",
                    borderRadius: "0",
                    marginRight: 30,
                  }}
                >
                  {Array.from({ length: 48 }, (_, index) => {
                    const age = index + 18;
                    const label = age < 65 ? `${age}` : "65+";
                    return (
                      <Option key={index.toString()} value={label}>
                        <div style={{ whiteSpace: "break-spaces" }}>
                          <h4>{label}</h4>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Form.Item>
            <div style={{ margin: "25px 0" }}>
              <h2 style={{ fontWeight: "bold" }}>Standort</h2>
              <span style={{ marginTop: "10px" }}>
                Wähle welche Personen du an welchen Standorten ansprechen möchtest.
              </span>
            </div>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Select
                data-tetid="ads-campaign-location-dropdown"
                className={adClasses.areaInput}
                value={selectedAreaOption}
                onChange={handleSelectedAreaOptionChange}
              >
                {selectedAreaOptions.map((option, index) => (
                  <Option data-testid={`ads-campaign-location-dropdown-options-${index}`} value={option.value}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>{option.label}</h4>
                      <p>{option.description}</p>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 25,
              }}
            >
              <div
                style={{
                  width: "30%",
                  minWidth: "30%",
                  color: "rgba(0, 0, 0, 0.88)",
                }}
              >
                <span>{lockInOptions[0].label}</span>
              </div>
              <Select
                data-testid="ads-campaign-includes-location-input"
                onClick={() => {
                  setLocationStatus(lockInOptions[0].value);
                }}
                value={includedLocations}
                className={adClasses.lockInput}
                showSearch
                mode="multiple"
                size="middle"
                placeholder="Standort suchen"
                onSearch={(e) => {
                  setCountryName(e);
                }}
                onChange={(e) => handleChange(e, "included")}
                filterOption={(input, option) => {
                  const label = typeof option?.key === "string" ? option.key : "";
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                style={{ width: "100%", marginRight: 0 }}
                notFoundContent={
                  !isSearchLoading &&
                  !isSearchFetching && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                      </span>
                    </div>
                  )
                }
              >
                {isSearchLoading || isSearchFetching ? (
                  <span>suchen...</span>
                ) : (
                  options.map((option) => (
                    <Option key={option?.label} value={option?.value}>
                      <div>
                        <h4>{option?.label}</h4>
                      </div>
                    </Option>
                  ))
                )}
              </Select>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 25 }}>
              <div
                data-testid="ads-campaign-excludes-location-input"
                style={{
                  width: "30%",
                  minWidth: "30%",
                  color: "rgba(0, 0, 0, 0.88)",
                }}
              >
                <span>{lockInOptions[1].label}</span>
              </div>
              <Select
                onClick={() => {
                  setLocationStatus(lockInOptions[1].value);
                }}
                value={excludedLocations}
                className={adClasses.lockInput}
                showSearch
                mode="multiple"
                size="middle"
                placeholder="Standort suchen"
                onSearch={(e) => {
                  setCountryName(e);
                }}
                onChange={(e) => handleChange(e, "")}
                filterOption={(input, option) => {
                  const label = typeof option?.key === "string" ? option.key : "";
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                style={{ width: "100%", marginRight: 0 }}
                notFoundContent={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span>
                      <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                    </span>
                  </div>
                }
              >
                {isSearchLoading || isSearchFetching ? (
                  <span>suchen...</span>
                ) : (
                  options.map((option) => (
                    <Option key={option?.label} value={option?.value}>
                      <div>
                        <h4>{option?.label}</h4>
                      </div>
                    </Option>
                  ))
                )}
              </Select>
            </div>
            <div style={{ position: "absolute", right: 0, marginTop: 10 }}>
              <Button
                style={{
                  color: "blue",
                  background: "white",
                  border: "none",
                  padding: 0,
                }}
                onClick={() => setShowMap(!showMap)}
              >
                Durchsuchen
              </Button>
            </div>
            {showMap ? (
              <div style={{ marginTop: 40 }}>
                <Map markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} />
              </div>
            ) : (
              ""
            )}
            <div style={{ marginTop: 45 }}>
              <h2 style={{ fontWeight: "bold" }}>Geschlecht</h2>
              <Checkbox.Group
                data-testid="ads-campaign-gender-all-checkbox"
                options={genderOptions}
                value={selectedGenders}
                onChange={handleGenderChange}
              />
            </div>
            <div style={{ marginTop: 25, marginBottom: 25 }}>
              <h2 style={{ fontWeight: "bold" }}>Detailliertes Targeting</h2>
              <span style={{ marginTop: 10 }}>Personen einschließen, die übereinstimmen mit.</span>
            </div>
            {targetsOptions.map((item) => (
              <Form.Item
                label={item.label}
                labelCol={{
                  lg: { span: 6 },
                  md: { span: 24 },
                  sm: { span: 24 },
                  xs: { span: 24 },
                }}
                wrapperCol={{
                  lg: { span: 18, offset: 1 },
                  md: { span: 24, offset: 0 },
                  sm: { span: 24, offset: 0 },
                  xs: { span: 24, offset: 0 },
                }}
              >
                <Select
                  data-testid={`ads-campaign-${item.testId}-input`}
                  onClick={() => {
                    if (item.value !== target) {
                      setInterestOptions([]);
                      setTarget(item.value);
                    }
                  }}
                  value={item.target}
                  onFocus={() => setInterestName("")}
                  className={adClasses.lockInput}
                  showSearch
                  placeholder="suchen"
                  mode="multiple"
                  size="middle"
                  onChange={(e) => handleInterestChange(e, item.value)}
                  onSearch={(e) => {
                    setInterestName(e);
                  }}
                  style={{ width: "100%", marginRight: 0 }}
                  notFoundContent={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                      </span>
                    </div>
                  }
                >
                  {isDetailsTargetingLoading || isDetailsTargetingFetching ? (
                    <span>suchen...</span>
                  ) : (
                    interestOptions.map((option) => (
                      <Option key={option.id} value={option.name}>
                        <div>
                          <h4>{option.name}</h4>
                        </div>
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            ))}
            <div style={{ marginTop: 25 }}>
              <h2 style={{ fontWeight: "bold" }}>Zahlender und Begünstigter</h2>
              <span style={{ marginTop: "10px" }}>
                Informationen zum Begünstigten und Zahler sind für Anzeigengruppen mit Zielgruppen in der Europäischen
                Union erforderlich und werden in den Anzeigenkontoeinstellungen gespeichert. Diese Informationen sind
                ein Jahr lang in der Meta-Anzeigenbibliothek öffentlich verfügbar, werden jedoch nicht in Anzeigen
                angezeigt
              </span>
            </div>
            <br />
            <Form.Item
              labelCol={{
                lg: { span: 6 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              label="Zahlender"
              name="payor"
              tooltip={{
                placement: "bottom",
                title: "Der Zahler ist die Person oder Organisation, die für die Anzeige bezahlt",
              }}
            >
              <Input
                data-testid="settings-first-name-field"
                className={adClasses.spendingAdSetInput}
                onChange={(e) => {
                  setAdsetFormData((prevFormData) => ({
                    ...prevFormData,
                    dsa_payor: e.target.value,
                  }));
                }}
              />
            </Form.Item>
            <Form.Item
              labelCol={{
                lg: { span: 6 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 1 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              label="Begünstigter"
              name="beneficiary"
              tooltip={{
                placement: "bottom",
                title: "Der Zahlende ist die Person oder Organisation, die für die Anzeige bezahlt",
              }}
            >
              <Input
                data-testid="settings-first-name-field"
                className={adClasses.spendingAdSetInput}
                onChange={(e) => {
                  setAdsetFormData((prevFormData) => ({
                    ...prevFormData,
                    dsa_beneficiary: e.target.value,
                  }));
                }}
              />
            </Form.Item>
            <div style={{ marginTop: 25 }}>
              <h2 style={{ fontWeight: "bold" }}>Platzierungen</h2>
              <span style={{ marginTop: "10px" }}>Personen einschließen, die übereinstimmen mit.</span>
            </div>
            <div style={{ marginTop: 20 }} className={adClasses.checkboxContainer}>
              <Checkbox.Group
                data-testid="ads-camapign-placements-facebook-checkbox"
                options={socialMediaOptions}
                value={selectedSocialMedia}
                onChange={handleSocialMediaChange}
              />
            </div>
            <div style={{ marginTop: 25, display: "flex", flexDirection: "column" }}>
              <h2 style={{ fontWeight: "bold" }}>Detaillierte Ausspielung</h2>
              <Checkbox
                data-testid="ads-campaign-playout-checkbox1"
                checked={selectedFeed}
                onChange={() => setSelectedFeed(!selectedFeed)}
                style={{ color: "black" }}
              >
                In den Beiträgen / Feeds
              </Checkbox>
              <span style={{ marginTop: 5, marginBottom: 5, marginLeft: 25 }}>
                Platziere Anzeigen in Feeds, um für eine hohe Sichtbarkeit deines Unternehmens zu sorgen.
              </span>
              <Checkbox
                data-testid="ads-campaign-playout-checkbox2"
                checked={selectedReels}
                onChange={() => setSelectedReels(!selectedReels)}
                style={{ color: "black" }}
              >
                In den Storys und Reels
              </Checkbox>
              <span style={{ marginTop: 5, marginLeft: 25 }}>
                Erzähle eine optisch ansprechende Story mit immersiven Anzeigen im vertikalen Vollbildformat.
              </span>
            </div>
            <div
              style={{
                position: "absolute",
                right: 0,
                marginTop: 10,
                paddingBottom: 20,
              }}
            >
              <Button
                data-testid="ads-campaign-back-btn"
                type="default"
                className={classes.createButton}
                onClick={() => {
                  if (params.adsetId) {
                    push("/user/adset/dashboard");
                  } else {
                    push(`/user/campaign/${category}`);
                  }
                }}
              >
                Zurück
              </Button>
              <Button
                data-testid="ads-campaign-weiter-btn"
                className={classes.furtherButton}
                loading={isLoading}
                type="primary"
                htmlType="submit"
              >
                Weiter
              </Button>
            </div>
          </Col>
        </Form>
      </Spin>
    </div>
  );
}
