import { useEffect, useState } from "react";
import { SelectValue } from "antd/lib/select";
import { GoalProps, OptionData, Account, GOALS } from "types";
import type { RadioChangeEvent } from "antd";
import { CloseOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { CampaignApi } from "services/api";
import { useQuery } from "react-query";
import { Select, Radio, Input, Form, Button, Space, Alert, Card } from "antd";
import useQueryCacheData from "hooks/useQueryCacheData";
import classes from "../CampaignAdSet.module.css";
import { conversionTypesBasedonGoals, performanceTypesBasedonGoals, performanceTypesForNotoriety } from "./constants";

const { Option } = Select;

type ValueOptionData = {
  label: string;
  value: string;
};

export default function Conversion({
  form,
  category,
  currentConversion,
  setCurrentConversion,
  bidAmount,
  setBidAmount,
  optimizationGoal,
  setOptimizationGoal,
  tncClose,
  handleTncClose,
}: GoalProps) {
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [pixelOption, setPixelOption] = useState<string>("");
  const [messageValue, setMessageValue] = useState(1);
  const [facebookSiteOption, setFacebookSiteOption] = useState<string>("");
  const [instagramSiteOption, setInstagramSiteOption] = useState<string>("");
  const [facebookSiteOptions, setFacebookSiteOptions] = useState<ValueOptionData>({
    label: "",
    value: "",
  });
  const [instagramSiteOptions, setInstagramSiteOptions] = useState<ValueOptionData>({
    label: "",
    value: "",
  });

  const { refetch: getAdsChannel } = useQuery(
    "get-adsAccount",
    () => CampaignApi.getAdsAccountChannels(accounts[0].id),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (data[0] && data.length === 1) {
          if (data[0].type === "FACEBOOK") {
            setFacebookSiteOptions({
              label: data[0].name,
              value: data[0].remoteId,
            });
            setFacebookSiteOption(data[0].remoteId);
            // setInstagramActorId(data[0].instagramActorId);
            // setInstagramSiteOption(data[0].instagramActorId);
          } else if (data[0].type === "INSTAGRAM") {
            setInstagramSiteOptions({
              label: data[1].name,
              value: data[1]?.instagramActorId || "",
            });
            setInstagramSiteOption(data[1].instagramActorId);
          }
        }
        if (data[0] && data.length > 1) {
          if (data[0].type === "FACEBOOK") {
            setFacebookSiteOptions({
              label: data[0].name,
              value: data[0].remoteId,
            });
            setFacebookSiteOption(data[0].remoteId);
            // setInstagramActorId(data[0].instagramActorId);
          } else {
            setInstagramSiteOptions({
              label: data[0].name,
              value: data[0]?.instagramActorId || "",
            });
            setInstagramSiteOption(data[0].instagramActorId);
          }
        }
        if (data[1] && data.length > 1) {
          if (data[1].type === "INSTAGRAM") {
            setInstagramSiteOptions({
              label: data[1].name,
              value: data[1]?.instagramActorId || "",
            });
            setInstagramSiteOption(data[1].instagramActorId);
          } else {
            setFacebookSiteOptions({
              label: data[1].name,
              value: data[1].remoteId,
            });
            setFacebookSiteOption(data[1].remoteId);
            // setInstagramActorId(data[1].instagramActorId);
          }
        }
      },
      onError(error) {
        console.log(error);
      },
    },
  );

  const handleCategoryOptionChange = (value: SelectValue) => {
    if (typeof value === "string") {
      setOptimizationGoal(value);
    }
  };

  const handleFacebookSiteOptionChange = (value: string) => {
    setFacebookSiteOption(value);
  };

  const handleInstagramSiteOptionChange = (value: string) => {
    setInstagramSiteOption(value);
  };

  const onChange = (e: RadioChangeEvent) => {
    if (messageValue === 1) {
      localStorage.setItem("isSponsored", "true");
    } else {
      localStorage.setItem("isSponsored", "false");
    }
    setMessageValue(e.target.value);
  };
  const pixelOptions: OptionData[] = [{ label: "Pixel auswählen" }];

  useEffect(() => {
    const filteredOptions =
      category === "bekanntheit"
        ? performanceTypesForNotoriety
        : performanceTypesBasedonGoals[category]
          ? performanceTypesBasedonGoals[category][currentConversion]
          : [];

    if (filteredOptions && filteredOptions.length > 0) {
      setOptimizationGoal(filteredOptions[0].value);
    }
    // eslint-disable-next-line
  }, [currentConversion]);

  useEffect(() => {
    console.log("clicked");
    getAdsChannel();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {category === "bekanntheit" ? (
        <div>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Performance-Ziel</h2>
            <span style={{ marginTop: 10 }}>
              Wenn du eine Seite mit deinen Anzeigen bewirbst, kannst du auch ein Performance-Ziel festlegen. Dein
              Performance-Ziel – früher Optimierungsziel für Anzeigenauslieferung genannt – hilft uns zu verstehen, wie
              du den Erfolg deiner Werbung misst. Infos zur AnzeigenauslieferungWenn du das Performance-Ziel für diese
              Kampagne änderst, sind bestimmte Platzierungen nicht mehr verfügbar.
            </span>
          </div>
          <br />
          <Form.Item
            label="Performance-Ziel"
            labelCol={{
              lg: { span: 4 },
              md: { span: 24 },
              sm: { span: 24 },
              xs: { span: 24 },
            }}
            wrapperCol={{
              lg: { span: 18, offset: 3 },
              md: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              xs: { span: 24, offset: 0 },
            }}
          >
            <Select
              data-testid="ads-campaign-performanceTarget-dropdown"
              className={classes.categoryInput}
              value={optimizationGoal}
              onChange={handleCategoryOptionChange}
            >
              {performanceTypesForNotoriety.map((option, index) => (
                <Option value={option.value} data-testid={`ads-campaign-performanceTarget-options-${index}`}>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{option.label}</h4>
                    <span>{option.description}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Konten</h2>
          <Form.Item
            label="Facebookseite"
            labelCol={{
              lg: { span: 4 },
              md: { span: 24 },
              sm: { span: 24 },
              xs: { span: 24 },
            }}
            wrapperCol={{
              lg: { span: 18, offset: 3 },
              md: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              xs: { span: 24, offset: 0 },
            }}
          >
            <Select
              className={classes.categoryInput}
              value={facebookSiteOption}
              onChange={handleFacebookSiteOptionChange}
            >
              <Option value={facebookSiteOptions.value}>
                <div style={{ whiteSpace: "break-spaces" }}>
                  <h4>{facebookSiteOptions.label}</h4>
                </div>
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Gebotslimit: optional"
            name="bidAmount"
            labelCol={{
              lg: { span: 4 },
              md: { span: 24 },
              sm: { span: 24 },
              xs: { span: 24 },
            }}
            wrapperCol={{
              lg: { span: 18, offset: 3 },
              md: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              xs: { span: 24, offset: 0 },
            }}
            rules={[
              {
                validator(rule, value, callback) {
                  if (value && value < 1) {
                    callback("Das Gebotslimit muss mindestens 1 betragen");
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input
              data-testid="ads-campaign-bid-limit-input"
              onChange={(e) => {
                const inputValue = e.target.value;

                const isValidInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                if (isValidInput || inputValue === "") {
                  form.setFieldValue("bidAmount", inputValue);
                }
              }}
              prefix="€"
              type="number"
              className={classes.spendingAdSetInput}
            />
          </Form.Item>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Conversion</h2>
            <span style={{ marginTop: 10 }}>
              Wähle aus, wo du mehr Traffic generieren möchtest. Du kannst später weitere Details zum Ziel eingeben.
            </span>
          </div>
          <br />
          <Form.Item
            label="Conversion-Ort"
            labelCol={{
              lg: { span: 4 },
              md: { span: 24 },
              sm: { span: 24 },
              xs: { span: 24 },
            }}
            wrapperCol={{
              lg: { span: 18, offset: 3 },
              md: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              xs: { span: 24, offset: 0 },
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {conversionTypesBasedonGoals[category]?.map((conversion) => (
                <>
                  <Radio
                    checked={currentConversion === conversion.value}
                    onClick={() => {
                      setCurrentConversion(conversion.value);
                    }}
                    style={{ display: "block", color: "black" }}
                  >
                    {conversion.label}
                  </Radio>
                  <div style={{ margin: "5px 25px" }}>
                    <span>{conversion.description}</span>
                  </div>
                </>
              ))}
            </div>
          </Form.Item>
          {category === GOALS.INTERACTIONS && currentConversion === "MESSENGER" ? (
            <div style={{ marginTop: 25 }}>
              <h2 style={{ fontWeight: "bold" }}>Art der Werbeanzeige</h2>
              <span style={{ marginTop: 10 }}>
                Wähle die Art der Werbeanzeige, die du schalten möchtest. Click-to-Message Ads starten neue
                Unterhaltungen. Werbliche Nachrichten sprechen bereits vorhandene Kontakte erneut an.
              </span>
              <br />
              <Radio.Group onChange={onChange} value={messageValue}>
                <Space direction="vertical">
                  <Radio className={classes.RadioButton} value={1}>
                    Click to message
                  </Radio>
                  <Radio className={classes.RadioButton} value={2}>
                    Werbliche Nachricht
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          ) : (
            ""
          )}
          <br />
          <Form.Item
            label="Performance-Ziel"
            labelCol={{
              lg: { span: 5 },
              md: { span: 24 },
              sm: { span: 24 },
              xs: { span: 24 },
            }}
            wrapperCol={{
              lg: { span: 18, offset: 2 },
              md: { span: 24, offset: 0 },
              sm: { span: 24, offset: 0 },
              xs: { span: 24, offset: 0 },
            }}
          >
            <Select
              data-testid="ads-campaign-performanceTarget-dropdown"
              className={classes.categoryInput}
              value={optimizationGoal}
              onChange={handleCategoryOptionChange}
              disabled={performanceTypesBasedonGoals[category][currentConversion]?.length <= 1}
            >
              {performanceTypesBasedonGoals[category][currentConversion]?.map((option, index) => (
                <Option data-testid={`ads-campaign-performanceTarget-options-${index}`} value={option.value}>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{option.label}</h4>
                    <span>{option.description}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          {(category === GOALS.INTERACTIONS || category === GOALS.LEADS) && currentConversion === "MESSENGER" ? (
            <Form.Item
              label="Gebotslimit : optional"
              name="bidAmount"
              labelCol={{
                lg: { span: 4 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 3 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  validator(rule, value, callback) {
                    console.log(value);
                    if (value && value < 1) {
                      callback("Das Gebotslimit muss mindestens 1 betragen");
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <Input
                data-testid="ads-campaign-bid-limit-input"
                onChange={(e) => {
                  const inputValue = e.target.value;

                  const isValidInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                  if (isValidInput || inputValue === "") {
                    form.setFieldValue("bidAmount", inputValue);
                  }
                }}
                prefix="€"
                type="number"
                className={classes.spendingAdSetInput}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label="Kostenziel pro Ergebnis∙ Optional"
              name="bidAmount"
              labelCol={{
                lg: { span: 4 },
                md: { span: 24 },
                sm: { span: 24 },
                xs: { span: 24 },
              }}
              wrapperCol={{
                lg: { span: 18, offset: 3 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              rules={[
                {
                  validator(rule, value, callback) {
                    if (value && value < 1) {
                      callback("Das Kostenziel pro Ergebnis muss mindestens 1 betragen");
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              {currentConversion !== "PHONE_CALL" && (
                <Input
                  data-testid="ads-campaign-bid-limit-input"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    const isValidInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                    if (isValidInput || inputValue === "") {
                      form.setFieldValue("bidAmount", inputValue);
                    }
                  }}
                  prefix="€"
                  type="number"
                  className={classes.spendingAdSetInput}
                />
              )}
            </Form.Item>
          )}
          <div style={{ marginTop: 25 }}>
            {currentConversion === "PHONE_CALL" ? (
              <span>
                Meta versucht, dein gesamtes Budget zu nutzen und mithilfe der Gebotsstrategie „Größtes Volumen“ den
                maximalen Wert für Ergebnisse zu erzielen.
              </span>
            ) : (
              <span>
                Meta wird nach Möglichkeit dein gesamtes Budget nutzen und mithilfe der Gebotsstrategie „Größtes
                Volumen“ die meisten Link-Klicks erzielen. Wenn die durchschnittlichen Kosten pro Ergebnis nicht höher
                sein dürfen als ein bestimmter Betrag, gib ein Kostenziel pro Ergebnis ein.
              </span>
            )}
          </div>
          {category === GOALS.LEADS ? (
            <>
              <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Konten</h2>
              <Form.Item
                label="Facebookseite"
                labelCol={{
                  lg: { span: 4 },
                  md: { span: 24 },
                  sm: { span: 24 },
                  xs: { span: 24 },
                }}
                wrapperCol={{
                  lg: { span: 18, offset: 3 },
                  md: { span: 24, offset: 0 },
                  sm: { span: 24, offset: 0 },
                  xs: { span: 24, offset: 0 },
                }}
              >
                <Select
                  className={classes.categoryInput}
                  value={facebookSiteOption}
                  onChange={handleFacebookSiteOptionChange}
                >
                  <Option value={facebookSiteOptions.value}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>{facebookSiteOptions.label}</h4>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
              {instagramSiteOptions.value && (
                <Form.Item
                  labelCol={{
                    lg: { span: 4 },
                    md: { span: 24 },
                    sm: { span: 24 },
                    xs: { span: 24 },
                  }}
                  wrapperCol={{
                    lg: { span: 18, offset: 3 },
                    md: { span: 24, offset: 0 },
                    sm: { span: 24, offset: 0 },
                    xs: { span: 24, offset: 0 },
                  }}
                  label="Instagramseite"
                >
                  <Select
                    className={classes.categoryInput}
                    value={instagramSiteOption}
                    onChange={handleInstagramSiteOptionChange}
                  >
                    <Option value={instagramSiteOptions.value}>
                      <div style={{ whiteSpace: "break-spaces" }}>
                        <h4>{instagramSiteOptions.label}</h4>
                      </div>
                    </Option>
                  </Select>
                </Form.Item>
              )}
              {tncClose && (
                <>
                  <Alert
                    style={{ borderRadius: 0 }}
                    message="Nutzungsbedingungen nicht akzeptiert: Sie können keine Lead-Anzeigen schalten, es sei denn, Ihre Facebook-Seite akzeptiert die Nutzungsbedingungen für die Facebook-Lead-Generierung."
                    type="error"
                  />
                  <Card
                    style={{
                      padding: 10,
                      marginTop: 10,
                      borderRadius: 0,
                      background: "#fff",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <p>
                        Bitte lesen und akzeptieren Sie die Lead Ads-Bedingungen von Meta, bevor Sie Ihre Anzeige für
                        diese Seite erstellen.
                      </p>
                      <Button
                        style={{
                          background: "none",
                          padding: 0,
                          alignSelf: "baseline",
                          color: "gray",
                        }}
                        icon={<CloseOutlined />}
                        onClick={handleTncClose}
                      />
                    </div>
                    <div>
                      <Button
                        className={classes.TncButton}
                        onClick={() => window.open("https://www.facebook.com/ads/leadgen/tos", "_blank")}
                      >
                        Zeige Bedingungen
                      </Button>
                    </div>
                  </Card>
                </>
              )}
            </>
          ) : (
            ""
          )}
          {currentConversion === "WHATSAPP" ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{
                  background: "rgb(220, 217, 217)",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 8px",
                }}
              >
                <WhatsAppOutlined style={{ fontSize: "24px", marginRight: "8px" }} />
                WhatsApp jetzt verknüpfen
              </Button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
