import { useAuthenticated } from "@pankod/refine-core";
import useMe from "hooks/useMe";
import { createContext, useMemo } from "react";
import { UserAccessPolicyType } from "types";

type AdminContextType = {
  isPlatformAdmin: boolean;
  isLoading: boolean;
};

export const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { isSuccess, isLoading } = useAuthenticated();

  const { data: me, isLoading: isLoadingMe } = useMe(undefined, {
    enabled: isSuccess,
  });

  const isPlatformAdmin = useMemo(
    () => (me ? me.accessPolicies.some((policy) => policy.policyType === UserAccessPolicyType.MANAGE_PLATFORM) : false),
    [me],
  );

  return (
    <AdminContext.Provider value={{ isPlatformAdmin, isLoading: isLoading || isLoadingMe }}>
      {children}
    </AdminContext.Provider>
  );
};
