import { Button, Form, FormInstance, FormProps, Input, Row, Select, message } from "antd";
import { AxiosError } from "axios";
import useCreateCompany from "hooks/company/useCreateCompany";
import useQueryCacheData from "hooks/useQueryCacheData";
import { useTranslation } from "react-i18next";
import { User } from "types";

type CompanyFormInitialValues = {
  name: string;
  requiresPostReview: boolean | undefined | null;
  profilePic: string | undefined | null;
  userIds: string[] | undefined | null;
  channelIds: string[] | undefined | null;
};

const companyFormInitialValues = {
  name: "",
  requiresPostReview: undefined,
  profilePic: undefined,
  userIds: undefined,
  channelIds: undefined,
};

const CompanyForm = ({
  onCancel,
  onFinish,
  disabled = false,
  isSubmitting = false,
  form,
  initialValues,
}: FormProps & {
  onCancel: () => void;
  isSubmitting: boolean;
  form: FormInstance;
  initialValues: CompanyFormInitialValues;
}) => {
  const { t } = useTranslation(["common", "pages"]);
  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues} disabled={disabled}>
      <Form.Item label={t("labels.name")} name="name" rules={[{ required: true, whitespace: true }]}>
        <Input name="name" data-testid="company-name-field" maxLength={40} />
      </Form.Item>

      <Form.Item label={t("pages:postingApproval.title")} name="requiresPostReview">
        <Select
          style={{ flex: 1 }}
          options={[
            { value: true, label: t("pages:postingApproval.requiredLabel") },
            { value: false, label: t("pages:postingApproval.notRequiredLabel") },
          ]}
        />
      </Form.Item>

      <Row justify="end">
        <Button onClick={onCancel} style={{ marginRight: 10 }}>
          {t("buttons.discard")}
        </Button>
        <Button type="primary" htmlType="submit" loading={isSubmitting} data-testid="user-invite-btn">
          {t("buttons.save")}
        </Button>
      </Row>
    </Form>
  );
};

export const CreateCompanyForm = ({ onCancel, accountId }: { onCancel: () => void; accountId: string | undefined }) => {
  const { t } = useTranslation(["common", "pages"]);
  const [form] = Form.useForm();

  const me = useQueryCacheData<User>("me");

  const { mutate: createCompany, isLoading: isCreatingCompany } = useCreateCompany({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      onCancel();
      message.success(t("pages:companies.createSuccessMessage"));
    },
  });

  const formDisabled = isCreatingCompany;

  return (
    <CompanyForm
      form={form}
      initialValues={{ ...companyFormInitialValues, userIds: [me.id] }}
      onFinish={(data) => {
        data.name = data.name.trim();
        if (!accountId) {
          message.error(t("pages:companies.emptyAccountError"));
          return;
        }
        createCompany({ company: { name: data.name.trim(), requiresPostReview: data.requiresPostReview }, accountId });
      }}
      onCancel={onCancel}
      disabled={formDisabled}
      isSubmitting={isCreatingCompany}
    />
  );
};
