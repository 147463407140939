import { Link, useNavigate, useSearchParams } from "@pankod/refine-react-router-v6";
import { Button, Flex, Select } from "antd";
import useAccounts from "hooks/useAccounts";
import useSelectedAccount from "hooks/useSelectedAccount";
import useCompanies from "hooks/company/useCompanies";
import { useState } from "react";
import useMe from "hooks/useMe";
import NoDataLocaleTable from "components/NoData";
import Logout from "components/Logout";
import { PlusCircleOutlined } from "@ant-design/icons";
import CustomAvatar from "components/CustomAvatar";
import MobileLogo from "../../assets/EPL Logo White.png";
import classes from "./index.module.css";

const avatarColors = [
  "hsl(225, 80%, 40%)", // Blue
  "hsl(26, 90%, 50%)", // Orange
  "hsl(271, 80%, 40%)", // Purple
  "hsl(125, 85%, 45%)", // Teal
  "hsl(166, 80%, 40%)", // Green
]
  //Randomize colors
  .sort(() => Math.random() - 0.5);

const SelectAccount = () => {
  const { data: accounts } = useAccounts();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { selectedAccountId } = useSelectedAccount();
  const { data: me } = useMe();

  const [accountId, setAccountId] = useState(selectedAccountId ?? "");

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isError: isGettingCompaniesError,
  } = useCompanies(
    { accountId, userIds: me ? [me.id] : [] },
    {
      selectFn: (res) => res.items,
      enabled: !!accountId && !!me,
    },
  );

  const targetPath = searchParams.get("to") ?? "/dashboard";

  return (
    <>
      <Logout style={{ position: "fixed", top: 15, right: 15 }} />
      <div className={`container ${classes.page}`}>
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <div className={classes.accountSelectContainer}>
              <div className={classes.cardHeaderLogo}>
                <img src={MobileLogo} />
              </div>
              <div className={classes.accountSelectWrapper}>
                <label className={classes.cardHelperText} htmlFor="account-select">
                  Konto auswählen
                </label>
                <Select
                  id="account-select"
                  className={classes.accountSelect}
                  value={accountId}
                  onChange={setAccountId}
                  variant="outlined"
                  labelRender={({ value, label }) => {
                    return (
                      <span className={classes.accountSelectLabel}>
                        {value && (
                          <CustomAvatar size="small" style={{ background: "hsl(0, 90%, 50%)" }}>
                            {(label && typeof label === "string" ? label[0] : "E") ?? "E"}
                          </CustomAvatar>
                        )}
                        <span
                          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "pre", maxWidth: "100%" }}
                        >
                          {value ? label : "Wähle ein Konto"}
                        </span>
                      </span>
                    );
                  }}
                  options={accounts?.map((account) => ({ value: account.id, label: account.name }))}
                />
              </div>
            </div>
            <span className={classes.cardTitle}>Wähle ein Unternehmen</span>
          </div>

          {companies?.length ? (
            <ul className={classes.companyList}>
              {companies.map((company, idx) => {
                return (
                  <li className={classes.avatarListItem} key={company.id}>
                    <Link
                      to={`/account/${accountId}/company/${company.id}${targetPath}`}
                      className={classes.avatarLink}
                    >
                      <CustomAvatar
                        src={company.profilePic}
                        alt={`${company.name ?? "Unternehmen"}'s profile picture`}
                        style={{ background: avatarColors[idx % avatarColors.length] }}
                      >
                        {company.name[0] ?? "E"}
                      </CustomAvatar>
                      <span className={classes.avatarLinkText} style={{ fontSize: "1rem" }}>
                        {company.name}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          ) : isLoadingCompanies ? (
            <ul className={`${classes.companyList} ${classes.skeletonList}`}>
              <li className={classes.skeletonListItem} />
              <li className={classes.skeletonListItem} />
              <li className={classes.skeletonListItem} />
              <li className={classes.skeletonListItem} />
              <li className={classes.skeletonListItem} />
            </ul>
          ) : (
            <Flex vertical align="center">
              {NoDataLocaleTable.emptyText}
              <span className={classes.cardBodyMessage}>
                {isGettingCompaniesError ? (
                  "Unternehmen konnten nicht geladen werden"
                ) : accountId ? (
                  <Button
                    type="default"
                    icon={<PlusCircleOutlined style={{ fontWeight: 700, fontSize: 14 }} />}
                    onClick={() => navigate(`/account/${accountId}/company/-/settings/companies`)}
                  >
                    Erstelle ein Unternehmen
                  </Button>
                ) : (
                  "Wähle ein Konto aus, um Unternehmen anzuzeigen"
                )}
              </span>
            </Flex>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectAccount;
