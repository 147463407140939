import { ForcedSubject, subject } from "@casl/ability";
import { CaslPrismaModels, CaslPrismaSubjectType } from "types/access";

export type CaslSubjectModelParams = {
  accountId: string;
  userId?: string;
  companyId?: string;
  channelId?: string;
  assignedToId?: string;
};

/**
 * This function takes subject type and parameters, create a dummy subject instance and forcefully
 * assign that subject instance to the subject type and return the object.
 *
 * @param subjectType This represents the className (or entityName) wrt to permission need to be checked
 * @param params This contains the parameters wrt to user access policy is being saved and then checked
 * @returns a casl subject instance (aka. subjectModel) assigned to the `subjectType`
 */
export const makeSubject = <T extends keyof CaslPrismaModels = CaslPrismaSubjectType>(
  subjectType: T,
  params: CaslSubjectModelParams,
): CaslPrismaModels[T] & ForcedSubject<T> => subject(subjectType, getSubjectModel(subjectType, params));

/**
 * This function takes the subjectType and parameters and generate a dummy object instance
 * on which permission can be checked
 *
 * @param subjectType This represents the className (or entityName) wrt to permission need to be checked
 * @param params This contains the parameters wrt to user access policy is being saved and then checked
 * @returns Returns the object (entity instance) corresponding to the `subjectType` to check permission
 *
 * TODO: Update this function as we go through the modules as for putting now it requires changes
 * in the types which would futher need changes in the pages also
 */
export const getSubjectModel = <T extends keyof CaslPrismaModels = CaslPrismaSubjectType>(
  subjectType: T,
  params: CaslSubjectModelParams,
): CaslPrismaModels[T] => {
  const { userId, accountId, companyId, channelId, assignedToId } = params;
  switch (subjectType) {
    case "User":
      return {
        accountUsers: [{ accountId }],
        ...(userId && { id: userId }),
        ...(companyId && { companyIds: [companyId] }),
      };
    case "Account":
      return {
        id: accountId,
      };
    case "AccountUser":
      return {
        accountId,
      };
    case "AccountSubscription":
      return {
        accountId,
      };
    case "Company":
      return {
        accountId,
        users: [{ accountUsers: [{ accountId }] }],
        channels: [{ accountId, ...(channelId && { id: channelId }) }],
        ...(companyId && { id: companyId }),
        ...(channelId && { channelIds: [channelId] }),
      };
    case "UserAccessPolicy":
      return {
        accountId,
        ...((userId ?? accountId ?? companyId) && {
          user: {
            ...(userId && { id: userId }),
            ...(accountId && { accountUsers: [{ accountId }] }),
            ...(companyId && { companyIds: [companyId] }),
          },
        }),
        ...(companyId && { companyId }),
      };
    case "UserInvite":
      return {
        accountId,
      };
    case "Channel":
      return {
        accountId,
        ...(companyId && { companyIds: [companyId] }),
        ...(channelId && { id: channelId }),
      };
    case "Post":
      return {
        accountId,
        ...(companyId && { companyId }),
        ...(channelId && { channelId }),
      };
    case "PostVersion":
      return {
        post: {
          accountId,
          ...(companyId && { companyId }),
          ...(channelId && { channelId }),
        },
      };
    case "PostReviewComment":
      return {
        post: {
          accountId,
          ...(companyId && { companyId }),
          ...(channelId && { channelId }),
        },
      };
    case "Todo":
      return {
        accountId,
        ...(companyId && { companyId }),
        ...(assignedToId && { assignedTo: assignedToId }),
        ...(userId && { createdBy: userId }),
        assignee: { accountUsers: [{ accountId }], companyIds: [companyId] },
        creator: { accountUsers: [{ accountId }] },
      };

    default:
      return {};
  }
};
