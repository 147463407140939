import useInsightPosts from "hooks/Insight/useInsightPosts";
import useSelectedAccount from "hooks/useSelectedAccount";
import { FacebookPostMetrics, InstagramPostMetrics } from "types";
import { useMemo } from "react";
import { formatDate } from "utils/date";
import CustomTable from "components/CustomTable";
import formatNumber from "utils/formatNumber";
import { usePagination } from "hooks/usePagination";
import { useTranslation } from "react-i18next";
import FacebookColored from "../../../assets/icons/socials/facebookColored.svg";
import InstagramColored from "../../../assets/icons/socials/instagramColored.svg";
import classes from "./index.module.css";

const sumValues = (values: { value: number }[] | undefined) =>
  values ? values.reduce((acc, curr) => acc + curr.value, 0) : 0;

const StatsContent = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const paginationState = usePagination(10);

  const { data: insightPosts, isLoading: isLoadingInsightPosts } = useInsightPosts({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [FacebookPostMetrics.post_clicks, FacebookPostMetrics.post_impressions_unique],
    instagram_metrics: [
      InstagramPostMetrics.reach,
      InstagramPostMetrics.likes,
      InstagramPostMetrics.comments,
      InstagramPostMetrics.shares,
    ],
    take: paginationState.take,
    skip: paginationState.skip,
  });

  const tableData = useMemo(() => {
    return insightPosts?.items.map((item) => {
      const reach =
        sumValues(
          item.insights.find((insight) => insight.name === FacebookPostMetrics.post_impressions_unique)?.values,
        ) + sumValues(item.insights.find((insight) => insight.name === InstagramPostMetrics.reach)?.values);
      const likes = sumValues(item.insights.find((insight) => insight.name === InstagramPostMetrics.likes)?.values);
      const comments = sumValues(
        item.insights.find((insight) => insight.name === InstagramPostMetrics.comments)?.values,
      );
      const shares = sumValues(item.insights.find((insight) => insight.name === InstagramPostMetrics.shares)?.values);
      const clicks = sumValues(
        item.insights.find((insight) => insight.name === FacebookPostMetrics.post_clicks)?.values,
      );

      return {
        ...item,
        formattedCreatedAt: formatDate(item.createdAt),
        formattedPostedAt: item.currentVersion.postedAt ? formatDate(item.currentVersion.postedAt) : 0,
        reach: reach ? formatNumber(reach) : 0,
        likes: likes ? formatNumber(likes) : 0,
        comments: comments ? formatNumber(comments) : 0,
        shares: shares ? formatNumber(shares) : 0,
        clicks: clicks ? formatNumber(clicks) : 0,
      };
    });
  }, [insightPosts]);

  return (
    <div className={classes.container}>
      <CustomTable
        defaultProps={{
          dataSource: tableData,
          loading: isLoadingInsightPosts,
          columns: [
            {
              title: t("pages:statsContent.postLabel"),
              align: "left",
              className: classes.columnHeader,
              render: (_, record) => {
                const primeAsset = record.currentVersion.content?.assets[0];

                return (
                  <div className={classes.motivContainer}>
                    {record.currentVersion.thumbnailUrl ? (
                      <img src={record.currentVersion.thumbnailUrl} alt="motiv" className={classes.motivImage} />
                    ) : primeAsset ? (
                      primeAsset?.url.toLowerCase().endsWith(".mp4") ? (
                        <video src={primeAsset.url} />
                      ) : (
                        <img src={primeAsset.url} alt="motiv" className={classes.motivImage} />
                      )
                    ) : null}
                    <p className={classes.motivText}>{record.currentVersion.content?.body}</p>
                  </div>
                );
              },
            },
            {
              dataIndex: "channel",
              title: t("pages:statsContent.platformLabel"),
              align: "left",
              className: classes.columnHeader,
              render: (_, record) => (
                <div className={classes.platformContainer}>
                  <img
                    className={classes.platformIcon}
                    src={record.channel.type === "FACEBOOK" ? FacebookColored : InstagramColored}
                    alt={record.channel.type === "FACEBOOK" ? "Facebook Logo" : "Instagram Logo"}
                  />
                  <p className={classes.platformText}>{record.channel.name}</p>
                </div>
              ),
            },
            {
              title: t("pages:statsContent.reachLabel"),
              align: "left",
              className: classes.statColumnHeader,
              render: (_, record) => <div className={classes.statColumn}>{record.reach}</div>,
            },
            {
              title: t("pages:statsContent.linkClicksLabel"),
              align: "left",
              className: classes.statColumnHeader,
              render: (_, record) => <div className={classes.statColumn}>{record.clicks}</div>,
            },
            {
              title: t("pages:statsContent.likesLabel"),
              align: "left",
              className: classes.statColumnHeader,
              render: (_, record) => <div className={classes.statColumn}>{record.likes}</div>,
            },
            {
              title: t("pages:statsContent.commentsLabel"),
              align: "left",
              className: classes.statColumnHeader,
              render: (_, record) => <div className={classes.statColumn}>{record.comments}</div>,
            },
            {
              title: t("pages:statsContent.sharesLabel"),
              align: "left",
              className: classes.statColumnHeader,
              render: (_, record) => <div className={classes.statColumn}>{record.shares}</div>,
            },
            {
              title: t("pages:statsContent.postedAtLabel"),
              align: "left",
              className: classes.columnHeader,
              render: (_, record) => <div className={classes.column}>{record.formattedPostedAt}</div>,
            },
          ],
        }}
        paginationProps={{ ...paginationState, total: insightPosts?.total }}
      />
    </div>
  );
};

export default StatsContent;
