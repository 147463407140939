import { useState, useEffect } from "react";
import { QueryObserver, useQueryClient } from "react-query";

export default function useQueryCacheData<T>(queryKey: string | unknown[]) {
  const queryClient = useQueryClient();
  const [cachedData, setCachedData] = useState<T>(queryClient.getQueryCache()?.find(queryKey)?.state.data as T);

  useEffect(() => {
    const observer = new QueryObserver<T>(queryClient, {
      queryKey,
    });

    const unsubscribe = observer.subscribe((result) => {
      if (result?.data) {
        setCachedData(result?.data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient, queryKey]);

  return cachedData;
}
