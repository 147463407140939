import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { UserApi } from "services/api";
import { AccountQueryKeys, UserQueryKeys } from "services/query-keys";

import { User } from "types";

export type DeleteUserResult = Awaited<ReturnType<typeof UserApi.delete>>;
export type TDeleteUserParams = {
  userId: string;
  forceDelete?: boolean;
  reassignments?: { userId: string; accountId: string }[];
};

const useDeleteUser = (options?: Omit<UseMutationOptions<User, unknown, TDeleteUserParams, unknown>, "mutationFn">) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: UserApi.delete,
    onSuccess: async (data, variables, ctx) => {
      await queryClient.invalidateQueries({
        queryKey: UserQueryKeys.list(),
      });
      await queryClient.invalidateQueries({
        queryKey: AccountQueryKeys.list(),
      });

      if (options?.onSuccess) {
        options.onSuccess(data, variables, ctx);
      }
    },
    onError: options?.onError,
  });
};

export default useDeleteUser;
