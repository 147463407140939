import { useState } from "react";
import { Modal, Button, Select, Form, Input, message, Tag } from "antd";
import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { LeadFormResponse, Account, Question, LeadsModalProps } from "types";
import { useMutation } from "react-query";
import useQueryCacheData from "hooks/useQueryCacheData";
import { CampaignApi } from "services/api";
import classes from "./LeadsModal.module.css";
import "../../../../../../styles/modal.css";
import "../../../../../../styles/icon.css";

const { Option } = Select;

type QuestionAnswers = {
  answers?: string[];
  question: string;
  type: string;
};

const categoriesNames: { [key: string]: string } = {
  FULL_NAME: "Vor- und Zuname",
  FIRST_NAME: "Vorname",
  LAST_NAME: "Nachname",
  EMAIL: "Email",
  PHONE: "Telefon",
};

// const convertToProperCase = (str: string) => {
//     const stringWithSpaces = str.replace(/_/g, ' ');

//     return stringWithSpaces.replace(/\w\S*/g, (word) => {
//         return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
//     });
// };

function LeadsModal({ visible, handleCloseModal, setLeadFormId }: LeadsModalProps) {
  const [form] = Form.useForm();
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [questions, setQuestions] = useState<QuestionAnswers[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(["EMAIL", "FULL_NAME"]);
  form.setFieldValue("categories", ["EMAIL", "CONTACT"]);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({
    EMAIL: "Email",
    FULL_NAME: "Vor- und Zuname",
  });

  const { mutate } = useMutation(
    "create-leads",
    () => {
      let questionsArray: Question[] = Object.keys(formValues).map((key, idx) => {
        return {
          type: key as Question["type"],
          key: `question${idx + 1}`,
          // label: formValues[key],
        };
      });
      if (questions.length > 0) {
        questionsArray = [
          ...questionsArray,
          ...questions.map((questionData, index) => {
            const newQuestion: Question = {
              type: "CUSTOM",
              key: `question${questionsArray.length + index + 1}`,
              label: questionData.question,
            };
            if (questionData.answers && questionData.answers.length > 0) {
              newQuestion.options = questionData.answers.map((answer, idx) => ({
                value: answer,
                key: `key${idx + 1}`,
              }));
            }
            return newQuestion;
          }),
        ];
      }
      if (!form.getFieldValue("formName")) {
        message.error("Bitte geben Sie den Namen des Formulars ein");
      }
      if (
        !form.getFieldValue("privacyUrl").includes("http://") &&
        !form.getFieldValue("privacyUrl").includes("https://")
      ) {
        message.error("Die URL sollte mit http:// oder https:// beginnen");
      }
      if (
        !form.getFieldValue("followupUrl").includes("http://") &&
        !form.getFieldValue("followupUrl").includes("https://")
      ) {
        message.error("Die Ziel-URL sollte mit http:// oder https:// beginnen");
      }
      const body: LeadFormResponse = {
        name: form.getFieldValue("formName"),
        questions: questionsArray,
        privacy_policy: {
          url: form.getFieldValue("privacyUrl"),
          link_text: form.getFieldValue("linkText"),
        },
        follow_up_action_url: form.getFieldValue("followupUrl"),
      };
      return CampaignApi.createLeads(body, accounts[0].id);
    },
    {
      onSuccess: (data) => {
        setLeadFormId(data.id);
        form.setFieldValue("formName", "");
        form.setFieldValue("privacyUrl", "");
        form.setFieldValue("linkText", "");
        form.setFieldValue("followupUrl", "");
        handleCloseModal();
      },
      onError: (error: Error) => {
        console.log(error.message);
      },
    },
  );

  const handleSelectChange = (value: string) => {
    if (value === "short-answer" || value === "multiple-choice") {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          type: value,
          question: "",
          answers: value === "multiple-choice" ? [""] : undefined,
        },
      ]);
    }
  };

  const handleDeleteQuestion = (index: number) => {
    setQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, i) => (i === index ? { ...question, question: value } : question)),
    );
  };

  const handleAddAnswer = (index: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, i) =>
        i === index ? { ...question, answers: [...(question.answers ?? []), ""] } : question,
      ),
    );
  };

  const handleRemoveAnswer = (questionIndex: number, answerIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: (question.answers ?? []).filter((_, j) => j !== answerIndex),
            }
          : question,
      ),
    );
  };

  const handleAnswerChange = (questionIndex: number, answerIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              answers: (question.answers ?? []).map((ans, j) => (j === answerIndex ? value : ans)),
            }
          : question,
      ),
    );
  };

  const handleAddCategory = (value: string) => {
    if (!selectedCategories.includes(value)) {
      setSelectedCategories([...selectedCategories, value]);
      setFormValues((prevValues) => ({
        ...prevValues,
        [value]: categoriesNames[value.toString()],
      }));
    }
  };

  const handleRemoveCategory = (index: number) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories.splice(index, 1);
    setSelectedCategories(updatedCategories);
    // Remove the category from formValues when deleted
    const updatedFormValues = { ...formValues };
    delete updatedFormValues[selectedCategories[index]];
    setFormValues(updatedFormValues);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div>
      <Modal
        width={550}
        style={{ borderRadius: 0 }}
        maskClosable={false}
        onCancel={handleCloseModal}
        className={classes.LeadsModal}
        open={visible}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className={classes.cancelButton} onClick={() => handleCloseModal()}>
              Entwurf speichern
            </Button>
            <Button
              type="primary"
              className={classes.createButton}
              onClick={() => {
                mutate();
              }}
            >
              Weiter
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item name="formName" label="Formularname" style={{ background: "#f5f5f5", padding: 5 }}>
            <Input
              style={{ borderRadius: 0 }}
              placeholder="Formularname eintragen"
              onChange={(e) => form.setFieldValue("formName", e.target.value)}
            />
          </Form.Item>

          <h3>Bitte Formulartyp wählen</h3>

          <Form.List name="categories">
            {(fields, { add, remove }) => (
              <>
                {selectedCategories.map((category) => (
                  <Form.Item
                    key={category}
                    style={{
                      background: "#f5f5f5",
                      padding: 5,
                      marginBottom: 15,
                    }}
                  >
                    <Form.Item validateTrigger={["onChange", "onBlur"]} noStyle>
                      <Input
                        style={{
                          width: "90%",
                          borderRadius: "0px !important",
                        }}
                        name={category}
                        value={formValues[category] || ""}
                        onChange={handleInputChange}
                        suffix={<Tag>{categoriesNames[category.toString()]}</Tag>}
                      />
                    </Form.Item>
                    <Button
                      type="default"
                      className={classes.dynamicDeleteButton}
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveCategory(selectedCategories.indexOf(category))}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Select
                    style={{ width: 120 }}
                    value="Kategorie"
                    open={categoryOpen}
                    onClick={() => setCategoryOpen(!categoryOpen)}
                    suffixIcon={<PlusOutlined />}
                    onSelect={(value) => {
                      handleAddCategory(value);
                      add(value);
                    }}
                  >
                    <Option value="FULL_NAME" disabled={selectedCategories.includes("FULL_NAME")}>
                      Vor- und Zuname
                    </Option>
                    <Option value="EMAIL" disabled={selectedCategories.includes("EMAIL")}>
                      Email
                    </Option>
                    <Option value="PHONE" disabled={selectedCategories.includes("PHONE")}>
                      Telefon
                    </Option>
                    <Option value="FIRST_NAME" disabled={selectedCategories.includes("FIRST_NAME")}>
                      Vorname
                    </Option>
                    <Option value="LAST_NAME" disabled={selectedCategories.includes("LAST_NAME")}>
                      Nachname
                    </Option>
                  </Select>
                </Form.Item>
              </>
            )}
          </Form.List>

          <h3>Fragen</h3>

          {questions.map((question, index) => (
            // eslint-disable-next-line
            <Form.Item key={index} style={{ background: "#f5f5f5" }}>
              <Form.Item label={`Frage ${index + 1}`} style={{ padding: 5, marginBottom: 5 }}>
                <Input
                  style={{ width: "90%" }}
                  value={question.question}
                  onChange={(e) => handleQuestionChange(index, e)}
                  suffix={<Tag>{question.type === "short-answer" ? "Kurzantworten" : "Multiple Choice"}</Tag>}
                />
                {questions.length >= 1 ? (
                  <Button
                    type="default"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteQuestion(index)}
                    style={{ marginLeft: 8, padding: 5 }}
                  />
                ) : null}
              </Form.Item>

              {question.answers && (
                <div>
                  <h3 style={{ fontWeight: "bold", padding: 5, paddingBottom: 3 }}>Antworten</h3>
                </div>
              )}
              {question.type === "multiple-choice" &&
                question.answers?.map((answer, answerIndex) => (
                  // eslint-disable-next-line
                  <Form.Item key={answerIndex} style={{ paddingLeft: 5 }}>
                    <Input
                      style={{ width: "80%" }}
                      suffix={<Tag>Option {answerIndex + 1}</Tag>}
                      value={answer}
                      onChange={(e) => handleAnswerChange(index, answerIndex, e)}
                    />
                    {(question.answers ?? []).length > 1 && (
                      <Button
                        type="default"
                        icon={<MinusOutlined />}
                        onClick={() => handleRemoveAnswer(index, answerIndex)}
                        style={{ marginLeft: 8, padding: 5 }}
                      />
                    )}
                    {(question.answers ?? []).length - 1 === answerIndex && (
                      <Button
                        type="default"
                        icon={<PlusOutlined />}
                        onClick={() => handleAddAnswer(index)}
                        style={{ marginLeft: 8, padding: 5 }}
                      />
                    )}
                  </Form.Item>
                ))}
            </Form.Item>
          ))}
          <Form.Item>
            <Select
              style={{ width: 160 }}
              value="Fragen hinzufügen"
              suffixIcon={<PlusOutlined />}
              open={questionOpen}
              onClick={() => setQuestionOpen(!questionOpen)}
              onChange={handleSelectChange}
            >
              <Option value="short-answer">Kurzantworten</Option>
              <Option value="multiple-choice">Multiple Choice</Option>
            </Select>
          </Form.Item>
          <h3>Datenschutzerklärung</h3>
          <Form.Item
            name="privacyUrl"
            label="URL"
            style={{ background: "#f5f5f5", padding: 5 }}
            tooltip="Die URL sollte mit http:// oder https:// beginnen."
          >
            <Input
              style={{ borderRadius: 0 }}
              placeholder="URL eintragen"
              onChange={(e) => form.setFieldValue("privacyUrl", e.target.value)}
            />
          </Form.Item>
          <Form.Item name="linkText" label="Link Text" style={{ background: "#f5f5f5", padding: 5 }}>
            <Input
              style={{ borderRadius: 0 }}
              placeholder="Link Text eintragen"
              onChange={(e) => form.setFieldValue("linkText", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="followupUrl"
            label="Ziel URL"
            style={{ background: "#f5f5f5", padding: 5 }}
            tooltip="Die Ziel-URL sollte mit http:// oder https:// beginnen."
          >
            <Input
              style={{ borderRadius: 0 }}
              placeholder="Ziel URL eintragen"
              onChange={(e) => form.setFieldValue("followupUrl", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default LeadsModal;
