import { GOALS } from "../../../../../../types/enum";

export const conversionTypesBasedonGoals: {
  [key: string]: {
    label: string;
    description: string;
    value: string;
  }[];
} = {
  [GOALS.NOTORIETY]: [],
  [GOALS.TRAFFIC]: [
    {
      label: "Website",
      description: "Leite Traffic zu deiner Website weiter",
      value: "WEBSITE",
    },
    {
      label: "Messenger",
      description: "Leite Traffic an den Messenger weiter",
      value: "MESSENGER",
    },
    {
      label: "Anrufe",
      description: "Rege Personen dazu an, dein Unternehmen anzurufen",
      value: "PHONE_CALL",
    },
  ],
  [GOALS.INTERACTIONS]: [
    {
      label: "Messaging-Apps",
      description:
        "Rege Personen dazu an, im Messenger, auf WhatsApp oder auf Instagram mit deiner Marke zu interagieren",
      value: "MESSENGER",
    },
    {
      label: "In deiner Anzeige",
      description:
        "Rege Personen dazu an, ein Video anzusehen oder mit deinem Beitrag oder deiner Veranstaltung zu interagieren",
      value: "ON_VIDEO",
    },
    {
      label: "Anrufe",
      description: "Rege Personen dazu an, dein Unternehmen anzurufen",
      value: "PHONE_CALL",
    },
    {
      label: "Website",
      description: "Leite Traffic zu deiner Website weiter",
      value: "UNDEFINED",
    },
    // {
    //     label: 'App',
    //     description: 'Rege Personen dazu an, mit deiner App zu interagieren',
    //     value: 'APP',
    // },
    {
      label: "Facebook-Seite",
      description: "Rege Personen dazu an, mit deiner Facebook-Seite zu interagieren",
      value: "ON_PAGE",
    },
  ],
  [GOALS.LEADS]: [
    {
      label: "Website",
      description: "Leite Traffic zu deiner Website weiter",
      value: "WEBSITE",
    },
    {
      label: "Instant-Formulare",
      description: "Generiere Leads, indem du Personen darum bittest, ein Formular auszufüllen",
      value: "ON_AD",
    },
    {
      label: "Messenger",
      description: "Generiere Leads, indem du Unterhaltungen im Messenger beginnst",
      value: "MESSENGER",
    },
    {
      label: "Instant-Formulare und Messenger",
      description:
        "Generiere Leads, indem du Personen darum bittest, ein Formular auszufüllen, oder indem du Unterhaltungen im Messenger beginnst",
      value: "LEAD_FORM_MESSENGER",
    },
    {
      label: "Anrufe",
      description: "Generiere Leads, indem du Personen darum bittest, dein Unternehmen anzurufen",
      value: "PHONE_CALL",
    },
  ],
  [GOALS.SALES]: [
    {
      label: "Website",
      description: "Steigere Umsatz und Conversions auf deiner Website.",
      value: "UNDEFINED",
    },
    // {
    //     label: 'App',
    //     description: 'Rege Personen dazu an, mit deiner App zu interagieren',
    //     value: 'APP',
    // },
    // {
    //     label: 'Website und App',
    //     description: 'Leite Traffic zu deiner Website weiter',
    //     value: 'WEBSITE_APP',
    // },
    {
      label: "Messenging-Apps",
      description:
        "Rege Personen dazu an, im Messenger, auf WhatsApp oder auf Instagram mit deiner Marke zu interagieren",
      value: "MESSENGER",
    },
    {
      label: "Anrufe",
      description: "Steigere Umsatz und Conversions mithilfe des Ziels „Anrufe“.",
      value: "PHONE_CALL",
    },
  ],
};

export const performanceTypesForNotoriety: {
  label: string;
  description: string;
  value: string;
}[] = [
  {
    label: "Anzeigenreichweite maximieren",
    description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern",
    value: "REACH",
  },
  {
    label: "Anzahl der Impressionen maximieren",
    description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
    value: "IMPRESSIONS",
  },
  // {
  //     label: 'Steigerung der Werbeerinnerung maximieren',
  //     description:
  //         'Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die sich wahrscheinlich daran erinnern werden.',
  //     value: 'AD_RECALL_LIFT',
  // },
];

export const performanceTypesBasedonGoals: {
  [key: string]: {
    [key: string]: {
      label: string;
      description: string;
      value: string;
    }[];
  };
} = {
  [GOALS.NOTORIETY]: {},
  [GOALS.TRAFFIC]: {
    WEBSITE: [
      {
        label: "Anzahl der Landingpage-Aufrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die sich am ehesten die Website oder Instant Experience über den Link in deiner Anzeige ansehen werden.",
        value: "LANDING_PAGE_VIEWS",
      },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
    ],
    MESSENGER: [
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
    ],
    PHONE_CALL: [
      {
        label: "Anzahl der Anrufe maximieren",
        description: "",
        value: "QUALITY_CALL",
      },
    ],
  },
  [GOALS.INTERACTIONS]: {
    MESSENGER: [
      {
        label: "Anzahl der Unterhaltungen maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten über einen Messaging-Dienst mit dir kommunizieren werden.",
        value: "CONVERSATIONS",
      },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
    ],
    ON_VIDEO: [
      {
        label: "ThruPlay-Aufrufe maximieren",
        description:
          "Wir versuchen, deine Video Ads Personen zu zeigen, die sich das gesamte Video ansehen werden, wenn es kürzer als 15 Sekunden ist. Bei längeren Videos versuchen wir, sie Personen zu zeigen, die sie sich wahrscheinlich mindestens 15 Sekunden ansehen werden.",
        value: "THRUPLAY",
      },
    ],
    PHONE_CALL: [
      {
        label: "Anzahl der Anrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die dein Unternehmen am wahrscheinlichsten anrufen werden.",
        value: "QUALITY_CALL",
      },
    ],
    UNDEFINED: [
      {
        label: "Anzahl der Landingpage-Aufrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die sich am ehesten die Website oder Instant Experience über den Link in deiner Anzeige ansehen werden.",
        value: "LANDING_PAGE_VIEWS",
      },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
      // {
      //     label: 'Anzahl der Conversions maximieren',
      //     description:
      //         'Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten eine bestimmte Handlung auf deiner Website ausführen werden.',
      //     value: 'OFFSITE_CONVERSIONS',
      // },
    ],
    ON_PAGE: [
      {
        label: "Anzahl der „Gefällt mir“-Angaben für die Seite maximieren",
        description: "",
        value: "PAGE_LIKES",
      },
    ],
  },
  [GOALS.LEADS]: {
    WEBSITE: [
      {
        label: "Anzahl der Landingpage-Aufrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die sich am ehesten die Website oder Instant Experience über den Link in deiner Anzeige ansehen werden.",
        value: "LANDING_PAGE_VIEWS",
      },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
    ],
    ON_AD: [
      {
        label: "Anzahl der Leads maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten ihre Kontaktinformationen mit dir teilen werden.",
        value: "LEAD_GENERATION",
      },
      {
        label: "Anzahl der Conversions maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten eine bestimmte Handlung auf deiner Website ausführen werden.",
        value: "QUALITY_LEAD",
      },
    ],
    MESSENGER: [
      {
        label: "Anzahl der Leads maximieren",
        description: "",
        value: "LEAD_GENERATION",
      },
    ],
    LEAD_FORM_MESSENGER: [
      {
        label: "Anzahl der Leads maximieren",
        description: "",
        value: "LEAD_GENERATION",
      },
    ],
    PHONE_CALL: [
      {
        label: "Anzahl der Anrufe maximieren",
        description: "",
        value: "QUALITY_CALL",
      },
    ],
  },
  [GOALS.SALES]: {
    UNDEFINED: [
      // {
      //     label: 'Anzahl der Conversions maximieren',
      //     description:
      //         'Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten eine bestimmte Handlung auf deiner Website ausführen werden.',
      //     value: 'OFFSITE_CONVERSIONS',
      // },
      {
        label: "Anzahl der Landingpage-Aufrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die sich am ehesten die Website oder Instant Experience über den Link in deiner Anzeige ansehen werden.",
        value: "LANDING_PAGE_VIEWS",
      },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
    ],
    MESSENGER: [
      {
        label: "Anzahl der Unterhaltungen maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten über einen Messaging-Dienst mit dir kommunizieren werden.",
        value: "CONVERSATIONS",
      },
      // {
      //     label: 'Anzahl der Conversions maximieren',
      //     description:
      //         'Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die am ehesten eine bestimmte Handlung auf deiner Website ausführen werden.',
      //     value: 'OFFSITE_CONVERSIONS',
      // },
      {
        label: "Anzahl der Link-Klicks maximieren",
        description: "Wir versuchen, deine Werbeanzeigen den Personen zu zeigen, die am ehesten darauf klicken werden.",
        value: "LINK_CLICKS",
      },
      {
        label: "Anzahl der täglich erreichten Einzelpersonen maximieren",
        description: "Wir versuchen, Personen deine Werbeanzeigen bis zu ein Mal täglich zu zeigen.",
        value: "REACH",
      },
      {
        label: "Anzahl der Impressionen maximieren",
        description: "Wir versuchen, deine Werbeanzeigen so oft wie möglich an Personen auszuliefern.",
        value: "IMPRESSIONS",
      },
    ],
    PHONE_CALL: [
      {
        label: "Anzahl der Anrufe maximieren",
        description:
          "Wir versuchen, deine Werbeanzeigen Personen zu zeigen, die dein Unternehmen am wahrscheinlichsten anrufen werden.",
        value: "QUALITY_CALL",
      },
    ],
  },
};
