import { message } from "antd";
import api from "utils/api";

const configId = {
  facebook: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
  instagram: process.env.REACT_APP_INSTAGRAM_CONFIG_ID,
};

export default {
  async login({ type }: { type: "facebook" | "instagram" }) {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          try {
            if (response.authResponse) {
              (async () => {
                const { data } = await api.get<any>("/account/list");

                try {
                  const { data: accountChannels } = await api.post(`/account/${data.data[0].id}/connect-meta`, {
                    accessToken: response.authResponse.accessToken,
                    metaUserId: response.authResponse.userID,
                  });

                  message.success(`${type} erfolgreich verknüpft!`);
                  return resolve(accountChannels.data);
                } catch (err: any) {
                  message.error(err.message ?? `${type} Verbindung fehlgeschlagen!`);
                  return reject(response);
                }
              })();
            } else {
              message.error(`${type} Verbindung fehlgeschlagen!`);
              return reject(response);
            }
          } catch (err) {
            message.error(`${type} Verbindung fehlgeschlagen!`);
            return reject(response);
          }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `config_id` is supported by the Facebook SDK
        { config_id: configId[type], auth_type: "rerequest" },
      );
    });
  },
  async connectAdAccount() {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          (async () => {
            if (response.authResponse) {
              const { data } = await api.get<any>("/account/list");

              try {
                await api.post(`/account/${data.data[0].id}/connect-meta-adaccount`, {
                  accessToken: response.authResponse.accessToken,
                  metaUserId: response.authResponse.userID,
                });

                message.success(`Facebook Ad account erfolgreich angeschlossen!`);
                return resolve(response);
              } catch (err: any) {
                message.error(err.message ?? `Facebook Ad account Verbindung fehlgeschlagen!`);
                return reject(response);
              }
            }
            message.error(`Facebook Ad account Verbindung fehlgeschlagen!`);
            return reject(response);
          })();
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: `config_id` is supported by the Facebook SDK
        { config_id: process.env.REACT_APP_FACEBOOK_AD_CONFIG_ID },
      );
    });
  },
};
