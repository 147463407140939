import { Button } from "@pankod/refine-antd";
import React, { ForwardedRef } from "react";
import { mergeClasses } from "utils/mergeClasses";
import classes from "./index.module.css";

const IconButton = React.forwardRef(
  (
    {
      children,
      className,
      width = "40px",
      height = "40px",
      style,
      svgColorProp = "stroke",
      alt,
      ...rest
    }: Omit<React.ComponentPropsWithoutRef<typeof Button>, "type"> & {
      width?: number | string;
      height?: number | string;
      svgColorProp?: "stroke" | "fill" | "color";
      alt: string;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        {...rest}
        style={{
          ...style,
          width: width ?? style?.width,
          height: height ?? style?.height,
        }}
        className={mergeClasses(classes.iconButton, className)}
        data-svg-color-prop={svgColorProp}
        type="ghost"
        ref={ref}
      >
        {children}
        <p className="sr-only">{alt}</p>
      </Button>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
