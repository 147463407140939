import { useState } from "react";
import { Button, Row, Col } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import Bekanntheit from "../../../../../assets/Bekanntheit.png";
import Traffic from "../../../../../assets/Traffic.png";
import Interaction from "../../../../../assets/Interaktionen.png";
import Leads from "../../../../../assets/Leads.png";
import CheckIcon from "../../../../../assets/CheckIcon.png";
import QuestionMark from "../../../../../assets/QuestionMark.png";
import Sales from "../../../../../assets/Umsatz.png";
import classes from "./CategoryForm.module.css";

type ButtonState = {
  category: string;
  description: string;
  useCase: string[];
};

type CategoryState = {
  category: string;
  description: string;
  useCase: string[];
  imageUrl: string;
  value: string;
};

const buttonNames: CategoryState[] = [
  {
    category: "Bekanntheit",
    description: "Richte deine Werbeanzeigen an die Personen, die sich am wahrscheinlichsten daran erinnern.",
    useCase: ["Reichweite", "Markenbekanntheit", "Videoaufrufe", "Bekanntheit deines Store-Standorts"],
    imageUrl: Bekanntheit,
    value: "ads-campaign-create-notoriety",
  },
  {
    category: "Traffic",
    description: "Leite Personen an ein Ziel weiter (z. B. deine Website, App oder Facebook- Veranstaltung).",
    useCase: ["Link Klicks", "Landingpage-Aufrufe", "Messenger und WhatsApp", "Anrufe"],
    imageUrl: Traffic,
    value: "ads-campaign-create-traffic",
  },
  {
    category: "Interaktionen",
    description:
      "Erhalte mehr Nachrichten, Videoaufrufe, Beitragsinteraktionen, „Gefällt mir“-Angaben oder Veranstaltungszusagen/-absagen.",
    useCase: ["Messenger, Instagram und WhatsApp", "Videoaufrufe", "Beitragsinteraktionen"],
    imageUrl: Interaction,
    value: "ads-campaign-create-interactions",
  },
  {
    category: "Leads",
    description: "Sammle Leads für dein Unternehmen oder deine Marke.",
    useCase: ["Instant-Formulare", "Messenger", "Conversions", "Anrufe"],
    imageUrl: Leads,
    value: "ads-campaign-create-leads",
  },
  {
    category: "Umsatz",
    description: "Finde Personen, die dein Produkt oder deinen Service mit hoher Wahrscheinlichkeit kaufen.",
    useCase: ["Conversions", "Katalogverkäufe", "Messenger, Instagram und WhatsApp", "Anrufe"],
    imageUrl: Sales,
    value: "ads-campaign-create-salesVolume",
  },
];

export default function CategoryForm() {
  const { push } = useRouterContext().useHistory();
  const [currentCampaign, setCurrentCampaign] = useState<ButtonState>({
    category: buttonNames[0].category,
    description: buttonNames[0].description,
    useCase: buttonNames[0].useCase,
  });
  const [activeButton, setActiveButton] = useState<string>("Bekanntheit");

  const handleButtonClick = (category: string, description: string, useCase: string[]) => {
    setActiveButton(category);
    setCurrentCampaign({
      category,
      description,
      useCase,
    });
  };

  return (
    <Row>
      <Col lg={18} md={24} sm={24} xs={24}>
        <Row>
          <h2>Kampagnenart</h2>
        </Row>
        <Row style={{ gap: 10 }}>
          {buttonNames.map((name) => (
            <div style={{ margin: 2 }} key={name.category}>
              <Button
                data-testid={name.value}
                className={`${classes.categoryButton} ${activeButton === name.category ? classes.activeButton : ""}`}
                onClick={() => handleButtonClick(name.category, name.description, name.useCase)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={name.imageUrl}
                  className={
                    name.category === "Leads" || name.category === "Umsatz"
                      ? classes.categoryShortImage
                      : classes.categoryLongImage
                  }
                  alt={name.category}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    position: "absolute",
                    bottom: 20,
                  }}
                >
                  {name.category}
                </span>
              </Button>
            </div>
          ))}
        </Row>
        <br />
        <br />
        <div className={classes.descriptionContainer}>
          <h2 style={{ fontWeight: "bold", fontSize: 18 }}>{currentCampaign.category}</h2>
          <span style={{ display: "block", fontSize: 16 }}>{currentCampaign.description}</span>
          <div style={{ marginTop: 20 }}>
            <h3 style={{ fontWeight: "bold", fontSize: 18 }}>Geeignet für:</h3>
          </div>
          {currentCampaign.useCase.map((item) => (
            <span style={{ display: "block", fontSize: 16, whiteSpace: "pre-wrap" }}>
              <img src={CheckIcon} style={{ width: 15, marginBottom: "5px" }} alt="checkIcon" /> {item}
            </span>
          ))}
        </div>

        <Row
          style={{
            marginTop: 20,
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <Col>
            <img src={QuestionMark} style={{ width: 30 }} alt="Question" />
            <a href="mailto:hello@easypostlab.com">
              <span className={classes.helpLink}>Brauchst du Hilfe? Melde dich bei unserem Expertenteam</span>
            </a>
          </Col>
          <Col>
            <Button
              data-testid="ads-campaign-weiter-btn"
              disabled={!activeButton}
              type="primary"
              className={classes.createButton}
              onClick={() => {
                push(`/user/campaign/${currentCampaign.category.toLowerCase()}`);
              }}
            >
              Weiter
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
