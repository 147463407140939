import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import useAdmin from "./useAdmin";

export type TGetAccountStatsParams = {
  startDate: Date | string;
  endDate: Date | string;
};

const useAccountStats = <TReturn = Awaited<ReturnType<typeof AccountApi.stats>>>(
  filters: TGetAccountStatsParams,
  options: {
    enabled: boolean;
    selectFn?: (res: Awaited<ReturnType<typeof AccountApi.stats>>) => TReturn;
    staleTime?: number;
  } = {
    enabled: true,
  },
) => {
  const { isPlatformAdmin } = useAdmin();

  return useQuery({
    queryKey: AccountQueryKeys.stats(filters),
    queryFn: async () => {
      return AccountApi.stats(filters);
    },
    select: options?.selectFn,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialData: [],
    initialDataUpdatedAt: 0,
    enabled: isPlatformAdmin && options.enabled,
    refetchOnWindowFocus: false,
  });
};

export default useAccountStats;
