import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { ForwardedRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import formatNumber from "utils/formatNumber";
import { mergeClasses } from "utils/mergeClasses";
import UpwardTrendGraph from "../../assets/UpwardTrendGraph.svg";
import DownwardTrendGraph from "../../assets/DownwardTrendGraph.svg";
import classes from "./index.module.css";

const defaultFormatter = (number: number) =>
  formatNumber(number, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    notation: number >= 10_000 ? "compact" : "standard",
  });

const StatCard = React.forwardRef(
  (
    {
      className,
      title,
      stat,
      prevMonthStat,
      loading,
      formatStat = defaultFormatter,
      ...rest
    }: React.HTMLAttributes<HTMLDivElement> & {
      title: string;
      stat: number;
      prevMonthStat: number;
      loading?: boolean;
      formatStat?: (num: number) => string | number;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const formattedStat = useMemo(() => formatStat(stat), [formatStat, stat]);

    const { t } = useTranslation(["common", "pages"]);
    const { deltaPercentage, isPositiveDelta } = useMemo(() => {
      const deltaVsMonth = stat - prevMonthStat;

      const deltaPercentage = Math.round((deltaVsMonth / prevMonthStat) * 100);

      return {
        deltaPercentage: isNaN(deltaPercentage) ? 0 : deltaPercentage === Infinity ? 0 : deltaPercentage,
        isPositiveDelta: deltaVsMonth >= 0,
      };
    }, [stat, prevMonthStat]);

    return (
      <div {...rest} className={mergeClasses(classes.statCard, className)} ref={ref}>
        <Spin spinning={loading}>
          <div className={classes.statCardHeader}>
            <strong>{title} </strong>
          </div>
          <div className={classes.statCardBody}>
            <div className={classes.statCardContent}>
              <strong>{formattedStat}</strong>
              <div className={classes.statCardContentText} data-positive={isPositiveDelta}>
                {isPositiveDelta ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                <span>
                  <em>{deltaPercentage}%</em> {t("pages:adminDashboard.vsLastMonthLabel")}
                </span>
              </div>
            </div>
            <div>
              <img
                className={classes.statCardTrendGraph}
                src={isPositiveDelta ? UpwardTrendGraph : DownwardTrendGraph}
                alt={isPositiveDelta ? "Green Upward trending graph" : "Red Downward trending graph"}
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  },
);

StatCard.displayName = "StatCard";

export default StatCard;
