/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/no-namespace */
import { Flex } from "antd";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AccountApi } from "services/api";
import { Link } from "@pankod/refine-react-router-v6";
import useCheckoutSession from "hooks/useCheckoutSession";
import useMe from "hooks/useMe";
import { useTranslation } from "react-i18next";
import EPLLogo from "../../../../assets/EPL Black Text Logo.svg";
import classes from "./index.module.css";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const PricingCard = ({
  title,
  subitle,
  featureTitle,
  discountPercent,
  price,
  discountedPrice,
  priceSubtext,
  features,
  ctaText,
  ctaDisabled = false,
  onCtaClick,
}: {
  title: string;
  subitle: string;
  price: string;
  discountedPrice?: string;
  priceSubtext: string;
  featureTitle?: string;
  discountPercent?: number;
  features: string[];
  ctaText: string;
  ctaDisabled?: boolean;
  onCtaClick?: () => void;
}) => {
  return (
    <div className={classes.pricingCard}>
      <div className={classes.pricingCardHeader}>
        <div>
          <strong>{title}</strong>
          {discountPercent && <sup>-{discountPercent}%</sup>}
        </div>
        <p>{subitle}</p>
      </div>
      <div className={classes.pricingCardPriceArea}>
        <strong>
          {discountedPrice && <s>{price}</s>}
          <span>{discountedPrice ? discountedPrice : price}</span>
        </strong>

        <span> {priceSubtext}</span>
      </div>
      {featureTitle && <span className={classes.pricingCardFeaturesTitle}>{featureTitle}</span>}
      <ul className={`${classes.pricingCardFeatures} ${!featureTitle ? classes.featuresWithoutTitle : ""}`}>
        {features.map((feature) => {
          return (
            <li key={feature}>
              <span className={classes.pricingCardFeatureItem}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.799805" width="24" height="24" rx="12" fill="#F4EBFF" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5964 8.18996L10.4364 15.1L8.53638 13.07C8.18638 12.74 7.63638 12.72 7.23638 13C6.84638 13.29 6.73638 13.8 6.97638 14.21L9.22638 17.87C9.44638 18.21 9.82638 18.42 10.2564 18.42C10.6664 18.42 11.0564 18.21 11.2764 17.87C11.6364 17.4 18.5064 9.20996 18.5064 9.20996C19.4064 8.28996 18.3164 7.47996 17.5964 8.17996V8.18996Z"
                    fill="#F21C2D"
                  />
                </svg>
                {feature}
              </span>
            </li>
          );
        })}
      </ul>
      <div className={classes.pricingCardFooter}>
        <button type="button" className={classes.pricingCardCtaBtn} onClick={onCtaClick} disabled={ctaDisabled}>
          {ctaText}
        </button>
      </div>
    </div>
  );
};

export default function AddPayment() {
  const { t } = useTranslation(["pages"]);
  const queryClient = useQueryClient();
  const { data: accounts } = useQuery("accounts", AccountApi.getList);
  const { refetch } = useMe();
  const data = queryClient.getQueryData<{ email: string }>("onboarding");

  const [recurrence, setRecurrence] = useState<"YEARLY" | "MONTHLY">("MONTHLY");

  const isYearly = recurrence === "YEARLY";
  const isMonthly = !isYearly;

  const { mutateAsync: createCheckoutSession, isLoading: isCreatingCheckoutSession } = useCheckoutSession({
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const handleCtaClick = (recurrence: "YEARLY" | "MONTHLY", planType: "BASIC" | "PRO") => {
    const currAccount = accounts?.[0];
    if (!currAccount) return;

    createCheckoutSession({ recurrence, planType, accountId: currAccount.id });
  };

  useEffect(() => {
    if (!data?.email) {
      refetch();
    }
  }, [data, refetch]);

  return (
    <Flex className={classes.pricingPlanPage} vertical align="center">
      <header className={classes.header}>
        <div>
          <Link className={classes.headerLogo} to="/">
            <img src={EPLLogo} alt="EasyPostLab Logo" />
          </Link>
        </div>
      </header>
      <main className={classes.mainContainer}>
        <Flex vertical align="center">
          <h1 className={classes.mainTitle}>
            <strong>Jetzt wählen und direkt starten.</strong>
          </h1>
          <span className={classes.mainSubtitle}>
            Wähle deinen Plan, der zu deinem Unternehmen passt und schalte deine Social Media Konten für den nächsten
            Gang frei.
          </span>
          <button
            className={classes.pricingPlanToggle}
            type="button"
            role="switch"
            aria-checked={isMonthly}
            onClick={() => setRecurrence(isMonthly ? "YEARLY" : "MONTHLY")}
          >
            <input tabIndex={-1} />
            <span data-state-active={isMonthly}>{t("subscription.monthly")}</span>
            <span data-state-active={isYearly}>{t("subscription.yearly")}</span>
          </button>
          <span className={classes.savingsText}>
            <strong>Spare 22%</strong> bei jährlicher Zahlung
          </span>
        </Flex>
        <Flex className={classes.cardContainer}>
          <PricingCard
            title="Basic"
            subitle="Einzelunternehmen"
            price="69,00€"
            discountedPrice={isYearly ? "60,00€" : undefined}
            priceSubtext={isMonthly ? "monatliche Bezahlung" : "jährliche Bezahlung"}
            features={[
              "1 Business ID",
              "Verwalte bis zu 5 Social Media Konten",
              "Planen und Posten von Beiträgen für 365 Tage",
              "ToDo-Planner",
              "Sammelimport von bis zu 40 Posts",
              "Beliebig viele Mitarbeiter",
              "Rollenmanagement",
            ]}
            ctaText="Jetzt starten"
            ctaDisabled={isCreatingCheckoutSession}
            onCtaClick={() => handleCtaClick(recurrence, "BASIC")}
          />
          <PricingCard
            title="Pro"
            subitle="Für Agenturen & Freelancer"
            price="249,00€"
            discountedPrice={isYearly ? "220,00€" : undefined}
            priceSubtext={isMonthly ? "monatliche Bezahlung" : "jährliche Bezahlung"}
            discountPercent={20}
            featureTitle="Zusätzlich zur Basic Version"
            features={[
              "5 Business IDs",
              "Verwalte bis zu 20 Social Media Konten",
              "Ad-Manager Lite",
              "Freigabe Center",
              "Meta Instantformulare",
            ]}
            ctaText="Jetzt starten"
            ctaDisabled={isCreatingCheckoutSession}
            onCtaClick={() => handleCtaClick(recurrence, "PRO")}
          />
          <PricingCard
            title="Enterprise"
            subitle="Für alle, die mehr brauchen"
            price="Custom"
            priceSubtext={isMonthly ? "monatliche Bezahlung" : "jährliche Bezahlung"}
            featureTitle="Zusätzlich zur Pro Version"
            features={["Mehr als 20 Business IDs", "Ad-Manager Premium", "Post-Toolbar", "Automatisierter Report"]}
            ctaText="Jetzt anfragen"
            ctaDisabled
          />
        </Flex>
      </main>
    </Flex>
  );
}
