import { UploadFile } from "antd";

interface CroppedImageResult {
  croppedFile: File;
  thumbnailUrl: string;
}

const createImage = (source: string | File) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.crossOrigin = "anonymous"; // needed to avoid cross-origin issues on CodeSandbox
    if (typeof source === "string") {
      image.src = source; // If source is a string (URL)
    } else if (source instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        image.src = reader.result as string;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(source); // If source is a File object
    } else {
      reject(new Error("Invalid image source"));
    }
  });

async function getCroppedImg(
  imageSource: UploadFile,
  pixelCrop: { x: number; y: number; width: number; height: number },
): Promise<CroppedImageResult> {
  const image = await createImage(imageSource.originFileObj as File);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Canvas context is not supported");
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  const thumbnailUrl = canvas.toDataURL("image/jpeg"); // Convert the cropped image to base64 string

  return new Promise<CroppedImageResult>((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      // Preserve the original file type
      const croppedFile = new File([blob], imageSource.originFileObj?.name ?? "", {
        type: imageSource.originFileObj?.type,
      });
      resolve({ croppedFile, thumbnailUrl });
    }, "image/jpeg"); // Use the original image type for the blob
  });
}

export { getCroppedImg };
