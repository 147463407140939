import { FB_BODY_CHAR_LIMIT, IG_BODY_CHAR_LIMIT } from "constants/editor";
import { RobotOutlined, SmileOutlined } from "@ant-design/icons";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";
import { CreatePost } from "types";
import TextArea from "antd/es/input/TextArea";
import { Button, Tooltip } from "antd";
import classes from "./BodyComponent.module.css";

export type TextComponentProps = {
  localPost: CreatePost;
  setLocalPost: (localPost: CreatePost) => void;
};

const BodyComponent = (props: TextComponentProps) => {
  const { localPost, setLocalPost } = props;
  const [showEmoji, setShowEmoji] = useState(false);

  const maxLength =
    localPost.platforms.length <= 0 || localPost.platforms.find((item) => item.includes("INSTAGRAM"))
      ? IG_BODY_CHAR_LIMIT
      : FB_BODY_CHAR_LIMIT;
  return (
    <div className={classes.textareaCont}>
      <div style={{ position: "relative" }}>
        <label className={classes.postBodyLabel} htmlFor="post-body">
          Beschreibung
        </label>
        <TextArea
          id="post-body"
          rows={5}
          value={localPost.body}
          maxLength={maxLength}
          onChange={(e) => {
            setLocalPost({
              ...localPost,
              body: e.target.value,
            });
          }}
          className={classes.textArea}
          placeholder="Schreibe hier deinen Text..."
          data-testid="post-text"
        />
        <div className={classes.emojiPickerCont}>
          <Tooltip title="Emoji-Auswahl">
            <SmileOutlined
              style={{ fontSize: 20, marginRight: 10 }}
              onClick={() => {
                setShowEmoji(!showEmoji);
              }}
            />
          </Tooltip>
          {showEmoji && (
            <div className={classes.emojiPicker}>
              <EmojiPicker
                autoFocusSearch={false}
                onEmojiClick={(emojiObject) => {
                  setLocalPost({ ...localPost, body: localPost.body + emojiObject.emoji });
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.textAreaFooter}>
        <a title="ChatGPT öffnen" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
          <Button type="primary" icon={<RobotOutlined />}>
            ChatGPT öffnen
          </Button>
        </a>
        <p>{`${localPost.body?.length || 0} / ${maxLength}`}</p>
      </div>
    </div>
  );
};

export default BodyComponent;
