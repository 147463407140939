import { GOALS } from "../../../../../types/enum";

export const callToActionTypesBasedonConversion: {
  [key: string]: {
    [key: string]: {
      label: string;
      value: string;
    }[];
  };
} = {
  [GOALS.NOTORIETY]: {
    UNDEFINED: [
      {
        label: "Weitere Infos",
        value: "LEARN_MORE",
      },
      {
        label: "Jetzt bewerben",
        value: "APPLY_NOW",
      },
      {
        label: "Jetzt kaufen",
        value: "BUY_NOW",
      },
      {
        label: "Kein button",
        value: "NO_BUTTON",
      },
      {
        label: "Termin Anfragen",
        value: "REQUEST_TIME",
      },
      {
        label: "Angebot einholen",
        value: "GET_QUOTE",
      },
      {
        label: "Jetzt buchen",
        value: "BOOK_TRAVEL",
      },
      {
        label: "Herunterladen",
        value: "DOWNLOAD",
      },
      // { "label": "Angebot beanspruchen", "value": "GET_OFFER" },
      // { "label": "Promotions erhalten", "value": "GET_PROMOTIONS" },
      {
        label: "Kontaktiere uns",
        value: "CONTACT_US",
      },
      {
        label: "Spielzeiten",
        value: "GET_SHOWTIMES",
      },
      {
        label: "Jetzt anhören",
        value: "LISTEN_NOW",
      },
      {
        label: "Jetzt bestellen",
        value: "ORDER_NOW",
      },
      {
        label: "Nachricht senden",
        value: "MESSAGE_PAGE",
      },
      {
        label: "Speichern",
        value: "SAVE",
      },
      {
        label: "Speisekarte ansehen",
        value: "SEE_MENU",
      },
      {
        label: "Registrieren",
        value: "SIGN_UP",
      },
      {
        label: "Abonnieren",
        value: "SUBSCRIBE",
      },
      {
        label: "Mehr ansehen",
        value: "WATCH_MORE",
      },
      {
        label: "WhatsApp-Nachricht senden",
        value: "WHATSAPP_MESSAGE",
      },
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
      // { "label": "Jetzt Anfragen", "value": "INQUIRE_NOW" },
      // { "label": "Updates erhalten", "value": "SEND_UPDATES" },
      // { "label": "Zugriff erhalten", "value": "" },
      // { "label": "Tickets kaufen", "value": "BUY_TICKETS" }
    ],
  },
  [GOALS.TRAFFIC]: {
    WEBSITE: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      {
        label: "Kein button",
        value: "NO_BUTTON",
      },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Promotions erhalten", value: "GET_PROMOTIONS" },
      { label: "Spielzeiten", value: "GET_SHOWTIMES" },
      { label: "Jetzt anhören", value: "LISTEN_NOW" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      // { "label": "Zugriff erhalten", "value": "" },
      { label: "Termin anfragen", value: "REQUEST_TIME" },
      { label: "Speisekarte ansehen", value: "SEE_MENU" },
      { label: "Updates erhalten", value: "SEND_UPDATES" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Mehr ansehen", value: "WATCH_MORE" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
    MESSENGER: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Nachricht senden", value: "MESSAGE_PAGE" },
      { label: "Promotions erhalten", value: "GET_PROMOTIONS" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      // { "label": "Zugriff erhalten", "value": "" },
      { label: "Updates erhalten", value: "SEND_UPDATES" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
    ],
    PHONE_CALL: [
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
  },
  [GOALS.INTERACTIONS]: {
    MESSENGER: [
      { label: "Nachricht senden", value: "MESSAGE_PAGE" },
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Promotions erhalten", value: "GET_PROMOTIONS" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Jetzt anfragen", value: "REQUEST_TIME" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      { label: "Updates erhalten", value: "SEND_UPDATES" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
    ],
    ON_VIDEO: [
      {
        label: "Weitere Infos",
        value: "LEARN_MORE",
      },
    ],
    PHONE_CALL: [
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
    UNDEFINED: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Kein Button", value: "NO_BUTTON" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Tickets kaufen", value: "BUY_TICKETS" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Spielzeiten", value: "GET_SHOWTIMES" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      { label: "Jetzt anhören", value: "LISTEN_NOW" },
      // { "label": "Spielen", "value": "PLAY" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Termin anfragen", value: "REQUEST_TIME" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Speisekarte ansehen", value: "SEE_MENU" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Mehr ansehen", value: "WATCH_MORE" },
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
    ON_PAGE: [
      // {
      //     label: 'Anzahl der „Gefällt mir“-Angaben für die Seite maximieren',
      //     value: 'PAGE_LIKES',
      // },
    ],
  },
  [GOALS.LEADS]: {
    WEBSITE: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Kein Button", value: "NO_BUTTON" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Mehr ansehen", value: "WATCH_MORE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Tickets kaufen", value: "BUY_TICKETS" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Spielzeiten", value: "GET_SHOWTIMES" },
      { label: "Jetzt anhören", value: "LISTEN_NOW" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      // { "label": "Spielen", "value": "PLAY" },
      { label: "Termin anfragen", value: "REQUEST_TIME" },
      { label: "Speisekarte ansehen", value: "SEE_MENU" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
    ON_AD: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Registrieren", value: "SIGN_UP" },
    ],
    MESSENGER: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Nachricht senden", value: "MESSAGE_PAGE" },
      { label: "Promotions erhalten", value: "GET_PROMOTIONS" },
      { label: "Jetzt anfragen", value: "INQUIRE_NOW" },
      { label: "Updates erhalten", value: "SEND_UPDATES" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
    ],
    LEAD_FORM_MESSENGER: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      { label: "Registrieren", value: "SIGN_UP" },
    ],
    PHONE_CALL: [
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
  },
  [GOALS.SALES]: {
    UNDEFINED: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Kein Button", value: "NO_BUTTON" },
      { label: "Spielzeiten", value: "GET_SHOWTIMES" },
      { label: "Jetzt anhören", value: "LISTEN_NOW" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      // { "label": "Spielen", "value": "PLAY" },
      { label: "Termin anfragen", value: "REQUEST_TIME" },
      { label: "Speisekarte ansehen", value: "SEE_MENU" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Mehr ansehen", value: "WATCH_MORE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Tickets kaufen", value: "BUY_TICKETS" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Herunterladen", value: "DOWNLOAD" },
      { label: "Angebot beanspruchen", value: "GET_OFFER" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
    MESSENGER: [
      { label: "Weitere Infos", value: "LEARN_MORE" },
      { label: "Jetzt bestellen", value: "ORDER_NOW" },
      { label: "Spielen", value: "PLAY" },
      { label: "Jetzt kaufen", value: "BUY_NOW" },
      { label: "Registrieren", value: "SIGN_UP" },
      { label: "Abonnieren", value: "SUBSCRIBE" },
      { label: "Nachricht senden", value: "MESSAGE_PAGE" },
      { label: "Jetzt bewerben", value: "APPLY_NOW" },
      { label: "Jetzt buchen", value: "BOOK_TRAVEL" },
      { label: "Kontaktiere uns", value: "CONTACT_US" },
      { label: "Angebot einholen", value: "GET_QUOTE" },
    ],
    PHONE_CALL: [
      {
        label: "Jetzt Anrufen",
        value: "CALL_NOW",
      },
    ],
  },
};

export default callToActionTypesBasedonConversion;
