import { Modal, Form, message, Select, Row, Button } from "antd";
import { AxiosError } from "axios";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useUpdateCompanyChannels from "hooks/company/useUpdateCompanyChannels";
import useChannels from "hooks/useChannels";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";

export default function AddChannelModal({
  visible,
  setVisible,
  companyId,
  channelIdsToExclude = [],
}: {
  companyId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  channelIdsToExclude?: string[];
}) {
  const { t } = useTranslation(["common", "pages"]);
  const [channelIdsToAdd, setChannelIdsToAdd] = useState<string[]>([]);
  const { ability } = useContext(UserAccessPolicyContext);

  const canViewChannels = ability.can("read", "Channel");

  const { selectedAccountId } = useSelectedAccount();

  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    { accountId: selectedAccountId },
    { enabled: canViewChannels },
  );

  const filteredChannels = useMemo(
    () =>
      channels
        ?.filter((channel) => !channelIdsToExclude.includes(channel.id))
        .filter((channel) =>
          ability.can(
            "update",
            makeSubject("Company", { accountId: selectedAccountId, companyId, channelId: channel.id }),
            "channelIds",
          ),
        ),
    [channels, channelIdsToExclude, ability, companyId, selectedAccountId],
  );

  const { mutate: updateCompanyChannels, isLoading: isAddingChannel } = useUpdateCompanyChannels({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      setChannelIdsToAdd([]);
      setVisible(false);
      message.success(t("pages:channel.addToCompanySuccessMessage"));
    },
  });

  const handleCancel = () => {
    setVisible(false);
    setChannelIdsToAdd([]);
  };

  return (
    <Modal
      title={t("pages:channel.connectedProfilesTitle")}
      open={visible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item label={t("pages:channel.choosePlaceholder")} name="channelIdsToAdd">
          <Select
            loading={isLoadingChannels}
            placeholder={t("pages:channel.choosePlaceholder")}
            mode="multiple"
            value={channelIdsToAdd}
            onChange={(value: string[]) => setChannelIdsToAdd([...value])}
            data-testid="channel-dropdown"
          >
            {filteredChannels?.map((channel) => (
              <Select.Option key={channel.id} value={channel.id} data-testid={`channel-${channel.id}`}>
                {channel.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="end">
          <Button onClick={handleCancel} style={{ marginRight: 10 }}>
            {t("buttons.discard")}
          </Button>
          <Button
            type="primary"
            onClick={() => updateCompanyChannels({ companyId, channelIds: channelIdsToAdd, action: "add" })}
            loading={isAddingChannel}
            disabled={!channelIdsToAdd.length || isLoadingChannels}
            data-testid="channel-invite-btn"
          >
            {t("buttons.add")}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
