import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { PostApi } from "services/api";
import { PostQueryKeys } from "services/query-keys";
import { POST_EDITING_STATUS, PostReviewComment } from "types";

export type ReviewPostData = {
  postVersionId: string;

  editingStatus: POST_EDITING_STATUS.APPROVED | POST_EDITING_STATUS.REJECTED;
};

const useReviewPost = (
  options?: Omit<UseMutationOptions<PostReviewComment, unknown, ReviewPostData, unknown>, "mutationFn">,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: PostApi.review,
    onSuccess: async (data, variables, ctx) => {
      await queryClient.invalidateQueries({ queryKey: PostQueryKeys.list() });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, ctx);
      }
    },
  });
};

export default useReviewPost;
