import { Button, Form, message, Modal, Select } from "antd";
import { AccountUser, ROLES, User } from "types";
import { usePagination } from "hooks/usePagination";
import { convertUTCDateToLocal } from "utils/date";
import { useMemo, useState } from "react";
import useUsers from "hooks/Users/useUsers";
import NoDataLocaleTable from "components/NoData";
import useAccountUsers from "hooks/useAccountUsers";
import { useTranslation } from "react-i18next";
import useDeleteUser from "hooks/Users/useDeleteUser";
import { AxiosError } from "axios";
import SearchBar from "components/SearchBar";
import IconButton from "components/IconButton/IconButton";
import CustomTable from "components/CustomTable";
import { ReactComponent as DeleteIcon } from "../../../assets/DeleteIcon.svg";
import sharedClasses from "../shared.module.css";
import classes from "./index.module.css";

const ReassignSelect = ({ accountUser }: { accountUser: AccountUser }) => {
  const { t } = useTranslation(["common", "pages"]);

  const { data: users, isLoading: isLoadingUsers } = useAccountUsers({
    accountId: accountUser.accountId,
  });

  const options = useMemo(() => {
    const options = [];

    if (users) {
      for (const user of users) {
        if (user.userId === accountUser.userId) {
          continue;
        }
        options.push({
          label: `${user.user.name} (${user.user.email})`,
          value: user.userId,
        });
      }
    }

    options.push({
      label: "Keine",
      value: "none",
    });

    return options;
  }, [users, accountUser]);

  return (
    <Form.Item
      name={accountUser.accountId}
      rules={[{ required: true, message: t("pages:adminUsers.assignSubstituteUserMessage") }]}
    >
      <Select options={options} loading={isLoadingUsers} />
    </Form.Item>
  );
};

const DeleteUserModal = ({ user, onCancel }: { user: User | undefined; onCancel: () => void }) => {
  const { t } = useTranslation(["common", "pages"]);

  const ownerAccountUsers = useMemo(
    () => user?.accountUsers.filter((accUser) => accUser.role === ROLES.EIGENTÜMER) ?? [],
    [user],
  );

  const [form] = Form.useForm<Record<string, string>>();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const { mutate: deleteUser, isLoading: isDeletingUser } = useDeleteUser({
    onSuccess: () => {
      message.success(t("pages:adminUsers.deleteUserSuccessMessage"));
      handleCancel();
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        if (err instanceof AxiosError) {
          message.error(err.response?.data?.message ?? err.message);
        } else {
          console.error(err);
        }
      }
      handleCancel();
    },
  });

  return (
    <Modal
      destroyOnClose
      open={!!user}
      onCancel={handleCancel}
      title={t("pages:adminUsers.deleteUserModalTitle")}
      footer={null}
    >
      <Form
        form={form}
        disabled={isDeletingUser}
        onFinish={(values) => {
          if (!user) return;
          const reassignments = [] as { userId: string; accountId: string }[];

          for (const [accountId, userId] of Object.entries(values)) {
            if (accountId === "none") continue;
            reassignments.push({ userId, accountId });
          }
          deleteUser({
            userId: user.id,
            reassignments,
            forceDelete: true,
          });
        }}
      >
        {ownerAccountUsers.length > 0 ? (
          <>
            <p>{t("pages:adminUsers.deleteUserModalAssignSubstituteLabel")}</p>
            {ownerAccountUsers.map((accountUser) => (
              <div key={accountUser.id}>
                <span>{accountUser.account?.name}</span>
                <ReassignSelect accountUser={accountUser} />
              </div>
            ))}
            <p className={classes.deleteUserWarningMessage}>*{t("pages:adminUsers.deleteUserWarningMessage")}</p>
          </>
        ) : (
          <p>{t("pages:adminUsers.deleteUserConfirmation")}</p>
        )}
        <div className={classes.deleteUserModalFooter}>
          <Button
            disabled={isDeletingUser}
            onClick={() => {
              handleCancel();
            }}
          >
            {t("common:buttons.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" disabled={isDeletingUser} loading={isDeletingUser}>
            {t("common:buttons.confirm")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const UserManagement = () => {
  const { t } = useTranslation(["common", "pages"]);
  const paginationState = usePagination(10);

  const [searchText, setSearchText] = useState("");

  const { data, isLoading } = useUsers({
    skip: paginationState.skip,
    take: paginationState.take,
    contains: searchText ? searchText : undefined,
    include: ["account", "channels"],
  });
  const allUsers = data?.items;

  const users = useMemo(
    () =>
      allUsers?.map((user) => {
        let role;
        if (user.accountUsers.some((accUser) => accUser.role === ROLES.EIGENTÜMER)) {
          role = "Owner";
        } else if (user.accountUsers.some((accUser) => accUser.role === ROLES.KUNDE)) {
          role = "Client";
        } else {
          role = "Member";
        }

        return {
          ...user,
          key: user.id,
          lastLogin: convertUTCDateToLocal(user.lastLogin).format("DD.MM.YYYY HH.mm[h]"),
          role,
        };
      }) ?? [],
    [allUsers],
  );

  const [userToDelete, setUserToDelete] = useState<User | undefined>();

  return (
    <div>
      <DeleteUserModal user={userToDelete} onCancel={() => setUserToDelete(undefined)} />
      <div className={sharedClasses.tableSearch}>
        <SearchBar
          setText={(value) => {
            setSearchText(value);
          }}
          paginationState={paginationState}
        />
      </div>
      <CustomTable
        headerPrefix={<CustomTable.Title level={3}>{t("pages:adminUsers.usersTableTitle")}</CustomTable.Title>}
        defaultProps={{
          dataSource: users,
          locale: NoDataLocaleTable,
          loading: isLoading,
          columns: [
            {
              title: t("pages:adminUsers.userNameLabel"),
              render: (_, record) => <span>{record.name}</span>,
            },
            {
              title: t("pages:adminUsers.emailLabel"),
              render: (_, record) => <span>{record.email}</span>,
            },
            {
              title: t("pages:adminUsers.roleLabel"),
              render: (_, record) => (
                <span>
                  {record.role === "Owner"
                    ? t("pages:adminUsers.ownerLabel")
                    : record.role === "Client"
                      ? t("pages:adminUsers.clientLabel")
                      : t("pages:adminUsers.memberLabel")}
                </span>
              ),
            },
            {
              title: t("pages:adminUsers.accountNameLabel"),
              render: (_, record) => {
                const totalAccounts = record._count?.accountUsers ?? 0;
                return (
                  <span>
                    {record.accountUsers?.[0]?.account?.name ?? "Unknown Account"}
                    {totalAccounts > 1 ? (
                      <span
                        style={{
                          marginLeft: "8px",
                          padding: "0.25rem",
                          borderRadius: "100%",
                          background: "#EEE",
                          fontWeight: 600,
                          fontSize: "12px",
                        }}
                      >
                        +{totalAccounts - 1}
                      </span>
                    ) : null}
                  </span>
                );
              },
            },
            {
              title: t("pages:adminUsers.lastLoginLabel"),
              render: (_, record) => <span>{record.lastLogin}</span>,
            },
            {
              title: t("common:labels.actions"),
              render: (record) => (
                <div>
                  <IconButton alt="Delete user" svgColorProp="fill" onClick={() => setUserToDelete(record)}>
                    <DeleteIcon fill={undefined} />
                  </IconButton>
                </div>
              ),
            },
          ],
        }}
        paginationProps={{ ...paginationState, total: data?.total ?? 0 }}
      />
    </div>
  );
};

export default UserManagement;
