import { ChangeEvent, ClipboardEvent, KeyboardEvent, useRef, useState } from "react";
import { Button, Flex, Row, Typography, message } from "antd";
import { useMutation } from "react-query";

import { parseJwt } from "utils/jwt";
import { AuthApi } from "services/api";
import { useTranslation } from "react-i18next";
import classes from "./OTPInput.module.css";

export default function OTPInput({
  email,
  next,
  verify,
}: {
  email: string;
  next: (data: any) => void;
  verify: (email: string, otp: number) => Promise<any>;
}) {
  const { t } = useTranslation(["common", "pages"]);
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const { mutate, isLoading: loading } = useMutation(
    () => {
      if (otp.some((o) => !o)) {
        throw new Error(t("validation.fillAllDetailsError"));
      }
      return verify(email, Number(otp.join("")));
    },
    {
      onSuccess: (data) => {
        const decodedUserInfo = parseJwt(data.result.access_token);
        localStorage.setItem("EPL_USER_INFO", JSON.stringify(decodedUserInfo));

        next(data);
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const { mutate: resendOtp, isLoading: resendLoading } = useMutation(() => AuthApi.resendOtp(email), {
    onSuccess: () => {
      message.success(t("pages:verifyOtp.resendOtpSuccessMessage"));
    },
    onError: (error: Error) => {
      message.error(error.message);
    },
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    const newOtp = [...otp];

    // Ensure that only digits are entered
    if (!/^\d*$/.test(value)) {
      return;
    }

    if (value.length > 1) {
      // Handle pasting of multiple characters
      if (!/^\d*$/.test(value)) {
        newOtp[index] = "";
        setOtp(newOtp);
        return;
      }

      const chars = value.split("");
      for (let i = 0; i < chars.length; i++) {
        if (index + i >= newOtp.length) break;
        newOtp[index + i] = chars[i];
      }

      setOtp(newOtp);
      inputsRef.current[Math.min(newOtp.length - 1, index + chars.length)].focus();
    } else {
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index + 1 < newOtp.length) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      inputsRef.current[index - 1].focus();
    } else if (e.key === "Delete" && index < otp.length - 1) {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      inputsRef.current[index].focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      e.preventDefault();
      inputsRef.current[index - 1].focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      e.preventDefault();
      inputsRef.current[index + 1].focus();
    }
  };

  const handleFocus = (index: number) => {
    // Select the entire value when the input is focused
    if (inputsRef.current[index]) {
      inputsRef.current[index].select();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>, index: number) => {
    const paste = e.clipboardData.getData("text");
    const newOtp = [...otp];
    if (!/^\d*$/.test(paste)) {
      e.preventDefault();
      return;
    }

    // Paste the content
    const chars = paste.split("");
    for (let i = 0; i < chars.length; i++) {
      if (index + i >= newOtp.length) break;
      newOtp[index + i] = chars[i];
    }

    setOtp(newOtp);

    // Focus the next input after pasting
    inputsRef.current[Math.min(newOtp.length - 1, index + chars.length)].focus();
    e.preventDefault();
  };

  return (
    <Flex vertical align="center" gap={32}>
      <Flex vertical className={classes.otpForm} gap={24}>
        <Row>
          <Typography className={classes.otpFormTitle}>{t("pages:verifyOtp.title")}</Typography>
        </Row>
        <Row>
          <Typography className={classes.otpFormSubtitle}>
            {t("pages:verifyOtp.subtitleLine1")}
            <br />
            {t("pages:verifyOtp.subtitleLine2")}
          </Typography>
        </Row>
      </Flex>
      <Flex vertical gap={32}>
        <Row>
          <div className={classes.otpInput}>
            {otp.map((value, index) => (
              <input
                key={index}
                disabled={resendLoading || loading}
                type="text"
                value={value}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={() => handleFocus(index)}
                onPaste={(e) => handlePaste(e, index)}
                ref={(el) => (inputsRef.current[index] = el as HTMLInputElement)}
                maxLength={1}
                pattern="\d*"
                inputMode="numeric"
              />
            ))}
            <input disabled={resendLoading || loading} type="hidden" name="otp" value={otp.join("")} />
          </div>
        </Row>
        <Flex vertical gap={26}>
          <Row>
            <Button
              disabled={loading || resendLoading}
              loading={loading}
              className={classes.submitBtn}
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                mutate();
              }}
            >
              {t("pages:verifyOtp.verifyButton")}
            </Button>
          </Row>
          <Row justify="center">
            <Typography className={classes.resendOtpLabel}>
              {t("pages:verifyOtp.resentOtpLabel")}{" "}
              <Button
                type="link"
                disabled={loading || resendLoading}
                className={classes.resendOtpLink}
                onClick={() => resendOtp()}
              >
                {t("pages:verifyOtp.resendOtpButton")}
              </Button>
            </Typography>
          </Row>
        </Flex>
      </Flex>
    </Flex>
  );
}
