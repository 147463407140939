import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useRouterContext } from "@pankod/refine-core";
import { Button, Form, Input, Row, message } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";

import { AuthApi } from "services/api";
import { validatePassword } from "utils";
import EPLlogo from "../../assets/EPLlogo.png";
import classes from "./ResetPassword.module.css";

type FormData = {
  confirmPassword: string;
  password: string;
};

const renderEyeIcon = (visible: boolean) => {
  return visible ? <EyeOutlined /> : <EyeInvisibleOutlined />;
};

export default function ResetPassword() {
  const { token } = useRouterContext().useParams<{ token: string }>();
  const [formData, setFormData] = useState<FormData>({ confirmPassword: "", password: "" });

  const { push } = useRouterContext().useHistory();

  const handleRedirect = async () => {
    push("/account/-/company/-/dashboard");
  };

  const { mutate, isLoading, isError } = useMutation(
    "reset-password",
    (data: FormData) => {
      if (!data.confirmPassword || !data.password || !token) {
        throw new Error("Bitte alle Felder ausfüllen");
      }
      if (data.confirmPassword !== data.password) {
        throw new Error("Passwörter stimmen nicht überein");
      }
      if (!validatePassword(data.password)) {
        throw new Error(
          "Nicht englische Alphabete sind nicht erlaubt.Bitte verwenden Sie nur englische Alphabete, Zahlen und Sonderzeichen",
        );
      }
      if (data.password.length < 8) {
        throw new Error("Das Passwort muss länger als oder gleich 8 Zeichen sein");
      }

      return AuthApi.resetPassword({
        password: data.password,
        token,
      });
    },
    {
      onSuccess: async () => {
        message.success("Passwort erfolgreich zurückgesetzt");
        await handleRedirect();
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <Row className={classes.LoginContainer}>
      <Row align="middle" className={classes.contentContainer}>
        <Row align="middle" className={classes.LoginForm}>
          <Row className={classes.LoginForm}>
            <Row align="middle">
              <div className={classes.ImageWrapper}>
                <img className={classes.Image} src={EPLlogo} alt="Logo" />
              </div>
            </Row>
            <Row className={classes.heading}>Passwort zurücksetzen</Row>
            <Form>
              <Row className={classes.inputBoxContainer}>
                <Input.Password
                  className={classes.inputBoxes}
                  size="large"
                  placeholder="Passwort"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  iconRender={renderEyeIcon}
                />
                <Input.Password
                  className={classes.inputBoxes}
                  size="large"
                  placeholder="Bestätige das Passwort"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }))
                  }
                  iconRender={renderEyeIcon}
                />
              </Row>
              <Row>
                <Button
                  loading={!isError && isLoading}
                  disabled={!isError && isLoading}
                  className={classes.submitButton}
                  onClick={() => mutate(formData)}
                  data-testid="login-btn"
                  htmlType="submit"
                >
                  Anmelden
                </Button>
              </Row>
            </Form>
          </Row>
        </Row>
      </Row>
    </Row>
  );
}
