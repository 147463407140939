import { Input } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { mergeClasses } from "utils/mergeClasses";
import { UsePaginationState } from "hooks/usePagination";
import { ReactComponent as InputSearch } from "../../InputSearch.svg";
import classes from "./SearchBar.module.css";

export default function SearchBar({
  setText,
  paginationState,
  className,
  placeholder,
}: {
  className?: string;
  setText: (value: string) => void;
  placeholder?: string;
  paginationState?: UsePaginationState;
}) {
  const { t } = useTranslation(["common"]);
  const [value, setValue] = useState<string>("");

  const setPage = paginationState?.setPage;

  useEffect(() => {
    const timer = setTimeout(() => {
      setText(value);
      if (setPage) {
        setPage(1);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <Input
      placeholder={placeholder ?? t("common:placeholders.search")}
      prefix={<InputSearch className={classes.SearchIcon} />}
      className={mergeClasses(classes.SearchBar, className)}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      size="large"
    />
  );
}
