import { useMutation, useQueryClient } from "react-query";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";

const useCreateCompany = (options?: {
  onSuccess?: (
    data: Awaited<ReturnType<typeof CompanyApi.create>>,
    variables: Parameters<typeof CompanyApi.create>[0],
  ) => void | Promise<void>;
  onError?: (e: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CompanyApi.create,
    onError: options?.onError,
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey: CompanyQueryKeys.list() });
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
};

export default useCreateCompany;
