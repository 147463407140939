import { useMutation } from "react-query";
import { PaymentApi } from "services/api";

type CreateCheckoutSessionResult = Awaited<ReturnType<typeof PaymentApi.checkout>>;
type CreateCheckoutSessionInput = Parameters<typeof PaymentApi.checkout>[0];

const useCheckoutSession = (options?: {
  onSuccess?: (data: CreateCheckoutSessionResult, variables: CreateCheckoutSessionInput) => void | Promise<void>;
  onError?: (error: unknown, variables: CreateCheckoutSessionInput) => void | Promise<void>;
  onSettled?: (
    data: CreateCheckoutSessionResult | undefined,
    error: unknown,
    variables: CreateCheckoutSessionInput,
  ) => void | Promise<void>;
}) => {
  return useMutation({
    mutationFn: PaymentApi.checkout,
    onSettled: options?.onSettled,
    onSuccess: async (data, variables) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
    onError: options?.onError,
  });
};

export default useCheckoutSession;
