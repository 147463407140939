import { Button, Col, Row, Table, TableProps, Typography } from "antd";
import NoDataLocaleTable from "components/NoData";
import { UsePaginationState } from "hooks/usePagination";
import React, { ForwardedRef } from "react";
import { useTranslation } from "react-i18next";

import { mergeClasses } from "utils/mergeClasses";
import classes from "./index.module.css";

type CustomTableProps<T> = {
  defaultProps: TableProps<T>;
  headerPrefix?: React.ReactNode;
  headerContent?: React.ReactNode;
  headerSuffix?: React.ReactNode;
  paginationProps?: UsePaginationState & { total: number | undefined };
};

const CustomTable = <T extends object>(props: CustomTableProps<T>) => {
  const { t } = useTranslation(["common", "pages"]);
  const { defaultProps, headerPrefix, headerContent, headerSuffix, paginationProps } = props;

  return (
    <Table
      {...defaultProps}
      className={mergeClasses(classes.table, defaultProps.className)}
      locale={NoDataLocaleTable}
      title={
        !headerPrefix && !headerSuffix
          ? undefined
          : () => (
              <Row justify="space-between">
                <Col>{headerPrefix}</Col>
                <Col>{headerContent}</Col>
                <Col>{headerSuffix}</Col>
              </Row>
            )
      }
      pagination={{
        showSizeChanger: false,
        ...(paginationProps && {
          pageSize: paginationProps.pageSize,
          current: paginationProps.page,
          onChange: paginationProps.onChange,
          total: paginationProps.total,
        }),
        itemRender: (_, type) => {
          if (type === "prev") {
            return (
              <Button className={classes.footerButton} type="default">
                {t("buttons.back")}
              </Button>
            );
          }
          if (type === "next") {
            return (
              <Button className={classes.footerButton} type="default">
                {t("buttons.next")}
              </Button>
            );
          }
          return undefined;
        },
        showTotal: (total, range) => {
          return (
            <span>
              Seite {Math.ceil(range[0] / ((paginationProps?.pageSize ?? 10) * 1.0))} von{" "}
              {Math.ceil(total / ((paginationProps?.pageSize ?? 10) * 1.0))}
            </span>
          );
        },
      }}
    />
  );
};

const CustomTableTitle = React.forwardRef(
  (
    { children, className, level = 3, ...rest }: React.ComponentPropsWithoutRef<typeof Typography.Title>,
    ref: ForwardedRef<HTMLHeadingElement>,
  ) => {
    return (
      <Typography.Title {...rest} level={level} className={mergeClasses(classes.headerTitle, className)} ref={ref}>
        {children}
      </Typography.Title>
    );
  },
);
CustomTableTitle.displayName = "CustomTableTitle";
CustomTable.Title = CustomTableTitle;

export default CustomTable;
