import { useQuery } from "react-query";
import { PostApi } from "services/api";
import { PostQueryKeys } from "services/query-keys";
import { UseCustomQueryOptions } from "types";

export type TGetPostStatsParams = {
  accountId: string;
  companyId: string;
};

type GetPostStatsResult = Awaited<ReturnType<typeof PostApi.stats>>;

const usePostStats = <TSelectData = GetPostStatsResult>(
  filters: TGetPostStatsParams,
  options?: UseCustomQueryOptions<GetPostStatsResult, TSelectData>,
) => {
  return useQuery({
    queryKey: PostQueryKeys.stats(filters),
    queryFn: async () => {
      return PostApi.stats(filters);
    },
    ...options,
    //Cache for 1 minute
    staleTime: options?.staleTime ?? 60_000,
    initialData: options?.initialData ?? {
      publishedPostCount: 0,
      scheduledPostCount: 0,
      totalPostCount: 0,
    },
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default usePostStats;
