import { Card, Spin } from "antd";
import React, { ForwardedRef } from "react";
import { mergeClasses } from "utils/mergeClasses";
import ChartStat from "pages/ChartStat";
import { ApexOptions } from "apexcharts";
import classes from "./index.module.css";

export const createStatChartCardOptions = ({
  color,
  categories,
  data,
  name,
  yaxisText,
}: {
  color: "red" | "blue";
  categories: string[];
  data: number[];
  name: string;
  yaxisText: string;
}) => {
  return {
    chart: {
      type: "line",
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
    },
    colors: [color === "blue" ? "hsla(214, 100%, 57%, 1)" : "hsla(344, 57%, 54%, 1)"],
    series: [
      {
        name,
        data,
      },
    ],
    xaxis: {
      categories,
      labels: {},
      tickAmount: "dataPoints",
    },
    yaxis: {
      title: {
        text: yaxisText,
        style: { fontWeight: 500, fontFamily: "Montserrat", fontSize: "12px", color: "hsla(220, 13%, 46%, 1)" },
      },
    },
    legend: {
      show: true,
      showForNullSeries: true,
      showForSingleSeries: true,
      showForZeroSeries: true,
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
  } satisfies ApexOptions;
};

const StatChartCard = React.forwardRef(
  (
    {
      className,
      children,
      title,
      icon,
      stat,
      loading = false,
      ...rest
    }: React.ComponentPropsWithoutRef<typeof Card> & {
      title: string;
      icon: React.ReactNode;
      stat: number | number;
      loading?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <Card {...rest} className={mergeClasses(classes.chartCard, className)} ref={ref}>
        <Spin spinning={loading}>
          <div className={classes.chartCardWrapper}>
            <div className={classes.chartCardHeader}>
              <ChartStat icon={icon} text={title} value={stat} />
            </div>
            <div className={classes.chartCardContent}>{children}</div>
          </div>
        </Spin>
      </Card>
    );
  },
);

export default StatChartCard;
