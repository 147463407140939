import { useQuery } from "react-query";
import { RetryValue } from "react-query/types/core/retryer";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";
import { Company } from "types";

const useCompany = (
  companyId: string,
  options?: {
    onSuccess?: (data: Company) => void;
    include?: ("users" | "channels" | "posts" | "account")[];
    retry?: RetryValue<unknown>;
  },
) => {
  return useQuery({
    queryKey: CompanyQueryKeys.details(companyId, options),
    queryFn: async () => {
      return CompanyApi.details(companyId, { include: options?.include });
    },
    onSuccess: options?.onSuccess,
    enabled: !!companyId,
    staleTime: Infinity,
    refetchOnWindowFocus: true,
    retry: options?.retry,
  });
};

export default useCompany;
