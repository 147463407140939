import { CreatePost, Post, PostPublishingStatusType } from "types";
import { validatePostContent } from "pages/PostEditor/helpers/contentValidators";
import { ComponentType, MessageType } from "pages/PostEditor/helpers/assetValidators";
import { Flex, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import classes from "../PostEditor.module.css";
import FormComponent from "../NewComponents/FormComponent";
import CommentSection from "../NewComponents/CommentSection";
import BodyComponent from "./Body/BodyComponent";
import AccountsComponent from "./Account";
import AssetsComponent from "./Assets";
import ActionComponent from "./Action";
import ScheduleComponent from "./Schedule";

export type PostContentProps = {
  loading: boolean;
  localPost: CreatePost;
  setLocalPost: React.Dispatch<React.SetStateAction<CreatePost>>;
  showCropModal: boolean;
  setShowCropModal: (state: boolean) => void;
  postSchedule: boolean;
  setPostSchedule: (state: boolean) => void;
  setZipModalOpen: (state: boolean) => void;
  callCreatePost: ({ type, publishMobile }: { type: PostPublishingStatusType; publishMobile: boolean }) => void;
  post: Post | undefined;
  requiresPostReview: boolean;
  deletePost: (id: string) => void;
};

const PostContent = (props: PostContentProps) => {
  const {
    loading,
    localPost,
    setLocalPost,
    callCreatePost,
    showCropModal,
    setShowCropModal,
    post,
    setZipModalOpen,
    postSchedule,
    setPostSchedule,
    deletePost,
    requiresPostReview,
  } = props;

  const validateLocalPost = validatePostContent(localPost);
  const isPostContentValid =
    !validateLocalPost.some((result) => result.messageType === MessageType.ERROR) &&
    !validateLocalPost.some((result) => result.valid === false);

  return (
    <Flex vertical gap={24} className={classes.contentContainer}>
      <CommentSection post={post} />
      <FormComponent
        title="POSTEN IN"
        componentType={ComponentType.ACCOUNT}
        postHelpTexts={validateLocalPost.filter((item) => item.type === ComponentType.ACCOUNT)}
        child={<AccountsComponent localPost={localPost} setLocalPost={setLocalPost} />}
      />
      <FormComponent
        title="BILD/VIDEO"
        componentType={ComponentType.ASSET}
        postHelpTexts={validateLocalPost.filter((item) => item.type === ComponentType.ASSET)}
        child={
          <AssetsComponent
            post={post}
            localPost={localPost}
            setLocalPost={setLocalPost}
            showCropModal={showCropModal}
            setShowCropModal={setShowCropModal}
            setZipModalOpen={setZipModalOpen}
          />
        }
      />
      <FormComponent
        title="TEXT"
        componentType={ComponentType.BODY}
        postHelpTexts={validateLocalPost.filter((item) => item.type === ComponentType.BODY)}
        child={<BodyComponent localPost={localPost} setLocalPost={setLocalPost} />}
      />
      <FormComponent
        title="PLANEN"
        titleSuffix={
          !requiresPostReview ? (
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={setPostSchedule}
            />
          ) : null
        }
        componentType={ComponentType.ACTION}
        postHelpTexts={validateLocalPost.filter((item) => item.type === ComponentType.ACTION)}
        child={
          <ScheduleComponent
            post={post}
            localPost={localPost}
            setLocalPost={setLocalPost}
            isLoading={loading}
            callCreatePost={callCreatePost}
            isPostContentValid={isPostContentValid}
            schedule={postSchedule}
          />
        }
      />
      <FormComponent
        showHeader={false}
        componentType={ComponentType.ACTION}
        postHelpTexts={validateLocalPost.filter((item) => item.type === ComponentType.ACTION)}
        child={
          <ActionComponent
            post={post}
            localPost={localPost}
            setLocalPost={setLocalPost}
            isLoading={loading}
            callCreatePost={callCreatePost}
            isPostContentValid={isPostContentValid}
            schedule={postSchedule}
            deletePost={deletePost}
            requiresPostReview={requiresPostReview}
          />
        }
      />
    </Flex>
  );
};

export default PostContent;
