import { IMG_SIZE_LIMIT } from "constants/editor";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Upload,
  FormInstance,
  UploadFile,
  message,
  Spin,
  Button,
} from "antd";
import { RcFile } from "@pankod/refine-antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { UploadOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import { TODO_PRIORITY, PLATFORMS, Todo } from "types";
import {
  getDisabledTime,
  convertDateTimeValuesToLocal,
  getCurrentLocalDate,
  convertLocalDateToUTC,
  convertUTCDateToLocal,
} from "utils/date";
import getBase64 from "utils/image";
import dayjs from "dayjs";
import { TodosAPI } from "services/api";
import useSelectedAccount from "hooks/useSelectedAccount";
import useAccount from "hooks/useAccount";
import useCompanies from "hooks/company/useCompanies";
import { useTranslation } from "react-i18next";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useMe from "hooks/useMe";
import { makeSubject } from "utils/access";
import useAccountUsers from "hooks/useAccountUsers";
import useCompany from "hooks/company/useCompany";
import { priorityTranslated } from "../constants";
import classes from "./TodoModal.module.css";

export default function TodoModal({
  open,
  closeModal,
  form,
  onSubmit,
  isLoading,
  todoId,
}: {
  open: boolean;
  closeModal: () => void;
  form: FormInstance;
  onSubmit: (todoId?: string) => void;
  isLoading: boolean;
  todoId: string;
}) {
  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();
  const { data: account, isLoading: isLoadingAccount } = useAccount(selectedAccountId);
  const { data: me, isLoading: isLoadingMe } = useMe(selectedAccountId);
  const { ability } = useContext(UserAccessPolicyContext);

  const { t } = useTranslation(["common", "pages"]);

  const { data, isLoading: isLoadingCompanies } = useCompanies(
    { accountId: selectedAccountId },
    { enabled: !selectedCompanyId },
  );

  const { data: company } = useCompany(selectedCompanyId);

  const companies = useMemo(
    () =>
      (selectedCompanyId && company ? [company] : data?.items)?.filter((item) =>
        ability.can(
          "create",
          makeSubject("Todo", {
            accountId: item.accountId,
            companyId: item.id,
          }),
        ),
      ) ?? [],
    [data, ability, selectedCompanyId, company],
  );

  const { isLoading: isTodoDataLoading } = useQuery<Todo>(
    ["todo", todoId],
    async () => {
      const data = TodosAPI.get(selectedAccountId, todoId);
      return data;
    },
    {
      enabled: !!todoId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        form.setFieldsValue({
          title: data?.title,
          description: data?.description,
          dueAt: convertLocalDateToUTC(data?.dueAt),
          priority: data?.priority,
          platform: data?.platform,
          status: data?.status,
          assignedTo: data?.assignedTo,
          files: data?.files,
          accountId: data?.accountId,
          companyId: data?.companyId ?? "None",
        });

        if (data.files) {
          const files = data.files.map(
            (url: string) =>
              ({
                uid: url.split("/").pop(),
                name: url.split("/").pop(),
                status: "done",
                url,
              }) as UploadFile,
          );
          setFileList(files);
        }

        const dueAt = convertUTCDateToLocal(data?.dueAt);

        setDueDate((prevState) => ({
          ...prevState,
          date: dueAt.format("YYYY-MM-DD"),
          time: dueAt.format("HH:mm"),
        }));
      },
    },
  );

  const companyId = Form.useWatch("companyId", form);
  const assignedTo = Form.useWatch("assignedTo", form);

  const { data: allUsers, isLoading: isLoadingAllUsers } = useAccountUsers(
    {
      accountId: selectedAccountId,
      companyId: companyId === "None" ? undefined : companyId,
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const users = useMemo(
    () =>
      allUsers?.filter((user) =>
        ability.can(
          "create",
          makeSubject("Todo", {
            accountId: selectedAccountId,
            companyId: selectedCompanyId,
            assignedToId: user.userId,
            userId: me?.id,
          }),
        ),
      ) ?? [],
    [allUsers, selectedAccountId, selectedCompanyId, ability, me],
  );

  const [dueDate, setDueDate] = useState({
    date: getCurrentLocalDate().add(20, "minute").format("YYYY-MM-DD"),
    time: getCurrentLocalDate().add(20, "minute").format("HH:mm"),
  });

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const isAssigneeDeleted = useMemo(
    () =>
      !assignedTo || isLoadingAllUsers || !allUsers ? false : !allUsers.some((user) => user.userId === assignedTo),
    [allUsers, assignedTo, isLoadingAllUsers],
  );

  useEffect(() => {
    if (!todoId) form.setFieldValue("dueAt", convertDateTimeValuesToLocal(dueDate.date, dueDate.time));
  }, []);

  return (
    <Modal
      open={open}
      width={1000}
      okText="Aufgabe senden"
      cancelText="Abbrechen"
      onCancel={closeModal}
      className={classes.modal}
      onOk={() => form.submit()}
      okButtonProps={{
        loading: isLoading,
      }}
      styles={{
        content: {
          height: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
        },
      }}
    >
      <h1 className="heading">Aufgabe Erstellen</h1>

      <Spin spinning={isTodoDataLoading}>
        <Form
          colon={false}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={() => {
            form.setFieldValue("files", fileList);
            onSubmit();
          }}
        >
          <Form.Item
            label="Aufgabe"
            name="title"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (value && value.trim().length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Bitte geben Sie einen Titel ein");
                },
              },
            ]}
          >
            <Input className={classes.Input} />
          </Form.Item>

          <Row justify="space-between">
            <Col lg={11} md={11} sm={24} xs={24}>
              <Form.Item
                label={t("pages:todos.accountLabel")}
                name="accountId"
                initialValue={selectedAccountId}
                rules={[
                  {
                    required: true,
                    message: "Bitte wähle einen Konto aus",
                  },
                ]}
              >
                <Select loading={isLoadingAccount || isLoadingMe || isLoadingAllUsers}>
                  <Select.Option value={selectedAccountId}>{account?.name}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={t("pages:todos.companyLabel")}
                name="companyId"
                initialValue="None"
                rules={[
                  {
                    required: true,
                    message: "Bitte wähle einen Unternehmen aus",
                  },
                ]}
              >
                <Select loading={isLoadingCompanies}>
                  {companies?.map((company) => <Select.Option value={company.id}>{company.name}</Select.Option>)}
                  <Select.Option value="None">Keine</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col lg={11} md={11} sm={24} xs={24}>
              <Form.Item
                label="Zugwiesen an"
                name="assignedTo"
                rules={[
                  {
                    required: true,
                    message: "Bitte wähle einen Benutzer aus",
                  },
                ]}
                validateStatus={isAssigneeDeleted ? "error" : undefined}
                help={
                  isAssigneeDeleted
                    ? "Dieser Benutzer existiert nicht oder ist nicht mehr Teil des Unternehmens"
                    : undefined
                }
              >
                <Select labelRender={({ label }) => (isAssigneeDeleted ? "" : label)}>
                  {users?.map((user) => <Select.Option value={user.userId}>{user?.user?.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Priorität"
                name="priority"
                rules={[
                  {
                    required: true,
                    message: "Bitte wähle eine Priorität aus",
                  },
                ]}
              >
                <Select>
                  {Object.values(TODO_PRIORITY).map((priority) => (
                    <Select.Option value={priority}>{priorityTranslated[priority]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col lg={11} md={11} sm={24} xs={24}>
              <Form.Item label="Fällig am" name="dueAt" required>
                <Row justify="space-between" className={classes.dateTimePicker}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <DatePicker
                      className={classes.Input}
                      placeholder="Datum wählen"
                      format="DD.MM.YYYY"
                      disabledDate={(current) => current && current < dayjs().subtract(1, "day")}
                      value={dayjs(dueDate.date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        setDueDate((prevState) => ({
                          ...prevState,
                          date: date?.format("YYYY-MM-DD") || "",
                        }));
                        form.setFieldValue(
                          "dueAt",
                          convertDateTimeValuesToLocal(date?.format("YYYY-MM-DD") || "", dueDate.time),
                        );
                      }}
                    />
                  </Col>
                  <Col lg={11} md={11} sm={24} xs={24}>
                    <TimePicker
                      className={classes.Input}
                      placeholder="Zeit wählen"
                      format="HH:mm"
                      disabledTime={() => getDisabledTime(dueDate.date)}
                      value={dayjs(dueDate.time, "HH:mm")}
                      onChange={(time) => {
                        setDueDate((prevState) => ({
                          ...prevState,
                          time: time?.format("HH:mm") || "",
                        }));
                        form.setFieldValue(
                          "dueAt",
                          convertDateTimeValuesToLocal(dueDate.date, time?.format("HH:mm") || ""),
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label="Plattform"
                name="platform"
                rules={[
                  {
                    required: true,
                    message: "Bitte wähle eine Plattform aus",
                  },
                ]}
              >
                <Select mode="multiple" className={classes.Select}>
                  {Object.values(PLATFORMS).map((platform) => (
                    <Select.Option value={platform} style={{ textTransform: "capitalize" }}>
                      {platform.toLocaleLowerCase()}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Beschreibung" name="description">
            <Input.TextArea className={classes.Input} style={{ height: 150 }} />
          </Form.Item>

          <Form.Item label="Anhänge" name="files">
            {fileList.length < 1 ? (
              <Upload.Dragger
                className={classes.upload}
                showUploadList={false}
                multiple
                beforeUpload={(file) => {
                  if (file.type !== "image/jpeg") {
                    message.error("Nur JPEG Dateien sind erlaubt!");
                  }
                  return false;
                }}
                onChange={({ fileList: newFileList }) => {
                  newFileList.forEach((item) => {
                    if (item.size && item.size > IMG_SIZE_LIMIT) {
                      // eslint-disable-next-line no-param-reassign
                      item.status = "error";
                    }
                  });
                  setFileList(newFileList);
                }}
                accept="image/jpeg"
                data-testid="post-image-upload"
              >
                <div className={classes.text}>
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ color: "#000" }} />
                  </p>
                  <p className="ant-upload-text">Bild oder Video Material per Drag&Drop platzieren.</p>
                  <p>oder</p>
                  <p className={classes.browseBtn}>Durchsuchen</p>
                </div>
              </Upload.Dragger>
            ) : (
              <Upload
                multiple
                listType="picture-card"
                accept="image/jpeg"
                fileList={fileList}
                className={classes.upload}
                beforeUpload={(file) => {
                  if (file.type !== "image/jpeg") {
                    message.error("Nur JPEG Dateien sind erlaubt!");
                  }
                  return false;
                }}
                onChange={({ fileList: newFileList }) => {
                  newFileList.forEach((item) => {
                    if (item.size && item.size > IMG_SIZE_LIMIT) {
                      // eslint-disable-next-line no-param-reassign
                      item.status = "error";
                    }
                  });
                  setFileList(newFileList);
                }}
                onPreview={async (file) => {
                  if (!file.url && !file.preview) {
                    // eslint-disable-next-line no-param-reassign
                    file.preview = await getBase64(file.originFileObj as RcFile);
                  }
                }}
                data-testid="post-image-upload"
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Hinzufügen</div>
                </div>
              </Upload>
            )}
          </Form.Item>
          {fileList.length > 0 && todoId !== "" && (
            <Button
              type="default"
              onClick={async () => {
                fileList.map(async (file) => {
                  const link = document.createElement("a");
                  if (file.url) {
                    const fileData = await fetch(file.url).then((r) => r.blob());
                    const fileBlob = URL.createObjectURL(fileData);
                    link.href = fileBlob;
                  } else {
                    link.href = URL.createObjectURL(file.originFileObj as RcFile);
                  }
                  link.download = file.name;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              }}
            >
              <DownloadOutlined /> Anhänge herunterladen
            </Button>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}
