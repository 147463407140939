import { useState } from "react";
import { Spin } from "antd";
import useSelectedAccount from "hooks/useSelectedAccount";
import useTodos from "hooks/todos/useTodos";
import { usePagination } from "hooks/usePagination";
import useMe from "hooks/useMe";
import TodosContainer from "../TableContainer";
import { TodosTableFilters } from "../TodosTable";

export default function MyTodos() {
  const { data: me } = useMe();
  const [searchText, setSearchText] = useState<string>("");

  const { selectedAccountId: accountId, selectedCompanyId: companyId } = useSelectedAccount({ companyRequired: false });

  const paginationState = usePagination(10);
  const [filters, setFilters] = useState<TodosTableFilters>({});

  const { data, isFetching } = useTodos(
    {
      accountId,
      companyIds: filters.companyId
        ? filters.companyId.flatMap((item) => (item === "None" ? ["null"] : [item]))
        : companyId
          ? [companyId, "null"]
          : undefined,
      status: filters.status,
      priority: filters.priority,
      assignee: me?.id,
      contains: searchText,
      skip: paginationState.skip,
      take: paginationState.take,
    },
    {
      enabled: !!me?.id,
    },
  );

  return (
    <Spin spinning={isFetching}>
      <TodosContainer
        data={data?.todos ?? []}
        total={data?.total ?? 0}
        openCount={data?.count?.OPEN ?? 0}
        doneCount={data?.count?.DONE ?? 0}
        progressCount={data?.count?.IN_PROGRESS ?? 0}
        setSearchText={setSearchText}
        paginationState={paginationState}
        filters={filters}
        setFilters={setFilters}
      />
    </Spin>
  );
}
