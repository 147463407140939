import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// Get timezone from API
dayjs.tz.setDefault("Europe/Berlin");

export const convertUTCDateToLocal = (date: string) => {
  return dayjs.utc(date).tz();
};

export const convertDateTimeValuesToLocal = (date: string, time: string) => {
  return dayjs.tz(`${date}T${time}`).format();
};

export const convertLocalDateToUTC = (date: string) => {
  return dayjs(date).utc().format();
};

export const getCurrentLocalDate = () => {
  return dayjs.tz(dayjs());
};

export const getLocalDate = (date: string) => {
  return dayjs.tz(date);
};

// disabled time is the time that is less than now if selected date is today for time picker
export const getDisabledTime = (date: string) => {
  if (dayjs(date).startOf("day").isSame(dayjs().startOf("day"))) {
    const now = dayjs.tz(dayjs());

    const minTime = now.add(15, "minute");

    const minHour = minTime.hour();
    const minMin = minTime.minute();

    return {
      disabledHours: () =>
        Array.from(
          {
            length: minHour,
          },
          (_, i) => i,
        ),
      disabledMinutes: (h: number) =>
        h <= minHour
          ? Array.from(
              {
                length: minMin,
              },
              (_, i) => i,
            )
          : [],
    };
  }
  return {};
};

export const getPeriodEndDate = (date: string, product: string) => {
  const parts = date.split(".");
  const day = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1;
  const year = parseInt(parts[2]);

  const originalDate = new Date(year, month, day);

  if (product.includes("Monatlich")) {
    // Increase the date by one month
    originalDate.setMonth(originalDate.getMonth() + 1);
  } else if (product.includes("Jährlich")) {
    // Increase the date by one year
    originalDate.setFullYear(originalDate.getFullYear() + 1);
  } else {
    return null;
  }

  const newDate = originalDate.toLocaleDateString("de-DE");
  return newDate;
};

export const formatDate = (date: Date | string) => {
  return `${dayjs(date).format("DD.MM.YYYY HH.mm")}h`;
};
