import { useRouterContext } from "@pankod/refine-core";
import Loader from "components/Loader";
import { useQuery } from "react-query";
import { AccountApi } from "services/api";

export default function CheckUserInvite() {
  const { push } = useRouterContext().useHistory();
  const { token } = useRouterContext().useParams<{ token: string }>();

  // TODO: ASK IF THIS PAGE IS BEING USED

  useQuery(
    "check-invite",
    () => {
      if (!token) {
        push("/login");
        return Promise.reject();
      }
      return AccountApi.checkInvite(token);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!token,
      onSuccess: (data) => {
        if (data === "NEEDSIGNUP") {
          push(`/register/${token}`);
        } else if (data === "NEEDLOGIN") {
          push(`/account/-/company/-/dashboard/${token}`);
        } else if (data === "EXPIRED") {
          push(`/account/-/company/-/dashboard/${token}/expired`);
        } else {
          push("/login");
        }
      },
    },
  );

  return <Loader />;
}
