import { useState } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { Button } from "antd";
import { MarkerProps } from "types";
import dropPin from "../../../../../../../assets/dropPin.png";
import "../Map.css";

type Coordinate = {
  lat: number;
  lng: number;
};

type Coordinates = {
  lat: number;
  lng: number;
  radius: number;
};

export default function CustomMarker({ setMarkerPosition, mapRef }: MarkerProps) {
  const [markerOnScreen, setMarkerOnScreen] = useState<boolean>(false);
  const [position, setPosition] = useState<Coordinate | null>(null);

  const handleClickDropPin = () => {
    setPosition(null);
    setMarkerOnScreen(!markerOnScreen);
  };

  const handleMapClick = (event: any) => {
    const { lat, lng } = event.latlng;

    if (position) {
      setMarkerPosition((prevMarkerPosition: Coordinates[]) => [...prevMarkerPosition, { lat, lng, radius: 10 }]);

      const map = mapRef?.current;

      if (map) {
        map.flyTo([lat, lng], 12, {
          duration: 1,
        });
      }

      setMarkerOnScreen(false);
      setPosition(null);
    }
  };
  useMapEvents({
    click(e) {
      const ele = (e.originalEvent.target as EventTarget & { localName: string }).localName;
      if (markerOnScreen && ele === "div") {
        handleMapClick(e);
      }
    },
    mousemove: (e) => {
      if (markerOnScreen) {
        setPosition(e.latlng);
      }
    },
  });

  return (
    <div className="drop">
      <Button className="dropBtn" onClick={handleClickDropPin}>
        <img style={{ width: "12%", marginRight: 10 }} src={dropPin} alt="Drop Pin" />
        <span style={{ fontSize: 16 }}>Stecknadel</span>
      </Button>
      {position && <Marker position={position} />}
    </div>
  );
}
