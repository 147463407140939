import useAdmin from "hooks/useAdmin";
import { useQuery } from "react-query";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";

export type TGetCompanyStatsParams = {
  startDate: Date | string;
  endDate: Date | string;
};

const useCompanyStats = <TReturn = Awaited<ReturnType<typeof CompanyApi.stats>>>(
  filters: TGetCompanyStatsParams,
  options: {
    enabled: boolean;
    selectFn?: (res: Awaited<ReturnType<typeof CompanyApi.stats>>) => TReturn;
    staleTime?: number;
  } = {
    enabled: true,
  },
) => {
  const { isPlatformAdmin } = useAdmin();

  return useQuery({
    queryKey: CompanyQueryKeys.stats(filters),
    queryFn: async () => {
      return CompanyApi.stats(filters);
    },
    select: options?.selectFn,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialData: [],
    initialDataUpdatedAt: 0,
    enabled: isPlatformAdmin && options.enabled,
    refetchOnWindowFocus: false,
  });
};

export default useCompanyStats;
