import { FB_IMG_SIZE_LIMIT, FB_IMG_SIZE_MB } from "constants/editor";
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, message, Spin, Modal, Upload, UploadFile, Divider } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import { AccountDetail } from "types";
import { getBase64, validateEmail, validateGermanVATId, validateTelephone, validateWebsite } from "utils";
import { FileTextOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import useAccount from "hooks/useAccount";
import NotAllowed from "components/NotAllowed";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import useUpdateAccount from "hooks/useUpdateAccount";
import { ReactComponent as EmailIcon } from "../../../assets/MailOutlined.svg";
import classes from "./General.module.css";

type FormData = AccountDetail;

export default function General() {
  const { t } = useTranslation(["common", "pages"]);

  const [formData, setFormData] = useState<FormData>({});
  const [, setIsDirty] = useState(false);

  const [isInvoiceExistModal, setIsInvoiceModalExist] = useState(false);
  const { push } = useRouterContext().useHistory();
  const [profilePic, setProfilePic] = useState<UploadFile>();
  const [picStr, setPicStr] = useState("");
  const { selectedAccountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);
  const canViewAccountDetails = ability.can("read", makeSubject("Account", { accountId: selectedAccountId }));
  const canUpdateAccountDetails = ability.can("update", makeSubject("Account", { accountId: selectedAccountId }));

  const resetForm = () => {
    setFormData((curr) => ({
      name: account?.name ?? curr.name,
      vat: account?.vat ?? curr.vat,
      address: account?.address ?? curr.address,
      postal: account?.postal ?? curr.postal,
      city: account?.city ?? curr.city,
      website: account?.website ?? curr.website,
      email: account?.email ?? curr.email,
      phone: account?.phone ?? curr.phone,
    }));
  };

  const { data: account, isLoading: isLoadingAccount } = useAccount(selectedAccountId, {
    enabled: canViewAccountDetails,
    onSuccess: (data) => {
      setProfilePic({
        uid: data.account_profile_pic?.split("/").pop() ?? "",
        name: data.account_profile_pic?.split("/").pop() ?? "",
        status: "done",
        url: data.account_profile_pic,
      } as UploadFile);
      if (data.account_profile_pic) {
        setPicStr(data.account_profile_pic);
      }
    },
  });

  useEffect(() => {
    if (!account) return;

    let shldCancel;
    setIsDirty((isDirty) => {
      if (isDirty) {
        shldCancel = true;
        return isDirty;
      }
      return false;
    });

    if (shldCancel) return;

    setFormData(() => {
      return {
        name: account.name ?? undefined,
        vat: account.vat ?? undefined,
        address: account.address ?? undefined,
        postal: account.postal ?? undefined,
        city: account.city ?? undefined,
        website: account.website ?? undefined,
        email: account.email ?? undefined,
        phone: account.phone ?? undefined,
      };
    });
  }, [account]);

  useEffect(() => {
    if (profilePic?.originFileObj !== undefined && profilePic.originFileObj.size < FB_IMG_SIZE_LIMIT) {
      getBase64(profilePic?.originFileObj as RcFile).then((data) => {
        setPicStr(data);
      });
    }
  }, [profilePic]);

  const { pathPrefix } = useSelectedAccount();

  const { mutateAsync: updateAccountDetails, isLoading: updateAccountDetailsLoading } = useUpdateAccount({
    onSuccess: () => {
      setFormData((curr) => ({ ...curr }));
      setIsDirty(false);
      message.success("Deine Daten wurden erfolgreich aktualisiert.");
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const errMsg = err.response?.data.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
  });

  return (
    <div className={classes.GeneralContainer} data-testid="user-general-settings-tab">
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ padding: 10, background: "none", marginRight: 10 }}
              icon={<FileTextOutlined style={{ fontSize: 20 }} />}
            />
            <span>Diese Rechnung gehört nicht Ihnen</span>
          </div>
        }
        open={isInvoiceExistModal}
        onOk={() => {
          setIsInvoiceModalExist(false);
          push(`${pathPrefix}/settings/general`);
        }}
        okText="Ja"
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setIsInvoiceModalExist(false);
          push(`${pathPrefix}/settings/general`);
        }}
      >
        <p style={{ marginLeft: 40 }}>Die Rechnung, auf die Sie zugreifen möchten, gehört nicht zu Ihrem Konto</p>
      </Modal>
      <Spin spinning={isLoadingAccount}>
        <div className={classes.formContainer}>
          <div className={classes.formHeader}>
            <strong>Persönliche Information</strong>

            {canUpdateAccountDetails ? (
              <div className={classes.formActionsWrapper}>
                <Button
                  className={classes.discardButton}
                  data-testid="settings-save-btn"
                  type="default"
                  htmlType="submit"
                  disabled={isLoadingAccount || updateAccountDetailsLoading}
                  onClick={() => resetForm()}
                >
                  {t("common:buttons.discard")}
                </Button>
                <Button
                  className={classes.saveButton}
                  data-testid="settings-save-btn"
                  type="primary"
                  htmlType="submit"
                  loading={isLoadingAccount || updateAccountDetailsLoading}
                  onClick={() => {
                    if (formData.vat && !validateGermanVATId(formData.vat)) {
                      message.error("Ungültige Umsatzsteuer-ID");
                      return;
                    }
                    if (formData.phone && !validateTelephone(formData.phone)) {
                      message.error("Ungültige Telefonnummer");
                      return;
                    }
                    if (formData.email && !validateEmail(formData.email)) {
                      message.error("Ungültige Email");
                      return;
                    }
                    if (formData.website && !validateWebsite(formData.website)) {
                      message.error("Ungültige Website");
                      return;
                    }
                    updateAccountDetails({ ...formData, accountId: selectedAccountId });
                  }}
                >
                  {t("common:buttons.save")}
                </Button>
              </div>
            ) : null}
          </div>

          <Divider style={{ margin: "20px 0 24px 0" }} />
          {canViewAccountDetails ? (
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              colon={false}
              labelAlign="left"
              className={classes.GeneralForm}
            >
              <Form.Item
                label={
                  <span>
                    Allgemeine <br /> Informationen
                  </span>
                }
              >
                <ImgCrop
                  beforeCrop={(file) => {
                    if (file.size && file.size > FB_IMG_SIZE_LIMIT) {
                      message.error(`Limit überschritten! Dateigröße sollte weniger als ${FB_IMG_SIZE_MB} MB sein.`);
                      return false;
                    } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
                      message.error("Nur JPEG/PNG-Bilder erlaubt.");
                      return false;
                    }
                    return true;
                  }}
                >
                  <Upload
                    className={classes.fileUpload}
                    accept="image/png,image/jpeg"
                    listType="picture-card"
                    maxCount={1}
                    disabled={!canUpdateAccountDetails}
                    showUploadList={false}
                    onChange={(info) => {
                      if (
                        (info.file.type === "image/png" || info.file.type === "image/jpeg") &&
                        info.file.size &&
                        info.file.size <= FB_IMG_SIZE_LIMIT
                      ) {
                        setProfilePic(info.file);
                      }
                    }}
                    multiple={false}
                  >
                    <div>{profilePic ? <img src={picStr} style={{ width: "100%" }} /> : <PlusOutlined />}</div>
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Divider style={{ margin: "20px 0 24px 0" }} />
              <Form.Item label="Firmenname">
                <Input
                  data-testid="settings-first-name-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="VAT ID">
                <Input
                  data-testid="settings-VATID-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.vat}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      vat: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="Straße + Hausnummer">
                <Input
                  data-testid="settings-street-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="Postleitzahl">
                <Input
                  data-testid="settings-postal-code-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.postal}
                  onChange={(e) => setFormData({ ...formData, postal: e.target.value })}
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="Ort">
                <Input
                  data-testid="settings-ort-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="Website">
                <Input
                  data-testid="settings-website-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.website}
                  onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="E-Mail">
                <Input
                  prefix={<EmailIcon style={{ width: "20px", height: "20px" }} />}
                  data-testid="settings-mail-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </Form.Item>
              <Divider className={classes.generalFormDivider} />
              <Form.Item label="Telefon" help="Sonderzeichen wie „-()“ sind nicht erlaubt.">
                <Input
                  data-testid="settings-telephone-field"
                  className={classes.input}
                  disabled={!canUpdateAccountDetails}
                  value={formData?.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </Form.Item>
            </Form>
          ) : (
            <NotAllowed />
          )}
        </div>
      </Spin>
    </div>
  );
}
