import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { PostReviewCommentApi } from "services/api";
import { PostReviewCommentQueryKeys } from "services/query-keys";
import { PostReviewComment } from "types";

export type EditPostReviewCommentData = {
  id: string;
  quotedCommentId?: string;
  message: string;
};

const useEditPostReviewComment = (
  options?: Omit<UseMutationOptions<PostReviewComment, unknown, EditPostReviewCommentData, unknown>, "mutationFn">,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: PostReviewCommentApi.edit,
    onSuccess: async (data, variables, ctx) => {
      await queryClient.invalidateQueries({ queryKey: PostReviewCommentQueryKeys.list() });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, ctx);
      }
    },
  });
};

export default useEditPostReviewComment;
