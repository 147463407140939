import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import { UseCustomQueryOptions } from "types";

export type TGetAccountInvoicesParams = {
  accountId: string;
};

type TGetAccountInvoicesResponse = Awaited<ReturnType<typeof AccountApi.getInvoices>>;

const useAccountInvoices = <TSelectData = TGetAccountInvoicesResponse>(
  filters: TGetAccountInvoicesParams,
  options?: UseCustomQueryOptions<TGetAccountInvoicesResponse, TSelectData>,
) => {
  return useQuery({
    queryKey: AccountQueryKeys.getInvoices(filters),
    queryFn: async () => {
      return AccountApi.getInvoices(filters);
    },
    ...options,
    //Cache for 5 minutes
    initialData: options?.initialData ?? [],
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: (options?.enabled ?? true) && !!filters.accountId,
  });
};

export default useAccountInvoices;
