import { Button, Col, Flex, Row } from "antd";
import { CreatePost, Post, PostPublishingStatusType } from "types";
import "dayjs/locale/de";
import { useContext, useState } from "react";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import classes from "./Action.module.css";

export type ActionComponentProps = {
  localPost: CreatePost;
  post: Post | undefined;
  setLocalPost: React.Dispatch<React.SetStateAction<CreatePost>>;
  isLoading: boolean;
  callCreatePost: ({ type, publishMobile }: { type: PostPublishingStatusType; publishMobile: boolean }) => void;
  isPostContentValid: boolean;
  schedule: boolean;
  requiresPostReview: boolean;
  deletePost: (id: string) => void;
};

enum PostAction {
  SCHEDULING = "SCHEDULING",
  PUBLISHING = "PUBLISHING",
  DRAFT = "DRAFT",
  DELETE = "DELETE",
}

const ActionComponent = (props: ActionComponentProps) => {
  const { localPost, isLoading, callCreatePost, isPostContentValid, post, schedule, deletePost, requiresPostReview } =
    props;
  const [action, setAction] = useState<PostAction>();

  const { ability } = useContext(UserAccessPolicyContext);

  const canDelete =
    post &&
    ability.can(
      "delete",
      makeSubject("Post", {
        accountId: post.accountId,
        channelId: post.channelId,
        companyId: post.companyId,
      }),
    );

  const processPost = (actionType: PostAction) => {
    setAction(actionType);
    switch (actionType) {
      case PostAction.DRAFT:
        callCreatePost({ type: PostPublishingStatusType.DRAFT, publishMobile: false });
        break;
      case PostAction.SCHEDULING:
        callCreatePost({ type: PostPublishingStatusType.SCHEDULING, publishMobile: false });
        break;
      case PostAction.PUBLISHING:
        callCreatePost({ type: PostPublishingStatusType.PUBLISHING, publishMobile: false });
        break;
      case PostAction.DELETE:
        if (post && canDelete) deletePost(post.id);
        break;
    }
  };

  return (
    <Flex vertical gap={20}>
      <Row gutter={[12, 12]} className={classes.actionContainer}>
        {post && canDelete ? (
          <Col>
            <Button
              disabled={isLoading}
              className={classes.postActionButton}
              type="default"
              data-type="default"
              onClick={() => processPost(PostAction.DELETE)}
            >
              Löschen
            </Button>
          </Col>
        ) : null}
        {!schedule && (
          <Col>
            <Button
              disabled={!isPostContentValid || isLoading}
              loading={isLoading && action === PostAction.DRAFT}
              className={classes.postActionButton}
              type="default"
              onClick={() => processPost(PostAction.DRAFT)}
              data-testid="post-draft-btn"
              data-type="default"
              data-loading={isLoading}
            >
              Entwurf speichern
            </Button>
          </Col>
        )}
        <Col>
          <Button
            disabled={!isPostContentValid || isLoading}
            loading={isLoading && action === (schedule ? PostAction.SCHEDULING : PostAction.PUBLISHING)}
            className={classes.postActionButton}
            type="primary"
            onClick={() => processPost(schedule ? PostAction.SCHEDULING : PostAction.PUBLISHING)}
            data-loading={!localPost.publishMobile && isLoading}
          >
            {requiresPostReview ? "Zur Freigabe senden" : !schedule ? "Veröffentlichen" : "Planen"}
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};

export default ActionComponent;
