import { useQuery } from "react-query";
import { InsightApi } from "services/api";
import { InsightQueryKeys } from "services/query-keys";
import { FacebookConsumptionMetrics, InsightDatePreset, MetricPeriod, UseCustomQueryOptions } from "types";

export type TGetInsightConsumptionParams = {
  accountId: string;
  companyId: string;
  date_preset?: InsightDatePreset;
  period?: MetricPeriod;
  since?: Date | string;
  until?: Date | string;
  facebook_metrics: FacebookConsumptionMetrics[];
};

type TGetInsightConsumptionResponse = Awaited<ReturnType<typeof InsightApi.getInsightConsumption>>;

const useInsightConsumption = <TSelectData = TGetInsightConsumptionResponse>(
  filters: TGetInsightConsumptionParams,
  options?: UseCustomQueryOptions<TGetInsightConsumptionResponse, TSelectData>,
) => {
  return useQuery({
    queryKey: InsightQueryKeys.getInsightConsumption(filters),
    queryFn: async () => {
      return InsightApi.getInsightConsumption(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default useInsightConsumption;
