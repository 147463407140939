import React from "react";
import { mergeClasses } from "utils/mergeClasses";
import { Typography } from "antd";
import classes from "./index.module.css";

const PageHeadingTitle = React.forwardRef(
  (
    { children, className, level = 1, ...rest }: React.ComponentPropsWithoutRef<typeof Typography.Title>,
    ref: React.ForwardedRef<HTMLHeadingElement>,
  ) => {
    return (
      <Typography.Title {...rest} className={mergeClasses(classes.pageHeadingTitle, className)} level={level} ref={ref}>
        {children}
      </Typography.Title>
    );
  },
);
PageHeadingTitle.displayName = "PageHeadingTitle";

const PageHeadingSubtitle = React.forwardRef(
  (
    { children, className, level = 2, ...rest }: React.ComponentPropsWithoutRef<typeof Typography.Title>,
    ref: React.ForwardedRef<HTMLHeadingElement>,
  ) => {
    return (
      <Typography.Title
        {...rest}
        level={level}
        className={mergeClasses(classes.pageHeadingSubtitle, className)}
        ref={ref}
      >
        {children}
      </Typography.Title>
    );
  },
);
PageHeadingSubtitle.displayName = "PageHeadingSubtitle";

const PageHeading = Object.assign(
  React.forwardRef(
    (
      { children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      return (
        <div {...rest} className={mergeClasses(classes.pageHeading, className)} ref={ref}>
          {children}
        </div>
      );
    },
  ),
  {
    Title: PageHeadingTitle,
    Subtitle: PageHeadingSubtitle,
  },
);
PageHeading.displayName = "PageHeading";

export default PageHeading;
