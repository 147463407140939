import { useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { PostReviewCommentApi } from "services/api";
import { PostReviewCommentQueryKeys } from "services/query-keys";

export type TGetPostReviewCommentsParams = {
  postId: string;
  postVersionId?: string;
  userId?: string;
  quotedCommentId?: string;
  contains?: string;
  skip?: number;
  take?: number;
  include?: ("post" | "postVersion" | "user" | "quotedComment")[];
};

type GetPostsResult = Awaited<ReturnType<typeof PostReviewCommentApi.list>>;

const usePostReviewComments = (
  filters: TGetPostReviewCommentsParams,
  options?: Omit<UseInfiniteQueryOptions<GetPostsResult, unknown, GetPostsResult>, "queryKey" | "queryFn">,
) => {
  return useInfiniteQuery({
    queryKey: PostReviewCommentQueryKeys.list(filters),
    queryFn: async ({ pageParam }) => {
      return PostReviewCommentApi.list({ ...filters, skip: pageParam ?? 0 });
    },
    getNextPageParam: (lastPage, allPages) => {
      const sum = allPages.reduce((acc, page) => acc + page.items.length, 0);
      return sum < lastPage.total ? sum : undefined;
    },
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.postId,
  });
};

export default usePostReviewComments;
