import { useEffect, useState } from "react";
import { Row, Spin, Dropdown, Tabs } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import { useQuery } from "react-query";

import { AccountApi } from "services/api";
import FB from "services/meta";
import useQueryCacheData from "hooks/useQueryCacheData";
import { Account, DASHBOARD_LABELS, DASHBOARD_TABS } from "types";
import { DownOutlined } from "@ant-design/icons";
import type { TabsProps } from "antd";
import FacebookIcon from "../../assets/icons/socials/facebook.svg";
import classes from "./Campaign.module.css";
import CampaignDashboard from "./components/Dashboards/CampaignDashboard";

import AdsDashboard from "./components/Dashboards/AdsDashboard";
import AdsetDashboard from "./components/Dashboards/AdsetDashboard";

const items: TabsProps["items"] = [
  {
    key: DASHBOARD_TABS.CAMPAIGN,
    label: DASHBOARD_LABELS.CAMPAIGN,
    children: <CampaignDashboard />,
  },
  {
    key: DASHBOARD_TABS.ADSET,
    label: DASHBOARD_LABELS.ADSET,
    children: <AdsetDashboard />,
  },
  {
    key: DASHBOARD_TABS.ADS,
    label: DASHBOARD_LABELS.ADS,
    children: <AdsDashboard />,
  },
];

export default function Campaign() {
  const [connectAdAccountLoading, setConnectAdAccountLoading] = useState(false);
  const { useParams, useHistory } = useRouterContext();
  const { push } = useHistory();
  const params = useParams<{ tab: string }>();
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [currentTab, setCurrentTab] = useState<string>();
  const [currentPage, setCurrentPage] = useState(<CampaignDashboard />);

  const {
    data: metaAdAccount,
    isLoading,
    refetch,
    isFetching,
  } = useQuery("meta-businesses", () => AccountApi.getMetaBusinesses(accounts?.[0].id), {
    refetchOnWindowFocus: false,
    enabled: !!accounts?.[0].id,
  });

  const getCurrentPage = (tab: string) => {
    switch (tab) {
      case DASHBOARD_TABS.CAMPAIGN:
        setCurrentPage(<CampaignDashboard />);
        setCurrentTab(DASHBOARD_LABELS.CAMPAIGN);
        break;
      case DASHBOARD_TABS.ADSET:
        setCurrentPage(<AdsetDashboard />);
        setCurrentTab(DASHBOARD_LABELS.ADSET);
        break;
      case DASHBOARD_TABS.ADS:
        setCurrentPage(<AdsDashboard />);
        setCurrentTab(DASHBOARD_LABELS.ADS);
        break;
    }
  };

  const connect = async () => {
    try {
      setConnectAdAccountLoading(true);
      await FB.connectAdAccount();
    } finally {
      setConnectAdAccountLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    getCurrentPage(params.tab);
  }, []);

  if (isLoading || isFetching || connectAdAccountLoading) {
    return (
      <div className="container">
        <Row justify="space-between" align="middle">
          <h1 className="heading">Werbeanzeige</h1>
        </Row>
        <Row justify="center" align="middle" style={{ height: "50vh" }}>
          <Spin spinning />
        </Row>
      </div>
    );
  }

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <h1 className="heading">Werbeanzeige</h1>
      </Row>
      {!(metaAdAccount?.[0] && metaAdAccount[0].adAccounts.length > 0) && (
        <>
          <p className={classes.AddProfileText}>Bitte verbinde deinen Meta Ads Account um fortzufahren.</p>

          <div className={classes.SocialContainer}>
            <div className={classes.SocialItem} onClick={connect} tabIndex={0} role="button" onKeyDown={connect}>
              <img src={FacebookIcon} alt="facebook" className={classes.SocialIcon} />
              <div>
                <h3>Facebook</h3>
                <p>Seite</p>
              </div>
            </div>
          </div>
        </>
      )}
      {metaAdAccount?.[0] && metaAdAccount[0].adAccounts.length > 0 && (
        <>
          <Tabs
            items={items}
            defaultActiveKey="campaign"
            className={classes.CampaignTabs}
            activeKey={params.tab}
            onChange={(key) => {
              getCurrentPage(key);
              push(`/user/${key}/dashboard`);
            }}
          />
          <div className={classes.mobileTabsView}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: DASHBOARD_TABS.CAMPAIGN,
                    label: <div>{DASHBOARD_LABELS.CAMPAIGN}</div>,
                  },
                  { key: DASHBOARD_TABS.ADSET, label: DASHBOARD_LABELS.ADSET },
                  {
                    key: DASHBOARD_TABS.ADS,
                    label: DASHBOARD_LABELS.ADS,
                  },
                ],
                onClick: (e) => {
                  getCurrentPage(e.key);
                  push(`/user/${e.key}/dashboard`);
                },
              }}
            >
              <div className={classes.mobileTab}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  {currentTab}
                  <DownOutlined />
                </div>
              </div>
            </Dropdown>
            {currentPage}
          </div>
        </>
      )}
    </div>
  );
}
