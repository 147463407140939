import { EyeOutlined, LockOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PermissionsModal from "components/Permissions";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useState } from "react";
import { useQuery } from "react-query";
import { UserAccessPolicyApi } from "services/api";
import { UserAccessPolicyKeys } from "services/query-keys";
import { useTranslation } from "react-i18next";
import useMe from "hooks/useMe";
import classes from "./NotAllowed.module.css";

const NotAllowed = () => {
  const { t } = useTranslation(["pages"]);
  const [showUserPermissions, setShowUserPermissions] = useState<boolean>(false);
  const { selectedAccountId } = useSelectedAccount();

  const { data: me } = useMe();

  const { data: userAccessPolicy, isLoading: isLoadingAccessPolicies } = useQuery({
    queryKey: UserAccessPolicyKeys.list({ accountId: selectedAccountId, userId: me?.id }),
    queryFn: async () => {
      return await UserAccessPolicyApi.findAll({ accountId: selectedAccountId, userId: me?.id });
    },
    enabled: !!me && !!selectedAccountId && showUserPermissions,
    refetchOnWindowFocus: true,
  });

  return (
    <>
      <div className={classes.mainContainer} style={{ flex: 1 }}>
        <LockOutlined className={classes.notFoundIcon} />
        <h3 className={classes.notFoundTitle}>{t("pages:notAllowed.title")}</h3>
        <h4 className={classes.notFountSubTitle}>{t("pages:notAllowed.seekSupportSubtitle")}</h4>
        <Button
          type="primary"
          icon={<EyeOutlined />}
          className={classes.viewPermissionBtn}
          onClick={() => setShowUserPermissions(true)}
        >
          {t("pages:notAllowed.viewYourPermissionsButton")}
        </Button>
      </div>
      {me ? (
        <PermissionsModal
          selectedUser={me}
          accessPolicies={userAccessPolicy?.items ?? []}
          isLoadingAccessPolicies={isLoadingAccessPolicies}
          open={showUserPermissions}
          onCancel={() => {
            setShowUserPermissions(false);
          }}
          viewOnly
        />
      ) : null}
    </>
  );
};

export default NotAllowed;
