import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const ReachChart = ({
  categories,
  paidReach,
  reachOnFb,
  reachOnIg,
}: {
  categories: string[];
  reachOnFb: number[];
  reachOnIg: number[];
  paidReach: number[];
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: "line",
        id: "line-chart",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 3,
      },
      colors: ["hsla(344, 57%, 54%, 1)", "hsla(214, 100%, 57%, 1)", "hsla(138, 61%, 46%, 1)"],
      series: [
        {
          name: t("pages:dashboard.reachOnFbLabel"),
          data: reachOnFb,
        },
        {
          name: t("pages:dashboard.reachOnIgLabel"),
          data: reachOnIg,
        },
        {
          name: t("pages:dashboard.paidReachLabel"),
          data: paidReach,
        },
      ],
      xaxis: {
        categories,
      },
      yaxis: {
        title: {
          text: t("pages:dashboard.reachLabel"),
          style: { fontWeight: 500, fontFamily: "Montserrat", fontSize: "12px", color: "hsla(220, 13%, 46%, 1)" },
        },
      },
      legend: {
        show: true,
        showForNullSeries: true,
        showForSingleSeries: true,
        showForZeroSeries: true,
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Montserrat",
      },
    } satisfies ApexOptions;
  }, [t, categories, paidReach, reachOnFb, reachOnIg]);

  return <Chart series={chartOptions.series} options={chartOptions} type="line" width="100%" height="100%" />;
};

export default ReachChart;
