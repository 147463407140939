import { Modal, Button } from "antd";
import authProvider from "authProvider";
import { useQueryClient as useRefineQueryClient, useRouterContext } from "@pankod/refine-core";
import { ModalProps } from "types";
import { useQueryClient } from "react-query";
import classes from "./LogoutModal.module.css";
import "../../styles/modal.css";
import "../../styles/icon.css";

function LogoutModal({ visible, handleCloseModal }: ModalProps) {
  const params = useRouterContext().useParams<{ resource: string }>();

  const queryClient = useQueryClient();

  const refineQueryClient = useRefineQueryClient();

  return (
    <div>
      <Modal
        onCancel={handleCloseModal}
        open={visible}
        title={
          <div>
            <h3>Möchtest du dich wirklich abmelden?</h3>
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="default" className={classes.cancelButton} onClick={() => handleCloseModal()}>
              Nein
            </Button>
            <Button
              type="primary"
              danger
              className={classes.createButton}
              onClick={async () => {
                await authProvider.logout(params);
                //Invalidate all queries on logout
                await queryClient.invalidateQueries();
                await refineQueryClient.invalidateQueries();
              }}
            >
              Ja
            </Button>
          </div>
        }
      />
    </div>
  );
}

export default LogoutModal;
