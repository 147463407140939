import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { PostReviewCommentApi } from "services/api";
import { PostReviewCommentQueryKeys } from "services/query-keys";
import { PostReviewComment } from "types";

export type DeletePostReviewCommentData = {
  id: string;
};

const useDeletePostReviewComment = (
  options?: Omit<UseMutationOptions<PostReviewComment, unknown, DeletePostReviewCommentData, unknown>, "mutationFn">,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: PostReviewCommentApi.delete,
    onSuccess: async (data, variables, ctx) => {
      await queryClient.invalidateQueries({ queryKey: PostReviewCommentQueryKeys.list() });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, ctx);
      }
    },
  });
};

export default useDeletePostReviewComment;
