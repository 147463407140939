import { useRouterContext } from "@pankod/refine-core";
import Loader from "components/Loader";
import { AdminContextProvider } from "context/AdminContext";
import useAdmin from "hooks/useAdmin";
import useIsLoggedIn from "hooks/useIsLoggedIn";
import React, { useEffect } from "react";

function UnauthenticatedOnly({ children }: { children: React.ReactNode }) {
  const { push } = useRouterContext().useHistory();
  const { isLoggedIn, isLoading, isFetching } = useIsLoggedIn();

  const { isPlatformAdmin, isLoading: isLoadingPlatformAdmin } = useAdmin();

  const shldHide = isLoading || isFetching || isLoggedIn;

  useEffect(() => {
    if (isLoading || isFetching || !isLoggedIn) return;

    if (isLoadingPlatformAdmin) return;

    if (isPlatformAdmin) {
      push("/admin/dashboard");
      return;
    }

    push("/account/-/company/-/select-account");
  }, [isLoggedIn, isLoading, isFetching, push, isPlatformAdmin, isLoadingPlatformAdmin]);

  return (
    <>
      <div style={{ display: shldHide ? "none" : undefined }}>{children}</div>
      {shldHide ? <Loader /> : null}
    </>
  );
}

export default function ({ children }: { children: React.ReactNode }) {
  return (
    <AdminContextProvider>
      <UnauthenticatedOnly>{children}</UnauthenticatedOnly>
    </AdminContextProvider>
  );
}
