import FB from "services/meta";
import { useState } from "react";
import { Button, message } from "antd";
import useMe from "hooks/useMe";
import AuthContainer from "components/AuthContainer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "@pankod/refine-react-router-v6";
import useChannels from "hooks/useChannels";
import { ReactComponent as FacebookIcon } from "../../../../assets/icons/socials/facebookColored.svg";
import { ReactComponent as InstagramIcon } from "../../../../assets/icons/socials/instagramColored.svg";
import ConnectSocialArt from "../../../../assets/ConnectSocialArt.png";
import FacebookSmallIcon from "../../../../assets/FacebookSmallIcon.svg";
import InstagramSmallIcon from "../../../../assets/InstagramSmallIcon.svg";
import { useOnboardingContext } from "../OnboardingContext";
import classes from "./index.module.css";

export default function ConnectSocial() {
  const { t } = useTranslation(["common", "pages"]);
  const [isConnecting, setIsConnecting] = useState(false);
  const { data: me } = useMe();
  const navigate = useNavigate();

  const { currAccount, isLoadingAccounts } = useOnboardingContext();
  const {
    data: channels,
    isLoading: isLoadingChannels,
    refetch: refetchChannels,
  } = useChannels({ accountId: currAccount?.id ?? "" }, { enabled: !!currAccount });

  const nextStepDisabled = !channels?.length;
  const goToNextStep = () => {
    if (nextStepDisabled) return;
    navigate("/onboarding?step=2");
  };

  const isLoading = isConnecting || isLoadingAccounts || isLoadingChannels;

  const connectToFb = async (channelKey: string) => {
    if (me?.role === "MEMBER") {
      message.error("Nur Admins können soziale Medienkonten verknüpfen. Kontaktiere deinen Admin.");
      return;
    }
    setIsConnecting(true);
    try {
      await FB.login({ type: channelKey as "facebook" | "instagram" });
      refetchChannels();
    } catch (err) {
      console.log(err);
    }
    setIsConnecting(false);
  };

  return (
    <AuthContainer
      type={undefined}
      sideContent={
        <div className={classes.sideContentContainer}>
          <div className={classes.sideContentWrapper}>
            <p className={classes.sideContentTitle}>
              Verbinde Deine <br /> Social Media Konten <br /> und starte durch.
            </p>
            <img
              className={classes.sideContentImage}
              src={ConnectSocialArt}
              alt="Big blue like button decorated with facebook icon and orange background"
            />
          </div>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <h1>
            <strong className={classes.title}>{t("pages:onboardingStep1.title")}</strong>
          </h1>
          <div className={classes.headerIcons}>
            <FacebookIcon />
            <InstagramIcon />
          </div>
        </div>
        <p className={classes.text}>{t("pages:onboardingStep1.subtitle")}</p>
        {channels && channels.length > 0 ? (
          <div>
            <div className={classes.connectedPagesContainer}>
              {channels.map((page) => {
                return (
                  <div key={page.id} className={classes.connectedPageItem}>
                    <img
                      src={page.type === "FACEBOOK" ? FacebookSmallIcon : InstagramSmallIcon}
                      className={classes.connectedIcon}
                    />
                    <p className={classes.PageName}>{`${page.name}`}</p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : isLoading ? (
          <p style={{ fontSize: "1rem" }}>Verknüpfte Seiten werden geladen...</p>
        ) : null}
        <div className={classes.ctaContainer}>
          <Button className={classes.ctaButton} type="primary" onClick={() => connectToFb("facebook")}>
            {t("pages:onboardingStep1.connectToFbButton")}
          </Button>
          <Button className={classes.ctaButton} type="primary" onClick={() => connectToFb("instagram")}>
            {t("pages:onboardingStep1.connectToIgButton")}
          </Button>
          <button
            className={classes.nextStepButton}
            disabled={nextStepDisabled}
            aria-disabled={nextStepDisabled}
            onClick={goToNextStep}
          >
            {t("pages:onboardingStep1.nextStepButton")}
          </button>
        </div>
      </div>
    </AuthContainer>
  );
}
