import { TFindAllChannelsParams } from "hooks/useChannels";
import { AccountApi } from "services/api";
import { Channel } from "types";

export const getUnhealthyChannels = (channels: Channel[]) => {
  return channels.filter((channel) => channel.accessStatus === "UNHEALTHY");
};

export const refreshChannels = async (params: TFindAllChannelsParams) => {
  return await AccountApi.getChannels(params);
};
