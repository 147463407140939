import { Button, Col, Dropdown, Flex, Popconfirm, Row, Spin, Table, TableProps, message } from "antd";
import dayjs from "dayjs";
import { Channel } from "types";
import { convertUTCDateToLocal } from "utils/date";
import { useContext, useState } from "react";
import { DeleteOutlined, EllipsisOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";
import useUpdateCompanyChannels from "hooks/company/useUpdateCompanyChannels";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";
import useSelectedAccount from "hooks/useSelectedAccount";
import NoDataLocaleTable from "components/NoData";
import FacebookIcon from "../../../../../assets/icons/socials/facebook.svg";
import InstagramIcon from "../../../../../assets/icons/socials/instagram.svg";
import AddChannelModal from "../../components/AddChannelModal";
import classes from "./index.module.css";

const CompanyChannels = ({
  companyId,
  channels,
  isLoading,
}: {
  companyId: string;
  channels: Channel[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation(["common", "pages"]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showAddChannelModal, setShowAddChannelModal] = useState<boolean>(false);
  const { selectedAccountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);

  const canAddAtleastOneChannel = ability.can("update", "Company", "channelIds");
  const canDeleteAllCompanyChannels = ability.can(
    "update",
    makeSubject("Company", { accountId: selectedAccountId, companyId }),
    "channelIds",
  );

  const { mutate: updateCompanyChannels, isLoading: isUpdatingChannels } = useUpdateCompanyChannels({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      setSelectedRows([]);
      message.success(t("pages:channel.updateSuccessMessage"));
    },
  });

  const columns: TableProps<Channel>["columns"] = [
    {
      title: t("labels.name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name < b.name ? -1 : 1),
      render(value, record) {
        return {
          children: (
            <Row align="middle" justify="space-between">
              <Col span={18}>
                <Flex align="center" gap={32}>
                  {record.type === "FACEBOOK" ? (
                    <img src={FacebookIcon} alt="facebook" style={{ height: 18, marginRight: 10, width: 18 }} />
                  ) : (
                    <img src={InstagramIcon} alt="instagram" style={{ height: 18, marginRight: 10, width: 18 }} />
                  )}{" "}
                  <span>{value}</span>
                </Flex>
              </Col>
              {ability.can(
                "update",
                makeSubject("Company", { accountId: selectedAccountId, companyId, channelId: record.id }),
                "channelIds",
              ) ? (
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <span
                            onClick={() => {
                              updateCompanyChannels({ companyId, action: "remove", channelIds: [record.id] });
                            }}
                          >
                            {t("buttons.remove")}
                          </span>
                        ),
                        key: "remove-channel",
                      },
                    ],
                  }}
                  placement="bottomRight"
                >
                  <span>
                    <EllipsisOutlined data-testid="user-three-dots" />
                  </span>
                </Dropdown>
              ) : null}
            </Row>
          ),
        };
      },
    },
    {
      title: `${t("labels.status")} / ${t("labels.active")} ${t("labels.accessToken")}`,
      dataIndex: "accessStatus",
      key: "accessStatus",
      render: (accessStatus: "HEALTHY" | "UNHEALTHY") => {
        const isActive = accessStatus === "HEALTHY";
        return (
          <span
            style={{
              color: isActive ? "#19A128" : "orange",
              fontWeight: "bold",
              fontSize: 13,
            }}
          >
            {isActive ? t("labels.active") : t("labels.inActive")}
          </span>
        );
      },
    },
    {
      title: t("pages:channel.updatedAtLabel"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => (dayjs.utc(a.updatedAt).isBefore(dayjs.utc(b.updatedAt)) ? -1 : 1),
      render: (updatedAt: string) => convertUTCDateToLocal(updatedAt).format("DD.MM.YYYY HH.mm[h]"),
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Table
          title={() => (
            <div className={classes.channelTableHeader}>
              <h2 className={classes.subHeading}>{t("pages:channel.connectedProfilesTitle")}</h2>
              {canDeleteAllCompanyChannels && selectedRows.length ? (
                <Popconfirm
                  title={t("pages:channel.removeFromCompanyConfirmTitle")}
                  cancelText={t("buttons.cancel")}
                  okText={t("buttons.remove")}
                  placement="topLeft"
                  icon={<QuestionCircleOutlined style={{ color: "var(--btn-primary-bg)", margin: "4px" }} />}
                  onConfirm={() => updateCompanyChannels({ companyId, channelIds: selectedRows, action: "remove" })}
                >
                  <Button type="default" icon={<DeleteOutlined />} size="middle">
                    {t("pages:channel.removeButton")}
                  </Button>
                </Popconfirm>
              ) : null}
              {canAddAtleastOneChannel && selectedRows.length === 0 ? (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="middle"
                  onClick={() => setShowAddChannelModal(true)}
                >
                  {t("pages:channel.addButton")}
                </Button>
              ) : null}
            </div>
          )}
          dataSource={channels ?? []}
          locale={NoDataLocaleTable}
          loading={isUpdatingChannels}
          columns={columns}
          className={classes.ProfileTable}
          rowKey="id"
          rowSelection={
            canDeleteAllCompanyChannels
              ? {
                  selectedRowKeys: selectedRows,
                  onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys as string[]),
                }
              : undefined
          }
          pagination={{
            position: ["bottomCenter"],
          }}
        />
      )}
      <AddChannelModal
        visible={showAddChannelModal}
        setVisible={setShowAddChannelModal}
        companyId={companyId}
        channelIdsToExclude={channels?.flatMap((channel) => channel.id) ?? []}
      />
    </>
  );
};

export default CompanyChannels;
