import { Authenticated, useNavigation, useRouterContext } from "@pankod/refine-core";
import Loader from "components/Loader";
import { AdminContextProvider } from "context/AdminContext";
import useIsLoggedIn from "hooks/useIsLoggedIn";

const AuthenticatedOnly = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn, isLoading, isError } = useIsLoggedIn();

  const { replace } = useNavigation();

  const { useLocation } = useRouterContext();
  const { pathname, search } = useLocation();

  if (isLoading) return <Loader />;

  if (isError || !isLoggedIn) {
    if (!pathname.includes("/login")) {
      replace(`/login?to=${encodeURIComponent(`${pathname}${search}`)}`);
    }
    return <Loader />;
  }

  return (
    <Authenticated>
      <AdminContextProvider>{children}</AdminContextProvider>
    </Authenticated>
  );
};

export default AuthenticatedOnly;
