const NoDataLocaleTable = {
  emptyText: (
    <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
      <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
    </span>
  ),
};

export default NoDataLocaleTable;
