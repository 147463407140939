import { FB_IMG_SIZE_LIMIT, FB_IMG_SIZE_MB } from "constants/editor";
import { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { Button, Col, Form, Input, Row, Tabs, Upload, UploadFile, message } from "antd";
import useQueryCacheData from "hooks/useQueryCacheData";
import { User } from "types";
import { useMutation } from "react-query";
import { getBase64, validatePassword } from "utils";
import { UserApi } from "services/api";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile } from "@pankod/refine-antd";
import classes from "./Profile.module.css";

export default function Profile() {
  const [profilePic, setProfilePic] = useState<UploadFile>();
  const [picStr, setPicStr] = useState("");
  const [form] = Form.useForm();
  const me = useQueryCacheData<User>("me");
  const [name, setName] = useState(me?.name);

  useEffect(() => {
    if (name === undefined && me?.name !== undefined) {
      setName(me.name);
    }
    if (profilePic?.originFileObj !== undefined) {
      if (profilePic.size !== undefined && profilePic.size < FB_IMG_SIZE_LIMIT) {
        getBase64(profilePic?.originFileObj as RcFile).then((data) => {
          setPicStr(data);
        });
      }
    } else if (me?.user_profile_pic !== undefined) {
      setPicStr(me.user_profile_pic);
    }
  }, [profilePic, me, name]);

  const { mutate: updateMe, isLoading: isUpdatingMe } = useMutation(
    async ({ name, profilePic }: { name: string; profilePic?: UploadFile }) => {
      await UserApi.updateMe({
        name,
        profilePic,
      });
    },
    {
      onSuccess: () => {
        message.success("Benutzer erfolgreich aktualisiert");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const { mutate: updatePassword, isLoading: isUpdatingPassword } = useMutation(
    async (data: any) => {
      const { currentPassword, newPassword, confirmPassword } = data;
      if (newPassword !== confirmPassword) {
        throw new Error("Passwörter stimmen nicht überein");
      }
      if (!validatePassword(newPassword)) {
        throw new Error(
          "Nicht englische Alphabete sind nicht erlaubt.Bitte verwenden Sie nur englische Alphabete, Zahlen und Sonderzeichen",
        );
      }
      if (newPassword.length < 8) {
        throw new Error("Das Passwort muss mindestens 8 Zeichen lang sein");
      }

      await UserApi.updatePassword({
        oldPassword: currentPassword,
        newPassword,
      });
    },
    {
      onSuccess: () => {
        message.success("Passwort erfolgreich aktualisiert");
        form.setFieldsValue({ currentPassword: undefined, newPassword: undefined, confirmPassword: undefined });
      },
      onError: (error: any) => {
        message.error(error.response.data.message);
      },
    },
  );

  const onFinish = (values: any) => {
    updatePassword(values);
  };

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <h1 className="heading">BENUTZER</h1>
      </Row>
      <div className={classes.GeneralContainer}>
        <Tabs
          items={[
            {
              key: "account",
              label: "ACCOUNT",
              children: (
                <Row className={classes.GeneralContent} justify="space-between" data-testid="user-account-tab">
                  <Col lg={14} md={24} sm={24} xs={24}>
                    <h3 className={classes.subheading}>Persönliche Informationen</h3>

                    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 18 }} colon={false} labelAlign="left">
                      <Form.Item label="Profilbild">
                        <ImgCrop
                          beforeCrop={(file) => {
                            if (file.size && file.size > FB_IMG_SIZE_LIMIT) {
                              message.error(
                                `Limit überschritten! Dateigröße sollte weniger als ${FB_IMG_SIZE_MB} MB sein.`,
                              );
                              return false;
                            } else if (file.type !== "image/png" && file.type !== "image/jpeg") {
                              message.error("Nur JPEG/PNG-Bilder erlaubt.");
                              return false;
                            }
                            return true;
                          }}
                        >
                          <Upload
                            accept="image/png,image/jpeg"
                            listType="picture-card"
                            maxCount={1}
                            showUploadList={false}
                            onChange={(info) => {
                              if (
                                (info.file.type === "image/png" || info.file.type === "image/jpeg") &&
                                info.file.size &&
                                info.file.size <= FB_IMG_SIZE_LIMIT
                              ) {
                                setProfilePic(info.file);
                              }
                            }}
                            multiple={false}
                          >
                            <div>{picStr ? <img src={picStr} style={{ width: "100%" }} /> : <PlusOutlined />}</div>
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                      <Form.Item label="Name">
                        <Input
                          data-testid="user-account-name-field"
                          className={classes.input}
                          value={name}
                          maxLength={20}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label="E-mail">
                        <Input
                          data-testid="user-account-email-field"
                          className={classes.input}
                          disabled
                          value={me?.email}
                        />
                      </Form.Item>
                    </Form>

                    <div className={classes.buttonContainer}>
                      <Button
                        data-testid="user-account-save-btn"
                        type="primary"
                        className={classes.button}
                        loading={isUpdatingMe}
                        disabled={name?.trim().length <= 0}
                        onClick={() => updateMe({ name, profilePic })}
                      >
                        Änderungen speichern
                      </Button>
                    </div>
                  </Col>
                </Row>
              ),
            },
            {
              key: "password",
              label: "PASSWORT",
              children: (
                <Row className={classes.GeneralContent} justify="space-between" data-testid="user-password-tab">
                  <Col lg={14} md={24} sm={24} xs={24}>
                    <h3 className={classes.subheading}>Passwort ändern</h3>

                    <Form
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 18 }}
                      colon={false}
                      labelAlign="left"
                      form={form}
                      onFinish={onFinish}
                    >
                      <Form.Item label="Aktuelles Passwort" name="currentPassword">
                        <Input.Password data-testid="user-current-pwd-field" className={classes.input} />
                      </Form.Item>
                      <Form.Item
                        label="Neues Passwort"
                        name="newPassword"
                        rules={[
                          {
                            validator(_, value, callback) {
                              if (value) {
                                if (value.length < 8) {
                                  callback("Das Passwort muss mindestens 8 Zeichen lang sein");
                                } else if (!validatePassword(value)) {
                                  callback(
                                    "Nicht englische Alphabete sind nicht erlaubt.Bitte verwenden Sie nur englische Alphabete, Zahlen und Sonderzeichen",
                                  );
                                } else {
                                  callback();
                                }
                              }
                            },
                          },
                        ]}
                      >
                        <Input.Password data-testid="user-new-pwd-field" className={classes.input} />
                      </Form.Item>
                      <Form.Item
                        label="Passwort bestätigen"
                        name="confirmPassword"
                        dependencies={["newPassword"]}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value, callback) {
                              if (!value || getFieldValue("newPassword") === value) {
                                callback();
                              }
                              callback("Passwörter stimmen nicht überein");
                            },
                          }),
                        ]}
                      >
                        <Input.Password data-testid="user-confirm-pwd-field" className={classes.input} />
                      </Form.Item>

                      <Form.Item
                        className={classes.buttonContainer}
                        dependencies={["currentPassword", "newPassword", "confirmPassword"]}
                      >
                        {({ getFieldValue }) => (
                          <Button
                            type="primary"
                            data-testid="user-password-save-btn"
                            htmlType="submit"
                            disabled={
                              getFieldValue("currentPassword") === undefined ||
                              getFieldValue("currentPassword") === "" ||
                              getFieldValue("newPassword") === undefined ||
                              getFieldValue("newPassword") === "" ||
                              getFieldValue("confirmPassword") === undefined ||
                              getFieldValue("confirmPassword") === ""
                            }
                          >
                            Passwort speichern
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              ),
            },
          ]}
          defaultActiveKey="account"
          className={classes.SettingsTabs}
        />
      </div>
    </div>
  );
}
