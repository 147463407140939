import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./resources/index";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v4",
  lng: "de",
  fallbackLng: "de",
  debug: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources,
});

export default i18n;
