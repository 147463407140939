import { Spin, Button, Tabs, TabsProps } from "antd";
import React, { useContext, useMemo, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import PreviewGrid from "components/PreviewGrid";
import { getCurrentLocalDate } from "utils/date";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import NotAllowed from "components/NotAllowed";
import { Link, useNavigate, useParams, useSearchParams } from "@pankod/refine-react-router-v6";
import PostDetailsModal from "components/PostDetailsModal";
import { useTranslation } from "react-i18next";
import useSelectedAccount from "hooks/useSelectedAccount";
import usePostVersion from "hooks/posts/usePostVersion";
import { PostPublishingStatusType } from "types";
import PageHeading from "components/PageHeading";
import classes from "./Planner.module.css";
import ListView from "./components/ListView";
import FullCalendar from "./components/FullCalendar";

const TabChildrenWrapper = ({
  children,
  isLoading,
  canReadPosts,
}: React.PropsWithChildren & {
  isLoading: boolean;
  canReadPosts: boolean;
}) => {
  return (
    <Spin spinning={isLoading}>
      <div className={classes.tabChildrenWrapper}>{canReadPosts ? children : !isLoading && <NotAllowed />}</div>
    </Spin>
  );
};

function PlannerScreen() {
  const { t } = useTranslation(["common", "pages"]);
  const { pathPrefix } = useSelectedAccount();

  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPostVersionId = searchParams.get("postVersionId");

  const { data: postVersion } = usePostVersion({
    versionId: currentPostVersionId ?? "",
  });
  const post = postVersion?.post;

  const setCurrentPostVersionId = (id: string | undefined) => {
    const newSearchParams = new URLSearchParams({ ...searchParams });
    if (id) {
      newSearchParams.set("postVersionId", id);
    } else {
      newSearchParams.delete("postVersionId");
    }
    setSearchParams(newSearchParams);
  };

  const [showPreview, setShowPreview] = useState(false);
  const currentLocaleDate = getCurrentLocalDate();
  const [previewGridStartDate, setPreviewGridStartDate] = useState(
    currentLocaleDate.startOf("month").toISOString() || "",
  );
  const [previewGridEndDate, setPreviewGridEndDate] = useState(currentLocaleDate.endOf("month").toISOString() || "");

  const { ability, isLoading } = useContext(UserAccessPolicyContext);
  const canReadPosts = ability.can("read", "Post");

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "calendar",
        label: "Kalender",
        children: (
          <TabChildrenWrapper isLoading={isLoading} canReadPosts={canReadPosts}>
            <FullCalendar
              setPreviewGridStartDate={setPreviewGridStartDate}
              setPreviewGridEndDate={setPreviewGridEndDate}
            />
          </TabChildrenWrapper>
        ),
      },
      {
        key: "list",
        label: "Liste",
        children: (
          <TabChildrenWrapper isLoading={isLoading} canReadPosts={canReadPosts}>
            <ListView />
          </TabChildrenWrapper>
        ),
      },
    ],
    [isLoading, canReadPosts],
  );

  return (
    <div className="container">
      {canReadPosts && !showPreview && (
        <div className={classes.closeButton} onClick={() => setShowPreview(true)}>
          <LeftOutlined style={{ color: "white" }} />
        </div>
      )}
      <PageHeading className={classes.PlannerHeader}>
        <PageHeading.Title>Planer</PageHeading.Title>
        <PageHeading.Subtitle>Marketingplan und Inhalt erstellen, planen und verwalten</PageHeading.Subtitle>
      </PageHeading>

      <Tabs
        className={classes.tabs}
        items={items}
        defaultActiveKey="calendar"
        activeKey={tab}
        onChange={(key) => navigate(`${pathPrefix}/planer/${key}`)}
      />

      {canReadPosts ? (
        <PreviewGrid
          open={showPreview}
          pageTitle="Easypostlab"
          onClose={() => setShowPreview(false)}
          previewGridStartDate={previewGridStartDate}
          previewGridEndDate={previewGridEndDate}
        />
      ) : null}
      <PostDetailsModal
        postVersionId={currentPostVersionId}
        onCancel={() => setCurrentPostVersionId(undefined)}
        title={<span className={classes.modalTitle}>{t("pages:approvalCenter.approvalModalTitle")}</span>}
        footerType="EDIT"
        footer={
          post?.publishingStatus !== PostPublishingStatusType.PUBLISHED ? (
            <div className={classes.modalFooter}>
              <Link className={classes.modalFooterButton} to={`${pathPrefix}/posts/${post?.id ?? "new"}`}>
                <Button className={classes.modalFooterButton} type="primary" disabled={!post}>
                  {t("pages:posts.editPostButtonLabel")}
                </Button>
              </Link>
            </div>
          ) : null
        }
      />
    </div>
  );
}

export default PlannerScreen;
