export const checkAuth = async () => {
  const userInfo = localStorage.getItem("EPL_USER_INFO");
  if (!userInfo) {
    return Promise.reject();
  }
  const parsedUserInfo = JSON.parse(userInfo);
  if (!parsedUserInfo.exp || parsedUserInfo.exp < Date.now() / 1000) {
    return Promise.reject();
  }
  return Promise.resolve();
};
