import { Col, DatePicker, Row, Typography } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { ReactComponent as DownArrowIcon } from "../../assets/DownArrow.svg";
import { ReactComponent as CalenderIcon } from "../../assets/calendar.svg";
import classes from "./CustomRangePicker.module.css";

export type RangePickerValue = Exclude<
  React.ComponentPropsWithoutRef<typeof DatePicker.RangePicker>["value"],
  undefined
>;

const CustomRangePicker = ({
  dateRange,
  onDateRangeChange,
}: {
  dateRange?: RangePickerValue;
  onDateRangeChange?: (dateRange: RangePickerValue) => void;
}) => {
  const diffDays = useMemo(() => (dateRange?.[1] ? dateRange[1]?.diff(dateRange[0], "day") + 1 : 0), [dateRange]);

  return (
    <Row className={classes.rangePickerContainer} align="middle">
      <Col className={classes.rangePickerIcon}>
        <CalenderIcon style={{ margin: 0 }} />
      </Col>
      <Col>
        <Typography.Text className={classes.rangePickerPrefixText}>{diffDays} Tage:</Typography.Text>
      </Col>
      <Col>
        <DatePicker.RangePicker
          allowClear={false}
          value={dateRange}
          onChange={onDateRangeChange}
          className={classes.customRangePicker}
          separator="-"
          format={(val) => dayjs(val).format("D MMM YYYY")}
          components={{
            input: React.forwardRef((props, ref) => {
              return (
                <span>
                  <input ref={ref} className={classes.rangePickerInput} {...props} />
                </span>
              );
            }),
          }}
          suffixIcon={<DownArrowIcon className={classes.rangePickerDownArrowIcon} />}
        />
      </Col>
    </Row>
  );
};

export default CustomRangePicker;
