import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";
import deDE from "antd/locale/de_DE";

import "./styles/antd.css";

import "./styles/sidebar.css";
import "./styles/upload.css";
import "./styles/planner.css";

import { AuthContextProvider } from "context/AuthContext";
import initFacebookSdk from "utils/init-fb";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const queryClient = new QueryClient();

function startApp() {
  root.render(
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Montserrat",
          },
        }}
        locale={deDE}
      >
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </React.StrictMode>,
  );
}

initFacebookSdk().then(startApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
