import React, { Dispatch, ForwardedRef, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, DatePicker, Divider, Spin } from "antd";
import Chart from "react-apexcharts";
import dayjs, { Dayjs } from "dayjs";
import { ApexOptions } from "apexcharts";
import useUserStats from "hooks/Users/useUserStats";
import useCompanyStats from "hooks/company/useCompanyStats";
import useAccountStats from "hooks/useAccountStats";
import usePaymentInvoices from "hooks/Payment/usePaymentInvoices";
import formatNumber from "utils/formatNumber";
import PageHeading from "components/PageHeading";
import { clampDecimal } from "utils/clampDecimal";
import StatCard from "components/StatCard";
import { ReactComponent as DownArrowIcon } from "../../assets/DownArrow.svg";
import { ReactComponent as UserIcon } from "../../assets/User2.svg";
import { ReactComponent as UsersIcon } from "../../assets/Users.svg";
import { ReactComponent as RevenueIcon } from "../../assets/Revenue2.svg";

import classes from "./index.module.css";

function* iterateMonths(startDate: Date | string | Dayjs | number, endDate: Date | string | Dayjs | number) {
  let currMonth = dayjs(startDate).startOf("month");
  const lastMonth = dayjs(endDate).endOf("month");

  while (currMonth.isBefore(lastMonth)) {
    yield currMonth;
    currMonth = currMonth.add(1, "month");
  }
}

const createChartOptions = (series: ApexAxisChartSeries, xaxis: ApexXAxis, color = "#2684FF") => {
  return {
    chart: {
      type: "line",
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
    },
    colors: [color],
    series,
    xaxis,
    legend: {
      show: true,
      showForNullSeries: true,
      showForSingleSeries: true,
      showForZeroSeries: true,
      fontSize: "14px",
      fontWeight: 400,
    },
  } satisfies ApexOptions;
};

type RangePickerValue = Exclude<React.ComponentPropsWithoutRef<typeof DatePicker.RangePicker>["value"], undefined>;

const ChartSectionDateInput = React.forwardRef(
  (props: React.HTMLAttributes<HTMLInputElement>, ref: ForwardedRef<HTMLInputElement>) => (
    <input {...props} ref={ref} value="Unknown Range" readOnly />
  ),
);
ChartSectionDateInput.displayName = "ChartSectionDateInput";

const ChartSection = ({
  title,
  stat,
  children,
  dateRange,
  onDateRangeChange,
}: React.PropsWithChildren & {
  title: string;
  stat: {
    text: string;
    value: number | string;
    icon: React.ReactNode;
  };
  dateRange?: RangePickerValue;
  onDateRangeChange?: (dateRange: RangePickerValue) => void;
}) => {
  const formattedDate = useMemo(() => {
    if (!dateRange) return "Unknown Range";
    const [start, end] = dateRange;
    if (!start || !end) return "Unknown Range";
    return `${start?.format("D MMM YYYY")} - ${end?.format("D MMM YYYY")}`;
  }, [dateRange]);

  return (
    <Card className={`${classes.chartSection}`}>
      <div className={classes.chartSectionHeader}>
        <div className={classes.chartSectionHeaderWrapper}>
          <strong>{title}</strong>
          <DatePicker.RangePicker
            className={classes.chartSectionDateSelect}
            format={() => formattedDate}
            suffixIcon={<DownArrowIcon />}
            value={dateRange}
            onChange={onDateRangeChange}
            separator={null}
            size="small"
          />
        </div>
        <Divider className={classes.chartSectionDivider} orientationMargin={0} />
      </div>
      <div className={classes.chartSectionBody}>
        <div className={classes.chartSectionStat}>
          <div className={classes.chartSectionStatText}>
            {stat.icon}
            <span>{stat.text}</span>
          </div>
          <strong>{stat.value}</strong>
        </div>
        <div>{children}</div>
      </div>
    </Card>
  );
};

const initDateRange = () => {
  const now = dayjs().startOf("day");

  return [now.startOf("year"), now.endOf("month")] as [Dayjs, Dayjs];
};

const sortChartData = (a: { year: number; month: number }, b: { year: number; month: number }) => {
  return a.year === b.year ? a.month - b.month : a.year - b.year;
};

const UserStatsSection = () => {
  const { t } = useTranslation(["common", "pages"]);
  const [dateRange, setDateRange] = useState(initDateRange);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dateRange[0].toDate(),
      endDate: dateRange[1].toDate(),
    };
  }, [dateRange]);

  const { data, isLoading } = useUserStats({
    startDate,
    endDate,
  });

  const usersChartOptions = useMemo(() => {
    const yAxisData: number[] = [];
    const categories: string[] = [];

    if (data) {
      for (const currMonth of iterateMonths(startDate, endDate)) {
        const month = currMonth.month();
        const year = currMonth.year();

        yAxisData.push(data.find((item) => item.month - 1 === month && item.year === year)?.count ?? 0);
        categories.push(currMonth.format("MMMM YYYY"));
      }
    }

    return createChartOptions(
      [
        {
          name: t("pages:adminDashboard.usersLabel"),
          data: yAxisData,
        },
      ],
      {
        categories,
      },
    );
  }, [t, data, startDate, endDate]);

  const totalUsers = useMemo(() => data?.reduce((acc, item) => acc + item.count, 0) ?? 0, [data]);

  return (
    <ChartSection
      title={t("pages:adminDashboard.usersChartTitle")}
      stat={{ text: t("pages:adminDashboard.totalUsersLabel"), value: totalUsers, icon: <UserIcon /> }}
      dateRange={dateRange}
      onDateRangeChange={setDateRange as Dispatch<SetStateAction<RangePickerValue>>}
    >
      <Spin
        spinning={isLoading}
        style={{
          width: "100%",
          height: 240,
        }}
      >
        <Chart series={usersChartOptions.series} options={usersChartOptions} type="line" width="100%" height="100%" />
      </Spin>
    </ChartSection>
  );
};

const CompanyStatsSection = () => {
  const { t } = useTranslation(["common", "pages"]);
  const [dateRange, setDateRange] = useState(initDateRange);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dateRange[0].toDate(),
      endDate: dateRange[1].toDate(),
    };
  }, [dateRange]);

  const { data, isLoading } = useCompanyStats({
    startDate,
    endDate,
  });

  const companiesChartOptions = useMemo(() => {
    const yAxisData: number[] = [];
    const categories: string[] = [];

    if (data) {
      for (const currMonth of iterateMonths(startDate, endDate)) {
        const month = currMonth.month();
        const year = currMonth.year();

        yAxisData.push(data.find((item) => item.month - 1 === month && item.year === year)?.count ?? 0);
        categories.push(currMonth.format("MMMM YYYY"));
      }
    }

    return createChartOptions(
      [
        {
          name: t("pages:adminDashboard.companiesLabel"),
          data: yAxisData,
        },
      ],
      {
        categories,
      },
      "#ECB22E",
    );
  }, [t, data, startDate, endDate]);

  const totalCompanies = useMemo(() => data?.reduce((acc, item) => acc + item.count, 0) ?? 0, [data]);

  return (
    <ChartSection
      title={t("pages:adminDashboard.companiesChartTitle")}
      stat={{
        text: t("pages:adminDashboard.totalCompaniesLabel"),
        value: totalCompanies,
        icon: <UsersIcon />,
      }}
      dateRange={dateRange}
      onDateRangeChange={setDateRange as Dispatch<SetStateAction<RangePickerValue>>}
    >
      <Spin
        spinning={isLoading}
        style={{
          width: "100%",
          height: 240,
        }}
      >
        <Chart
          series={companiesChartOptions.series}
          options={companiesChartOptions}
          type="line"
          width="100%"
          height="100%"
        />
      </Spin>
    </ChartSection>
  );
};

const RevenueStatsSection = () => {
  const { t } = useTranslation(["common", "pages"]);
  const [dateRange, setDateRange] = useState(initDateRange);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dateRange[0].toDate(),
      endDate: dateRange[1].toDate(),
    };
  }, [dateRange]);

  const { data, isLoading } = usePaymentInvoices(
    {
      startDate,
      endDate,
    },
    {
      select: (data) =>
        data.map((item) => ({
          ...item,
          invoices: item.invoices.map((invoice) => ({
            ...invoice,
            amount_paid: clampDecimal(invoice.amount_paid / 100),
          })),
        })),
    },
  );

  const [revenueChartOptions, totalRevenue] = useMemo(() => {
    const yAxisData: number[] = [];
    const categories: string[] = [];
    let totalRevenue = 0;

    //Map of shape {[year]: {[month]: revenue}}
    //2D array of shape where of shape: arr[year][month]
    const revenuePoints = [] as {
      year: number;
      month: number;
      revenue: number;
    }[];

    if (data) {
      for (const item of data) {
        for (const invoice of item.invoices) {
          for (const currMonth of iterateMonths(invoice.period_start * 1000, invoice.period_end * 1000)) {
            const month = currMonth.month();
            const year = currMonth.year();

            let currPoint = revenuePoints.find((item) => item.year === year && item.month === month);

            if (!currPoint) {
              currPoint = {
                year,
                month,
                revenue: 0,
              };
              revenuePoints.push(currPoint);
            }

            currPoint.revenue += invoice.amount_paid;
          }
        }
      }

      for (const currMonth of iterateMonths(startDate, endDate)) {
        const month = currMonth.month();
        const year = currMonth.year();

        const currMonthRev = revenuePoints.find((item) => item.month === month && item.year === year)?.revenue ?? 0;

        yAxisData.push(currMonthRev);
        categories.push(currMonth.format("MMMM YYYY"));
        totalRevenue += currMonthRev;
      }
    }

    return [
      createChartOptions(
        [
          {
            name: `${t("pages:adminRevenue.revenueLabel")} (€)`,
            data: yAxisData,
          },
        ],
        {
          categories,
        },
        "#2EBD59",
      ),
      totalRevenue,
    ];
  }, [t, data, startDate, endDate]);

  const formattedTotalRevenue = useMemo(() => {
    return formatNumber(totalRevenue);
  }, [totalRevenue]);

  return (
    <ChartSection
      title={t("pages:adminDashboard.revenueChartTitle")}
      stat={{
        text: t("pages:adminDashboard.totalRevenueLabel"),
        value: `${formattedTotalRevenue}€`,
        icon: <RevenueIcon />,
      }}
      dateRange={dateRange}
      onDateRangeChange={setDateRange as Dispatch<SetStateAction<RangePickerValue>>}
    >
      <Spin
        spinning={isLoading}
        style={{
          width: "100%",
          height: 240,
        }}
      >
        <Chart
          series={revenueChartOptions.series}
          options={revenueChartOptions}
          type="line"
          width="100%"
          height="100%"
        />
      </Spin>
    </ChartSection>
  );
};

const StatCardSection = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { startDate, endDate } = useMemo(() => {
    const now = dayjs().endOf("month");
    return { startDate: now.subtract(2, "month").startOf("month").toDate(), endDate: now.toDate() };
  }, []);

  const { data: userData, isLoading: isLoadingUserData } = useUserStats({ startDate, endDate });
  const { data: companiesData, isLoading: isLoadingCompaniesData } = useCompanyStats({ startDate, endDate });
  const { data: accountsData, isLoading: isLoadingAccountsData } = useAccountStats({ startDate, endDate });

  const {
    currMonthUserCount,
    currMonthCompanyCount,
    currMonthAccountCount,
    prevMonthUserCount,
    prevMonthCompanyCount,
    prevMonthAccountCount,
  } = useMemo(() => {
    const sortedUserData = userData?.toSorted(sortChartData) ?? [];
    const sortedCompaniesData = companiesData?.toSorted(sortChartData) ?? [];
    const sortedAccountsData = accountsData?.toSorted(sortChartData) ?? [];

    const currMonthUserCount = sortedUserData.at(-1)?.count ?? 0;
    const currMonthCompanyCount = sortedCompaniesData.at(-1)?.count ?? 0;
    const currMonthAccountCount = sortedAccountsData.at(-1)?.count ?? 0;

    const prevMonthUserCount = sortedUserData.at(-2)?.count ?? 0;
    const prevMonthCompanyCount = sortedCompaniesData.at(-2)?.count ?? 0;
    const prevMonthAccountCount = sortedAccountsData.at(-2)?.count ?? 0;

    return {
      currMonthCompanyCount,
      currMonthUserCount,
      currMonthAccountCount,
      prevMonthCompanyCount,
      prevMonthUserCount,
      prevMonthAccountCount,
    };
  }, [userData, companiesData, accountsData]);

  return (
    <section className={classes.statCardsSection}>
      <StatCard
        title={t("pages:adminDashboard.newUsersLabel")}
        stat={currMonthUserCount}
        prevMonthStat={prevMonthUserCount}
        loading={isLoadingUserData}
      />
      <StatCard
        title={t("pages:adminDashboard.newCompaniesLabel")}
        stat={currMonthCompanyCount}
        prevMonthStat={prevMonthCompanyCount}
        loading={isLoadingCompaniesData}
      />
      <StatCard
        title={t("pages:adminDashboard.newSubscribersLabel")}
        stat={currMonthAccountCount}
        prevMonthStat={prevMonthAccountCount}
        loading={isLoadingAccountsData}
      />
    </section>
  );
};

const AdminDashboard = () => {
  const { t } = useTranslation(["common", "pages"]);

  return (
    <div className={`container ${classes.page}`}>
      <PageHeading>
        <PageHeading.Title className={classes.title}>{t("pages:adminDashboard.title")}</PageHeading.Title>
        <PageHeading.Subtitle className={classes.subtitle}>{t("pages:adminDashboard.subtitle")}</PageHeading.Subtitle>
      </PageHeading>
      <StatCardSection />
      <UserStatsSection />
      <CompanyStatsSection />
      <RevenueStatsSection />
    </div>
  );
};

export default AdminDashboard;
