import { UploadFile } from "antd";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";

export type UpdateAccountResult = Awaited<ReturnType<typeof AccountApi.updateAccountDetails>>;
export type TUpdateAccountParams = {
  accountId: string;
  name?: string;
  email?: string;
  address?: string;
  postal?: string;
  city?: string;
  website?: string;
  phone?: string;
  vat?: string;
  account_profile_pic?: string;
  accountProfilePic?: UploadFile;
};

const useUpdateAccount = (
  options?: Omit<UseMutationOptions<UpdateAccountResult, unknown, TUpdateAccountParams, unknown>, "mutationFn">,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: AccountApi.updateAccountDetails,
    onSuccess: async (data, vars, ctx) => {
      await queryClient.invalidateQueries(AccountQueryKeys.list());
      await queryClient.invalidateQueries(AccountQueryKeys.details(vars.accountId));

      if (options?.onSuccess) {
        options.onSuccess(data, vars, ctx);
      }
    },
  });
};

export default useUpdateAccount;
