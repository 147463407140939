import { useState } from "react";
import { PopupModal } from "react-calendly";
import { FloatButton } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";

const CalendlyWidget = () => {
  const element = document.getElementById("root") as HTMLElement;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openCalendlyPopup = async () => {
    setIsPopupOpen((prev) => !prev);
  };

  return (
    <div>
      <FloatButton
        style={{ height: 50, width: 50, position: "fixed", bottom: "70px" }}
        icon={<CustomerServiceOutlined size={30} />}
        tooltip={<span>Termin buchen</span>}
        onClick={openCalendlyPopup}
      />
      <PopupModal
        open={isPopupOpen}
        onModalClose={() => setIsPopupOpen(false)}
        url="https://calendly.com/easypostlab/onboarding"
        rootElement={element}
      />
    </div>
  );
};

export default CalendlyWidget;
