import { TabsProps, Tabs } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useTranslation } from "react-i18next";
import PageHeading from "components/PageHeading";
import { useMemo } from "react";
import classes from "./Settings.module.css";
import ProfileSettings from "./Profile";
import AdminSettings from "./Admin";
import General from "./General";
import Companies from "./Companies";
import Billing from "./Billing";

const items: TabsProps["items"] = [
  {
    key: "admin",
    label: "Admin",
    children: <AdminSettings />,
  },
  {
    key: "general",
    label: "Allgemein",
    children: <General />,
  },
  {
    key: "profile",
    label: "Profile",
    children: <ProfileSettings />,
  },
  {
    key: "companies",
    label: "Unternehmen",
    children: <Companies />,
  },
  {
    key: "billing",
    label: "Abonnement",
    children: <Billing />,
  },
  // {
  //     key: 'publisher',
  //     label: 'PUBLISHER',
  //     children: `Content of Tab Pane 3`,
  // },
  // {
  //   key: "ads",
  //   label: "ADS",
  //   children: <AdSettings />,
  // },
];

export default function Settings() {
  const { t } = useTranslation(["common", "pages"]);

  const { useParams, useHistory } = useRouterContext();
  const { push } = useHistory();

  const { tab } = useParams<{ tab: string }>();

  const { pathPrefix } = useSelectedAccount();

  const subtitle = useMemo(() => {
    if (tab === "admin") return t("pages:settings.adminSettingsSubtitle");

    if (tab === "general") return t("pages:settings.generalSettingsSubtitle");

    if (tab === "profile") return t("pages:settings.profileSettingsSubtitle");

    if (tab === "companies") return t("pages:settings.companySettingsSubtitle");

    if (tab === "billing") return t("pages:settings.billingSettingsSubtitle");
  }, [t, tab]);

  return (
    <div className="container">
      <PageHeading>
        <PageHeading.Title>{t("pages:settings.title")}</PageHeading.Title>
        {subtitle ? <PageHeading.Subtitle>{subtitle}</PageHeading.Subtitle> : null}
      </PageHeading>

      <Tabs
        items={items}
        defaultActiveKey="admin"
        className={classes.SettingsTabs}
        activeKey={tab}
        onChange={(key) => push(`${pathPrefix}/settings/${key}`)}
      />
    </div>
  );
}
