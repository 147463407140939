import { MediaTypes } from "constants/editor";
import { Button, Col, Flex, Row, Upload, UploadProps } from "antd";
import { CreatePost, Post } from "types";
import type { UploadFile } from 'antd';
import { convertBase64ToBlob, getBase64 } from "utils";
import { RcFile } from "antd/es/upload";
import CropModal from "pages/PostEditor/NewComponents/CropModal";
import { ReactComponent as ImageIcon } from '../../../../assets/image.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/video.svg';
import { ReactComponent as ZipIcon } from '../../../../assets/file-plus.svg';
import classes from "./Assets.module.css";

export type AssetsComponentProps = {
  post: Post | undefined;
  localPost: CreatePost;
  setLocalPost: React.Dispatch<React.SetStateAction<CreatePost>>;
  showCropModal: boolean;
  setShowCropModal: (state: boolean) => void;
  setZipModalOpen: (state: boolean) => void;
};

const AssetsComponent = (props: AssetsComponentProps) => {
  const { localPost, setLocalPost, showCropModal, setShowCropModal, setZipModalOpen, post } = props;

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    const base64Content = file.preview as string;

    if (file.url ?? (file.preview as string)) {
      if (base64Content) {
        // Convert base64 to a Blob object
        const blobUrl = convertBase64ToBlob(base64Content, file.type as MediaTypes);

        // Open the temporary URL in a new tab
        window.open(blobUrl, '_blank');

        // Cleanup the temporary URL after use
        URL.revokeObjectURL(blobUrl);
      } else {
        window.open(file.url, '_blank');
      }
    }
  };

  const uploadProps: UploadProps = {
    customRequest: ({ onSuccess }) => {
      onSuccess && onSuccess("Ok");
    },
    className: classes.upload,
    multiple: false,
    onPreview: handlePreview,
    onChange({ file }) {
      if (file.status === "removed") {
        const updatedList = localPost.assets.filter((item) => item.name !== file.name);

        setLocalPost({
          ...localPost,
          assets: updatedList,
        });
      } else {
        setLocalPost((prev: CreatePost) => ({
          ...prev,
          assets: [...prev.assets, { ...file, status: 'done' }],
        }));
        setShowCropModal(!!((file.type === MediaTypes.JPEG || file.type === MediaTypes.PNG) && localPost.platforms.find((item) => item.includes("INSTAGRAM"))));
      }
    },
    listType: "picture-card",
    fileList: localPost.assets,
    accept: 
      Object.values(MediaTypes).toString(),
  };

  return (
    <Flex vertical gap={20}>
      <Row gutter={12}>
        <Col span={8}>
          <Upload {...uploadProps} listType="picture" fileList={[]} accept={MediaTypes.JPEG.toString() + ',' + MediaTypes.PNG} data-testid="post-image-upload">
            <Button className={classes.assetActionButton} type="default" icon={<ImageIcon />}>Bild hochladen</Button>
          </Upload>
        </Col>
        <Col span={8}>
          <Upload {...uploadProps} listType="picture" fileList={[]} accept={MediaTypes.MP4.toString()} data-testid="post-video-upload">
            <Button className={classes.assetActionButton} type="default" icon={<VideoIcon />}>Video hochladen</Button>
          </Upload>
        </Col>
        {post === undefined && <Col span={8}>
          <Button className={classes.assetActionButton} type="default" icon={<ZipIcon />} onClick={() => setZipModalOpen(true)}>Import zip</Button>
        </Col>}
      </Row>
      {localPost.assets.length ? <Upload {...uploadProps} data-testid="post-upload-list" />: null}
      {showCropModal ? <CropModal
        showModal={showCropModal}
        files={localPost.assets}
        onCloseModal={() => setShowCropModal(false)}
        updateCroppedFile={(croppedFiles: UploadFile[]) => setLocalPost({ ...localPost, assets: croppedFiles})}
      />: null}
    </Flex>
  );
};

export default AssetsComponent;
