import {
  FB_IMG_SIZE_LIMIT,
  FB_IMG_SIZE_MB,
  FB_PNG_RECC_SIZE_LIMIT,
  FB_PNG_RECC_SIZE_MB,
  FB_VIDEO_SIZE_LIMIT,
  FB_VIDEO_SIZE_MB,
  IG_IMG_SIZE_LIMIT,
  IG_IMG_SIZE_MB,
  IG_VIDEO_SIZE_LIMIT,
  IG_VIDEO_SIZE_MB,
  MediaTypes,
} from "constants/editor";
import { UploadFile } from "antd";

export enum ComponentType {
  ACCOUNT = "account",
  ASSET = "asset",
  BODY = "body",
  ACTION = "action",
}

export enum MessageType {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

export type ValidationResult = {
  valid: boolean;
  type: ComponentType;
  file?: UploadFile;
  messageType: MessageType;
  message?: string;
};

const videoRegex = /(https?:\/\/.*\.(?:mp4))/i;
const imageRegex = /(https?:\/\/.*\.(?:png|jpeg|jpg))/i;

export const validateVideoForFb = (file: UploadFile): ValidationResult => {
  if (!!file.url && videoRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > FB_VIDEO_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Video-Größe darf ${FB_VIDEO_SIZE_MB}MB nicht überschreiten.`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Videos konnte nicht bestimmt werden.",
    };
  } else {
    // Video is valid
    return {
      valid: true,
      messageType: MessageType.SUCCESS,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      message: "Dein Video erfüllt die Plattformanforderungen.",
    };
  }
};

export const validateImageForFb = (file: UploadFile): ValidationResult => {
  if (!!file.url && imageRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > FB_IMG_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Die Bildgröße darf ${FB_IMG_SIZE_MB}MB nicht überschreiten.`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Bildes kann nicht bestimmt werden.",
    };
  } else {
    // Image is valid
    if (file.type === MediaTypes.PNG && file.size > FB_PNG_RECC_SIZE_LIMIT) {
      return {
        valid: true,
        type: ComponentType.ASSET,
        file: { ...file, status: "error" },
        messageType: MessageType.ERROR,
        message: `Die Größe eines PNG-Bildes sollte unter ${FB_PNG_RECC_SIZE_MB}MB für Facebook sein, sonst erscheint das Bild möglicherweise verpixelt.`,
      };
    }

    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  }
};

export const validateVideoForInsta = (file: UploadFile): ValidationResult => {
  if (!!file.url && videoRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > IG_VIDEO_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Video-Größe darf ${IG_VIDEO_SIZE_MB}MB nicht überschreiten`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Videos konnte nicht bestimmt werden.",
    };
  } else {
    // Video is valid
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Deine Video(s) erfüllen die Plattformanforderungen.",
    };
  }
};

export const validateImageForInsta = (file: UploadFile): ValidationResult => {
  if (!!file.url && imageRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > IG_IMG_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Die Bildgröße darf ${IG_IMG_SIZE_MB}MB nicht überschreiten.`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Bildes kann nicht bestimmt werden.",
    };
  } else {
    // Image is valid
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  }
};

export const validateVideoForInstaFb = (file: UploadFile): ValidationResult => {
  if (!!file.url && videoRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > IG_VIDEO_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Video-Größe darf ${IG_VIDEO_SIZE_MB}MB nicht überschreiten.`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Videos konnte nicht bestimmt werden.",
    };
  } else {
    // Video is valid
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein Video erfüllt die Plattformanforderungen.",
    };
  }
};

export const validateImageForInstaFb = (file: UploadFile): ValidationResult => {
  if (!!file.url && imageRegex.test(file.url)) {
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  } else if (file.size !== undefined && file.size > FB_IMG_SIZE_LIMIT) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: `Die Bildgröße darf ${FB_IMG_SIZE_MB}MB nicht überschreiten.`,
    };
  } else if (file.size === undefined) {
    return {
      valid: false,
      type: ComponentType.ASSET,
      file: { ...file, status: "error" },
      messageType: MessageType.ERROR,
      message: "Die Größe des Bildes kann nicht bestimmt werden.",
    };
  } else {
    // Image is valid
    return {
      valid: true,
      type: ComponentType.ASSET,
      file: { ...file, status: "done" },
      messageType: MessageType.SUCCESS,
      message: "Dein(e) Bild(er) erfüllen die Plattformanforderungen.",
    };
  }
};
