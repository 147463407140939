import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { ControlOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import pluginConfig from "./config";

const CookieConsentComponent = () => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);

  return (
    <FloatButton
      tooltip={<span>Einstellungen ändern</span>}
      onClick={() => CookieConsent.show(true)}
      icon={<ControlOutlined size={30} />}
      style={{
        height: 50,
        width: 50,
        position: "fixed",
        bottom: "10px",
      }}
    />
  );
};

export default CookieConsentComponent;
