import deResources from "./deResources";
import enResources from "./enResources";

/**
 * Which strings go into which file:
 * `common.json` - Contains all common strings
 * `fields.json` - Contains all field
 * `validations.json` - Contains all validation strings
 * `pages.json` - Contains all page specific strings
 */

export const resources = { en: enResources, de: deResources };
