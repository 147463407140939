import React, { useContext, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Button, Popconfirm, Row, Spin, TableProps, message, Col, Flex } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import dayjs from "dayjs";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";

import { Channel, PLATFORMS } from "types";
import { AccountApi } from "services/api";
import FB from "services/meta";
import { convertUTCDateToLocal } from "utils/date";
import useSelectedAccount from "hooks/useSelectedAccount";
import useChannels from "hooks/useChannels";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import NotAllowed from "components/NotAllowed";
import CustomTable from "components/CustomTable";
import CustomBadge from "components/CustomBadge";
import { useTranslation } from "react-i18next";
import CustomAvatar from "components/CustomAvatar";
import IconButton from "components/IconButton/IconButton";
import { ReactComponent as RefreshCw } from "../../../assets/RefreshCw.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/DeleteIcon.svg";
import classes from "./Profile.module.css";

const DeletePopConfirm = ({ onConfirm, children }: React.PropsWithChildren & { onConfirm: () => void }) => {
  return (
    <Popconfirm
      title="Bist du sicher, dass du dieses Profil entfernen möchtest?"
      okText="Ja"
      cancelText="Abbrechen"
      onConfirm={onConfirm}
      okButtonProps={{ className: classes.deleteAccountConfirmBtn }}
      placement="top"
    >
      {children}
    </Popconfirm>
  );
};

export default function ProfileSettings() {
  const { t } = useTranslation(["common", "pages"]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { push } = useRouterContext().useHistory();
  const { selectedAccountId: accountId } = useSelectedAccount();
  const { ability, isLoading: isLoadingPermissions } = useContext(UserAccessPolicyContext);

  const canViewChannels = ability.can("read", "Channel");
  const canManageChannels = ability.can("manage", makeSubject("Channel", { accountId }));

  const { data: channels, isLoading, refetch } = useChannels({ accountId }, { enabled: canViewChannels });

  const { mutate: refreshAccessToken, isLoading: isAccessTokenRefreshing } = useMutation(async (ids: string[]) => {
    const socialMedia = ids.map((row) => channels?.find((c) => c.id === row));
    if (socialMedia?.length) {
      await FB.login({
        type: socialMedia.find((channel) => channel && channel.type.toLowerCase() === "instagram")
          ? "instagram"
          : "facebook",
      });
    }
  });

  const { mutate: deleteAccounts, isLoading: isDeletingAccounts } = useMutation(
    async (ids: string[]) => {
      if (canManageChannels) {
        await AccountApi.deleteChannels({
          accountId: `${accountId}`,
          ids,
        });
      }
    },
    {
      onSuccess: () => {
        setSelectedRows([]);
        refetch();
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const { pathPrefix } = useSelectedAccount();

  const columns: TableProps<Channel>["columns"] = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => (a.name < b.name ? -1 : 1),
        render(value, record) {
          return {
            children: (
              <Flex gap="12px" align="center">
                <CustomAvatar>{record.name[0] ?? "U"}</CustomAvatar>
                <Col span={18}>{value}</Col>
              </Flex>
            ),
          };
        },
      },
      {
        title: "Plattform",
        dataIndex: "type",
        key: "id",
        sorter: (a, b) => a.type.localeCompare(b.type),
        render(_, record) {
          return {
            children: <span>{record.type === PLATFORMS.FACEBOOK ? "Facebook" : "Instagram"}</span>,
          };
        },
      },
      {
        title: "Access Token",
        dataIndex: "accessStatus",
        key: "accessStatus",
        render: (accessStatus: "HEALTHY" | "UNHEALTHY") => {
          const isActive = accessStatus === "HEALTHY";
          return (
            <CustomBadge color={isActive ? "green" : "red"} size="sm">
              {isActive ? t("common:labels.active") : t("common:labels.inActive")}
            </CustomBadge>
          );
        },
      },
      {
        title: "Verbindung aktualisiert",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: (a, b) => (dayjs.utc(a.updatedAt).isBefore(dayjs.utc(b.updatedAt)) ? -1 : 1),
        render: (updatedAt: string) => convertUTCDateToLocal(updatedAt).format("DD.MM.YYYY HH.mm[h]"),
      },
      {
        title: t("common:labels.actions"),
        dataIndex: "updatedAt",
        key: "id",
        render: (_, record) => {
          return (
            <div>
              <IconButton
                alt="Refresh access token"
                svgColorProp="stroke"
                onClick={() => refreshAccessToken([record.id])}
                disabled={isAccessTokenRefreshing}
              >
                <RefreshCw stroke={undefined} />
              </IconButton>
              <DeletePopConfirm onConfirm={() => deleteAccounts([record.id])}>
                <IconButton alt="Delete channel" svgColorProp="fill" disabled={isDeletingAccounts}>
                  <DeleteIcon fill={undefined} />
                </IconButton>
              </DeletePopConfirm>
            </div>
          );
        },
      },
    ],
    [t, isDeletingAccounts, isAccessTokenRefreshing, refreshAccessToken, deleteAccounts],
  );

  return (
    <div className={classes.ProfileContainer}>
      {isLoading || isLoadingPermissions ? (
        <Spin />
      ) : canViewChannels ? (
        <div className={classes.ProfileContent}>
          <Row className={classes.ProfileFilters} justify="space-between">
            <div>
              {selectedRows.length > 0 && (
                <>
                  <Button
                    type="default"
                    className={classes.ProfileButton}
                    onClick={() => refreshAccessToken(selectedRows)}
                    loading={isAccessTokenRefreshing}
                  >
                    <ReloadOutlined />
                    Zugriff aktualisieren
                  </Button>
                  <DeletePopConfirm onConfirm={() => deleteAccounts(selectedRows)}>
                    <Button type="default" className={classes.ProfileButton} loading={isDeletingAccounts}>
                      <DeleteOutlined />
                      Löschen
                    </Button>
                  </DeletePopConfirm>
                </>
              )}
            </div>
            {canManageChannels ? (
              <Button
                type="primary"
                className={classes.AddProfileButton}
                onClick={() => push(`${pathPrefix}/settings/profile/new`)}
              >
                Profil hinzufügen
              </Button>
            ) : null}
          </Row>
          <CustomTable
            headerPrefix={<CustomTable.Title>{t("pages:profileSettings.tableTitle")}</CustomTable.Title>}
            defaultProps={{
              dataSource: channels ?? [],
              columns,
              rowKey: "id",
              rowSelection: canManageChannels
                ? {
                    selectedRowKeys: selectedRows,
                    onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys as string[]),
                  }
                : undefined,
            }}
          />
        </div>
      ) : (
        <NotAllowed />
      )}
    </div>
  );
}
