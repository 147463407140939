import { UploadFile } from "antd";
import axios from "axios";
import api from "utils/api";
import { ENDPOINTS } from "utils/endpoints";

//Subset type of UploadFile using only the fields that are used for uploading
//so that ts doesn't complain and the API is more generic
export type AssetApiUploadFile = {
  originFileObj?: File;
  name: UploadFile["name"];
  type?: UploadFile["type"];
  url?: UploadFile["url"];
};

const uploadAndConfirmOne = async (
  file: AssetApiUploadFile,
  accountId?: string,
  companyId?: string,
): Promise<{
  url: string | undefined;
  type: string | undefined;
}> => {
  if (file.originFileObj) {
    const { data } = await api.post(ENDPOINTS.POST_UPLOAD_ASSET, {
      accountId,
      companyId,
      filename: file.name,
      mimeType: file.type,
    });
    const { assetId, preSignedUploadUrl } = data.data;

    // Upload the file to S3
    await axios.put(preSignedUploadUrl, file.originFileObj, {
      headers: {
        "Content-Type": file.type,
      },
    });

    // Confirm the asset upload status
    await api.post(ENDPOINTS.POST_UPLOAD_CONFIRM + `/${assetId}`, {
      accountId,
      companyId,
      uploaded: true,
    });

    return {
      url: preSignedUploadUrl.split("?")[0],
      type: file.type,
    };
  } else {
    if (file.url?.includes(".mp4")) {
      return {
        url: file.url,
        type: "video/mp4",
      };
    } else if (file.url?.includes(".jpeg")) {
      return {
        url: file.url,
        type: "image/jpeg",
      };
    } else if (file.url?.includes(".png")) {
      return {
        url: file.url,
        type: "image/png",
      };
    } else {
      return {
        url: file.url,
        type: "image/jpg",
      };
    }
  }
};

/**
 * Returns a array of asset objects as { url: string; type: string }
 */
export const AssetApi = {
  uploadAndConfirmOne,
  uploadAndConfirm: async (files: AssetApiUploadFile[], accountId?: string, companyId?: string) => {
    const responses = await Promise.all(
      files.map(async (file) => {
        return await uploadAndConfirmOne(file, accountId, companyId);
      }),
    );

    return responses;
  },
};
