import { IMG_SIZE_LIMIT } from "constants/editor";
import { useEffect, useState } from "react";
import { Row, Input, Select, Form, Radio, Button, Col, Spin, message, Modal } from "antd";
import { SelectValue } from "antd/lib/select";
import { CarouselData, PostData, Account, AdsCreativeData, CONVERSIONS, GOALS } from "types";
import { useRouterContext } from "@pankod/refine-core";
import { CampaignApi, ImageApi } from "services/api";
import { useQuery, useMutation } from "react-query";
import useQueryCacheData from "hooks/useQueryCacheData";
import CallToAction from "./CallToAction";
import classes from "./CampaignAds.module.css";
import "../../../../../styles/radio.css";
import PreviewForm from "./PreviewForm";
import callToActionTypesBasedonConversion from "./constants";

const { TextArea } = Input;
const { Option } = Select;

const categoryType: { [key: string]: string } = {
  OUTCOME_AWARENESS: "bekanntheit",
  OUTCOME_TRAFFIC: "traffic",
  OUTCOME_ENGAGEMENT: "interaktionen",
  OUTCOME_LEADS: "leads",
  OUTCOME_SALES: "umsatz",
};

type ValueOptionData = {
  label: string;
  value: string;
};

const validateFields = (carouselUpload: boolean, postsData: PostData[], carouselData: CarouselData[]) => {
  const videoCount = postsData.filter((item) => item.videoFile?.type === "video/mp4").length;
  // if images file is empty
  if (postsData.length === 0 && !carouselUpload) {
    throw new Error("Bitte wählen Sie Bilder oder Videos aus");
  }

  // if carousal is empty
  if (carouselData.length === 0 && carouselUpload) {
    throw new Error("Bitte wählen Sie mindestens 2  Medien für das Karussell aus");
  }

  // if any of the image' size in postsData or carouselData is more than 8MB
  if (!carouselUpload && postsData.some((file) => file.imageFile?.size && file.imageFile.size > IMG_SIZE_LIMIT)) {
    throw Error("Bitte wählen Sie Medien aus, die weniger als 8 MB sind");
  }

  if (!carouselUpload && postsData.some((file) => file.videoFile?.size && file.videoFile.size > IMG_SIZE_LIMIT)) {
    throw Error("Bitte wählen Sie Medien aus, die weniger als 8 MB sind");
  }

  if (carouselUpload && carouselData.some((file) => file.imageFile?.size && file.imageFile.size > IMG_SIZE_LIMIT)) {
    throw Error("Bitte wählen Sie Medien aus, die weniger als 8 MB sind");
  }

  if (carouselUpload && carouselData.some((file) => file.videoFile?.size && file.videoFile.size > IMG_SIZE_LIMIT)) {
    throw Error("Bitte wählen Sie Medien aus, die weniger als 8 MB sind");
  }

  // if carousal has only 1 image or video
  if (carouselData.length === 1 && carouselUpload) {
    throw Error("Bitte wählen Sie mindestens 2  Medien für das Karussell aus");
  }

  // if images uploaded are of length 2
  if ((!carouselUpload && postsData.length === 2 && postsData.length > 7) || videoCount > 1) {
    throw new Error(
      "Sie können entweder ein einzelnes Bild oder ein einzelnes Video oder mindestens 3 und maximal 7 Bilder für eine Diashow auswählen",
    );
  }

  // if carousal are more than 10
  if (carouselUpload && carouselData.length > 10) {
    throw new Error("Karussellmedien dürfen 10 nicht überschreiten");
  }

  // if any of the images' type is not jpg, jpeg or mp4 in carousal
  if (
    carouselUpload &&
    carouselData &&
    carouselData.some(
      (image) =>
        image.imageFile?.type &&
        !image.imageFile.type.includes("image/jpeg") &&
        !image.imageFile.type.includes("image/jpg") &&
        !image.imageFile?.type.includes("video/mp4"),
    )
  ) {
    throw new Error("Bitte wählen Sie Mediendateien im Format jpg, jpeg, jfif, pjpg, pjpeg, m4v oder mp4 aus");
  }

  // if any of the images' type is not jpg, jpeg in single image or video
  if (
    !carouselUpload &&
    postsData.length === 1 &&
    postsData.some(
      (image) =>
        image.imageFile?.type &&
        !image.imageFile?.type.includes("image/jpeg") &&
        !image.imageFile?.type.includes("image/jpg") &&
        !image.imageFile?.type.includes("video/mp4"),
    )
  ) {
    throw new Error("Bitte wählen Sie Mediendateien im Format jpg, jpeg, jfif, pjpg, pjpeg, m4v oder mp4 aus");
  }

  // if images file not present in a slideshow
  if (
    !carouselUpload &&
    postsData.length > 2 &&
    postsData.some(
      (item) =>
        !item.imageFile?.type ||
        item.video ||
        item.videoFile?.type ||
        (item.imageFile?.type && !["image/jpeg", "image/jpg"].includes(item.imageFile.type)),
    )
  ) {
    throw new Error(
      "Sie können Bilder nur als Diashow hochladen. Bitte wählen Sie Bilder aus, die im Format jpg oder jpeg sind",
    );
  }
};

export default function CampaignAds() {
  const [form] = Form.useForm();
  const params = useRouterContext().useParams<{
    id: string;
    category: string;
    adsetId: string;
    adId: string;
  }>();
  const { push } = useRouterContext().useHistory();
  const [category, setCategory] = useState<string>(params.category || GOALS.TRAFFIC);
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [adText, setAdText] = useState("");
  const [facebookSiteOption, setFacebookSiteOption] = useState<string>("");
  const [instagramSiteOption, setInstagramSiteOption] = useState<string>("");
  const [carouselUpload, setCarouselUpload] = useState<boolean>(true);
  const [postsData, setPostsData] = useState<PostData[]>([]);
  const [carouselData, setCarouselData] = useState<CarouselData[]>([]);
  const [currentConversion, setCurrentConversion] = useState("WEBSITE");
  const [callToActionOption, setCallToActionOption] = useState<string>("");
  const [facebookSiteOptions, setFacebookSiteOptions] = useState<ValueOptionData>({
    label: "",
    value: "",
  });
  const [instagramSiteOptions, setInstagramSiteOptions] = useState<ValueOptionData>({
    label: "",
    value: "",
  });
  const [creativeId, setCreativeId] = useState<string>("");
  const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
  const [instagramActorId, setInstagramActorId] = useState<string>("");
  const [leadFormId, setLeadFormId] = useState<string>("");
  const [welcomeMessage, setWelcomeMessage] = useState(`Hallo ${accounts[0].name} ! Was können wir für dich tun?`);
  const [countryCode, setCountryCode] = useState("+49");
  const [isSponsored, setIsSponsored] = useState<boolean>(false);
  const [remoteId, setRemoteId] = useState<string>("");

  const { refetch: getCampaignFetch } = useQuery(
    "getCampaign",
    () => CampaignApi.getCampaignDetails(accounts[0].id, params.id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCategory(categoryType[data.objective]);
      },
      onError(error) {
        console.log(error);
      },
    },
  );

  const { refetch: getAdsetFetch } = useQuery(
    "getAdset",
    () => CampaignApi.getAdsetDetails(accounts[0].id, params.adsetId),
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCurrentConversion(data.destination_type);
      },
      onError(error: Error) {
        console.log(error.message);
      },
    },
  );

  const { refetch: getAdDataFetch } = useQuery(
    "ad",
    () => {
      return CampaignApi.getAdData(accounts[0].id, params.adId || "");
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        form.setFieldValue("adsName", data.name);
        setCreativeId(data.creativeId);
      },
    },
  );

  const { refetch: getCreativeData } = useQuery(
    "get-creativeData",
    () => CampaignApi.getAdCreativesData(accounts[0].id, creativeId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCallToActionOption(
          data.object_story_spec.link_data?.call_to_action.type ||
            data.object_story_spec.video_data?.call_to_action.type ||
            "",
        );
        if (data.object_story_spec.link_data?.child_attachments) {
          form.setFieldValue("adText", data.object_story_spec.link_data.message || "");
          form.setFieldValue("link", data.object_story_spec.link_data.link || "");
          form.setFieldValue("linkCaption", data.object_story_spec.link_data.call_to_action.value.link_caption || "");
          setCarouselUpload(true);
          const fetchCarousalData: CarouselData[] = data.object_story_spec.link_data.child_attachments.map(
            (attachment, idx) => {
              form.setFieldValue(`websiteUrl${idx}`, attachment.link);
              form.setFieldValue(`description${idx}`, attachment.description);
              return {
                image: attachment.picture,
                video: attachment.video_id ? attachment.video_id : "",
                name: attachment.name || "",
                description: attachment.description || "",
                url: attachment.link || "",
              };
            },
          );
          setCarouselData(fetchCarousalData);
        } else {
          setCarouselUpload(false);
          if (data.object_story_spec.link_data) {
            form.setFieldValue("headline", data.object_story_spec.link_data.name || "");
            form.setFieldValue("adText", data.object_story_spec.link_data.message || "");
            form.setFieldValue("description", data.object_story_spec.link_data.description || "");
            form.setFieldValue("link", data.object_story_spec.link_data.link || "");
            form.setFieldValue("linkCaption", data.object_story_spec.link_data.call_to_action.value.link_caption || "");
          } else if (data.object_story_spec.video_data) {
            form.setFieldValue("adText", data.object_story_spec.video_data.message || "");
            form.setFieldValue("link", data.object_story_spec.video_data.call_to_action.value.link || "");
            form.setFieldValue(
              "linkCaption",
              data.object_story_spec.video_data.call_to_action.value.link_caption || "",
            );
          }
        }
        if (data.messenger_sponsored_message) {
          setIsSponsored(true);
          setWelcomeMessage(data.messenger_sponsored_message.message.text);
        } else {
          setIsSponsored(false);
          if (data.object_story_spec.link_data?.page_welcome_message?.message) {
            setWelcomeMessage(data.object_story_spec.link_data.page_welcome_message.message.text);
          } else if (
            data.object_story_spec.video_data &&
            data.object_story_spec.link_data?.page_welcome_message?.message
          ) {
            setWelcomeMessage(data.object_story_spec.video_data.page_welcome_message?.message.text || "");
          }
        }
        setFacebookSiteOption(data.object_story_spec.page_id);
        if (data.object_story_spec.instagram_actor_id) {
          setInstagramActorId(data.object_story_spec.instagram_actor_id);
        }
        if (data.object_story_spec.link_data?.call_to_action?.value?.link) {
          const phone = data.object_story_spec.link_data?.call_to_action.value.link[0];
          if (phone[0] === "+") {
            setCountryCode(phone.slice(0, 3));
            form.setFieldValue("telefon", phone.slice(3));
          }
        }
      },
      onError(error: Error) {
        console.log(error.message);
      },
    },
  );

  const {
    isLoading: isAdsChannelLoading,
    refetch: getAdsChannel,
    isFetching: isAdsChannelFetching,
  } = useQuery("get-adsAccount", () => CampaignApi.getAdsAccountChannels(accounts[0].id), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (data) {
        setRemoteId(data[0].remoteId);
        if (data[0].type === "FACEBOOK") {
          setFacebookSiteOptions({
            label: data[0].name,
            value: data[0].remoteId,
          });
          setFacebookSiteOption(data[0].remoteId);
          setInstagramActorId(data[0].instagramActorId);
          setInstagramSiteOption(data[0].instagramActorId);
        }
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const { mutate: createAdsMutate } = useMutation(
    "ads",
    (id: string) => {
      const body: {
        name: string;
        adsetId: string;
        creative_id: string;
        leadFormId?: string;
        status: string;
      } = {
        name: form.getFieldValue("adsName"),
        adsetId: params.adsetId,
        creative_id: id,
        // ...(category === GOALS.LEADS && { leadFormId }),
        status: "ACTIVE",
      };
      if (params.adId) {
        return CampaignApi.updateAds(accounts[0].id, body, params.adId);
      }
      return CampaignApi.createAds(accounts[0].id, body);
    },
    {
      onSuccess(data) {
        if (params.adsetId) {
          message.success("Ad aktualisiert");
          push("/user/ads/dashboard");
        } else {
          message.success("Ad erstellt.");
        }
      },
    },
  );

  const { mutate, isLoading } = useMutation(
    "ads-creative",
    async () => {
      if (carouselData.some((item) => !item.image)) {
        throw new Error("Laden Sie ein Miniaturbild für die Videodatei hoch");
      }
      validateFields(carouselUpload, postsData, carouselData);
      let updatedCarousalAssets;
      let updatedPostsDataAssets;
      let slideShowId;
      if (carouselUpload) {
        setShowProgressModal(true);
        updatedCarousalAssets = await Promise.all(
          carouselData.map(async (data) => {
            const { imageFile, videoFile, name, url, description } = data;
            const newCarouselData: CarouselData = {
              name,
              description,
              url,
            };

            if (imageFile) {
              const imageUrl = await ImageApi.singleUpload(imageFile, accounts[0].id);
              newCarouselData.image = imageUrl;
            }

            if (videoFile) {
              const videoUrl = await ImageApi.singleUpload(videoFile, accounts[0].id);
              newCarouselData.video = videoUrl;
            }

            return newCarouselData;
          }),
        );
        setShowProgressModal(false);
      } else {
        setShowProgressModal(true);
        updatedPostsDataAssets = await Promise.all(
          postsData.map(async (data) => {
            const { imageFile, videoFile } = data;
            const newPostsData: PostData = {};

            if (imageFile) {
              const imageUrl = await ImageApi.singleUpload(imageFile, accounts[0].id);
              newPostsData.image = imageUrl;
            }

            if (videoFile) {
              const videoUrl = await ImageApi.singleUpload(videoFile, accounts[0].id);
              newPostsData.video = videoUrl;
            }

            return newPostsData;
          }),
        );
        setShowProgressModal(false);
        if (postsData.length > 1) {
          const body: {
            images_urls: string[];
          } = {
            images_urls: [],
          };
          updatedPostsDataAssets.forEach((post) => {
            if (post.image) {
              body.images_urls.push(post.image);
            }
          });
          slideShowId = await CampaignApi.createSlideShow(accounts[0].id, body);
        }
      }
      const body: AdsCreativeData = {
        // adAccountId: '0da7b9f0-b1f3-465a-81d9-69d78f709e03',
        name: form.getFieldValue("adsName"),
        object_type: "SHARE",
        ...(category === GOALS.INTERACTIONS &&
          currentConversion === CONVERSIONS.MESSENGER &&
          isSponsored && {
            messenger_sponsored_message: {
              message: {
                text: welcomeMessage,
                attachement: {
                  type: "template",
                  payload: {
                    template_type: "generic",
                  },
                },
              },
            },
          }),
        object_story_spec: {
          page_id: remoteId,
          ...(instagramSiteOption && { instagram_actor_id: instagramSiteOption }),
          ...(carouselUpload &&
            updatedCarousalAssets && {
              link_data: {
                ...(form.getFieldValue("link")
                  ? { link: form.getFieldValue("link") }
                  : // need to provide an external default website (fb not accepting so providing for appleute for now)
                    { link: "https://appleute.de/" }),
                message: form.getFieldValue("adText"),
                call_to_action: {
                  type: form.getFieldValue("telefon") ? "CALL_NOW" : callToActionOption,
                  value: {
                    ...(countryCode &&
                      form.getFieldValue("telefon") && {
                        link: `tel:${countryCode}${form.getFieldValue("telefon")}`,
                      }),
                    ...((!countryCode || !form.getFieldValue("telefon")) && {
                      link: form.getFieldValue("link"),
                    }),
                    ...(form.getFieldValue("linkCaption") &&
                      (currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && {
                        link_caption: form.getFieldValue("linkCaption"),
                      }),
                    ...((CONVERSIONS.LEAD_FORM_MESSENGER || CONVERSIONS.ON_AD) &&
                      leadFormId && { lead_gen_form_id: leadFormId }),
                    ...(category === GOALS.INTERACTIONS &&
                      currentConversion === CONVERSIONS.MESSENGER && {
                        app_destination: "MESSENGER",
                      }),
                  },
                },
                child_attachments: updatedCarousalAssets.map((item) => ({
                  description: item.description,
                  picture: item.image,
                  video_id: item.video,
                  link: item.url,
                  name: item.name,
                  call_to_action: {
                    type: form.getFieldValue("telefon") ? "CALL_NOW" : callToActionOption,
                    value: {
                      ...(countryCode &&
                        form.getFieldValue("telefon") && {
                          link: `tel:${countryCode}${form.getFieldValue("telefon")}`,
                        }),
                      ...((!countryCode || !form.getFieldValue("telefon")) && {
                        link: form.getFieldValue("link"),
                      }),
                      ...(form.getFieldValue("linkCaption") &&
                        (currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && {
                          link_caption: form.getFieldValue("linkCaption"),
                        }),
                      ...((CONVERSIONS.LEAD_FORM_MESSENGER || CONVERSIONS.ON_AD) &&
                        leadFormId && { lead_gen_form_id: leadFormId }),
                      ...(category === GOALS.INTERACTIONS &&
                        currentConversion === CONVERSIONS.MESSENGER && {
                          app_destination: "MESSENGER",
                        }),
                    },
                  },
                })),
                ...(category === GOALS.INTERACTIONS &&
                  currentConversion === CONVERSIONS.MESSENGER &&
                  !isSponsored && {
                    page_welcome_message: {
                      customer_action_type: callToActionOption,
                      message: {
                        text: welcomeMessage,
                      },
                    },
                  }),
              },
            }),
          ...(!carouselUpload &&
            updatedPostsDataAssets &&
            postsData &&
            postsData[0].imageFile?.type === "image/jpeg" &&
            postsData[0].videoFile?.type !== "video/mp4" &&
            !slideShowId && {
              link_data: {
                name: form.getFieldValue("headline"),
                link: form.getFieldValue("link") || "https://appleute.de/",
                message: form.getFieldValue("adText"),
                description: form.getFieldValue("description"),
                picture: updatedPostsDataAssets[0].image,
                call_to_action: {
                  type: form.getFieldValue("telefon") ? "CALL_NOW" : callToActionOption,
                  value: {
                    ...(countryCode &&
                      form.getFieldValue("telefon") && {
                        link: `tel:${countryCode}${form.getFieldValue("telefon")}`,
                      }),
                    ...((!countryCode || !form.getFieldValue("telefon")) && {
                      link: form.getFieldValue("link"),
                    }),
                    ...((currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && {
                      link_caption: form.getFieldValue("linkCaption"),
                    }),
                    ...((CONVERSIONS.LEAD_FORM_MESSENGER || CONVERSIONS.ON_AD) &&
                      leadFormId && { lead_gen_form_id: leadFormId }),
                  },
                },
                ...(category === GOALS.INTERACTIONS &&
                  currentConversion === CONVERSIONS.MESSENGER &&
                  !isSponsored && {
                    page_welcome_message: {
                      customer_action_type: callToActionOption,
                      message: {
                        text: welcomeMessage,
                      },
                    },
                  }),
              },
            }),
          ...(!carouselUpload &&
            updatedPostsDataAssets &&
            postsData &&
            postsData[0].videoFile?.type === "video/mp4" && {
              video_data: {
                video_id: updatedPostsDataAssets[0].video,
                message: form.getFieldValue("adText"),
                image_url: updatedPostsDataAssets[0].image,
                call_to_action: {
                  type: form.getFieldValue("telefon") ? "CALL_NOW" : callToActionOption,
                  value: {
                    ...(countryCode &&
                      form.getFieldValue("telefon") && {
                        link: `${countryCode}${form.getFieldValue("telefon")}`,
                      }),
                    ...((!countryCode || !form.getFieldValue("telefon")) && {
                      link: form.getFieldValue("link") || "https://appleute.de/",
                    }),
                    ...((currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && {
                      link_caption: form.getFieldValue("linkCaption"),
                    }),
                    ...((CONVERSIONS.LEAD_FORM_MESSENGER || CONVERSIONS.ON_AD) &&
                      leadFormId && { lead_gen_form_id: leadFormId }),
                  },
                },
                ...(category === GOALS.INTERACTIONS &&
                  currentConversion === CONVERSIONS.MESSENGER &&
                  !isSponsored && {
                    page_welcome_message: {
                      customer_action_type: callToActionOption,
                      message: {
                        text: welcomeMessage,
                      },
                    },
                  }),
              },
            }),
          ...(!carouselUpload &&
            updatedPostsDataAssets &&
            slideShowId && {
              video_data: {
                video_id: slideShowId,
                message: form.getFieldValue("adText"),
                image_url: updatedPostsDataAssets[0].image,
                call_to_action: {
                  type: form.getFieldValue("telefon") ? "CALL_NOW" : callToActionOption,
                  value: {
                    ...(countryCode &&
                      form.getFieldValue("telefon") && {
                        link: `${countryCode}${form.getFieldValue("telefon")}`,
                      }),
                    ...((!countryCode || !form.getFieldValue("telefon")) && {
                      link: form.getFieldValue("link") || "https://appleute.de/",
                    }),
                    ...((currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && {
                      link_caption: form.getFieldValue("linkCaption"),
                    }),
                    ...((CONVERSIONS.LEAD_FORM_MESSENGER || CONVERSIONS.ON_AD) &&
                      leadFormId && { lead_gen_form_id: leadFormId }),
                  },
                },
                ...(category === GOALS.INTERACTIONS &&
                  currentConversion === CONVERSIONS.MESSENGER &&
                  !isSponsored && {
                    page_welcome_message: {
                      customer_action_type: callToActionOption,
                      message: {
                        text: welcomeMessage,
                      },
                    },
                  }),
              },
            }),
        },
      };
      return CampaignApi.createAdCreatives(accounts[0].id, body);
    },
    {
      onSuccess: (data) => {
        setCreativeId(data.id);
        message.success("Anzeigenmotiv erstellt");
        createAdsMutate(data.id);
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const handleFacebookSiteOptionChange = (value: SelectValue) => {
    if (typeof value === "string") setFacebookSiteOption(value);
  };

  const handleInstagramSiteOptionChange = (value: SelectValue) => {
    if (typeof value === "string") setInstagramSiteOption(value);
  };

  const CallToActionOptionChange = (value: SelectValue) => {
    if (typeof value === "string") setCallToActionOption(value);
  };

  const getCallToAction = () => {
    const filteredOptions = category ? callToActionTypesBasedonConversion[category][currentConversion] : [];

    if (filteredOptions && filteredOptions.length > 0) {
      setCallToActionOption(filteredOptions[0].value);
    }
  };

  useEffect(() => {
    try {
      if (carouselData.length > 0 || postsData.length > 0) {
        validateFields(carouselUpload, postsData, carouselData);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  }, [carouselData.length, carouselUpload, postsData.length]);

  useEffect(() => {
    if (currentConversion && category) {
      getCallToAction();
    }
    // eslint-disable-next-line
  }, [currentConversion, category]);

  useEffect(() => {
    if (localStorage.getItem("adsName")) {
      form.setFieldValue("adsName", localStorage.getItem("adsName") || "");
      localStorage.removeItem("adsName");
    }
    form.setFieldValue("adsName", "Neue Werbeanzeige");
    if (localStorage.getItem("isSponsored")) {
      setIsSponsored(localStorage.getItem("isSponsored") === "true");
      localStorage.removeItem("isSponsored");
    }
    if (params.adId) {
      getCampaignFetch();
      getAdsetFetch();
      getAdDataFetch();
      getAdsChannel();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (params.adId && creativeId) {
      getCreativeData();
    }
    // eslint-disable-next-line
  }, [creativeId]);

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <h1 className="heading" style={{ paddingBottom: 10 }}>
          Werbeanzeige erstellen
        </h1>
      </Row>
      <Modal width={150} open={showProgressModal} footer={null} closable={false} centered>
        <Spin size="large" tip="Dateien werden hochgeladen" style={{ color: "blue" }} />
      </Modal>
      <Spin spinning={isAdsChannelFetching || isAdsChannelLoading || isLoading}>
        <Form
          form={form}
          labelAlign="left"
          labelWrap
          colon={false}
          onFinish={() => {
            mutate();
          }}
          labelCol={{
            lg: { offset: 0, span: 6 },
            md: { offset: 0, span: 24 },
            sm: { offset: 0, span: 24 },
            xs: { offset: 0, span: 24 },
          }}
          wrapperCol={{
            lg: { span: 18, offset: 1 },
            md: { span: 24, offset: 0 },
            sm: { span: 24, offset: 0 },
            xs: { span: 24, offset: 0 },
          }}
        >
          <Col lg={14} md={24} sm={24} xs={24}>
            <div style={{ color: "black" }}>
              <h2 style={{ fontWeight: "bold" }}>Anzeige aufsetzen</h2>
              <Form.Item
                label="Anzeigenname"
                name="adsName"
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Anzeigenname ein",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue("adsName", e.target.value);
                  }}
                  className={classes.displayInput}
                />
              </Form.Item>
            </div>
            <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Konten</h2>
            <Form.Item label="Facebookseite" initialValue={facebookSiteOption}>
              <Select
                className={classes.categoryInput}
                value={facebookSiteOption}
                onChange={handleFacebookSiteOptionChange}
              >
                <Option value={facebookSiteOptions.value}>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{facebookSiteOptions.label}</h4>
                  </div>
                </Option>
              </Select>
            </Form.Item>
            {currentConversion === CONVERSIONS.ON_PAGE ? (
              ""
            ) : (
              <Form.Item label="Instagramseite" initialValue={instagramSiteOption}>
                <Select
                  className={classes.categoryInput}
                  value={instagramSiteOption}
                  onChange={handleInstagramSiteOptionChange}
                >
                  {instagramSiteOptions.value && (
                    <Option value={instagramSiteOptions.value}>
                      <div style={{ whiteSpace: "break-spaces" }}>
                        <h4>{instagramSiteOptions.label}</h4>
                      </div>
                    </Option>
                  )}
                  <Option value={instagramActorId}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>Ausgewählte Seite verwenden</h4>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            )}
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "black",
              }}
            >
              <h2 style={{ fontWeight: "bold" }}>Anzeigenkonfiguration</h2>
              <Radio style={{ display: "block" }} checked={!carouselUpload} onClick={() => setCarouselUpload(false)}>
                Einzelnes Bild oder Video
              </Radio>
              <span style={{ marginTop: 5, marginBottom: 5 }}>
                Einzelnes Bild oder Video, oder Slideshow mit mehreren Bildern.
              </span>
              <Radio style={{ display: "block" }} checked={carouselUpload} onClick={() => setCarouselUpload(true)}>
                Carousel
              </Radio>
              <span style={{ marginTop: 5 }}>2 oder mehr scrollbare Bilder oder Videos.</span>
            </div>
            <br />
            <h2 style={{ fontWeight: "bold" }}>Anzeigengestaltung</h2>
            <span style={{ marginTop: "10px" }}>
              Wähle die Medien, Texte und das Ziel für deine Anzeige aus. Du kannst deine Medien und Texte auch für jede
              Platzierung anpassen.
            </span>
            <PreviewForm
              form={form}
              carouselData={carouselData}
              setCarouselData={setCarouselData}
              postsData={postsData}
              setPostsData={setPostsData}
              carouselUpload={carouselUpload}
            />
            <br />
            <br />
            <Form.Item
              label="Anzeigentext"
              name="adText"
              initialValue={adText}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                value={adText}
                placeholder="Erkläre, worum es in deiner Anzeige geht"
                onChange={(e) => {
                  form.setFieldValue("adText", e.target.value);
                  setAdText(e.target.value);
                }}
                draggable={false}
                className={classes.textInput}
                rows={1}
              />
            </Form.Item>
            {currentConversion === CONVERSIONS.ON_PAGE || carouselUpload ? (
              " "
            ) : (
              <>
                <Form.Item
                  label="Überschrift"
                  name="headline"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Bitte geben Sie den Überschrift ein",
                    },
                  ]}
                >
                  <Input
                    className={classes.textInput}
                    placeholder="Verfasse eine kurze Überschrift"
                    onChange={(e) => {
                      form.setFieldValue("headline", e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Beschreibung"
                  name="description"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Bitte geben Sie den Beschreibung ein",
                    },
                  ]}
                >
                  <TextArea
                    // draggable={false}
                    placeholder="usätzliche Informationen hinzufügen"
                    className={classes.textInput}
                    rows={2}
                    onChange={(e) => {
                      form.setFieldValue("description", e.target.value);
                    }}
                  />
                </Form.Item>
              </>
            )}
            {currentConversion !== CONVERSIONS.ON_PAGE && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 10,
                  }}
                >
                  <span style={{ marginTop: 5, marginRight: 80 }}>Call-To-Action</span>
                  <Select
                    className={classes.textInput}
                    value={callToActionOption}
                    onChange={CallToActionOptionChange}
                    disabled={callToActionTypesBasedonConversion[category][currentConversion]?.length <= 1}
                  >
                    {callToActionTypesBasedonConversion[category][currentConversion]?.map((option, index) => (
                      <Option value={option.value}>
                        <div style={{ whiteSpace: "break-spaces" }}>
                          <h4>{option.label}</h4>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
                <CallToAction
                  form={form}
                  carouselUpload={carouselUpload}
                  category={category}
                  leadFormId={leadFormId}
                  setLeadFormId={setLeadFormId}
                  currentConversion={currentConversion}
                  welcomeMessage={welcomeMessage}
                  setWelcomeMessage={setWelcomeMessage}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                />
              </>
            )}
            <div
              style={{
                position: "absolute",
                right: 0,
                marginTop: 10,
                paddingBottom: 10,
              }}
            >
              <Button
                type="default"
                className={classes.createButton}
                onClick={() => {
                  if (params.adId) {
                    push("/user/ads/dashboard");
                  } else {
                    push(`/user/campaign/${category}/${params.id}/adset/`);
                  }
                }}
              >
                Zurück
              </Button>
              {/* <Button className={classes.createButton}>
                                Als Entwurf speichern
                            </Button> */}
              <Button className={classes.furtherButton} htmlType="submit" type="primary" loading={isLoading}>
                Weiter
              </Button>
            </div>
          </Col>
        </Form>
      </Spin>
    </div>
  );
}
