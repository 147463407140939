import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import { UseCustomQueryOptions } from "types";

export type TGetAccountSubscriptionParams = {
  accountId: string;
};

type TAccountSubscriptionResponse = Awaited<ReturnType<typeof AccountApi.getSubscription>>;

const useAccountSubscription = <TSelectData = TAccountSubscriptionResponse>(
  filters: TGetAccountSubscriptionParams,
  options?: UseCustomQueryOptions<TAccountSubscriptionResponse, TSelectData>,
) => {
  return useQuery({
    queryKey: AccountQueryKeys.getSubscription(filters),
    queryFn: async () => {
      return AccountApi.getSubscription(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: (options?.enabled ?? true) && !!filters.accountId,
  });
};

export default useAccountSubscription;
