import { Button, Col, Modal, Row, Select, Spin, Typography, UploadFile, message } from "antd";
import { useContext, useMemo, useState } from "react";
import { Channel } from "types";
import Uploader from "components/Uploader";
import { DownloadOutlined } from "@ant-design/icons";
import useSelectedAccount from "hooks/useSelectedAccount";
import useCompany from "hooks/company/useCompany";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import NotAllowed from "components/NotAllowed";
import { makeSubject } from "utils/access";
import FacebookIcon from "../../../../assets/icons/socials/facebook.svg";
import InstagramIcon from "../../../../assets/icons/socials/instagram.svg";
import classes from "./ScheduleModal.module.css";

const TypeZip1 = "application/zip";
const TypeZip2 = "application/x-zip-compressed";
const TypeCsv = "text/csv";

const AccountLabelView = ({ channel }: { channel: Channel }) => {
  return (
    <Row align="middle">
      {channel.type === "FACEBOOK" ? (
        <img src={FacebookIcon} alt="facebook" style={{ marginRight: 7, height: 18 }} />
      ) : (
        <img src={InstagramIcon} alt="instagram" style={{ marginRight: 7, height: 18 }} />
      )}{" "}
      <div
        style={{
          width: "70%",
          whiteSpace: "nowrap",
        }}
      >
        {channel.name}
      </div>
    </Row>
  );
};

export type ScheduleModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  onOkClick: (zipFile: UploadFile, csvFile: UploadFile, selectedChannels: string[], accountId: string) => void;
  isZipProcessing: boolean;
};

const ScheduleModal = (props: ScheduleModalProps) => {
  const { isModalOpen, onModalClose, onOkClick, isZipProcessing } = props;
  const [zipFile, setZipFile] = useState<UploadFile>();
  const [csvFile, setCsvFile] = useState<UploadFile>();
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedCompanyId: companyId, selectedAccountId: accountId } = useSelectedAccount();

  const { ability } = useContext(UserAccessPolicyContext);
  const canCreatePosts = ability.can("create", "Post");

  const canManagePosts = ability.can("update", "Post");

  const { data } = useCompany(companyId, {
    include: canManagePosts ? ["channels"] : [],
  });

  const channels: Channel[] = useMemo(() => {
    if (!data?.channels) return [];

    return data.channels.filter((channel) =>
      ability.can(
        "update",
        makeSubject("Post", {
          accountId,
          companyId,
          channelId: channel.id,
        }),
      ),
    );
  }, [accountId, companyId, data, ability]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={onModalClose}
      footer={[
        canCreatePosts ? (
          <Button
            className={classes.modalBtn}
            type="primary"
            disabled={isZipProcessing}
            onClick={() => {
              if (!zipFile) {
                message.error("Du musst eine ZIP-Datei hinzufügen, bevor du fortfahren kannst.");
              } else if (zipFile.type !== TypeZip1 && zipFile.type !== TypeZip2) {
                message.error(`Bitte ersetze das "${zipFile.name}" durch eine ZIP-Datei, um fortzufahren.`);
              } else if (!csvFile) {
                message.error("Du musst eine CSV-Datei hinzufügen, bevor du weitermachen kannst.");
              } else if (csvFile.type !== TypeCsv) {
                message.error(`Bitte ersetze das "${csvFile.name}" durch eine CSV-Datei, um fortzufahren.`);
              } else if (selectedChannels.length <= 0) {
                message.error("Bitte wählen Sie eine Plattform, bevor Sie fortfahren!");
              } else {
                onOkClick(zipFile, csvFile, selectedChannels, accountId);
                setLoading(true);
              }
            }}
          >
            Hochladen
          </Button>
        ) : undefined,
      ]}
    >
      <Typography.Title level={3}>Sammelimport</Typography.Title>
      {!canCreatePosts ? (
        <NotAllowed />
      ) : (
        <>
          {isZipProcessing && (
            <div>
              <p>Eine frühere Anfrage wird verarbeitet. Bitte überprüfen Sie dies nach einiger Zeit.</p>
            </div>
          )}
          {!isZipProcessing && (
            <Spin spinning={loading}>
              <div>
                <p className={classes.sampleBtnTitle}>Deine Beispieldateien (beide werden benötigt)</p>
                <Col className={classes.downloadContainer}>
                  <Row className={classes.sampleBtnRow}>
                    <Button
                      size="small"
                      onClick={() => window.open(process.env.REACT_APP_SCHEDULE_EXAMPLE_CSV, "_blank")}
                    >
                      <DownloadOutlined />
                      Beispiel.csv
                    </Button>
                    <div style={{ width: "8px" }} />
                    <Button
                      size="small"
                      onClick={() => window.open(process.env.REACT_APP_SCHEDULE_EXAMPLE_ZIP, "_blank")}
                    >
                      <DownloadOutlined />
                      Beispiel.zip
                    </Button>
                  </Row>
                </Col>
                <Uploader
                  title="Bilder-ZIP"
                  accept={`${TypeZip1},${TypeZip2}`}
                  multiple={false}
                  beforeUpload={() => false}
                  onFilesSelected={(files): void => {
                    setZipFile(files[0]);
                  }}
                  content={
                    !zipFile ? (
                      <div>
                        <p>Bilder-ZIP per Drag&Drop hineinziehen</p>
                        <p>oder</p>
                        <p>Durchsuchen</p>
                      </div>
                    ) : (
                      <div>
                        <p>Klicken, um eine andere ZIP-Datei auszuwählen</p>
                      </div>
                    )
                  }
                />
                <Uploader
                  title="Beitrags-CSV"
                  accept={TypeCsv}
                  multiple={false}
                  beforeUpload={() => false}
                  onFilesSelected={(files): void => {
                    setCsvFile(files[0]);
                  }}
                  content={
                    !csvFile ? (
                      <div>
                        <p>Beitrags-CSV per Drag&Drop hineinziehen</p>
                        <p>oder</p>
                        <p>Durchsuchen</p>
                      </div>
                    ) : (
                      <div>
                        <p>Klicken, um eine andere CSV-Datei auszuwählen</p>
                      </div>
                    )
                  }
                />
                <p>Für welche Konten sollen diese Beiträge geplant werden?</p>
                <Select
                  placeholder="Konten wählen"
                  mode="multiple"
                  allowClear
                  value={selectedChannels}
                  onChange={setSelectedChannels}
                  options={channels?.map((channel) => {
                    return {
                      key: `${channel.id}`,
                      label: <AccountLabelView channel={channel} />,
                      value: `${channel.id}_${channel.type}`,
                    };
                  })}
                />
              </div>
            </Spin>
          )}
        </>
      )}
    </Modal>
  );
};

export default ScheduleModal;
