import { DeleteOutlined, EllipsisOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Popconfirm, Row, Spin, Table, TableProps, message } from "antd";
import { useContext, useState } from "react";
import { User } from "types";
import useQueryCacheData from "hooks/useQueryCacheData";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import dayjs from "dayjs";
import { convertUTCDateToLocal } from "utils/date";
import useSelectedAccount from "hooks/useSelectedAccount";
import PermissionsModal from "components/Permissions";
import { AxiosError } from "axios";
import useUpdateCompanyUsers from "hooks/company/useUpdateCompanyUsers";
import useUserAccessPolicy from "hooks/UserAccessPolicy/useUserAccessPolicy";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";
import NoDataLocaleTable from "components/NoData";
import AddUserModal from "../../components/AddUserModal";
import classes from "./index.module.css";

const CompanyUsers = ({ companyId, users, isLoading }: { companyId: string; users: User[]; isLoading: boolean }) => {
  const { t } = useTranslation(["common", "pages"]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { ability } = useContext(UserAccessPolicyContext);
  const { selectedAccountId: accountId } = useSelectedAccount();

  const canViewUserAccessPolicy = ability.can("read", makeSubject("UserAccessPolicy", { accountId, companyId }));
  const canUpdateCompanyUsers = ability.can("update", makeSubject("Company", { accountId, companyId }), "userIds");

  const me = useQueryCacheData<User>("me");

  const { data: selectedUserAccessPolicy, isLoading: isLoadingAccessPolicies } = useUserAccessPolicy(
    {
      accountId,
      userId: selectedUser?.id,
      companyId,
    },
    {
      onSuccess: () => {
        setShowPermissionModal(true);
      },
    },
  );

  const { mutate: updateCompanyUsers, isLoading: isUpdatingUsers } = useUpdateCompanyUsers({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      setSelectedRows([]);
      message.success(t("pages:user.removeFromCompanySuccessMessage"));
    },
  });

  const columns: TableProps<User>["columns"] = [
    {
      title: t("pages:user.title"),
      dataIndex: "user",
      key: "user",
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render(_, record) {
        return (
          <Row align="middle" justify="space-between">
            <span className={classes.UserName}>{record.name}</span>
            {canViewUserAccessPolicy || record.id === me.id ? (
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <span
                          onClick={() => {
                            setSelectedUser(record);
                          }}
                        >
                          {t("labels.managePermissions")}
                        </span>
                      ),
                      key: "manage-permissions",
                    },
                  ],
                }}
                placement="bottom"
              >
                <span>
                  <EllipsisOutlined data-testid="user-three-dots" />
                </span>
              </Dropdown>
            ) : null}
          </Row>
        );
      },
      width: 220,
    },
    {
      title: t("pages:user.emailLabel"),
      dataIndex: "email",
      key: "email",
      width: 220,
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render(_, record) {
        return <span className={classes.UserName}>{record.email}</span>;
      },
    },
    {
      title: t("labels.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter(a, b) {
        return dayjs.utc(a.createdAt).isAfter(b.createdAt) ? 1 : -1;
      },
      render(_, record) {
        return (
          <Row align="middle">
            <span className={classes.UserName}>
              {convertUTCDateToLocal(record.createdAt).format("DD.MM.YYYY HH.mm[h]")}
            </span>
          </Row>
        );
      },
      width: 220,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Table
          title={() => (
            <div className={classes.userTableHeader}>
              <h2 className={classes.subHeading}>{t("pages:user.title")}</h2>
              {canUpdateCompanyUsers ? (
                !selectedRows.length ? (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="middle"
                    onClick={() => setShowAddUserModal(true)}
                  >
                    {t("pages:user.addButton")}
                  </Button>
                ) : (
                  <Popconfirm
                    title={t("pages:user.removeFromCompanyConfirmTitle")}
                    description={t("pages:user.removeFromCompanyConfirmDescription")}
                    cancelText={t("buttons.cancel")}
                    okText={t("buttons.remove")}
                    placement="topLeft"
                    icon={<QuestionCircleOutlined style={{ color: "var(--btn-primary-bg)", margin: "4px" }} />}
                    onConfirm={() => updateCompanyUsers({ companyId, userIds: selectedRows, action: "remove" })}
                  >
                    <Button type="default" icon={<DeleteOutlined />} size="middle">
                      {t("pages:user.removeButton")}
                    </Button>
                  </Popconfirm>
                )
              ) : null}
            </div>
          )}
          loading={isUpdatingUsers}
          dataSource={users ?? []}
          locale={NoDataLocaleTable}
          columns={columns}
          className={classes.ProfileTable}
          rowKey="id"
          rowSelection={
            canUpdateCompanyUsers
              ? {
                  selectedRowKeys: selectedRows,
                  onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys as string[]),
                }
              : undefined
          }
          pagination={{
            position: ["bottomCenter"],
          }}
        />
      )}
      {selectedUser ? (
        <PermissionsModal
          selectedUser={selectedUser}
          accessPolicies={selectedUserAccessPolicy?.items ?? []}
          isLoadingAccessPolicies={isLoadingAccessPolicies}
          initialCompanyId={companyId}
          selectCompanyDisabled
          open={showPermissionModal}
          onCancel={() => {
            setSelectedUser(null);
            setShowPermissionModal(false);
          }}
          viewOnly={selectedUser.id === me?.id}
        />
      ) : null}
      <AddUserModal
        visible={showAddUserModal}
        setVisible={setShowAddUserModal}
        companyId={companyId}
        userIdsToExclude={users?.flatMap((user) => user.id) ?? []}
      />
    </>
  );
};

export default CompanyUsers;
