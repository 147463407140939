import { FileSyncOutlined } from "@ant-design/icons";
import { Button } from "antd";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import { Link, useNavigate } from "@pankod/refine-react-router-v6";
import Logout from "components/Logout";
import EPLLogo from "../../assets/EPL Black Text Logo.svg";
import classes from "./InactiveSubscription.module.css";

const InactiveSubscription = () => {
  const { t } = useTranslation(["pages"]);
  const navigate = useNavigate();

  const { selectedAccountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);

  const canManageSubcription = ability.can(
    "manage",
    makeSubject("AccountSubscription", { accountId: selectedAccountId }),
  );

  return (
    <>
      <Logout style={{ position: "fixed", top: 15, right: 15 }} />
      <div className={classes.mainContainer} style={{ flex: 1 }}>
        <Link className={classes.headerLogo} to="/">
          <img src={EPLLogo} alt="EasyPostLab Logo" />
        </Link>
        <h3 className={classes.notFoundTitle}>
          {canManageSubcription
            ? t("pages:inactiveSubscription.titleForAdminUser")
            : t("pages:inactiveSubscription.titleForNormalUser")}
        </h3>
        <h4 className={classes.notFountSubTitle}>{t("pages:inactiveSubscription.seekSupportSubtitle")}</h4>
        {canManageSubcription ? (
          <Button
            type="primary"
            icon={<FileSyncOutlined />}
            className={classes.viewPermissionBtn}
            onClick={() => {
              navigate("/onboarding?step=2", { replace: true });
            }}
          >
            {t("pages:inactiveSubscription.renewButton")}
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default InactiveSubscription;
