import { Button, Col, Row, message } from "antd";
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { auth, googleProvider } from "config/firebase";
import { SigninProvider } from "authProvider";
import { ReactComponent as GoogleGIcon } from "../../assets/icons/socials/logo_google_g_icon.svg";
import { ReactComponent as MicrosoftIcon } from "../../assets/icons/socials/microsoft_logo_social_social media_icon.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/socials/apple_icon.svg";
import classes from "./sso.module.css";

export default function SingleSignOn({
  mutate,
  disabled = false,
}: {
  mutate: ({ access_token, signinProvider }: { access_token: string; signinProvider: SigninProvider }) => void;
  disabled?: boolean;
}) {
  const googleSignin = async () => {
    googleProvider.setCustomParameters({
      // Localize the Google authentication screen in French.
      locale: "de",
    });
    const response = signInWithPopup(auth, googleProvider)
      .then(async (result: any) => {
        const access_token = result.user.accessToken;
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        await mutate({
          access_token: idTokenResult ? idTokenResult.token : access_token,
          signinProvider: SigninProvider.GOOGLE,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "auth/account-exists-with-different-credential") {
          message.error(`
Du hast versucht, dich mit einer anderen Methode anzumelden als der, die du normalerweise benutzt. Versuch's bitte nochmal mit deiner üblichen Methode.`);
        }
        return false;
      });
    return response;
  };

  const microsoftSignin = async () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      // Localize the Microsoft authentication screen in French.
      locale: "de",
    });
    const response = signInWithPopup(auth, provider)
      .then(async (result: any) => {
        const access_token = result.user.accessToken;
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        await mutate({
          access_token: idTokenResult ? idTokenResult.token : access_token,
          signinProvider: SigninProvider.MICROSOFT,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "auth/account-exists-with-different-credential") {
          message.error(`
Du hast versucht, dich mit einer anderen Methode anzumelden als der, die du normalerweise benutzt. Versuch's bitte nochmal mit deiner üblichen Methode.`);
        }
        return false;
      });
    return response;
  };

  const appleSignin = async () => {
    const provider = new OAuthProvider("apple.com");
    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: "de",
    });
    const response = signInWithPopup(auth, provider)
      .then(async (result: any) => {
        const access_token = result.user.accessToken;
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        await mutate({
          access_token: idTokenResult ? idTokenResult.token : access_token,
          signinProvider: SigninProvider.APPLE,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.code === "auth/account-exists-with-different-credential") {
          message.error(`
Du hast versucht, dich mit einer anderen Methode anzumelden als der, die du normalerweise benutzt. Versuch's bitte nochmal mit deiner üblichen Methode.`);
        }
        return false;
      });
    return response;
  };

  return (
    <Row gutter={12} justify="space-between" align="middle">
      <Col span={8}>
        <Button
          type="text"
          icon={<GoogleGIcon />}
          className={classes.ssoButton}
          onClick={googleSignin}
          disabled={disabled}
        >
          Google
        </Button>
      </Col>
      <Col span={8}>
        <Button
          type="text"
          icon={<AppleIcon />}
          className={classes.ssoButton}
          onClick={appleSignin}
          disabled={disabled}
        >
          Apple
        </Button>
      </Col>
      <Col span={8}>
        <Button
          type="text"
          icon={<MicrosoftIcon />}
          className={classes.ssoButton}
          onClick={microsoftSignin}
          disabled={disabled}
        >
          Microsoft
        </Button>
      </Col>
    </Row>
  );
}
