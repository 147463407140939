import PageHeading from "components/PageHeading";
import { Card, Select, Spin } from "antd";
import ChartStat from "pages/ChartStat";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import useSelectedAccount from "hooks/useSelectedAccount";
import useInsights, { GroupedInsights, groupInsightsByMonth } from "hooks/Insight/useInsights";
import { FacebookMetrics, InsightDatePreset, InstagramMetrics, MetricPeriod } from "types";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { ReactComponent as FacebookGray } from "../../../assets/FacebookGray.svg";
import { ReactComponent as InstagramGray } from "../../../assets/InstagramGray.svg";
import StatChartCard, { createStatChartCardOptions } from "../components/StatChartCard";
import classes from "./index.module.css";

const AdTrendsCard = ({
  loading,
  stats,
  datePreset,
}: {
  loading: boolean;
  stats?: GroupedInsights;
  datePreset: InsightDatePreset;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const reachData = useMemo(() => {
    const categories: string[] = [];
    const paidReach: number[] = [];
    const organicReach: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      paidReach.push(
        stats?.facebook.page_impressions_paid_unique?.data.find((item) => item.month === month)?.value ?? 0,
      );
      organicReach.push(stats?.facebook.page_impressions_unique?.data.find((item) => item.month === month)?.value ?? 0);
    }
    return {
      chartData: {
        chart: {
          type: "line",
          id: "line-chart",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 3,
        },

        series: [
          {
            name: t("pages:statsReach.paidReachLabel"),
            data: paidReach,
          },
          {
            name: t("pages:statsReach.organicReachLabel"),
            data: organicReach,
          },
        ],
        xaxis: {
          categories,
        },
        yaxis: {
          title: {
            text: t("pages:dashboard.reachLabel"),
            style: { fontWeight: 500, fontFamily: "Montserrat", fontSize: "12px", color: "hsla(220, 13%, 46%, 1)" },
          },
        },
        legend: {
          show: true,
          showForNullSeries: true,
          showForSingleSeries: true,
          showForZeroSeries: true,
          fontSize: "14px",
          fontWeight: 400,
        },
      } satisfies ApexOptions,
      totalPaidReach: stats?.facebook.page_impressions_paid_unique?.total ?? 0,
      totalOrganicReach: stats?.facebook.page_impressions_unique?.total ?? 0,
    };
  }, [t, stats, datePreset]);

  return (
    <Card className={classes.adChartCard}>
      <Spin spinning={loading}>
        <div className={classes.adChartCardWrapper}>
          <div className={classes.adChartCardHeader}>
            <strong>{t("pages:statsReach.adTrendsTitle")}</strong>
            <div className={classes.adChartCardStats}>
              <ChartStat icon={null} text={t("pages:statsReach.paidReachLabel")} value={reachData.totalPaidReach} />
              <ChartStat
                icon={null}
                text={t("pages:statsReach.organicReachLabel")}
                value={reachData.totalOrganicReach}
              />
            </div>
          </div>
          <div className={classes.adChartCardContent}>
            <Chart
              series={reachData.chartData.series}
              options={reachData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </Spin>
    </Card>
  );
};

const currYear = dayjs().year();
const lastYear = currYear - 1;

const StatsReach = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();
  const [datePreset, setDatePreset] = useState(InsightDatePreset.this_year);

  const { data, isLoading: isLoadingInsights } = useInsights({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [
      FacebookMetrics.page_follows,
      FacebookMetrics.page_impressions_unique,
      FacebookMetrics.page_impressions_paid_unique,
      FacebookMetrics.page_views_total,
      FacebookMetrics.page_follows,
    ],
    instagram_metrics: [InstagramMetrics.follower_count, InstagramMetrics.reach, InstagramMetrics.profile_views],
    date_preset: datePreset,
    period: MetricPeriod.day,
  });
  const stats = useMemo(() => (data ? groupInsightsByMonth(data) : undefined), [data]);

  const fbReachData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.facebook.page_impressions_unique?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "blue",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.fbReachLabel"),
        yaxisText: t("pages:dashboard.reachLabel"),
      }),
      total: stats?.facebook.page_impressions_unique?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  const igReachData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.instagram.reach?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "red",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.igReachLabel"),
        yaxisText: t("pages:dashboard.reachLabel"),
      }),
      total: stats?.instagram.reach?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  const fbPageViewsData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.facebook.page_views_total?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "blue",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.fbPageViewsLabel"),
        yaxisText: t("pages:statsReach.pageViewsLabel"),
      }),
      total: stats?.facebook.page_views_total?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  const igProfileViewsData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.instagram.profile_views?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "red",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.igProfileVisitsLabel"),
        yaxisText: t("pages:statsReach.profileVisitsLabel"),
      }),
      total: stats?.instagram.profile_views?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  const fbNewFollowsData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.facebook.page_follows?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "blue",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.newFbFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.facebook.page_follows?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  const igNewFollowersData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.instagram.follower_count?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "red",
        categories,
        data: monthlyData,
        name: t("pages:statsReach.newIgFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.instagram.follower_count?.total ?? 0,
    };
  }, [stats, t, datePreset]);

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageHeading>
          <PageHeading.Title className={classes.containerTitle}>{t("pages:statsReach.title")}</PageHeading.Title>
          <PageHeading.Subtitle className={classes.containerSubtitle}>
            {t("pages:statsReach.subtitle")}
          </PageHeading.Subtitle>
        </PageHeading>
        <Select
          className={classes.datePresetSelect}
          value={datePreset}
          onChange={setDatePreset}
          defaultValue={InsightDatePreset.this_year}
          options={[
            {
              label: `${t("common:labels.currYear")} (${currYear})`,
              value: InsightDatePreset.this_year,
            },
            {
              label: `${t("common:labels.lastYear")} (${lastYear})`,
              value: InsightDatePreset.last_year,
            },
          ]}
        />
      </div>
      <div className={classes.pageContent}>
        <section className={classes.cardSection}>
          <StatChartCard
            icon={<FacebookGray />}
            title={t("pages:statsReach.fbReachLabel")}
            stat={fbReachData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={fbReachData.chartData.series}
              options={fbReachData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
          <StatChartCard
            icon={<InstagramGray />}
            title={t("pages:statsReach.igReachLabel")}
            stat={igReachData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={igReachData.chartData.series}
              options={igReachData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
        </section>
        <section className={classes.cardSection}>
          <StatChartCard
            icon={<FacebookGray />}
            title={t("pages:statsReach.fbPageViewsLabel")}
            stat={fbPageViewsData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={fbPageViewsData.chartData.series}
              options={fbPageViewsData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
          <StatChartCard
            icon={<InstagramGray />}
            title={t("pages:statsReach.igProfileVisitsLabel")}
            stat={igProfileViewsData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={igProfileViewsData.chartData.series}
              options={igProfileViewsData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
        </section>
        <section className={classes.cardSection}>
          <StatChartCard
            icon={<FacebookGray />}
            title={t("pages:statsReach.newFbFollowersLabel")}
            stat={fbNewFollowsData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={fbNewFollowsData.chartData.series}
              options={fbNewFollowsData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
          <StatChartCard
            icon={<InstagramGray />}
            title={t("pages:statsReach.newIgFollowersLabel")}
            stat={igNewFollowersData.total}
            loading={isLoadingInsights}
          >
            <Chart
              series={igNewFollowersData.chartData.series}
              options={igNewFollowersData.chartData}
              type="line"
              width="100%"
              height="100%"
            />
          </StatChartCard>
        </section>
        <section className={classes.cardSection}>
          <AdTrendsCard stats={stats} loading={isLoadingInsights} datePreset={datePreset} />
        </section>
      </div>
    </div>
  );
};

export default StatsReach;
