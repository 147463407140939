import useMe from "hooks/useMe";
import { CSSProperties, useState } from "react";
import LogoutModal from "pages/LogoutModal";
import CustomAvatar from "components/CustomAvatar";
import LogoutIcon from "../../assets/Logout.svg";
import classes from "./Logout.module.css";

const Logout = ({ style }: { style?: CSSProperties | undefined }) => {
  const { data: me } = useMe();
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  const handleLogoutModal = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };
  return (
    <>
      <div className={classes.logoutContainer} style={style} onClick={() => setIsLogoutVisible(true)}>
        <CustomAvatar src={me?.user_profile_pic} alt={`${me?.name ?? "User"}'s profile picture`}>
          {me?.name?.[0] ?? "U"}
        </CustomAvatar>
        <div className={classes.userDetails}>
          <h5 className={classes.username}>{me?.name}</h5>
          <h5 className={classes.email}>{me?.email}</h5>
        </div>
        <img src={LogoutIcon} alt="Logout" className={classes.navIcon} />
      </div>
      {isLogoutVisible && <LogoutModal visible={isLogoutVisible} handleCloseModal={handleLogoutModal} />}
    </>
  );
};

export default Logout;
