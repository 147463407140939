export enum ROLES {
  KUNDE = "CLIENT",
  MITGLIED = "MEMBER",
  EIGENTÜMER = "OWNER",
}

export enum AVAILABLE_ROLES {
  KUNDE = "CLIENT",
  MITGLIED = "MEMBER",
}

export enum GOALS {
  NOTORIETY = "bekanntheit",
  TRAFFIC = "traffic",
  INTERACTIONS = "interaktionen",
  LEADS = "leads",
  SALES = "umsatz",
}

export enum CONVERSIONS {
  WEBSITE = "WEBSITE",
  MESSENGER = "MESSENGER",
  PHONE_CALL = "PHONE_CALL",
  UNDEFINED = "UNDEFINED",
  ON_AD = "ON_AD",
  ON_PAGE = "ON_PAGE",
  ON_VIDEO = "ON_VIDEO",
  LEAD_FORM_MESSENGER = "LEAD_FORM_MESSENGER",
}

export enum TODO_STATUS {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
}

export enum TODO_PRIORITY {
  LOW = "LOW",
  NORMAL = "NORMAL",
  HIGH = "HIGH",
  URGENT = "URGENT",
}

export enum PLATFORMS {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
}

export enum POST_EDITING_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum SYSTEM_STATUS {
  IN_QUEUE = "IN_QUEUE",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
}

export enum DASHBOARD_TABS {
  CAMPAIGN = "campaign",
  ADSET = "adset",
  ADS = "ads",
}

export enum DASHBOARD_LABELS {
  CAMPAIGN = "Kampagnen",
  ADSET = "Anzeigengruppen",
  ADS = "Werbeanzeigen",
}

export default ROLES;
