import { MediaTypes } from "constants/editor";
import { Button, Modal, UploadFile } from "antd";
import { getBase64 } from "utils";
import Cropper from "react-easy-crop";
import { useCallback, useEffect, useState } from "react";
import { RcFile } from "@pankod/refine-antd";
import Slider from "rc-slider";
import { getCroppedImg } from "pages/PostEditor/helpers/cropImage";
import classes from "./CropModal.module.css";
import "rc-slider/assets/index.css";

export type CropModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
  files: UploadFile[];
  updateCroppedFile: (files: UploadFile[]) => void;
};

const CropModal = (props: CropModalProps) => {
  const { showModal, onCloseModal, files, updateCroppedFile } = props;
  const images = files.filter(
    (file) => (file.type === MediaTypes.JPEG || file.type === MediaTypes.PNG) && file.response !== "cropped",
  );
  const [croppedImages, setCroppedImages] = useState<UploadFile[]>([]);
  const [imageStr, setImageStr] = useState<string>();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspectRatio, setAspectRatio] = useState(1 / 1);
  // State to track cropped area pixels for each image
  const [croppedAreaPixelsMap, setCroppedAreaPixelsMap] = useState<Record<number, any>>({});
  const [cropRemainingCount, setCropRemainingCount] = useState(images.length);

  const updateImageStrs = async (file: UploadFile) => {
    const imageStrs = await getBase64(file.originFileObj as RcFile);
    setImageStr(imageStrs);
  };

  useEffect(() => {
    const pendingCrops = files.filter(
      (file) => (file.type === MediaTypes.JPEG || file.type === MediaTypes.PNG) && file.response !== "cropped",
    );
    setCroppedImages(pendingCrops);
    setCropRemainingCount(pendingCrops.length);
  }, [files]);

  useEffect(() => {
    if (images?.[cropRemainingCount - 1] && images[cropRemainingCount - 1]?.response !== "cropped")
      updateImageStrs(images[cropRemainingCount - 1]);
  }, [cropRemainingCount, images]);

  // Function to update cropped area pixels for a specific image
  const updateCroppedAreaPixels = (index: number, croppedAreaPixels: any) => {
    setCroppedAreaPixelsMap((prevState) => ({
      ...prevState,
      [index]: croppedAreaPixels,
    }));
  };

  const onPreviousImage = () => {
    setCropRemainingCount((prev) => prev + 1);
  };

  const updateCroppedImages = useCallback(
    async (finalSubmit = true) => {
      try {
        const { croppedFile: croppedImageFile, thumbnailUrl } = await getCroppedImg(
          images[cropRemainingCount - 1],
          croppedAreaPixelsMap[cropRemainingCount - 1],
        );
        const croppedImage = {
          ...images[cropRemainingCount - 1],
          originFileObj: croppedImageFile, // Replace original image with cropped image
          thumbUrl: thumbnailUrl,
          response: "cropped",
          status: "done", // Update status to 'done' to indicate cropping is completed
        };
        // Save the cropped images using updateCroppedFile function
        if (finalSubmit) {
          const finalCroppedImages = croppedImages.map((file) =>
            file?.uid === croppedImage.uid ? (croppedImage as UploadFile) : file,
          );
          const finalCroppedFiles: UploadFile[] = files.map((file: UploadFile) => {
            const existingCrop = finalCroppedImages.find((croppedImage: UploadFile) => croppedImage.uid === file.uid);
            return existingCrop ? existingCrop : file;
          });
          updateCroppedFile(finalCroppedFiles);
        } else {
          setCroppedImages((prev) =>
            prev.map((file) => (file?.uid === croppedImage.uid ? (croppedImage as UploadFile) : file)),
          );
          setCropRemainingCount((prev) => prev - 1);
        }
      } catch (e) {
        console.error(e);
      }

      if (finalSubmit) onCloseModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [croppedAreaPixelsMap, images, updateCroppedFile, onCloseModal],
  );

  return (
    <>
      {showModal && imageStr ? (
        <Modal
          title={`Bild zuschneiden ${images.length - cropRemainingCount + 1}/${images.length}`}
          open={showModal}
          maskClosable={false}
          width={520}
          styles={{ body: { position: "relative", height: 380 } }}
          destroyOnClose
          onCancel={onCloseModal}
          footer={[
            <div style={{ margin: 8, display: "flex" }} key="aspect-ratio">
              <p style={{ alignContent: "center", marginBottom: 0, fontWeight: "bold", marginRight: 16 }}>
                Seitenverhältnis: {aspectRatio.toFixed(1)}
              </p>
            </div>,
            <div style={{ margin: "5px" }} key="aspect-ratio-selector">
              <Slider
                min={4 / 5}
                max={1.91 / 1}
                step={0.1}
                value={aspectRatio}
                onChange={(value) => setAspectRatio(value as number)}
              />
            </div>,
            <div style={{ margin: 8, display: "flex" }} key="zoom">
              <p style={{ alignContent: "center", marginBottom: 0, fontWeight: "bold", marginRight: 16 }}>
                Zoom: {zoom.toFixed(1)}
              </p>
            </div>,
            <div style={{ margin: "5px" }} key="zoom-selector">
              <Slider min={1} max={3} step={0.1} value={zoom} onChange={(value) => setZoom(value as number)} />
            </div>,
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }} key="action">
              {images.length > 1 ? (
                <Button
                  className={classes.submitBtn}
                  onClick={onPreviousImage}
                  disabled={cropRemainingCount >= images.length}
                >
                  Vorherige
                </Button>
              ) : null}
              <Button className={classes.submitBtn} onClick={onCloseModal}>
                Abbrechen
              </Button>
              <Button
                type="primary"
                className={classes.submitBtn}
                onClick={() => updateCroppedImages(cropRemainingCount <= 1)}
              >
                {cropRemainingCount <= 1 ? "Fertig" : "Weiter"}
              </Button>
            </div>,
          ]}
        >
          <div>
            <div key={cropRemainingCount - 1}>
              <Cropper
                image={imageStr as string}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                objectFit="contain"
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                  updateCroppedAreaPixels(cropRemainingCount - 1, croppedAreaPixels);
                }}
                onZoomChange={setZoom}
              />
            </div>
            {/* </Slider> */}
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default CropModal;
