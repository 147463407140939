import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import { UseCustomQueryOptions } from "types";

type GetAccountsResponse = Awaited<ReturnType<typeof AccountApi.getList>>;

const useAccounts = <TSelect = GetAccountsResponse>(options?: UseCustomQueryOptions<GetAccountsResponse, TSelect>) => {
  return useQuery({
    ...options,
    queryKey: AccountQueryKeys.list(),
    queryFn: AccountApi.getList,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
  });
};

export default useAccounts;
