import { IMG_SIZE_LIMIT } from "constants/editor";
import { useState } from "react";
import { CarouselData, CarouselProps, PostData } from "types";
import { Card, Form, Button, Space, Input, Upload, message, UploadFile, Alert, Tooltip } from "antd";
import { DeleteOutlined, LinkOutlined, UploadOutlined, QuestionCircleOutlined, EditOutlined } from "@ant-design/icons";
import getBase64 from "utils/image";
import { RcFile } from "@pankod/refine-antd";
import VideoFile from "../../../../../../assets/videoFile.svg";
import classes from "../CampaignAds.module.css";

const MAX_IMAGES = 7;
const MAX_VIDEOS = 1;
const { TextArea } = Input;

type ThumbnailState = {
  thumbnails: UploadFile;
  videoFile: UploadFile;
};

export default function PreviewForm({
  form,
  carouselData,
  setCarouselData,
  postsData,
  setPostsData,
  carouselUpload,
}: CarouselProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [thumbnailFileList, setThumbnailFileList] = useState<UploadFile[]>([]);
  const [thumbnailsArray, setThumbnailsArray] = useState<ThumbnailState[]>();

  const handleDelete = (index: number) => {
    if (carouselData) {
      for (let idx = 0; idx < carouselData.length; idx += 1) {
        form.setFieldValue(`description${idx}`, "");
        form.setFieldValue(`websiteUrl${idx}`, "");
      }
      const updatedCarouselData = [...carouselData];
      updatedCarouselData.splice(index, 1);
      for (let idx = 0; idx < updatedCarouselData.length; idx += 1) {
        const data = updatedCarouselData[idx];

        form.setFieldValue(`description${idx}`, data.description);
        form.setFieldValue(`websiteUrl${idx}`, data.url);
      }
      setCarouselData(updatedCarouselData);
    }
    if (postsData) {
      const updatedPostsData = [...postsData];
      updatedPostsData.splice(index, 1);
      setPostsData(updatedPostsData);
    }
    if (fileList) {
      const newFileList = [...fileList];
      newFileList.splice(index, 1);
      setFileList(newFileList);
    }
  };

  // const handlePostsDataDelete = (index: number) => {
  //     if (postsData) {
  //         const updatedPostsData = [...postsData];
  //         updatedPostsData.splice(index, 1);
  //         setPostsData(updatedPostsData);
  //     }
  //     if (fileList) {
  //         const newFileList = [...fileList];
  //         newFileList.splice(index, 1);
  //         setFileList(newFileList);
  //     }
  // };

  const updatePostsData = async (newFileList: UploadFile[]) => {
    const promises = newFileList.map(async (file, i) => {
      let base64Image = await getBase64(file.originFileObj as RcFile);
      const videoThumbnail = thumbnailsArray?.find((data) => data.videoFile === file);
      if (thumbnailsArray && videoThumbnail) {
        base64Image = await getBase64(videoThumbnail?.thumbnails.originFileObj as RcFile);
        return {
          video: base64Image as string,
          videoFile: file,
          image: base64Image as string,
          imageFile: videoThumbnail?.thumbnails,
        };
      }

      if (file.type === "video/mp4") {
        return {
          video: base64Image as string,
          videoFile: file,
        };
      }
      return {
        image: base64Image as string,
        imageFile: file,
      };
    });

    const newPostsData = await Promise.all(promises);

    newFileList.forEach((file) => {
      if (file.size && file.size > IMG_SIZE_LIMIT) {
        // eslint-disable-next-line
        file.status = "error";
      }
    });
    setFileList(newFileList);
    setPostsData(newPostsData);
  };

  const updateCarouselData = async (newFileList: UploadFile[]) => {
    const promises = newFileList.map(async (file, i) => {
      let base64Image = await getBase64(file.originFileObj as RcFile);
      const videoThumbnail = thumbnailsArray?.find((data) => data.videoFile === file);
      if (thumbnailsArray && videoThumbnail) {
        base64Image = await getBase64(videoThumbnail?.thumbnails.originFileObj as RcFile);
        return {
          video: base64Image as string,
          videoFile: file,
          image: base64Image as string,
          imageFile: videoThumbnail?.thumbnails,
          name: carouselData ? carouselData[i]?.name : "",
          url: carouselData ? carouselData[i]?.url : "",
          description: carouselData ? carouselData[i]?.description : "",
        };
      }
      if (file.type === "video/mp4") {
        return {
          video: base64Image as string,
          videoFile: file,
          name: carouselData ? carouselData[i]?.name : "",
          description: carouselData ? carouselData[i]?.description : "",
          url: carouselData ? carouselData[i]?.url : "",
        };
      }

      return {
        image: base64Image as string,
        imageFile: file,
        name: carouselData ? carouselData[i]?.name : "",
        description: carouselData ? carouselData[i]?.description : "",
        url: carouselData ? carouselData[i]?.url : "",
      };
    });

    const newCarouselData = await Promise.all(promises);

    newFileList.forEach((file) => {
      if (file.size && file.size > IMG_SIZE_LIMIT) {
        // eslint-disable-next-line
        file.status = "error";
      }
    });
    setFileList(newFileList);
    setCarouselData(newCarouselData);
  };

  const updateVideoThumbnail = async (newFileList: UploadFile[], videoFile: UploadFile) => {
    let index = carouselData?.findIndex((item) => item.videoFile === videoFile);

    if (index !== -1) {
      const base64Image = await getBase64(newFileList[0].originFileObj as RcFile);
      if (carouselData && newFileList[0].type === "image/jpeg") {
        const updatedCarouselData: CarouselData[] = [...carouselData];

        updatedCarouselData[index || 0] = {
          ...updatedCarouselData[index || 0],
          image: base64Image as string,
          imageFile: newFileList[0],
        };

        setCarouselData(updatedCarouselData);
      }
    }
    index = postsData?.findIndex((item) => item.videoFile === videoFile);
    if (index !== -1) {
      const base64Image = await getBase64(newFileList[0].originFileObj as RcFile);
      if (postsData && newFileList[0].type === "image/jpeg") {
        const updatedPostsData: PostData[] = [...postsData];

        updatedPostsData[index || 0] = {
          ...updatedPostsData[index || 0],
          image: base64Image as string,
          imageFile: newFileList[0],
        };

        setPostsData(updatedPostsData);
      }
    }
    const thumbnailIndex = thumbnailsArray?.findIndex((data) => data.videoFile === videoFile);
    if (thumbnailsArray) {
      if (thumbnailIndex !== -1 && thumbnailIndex !== undefined) {
        const updatedThumbnailStates = [...thumbnailsArray];
        // eslint-disable-next-line
        updatedThumbnailStates[thumbnailIndex].thumbnails = newFileList[0];
        setThumbnailsArray(updatedThumbnailStates);
      } else {
        const newThumbnailState: ThumbnailState = {
          thumbnails: newFileList[0],
          videoFile,
        };
        setThumbnailsArray([...(thumbnailsArray || []), newThumbnailState]);
      }
    } else {
      const newThumbnailState: ThumbnailState = {
        thumbnails: newFileList[0],
        videoFile,
      };
      setThumbnailsArray([...(thumbnailsArray || []), newThumbnailState]);
    }
    setThumbnailFileList([]);
  };

  return (
    <div>
      {carouselUpload ? (
        <div>
          {carouselData?.length === 1 && (
            <Alert
              style={{ marginTop: 20, borderRadius: 0 }}
              description="Bitte wählen Sie mindestens 2  Medien für das Karussell aus"
              type="error"
              showIcon
            />
          )}
          Motivupload für Carousel
          <Upload.Dragger
            className={classes.carouselUpload}
            showUploadList={false}
            multiple
            fileList={fileList}
            beforeUpload={(file) => {
              if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "video/mp4") {
                message.error("Es sind nur JPG-, JPEG-, JFIF-, PJP-, PJPEG-, M4V- oder mp4-Dateien erlaubt!");
              }
              return false;
            }}
            onChange={({ fileList: newFileList }) => {
              if (fileList.length > 10) {
                throw new Error("Bitte wählen Sie nicht mehr als 10 Bilder oder Videos aus");
              }
              updatePostsData(newFileList);
              updateCarouselData(newFileList);
            }}
            accept="image/jpeg,image/jpg,video/mp4"
          >
            <div className={classes.text}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined style={{ color: "#000" }} />
              </p>
              <p className="ant-upload-text">Bild oder Video Material hier per Drag & Drop platzieren.</p>
              <p>oder</p>

              <p className="ant-upload-hint">Durchsuchen</p>
            </div>
          </Upload.Dragger>
        </div>
      ) : (
        <div>
          {(fileList.length === 2 ||
            fileList.length > 7 ||
            (postsData && postsData.filter((item) => item.videoFile?.type === "video/mp4").length > 1)) && (
            <Alert
              style={{ marginTop: 20, borderRadius: 0 }}
              message="Mehrere Dateien ausgewählt"
              description="Sie können entweder ein einzelnes Bild oder ein einzelnes Video oder mindestens 3 und maximal 7 Bilder für eine Diashow auswählen"
              type="error"
              showIcon
            />
          )}
          <div>
            <span>Motivupload für Beiträge</span>
            <Upload.Dragger
              fileList={fileList}
              className={classes.carouselUpload}
              showUploadList={false}
              multiple
              beforeUpload={(file) => {
                if (file.type !== "image/jpeg" && file.type !== "image/jpg" && file.type !== "video/mp4") {
                  message.error("Es sind nur JPG-, JPEG-, JFIF-, PJP-, PJPEG-, M4V- oder mp4-Dateien erlaubt!");
                }

                return false;
              }}
              onChange={({ fileList: newFileList }) => {
                newFileList.forEach((item) => {
                  if (item.size && item.size > IMG_SIZE_LIMIT) {
                    // eslint-disable-next-line no-param-reassign
                    item.status = "error";
                  }
                });
                updatePostsData(newFileList);
                updateCarouselData(newFileList);
              }}
              accept="image/jpeg,image/jpg,video/mp4"
            >
              <div className={classes.text}>
                <p className="ant-upload-drag-icon">
                  <UploadOutlined style={{ color: "#000" }} />
                </p>
                <p className="ant-upload-text">Bild oder Video Material hier per Drag & Drop platzieren.</p>
                <p>oder</p>

                <p className="ant-upload-hint">Durchsuchen</p>
              </div>
            </Upload.Dragger>
          </div>
        </div>
      )}
      {carouselUpload ? (
        <div style={{ marginTop: 30 }}>
          <span>Karussell-Medien</span>
          {carouselData ? <p>{carouselData.length} von 10 Medien</p> : ""}
          {carouselData?.map((item, index) => (
            <div style={{ marginTop: 15 }}>
              {/* {isCardOpen[index] ? ( */}
              <Card className={classes.previewCard}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "30%",
                      padding: 20,
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {item.video ? (
                      <img
                        src={item.image ? item.image : VideoFile}
                        alt="logo"
                        style={{ width: "100%", marginBottom: 10 }}
                      />
                    ) : (
                      <img src={item.image} alt="logo" style={{ width: "100%" }} />
                    )}
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      <span className={classes.fileName}>{item.videoFile?.name || item.imageFile?.name}</span>
                      {item.video && (
                        <Tooltip
                          trigger="hover"
                          color="#000"
                          placement="bottom"
                          title="Für eine Videodatei müssen Sie ein Miniaturbild hochladen"
                        >
                          <Upload
                            fileList={thumbnailFileList}
                            showUploadList={false}
                            multiple={false}
                            beforeUpload={(file) => {
                              if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
                                message.error(
                                  "Für Miniaturansichten sind nur JPG-, JPEG-, PJP-, PJPEG- und JFIF-Bilder zulässig!",
                                );
                              }

                              return false;
                            }}
                            onChange={({ fileList: newFileList }) => {
                              newFileList.forEach((thumbnail) => {
                                if (thumbnail.size && thumbnail.size > IMG_SIZE_LIMIT) {
                                  // eslint-disable-next-line no-param-reassign
                                  thumbnail.status = "error";
                                }
                              });
                              if (item.videoFile) {
                                updateVideoThumbnail(newFileList, item.videoFile);
                              }
                            }}
                            accept="image/jpeg,image/jpg"
                          >
                            <Button
                              className={classes.thumbnailBtn}
                              icon={item.image ? <EditOutlined /> : <UploadOutlined />}
                            >
                              Miniaturansicht
                            </Button>
                          </Upload>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div style={{ margin: 10, color: "black", width: "70%" }}>
                    <Space style={{ width: "100%" }} direction="vertical">
                      <span>Überschrift</span>
                      <Input
                        value={item.name}
                        placeholder="Schreiben Sie eine kurze Überschrift"
                        style={{ width: "90%", borderRadius: 0 }}
                        onChange={(event) => {
                          const newCarouselData = [...carouselData];
                          newCarouselData[index].name = event.target.value;
                          setCarouselData(newCarouselData);
                        }}
                      />
                      <Form.Item
                        name={`description${index}`}
                        label="Beschreibung"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea
                          // draggable={false}
                          placeholder="usätzliche Informationen hinzufügen"
                          style={{ width: "90%", borderRadius: 0 }}
                          rows={2}
                          onChange={(event) => {
                            const newCarouselData = [...carouselData];
                            newCarouselData[index].description = event.target.value;
                            newCarouselData.map((data, idx) =>
                              form.setFieldValue(`description${idx}`, data.description),
                            );
                            setCarouselData(newCarouselData);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={`websiteUrl${index}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LinkOutlined style={{ marginRight: 8 }} />
                            Webadresse
                            <Tooltip
                              className={classes.TooltipText}
                              placement="right"
                              style={{
                                color: "#000 !important",
                                background: "#fff",
                              }}
                              title="Geben Sie die URL der zu besuchenden Website ein. Die URL sollte mit http:// oder https:// beginnen"
                            >
                              <QuestionCircleOutlined
                                style={{
                                  marginLeft: 10,
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Bitte geben Sie den Webadresse ein",
                          },
                          {
                            validator(rule, value, callback) {
                              if (value && !/^https?:\/\//i.test(value)) {
                                callback(
                                  "Die von Ihnen eingegebene URL führt nicht zu einer Website. Bitte geben Sie eine gültige URL ein",
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          value={item.url}
                          className={classes.websiteUrl}
                          placeholder="http://www.example.com/page"
                          onChange={(event) => {
                            const newCarouselData = [...carouselData];
                            newCarouselData[index].url = event.target.value;
                            newCarouselData.map((data, idx) => form.setFieldValue(`websiteUrl${idx}`, data.url));
                            setCarouselData(newCarouselData);
                          }}
                        />
                      </Form.Item>
                    </Space>
                    <DeleteOutlined
                      style={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        cursor: "pointer",
                        fontSize: 17,
                      }}
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: 30,
            justifyContent: "center",
            background: "#f4f4f4",
          }}
        >
          {postsData?.map((item, index) => (
            <Card
              hoverable
              style={{
                width: 180,
                margin: 12,
                borderRadius: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              actions={[
                <DeleteOutlined
                  key="delete"
                  style={{
                    cursor: "pointer",
                    fontSize: 17,
                  }}
                  onClick={() => handleDelete(index)}
                />,
              ]}
              cover={
                item.video ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={item.image ? item.image : VideoFile}
                      alt="logo"
                      style={{ width: "100%", marginBottom: 10 }}
                    />
                  </div>
                ) : (
                  <img src={item.image} alt="logo" style={{ width: "100%" }} />
                )
              }
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  whiteSpace: "break-spaces",
                }}
              >
                <span>{item.videoFile?.name || item.imageFile?.name}</span>
                {item.video && (
                  <Tooltip
                    trigger="hover"
                    color="#000"
                    placement="bottom"
                    title="Für eine Videodatei müssen Sie ein Miniaturbild hochladen"
                  >
                    <Upload
                      fileList={thumbnailFileList}
                      showUploadList={false}
                      multiple={false}
                      beforeUpload={(file) => {
                        if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
                          message.error(
                            "Für Miniaturansichten sind nur JPG-, JPEG-, PJP-, PJPEG- und JFIF-Bilder zulässig!",
                          );
                        }
                        return false;
                      }}
                      onChange={({ fileList: newFileList }) => {
                        newFileList.forEach((thumbnail) => {
                          if (thumbnail.size && thumbnail.size > IMG_SIZE_LIMIT) {
                            // eslint-disable-next-line no-param-reassign
                            thumbnail.status = "error";
                          }
                        });
                        if (item.videoFile) {
                          updateVideoThumbnail(newFileList, item.videoFile);
                        }
                      }}
                      accept="image/jpeg,image/jpg"
                    >
                      <Button
                        style={{
                          padding: 5,
                          background: "#eaeaea",
                        }}
                        icon={item.image ? <EditOutlined /> : <UploadOutlined />}
                      >
                        Miniaturansicht
                      </Button>
                    </Upload>
                  </Tooltip>
                )}
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
