import { useMutation, useQueryClient } from "react-query";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";

const useUpdateCompanyUsers = (options?: {
  onSuccess?: (
    data: Awaited<ReturnType<typeof CompanyApi.updateUsers>>,
    variables: Parameters<typeof CompanyApi.updateUsers>[0],
  ) => void | Promise<void>;
  onError?: (e: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CompanyApi.updateUsers,
    onError: options?.onError,
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey: CompanyQueryKeys.details(variables.companyId) });

      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
};

export default useUpdateCompanyUsers;
