import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { NotificationApi } from "services/api";
import { NotificationQueryKeys } from "services/query-keys";
import { NotificationStatusType } from "types";

export type UpdateNoficationData = {
  notificationIds: string[];
  status: NotificationStatusType | "UNREAD";
  hideSuccessMessage?: boolean;
};

const useUpdateNotification = (
  options?: Omit<UseMutationOptions<void, unknown, UpdateNoficationData, unknown>, "mutationFn">,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: NotificationApi.updateNotificationStatus,
    onSuccess: async (data, variables, ctx) => {
      await queryClient.invalidateQueries({ queryKey: NotificationQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: NotificationQueryKeys.getUnreadCount() });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, ctx);
      }
    },
  });
};

export default useUpdateNotification;
