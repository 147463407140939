import { AxiosResponse } from "axios";
import { UploadFile } from "antd/es/upload";
import dayjs from "dayjs";
import {
  Account,
  AccountUser,
  LocationSearchData,
  LocationSearchMapData,
  Post,
  PostFormData,
  User,
  TargetingDetail,
  CampaignFormData,
  AdSetFormData,
  InsightsData,
  MetaBusiness,
  AccountInvitedUser,
  AccountSubscription,
  AccountInvoice,
  AdSetResponseData,
  CampaignResponseData,
  AccountChannelType,
  AdsCreativeData,
  AdsData,
  LeadFormResponse,
  AdsCopyData,
  AccountDetail,
  AdsResponseData,
  Todo,
  TodoResponse,
  AdAccountDetail,
  TodoFormData,
  ZipPostData,
  NotificationStatusType,
  DevicePlatform,
  CheckoutSession,
  UserAccessPolicy,
  TFindAllResponse,
  CreateUserAccessPolicy,
  Channel,
  NotificationData,
  PostReviewComment,
  PostPublishingStatusType,
  PostVersion,
  AccountPaymentInvoice,
  Company,
  InsightResponse,
  PostWithInsight,
} from "types/data";
import api from "utils/api";
import { TODO_STATUS } from "types";
import { SigninProvider } from "authProvider";
import { TFindAllUserAccessPolicyParams } from "hooks/UserAccessPolicy/useUserAccessPolicy";
import { TGetPostsParams } from "hooks/posts/usePosts";
import { TFindAllChannelsParams } from "hooks/useChannels";
import { TGetTodosParams } from "hooks/todos/useTodos";
import { TGetPostReviewCommentsParams } from "hooks/PostReviewComment/usePostReviewComments";
import { CreatePostReviewCommentData } from "hooks/PostReviewComment/useCreatePostReviewComment";
import { DeletePostReviewCommentData } from "hooks/PostReviewComment/useDeletePostReviewComment";
import { EditPostReviewCommentData } from "hooks/PostReviewComment/useEditPostReviewComment";
import { ReviewPostData } from "hooks/posts/useReviewPost";
import { TListUsersParams } from "hooks/Users/useUsers";
import { TListNotificationsParams } from "hooks/Notification/useNotifications";
import { TGetUnreadNotificationsCountParams } from "hooks/Notification/useUnreadNotificationsCount";
import { UpdateNoficationData } from "hooks/Notification/useUpdateNotification";
import { TGetPostVersionParams } from "hooks/posts/usePostVersion";
import { TPostReviewCommentDetailsParams } from "hooks/PostReviewComment/usePostReviewComment";
import { GetPostVersionParams } from "hooks/posts/usePostVersions";
import { TFindAllCompaniesParams } from "hooks/company/useCompanies";
import { TDeleteCompanyParams } from "hooks/company/useDeleteCompany";
import { TGetUserStatsParams } from "hooks/Users/useUserStats";
import { TGetCompanyStatsParams } from "hooks/company/useCompanyStats";
import { TGetAccountStatsParams } from "hooks/useAccountStats";
import { TListInvoicesParams } from "hooks/Payment/usePaymentInvoices";
import { TGetAccountUsersParams } from "hooks/useAccountUsers";
import { TDeleteUserParams } from "hooks/Users/useDeleteUser";
import { TGetInsightsParams } from "hooks/Insight/useInsights";
import { TGetPostStatsParams } from "hooks/posts/usePostStats";
import { TGetInsightPostsParams } from "hooks/Insight/useInsightPosts";
import { TGetInsightConsumptionParams } from "hooks/Insight/useInsightConsumption";
import { TGetAccountInvoicesParams } from "hooks/useAccountInvoices";
import { TDownloadInvoiceParams } from "hooks/useDownloadInvoice";
import { TGetAccountSubscriptionParams } from "hooks/useAccountSubscription";
import { TUpdateAccountParams } from "hooks/useUpdateAccount";
import { TInsightDemographicParams } from "hooks/Insight/useInsightDemographic";
import { AssetApi } from "./interface/asset-api";

export type PostFormBody = PostFormData & {
  publishingStatus: PostPublishingStatusType;
  facebookScheduledAt?: string;
  instagramScheduledAt?: string;
};

type ScheduleBody = {
  publishingStatus: PostPublishingStatusType;
  facebookScheduledAt?: string;
  instagramScheduledAt?: string;
};

type PostInsightData = {
  likes: {
    data: any[];
    summary: {
      total_count: number;
      can_like: boolean;
      has_liked: boolean;
    };
  };
  comments: {
    data: any[];
    summary: {
      order: string;
      total_count: number;
      can_comment: boolean;
    };
  };
  insights: {
    data: InsightData[];
    paging: {
      previous: string;
      next: string;
    };
  };
  id: string;
};

type InsightData = {
  name: string;
  period: string;
  values: InsightValue[];
  title: string;
  description: string;
  id: string;
};

type InsightValue = {
  value: number;
};

export const AuthApi = {
  login: async (
    body: { email: string; password: string } | { access_token: string; signinProvider: SigninProvider },
  ) => {
    if (!("signinProvider" in body)) {
      const res = await api.post("/auth/login", body);
      return res.data;
    } else if ("access_token" in body) {
      const url = `/auth/social-login?idToken=${body.access_token}`;
      const res = await api.get(url);
      return res.data;
    }
  },
  register: async (body: { accountName: string; name: string; email: string; password: string }) => {
    const res = await api.post("/auth/signup", body);
    return res.data;
  },
  verifyOtp: async (email: string, otp: number) => {
    const res = await api.post("/auth/verify", {
      email,
      otp,
    });
    return res.data;
  },
  resendOtp: async (email: string) => {
    const res = await api.post("/auth/resend-otp", {
      email,
    });
    return res.data;
  },
  forgotPassword: async (body: { email: string }) => {
    const res = await api.post("/auth/forgot-password", body);
    return res.data;
  },
  resetPassword: async (body: { token: string; password: string }) => {
    const res = await api.post("/auth/reset-password", body);
    return res.data;
  },
  logout: async () => {
    const res = await api.get("/auth/logout");
    return res.data;
  },
};

export const UserApi = {
  getMe: async (accountId?: string) => {
    const res = await api.get<{ data: User; message: string }>("/user/me", { params: { accountId } });
    return res.data.data;
  },
  updateMe: async (body: { name: string; profilePic?: UploadFile }) => {
    let requestBody;
    if (body.profilePic) {
      const response = await AssetApi.uploadAndConfirm([body.profilePic]);
      requestBody = { ...body, user_profile_pic: response[0].url, name: body.name.trim() };
      delete requestBody.profilePic;
    } else {
      requestBody = { ...body, name: body.name.trim() };
    }
    const res = await api.patch("/user/me", requestBody);
    return res.data.data;
  },
  updatePassword: async (body: { oldPassword: string; newPassword: string }) => {
    const res = await api.patch("/user/me/password", body);
    return res.data.data;
  },
  list: async (filters?: TListUsersParams) => {
    const { data } = await api.get<{ data: { items: User[]; total: number } }>(`/user`, {
      params: filters,
    });
    return data.data;
  },
  stats: async (filters: TGetUserStatsParams) => {
    const { data } = await api.get<{
      data: {
        count: number;
        year: number;
        month: number;
      }[];
    }>(`/user/stats`, {
      params: filters,
    });

    return data.data;
  },
  delete: async ({ userId, forceDelete, reassignments }: TDeleteUserParams) => {
    const { data } = await api.delete(`/user/${userId}`, {
      params: {
        forceDelete,
      },
      data: reassignments,
    });

    return data.data;
  },
};

export const UserAccessPolicyApi = {
  findAll: async (params: TFindAllUserAccessPolicyParams) => {
    const queryString = new URLSearchParams(params).toString();
    const res = await api.get<AxiosResponse<TFindAllResponse<UserAccessPolicy>>>(`user-access-policy?${queryString}`);
    return res.data.data;
  },
  createAccessPolicy: async (data: CreateUserAccessPolicy) => {
    const res = await api.post<AxiosResponse<UserAccessPolicy>>(`user-access-policy/`, data);
    return res.data.data;
  },
  deleteAccessPolicy: async (data: { id: string }) => {
    const res = await api.delete<AxiosResponse<UserAccessPolicy>>(`user-access-policy/${data.id}`);
    return res.data.data;
  },
};

export type CreateCompanyInput = {
  name: string;
  address?: string | null;
  postal?: string | null;
  city?: string | null;
  website?: string | null;
  phone?: string | null;
  requiresPostReview?: boolean | null;
  profilePic?: string | null;
  userIds?: string[] | null;
  channelIds?: string[] | null;
};

export const PostReviewCommentApi = {
  list: async (filters?: TGetPostReviewCommentsParams) => {
    const {
      data: { data },
    } = await api.get<{
      data: {
        total: number;
        items: PostReviewComment[];
      };
    }>(`/post-review-comment`, {
      params: filters,
    });

    return data;
  },
  details: async ({ commentId, ...filters }: TPostReviewCommentDetailsParams) => {
    const {
      data: { data },
    } = await api.get<{
      data: PostReviewComment;
    }>(`/post-review-comment/${commentId}`, {
      params: filters,
    });

    return data;
  },
  create: async (input: CreatePostReviewCommentData) => {
    const {
      data: { data },
    } = await api.post<{
      data: PostReviewComment;
    }>(`/post-review-comment`, input);

    return data;
  },
  edit: async ({ id, ...input }: EditPostReviewCommentData) => {
    const {
      data: { data },
    } = await api.patch<{
      data: PostReviewComment;
    }>(`/post-review-comment/${id}`, input);

    return data;
  },
  delete: async ({ id }: DeletePostReviewCommentData) => {
    const {
      data: { data },
    } = await api.delete<{
      data: PostReviewComment;
    }>(`/post-review-comment/${id}`);

    return data;
  },
};

export const CompanyApi = {
  list: async (filters?: TFindAllCompaniesParams) => {
    const params = new URLSearchParams();

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach((item) => params.append(key, item));
          } else {
            params.append(key, `${value}`);
          }
        }
      }
    }

    const {
      data: { data },
    } = await api.get<{
      data: {
        total: number;
        items: Company[];
      };
    }>(`/company`, {
      params,
    });

    return data;
  },
  details: async (
    companyId: string,
    filters?: {
      include?: ("users" | "channels" | "posts" | "account")[];
    },
  ) => {
    const params = new URLSearchParams();

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach((item) => params.append(key, item));
          } else {
            params.append(key, `${value}`);
          }
        }
      }
    }
    const {
      data: { data },
    } = await api.get<{ data: Company }>(`/company/${companyId}?${params.toString()}`);

    return data;
  },
  stats: async (filters: TGetCompanyStatsParams) => {
    const { data } = await api.get<{
      data: {
        count: number;
        year: number;
        month: number;
      }[];
    }>(`/company/stats`, {
      params: filters,
    });

    return data.data;
  },
  create: async ({ company, accountId }: { company: CreateCompanyInput; accountId: string }) => {
    const params = new URLSearchParams({
      accountId,
    });

    const {
      data: { data },
    } = await api.post<{ data: { count: number }; message: string; statusCode: number }>(`/company`, company, {
      params,
    });
    return data;
  },
  update: async ({ updatedFields, companyId }: { companyId: string; updatedFields: Partial<Company> }) => {
    const {
      data: { data },
    } = await api.patch<{
      data: {
        data: Company;
        message: string;
        statusCode: number;
      };
    }>(`/company/${companyId}`, updatedFields);

    return data;
  },
  updateUsers: async ({
    userIds,
    companyId,
    action,
  }: {
    companyId: string;
    userIds: string[];
    action: "add" | "remove";
  }) => {
    const {
      data: { data },
    } = await api.patch<{
      data: {
        data: Company;
        message: string;
        statusCode: number;
      };
    }>(`/company/${companyId}/user`, { userIds }, { params: { action } });

    return data;
  },
  updateChannels: async ({
    channelIds,
    companyId,
    action,
  }: {
    companyId: string;
    channelIds: string[];
    action: "add" | "remove";
  }) => {
    const {
      data: { data },
    } = await api.patch<{
      data: {
        data: Company;
        message: string;
        statusCode: number;
      };
    }>(`/company/${companyId}/channel/${action}`, { channelIds });

    return data;
  },
  delete: async ({ forceDelete = false, companyId }: TDeleteCompanyParams) => {
    const { data } = await api.delete(`/company/${companyId}`, {
      params: {
        forceDelete: `${forceDelete}`,
      },
    });

    return data;
  },
};

export const PaymentApi = {
  checkout: async (params: { planType: "BASIC" | "PRO"; recurrence: "MONTHLY" | "YEARLY"; accountId: string }) => {
    const {
      data: { data },
    } = await api.post<{ data: { checkoutSession: CheckoutSession }; message: string; statusCode: number }>(
      `/payments/checkout`,
      params,
    );

    return data.checkoutSession;
  },
  listCheckoutInvoices: async (filters?: TListInvoicesParams) => {
    const params = {
      ...filters,
      startDate: filters?.startDate ? dayjs(filters.startDate).format("YYYY-MM-DD") : undefined,
      endDate: filters?.endDate ? dayjs(filters.endDate).format("YYYY-MM-DD") : undefined,
    };

    const {
      data: { data },
    } = await api.get<{
      data: AccountPaymentInvoice[];
    }>(`/payments/invoices`, {
      params,
    });

    return data;
  },
};

export const AccountApi = {
  details: async (accountId: string) => {
    const {
      data: { data },
    } = await api.get<{ data: Account }>(`/account/${accountId}`);

    return data;
  },
  stats: async (filters: TGetAccountStatsParams) => {
    const { data } = await api.get<{
      data: {
        count: number;
        year: number;
        month: number;
      }[];
    }>(`/account/stats`, {
      params: filters,
    });

    return data.data;
  },
  getList: async () => {
    const { data } = await api.get<{ data: Account[]; message: string }>("/account/list");
    return data.data;
  },
  getUsers: async ({ accountId, ...filters }: TGetAccountUsersParams) => {
    const { data } = await api.get<{ data: AccountUser[]; message: string }>(`/account/${accountId}/users`, {
      params: filters,
    });
    return data.data;
  },
  getChannels: async ({ accountId }: TFindAllChannelsParams) => {
    const { data } = await api.get<{ data: Channel[]; message: string }>(`/account/${accountId}/channels`);
    return data.data;
  },
  getInvitedUsers: async (accountId: string) => {
    const { data } = await api.get<{ data: AccountInvitedUser[]; message: string }>(
      `/account/${accountId}/user/invite`,
    );
    return data.data;
  },
  getSubscription: async ({ accountId, ...filters }: TGetAccountSubscriptionParams) => {
    const { data } = await api.get<{ data: AccountSubscription; message: string }>(
      `/account/${accountId}/subscription`,
      {
        params: filters,
      },
    );
    return data.data;
  },
  updateUser: async ({ accountId, userId, role }: { accountId: string; userId: string; role: string }) => {
    const { data } = await api.patch(`/account/${accountId}/user`, undefined, {
      params: {
        userId,
        role,
      },
    });
    return data.data;
  },
  deleteUser: async ({ accountId, userId }: { accountId: string; userId: string }) => {
    const { data } = await api.delete(`/account/${accountId}/user`, { params: { userId } });
    return data.data;
  },
  deleteInvitedUser: async ({ accountId, email }: { accountId: string; email: string }) => {
    const { data } = await api.delete(`/account/${accountId}/user/invite`, {
      data: {
        email,
      },
    });
    return data.data;
  },
  deleteChannels: async ({ accountId, ids }: { accountId: string; ids: string[] }) => {
    const { data } = await api.delete(`/account/${accountId}/channels`, {
      params: {
        ids,
      },
    });
    return data.data;
  },
  inviteUser: async ({ accountId, email, role }: { accountId: string; email: string; role: string }) => {
    const { data } = await api.post(`/account/${accountId}/user/invite`, {
      email,
      role,
    });
    return data.data;
  },
  checkInvite: async (token: string) => {
    const { data } = await api.get<{ statusCode: number; message: string; data: [] }>(
      `/account/user/check-invite?token=${token}`,
    );
    return data.message;
  },
  respondInvite: async ({
    token,
    accept,
    email,
    name,
    password,
  }: {
    token: string;
    accept: boolean;
    email: string;
    name: string;
    password?: string;
  }) => {
    const { data } = await api.post(`/account/user/response-invite`, {
      token,
      accept,
      email,
      name,
      password,
    });
    return data.message;
  },
  getMetaBusinesses: async (accountId: string) => {
    const { data } = await api.get<{ data: MetaBusiness[]; message: string }>(`/account/${accountId}/meta-businesses`);
    return data.data;
  },
  getAccountDetails: async (accountId: string) => {
    const { data } = await api.get<{ data: AccountDetail; message: string }>(`/account/${accountId}`);
    return data.data;
  },
  updateAccountDetails: async ({ accountId, accountProfilePic, ...input }: TUpdateAccountParams) => {
    const body = { ...input };
    if (accountProfilePic) {
      const response = await AssetApi.uploadAndConfirm([accountProfilePic], accountId);
      body.account_profile_pic = response[0].url;
    }

    const { data } = await api.patch(`/account/${accountId}`, body);
    return data.data;
  },
  getInvoices: async ({ accountId, ...filters }: TGetAccountInvoicesParams) => {
    const { data } = await api.get<{ data: AccountInvoice[]; message: string }>(`/account/${accountId}/invoices`, {
      params: filters,
    });
    return data.data;
  },
  generateInvoicePdf: async ({ accountId, invoiceId }: TDownloadInvoiceParams) => {
    const data = await api.get(`/account/${accountId}/download-pdf/${invoiceId}`, {
      responseType: "arraybuffer",
    });
    return data.data;
  },
};

export const ImageApi = {
  upload: async (files: UploadFile[], accountId?: string, companyId?: string) => {
    const responses = await Promise.all(
      files.map(async (file) => {
        let url = file?.url;

        if (file.originFileObj) {
          const response = await AssetApi.uploadAndConfirmOne(file, accountId, companyId);
          url = response.url;
        }

        if (
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.url?.includes("jpeg") ||
          file.url?.includes("jpg") ||
          file.url?.includes("png")
        ) {
          return url;
        }

        const { data } = await api.post(`campaign/${accountId}/advideo`, {
          url,
        });
        return data.data.id;
      }),
    );

    return responses;
  },
  zipCsvUpload: async (file: UploadFile, accountId: string, companyId?: string) => {
    let url = file?.url;

    if (file.originFileObj) {
      const response = await AssetApi.uploadAndConfirmOne(file, accountId, companyId);
      url = response.url;

      return url;
    }
  },
  singleUpload: async (file: UploadFile, accountId: string) => {
    let url = file?.url;

    if (file.originFileObj) {
      const response = await AssetApi.uploadAndConfirmOne(file, accountId);
      url = response.url;
    }

    if (file.type === "image/jpeg") {
      return url;
    }

    const { data } = await api.post(`campaign/${accountId}/advideo`, {
      url,
    });
    return data.data.id;
  },
};

export const PostApi = {
  review: async ({ postVersionId, ...input }: ReviewPostData) => {
    const { data } = await api.patch(`/post/version/review/${postVersionId}`, input);
    return data.data;
  },
  scheduleZip: async (body: ZipPostData) => {
    let csv, zip;
    if (body.csv) {
      csv = await ImageApi.zipCsvUpload(body.csv, body.accountId);
    }
    if (body.zip) {
      zip = await ImageApi.zipCsvUpload(body.zip, body.accountId);
    }

    const { data } = await api.post("/post/schedule-zip", {
      accountId: body.accountId,
      companyId: body.companyId,
      userId: body.userId,
      channelIds: body.platform,
      csvUrl: csv,
      zipUrl: zip,
    });

    return data.data;
  },
  checkScheduleStatus: async (accountId: string, companyId: string) => {
    if (accountId) {
      const { data } = await api.post("/post/check-schedule-status", {
        accountId,
        companyId,
      });

      return data.data;
    } else {
    }
  },
  create: async (body: PostFormBody, publishMobile: boolean) => {
    let images;

    if (body.images?.length) {
      // images = await ImageApi.upload(body.images, body.accountId);
      images = await AssetApi.uploadAndConfirm(body.images, body.accountId, body.companyId);
    }

    const assets = images?.map((image) => ({
      ...image,
      url: image.url,
      type: image.type === "video/mp4" ? "VIDEO" : "PHOTO",
    }));

    const { data } = await api.post(
      `/post`,
      {
        accountId: body.accountId,
        companyId: body.companyId,
        channelIds: body.platform,
        body: body.text,
        assets,
        publishingStatus: body.publishingStatus,
        facebookScheduledAt: body.facebookScheduledAt,
        instagramScheduledAt: body.instagramScheduledAt,
      },
      {
        params: {
          publishMobile,
        },
      },
    );

    return data.data;
  },
  versionDetails: async ({ versionId: postId, ...params }: TGetPostVersionParams) => {
    const getData = await api.get<{
      data: Omit<PostVersion, "post"> & {
        post: Post;
      };
      message: string;
    }>(`/post/version/${postId}`, {
      params,
    });

    return getData.data.data;
  },
  getPosts: async (params: TGetPostsParams) => {
    const getData = await api.get<{ data: { posts: Post[]; total: number }; message: string }>(`/post`, {
      params,
    });

    return getData.data.data.posts;
  },
  stats: async (params: TGetPostStatsParams) => {
    const getData = await api.get<{
      data: {
        publishedPostCount: number;
        scheduledPostCount: number;
        totalPostCount: number;
      };
      message: string;
    }>(`/post/stats`, {
      params,
    });

    return getData.data.data;
  },

  getPostVersions: async (params: GetPostVersionParams) => {
    const getData = await api.get<{ data: { posts: PostVersion[]; total: number }; message: string }>(`/post/version`, {
      params,
    });

    return getData.data.data.posts;
  },
  getPublishedPosts: async (accountId: string, companyId: string, startDate: string, endDate: string) => {
    const { data } = await api.get<{ data: { posts: Post[] } }>(
      `/post?accountId=${accountId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}&status=PUBLISHED`,
    );
    return data.data;
  },
  update: async (versionId: string, body: PostFormBody, publishMobile: boolean) => {
    let images;
    if (body.images?.length) {
      images = await AssetApi.uploadAndConfirm(body.images, body.accountId, body.companyId);
    }

    const assets = images?.map((image) => ({
      ...image,
      url: image.url,
      type: image.type === "video/mp4" ? "VIDEO" : "PHOTO",
    }));

    const { data } = await api.patch(
      `/post/version/${versionId}`,
      {
        accountId: body.accountId,
        companyId: body.companyId,
        channelId: body.platform[0],
        ...(body.text && { body: body.text }),
        assets,
        publishingStatus: body.publishingStatus,
        facebookScheduledAt: body.facebookScheduledAt,
        instagramScheduledAt: body.instagramScheduledAt,
      },
      {
        params: {
          publishMobile,
        },
      },
    );
    return data.data;
  },
  updateSchedule: async (versionId: string, body: ScheduleBody) => {
    const { data } = await api.patch(`/post/version/${versionId}`, {
      publishingStatus: body.publishingStatus,
      facebookScheduledAt: body.facebookScheduledAt,
      instagramScheduledAt: body.instagramScheduledAt,
    });
    return data.data;
  },
  getById: async (id: string) => {
    const { data } = await api.get<{ data: Post; message: string }>(`/post/${id}`);
    return data.data;
  },
  removePost: async (id: string) => {
    const { data } = await api.delete(`/post/${id}`);
    return data.data;
  },
};

export const CampaignApi = {
  createCampaign: async (body: {
    accountId: string;
    name: string;
    objective: string;
    status: string;
    special_ad_categories: string[];
    buying_type: string;
    spend_cap?: number | null;
  }) => {
    const { data } = await api.post(`/campaign/${body.accountId}`, body);
    return data.data;
  },
  updateCampaign: async (
    body: {
      accountId: string;
      name: string;
      objective: string;
      status: string;
      special_ad_categories: string[];
      buying_type: string;
      spend_cap?: number | null;
    },
    campaignId: string,
  ) => {
    const { data } = await api.patch(`/campaign/${body.accountId}/${campaignId}`, body);
    return data.data;
  },
  updateCampaignStatus: async (
    body: {
      accountId: string;
      status: string;
    },
    campaignId: string,
  ) => {
    const { data } = await api.patch(`/campaign/${body.accountId}/${campaignId}`, body);
    return data.data;
  },
  updateAdsetStatus: async (
    body: {
      accountId: string;
      status: string;
    },
    adsetId: string,
  ) => {
    const { data } = await api.post(`/campaign/${body.accountId}/adset/${adsetId}`, body);
    return data.data;
  },
  updateAdStatus: async (
    body: {
      accountId: string;
      status: string;
    },
    adId: string,
  ) => {
    const { data } = await api.post(`/campaign/${body.accountId}/ad/${adId}`, body);
    return data.data;
  },
  createCampaignAdset: async (body: AdSetFormData) => {
    const { data } = await api.post(`/campaign/${body.accountId}/adset`, body);
    return data.data;
  },
  updateCampaignAdset: async (body: AdSetFormData, adsetId: string) => {
    const { data } = await api.patch(`/campaign/${body.accountId}/adset/${adsetId}`, body);
    return data.data;
  },
  createAds: async (accountId: string, body: AdsData) => {
    const { data } = await api.post(`/campaign/${accountId}/ad`, body);
    return data.data;
  },
  updateAds: async (accountId: string, body: AdsData, adId: string) => {
    const { data } = await api.patch(`/campaign/${accountId}/ad/${adId}`, body);
    return data.data;
  },
  createAdCreatives: async (accountId: string, body: AdsCreativeData) => {
    const { data } = await api.post(`/campaign/${accountId}/adcreative`, body);
    return data.data;
  },
  getAdCreativesData: async (accountId: string, creativeId: string) => {
    const { data } = await api.get<{ data: AdsCreativeData }>(`/campaign/${accountId}/adcreative/${creativeId}`);
    return data.data;
  },
  getCampaignDetails: async (accountId: string, campaignId: string) => {
    const { data } = await api.get<{ data: CampaignFormData }>(`/campaign/${accountId}/${campaignId}`);
    return data.data;
  },
  getAdsetDetails: async (accountId: string, adsetId: string) => {
    const { data } = await api.get<{ data: AdSetFormData }>(`/campaign/${accountId}/adset/${adsetId}`);
    return data.data;
  },
  getLocationOnSearch: async (accountId: string, q: string) => {
    const { data } = await api.get<{ data: LocationSearchData[] }>(
      `/campaign/${accountId}/location-search?q=${encodeURIComponent(q)}&location_types=country`,
    );
    return data.data;
  },
  getLocationOnSearchMap: async (accountId: string, q: string) => {
    const { data } = await api.get<{ data: LocationSearchMapData[] }>(
      `/campaign/${accountId}/location-search?q=${encodeURIComponent(
        q,
      )}&location_types=country&location_types=region&location_types=city&location_types=zip`,
    );
    return data.data;
  },
  getDetailedTargeting: async (accountId: string, type: string, q: string) => {
    const { data } = await api.get<{ data: TargetingDetail[] }>(
      `/campaign/${accountId}/detailed-targeting?type=${type}&q=${encodeURIComponent(q)}`,
    );
    return data.data;
  },
  getCampaignList: async (accountId: string, startDate: string, endDate: string) => {
    const { data } = await api.get<{
      data: {
        campaigns: CampaignResponseData[];
      };
    }>(`/campaign/${accountId}/campaign?startDate=${startDate}&endDate=${endDate}`);
    return data.data;
  },
  getAdsetList: async (accountId: string, startDate: string, endDate: string, campaignId?: string) => {
    const { data } = await api.get<{
      data: {
        adsets: AdSetResponseData[];
      };
    }>(
      startDate && endDate
        ? `/campaign/${accountId}/adset?startDate=${startDate}&endDate=${endDate}`
        : `/campaign/${accountId}/adset?campaignId=${campaignId}`,
    );
    return data.data;
  },
  getAdsList: async (accountId: string, startDate: string, endDate: string) => {
    const { data } = await api.get<{
      data: {
        ads: AdsResponseData[];
      };
    }>(`/campaign/${accountId}/ad?startDate=${startDate}&endDate=${endDate}`);
    return data.data;
  },
  getAdData: async (accountId: string, adId: string) => {
    const { data } = await api.get<{
      data: {
        name: string;
        adsetId: string;
        creativeId: string;
        leadFormId?: string;
        status: string;
      };
    }>(`/campaign/${accountId}/ad/${adId}`);
    return data.data;
  },
  getAdsAccountChannels: async (accountId: string) => {
    const { data } = await api.get<{ data: AccountChannelType[] }>(`/campaign/${accountId}/channel`);
    return data.data;
  },
  createSlideShow: async (accountId: string, body: { images_urls: string[] }) => {
    const { data } = await api.post(`/campaign/${accountId}/slideshow`, body);
    return data.data.id;
  },
  createLeads: async (body: LeadFormResponse, accountId: string) => {
    const { data } = await api.post(`/campaign/${accountId}/leadform`, body);
    return data.data;
  },
  deleteCampaign: async ({ accountId, campaignId }: { accountId: string; campaignId: string }) => {
    const { data } = await api.delete(`/campaign/${accountId}/${campaignId}`);
    return data.data;
  },
  deleteAdset: async ({ accountId, adsetId }: { accountId: string; adsetId: string }) => {
    const { data } = await api.delete(`/campaign/${accountId}/adset/${adsetId}`);
    return data.data;
  },
  deleteAds: async ({ accountId, adId }: { accountId: string; adId: string }) => {
    const { data } = await api.delete(`/campaign/${accountId}/ad/${adId}`);
    return data.data;
  },
  copyAds: async (accountId: string, body: AdsCopyData) => {
    const { data } = await api.post(`/campaign/${accountId}/ad/copy`, body);
    return data.data;
  },
  getLeadsFormList: async (accountId: string) => {
    const { data } = await api.get<{ data: { id: string; name: string }[] }>(
      `/campaign/${accountId}/leadform?isMessangerEligible=true`,
    );
    return data.data;
  },
};

export const InsightApi = {
  getInsights: async (filters: TGetInsightsParams) => {
    const { data } = await api.get<{
      data: InsightResponse;
    }>(`/insight`, {
      params: filters,
    });
    return data.data;
  },
  getInsightPosts: async (filters: TGetInsightPostsParams) => {
    const { data } = await api.get<{
      data: { items: PostWithInsight[]; total: number };
    }>(`/insight/post`, {
      params: filters,
    });

    return data.data;
  },
  getInsightConsumption: async (filters: TGetInsightConsumptionParams) => {
    const { data } = await api.get<{
      data: {
        facebook: {
          page_consumptions_by_consumption_type: {
            month: 8;
            year: 2024;
            value: {
              "link clicks"?: number;
              "other clicks"?: number;
              "photo view"?: number;
              "video play"?: number;
            };
          }[];
        };
        instagram: [];
      };
    }>(`/insight/consumption`, {
      params: filters,
    });
    return data.data;
  },
  getDemographic: async (filters: TInsightDemographicParams) => {
    const { data } = await api.get<{
      data: {
        facebook: {
          page_fans_country: [
            {
              key: string;
              value: Record<string, number>;
            },
          ];
        };
        instagram: {
          reached_audience_demographics: [
            {
              key: "country";
              value: Record<string, number>;
            },
          ];
        };
      };
      message: string;
    }>(`/insight/demographic`, {
      params: filters,
    });
    return data.data;
  },
  getFacebookPostsInsights: async (accountId: string, since?: string, until?: string, dateRange?: string) => {
    const last90Days = await api.get<{ data: InsightsData }>(`/insight/${accountId}?date_preset=${dateRange}`);
    const prior90Days = await api.get<{ data: InsightsData }>(`/insight/${accountId}?since=${since}&until=${until}`);

    const [lastData, priorData] = await Promise.all([last90Days, prior90Days]);
    const mergedData = [lastData.data.data, priorData.data.data];
    return mergedData;
  },
  getPostsInsight: async (accountId: string, postId: string) => {
    const { data } = await api.get<{ data: PostInsightData }>(`/insight/${accountId}/post/${postId}`);
    return data.data;
  },
  getPostsConsumptionInsights: async (accountId: string, since?: string, until?: string, dateRange?: string) => {
    const last90Days = await api.get<{ data: InsightsData }>(
      `/insight/${accountId}/consumption?date_preset=${dateRange}`,
    );
    const prior90Days = await api.get<{ data: InsightsData }>(
      `/insight/${accountId}/consumption?since=${since}&until=${until}`,
    );

    const [lastData, priorData] = await Promise.all([last90Days, prior90Days]);
    const mergedData = [lastData.data.data, priorData.data.data];
    return mergedData;
  },
};

export const TodosAPI = {
  create: async (accountId: string, body: TodoFormData) => {
    let files;
    if (body.files) {
      files = await ImageApi.upload(body.files, accountId);
    }
    return api.post(`/todo`, { accountId, ...body, files });
  },
  list: async (filters: TGetTodosParams) => {
    const { data } = await api.get<{ data: TodoResponse }>(`/todo`, {
      params: filters,
    });
    return data.data;
  },
  get: async (accountId: string, todoId: string) => {
    const { data } = await api.get<{ data: Todo }>(`/todo/${todoId}`);
    return data.data;
  },
  updateStatus: async (todoId: string, status: TODO_STATUS, accountId: string, companyId?: string) => {
    const { data } = await api.patch(`/todo/${todoId}`, { status, accountId, companyId });
    return data.data;
  },
  delete: async (todoId: string) => {
    const { data } = await api.delete(`/todo/${todoId}`);
    return data.data;
  },
  update: async (accountId: string, todoId: string, body: TodoFormData) => {
    let files;
    if (body.files) {
      files = await ImageApi.upload(body.files, accountId);
    }
    const { data } = await api.patch(`/todo/${todoId}`, { accountId, ...body, files });
    return data.data;
  },
};

export const AdAccountsAPI = {
  getAll: async (accountId: string) => {
    const { data } = await api.get<{ data: any[] }>(`/account/${accountId}/ad-accounts`);
    return data.data;
  },
  updateAdAccount: async (accountId: string, adAccountId: string, body: AdAccountDetail) => {
    const { data } = await api.patch(`/account/${accountId}/ad-accounts/${adAccountId}`, body);
    return data.data;
  },
  deleteAdAccount: async (accountId: string, adAccountId: string) => {
    const { data } = await api.delete(`/account/${accountId}/ad-accounts/${adAccountId}`);
    return data.data;
  },
};

export const NotificationAPI = {
  getUnreadCount: async (accountId: string, platform: DevicePlatform) => {
    const { data } = await api.get<{ data: { unreadCount: number } }>(
      `/notification/count?accountId=${accountId}&platform=${platform}`,
    );
    return data.data;
  },
  getAll: async (accountId: string, platform: DevicePlatform, skip: number, take: number) => {
    const { data } = await api.get<{ data: { notifications: NotificationData[]; total: number } }>(
      `/notification?accountId=${accountId}&platform=${platform}&skip=${skip}&take=${take}`,
    );
    return data.data;
  },
  updateNotificationStatus: async (status: NotificationStatusType, notificationIds: string[]) => {
    const { data } = await api.patch(`/notification`, { status, notificationIds });
    return data;
  },
};

export const NotificationApi = {
  getUnreadCount: async (params: TGetUnreadNotificationsCountParams) => {
    const { data } = await api.get<{ data: { unreadCount: number } }>(`/notification/count`, {
      params,
    });
    return data.data;
  },
  list: async (params: TListNotificationsParams) => {
    const { data } = await api.get<{ data: { items: NotificationData[]; total: number } }>(`/notification`, {
      params,
    });
    return data.data;
  },
  updateNotificationStatus: async (input: UpdateNoficationData) => {
    const { data } = await api.patch(`/notification`, input);
    return data;
  },
};
