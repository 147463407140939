import { Spin } from "antd";
import { useState } from "react";
import useTodos from "hooks/todos/useTodos";
import useSelectedAccount from "hooks/useSelectedAccount";
import { usePagination } from "hooks/usePagination";
import TodosContainer from "../TableContainer";
import { TodosTableFilters } from "../TodosTable";

export default function AllTodos() {
  const [searchText, setSearchText] = useState<string>("");

  const { selectedAccountId: accountId, selectedCompanyId: companyId } = useSelectedAccount({ companyRequired: false });

  const paginationState = usePagination(10);
  const [filters, setFilters] = useState<TodosTableFilters>({});

  const { data: todosData, isFetching } = useTodos({
    accountId,
    companyIds: filters.companyId
      ? filters.companyId.flatMap((item) => (item === "None" ? ["null"] : [item]))
      : companyId
        ? [companyId, "null"]
        : undefined,
    status: filters.status,
    priority: filters.priority,
    contains: searchText,
    skip: paginationState.skip,
    take: paginationState.take,
  });

  return (
    <Spin spinning={isFetching}>
      <TodosContainer
        data={todosData?.todos ?? []}
        total={todosData?.total ?? 0}
        openCount={todosData?.count?.OPEN ?? 0}
        doneCount={todosData?.count?.DONE ?? 0}
        progressCount={todosData?.count?.IN_PROGRESS ?? 0}
        setSearchText={setSearchText}
        paginationState={paginationState}
        filters={filters}
        setFilters={setFilters}
      />
    </Spin>
  );
}
