import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Row, Select, Spin, Tabs, message } from "antd";
import React, { useContext, useEffect, useMemo } from "react";
import type { TabsProps } from "antd";
import useCompany from "hooks/company/useCompany";
import useUpdateCompany from "hooks/company/useUpdateCompany";
import { AxiosError } from "axios";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import NotAllowed from "components/NotAllowed";
import { useTranslation } from "react-i18next";
import useSelectedAccount from "hooks/useSelectedAccount";
import { makeSubject } from "utils/access";
import CompanyChannels from "./Channels";
import CompanyUsers from "./Users";

const CompanyDetails = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation(["common", "pages"]);
  const { ability, isLoading: isLoadingPermissions } = useContext(UserAccessPolicyContext);
  const { selectedAccountId } = useSelectedAccount();

  const [form] = Form.useForm();
  const canReadCompanyDetails = ability.can(
    "read",
    makeSubject("Company", { accountId: selectedAccountId, companyId }),
    "name",
  );
  const canUpdateCompanyDetails = ability.can(
    "update",
    makeSubject("Company", { accountId: selectedAccountId, companyId }),
    "name",
  );
  const canViewConnectedUsers = ability.can(
    "read",
    makeSubject("Company", { accountId: selectedAccountId, companyId }),
    "userIds",
  );
  const canViewConnectedChannels = ability.can(
    "read",
    makeSubject("Company", { accountId: selectedAccountId, companyId }),
    "channelIds",
  );

  const { data, isLoading: isLoadingCompany } = useCompany(companyId, {
    include:
      canViewConnectedUsers && canViewConnectedChannels
        ? ["users", "channels"]
        : canViewConnectedUsers
          ? ["users"]
          : canViewConnectedChannels
            ? ["channels"]
            : [],
  });
  const company = data;

  const items: TabsProps["items"] = useMemo(
    () =>
      company
        ? [
            {
              key: "1",
              label: t("pages:user.title"),
              children: canViewConnectedUsers ? (
                <CompanyUsers companyId={company.id} users={company.users ?? []} isLoading={isLoadingCompany} />
              ) : (
                <NotAllowed />
              ),
            },
            {
              key: "2",
              label: t("pages:channel.title"),
              children: canViewConnectedChannels ? (
                <CompanyChannels
                  companyId={company.id}
                  channels={company.channels ?? []}
                  isLoading={isLoadingCompany}
                />
              ) : (
                <NotAllowed />
              ),
            },
          ]
        : [],
    [company, isLoadingCompany, canViewConnectedChannels, canViewConnectedUsers, t],
  );

  const { mutate: editCompany, isLoading: isUpdatingCompany } = useUpdateCompany({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      message.success(t("pages:companies.updateSuccessMessage"));
    },
  });

  useEffect(() => {
    if (isLoadingCompany) return;
    form.resetFields();
  }, [form, companyId, isLoadingCompany]);

  return (
    <>
      {isLoadingCompany || isLoadingPermissions ? (
        <Spin />
      ) : canReadCompanyDetails ? (
        company ? (
          <Card
            style={{ flex: 1 }}
            title={
              <>
                <Tabs
                  defaultActiveKey="company-details"
                  items={[
                    {
                      key: "company-details",
                      label: t("pages:companies.detailsLabel"),
                      children: (
                        <Form
                          style={{ marginBottom: "1rem" }}
                          form={form}
                          layout="horizontal"
                          onFinish={async (data) => {
                            if (!companyId) return;
                            data.name = data.name.trim();
                            editCompany({ companyId, updatedFields: data });
                          }}
                          initialValues={company}
                          disabled={isUpdatingCompany}
                        >
                          <Form.Item
                            label={t("pages:companies.nameLabel")}
                            name="name"
                            labelCol={{ xl: 8, lg: 24, md: 10, sm: 24, xs: 24 }}
                            labelAlign="left"
                          >
                            <Input
                              data-testid="company-name-field"
                              disabled={!canUpdateCompanyDetails}
                              maxLength={40}
                            />
                          </Form.Item>

                          <Form.Item
                            label={t("pages:postingApproval.title")}
                            name="requiresPostReview"
                            labelCol={{ xl: 8, lg: 24, md: 10, sm: 24, xs: 24 }}
                            labelAlign="left"
                          >
                            <Select
                              style={{ flex: 1 }}
                              disabled={!canUpdateCompanyDetails}
                              options={[
                                { value: true, label: t("pages:postingApproval.requiredLabel") },
                                { value: false, label: t("pages:postingApproval.notRequiredLabel") },
                              ]}
                            />
                          </Form.Item>

                          {canUpdateCompanyDetails ? (
                            <Row justify="end">
                              <Button onClick={() => form.setFieldsValue(company)} style={{ marginRight: 10 }}>
                                {t("buttons.discard")}
                              </Button>
                              <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                disabled={isLoadingCompany || isUpdatingCompany}
                                loading={isUpdatingCompany}
                                data-testid="user-invite-btn"
                              >
                                {t("buttons.save")}
                              </Button>
                            </Row>
                          ) : null}
                        </Form>
                      ),
                    },
                  ]}
                />
              </>
            }
          >
            <div style={{ padding: "0.5rem 1rem" }}>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </Card>
        ) : null
      ) : (
        <NotAllowed />
      )}
    </>
  );
};

export default CompanyDetails;
