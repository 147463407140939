import { Modal, Button, message } from "antd";
import { useMutation } from "react-query";
import { CampaignApi, PostApi } from "services/api";
import { DeleteModalProps, Account } from "types";
import { AxiosError } from "axios";
import useQueryCacheData from "hooks/useQueryCacheData";
import classes from "./DeleteModal.module.css";
import "../../../../styles/modal.css";
import "../../../../styles/icon.css";

function DeleteModal({ visible, handleCloseModal, currentText, currentIds, setSelectedCheckbox }: DeleteModalProps) {
  const accounts = useQueryCacheData<Account[]>("accounts");
  const { mutate, isLoading } = useMutation(
    "delete",
    () => {
      if (currentText === "Beitrag") {
        // return PostApi.removePost(id);
        return Promise.all(
          currentIds.map((id) => {
            return PostApi.removePost(id);
          }),
        );
      }
      if (currentText === "Adset") {
        // return CampaignApi.deleteAdset({ accountId: accounts[0].id, adsetId: id });
        return Promise.all(
          currentIds.map((id) => {
            return CampaignApi.deleteAdset({ accountId: accounts[0].id, adsetId: id });
          }),
        );
      }
      if (currentText === "Ad") {
        // return CampaignApi.deleteAds({ accountId: accounts[0].id, adId: id });
        return Promise.all(
          currentIds.map((id) => {
            return CampaignApi.deleteAds({ accountId: accounts[0].id, adId: id });
          }),
        );
      }
      // return CampaignApi.deleteCampaign({ accountId: accounts[0].id, campaignId: id });
      return Promise.all(
        currentIds.map((id) => {
          return CampaignApi.deleteCampaign({
            accountId: accounts[0].id,
            campaignId: id,
          });
        }),
      );
    },
    {
      onSuccess() {
        message.success(`${currentText} wurde erfolgreich gelöscht!`);
        if (setSelectedCheckbox) {
          setSelectedCheckbox([]);
        }
        handleCloseModal();
      },
      onError(error: unknown) {
        if (error instanceof AxiosError) {
          message.error(error.response?.data?.message ?? "Ups, das Löschen hat nicht geklappt!");
        } else {
          console.error(error);
        }
        handleCloseModal();
      },
    },
  );
  return (
    <div>
      <Modal
        onCancel={handleCloseModal}
        open={visible}
        title={
          <div style={{ paddingRight: "10px" }}>
            <h3>Bist du dir sicher, dass du diesen {currentText} löschen möchtest?</h3>
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="default" className={classes.cancelButton} onClick={() => handleCloseModal()}>
              Nein
            </Button>
            <Button type="primary" danger className={classes.createButton} onClick={() => mutate()} loading={isLoading}>
              Ja
            </Button>
          </div>
        }
      />
    </div>
  );
}

export default DeleteModal;
