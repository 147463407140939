import useAdmin from "hooks/useAdmin";
import { useQuery } from "react-query";
import { UserApi } from "services/api";
import { UserQueryKeys } from "services/query-keys";

export type TListUsersParams = {
  skip?: number;
  take?: number;
  accountIds?: string[];
  companyIds?: string[];
  verified?: boolean;
  contains?: string;
  include?: ("account" | "users" | "channels" | "posts")[];
};

const useUsers = <TReturn = Awaited<ReturnType<typeof UserApi.list>>>(
  filters?: TListUsersParams,
  options: {
    enabled: boolean;
    selectFn?: (res: Awaited<ReturnType<typeof UserApi.list>>) => TReturn;
  } = {
    enabled: true,
  },
) => {
  const { isPlatformAdmin } = useAdmin();

  return useQuery({
    queryKey: UserQueryKeys.list(filters),
    queryFn: async () => {
      return UserApi.list(filters);
    },
    select: options?.selectFn,
    initialData: { items: [], total: 0 },
    initialDataUpdatedAt: 0,
    enabled: isPlatformAdmin && options.enabled,
    refetchOnWindowFocus: true,
  });
};

export default useUsers;
