import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Input, Popconfirm } from "antd";
import useDeletePostReviewComment from "hooks/PostReviewComment/useDeletePostReviewComment";
import useEditPostReviewComment from "hooks/PostReviewComment/useEditPostReviewComment";
import useMe from "hooks/useMe";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PostReviewComment } from "types";
import { convertUTCDateToLocal } from "utils/date";

import CustomAvatar from "components/CustomAvatar";
import classes from "./index.module.css";

const EditCommentForm = ({
  comment,
  onFinish,
  disabled = false,
  onDiscard,
}: {
  comment: PostReviewComment;
  onFinish: (values: { message: string }) => void;
  onDiscard: () => void;
  disabled?: boolean;
}) => {
  const [form] = Form.useForm<{ message: string }>();

  const { t } = useTranslation(["common", "pages"]);

  return (
    <Form className={classes.editCommentForm} onFinish={onFinish} form={form} disabled={disabled}>
      <div className={classes.editCommentContainer}>
        <Form.Item
          name="message"
          rules={[{ required: true, max: 2400 }]}
          style={{ margin: 0 }}
          initialValue={comment.message}
        >
          <Input.TextArea style={{ width: "100%" }} placeholder={t("pages:comments.commentsPlaceholder")} />
        </Form.Item>
      </div>
      <div className={classes.editCommentFooter}>
        <Button disabled={disabled} onClick={onDiscard}>
          {t("common:buttons.discard")}
        </Button>
        <Button type="primary" disabled={disabled} htmlType="submit">
          {t("common:buttons.save")}
        </Button>
      </div>
    </Form>
  );
};

const Comment = ({ comment }: { comment: PostReviewComment }) => {
  const [isEditing, setIsEditing] = useState(false);

  const { mutate: deleteComment, isLoading: isDeletingComment } = useDeletePostReviewComment();

  const { mutate: editComment, isLoading: isEditingComment } = useEditPostReviewComment({
    onSuccess: () => {
      setIsEditing(false);
    },
  });

  const { data: me } = useMe();

  const { t } = useTranslation(["common", "pages"]);

  const formattedDate = useMemo(() => {
    if (!comment.createdAt) return "Unknown Date";
    return convertUTCDateToLocal(comment.createdAt).format("DD MMM YYYY, HH:mm");
  }, [comment.createdAt]);

  const isEdited = useMemo(() => {
    if (!comment.updatedAt || !comment.createdAt) return false;
    return comment.updatedAt > comment.createdAt;
  }, [comment.createdAt, comment.updatedAt]);

  return (
    <div className={classes.comment}>
      <CustomAvatar src={comment.user?.user_profile_pic} alt={`${comment.user?.name ?? "User"}'s profile picture`}>
        {comment.user?.name?.[0] ?? "U"}
      </CustomAvatar>

      <div className={classes.commentContainer}>
        <div className={classes.commentHeader}>
          <span className={classes.commentUsername}>{comment.user?.name ?? t("common:placeholders.unknownUser")}</span>
          <span className={classes.commentCreatedDate}>
            {formattedDate}
            {isEdited ? ` (Bearbeitet)` : null}
          </span>
          {!isEditing && comment.userId === me?.id ? (
            <div className={classes.commentDropdown}>
              <Dropdown
                menu={{
                  items: [
                    { key: "edit", label: t("common:buttons.edit"), disabled: isEditingComment },
                    {
                      disabled: isDeletingComment,
                      key: "delete",
                      label: (
                        <Popconfirm
                          overlayClassName={classes.deleteCommentConfirmationOverlay}
                          title={t("pages:approvalCenter.deleteCommentConfirmation")}
                          okButtonProps={{
                            loading: isDeletingComment,
                            className: classes.confirmDeleteCommentButton,

                            style: {
                              height: "42px !important",
                              background: "blue !important",
                            },
                          }}
                          onConfirm={() => {
                            deleteComment({
                              id: comment.id,
                            });
                          }}
                        >
                          {t("common:buttons.delete")}
                        </Popconfirm>
                      ),
                    },
                  ],
                  onClick: ({ key }) => {
                    if (key === "edit") {
                      setIsEditing(true);
                    }
                  },
                }}
                trigger={["click"]}
              >
                <EllipsisOutlined />
              </Dropdown>
            </div>
          ) : null}
        </div>
        {!isEditing ? (
          <p className={classes.commentMessage}>{comment.message}</p>
        ) : (
          <EditCommentForm
            comment={comment}
            onDiscard={() => setIsEditing(false)}
            onFinish={(values) => {
              editComment({ id: comment.id, ...values });
            }}
            disabled={isEditingComment}
          />
        )}
      </div>
    </div>
  );
};

export default Comment;
