import io from "socket.io-client";
import { SocketPayload } from "types";

export const socket = io(process.env.REACT_APP_WORKER_ENDPOINT as string, {
  transports: ["websocket"],
});

export const listenToEvent = (event: string, callback: (payload: SocketPayload) => void) => {
  return socket.on(event, (payload: SocketPayload) => callback(payload));
};

export const stopListeningToEvent = (event: string) => socket.off(event);
