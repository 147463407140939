import { Row, Select, Typography } from "antd";
import { Channel, CreatePost } from "types";
import { useContext, useMemo } from "react";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useCompany from "hooks/company/useCompany";
import { makeSubject } from "utils/access";
import FacebookIcon from "../../../../assets/icons/socials/facebookColored.svg";
import InstagramIcon from "../../../../assets/icons/socials/instagramColored.svg";
import classes from "./Account.module.css";

export type AccountsComponentProps = {
  localPost: CreatePost;
  setLocalPost: (localPost: CreatePost) => void;
};

const AccountsComponent = (props: AccountsComponentProps) => {
  const { localPost, setLocalPost } = props;
  const { selectedCompanyId: companyId, selectedAccountId: accountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);

  const canManagePosts = ability.can("update", "Post");

  const { data, isLoading } = useCompany(companyId, {
    include: canManagePosts ? ["channels"] : [],
  });

  const channels: Channel[] = useMemo(() => {
    if (!data?.channels) return [];

    return data.channels.filter((channel) =>
      ability.can(
        "update",
        makeSubject("Post", {
          accountId,
          companyId,
          channelId: channel.id,
        }),
      ),
    );
  }, [accountId, companyId, data, ability]);

  return (
    <>
      <Row className={classes.channelSelectLabel}>
        <Typography.Text>Kanal auswählen</Typography.Text>
      </Row>
      <Row>
        <Select
          loading={isLoading}
          disabled={Boolean(localPost.isEdit)}
          className={classes.select}
          placeholder="Plattform auswählen"
          mode="multiple"
          allowClear
          value={localPost.platforms}
          onChange={(selected) =>
            setLocalPost({
              ...localPost,
              platforms: selected,
            })
          }
          showSearch={false}
          options={
            localPost.accountId
              ? channels.map((item) => ({
                  label: (
                    <span className={classes.channelListItem}>
                      {item.type === "FACEBOOK" ? (
                        <img src={FacebookIcon} alt="facebook" style={{ marginRight: 7, height: 18, width: 18 }} />
                      ) : (
                        <img src={InstagramIcon} alt="instagram" style={{ marginRight: 7, height: 18, width: 18 }} />
                      )}
                      {item.name} {item.accessStatus === "UNHEALTHY" ? "(Inaktiv)" : ""}
                    </span>
                  ),
                  value: `${item.id}__${item.type}`,
                  disabled: item.accessStatus === "UNHEALTHY",
                }))
              : []
          }
          popupClassName={classes.selectPopup}
          data-testid="post-select-channel-dropdown"
        />
      </Row>
    </>
  );
};

export default AccountsComponent;
