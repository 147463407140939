import React, { useRef, useState, useEffect } from "react";
import { Dropdown, InputNumber, Button, Slider, Menu } from "antd";
import { CaretDownOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { MapContainer, TileLayer, Marker, Popup, Circle, FeatureGroup, ZoomControl } from "react-leaflet";
import L from "leaflet";
import type { SliderMarks } from "antd/es/slider";
import { CustomMarkerProps } from "types";
import location from "../../../../../../assets/marker.png";
import CustomMarker from "./CustomMarker/index";
import "./Map.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "../../../../../../styles/input.css";
import "../../../../../../styles/dropdown.css";

const marks: SliderMarks = {
  1: "1",
  50: "50",
};

const iconMarker = new L.Icon({
  iconUrl: location,
  iconSize: [20, 40],
});

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

export default function Map({ markerPosition, setMarkerPosition }: CustomMarkerProps) {
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState<number>(0);
  const [searchLocation, setSearchLocation] = useState("");
  const [userLocation, setUserLocation] = useState(true);
  const [inputValues, setInputValues] = useState<number[]>([10]);
  const [zoom, setZoom] = useState<number>(5);
  const mapRef = useRef<L.Map | null>(null);
  const circleRef = useRef<(L.Circle | null)[]>([]);

  const updateRadius = (index: number, value: number) => {
    setMarkerPosition((prevMarkerPosition) => {
      const updatedPositions = [...prevMarkerPosition];

      if (index >= 0 && index < updatedPositions.length) {
        // Update the radius of the object at the specified index
        updatedPositions[index] = {
          ...updatedPositions[index],
          radius: value,
        };
      }

      return updatedPositions;
    });
  };

  useEffect(() => {
    const map = mapRef.current;

    if (map && circleRef.current?.length > 0) {
      const circle = circleRef?.current[currentMarkerIndex];
      const bounds = circle ? circle.getBounds() : [];
      map.fitBounds(bounds);
      setZoom(map.getZoom());
    }
    // eslint-disable-next-line
  }, [currentMarkerIndex, inputValues]);

  const handleClickLocation = (lat: number, lng: number) => {
    const map = mapRef.current;
    if (map) {
      const coordinates = {
        lat,
        lng,
      };
      setCurrentMarkerIndex(markerPosition.findIndex((coordinate) => coordinate.lat === coordinates.lat));
      map.flyTo([coordinates.lat, coordinates.lng], 12, {
        duration: 2,
      });
    }
  };

  const onChange = (index: number, newValue: number) => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue; // Update the value at the specified index
      return updatedValues;
    });

    updateRadius(index, newValue);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "100%" }}>
            <Slider
              marks={marks}
              min={1}
              max={50}
              onChange={(value) => onChange(currentMarkerIndex, value)}
              value={typeof inputValues[currentMarkerIndex] === "number" ? inputValues[currentMarkerIndex] : 10}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <InputNumber
              min={1}
              max={50}
              style={{ margin: "0 16px" }}
              value={inputValues[currentMarkerIndex] ? inputValues[currentMarkerIndex] : 10}
              size="small"
              step="false"
              onChange={(value) => (value ? onChange(currentMarkerIndex, value) : null)}
            />
            <span>mi</span>
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  // eslint-disable-next-line
  function LocationMarker() {
    // const map = useMapEvents({
    //   click(e) {
    //     // eslint-disable-next-line
    //     // !markerOnScreen && userLocation ? map.locate() : null;
    //   },
    //   locationfound(e) {
    //     setMarkerPosition((prevMarkerPosition) => [
    //       ...prevMarkerPosition,
    //       { lat: e.latlng.lat, lng: e.latlng.lng, radius: 10 },
    //     ]);
    //     setUserLocation(false);
    //     map.flyTo(e.latlng, 12, {
    //       duration: 3
    //     })
    //   },
    // })

    return (
      <>
        {markerPosition.map((marker, index) => (
          <>
            <Marker position={marker}>
              <Popup>
                {marker.lat} , {marker.lng}
              </Popup>
            </Marker>
            <Circle
              center={marker}
              radius={marker.radius * 300}
              fillColor="blue"
              ref={(circleInstance) => {
                circleRef.current[index] = circleInstance;
              }}
            />
          </>
        ))}
      </>
    );
  }

  return (
    <div style={{ background: "#f3f3f3" }}>
      {markerPosition
        ? markerPosition.map((coordinates, index) => (
            <div
              // eslint-disable-next-line
              key={index}
              style={{
                border: "1px solid rgb(220, 217, 217)",
                borderRadius: 8,
                background: "white",
                padding: 5,
                display: "flex",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <div style={{ marginRight: "16px", width: "40%" }}>
                <EnvironmentOutlined style={{ fontSize: 16, color: "lightgreen" }} />
                <Button className="locationBtn" onClick={() => handleClickLocation(coordinates.lat, coordinates.lng)}>
                  ({coordinates.lat.toPrecision(5)},{coordinates.lng.toPrecision(5)})
                </Button>
              </div>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                onOpenChange={() => handleClickLocation(coordinates.lat, coordinates.lng)}
              >
                <div className="radiusButton">
                  <span style={{ marginRight: 5 }}>+{coordinates.radius} mi</span>
                  <CaretDownOutlined />
                </div>
              </Dropdown>
            </div>
          ))
        : ""}
      {/* <div>
        <input type="text" value={searchLocation} onChange={handleSearchLocation} placeholder="Enter a location" />
        <Button onClick={searchLocationOnMap}>Search</Button>
      </div> */}
      <MapContainer
        center={[52.535152, 13.390206]}
        doubleClickZoom
        zoom={zoom}
        zoomControl={false}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <CustomMarker setMarkerPosition={setMarkerPosition} mapRef={mapRef} />
        <FeatureGroup>
          <ZoomControl position="topright" />
        </FeatureGroup>
        <TileLayer attribution="" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LocationMarker />
        {/* <ReactLeafletSearch /> */}
      </MapContainer>
    </div>
  );
}
