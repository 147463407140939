import { Row } from "antd";
import useSelectedAccount from "hooks/useSelectedAccount";
import NotFound from "pages/NotFound";
import CompanyDetails from "pages/Settings/Companies/Company";
import { useTranslation } from "react-i18next";

const CompanySettings = () => {
  const { t } = useTranslation("pages");
  const { selectedCompanyId: companyId } = useSelectedAccount();

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <h1 className="heading">{t("companies.settingsTitle")}</h1>
      </Row>

      <Row>{companyId ? <CompanyDetails companyId={companyId} /> : <NotFound type="resource" />}</Row>
    </div>
  );
};

export default CompanySettings;
