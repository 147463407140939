import useCompanies from "hooks/company/useCompanies";
import { useContext, useState } from "react";
import { Company } from "types";
import { Avatar, Card, Col, Dropdown, Image, List, Modal, Row, message } from "antd";
import { EllipsisOutlined, PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useDeleteCompany from "hooks/company/useDeleteCompany";
import { AxiosError } from "axios";
import NotAllowed from "components/NotAllowed";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";
import NoDataLocaleTable from "components/NoData";
import CustomAvatar from "components/CustomAvatar";
import CompanySection from "../../../assets/CompanyCreate.svg";
import CompanyDetails from "./Company";
import { CreateCompanyForm } from "./components/CreateCompanyModal";
import DeleteCompanyModal, { TDeleteDialogState } from "./components/DeleteCompanyModal";
import classes from "./index.module.css";

const Companies = () => {
  const { t } = useTranslation(["common", "pages"]);
  const [companyIdToEdit, setCompanyIdToEdit] = useState<string | undefined>();
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState<TDeleteDialogState>({
    open: false,
    forceDelete: false,
    companyToDelete: undefined,
    message: undefined,
  });

  const { selectedAccountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);

  const canDeleteCompany = ability.can("delete", makeSubject("Company", { accountId: selectedAccountId }));
  const canViewCompanies = ability.can("read", "Company");
  const canCreateCompany = ability.can("create", makeSubject("Company", { accountId: selectedAccountId }));

  const closeDeleteDialog = () => {
    setDeleteDialogState((curr) => ({ ...curr, message: undefined, open: false, companyToDelete: undefined }));
  };

  const [canForceDeleteCompanyIds, setCanForceDeleteCompanyIds] = useState([] as string[]);

  const { mutate: deleteCompany, isLoading: isDeletingCompany } = useDeleteCompany({
    onError: (e, { companyId }) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (e.response?.status && (e.response.status === 412 || e.response.status === 424)) {
          setCanForceDeleteCompanyIds((prev) => [...prev, companyId]);
          setDeleteDialogState((prev) => ({
            ...prev,
            message: t("pages:companies.deleteError"),
          }));
        }

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: (_, { companyId }) => {
      closeDeleteDialog();
      if (companyIdToEdit === companyId) setCompanyIdToEdit(undefined);
      message.success(t("pages:companies.deleteSuccessMessage"));
      setCanForceDeleteCompanyIds((prev) => prev.filter((id) => id !== companyId));
    },
  });

  const { data, isFetching: isFetchingCompanies } = useCompanies(
    {
      accountId: selectedAccountId,
    },
    {
      enabled: !!selectedAccountId && canViewCompanies,
    },
  );

  const companies = data?.items ?? [];

  return (
    <>
      {canViewCompanies ? (
        <>
          <Row gutter={12}>
            <Col xl={6} lg={8} md={24} sm={24} xs={24} style={{ marginBottom: "0.5rem" }}>
              <Card>
                <List
                  dataSource={companies}
                  loading={isFetchingCompanies}
                  locale={NoDataLocaleTable}
                  header={
                    canCreateCompany ? (
                      <div className={classes.createCompanyWrapper} onClick={() => setShowCreateCompanyModal(true)}>
                        <Avatar icon={<PlusOutlined />} className={classes.createCompanyBtn} />
                        {t("pages:companies.createButton")}
                      </div>
                    ) : (
                      <div className={classes.createCompanyWrapper} style={{ textAlign: "center" }}>
                        {t("pages:companies.title")}
                      </div>
                    )
                  }
                  renderItem={(item: Company, index: number) => (
                    <div
                      className={classes.listCompanyItem}
                      style={{
                        ...(index !== companies.length - 1 && { borderBottom: `1px solid var(--btn-secondary-bg)` }),
                        ...(companyIdToEdit === item.id && { background: "var(--btn-secondary-bg)" }),
                      }}
                    >
                      <div className={classes.listCompanyItemBody} onClick={() => setCompanyIdToEdit(item.id)}>
                        {item.profilePic ? (
                          <CustomAvatar src={item.profilePic} alt={`${item.name ?? "Unternehmen"}'s profile picture`}>
                            {item.name?.[0] ?? "U"}
                          </CustomAvatar>
                        ) : (
                          <Avatar icon={<UsergroupAddOutlined />} style={{ marginRight: "0.5rem", flexShrink: 0 }} />
                        )}
                        <span className={classes.listCompanyItemName}>{item.name}</span>
                      </div>
                      {canDeleteCompany ? (
                        <Dropdown
                          menu={{
                            items: [
                              { label: t("pages:companies.deleteButton"), key: "delete" },
                              {
                                label: t("pages:companies.forceDeleteButton"),
                                key: "force-delete",
                                disabled: !canForceDeleteCompanyIds.includes(item.id),
                              },
                            ],
                            onClick: ({ key }) => {
                              if (key === "delete") {
                                setDeleteDialogState((prev) => ({
                                  ...prev,
                                  open: true,
                                  forceDelete: false,
                                  companyToDelete: item,
                                }));
                              } else if (key === "force-delete") {
                                setDeleteDialogState((prev) => ({
                                  ...prev,
                                  open: true,
                                  forceDelete: true,
                                  companyToDelete: item,
                                }));
                              }
                            },
                          }}
                          placement="bottomRight"
                        >
                          <span>
                            <EllipsisOutlined data-testid="user-three-dots" />
                          </span>
                        </Dropdown>
                      ) : null}
                    </div>
                  )}
                />
              </Card>
            </Col>
            <Col xl={18} lg={16} md={24} sm={24} xs={24}>
              {companyIdToEdit ? (
                <CompanyDetails companyId={companyIdToEdit} />
              ) : (
                <Card>
                  <div className={classes.selectCompanyCard}>
                    <h2 className={classes.selectCompanyText}>{t("pages:companies.chooseTitle")}</h2>
                    <Image src={CompanySection} width={300} preview={false} />
                  </div>
                </Card>
              )}
            </Col>
          </Row>
          <Modal
            title={t("pages:companies.createButton")}
            open={showCreateCompanyModal}
            onCancel={() => setShowCreateCompanyModal(false)}
            footer={null}
            destroyOnClose
          >
            <CreateCompanyForm onCancel={() => setShowCreateCompanyModal(false)} accountId={selectedAccountId} />
          </Modal>
          <DeleteCompanyModal
            deleteDialogState={deleteDialogState}
            deleteCompany={deleteCompany}
            isDeletingCompany={isDeletingCompany}
            closeDeleteDialog={closeDeleteDialog}
          />
        </>
      ) : (
        <NotAllowed />
      )}
    </>
  );
};

export default Companies;
