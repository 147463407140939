import { useInfiniteQuery, UseQueryOptions } from "react-query";
import { NotificationApi } from "services/api";
import { NotificationQueryKeys } from "services/query-keys";
import { DevicePlatform } from "types";

export type TListNotificationsParams = {
  accountId: string;
  platform: DevicePlatform;
  skip?: number;
  take?: number;
  companyId?: string;
};

type ListNotificationsResult = Awaited<ReturnType<typeof NotificationApi.list>>;

const useNotifications = (filters: TListNotificationsParams, options?: UseQueryOptions<ListNotificationsResult>) => {
  return useInfiniteQuery({
    queryKey: NotificationQueryKeys.list(filters),
    queryFn: async ({ pageParam }) => {
      return NotificationApi.list({ ...filters, skip: pageParam ?? 0 });
    },
    getNextPageParam: (lastPage, allPages) => {
      const sum = allPages.reduce((acc, page) => acc + page.items.length, 0);
      return sum < lastPage.total ? sum : undefined;
    },
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.platform,
  });
};

export default useNotifications;
