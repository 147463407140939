import React, { useMemo } from "react";
import formatNumber from "utils/formatNumber";
import classes from "./index.module.css";

const defaultFormatter = (number: number | string) =>
  formatNumber(number, { maximumFractionDigits: 0, minimumFractionDigits: 0 });

const ChartStat = React.forwardRef(
  ({
    text,
    value,
    icon,
    formatValue = defaultFormatter,
  }: {
    text?: React.ReactNode;
    value?: number | string;
    icon?: React.ReactNode;
    formatValue?: (num: number | string) => string | number;
  }) => {
    const formattedValue = useMemo(() => (value !== undefined ? formatValue(value) : undefined), [value, formatValue]);

    return (
      <div className={classes.chartSectionStat}>
        <div className={classes.chartSectionStatText}>
          {icon}
          <span>{text}</span>
        </div>
        <strong>{formattedValue}</strong>
      </div>
    );
  },
);
ChartStat.displayName = "ChartStat";
export default ChartStat;
