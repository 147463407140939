import useAdmin from "hooks/useAdmin";
import { useQuery } from "react-query";
import { CompanyApi } from "services/api";
import { CompanyQueryKeys } from "services/query-keys";

export type TFindAllCompaniesParams = {
  skip?: number;
  take?: number;
  accountId?: string;
  userIds?: string[];
  channelIds?: string[];
  requiresPostReview?: boolean;
  contains?: string;
  include?: ("users" | "channels" | "posts" | "account")[];
};

const useCompanies = <TReturn = Awaited<ReturnType<typeof CompanyApi.list>>>(
  filters?: TFindAllCompaniesParams,
  options: {
    enabled: boolean;
    selectFn?: (res: Awaited<ReturnType<typeof CompanyApi.list>>) => TReturn;
  } = {
    enabled: true,
  },
) => {
  const { isPlatformAdmin } = useAdmin();

  const enabled = filters?.accountId ? true : isPlatformAdmin;

  return useQuery({
    queryKey: CompanyQueryKeys.list(filters),
    queryFn: async () => {
      return CompanyApi.list(filters);
    },
    select: options?.selectFn,
    initialData: { items: [], total: 0 },
    initialDataUpdatedAt: 0,
    enabled: enabled && options.enabled,
    refetchOnWindowFocus: true,
  });
};

export default useCompanies;
