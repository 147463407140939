import { UserAccessPolicy, UserAccessPolicyType } from "types";

/**
 * This function returns all the policies that eclipse the `of` policy
 * i.e., the policies due to which the `of` policy does not have any effect
 *
 * Note: This function is written by keeping in mind that:
 *  - The accountId would always be present in the policies provided in the params
 *  - The accountId is same for all the policies.
 * @param of - The user access policy for which it should filter out the eclipsing policies
 * @param from - List of user access policies from which eclispsing policies needs to be filtered
 * @returns - List of eclipsing user access policies wrt `of`
 */
export const getEclipsingPolicies = (of: UserAccessPolicy, from: UserAccessPolicy[]): UserAccessPolicy[] => {
  from = from.filter((it) => it.id !== of.id);

  switch (of.policyType) {
    case UserAccessPolicyType.MANAGE_ALL:
      return [];
    case UserAccessPolicyType.MANAGE_ACCOUNT:
      return from.filter((policy) => policy.policyType === UserAccessPolicyType.MANAGE_ALL);
    case UserAccessPolicyType.VIEW_ACCOUNT:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          policy.policyType === UserAccessPolicyType.MANAGE_ACCOUNT,
      );
    case UserAccessPolicyType.MANAGE_USER:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_USER && policy.companyId === null),
      );
    case UserAccessPolicyType.VIEW_USER:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_USER &&
            (policy.companyId === null || policy.companyId === of.id)) ||
          (policy.policyType === UserAccessPolicyType.VIEW_USER && policy.companyId === null),
      );
    case UserAccessPolicyType.MANAGE_TODO:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_TODO && policy.companyId === null),
      );
    case UserAccessPolicyType.VIEW_TODO:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_TODO &&
            (policy.companyId === null || policy.companyId === of.id)) ||
          (policy.policyType === UserAccessPolicyType.VIEW_TODO && policy.companyId === null),
      );
    case UserAccessPolicyType.MANAGE_COMPANY:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          policy.policyType === UserAccessPolicyType.MANAGE_ACCOUNT ||
          (policy.policyType === UserAccessPolicyType.MANAGE_COMPANY &&
            (policy.companyId === null || policy.companyId === of.companyId)),
      );
    case UserAccessPolicyType.VIEW_COMPANY:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          policy.policyType === UserAccessPolicyType.MANAGE_ACCOUNT ||
          (policy.policyType === UserAccessPolicyType.MANAGE_COMPANY &&
            (policy.companyId === null || policy.companyId === of.companyId)) ||
          (policy.policyType === UserAccessPolicyType.VIEW_COMPANY &&
            (policy.companyId === null || policy.companyId === of.companyId)),
      );
    case UserAccessPolicyType.MANAGE_CHANNEL:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          policy.policyType === UserAccessPolicyType.MANAGE_ACCOUNT ||
          (policy.policyType === UserAccessPolicyType.MANAGE_CHANNEL &&
            ((policy.companyId === null && policy.channelId === null) ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    case UserAccessPolicyType.VIEW_CHANNEL:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          policy.policyType === UserAccessPolicyType.MANAGE_ACCOUNT ||
          policy.policyType === UserAccessPolicyType.VIEW_ACCOUNT ||
          (policy.policyType === UserAccessPolicyType.MANAGE_CHANNEL &&
            ((policy.companyId === null && policy.channelId === null) ||
              (policy.companyId === of.companyId &&
                (policy.channelId === null || policy.channelId === of.channelId)))) ||
          (policy.policyType === UserAccessPolicyType.VIEW_CHANNEL &&
            ((policy.companyId === null && policy.channelId === null) ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    case UserAccessPolicyType.MANAGE_POST:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    case UserAccessPolicyType.EDIT_POST:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId &&
                (policy.channelId === null || policy.channelId === of.channelId)))) ||
          (policy.policyType === UserAccessPolicyType.EDIT_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    case UserAccessPolicyType.VIEW_POST:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.MANAGE_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId &&
                (policy.channelId === null || policy.channelId === of.channelId)))) ||
          (policy.policyType === UserAccessPolicyType.EDIT_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId &&
                (policy.channelId === null || policy.channelId === of.channelId)))) ||
          (policy.policyType === UserAccessPolicyType.VIEW_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    case UserAccessPolicyType.REVIEW_POST:
      return from.filter(
        (policy) =>
          policy.policyType === UserAccessPolicyType.MANAGE_ALL ||
          (policy.policyType === UserAccessPolicyType.REVIEW_POST &&
            (policy.companyId === null ||
              (policy.companyId === of.companyId && (policy.channelId === null || policy.channelId === of.channelId)))),
      );
    default:
      return [];
  }
};
