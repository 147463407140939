import { IMG_SIZE_LIMIT } from "constants/editor";
import { useEffect, useState } from "react";
import { Row, Col, Button, Form, message, UploadFile } from "antd";
import { useMutation, useQueryClient } from "react-query";
import SearchBar from "components/SearchBar";
import { useRouterContext } from "@pankod/refine-core";

import { TodosAPI } from "services/api";
import { convertLocalDateToUTC } from "utils/date";

import { Todo } from "types";
import { TodoQueryKeys } from "services/query-keys";
import { UsePaginationState } from "hooks/usePagination";
import useSelectedAccount from "hooks/useSelectedAccount";
import TodosTable, { TodosTableFilters } from "../TodosTable";
import TodoModal from "../TodoModal";
import classes from "./TodosContainer.module.css";

const validateImageSizes = (images: UploadFile[]) => {
  // if any of the images' size is more than 8MB
  if (images.some((image) => image.size && image.size > IMG_SIZE_LIMIT)) {
    throw new Error("Bitte wählen Sie Bilder aus, die weniger als 8 MB sind");
  }

  // if any of the images' type is not jpg, jpeg
  if (images.some((image) => image.type && !image.type.includes("image/jpeg") && !image.type.includes("image/jpg"))) {
    throw new Error("Bitte wählen Sie Bilder aus, die im Format jpg oder jpeg sind");
  }
};

export default function TodosContainer({
  data,
  openCount,
  doneCount,
  progressCount,
  paginationState,
  setSearchText,
  total,
  filters,
  setFilters,
}: {
  data: Todo[];
  openCount: number;
  doneCount: number;
  progressCount: number;
  total: number;
  paginationState: UsePaginationState;
  setSearchText: (value: string) => void;
  filters: TodosTableFilters;
  setFilters: (filters: TodosTableFilters) => void;
}) {
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const { selectedAccountId: accountId } = useSelectedAccount({ companyRequired: false });

  const [selectedTodoId, setSelectedTodoId] = useState<string>("");

  const { useLocation } = useRouterContext();
  const location = useLocation();

  useEffect(() => {
    const todoId = new URLSearchParams(location.search).get("todoId");
    if (todoId) {
      setSelectedTodoId(todoId);
      setOpenModal(true);
    }
  }, [location]);

  const queryClient = useQueryClient();

  const invalidateData = () => {
    queryClient.invalidateQueries(TodoQueryKeys.list());
  };

  const { mutate: mutateTodo, isLoading: mutateTodoLoading } = useMutation(
    "mutateTodo",
    async (todoId?: string) => {
      const values = form.getFieldsValue();
      const images = values.files;

      if (images) {
        validateImageSizes(images);
      }

      const body = {
        ...values,
        dueAt: convertLocalDateToUTC(values.dueAt),
        companyId: values.companyId === "None" ? undefined : values.companyId,
      };

      return todoId ? TodosAPI.update(accountId, todoId, body) : TodosAPI.create(accountId, body);
    },
    {
      onSuccess: () => {
        message.success(selectedTodoId ? "Die Aufgabe wurde erfolgreich aktualisiert" : "Aufgabe erfolgreich erstellt");
        invalidateData();
        closeModal();
      },
      onError: (error: ErrorEvent) => {
        message.error(error.message);
      },
    },
  );

  const closeModal = () => {
    form.resetFields();
    setOpenModal(false);
    setSelectedTodoId("");
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  return (
    <div>
      {openModal && (
        <TodoModal
          open={openModal}
          closeModal={closeModal}
          form={form}
          onSubmit={() => mutateTodo(selectedTodoId)}
          isLoading={mutateTodoLoading}
          todoId={selectedTodoId}
        />
      )}
      <Row justify="space-between" align="middle" className={classes.Header}>
        <div className={classes.Stats}>
          <p>Unbearbeitete Aufgaben: {openCount}</p>
          <p>In Bearbeitung: {progressCount}</p>
          <p>Erledigt: {doneCount}</p>
        </div>
        <Col xl={6} lg={12} md={24} sm={24} xs={24} className={classes.SearchBarContainer}>
          <SearchBar setText={setSearchText} paginationState={paginationState} />
        </Col>
        <Button type="primary" onClick={() => setOpenModal(true)}>
          Aufgabe erstellen
        </Button>
      </Row>
      <TodosTable
        data={data}
        total={total}
        invalidateData={invalidateData}
        openModalHandler={openModalHandler}
        setSelectedTodoId={setSelectedTodoId}
        paginationState={paginationState}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
}
