import CalendlyWidget from "components/CalendlyWidget";
import useChannels from "hooks/useChannels";
import useCompanies from "hooks/company/useCompanies";
import { Navigate, useSearchParams } from "@pankod/refine-react-router-v6";
import Loader from "components/Loader";
import ConnectSocial from "./components/Connect";
import AddPayment from "./components/Payment";
import { OnboardingContextProvider, useOnboardingContext } from "./components/OnboardingContext";

const OnboardingLoader = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");

  const { currAccount, isLoadingAccounts } = useOnboardingContext();

  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    { accountId: currAccount?.id ?? "" },
    { enabled: !!currAccount },
  );

  const { data: companies } = useCompanies({ accountId: currAccount?.id ?? "" });

  if (!step) {
    if (!isLoadingAccounts && !isLoadingChannels) {
      let redirectUrl;
      if (channels?.length) {
        if (currAccount?.accessExpiresAt && new Date(currAccount.accessExpiresAt) > new Date()) {
          redirectUrl = `/account/${currAccount.id}/company/${companies?.items[0]?.id ?? "-"}/dashboard`;
        } else {
          redirectUrl = `/onboarding?step=2`;
        }
      } else {
        redirectUrl = `/onboarding?step=1`;
      }

      return <Navigate to={redirectUrl} />;
    }

    return <Loader />;
  }

  return (
    <>
      {step === "2" ? <AddPayment /> : <ConnectSocial />}

      <CalendlyWidget />
    </>
  );
};

export default function Onboarding() {
  return (
    <OnboardingContextProvider>
      <OnboardingLoader />
    </OnboardingContextProvider>
  );
}
