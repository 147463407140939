export default function formatNumber(
  num: string | number,
  options?: Partial<{
    minimumFractionDigits: number;
    maximumFractionDigits: number;
    notation: Intl.NumberFormatOptions["notation"];
    maximumTruncatedDigits: number;
  }>,
) {
  const number = Number(num);
  return Intl.NumberFormat("en-DE", {
    minimumFractionDigits: options?.minimumFractionDigits,
    maximumFractionDigits: options?.maximumFractionDigits,
    notation: options?.notation,
    maximumSignificantDigits: options?.maximumTruncatedDigits,
  }).format(number);
}
