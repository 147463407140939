import { useQuery } from "react-query";
import { RetryValue } from "react-query/types/core/retryer";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";
import { Account, AccountDetail } from "types";

const useAccount = (
  accountId: string,
  options: {
    enabled: boolean;
    onSuccess?: (data: Account & AccountDetail) => void;
    retry?: RetryValue<unknown>;
  } = { enabled: true },
) => {
  return useQuery({
    queryKey: AccountQueryKeys.details(accountId),
    queryFn: async () => {
      return AccountApi.details(accountId);
    },
    ...(options.onSuccess && { onSuccess: options.onSuccess }),
    enabled: !!accountId && options.enabled,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: options?.retry,
  });
};

export default useAccount;
