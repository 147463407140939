import { useQuery, UseQueryOptions } from "react-query";
import { PostApi } from "services/api";
import { PostQueryKeys } from "services/query-keys";
import { POST_EDITING_STATUS, PostPublishingStatusType } from "types";

export type TGetPostsParams = {
  accountId: string;
  startDate?: string;
  endDate?: string;
  companyId: string;
  requiresReview?: boolean;
  publishingStatuses?: PostPublishingStatusType[];
  editingStatuses?: POST_EDITING_STATUS[];
};

type GetPostsResult = Awaited<ReturnType<typeof PostApi.getPosts>>;

const usePosts = (filters: TGetPostsParams, options?: UseQueryOptions<GetPostsResult>) => {
  return useQuery({
    queryKey: PostQueryKeys.list(filters),
    queryFn: async () => {
      return PostApi.getPosts(filters);
    },
    ...options,
    //Cache for 1 minute
    staleTime: options?.staleTime ?? 60_000,
    initialData: options?.initialData ?? [],
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default usePosts;
