import { useState } from "react";
import { Modal, Button, DatePicker, TimePicker, message, Spin } from "antd";
import dayjs from "dayjs";
import { EventModalProps, PostPublishingStatusType } from "types";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import { PostApi } from "services/api";
import { useRouterContext } from "@pankod/refine-core";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import localeData from "dayjs/plugin/localeData";
import locale from "antd/es/date-picker/locale/de_DE";
import "../../../../styles/modal.css";
import "../../../../styles/icon.css";
import useSelectedAccount from "hooks/useSelectedAccount";
import { getDisabledTime } from "utils/date";
import classes from "./EventModal.module.css";
import "dayjs/locale/de";

dayjs.locale("de_DE");

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");

function EventModal({ visible, handleCloseModal, dateSelected, postVersionId, publishingStatus }: EventModalProps) {
  const { push } = useRouterContext().useHistory();
  const [reScheduledAt, setReScheduledAt] = useState({
    date: dayjs(dateSelected).format("YYYY-MM-DD"),
    time: dayjs(dateSelected).format("HH:mm"),
  });

  const { mutate, isLoading } = useMutation(
    "update-schedule",
    ({ type, postVersionId }: { type: PostPublishingStatusType; postVersionId: string }) => {
      if (reScheduledAt.date < dayjs().format("YYYY-MM-DD")) {
        throw new Error(
          "Die Veranstaltung kann nicht verschoben werden! Bitte wählen Sie aktuelle oder zukünftige Termine",
        );
      }
      const body = {
        publishingStatus: type,
        facebookScheduledAt: dayjs.tz(`${reScheduledAt.date}T${reScheduledAt.time}:00`).utc().format(),
        instagramScheduledAt: dayjs.tz(`${reScheduledAt.date}T${reScheduledAt.time}:00`).utc().format(),
      };

      return PostApi.updateSchedule(postVersionId, body);
    },
    {
      onSuccess: () => {
        message.success("Beitrag verschoben");
        handleCloseModal();
      },
      onError: (error: Error) => {
        if (error instanceof AxiosError) {
          message.error(error.response?.data?.message ?? error.message);
        } else {
          console.error(error);
        }
        handleCloseModal();
      },
    },
  );

  const { pathPrefix } = useSelectedAccount();

  return (
    <div>
      <Spin spinning={isLoading}>
        <Modal
          onCancel={handleCloseModal}
          data-testid="planner-schedule-popup-close-btn"
          open={visible}
          title={
            <div data-testid="planner-schedule-popup-label">
              <h3>Postingoptionen</h3>
            </div>
          }
          footer={
            postVersionId ? (
              <Button
                onClick={() => {
                  if (publishingStatus === PostPublishingStatusType.DRAFT) {
                    mutate({ type: PostPublishingStatusType.DRAFT, postVersionId });
                  }
                  if (
                    publishingStatus === PostPublishingStatusType.SCHEDULED ||
                    publishingStatus === PostPublishingStatusType.FAILED
                  ) {
                    mutate({ type: PostPublishingStatusType.SCHEDULING, postVersionId });
                  }
                }}
                data-testid="planner-schedule-popup-plan-btn"
                type="primary"
                loading={isLoading}
                style={{ backgroundColor: "green", padding: "10px" }}
              >
                Neu planen
              </Button>
            ) : (
              <Button
                onClick={() => {
                  localStorage.setItem("selectedDate", dayjs(`${reScheduledAt.date}T${reScheduledAt.time}`).format());
                  push(`${pathPrefix}/posts/new`);
                }}
                data-testid="planner-schedule-popup-plan-btn"
                type="primary"
                style={{ backgroundColor: "green", padding: "10px" }}
              >
                Jetzt planen
              </Button>
            )
          }
        >
          <div>
            <p style={{ color: "black" }}>
              Plane deinen Beitrag für einen Zeitpunkt, zu dem deine Zielgruppe am aktivsten ist oder wähle manuell aus,
              an welchem Tag und zu welcher Uhrzeit dein Beitrag veröffentlicht werden soll.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className={classes.inputContainer}>
                <DatePicker
                  className={classes.datePicker}
                  format="DD.MM.YYYY"
                  locale={locale}
                  disabledDate={(current) =>
                    current &&
                    (current < dayjs.tz(dayjs()).subtract(1, "day") || current > dayjs.tz(dayjs()).add(364, "day"))
                  }
                  value={dateSelected && reScheduledAt.date ? dayjs(reScheduledAt.date, "YYYY-MM-DD") : undefined}
                  onChange={(_, dateStr) => {
                    setReScheduledAt({
                      ...reScheduledAt,
                      date: dateStr ? dayjs(dateStr as string, "DD.MM.YYYY").format("YYYY-MM-DD") : "",
                    });
                  }}
                />
                <TimePicker
                  className={classes.timePicker}
                  format="HH:mm"
                  locale={locale}
                  disabledTime={() => getDisabledTime(reScheduledAt.date)}
                  value={
                    dateSelected && reScheduledAt.time
                      ? dayjs(`${dayjs(reScheduledAt.date).format("YYYY-MM-DD")}T${reScheduledAt.time}:00`)
                      : undefined
                  }
                  onChange={(_, timeStr) => {
                    setReScheduledAt({
                      ...reScheduledAt,
                      time: timeStr as string,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Spin>
    </div>
  );
}

export default EventModal;
