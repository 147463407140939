import { IG_IMG_SIZE_LIMIT } from "constants/editor";
import { useRouterContext } from "@pankod/refine-core";
import { Flex, Popover, Row, Space, Typography, UploadFile, message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { CreatePost, PLATFORMS, Post, PostPublishingStatusType, SocketPayload, User, ZipPostData } from "types";
import { listenToEvent, stopListeningToEvent } from "utils/socket";
import dayjs from "dayjs";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { PostApi } from "services/api";
import useQueryCacheData from "hooks/useQueryCacheData";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import NotAllowed from "components/NotAllowed";
import { useQueryClient } from "react-query";
import { PostQueryKeys } from "services/query-keys";
import useChannels from "hooks/useChannels";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import DeleteModal from "pages/Planner/components/DeleteModal";
import useCompany from "hooks/company/useCompany";
import classes from "./PostEditor.module.css";
import PostPreview from "./Preview";
import PostContent from "./Content";
import { checkScheduleStatus, createUpdatePost, getPost } from "./helpers/apiUtil";
import ScheduleModal from "./NewComponents/ScheduleModal";
import { getUnhealthyChannels } from "./helpers/utils";
import { isAssetsProcessed } from "./helpers/contentValidators";

const PostEditor = () => {
  // Get user
  const me = useQueryCacheData<User>("me");
  // Get list of accounts
  const { pathPrefix, selectedAccountId: accountId, selectedCompanyId: companyId } = useSelectedAccount();

  const { t } = useTranslation(["common", "pages"]);

  const { push } = useRouterContext().useHistory();
  const params = useRouterContext().useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now());
  const [zipModalOpen, setZipModalOpen] = useState(false);
  const [isZipProcessing, setIsZipProcessing] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [postSchedule, setPostSchedule] = useState(false);
  const [isZipScheduling, setIsZipScheduling] = useState(false);
  const [deletePostId, setDeletePostId] = useState("");
  const [post, setPost] = useState<Post>();
  const [localPost, setLoacalPost] = useState<CreatePost>({
    accountId,
    companyId,
    assets: [],
    platforms: [],
    publishingStatus:
      localStorage.getItem("selectedDate") !== null
        ? PostPublishingStatusType.SCHEDULING
        : PostPublishingStatusType.DRAFT,
    body: "",
    schedule: {
      facebookScheduledAt:
        localStorage.getItem("selectedDate") !== null
          ? dayjs(localStorage.getItem("selectedDate")).format("YYYY-MM-DDTHH:mm")
          : dayjs().format("YYYY-MM-DDTHH:mm:ss").toString(),
      instagramScheduledAt:
        localStorage.getItem("selectedDate") !== null
          ? dayjs(localStorage.getItem("selectedDate")).format("YYYY-MM-DDTHH:mm")
          : dayjs().format("YYYY-MM-DDTHH:mm:ss").toString(),
    },
    channel: undefined,
    versionId: undefined,
  });

  const queryClient = useQueryClient();

  const { data: channels, refetch: refetchChannels } = useChannels({ accountId });
  const { data: company } = useCompany(companyId ?? "", {
    onSuccess: (data) => setPostSchedule(post ? post.requiresReview : data.requiresPostReview),
  });

  const requiresPostReview = post ? post.requiresReview : company?.requiresPostReview ?? false;

  const unhealthyChannels = useMemo(() => getUnhealthyChannels(channels ?? []), [channels]);

  const { ability } = useContext(UserAccessPolicyContext);
  const canCreatePosts = ability.can("create", "Post");
  const canUpdatePosts = ability.can(
    "update",
    makeSubject("Post", {
      accountId: localPost.accountId,
      companyId: localPost.companyId,
      channelId: localPost.channel?.id,
    }),
  );

  const canAccessEditor = params?.id ? canUpdatePosts : canCreatePosts;

  // TODO move this to path params
  useEffect(() => {
    if (localStorage.getItem("selectedDate")) {
      localStorage.removeItem("selectedDate");
    }
  });

  useEffect(() => {
    const prepare = async () => {
      if (!params.id) return;
      setLoading(true);
      const post = await getPost(params.id);
      setPost(post);

      const assets =
        post.currentVersion.content?.assets.map((asset) => {
          const assetArray = asset.url.split("/");
          const name = assetArray[assetArray.length - 1].toString().split(".")[0];
          const updatedType =
            assetArray[assetArray.length - 1].toString().split(".")[1] === "jpg"
              ? "jpeg"
              : assetArray[assetArray.length - 1].toString().split(".")[1];
          const type =
            asset.type === "PHOTO"
              ? `image/${updatedType}`
              : `video/${assetArray[assetArray.length - 1].toString().split(".")[1]}`;

          return {
            uid: name,
            name,
            url: asset.url,
            type,
            size: IG_IMG_SIZE_LIMIT,
          } as UploadFile;
        }) ?? [];

      if (post.currentVersion.scheduledAt === null) {
        post.currentVersion.scheduledAt = dayjs().format();
      }

      let publishingStatus = post.publishingStatus as PostPublishingStatusType;

      if (publishingStatus === PostPublishingStatusType.FAILED) {
        if (post.scheduled) {
          setPostSchedule(true);
          publishingStatus = PostPublishingStatusType.SCHEDULED;
        } else {
          publishingStatus = PostPublishingStatusType.PUBLISHED;
        }
      }

      setLoacalPost({
        accountId: post.accountId,
        companyId: post.companyId,
        assets,
        platforms: [`${post.channelId}__${post.channel.type}`],
        publishingStatus,
        body: post.currentVersion.content?.body ?? "",
        schedule: {
          facebookScheduledAt: (dayjs.tz(post.currentVersion.scheduledAt) || dayjs()).format("YYYY-MM-DDTHH:mm:ss"),
          instagramScheduledAt: (dayjs.tz(post.currentVersion.scheduledAt) || dayjs()).format("YYYY-MM-DDTHH:mm:ss"),
        },
        isEdit: true,
        channel: post.channel,
        versionId: post.currentVersion.id,
      });
      setLoading(false);
    };

    const checkSchedule = async () => {
      const response = await checkScheduleStatus(accountId, companyId);
      if (response.isZipProcessing) {
        setIsZipProcessing(response.isZipProcessing);
      }
    };

    prepare();
    checkSchedule();
  }, [params.id, accountId]);

  const callCreatePost = async ({
    type,
    publishMobile,
  }: {
    type: PostPublishingStatusType;
    publishMobile: boolean;
  }) => {
    try {
      if (
        localPost.platforms.find((item) => item.includes("INSTAGRAM")) &&
        !isAssetsProcessed(localPost, PLATFORMS.INSTAGRAM)
      ) {
        message.error(
          "Deine Bilder müssen für die Instagram-Plattform zugeschnitten werden. Bitte schneide die Bilder zu, bevor du fortfährst.",
        );
        setShowCropModal(true);
        return;
      }
      setLoading(true);
      const response = await createUpdatePost({ ...localPost, publishMobile, publishingStatus: type });
      if (response?.postIds) {
        response?.postIds?.map((id: string) => {
          listenToEvent(id, (payload: SocketPayload) => {
            if (payload.type === "SUCCESS") message.success(payload.message);
            else if (payload.type === "ERROR") message.error(payload.message);
            stopListeningToEvent(id);
          });
          return id;
        });
      }
      setLoading(false);

      if (params.id) {
        message.success("Beitrag erfolgreich aktualisiert");
      } else {
        const successMessage = {
          PUBLISHING: publishMobile
            ? "Der Beitrag wurde erfolgreich an alle verknüpften Geräte gesendet"
            : "Beitrag erfolgreich veröffentlicht",
          PUBLISHED: publishMobile
            ? "Der Beitrag wurde erfolgreich an alle verknüpften Geräte gesendet"
            : "Beitrag erfolgreich veröffentlicht",
          SCHEDULING: "Beitrag erfolgreich geplant",
          SCHEDULED: "Beitrag erfolgreich geplant",
          DRAFT: "Beitrag erfolgreich als Entwurf gespeichert",
          FAILED: "Beitrag konnte nicht veröffentlicht werden",
        };
        message.success(successMessage[localPost.publishingStatus]);
      }
      await queryClient.invalidateQueries({ queryKey: PostQueryKeys.list() });
      push(`${pathPrefix}/planer`);
    } catch (e) {
      if (e instanceof AxiosError) {
        message.error(e.response?.data?.message ?? e.message);
      } else {
        console.error(e);
        message.error(t("pages:posts.createPostFailedError"));
      }
      // message.error()
      refetchChannels();

      // Need to handle this better. Error from API currently does not come till here
      setLoading(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setDeletePostId("");
    push(`${pathPrefix}/planer`);
  };

  const startZipScheduling = async (
    zipFile: UploadFile,
    csvFile: UploadFile,
    selectedChannels: string[],
    accountId: string,
  ) => {
    try {
      setIsZipScheduling(true);
      const data: ZipPostData = {
        accountId,
        companyId,
        userId: me.id,
        platform: selectedChannels.map((channel) => channel.split("_")[0]),
        csv: csvFile,
        zip: zipFile,
      };
      const response = await PostApi.scheduleZip(data);
      if (response) {
        message.success(
          "Wir haben mit dem Planen deiner Beiträge begonnen. Dies kann bis zu 15 Minuten dauern - du bekommst eine E-Mail sobald der Prozess abgeschlossen ist.",
        );
        setIsZipProcessing(true);
      } else {
        message.error("Fehler beim Planen der Zip-Datei. Versuchen Sie es erneut!");
      }
      setIsZipScheduling(false);
      setZipModalOpen(false);
      setModalKey(Date.now());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={`container ${classes.mainContainer}`}>
      <Row justify="space-between" align="middle">
        <h1 className={classes.heading}>
          Post Verfassen{" "}
          <Popover
            title="Bitte beachten"
            content={
              <div className={classes.postInfoContainer}>
                <Typography.Text>
                  1. Beiträge können nur mindestens 60 Minuten im Voraus und bis zu maximal 1 Jahr in der Zukunft
                  geplant werden.
                </Typography.Text>
                <br />
                <Typography.Text>
                  2. Für Facebook-Posts: Bilder im Format JPEG, PNG (max. 4MB). Videos im MP4-Format (max. 100MB)
                </Typography.Text>
                <br />
                <Typography.Text>
                  3. Für Instagram-Beiträge: Bilder im JPEG-Format (max. 8 MB). Andere Formate werden nicht unterstützt.
                  Videos im MP4-Format (max. 100 MB)
                </Typography.Text>
                <br />
                <Typography.Text>
                  4. Für ZIP-Importe: Vergewissere dich, dass die csv-Datei das richtige Format hat und dass der in der
                  csv-Datei angegebene Medienname mit dem in der ZIP-Datei enthaltenen Mediennamen übereinstimmt.
                </Typography.Text>
              </div>
            }
            className={classes.infoPopover}
          >
            <InfoCircleOutlined />
          </Popover>
        </h1>
      </Row>
      {canAccessEditor ? (
        <>
          {unhealthyChannels.length > 0 && (
            <div className={classes.errorDiv}>
              <Typography.Text className={classes.errorText}>
                Einige Seiten, die mit deinem Konto verbunden sind, sind nicht mehr erreichbar. Bitte verbinde sie
                wieder. Deine geplanten Beiträge könnten fehlschlagen, wenn diese Kanäle nicht wieder verbunden sind.
              </Typography.Text>
              <br />
              <Typography.Text>
                <Typography.Link href={`${pathPrefix}/settings/profile`}>Klicke hier</Typography.Link>, um die Seiten
                neu zu verbinden.
              </Typography.Text>
            </div>
          )}
          {isZipProcessing && (
            <div className={classes.zipProcessing}>
              <Space>
                <InfoCircleFilled className={classes.zipInfo} />
                <Typography.Text strong>Bitte beachten</Typography.Text>
              </Space>
              <br />
              <Typography.Text>
                Deine ZIP-Datei wird gerade verarbeitet. Wir schicken dir eine E-Mail, sobald der Vorgang abgeschlossen
                wurde. Dieser Vorgang kann bis zu 15 Minuten dauern.
              </Typography.Text>
            </div>
          )}
          <Flex gap={44} className={classes.innerContainer}>
            <PostContent
              post={post}
              loading={loading}
              localPost={localPost}
              setLocalPost={setLoacalPost}
              callCreatePost={callCreatePost}
              showCropModal={showCropModal}
              setShowCropModal={setShowCropModal}
              setZipModalOpen={setZipModalOpen}
              postSchedule={postSchedule}
              setPostSchedule={setPostSchedule}
              deletePost={setDeletePostId}
              requiresPostReview={requiresPostReview}
            />
            <PostPreview localPost={localPost} />
          </Flex>
          <ScheduleModal
            key={modalKey}
            isModalOpen={zipModalOpen}
            onModalClose={() => {
              setZipModalOpen(false);
              setModalKey(Date.now());
            }}
            onOkClick={startZipScheduling}
            isZipProcessing={isZipProcessing}
          />
          {deletePostId && (
            <DeleteModal
              visible={!!setDeletePostId}
              handleCloseModal={handleCloseDeleteModal}
              currentText="Beitrag"
              currentIds={[deletePostId]}
            />
          )}
        </>
      ) : (
        <NotAllowed />
      )}
    </div>
  );
};

export default PostEditor;
