import { Navigate, useLocation, useParams } from "@pankod/refine-react-router-v6";
import Loader from "components/Loader";
import useCompany from "hooks/company/useCompany";
import { ReactNode, createContext, useMemo } from "react";
import useAccounts from "hooks/useAccounts";

export type AccountContextType = {
  selectedAccount: string | undefined;
  selectedCompany: string | undefined;
  accountRequired: boolean;
  companyRequired: boolean;
  pathWithoutPrefix: string;
  pathPrefix: string;
  isAdminOnlyRoute: boolean;
};

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

const getAcccountSelectRedirectPath = (pathName: string, account: string | undefined, company: string | undefined) => {
  // All routes wrapped in AccountContextProvider have the
  // prefix pattern /account/:accountId/company/:companyId/:suffix
  //So it is safe to use split + slice + join to get the :suffix part
  const to = pathName.split("/").slice(5).join("/");

  //Store the :suffix part in a search param so that select account page
  //can redirect the user back to the original page
  const params = new URLSearchParams(to ? { to: `/${to}` } : undefined);

  return `/account/${account ?? "-"}/company/${company ?? "-"}/select-account?${params.toString()}`;
};

/**
 * Should wrap all routes that have the
 * prefix pattern /account/:accountId/company/:companyId/:suffix.
 * E.g. /account/:accountId/company/:companyId/dashboard
 * Routes without this prefix MUST NOT BE WRAPPED WITH THIS.
 */
export const AccountContextProvider = ({
  children,
  accountRequired = true,
  companyRequired = true,
  adminOnly = false,
}: {
  children?: ReactNode;
  accountRequired?: boolean;
  companyRequired?: boolean;
  adminOnly?: boolean;
}) => {
  const { account: accountId, company: companyId } = useParams();
  const location = useLocation();

  const selectedAccount = accountId !== "-" ? accountId : undefined;
  const selectedCompany = companyId !== "-" ? companyId : undefined;

  const pathWithoutPrefix = useMemo(() => {
    const suffix = location.pathname.match(/^\/account\/[^/]+\/company\/[^/]+(\/.*)?$/)?.[1];

    if (!suffix) {
      return location.pathname;
    }

    return suffix;
  }, [location.pathname]);

  const pathPrefix = useMemo(
    () => `/account/${selectedAccount ?? "-"}/company/${selectedCompany ?? "-"}`,
    [selectedAccount, selectedCompany],
  );

  const { data: accounts, isLoading: isLoadingAccount } = useAccounts();

  const account = useMemo(
    () => accounts?.find((account) => account.id === selectedAccount),
    [selectedAccount, accounts],
  );

  const { data: company, isLoading: isLoadingCompany } = useCompany(selectedCompany ?? "", {
    retry: false,
  });

  if (accountRequired) {
    if (!selectedAccount)
      return <Navigate to={getAcccountSelectRedirectPath(location.pathname, accountId, companyId)} replace />;

    if (isLoadingAccount) return <Loader />;

    //Make the user select both account and company if account is invalid
    if (!account)
      return <Navigate to={getAcccountSelectRedirectPath(location.pathname, undefined, undefined)} replace />;
  }

  if (!accountRequired && !isLoadingAccount && selectedAccount && !account) {
    return <Navigate to={`/account/-/company/-/${location.pathname.split("/").slice(5).join("/")}`} replace />;
  }

  if (companyRequired) {
    if (!selectedCompany)
      return <Navigate to={getAcccountSelectRedirectPath(location.pathname, accountId, companyId)} replace />;

    if (isLoadingCompany) return <Loader />;

    //Make the user select only the company if company is invalid
    if (!company)
      return <Navigate to={getAcccountSelectRedirectPath(location.pathname, accountId, undefined)} replace />;
  }

  if (!companyRequired && !isLoadingCompany && selectedCompany && !company) {
    return <Navigate to={`/account/${accountId}/company/-${pathWithoutPrefix}`} replace />;
  }

  return (
    <AccountContext.Provider
      value={{
        selectedAccount,
        selectedCompany,
        accountRequired,
        companyRequired,
        pathWithoutPrefix,
        pathPrefix,
        isAdminOnlyRoute: adminOnly,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
