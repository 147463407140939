import React, { useMemo } from "react";
import { Modal, Popconfirm, Segmented, Spin, Tooltip, message } from "antd";
import { DevicePlatform, NotificationStatusType } from "types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useInView } from "react-intersection-observer";
import useSelectedAccount from "hooks/useSelectedAccount";
import useNotifications from "hooks/Notification/useNotifications";
import useUpdateNotification from "hooks/Notification/useUpdateNotification";
import useUnreadNotificationsCount from "hooks/Notification/useUnreadNotificationsCount";
import { useTranslation } from "react-i18next";
import ReadMessage from "../../assets/ReadMessage.svg";
import UnreadMessage from "../../assets/UnreadMessage.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import classes from "./NotificationModal.module.css";
import { NotificationListItem } from "./NotificationListItem/NotificationListItem";

const NotificationModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  const { t } = useTranslation(["common", "pages"]);
  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const [selectedNotificationIds, setSelectedNotificationIds] = React.useState<string[]>([]);
  const [ref, inView] = useInView();

  const { data: unreadNotificationCount } = useUnreadNotificationsCount({
    accountId: selectedAccountId,
    platform: DevicePlatform.desktop,
    companyId: selectedCompanyId,
  });

  const {
    isLoading: isFetchingNotification,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNotifications({
    accountId: selectedAccountId,
    platform: DevicePlatform.desktop,
    companyId: selectedCompanyId,
  });

  const notifications = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((data) => data.items) ?? [];
  }, [data]);

  const totalNotifications = data ? data.pages?.[data.pageParams.length - 1].total ?? 0 : 0;

  const selectedIdsUpdateStatus: NotificationStatusType = data?.pages
    .flatMap((data) => data.items)
    .filter(
      (notification) =>
        (selectedNotificationIds.includes(notification.id) &&
          //Check if the notification is unread
          !notification.userStatuses.length) ||
        //Check if the notification is unread
        notification.userStatuses.some((n) => n.status === NotificationStatusType.UNREAD),
    ).length
    ? NotificationStatusType.READ
    : NotificationStatusType.UNREAD;

  const {
    mutate: updateNotifications,
    variables,
    isLoading: isUpdatingNotifications,
  } = useUpdateNotification({
    onSuccess: (_, { status, hideSuccessMessage }) => {
      setSelectedNotificationIds([]);

      if (hideSuccessMessage) return;
      if (status === NotificationStatusType.READ) {
        message.success(t("pages:notifications.notificationsReadSuccessMessage"));
      } else if (status === NotificationStatusType.UNREAD) {
        message.success(t("pages:notifications.notificationsUnreadSuccessMessage"));
      } else {
        message.success(t("pages:notifications.notificationsDeleteSuccessMessage"));
      }
    },
    onError: (error: unknown) => {
      if (error instanceof Error) message.error(error.message);
    },
  });

  const toggleNotificationSelection = (isChecked: boolean, id: string) => {
    setSelectedNotificationIds((prev) =>
      isChecked ? Array.from(new Set([...prev, id])) : prev.filter((item) => item !== id),
    );
  };

  React.useEffect(() => {
    if (!inView || !hasNextPage || isFetchingNextPage) return;

    fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <>
      <Modal
        open={isOpen}
        title="Deine Benachrichtigungen"
        onCancel={closeModal}
        footer={null}
        centered
        styles={{
          content: {
            height: "75vh",
            display: "flex",
            flexDirection: "column",
          },
          body: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1",
          },
        }}
      >
        <Segmented
          defaultValue={t("pages:notifications.inboxLabel")}
          block
          className={classes.notificationTabs}
          options={[t("pages:notifications.inboxLabel")]}
        />
        {notifications.length ? (
          <>
            <div
              className={classes.notificationActions}
              style={{
                justifyContent: !selectedNotificationIds.length ? "flex-start" : "space-between",
                alignItems: "center",
              }}
            >
              {totalNotifications > 0 ? <h5> Gesamte Benachrichtigungen: {totalNotifications}</h5> : null}
              {selectedNotificationIds.length ? (
                <div className={classes.notificationBulkActionContainer}>
                  {selectedIdsUpdateStatus === NotificationStatusType.UNREAD ? (
                    <Tooltip placement="top" title="Als ungelesen markieren" arrow={false}>
                      <img
                        src={UnreadMessage}
                        className={classes.notificationActionIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          updateNotifications({
                            status: NotificationStatusType.UNREAD,
                            notificationIds: selectedNotificationIds,
                          });
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Als gelesen markieren" arrow={false}>
                      <img
                        src={ReadMessage}
                        className={classes.notificationActionIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          updateNotifications({
                            status: NotificationStatusType.READ,
                            notificationIds: selectedNotificationIds,
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                  <Popconfirm
                    title="Lösche die Benachrichtigung"
                    description="Bist du sicher, dass du diese Benachrichtigung löschen möchtest?"
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    okText="Ja"
                    cancelText="Nein"
                    okButtonProps={{
                      type: "primary",
                      danger: true,
                      className: classes.confirmDeleteNotificationButton,
                    }}
                    onConfirm={() =>
                      updateNotifications({
                        status: NotificationStatusType.DELETE,
                        notificationIds: selectedNotificationIds,
                      })
                    }
                  >
                    <Tooltip placement="top" title="Löschen" arrow={false}>
                      <img src={DeleteIcon} className={classes.notificationActionIcon} />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ) : null}
            </div>
            <div>
              <h5>Ungelesene Benachrichtigungen: {unreadNotificationCount?.unreadCount ?? 0}</h5>
            </div>

            <div
              className={classes.notificationContainer}
              style={{
                justifyContent: !isFetchingNotification ? "flex-start" : "space-between",
              }}
            >
              {notifications.map((notification) => (
                <NotificationListItem
                  toggleSelection={toggleNotificationSelection}
                  notification={notification}
                  isSelected={selectedNotificationIds.includes(notification.id)}
                  updateStatusFn={(status, id, hideSuccessMessage) =>
                    updateNotifications({ status, notificationIds: [id], hideSuccessMessage })
                  }
                  closeModal={closeModal}
                  notifcationsBeingUpdated={isUpdatingNotifications ? variables?.notificationIds : undefined}
                />
              ))}

              {hasNextPage ? (
                <div ref={ref}>
                  <Spin className={classes.notificationSpinLoader} />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                margin: "0 auto",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <img
                src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                alt="emptyImage"
                style={{ width: "195px" }}
              />
              <p style={{ color: "#000" }}>Keine Benachrichtigungen verfügbar</p>
            </span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default NotificationModal;
