import { useMutation, useQueryClient } from "react-query";
import { UserAccessPolicyApi } from "services/api";
import { UserAccessPolicyKeys } from "services/query-keys";

type UseDeleteUserAccessPolicyInput = Parameters<typeof UserAccessPolicyApi.deleteAccessPolicy>[0];
type UseDeleteUserAccessPolicyOutput = Awaited<ReturnType<typeof UserAccessPolicyApi.deleteAccessPolicy>>;

const useDeleteUserAccessPolicy = (options?: {
  onSuccess?: (
    data: UseDeleteUserAccessPolicyOutput,
    variables: UseDeleteUserAccessPolicyInput,
  ) => void | Promise<void>;
  onError?: (e: unknown) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: UserAccessPolicyApi.deleteAccessPolicy,
    onSuccess: async (data, input) => {
      await queryClient.invalidateQueries(UserAccessPolicyKeys.list());

      if (options?.onSuccess) {
        options.onSuccess(data, input);
      }
    },
    onError: options?.onError,
  });
};

export default useDeleteUserAccessPolicy;
