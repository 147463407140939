import { Button, Modal, Popover, Row, Select, SelectProps, Space, Table, TableProps, Tooltip, message } from "antd";
import useAccount from "hooks/useAccount";
import useCompanies from "hooks/company/useCompanies";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useCallback, useContext, useMemo, useState } from "react";
import { User, UserAccessPolicy, UserAccessPolicyType } from "types";
import { AxiosError } from "axios";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import useCreateUserAccessPolicy from "hooks/useCreateUserAccessPolicy";
import useDeleteUserAccessPolicy from "hooks/useDeleteUserAccessPolicy";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import SearchBar from "components/SearchBar";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";
import useChannels from "hooks/useChannels";
import NoDataLocaleTable from "components/NoData";
import {
  getEclipsingPolicies,
  isAccessPolicyValid,
  isChannelAllowedInPolicy,
  isCompanyAllowedInPolicy,
  getPolicyPermissions,
} from "./helpers";
import classes from "./index.module.css";

const DEFAULT_POLICY_INPUT_OPTIONS: UserAccessPolicyType[] = [
  UserAccessPolicyType.MANAGE_ALL,
  UserAccessPolicyType.MANAGE_ACCOUNT,
  UserAccessPolicyType.VIEW_ACCOUNT,
  UserAccessPolicyType.MANAGE_USER,
  UserAccessPolicyType.VIEW_USER,
  UserAccessPolicyType.MANAGE_TODO,
  UserAccessPolicyType.VIEW_TODO,
  UserAccessPolicyType.MANAGE_COMPANY,
  UserAccessPolicyType.VIEW_COMPANY,
  UserAccessPolicyType.MANAGE_CHANNEL,
  UserAccessPolicyType.VIEW_CHANNEL,
  UserAccessPolicyType.MANAGE_POST,
  UserAccessPolicyType.EDIT_POST,
  UserAccessPolicyType.VIEW_POST,
  UserAccessPolicyType.REVIEW_POST,
];

enum ExpandType {
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

type FormattedUserAccessPolicy = UserAccessPolicy & {
  policyName: string;
  accountName: string;
  companyName: string;
  channelName: string;
  expandType?: ExpandType;
  expandDescription?: React.ReactNode;
  canDelete?: boolean;
};

const policyTypeToNameMap = {
  MANAGE_ALL: "Alles verwalten",
  MANAGE_ACCOUNT: "Konto verwalten",
  VIEW_ACCOUNT: "Konto ansehen",
  MANAGE_USER: "Benutzer verwalten",
  VIEW_USER: "Benutzer ansehen",
  MANAGE_TODO: "Todos verwalten",
  VIEW_TODO: "Todos ansehen",
  MANAGE_COMPANY: "Unternehmen verwalten",
  VIEW_COMPANY: "Unternehmen ansehen",
  MANAGE_CHANNEL: "Kanal verwalten",
  VIEW_CHANNEL: "Kanal ansehen",
  MANAGE_POST: "Beiträge verwalten",
  EDIT_POST: "Beiträge bearbeiten",
  VIEW_POST: "Beiträge ansehen",
  REVIEW_POST: "Beiträge überprüfen",
};

const initialAddPolicyData = (defaultValues?: Partial<FormattedUserAccessPolicy>) => {
  return {
    id: "0",
    accountId: "",
    accountName: "",
    companyId: null,
    companyName: "",
    channelId: null,
    channelName: "",
    policyName: "",
    userId: "",
    policyType: null,
    account: { id: "", name: "" },
    company: null,
    channel: null,
    ...defaultValues,
  };
};

const getPolicyName = (policy: keyof typeof policyTypeToNameMap) => policyTypeToNameMap[policy];

const PermissionsModal = ({
  open,
  onCancel,
  selectedUser,
  accessPolicies,
  isLoadingAccessPolicies,
  policyTypeOptions = DEFAULT_POLICY_INPUT_OPTIONS,
  initialCompanyId = null,
  initialChannelId = null,
  selectCompanyDisabled = false,
  selectChannelDisabled = false,
  viewOnly = false,
}: {
  open: boolean;
  onCancel: () => void;
  selectedUser: User;
  accessPolicies: UserAccessPolicy[];
  isLoadingAccessPolicies: boolean;
  policyTypeOptions?: UserAccessPolicyType[];
  initialCompanyId?: string | null;
  initialChannelId?: string | null;
  selectCompanyDisabled?: boolean;
  selectChannelDisabled?: boolean;
  viewOnly?: boolean;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const [addPolicyData, setAddPolicyData] = useState(
    initialAddPolicyData({ companyId: initialCompanyId, channelId: initialChannelId }),
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddPolicyRowVisible, setIsAddPolicyRowVisible] = useState<boolean>(false);
  const { selectedAccountId } = useSelectedAccount();

  const { ability } = useContext(UserAccessPolicyContext);

  const canViewChannels = ability.can("read", "Channel");
  const canAssignPolicy =
    !viewOnly &&
    ability.can(
      "create",
      makeSubject("UserAccessPolicy", {
        userId: selectedUser.id,
        accountId: selectedAccountId,
        companyId: initialCompanyId ?? undefined,
      }),
    );
  const canDeletePolicy =
    !viewOnly &&
    ability.can(
      "delete",
      makeSubject("UserAccessPolicy", {
        userId: selectedUser.id,
        accountId: selectedAccountId,
        companyId: initialCompanyId ?? undefined,
      }),
    );

  const { data: account } = useAccount(selectedAccountId);
  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    { accountId: selectedAccountId },
    { enabled: canViewChannels },
  );

  const { data: companies, isLoading: isLoadingCompanies } = useCompanies(
    {
      accountId: selectedAccountId,
      userIds: [selectedUser.id],
    },
    {
      selectFn: (res) => res.items,
      enabled: true,
    },
  );

  const closeModal = () => {
    onCancel();
    setAddPolicyData(initialAddPolicyData());
    setSearchValue("");
    setIsAddPolicyRowVisible(false);
  };

  const formatAccessPolicyInfo = useCallback(
    (eclipsingPolicies: UserAccessPolicy[], policyInfoItems: string[]) => {
      return (
        <div>
          {eclipsingPolicies.length ? (
            <>
              <h5 style={{ fontSize: "12px", color: "grey" }}>{t("pages:eclipsingAccessPolicy.title")}:</h5>
              <ul>
                {eclipsingPolicies
                  .filter((item) => item.id !== "0")
                  .map(({ id, policyType, company, channel }) => (
                    <li
                      key={id}
                      style={{ fontSize: "12px", color: "grey" }}
                    >{`${getPolicyName(policyType as keyof typeof policyTypeToNameMap)}${
                      company
                        ? channel
                          ? ` ${t("pages:eclipsingAccessPolicy.policyWithCompanyAndChannelLabel", {
                              companyName: company.name,
                              channelName: channel.name,
                            })}`
                          : ` ${t("pages:eclipsingAccessPolicy.policyWithCompanyLabel", {
                              companyName: company.name,
                            })}`
                        : ""
                    }`}</li>
                  ))}
              </ul>
            </>
          ) : null}
          {policyInfoItems.length ? (
            <>
              <h5
                style={{ fontSize: "12px", color: "grey", ...(eclipsingPolicies.length && { marginTop: "1.5rem" }) }}
                className={classes.popoverTitle}
              >
                {t("pages:accessPolicy.infoTitle")}:
              </h5>
              <ul>
                {policyInfoItems.map((info, index) => (
                  <li key={index + info} style={{ fontSize: "12px", color: "grey" }}>
                    {info}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      );
    },
    [t],
  );

  const formattedAccessPolicies: FormattedUserAccessPolicy[] = useMemo(() => {
    const policies = accessPolicies
      .map((policy) => {
        const eclipsedPolicies = isAccessPolicyValid(policy) ? getEclipsingPolicies(policy, accessPolicies) : [];
        const policyInfoItems = isAccessPolicyValid(policy) ? getPolicyPermissions(policy) : [];
        const canDelete =
          !viewOnly &&
          ability.can(
            "delete",
            makeSubject("UserAccessPolicy", {
              userId: policy.userId,
              companyId: policy.companyId ?? undefined,
              accountId: policy.accountId,
              channelId: policy.channelId ?? undefined,
            }),
          );
        return {
          ...policy,
          policyName: policy.policyType ? getPolicyName(policy.policyType as keyof typeof policyTypeToNameMap) : "",
          accountName: policy.account.name,
          companyName:
            policy?.company?.name ??
            (policy.policyType && isCompanyAllowedInPolicy(policy.policyType) ? t("options.all") : ""),
          channelName:
            policy?.channel?.name ??
            (policy.policyType && isChannelAllowedInPolicy(policy.policyType) ? t("options.all") : ""),
          expandType: eclipsedPolicies.length ? ExpandType.WARN : ExpandType.INFO,
          expandDescription: formatAccessPolicyInfo(eclipsedPolicies, policyInfoItems),
          canDelete,
        };
      })
      .filter((policy) => {
        if (!searchValue || searchValue === "") {
          return true;
        }
        return (
          policy.policyName.toLowerCase().includes(searchValue.toLowerCase()) ||
          policy.accountName.toLowerCase().includes(searchValue.toLowerCase()) ||
          policy.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
          policy.channelName.toLowerCase().includes(searchValue.toLowerCase())
        );
      });

    if (canAssignPolicy && isAddPolicyRowVisible && account) {
      const addPolicyRow = {
        ...addPolicyData,
        accountId: selectedAccountId,
        userId: selectedUser.id,
        accountName: account.name,
        account,
        ...(addPolicyData.companyId && {
          company: companies?.find((item) => item.id === addPolicyData.companyId) ?? null,
        }),
        ...(addPolicyData.channelId && {
          channel: channels?.find((item) => item.id === addPolicyData.channelId) ?? null,
        }),
      };

      if (isAccessPolicyValid(addPolicyRow)) {
        const eclipsedPolicies = getEclipsingPolicies(addPolicyRow, accessPolicies);
        const policyInfoItems = getPolicyPermissions(addPolicyRow);
        if (eclipsedPolicies.length) {
          addPolicyRow.expandType = ExpandType.WARN;
        } else if (policyInfoItems.length) {
          addPolicyRow.expandType = ExpandType.INFO;
        }
        addPolicyRow.expandDescription = formatAccessPolicyInfo(eclipsedPolicies, policyInfoItems);
      } else {
        addPolicyRow.expandType = undefined;
      }
      return [addPolicyRow, ...policies];
    } else {
      return policies;
    }
  }, [
    accessPolicies,
    selectedAccountId,
    selectedUser,
    addPolicyData,
    account,
    channels,
    searchValue,
    isAddPolicyRowVisible,
    canAssignPolicy,
    companies,
    formatAccessPolicyInfo,
    ability,
    viewOnly,
    t,
  ]);

  const { mutate: createUserAccessPolicy, isLoading: isAddingPermission } = useCreateUserAccessPolicy({
    onSuccess: () => {
      setAddPolicyData(initialAddPolicyData({ companyId: initialCompanyId, channelId: initialChannelId }));
      message.success(t("pages:accessPolicy.createSuccessMessage"));
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const errMsg = err.response?.data.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
  });

  const { mutate: deleteUserAccessPolicy, isLoading: isRemovingPermission } = useDeleteUserAccessPolicy({
    onSuccess: async () => {
      message.success(t("pages:accessPolicy.deleteSuccessMessage"));
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const errMsg = err.response?.data.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
  });

  const validateAndSaveAccessPolicy = () => {
    const validationResult = isAccessPolicyValid({
      ...addPolicyData,
      accountId: selectedAccountId,
      userId: selectedUser.id,
    });

    if (!canAssignPolicy) {
      message.error(t("pages:accessPolicy.createForbiddenError"));
    }

    if (validationResult.success) {
      createUserAccessPolicy({
        policyType: addPolicyData.policyType as UserAccessPolicyType,
        accountId: selectedAccountId,
        userId: selectedUser.id,
        companyId: addPolicyData.companyId,
        channelId: addPolicyData.channelId,
      });
    } else {
      message.error(validationResult.errors[0].errMessage);
    }
  };

  const companyOptions = useMemo(() => {
    if (!companies) return { select: [], filter: [] };
    const companyFilterOptions = [
      { text: `(${t("options.blank")})`, value: "" },
      { text: t("options.all"), value: t("options.all") },
    ];
    const companySeclectOptions: SelectProps["options"] = [{ label: t("options.all"), value: null }];
    for (const company of companies) {
      companySeclectOptions.push({ label: company.name, value: company.id });
      companyFilterOptions.push({ text: company.name, value: company.name });
    }
    return { select: companySeclectOptions, filter: companyFilterOptions };
  }, [companies, t]);

  const channelOptions = useMemo(() => {
    if (!channels) return { select: [], filter: [] };
    const channelFilterOptions = [
      { text: `(${t("options.blank")})`, value: "" },
      { text: t("options.all"), value: t("options.all") },
    ];
    const channelSelectOptions: SelectProps["options"] = [{ label: t("options.all"), value: null }];
    for (const channel of channels) {
      channelSelectOptions.push({ label: channel.name, value: channel.id });
      channelFilterOptions.push({ text: channel.name, value: channel.name });
    }
    return { select: channelSelectOptions, filter: channelFilterOptions };
  }, [channels, t]);

  const policyTypeTableOptions = useMemo(() => {
    const policyTypeSelectOptions: SelectProps["options"] = [],
      policyTypeFilterOptions: { text: string; value: string }[] = [];
    for (const type of policyTypeOptions) {
      const policyName = getPolicyName(type as keyof typeof policyTypeToNameMap);
      if (
        (initialCompanyId && selectCompanyDisabled && !isCompanyAllowedInPolicy(type)) ??
        (initialChannelId && selectChannelDisabled && !isChannelAllowedInPolicy(type))
      )
        continue;
      policyTypeSelectOptions.push({ label: policyName, value: type, key: type });
      policyTypeFilterOptions.push({ text: policyName, value: policyName });
    }
    return { select: policyTypeSelectOptions, filter: policyTypeFilterOptions };
  }, [policyTypeOptions, initialChannelId, initialCompanyId, selectChannelDisabled, selectCompanyDisabled]);

  const columns: TableProps<FormattedUserAccessPolicy>["columns"] = [
    {
      title: t("pages:accessPolicy.policyNameLabel"),
      dataIndex: "policyName",
      key: "policyName",
      width: 290,
      sorter: (a, b, order) => {
        if (b.id === "0") return order === "ascend" ? 1 : -1;

        return a.policyName.localeCompare(b.policyName);
      },
      filters: policyTypeTableOptions.filter,
      onFilter: (value, record) => record.id === "0" || record.policyName === value,
      render(value, policy) {
        return {
          children: (
            <Row align="middle" justify="space-between">
              {policy.id === "0" ? (
                <Select
                  options={policyTypeTableOptions.select}
                  value={policy.policyType ?? undefined}
                  onSelect={(value) => {
                    setAddPolicyData((prev) => ({ ...prev, policyType: value }));
                  }}
                  style={{ maxWidth: `80%` }}
                />
              ) : (
                <span style={{ maxWidth: `80%` }}>{value}</span>
              )}
              {policy.expandType === ExpandType.WARN || policy.expandType === ExpandType.INFO ? (
                <div style={{ flexBasis: "1rem" }}>
                  {policy.expandType === ExpandType.WARN ? (
                    <Popover content={policy.expandDescription} placement="top">
                      <WarningOutlined style={{ fontSize: "1rem", color: "orange" }} />
                    </Popover>
                  ) : null}
                  {policy.expandType === ExpandType.INFO ? (
                    <Popover content={policy.expandDescription} placement="top">
                      <InfoCircleOutlined style={{ fontSize: "1rem", color: "grey" }} />
                    </Popover>
                  ) : null}
                </div>
              ) : null}
            </Row>
          ),
        };
      },
    },
    {
      title: t("pages:account.nameLabel"),
      dataIndex: "accountName",
      key: "accountName",
      width: 180,
      sorter: (a, b, order) => {
        if (b.id === "0") return order === "ascend" ? 1 : -1;

        return a.accountName.localeCompare(b.accountName);
      },
      render(value) {
        return {
          children: (
            <Row>
              <span>{value}</span>
            </Row>
          ),
        };
      },
    },
    {
      title: t("pages:companies.nameLabel"),
      dataIndex: "companyName",
      key: "companyName",
      width: 180,
      sorter: (a, b, order) => {
        if (b.id === "0") return order === "ascend" ? 1 : -1;

        return a.companyName.localeCompare(b.companyName);
      },
      filters: companyOptions.filter,
      onFilter: (value, record) => record.id === "0" || record.companyName === value,
      render(value, policy) {
        return {
          children:
            policy.id === "0" && policy.policyType && isCompanyAllowedInPolicy(policy.policyType) ? (
              <Select
                options={companyOptions.select}
                loading={isLoadingCompanies}
                value={addPolicyData.companyId ?? null}
                disabled={selectCompanyDisabled}
                onSelect={(value) =>
                  setAddPolicyData((prev) => ({ ...prev, companyId: value, ...(!value && { channelId: null }) }))
                }
              />
            ) : (
              <Row>
                <span>{value}</span>
              </Row>
            ),
        };
      },
    },
    {
      title: t("pages:channel.title"),
      dataIndex: "channelName",
      key: "channelName",
      width: 180,
      sorter: (a, b, order) => {
        if (b.id === "0") return order === "ascend" ? 1 : -1;

        return a.channelName.localeCompare(b.channelName);
      },
      filters: channelOptions.filter,
      onFilter: (value, record) => record.id === "0" || record.channelName === value,
      render(value, policy) {
        return {
          children:
            policy.id === "0" && policy.policyType && isChannelAllowedInPolicy(policy.policyType) ? (
              <Select
                options={channelOptions.select}
                value={addPolicyData.channelId ?? null}
                loading={isLoadingChannels}
                disabled={selectChannelDisabled}
                onSelect={(value) => setAddPolicyData((prev) => ({ ...prev, channelId: value }))}
              />
            ) : (
              <Row>
                <span>{value}</span>
              </Row>
            ),
        };
      },
    },
    ...(canDeletePolicy || (canAssignPolicy && isAddPolicyRowVisible)
      ? [
          {
            title: t("buttons.actions"),
            dataIndex: "id",
            key: "id",
            width: 30,
            render(_: string, policy: FormattedUserAccessPolicy) {
              return {
                children: (
                  <Row>
                    <div className={classes.permissionTableActionCell}>
                      {policy.id === "0" ? (
                        <Tooltip title={t("buttons.discard")}>
                          <Button
                            disabled={isAddingPermission || isRemovingPermission}
                            style={{ background: "none" }}
                            icon={<CloseCircleOutlined />}
                            onClick={() => {
                              setIsAddPolicyRowVisible(false);
                              setAddPolicyData(
                                initialAddPolicyData({ companyId: initialCompanyId, channelId: initialChannelId }),
                              );
                            }}
                          />
                        </Tooltip>
                      ) : null}
                      {policy.id === "0" && canAssignPolicy ? (
                        <Tooltip title={t("buttons.save")}>
                          <Button
                            disabled={isAddingPermission || isRemovingPermission}
                            style={{ background: "none" }}
                            icon={<SaveOutlined />}
                            onClick={validateAndSaveAccessPolicy}
                          />
                        </Tooltip>
                      ) : null}
                      {policy.canDelete && policy.id !== "0" ? (
                        <Tooltip title={t("buttons.remove")}>
                          <Button
                            disabled={isAddingPermission || isRemovingPermission}
                            style={{ background: "none" }}
                            icon={<DeleteOutlined />}
                            onClick={() => deleteUserAccessPolicy({ id: policy.id })}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </Row>
                ),
              };
            },
          },
        ]
      : []),
  ];

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      footer={null}
      title={`${t("labels.userPermissions")}: "${selectedUser.name}"`}
      className={classes.permissionsModal}
      destroyOnClose
    >
      <Space className={classes.permissionHeader}>
        <SearchBar setText={setSearchValue} />
        {canAssignPolicy ? (
          <Button
            className={classes.createPolicyBtn}
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAddPolicyRowVisible(true);
            }}
          >
            {t("buttons.create")}
          </Button>
        ) : null}
      </Space>
      <Table
        loading={isLoadingAccessPolicies || isAddingPermission || isRemovingPermission}
        dataSource={formattedAccessPolicies}
        columns={columns}
        locale={NoDataLocaleTable}
        scroll={{ x: "max-content" }}
        pagination={false}
        rowKey="id"
      />
    </Modal>
  );
};

export default PermissionsModal;
