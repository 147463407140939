import { TODO_PRIORITY, TODO_STATUS } from "types";

export const priorityTranslated: { [key: string]: string } = {
  [TODO_PRIORITY.LOW]: "Niedrig",
  [TODO_PRIORITY.NORMAL]: "Normal",
  [TODO_PRIORITY.HIGH]: "Hoch",
  [TODO_PRIORITY.URGENT]: "Dringend",
};

export const statusTranslated: { [key: string]: string } = {
  [TODO_STATUS.OPEN]: "Unbearbeitet",
  [TODO_STATUS.IN_PROGRESS]: "In Bearbeitung",
  [TODO_STATUS.DONE]: "Erledigt",
};
