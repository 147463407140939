import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { useRouterContext } from "@pankod/refine-core";
import dayjs, { Dayjs } from "dayjs";
import { useMutation } from "react-query";
import { Row, Button, Spin, Dropdown, MenuProps, message, Typography, Tooltip, TableProps } from "antd";
import { EllipsisOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { PostApi } from "services/api";
import { convertUTCDateToLocal } from "utils/date";
import { POST_EDITING_STATUS, Post, PostPublishingStatusType } from "types";

import { postStatusBadgeColorMapping, postStatusColorMapping } from "utils";
import useSelectedAccount from "hooks/useSelectedAccount";
import usePosts from "hooks/posts/usePosts";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import useCompany from "hooks/company/useCompany";
import { AxiosError } from "axios";
import { useSearchParams } from "@pankod/refine-react-router-v6";
import CustomTable from "components/CustomTable";
import { useTranslation } from "react-i18next";
import CustomRangePicker, { RangePickerValue } from "components/CustomRangePicker";
import CustomBadge from "components/CustomBadge";
import EventModal from "../EventModal";
import DeleteModal from "../DeleteModal";

import FacebookColored from "../../../../assets/icons/socials/facebookColored.svg";
import InstagramColored from "../../../../assets/icons/socials/instagramColored.svg";
import classes from "./ListView.module.css";

const { Text } = Typography;

type ListViewTableRecord = Post & { canUpdate: boolean; canDelete: boolean };

export const postStatusTranslated: { [key: string]: string } = {
  [PostPublishingStatusType.PUBLISHING]: "In Bearbeitung",
  [PostPublishingStatusType.SCHEDULED]: "Geplant",
  [PostPublishingStatusType.SCHEDULING]: "In Bearbeitung",
  [PostPublishingStatusType.PUBLISHED]: "Veröffentlicht",
  [PostPublishingStatusType.DRAFT]: "Entwurf",
  [PostPublishingStatusType.FAILED]: "Fehlgeschlagen",
};

export const postEditingStatusTranslated: { [key: string]: string } = {
  [POST_EDITING_STATUS.APPROVED]: "Genehmigt",
  [POST_EDITING_STATUS.IN_PROGRESS]: "Im Gange",
  [POST_EDITING_STATUS.IN_REVIEW]: "In Prüfung",
  [POST_EDITING_STATUS.REJECTED]: "Abgelehnt",
};

const renderStatus = (status: PostPublishingStatusType) => {
  return <CustomBadge color={postStatusBadgeColorMapping[status]}>{postStatusTranslated[status]}</CustomBadge>;
};

const initDateRange = () => {
  const now = dayjs().startOf("day");

  return [now.startOf("month"), now.endOf("month")] as [Dayjs, Dayjs];
};

const getItems = (
  post: Post & {
    canUpdate: boolean;
    canDelete: boolean;
  },
  requiresPostReview: boolean,
) => {
  const items: MenuProps["items"] = [
    {
      label: "Beitrag ansehen",
      key: "View-Post",
    },
    ...(post.canUpdate && post.publishingStatus !== PostPublishingStatusType.PUBLISHED
      ? [
          {
            label: "Beitrag bearbeiten",
            key: "Edit-Post",
          },
        ]
      : []),
    ...(post.canUpdate &&
    (post.publishingStatus === PostPublishingStatusType.SCHEDULED ||
      post.publishingStatus === PostPublishingStatusType.FAILED)
      ? [
          {
            label: "Beitrag neu planen",
            key: "Reschedule-Post",
          },
        ]
      : []),

    ...(post.canUpdate &&
    !requiresPostReview &&
    (post.publishingStatus === PostPublishingStatusType.SCHEDULED ||
      post.publishingStatus === PostPublishingStatusType.DRAFT)
      ? [
          {
            label: "Beitrag veröffentlichen",
            key: "Publish-Post",
          },
        ]
      : []),
    ...(post.canDelete && post.publishingStatus !== PostPublishingStatusType.PUBLISHED
      ? [
          {
            label: "Beitrag löschen",
            key: "Delete-Post",
          },
        ]
      : []),
  ];

  return items;
};

export default function ListView() {
  const { t } = useTranslation(["common"]);
  const [deletePostId, setDeletePostId] = useState("");
  const [postToEdit, setPostToEdit] = useState<Post | undefined>();
  const { push } = useRouterContext().useHistory();

  const [dateRange, setDateRange] = useState(initDateRange);

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: dateRange[0].startOf("day").toISOString(),
      endDate: dateRange[1].endOf("day").toISOString(),
    };
  }, [dateRange]);

  const [_, setSearchParams] = useSearchParams();

  const { pathPrefix, selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const { data: company } = useCompany(selectedCompanyId ?? "");

  const { ability } = useContext(UserAccessPolicyContext);
  const canCreatePosts = ability.can("create", "Post");

  const { data, refetch, isLoading } = usePosts(
    {
      accountId: selectedAccountId,
      companyId: selectedCompanyId,
      startDate,
      endDate,
    },
    {
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
    },
  );

  const tableData: ListViewTableRecord[] = useMemo(
    () =>
      data?.map((post) => {
        const canUpdate = ability.can(
          "update",
          makeSubject("Post", {
            accountId: post.accountId,
            channelId: post.channelId,
            companyId: post.companyId,
          }),
        );

        const canDelete = ability.can(
          "delete",
          makeSubject("Post", {
            accountId: post.accountId,
            channelId: post.channelId,
            companyId: post.companyId,
          }),
        );
        return { ...post, canUpdate, canDelete };
      }) ?? [],
    [data, ability],
  );

  const onClick = (key: string, post?: Post) => {
    switch (key) {
      case "View-Post":
        if (!post) throw new Error(`Expected to have post but got ${post}`);
        setSearchParams({ postVersionId: post.currentVersion.id });
        break;
      case "Edit-Post":
        if (!post) throw new Error(`Expected to have post but got ${post}`);
        push(`${pathPrefix}/posts/${post.id}`);
        break;
      case "Reschedule-Post":
        if (!post) throw new Error(`Expected to have post but got ${post}`);
        setPostToEdit(post);
        break;
      case "Publish-Post":
        if (!post) throw new Error(`Expected to have post but got ${post}`);
        mutate({ type: PostPublishingStatusType.PUBLISHING, postVersionId: post.currentVersion.id });
        break;
      case "Delete-Post":
        if (!post) throw new Error(`Expected to have post but got ${post}`);
        setDeletePostId(post.id);
        break;
      default:
        break;
    }
  };

  const { mutate } = useMutation(
    "update-schedule",
    ({ type, postVersionId }: { type: PostPublishingStatusType; postVersionId?: string }) => {
      return PostApi.updateSchedule(postVersionId ?? "", {
        publishingStatus: type,
      });
    },
    {
      onSuccess: () => {
        message.success("Beitrag veröffentlicht");
        refetch();
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          message.error(error.response?.data?.message ?? error.message);
        } else {
          console.error(error);
        }
      },
    },
  );

  const handleCloseDeleteModal = () => {
    setDeletePostId("");
    refetch();
  };

  const handleCloseEditModal = () => {
    setPostToEdit(undefined);
    refetch();
  };

  const columns: TableProps<ListViewTableRecord>["columns"] = [
    {
      title: "Beitrag",
      align: "left",
      render: (_, record) => {
        const primeAsset = record.currentVersion.content?.assets[0];

        return (
          <div className={classes.motivContainer}>
            {record.currentVersion.thumbnailUrl ? (
              <img src={record.currentVersion.thumbnailUrl} alt="motiv" className={classes.motivImage} />
            ) : primeAsset ? (
              primeAsset?.url.toLowerCase().endsWith(".mp4") ? (
                <video src={primeAsset.url} />
              ) : (
                <img src={primeAsset.url} alt="motiv" className={classes.motivImage} />
              )
            ) : null}
            <span className={classes.motivText}>{record.currentVersion.content?.body}</span>
          </div>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      className: classes.publishingStatusColumn,
      render: (_, record) => {
        const items = getItems(record, company?.requiresPostReview ?? false);

        return (
          <Row justify="space-between" align="middle" wrap={false} className={classes.statusContainer}>
            <div style={{ marginRight: 10 }}>
              {record.publishingStatus === PostPublishingStatusType.SCHEDULED
                ? renderStatus(PostPublishingStatusType.SCHEDULED)
                : renderStatus(record.publishingStatus as PostPublishingStatusType)}
              {record.currentVersion.errMessage && record.publishingStatus === PostPublishingStatusType.FAILED ? (
                <Tooltip placement="bottom" color="#fff" overlay={<p>{record.currentVersion.errMessage}</p>}>
                  <InfoCircleOutlined
                    style={{
                      marginLeft: 5,
                      marginRight: 5,
                      cursor: "pointer",
                      color: postStatusColorMapping[record.publishingStatus],
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
            <Dropdown
              menu={{
                items,
                onClick: (menuItem) => onClick(menuItem.key, record),
              }}
              trigger={["click"]}
              disabled={
                record.publishingStatus === PostPublishingStatusType.SCHEDULING ||
                record.publishingStatus === PostPublishingStatusType.PUBLISHING
              }
            >
              <span>{items.length ? <EllipsisOutlined /> : null}</span>
            </Dropdown>
          </Row>
        );
      },
      filters: [
        {
          text: "Geplant",
          value: PostPublishingStatusType.SCHEDULED,
        },
        {
          text: "Veröffentlicht",
          value: PostPublishingStatusType.PUBLISHED,
        },
        {
          text: "Entwurf",
          value: PostPublishingStatusType.DRAFT,
        },
        {
          text: "In Bearbeitung",
          value: "In Progress",
        },
        {
          text: "Fehlgeschlagen",
          value: PostPublishingStatusType.FAILED,
        },
      ],
      onFilter: (value, record) => {
        if (value === "In Progress")
          return (
            record.publishingStatus === PostPublishingStatusType.PUBLISHING ||
            record.publishingStatus === PostPublishingStatusType.SCHEDULING
          );
        return record.publishingStatus === value;
      },
    },

    {
      dataIndex: "channel",
      title: "Plattform",
      render: (_, record) => (
        <div className={classes.platformContainer}>
          <img
            className={classes.platformIcon}
            src={record.channel.type === "FACEBOOK" ? FacebookColored : InstagramColored}
            alt={record.channel.type === "FACEBOOK" ? "Facebook Logo" : "Instagram Logo"}
          />
          {record.channel.name}
        </div>
      ),
    },
    {
      dataIndex: "scheduledAt",
      title: "Geplant am",
      render: (_, record) =>
        record.currentVersion.scheduledAt ? (
          <Text style={{ fontSize: 14 }}>
            {convertUTCDateToLocal(record.currentVersion.scheduledAt).format("DD.MM.YYYY HH.mm[h]")}
          </Text>
        ) : (
          <Text style={{ fontSize: 30 }}>-</Text>
        ),
      className: classes.dateColumn,
      sorter: (a, b) => {
        if (a.currentVersion.scheduledAt && b.currentVersion.scheduledAt) {
          return (
            convertUTCDateToLocal(a.currentVersion.scheduledAt).unix() -
            convertUTCDateToLocal(b.currentVersion.scheduledAt).unix()
          );
        } else if (a.currentVersion.scheduledAt && !b.currentVersion.scheduledAt) {
          return -1;
        } else if (!a.currentVersion.scheduledAt && b.currentVersion.scheduledAt) {
          return 1;
        }
        return 0;
      },
    },
    {
      dataIndex: "postedAt",
      title: "Veröffentlicht am",
      render: (_, record) =>
        /**
         * A hacky fix
         * He we are only showing the postedAt time when the postedAt time has been passed
         * Actually this need to be handled from backend such that postedAt field is only available when the posts gets published
         * But this requires the integration of fb webhooks to properly track fb posts schedules.
         */
        record.currentVersion.postedAt && new Date(record.currentVersion.postedAt) <= new Date() ? (
          <Text style={{ fontSize: 14 }}>
            {convertUTCDateToLocal(record.currentVersion.postedAt).format("DD.MM.YYYY HH.mm[h]")}
          </Text>
        ) : (
          <Text style={{ fontSize: 30 }}>-</Text>
        ),
      className: classes.dateColumn,
      sorter: (a, b) => {
        if (a.currentVersion.postedAt && b.currentVersion.postedAt) {
          return (
            convertUTCDateToLocal(a.currentVersion.postedAt).unix() -
            convertUTCDateToLocal(b.currentVersion.postedAt).unix()
          );
        } else if (a.currentVersion.postedAt && !b.currentVersion.postedAt) {
          return -1;
        } else if (!a.currentVersion.postedAt && b.currentVersion.postedAt) {
          return 1;
        }
        return 0;
      },
    },
    {
      dataIndex: "createdAt",
      title: "Letzte Bearbeitung",
      render: (_, record) =>
        record.currentVersion.updatedAt ? (
          <Text style={{ fontSize: 14 }}>
            {convertUTCDateToLocal(record.currentVersion.updatedAt).format("DD.MM.YYYY HH.mm[h]")}
          </Text>
        ) : (
          <Text style={{ fontSize: 30 }}>-</Text>
        ),
      className: classes.dateColumn,
      sorter: (a, b) => {
        if (a.currentVersion.updatedAt && b.currentVersion.updatedAt) {
          return (
            convertUTCDateToLocal(a.currentVersion.updatedAt).unix() -
            convertUTCDateToLocal(b.currentVersion.updatedAt).unix()
          );
        } else if (a.currentVersion.updatedAt && !b.currentVersion.updatedAt) {
          return -1;
        } else if (!a.currentVersion.updatedAt && b.currentVersion.updatedAt) {
          return 1;
        }
        return 0;
      },
    },
  ];

  return (
    <div>
      {deletePostId && (
        <DeleteModal
          visible={!!setDeletePostId}
          handleCloseModal={handleCloseDeleteModal}
          currentText="Beitrag"
          currentIds={[deletePostId]}
        />
      )}
      {postToEdit && (
        <EventModal
          visible={!!postToEdit}
          handleCloseModal={handleCloseEditModal}
          dateSelected={convertUTCDateToLocal(postToEdit.currentVersion.scheduledAt ?? "").format(
            "YYYY-MM-DDTHH:mm:ss",
          )}
          postVersionId={postToEdit.currentVersion.id}
          publishingStatus={postToEdit.publishingStatus}
        />
      )}

      <Spin spinning={isLoading}>
        <CustomTable<ListViewTableRecord>
          defaultProps={{
            dataSource: tableData,
            onRow: (record) => {
              if (
                record.publishingStatus === PostPublishingStatusType.PUBLISHING ||
                record.publishingStatus === PostPublishingStatusType.SCHEDULING
              ) {
                return {
                  className: classes.processingRow,
                };
              } else return {};
            },
            columns,
          }}
          headerPrefix={
            <CustomRangePicker
              dateRange={dateRange}
              onDateRangeChange={setDateRange as Dispatch<SetStateAction<RangePickerValue>>}
            />
          }
          headerSuffix={
            canCreatePosts ? (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                disabled={!canCreatePosts}
                className={classes.createPostButton}
                onClick={() => push(`${pathPrefix}/posts/new`)}
              >
                {t("buttons.create")}
              </Button>
            ) : undefined
          }
        />
      </Spin>
    </div>
  );
}
