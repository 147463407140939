import { useRouterContext } from "@pankod/refine-core";
import { Tabs, TabsProps } from "antd";
import useSelectedAccount from "hooks/useSelectedAccount";
import AllTodos from "./AllTodos";
import MyTodos from "./MyTodos";
import classes from "./Todos.module.css";

const items: TabsProps["items"] = [
  {
    key: "my",
    label: "Meine Aufgaben",
    children: <MyTodos />,
  },
  {
    key: "all",
    label: "Alle Aufgaben",
    children: <AllTodos />,
  },
];

export default function Todos() {
  const { useParams, useHistory } = useRouterContext();
  const { push } = useHistory();
  const params = useParams<{ tab: string }>();

  const { pathPrefix } = useSelectedAccount();

  return (
    <div className="container">
      <h1 className="heading">Aufgaben</h1>
      <Tabs
        items={items}
        defaultActiveKey="all"
        className={classes.Tabs}
        activeKey={params.tab}
        onChange={(key) => push(`${pathPrefix}/todos/${key}`)}
        destroyInactiveTabPane
      />
    </div>
  );
}
