import { Modal, Typography } from "antd";
import { TDeleteCompanyParams } from "hooks/company/useDeleteCompany";
import { useTranslation } from "react-i18next";
import { Company } from "types";

export type TDeleteDialogState = {
  open: boolean;
  forceDelete: boolean;
  companyToDelete: Company | undefined;
  message: string | undefined;
};

const DeleteCompanyModal = ({
  deleteDialogState,
  closeDeleteDialog,
  deleteCompany,
  isDeletingCompany,
}: {
  deleteDialogState: TDeleteDialogState;
  deleteCompany: (data: TDeleteCompanyParams) => void;
  isDeletingCompany: boolean;
  closeDeleteDialog: () => void;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  return (
    <Modal
      title={
        !deleteDialogState.forceDelete ? t("pages:companies.deleteButton") : t("pages:companies.forceDeleteButton")
      }
      open={deleteDialogState.open}
      onCancel={closeDeleteDialog}
      cancelButtonProps={{ style: { display: deleteDialogState.forceDelete ? "none" : undefined } }}
      okText={deleteDialogState.forceDelete ? t("buttons.forceDelete") : t("buttons.confirm")}
      okButtonProps={{
        onClick: async () => {
          if (!deleteDialogState.companyToDelete) return;
          await deleteCompany({
            companyId: deleteDialogState.companyToDelete.id,
            forceDelete: deleteDialogState.forceDelete,
          });
        },
        disabled: !deleteDialogState.companyToDelete,
        danger: true,
      }}
      confirmLoading={isDeletingCompany}
    >
      {deleteDialogState.forceDelete ? (
        <>
          <Typography>{t("pages:companies.forceDeleteModalDescription")}</Typography>
        </>
      ) : (
        <>
          <Typography>{t("pages:companies.deleteModalDescription")}</Typography>
          <Typography>{t("pages:companies.deleteModalConfirmation")}</Typography>
        </>
      )}
      {deleteDialogState.message && (
        <Typography style={{ color: "red", marginTop: "1rem", fontWeight: 500 }}>
          {deleteDialogState.message}
        </Typography>
      )}
    </Modal>
  );
};

export default DeleteCompanyModal;
