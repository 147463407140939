import { useEffect, useState } from "react";
import { Modal, Button, Select, message, Radio, Space } from "antd";
import { useQuery, useMutation } from "react-query";
import type { RadioChangeEvent } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import { Account, DescriptionOptionData, CloneModalProps, AdsCopyData } from "types";
import { CampaignApi } from "services/api";
import useQueryCacheData from "hooks/useQueryCacheData";
import classes from "./CloneModal.module.css";

const { Option } = Select;

export default function CloneModal({ visible, handleCloseModal, campaignList, adId }: CloneModalProps) {
  const { push } = useRouterContext().useHistory();
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [adsetId, setAdsetId] = useState("");
  const [adsetList, setAdsetList] = useState<DescriptionOptionData[]>([]);
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [campaignValue, setCampaignValue] = useState(2);

  const { refetch } = useQuery(
    "adset-list-clone",
    () => {
      return CampaignApi.getAdsetList(accounts[0].id, "", "", campaignId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const adset = data.adsets.map((event) => ({
          label: event.name,
          value: event.id,
          description: `${event.id}•campaign: ${campaignName}}`,
        }));
        setAdsetList(adset);
      },
    },
  );

  const { mutate } = useMutation(
    "copy-ad",
    () => {
      const body: AdsCopyData = {
        adId,
        ...(adsetId !== "" ? { adsetId } : {}),
        status: "PAUSE",
      };
      return CampaignApi.copyAds(accounts[0].id, body);
    },
    {
      onSuccess: () => {
        message.success("Anzeige dupliziert");
        handleCloseModal();
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const handleCampaignSelect = (value: string) => {
    setCampaignId(value);
    setAdsetId("");
    const foundItem = campaignList.find((item) => item.value === value);
    if (foundItem) {
      setCampaignName(foundItem.label);
    }
  };

  const handleAdsetSelect = (value: string) => {
    setAdsetId(value);
  };

  const onChange = (e: RadioChangeEvent) => {
    setCampaignValue(e.target.value);
    setAdsetId("");
    setCampaignId("");
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [campaignId]);

  return (
    <div>
      <Modal
        className={classes.ExistingModal}
        width={550}
        style={{ borderRadius: 0, marginTop: 0 }}
        maskClosable={false}
        onCancel={handleCloseModal}
        open={visible}
        footer={null}
      >
        <h3 style={{ fontWeight: "bold" }}>Duplizieren Sie Ihre Anzeige</h3>
        <br />
        <span style={{ color: "black", fontWeight: 600 }}>Wählen Sie eine Kampagne für Ihre Anzeige</span>
        <div style={{ marginBottom: 10 }}>
          <Radio.Group onChange={onChange} value={campaignValue}>
            <Space direction="vertical">
              <Radio className={classes.RadioButton} value={1}>
                Original Campaign
              </Radio>
              <Radio className={classes.RadioButton} value={2}>
                Existing Campaign
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        {campaignValue === 2 ? (
          <div>
            <div style={{ marginLeft: 25 }}>
              <Select showSearch value={campaignId} onChange={handleCampaignSelect}>
                {campaignList.map((option, index) => (
                  <Option value={option.value}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>{option.label}</h4>
                      <span>{option.description}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            <br />
            <hr />
            <br />
            <span style={{ color: "black", fontWeight: 600 }}>Wählen Sie eine Anzeigengruppe für Ihre Anzeige</span>
            <div style={{ marginBottom: 10 }}>
              <Radio.Group value={1}>
                <Space direction="vertical">
                  <Radio className={classes.RadioButton} value={1}>
                    Vorhandene Anzeigengruppe
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div style={{ marginLeft: 25 }}>
              <Select value={adsetId} onChange={handleAdsetSelect}>
                {adsetList.map((option, index) => (
                  <Option value={option.value}>
                    <div style={{ whiteSpace: "break-spaces" }}>
                      <h4>{option.label}</h4>
                      <span>{option.description}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ) : (
          ""
        )}
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="default" className={classes.cancelButton} onClick={() => handleCloseModal()}>
            stornieren
          </Button>
          <Button
            type={campaignId === "" && campaignValue === 2 ? "default" : "primary"}
            className={classes.createButton}
            disabled={campaignId === "" && campaignValue === 2}
            onClick={() => mutate()}
          >
            Weiter
          </Button>
        </div>
      </Modal>
    </div>
  );
}
