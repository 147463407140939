export type JwtToken = {
  email: string;
  accountId: string;
  role: string;
  iat: string;
  exp: string;
};

export const EMPTY_TOKEN: JwtToken = {
  email: "",
  accountId: "",
  role: "",
  iat: "",
  exp: "",
};

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

export default parseJwt;
