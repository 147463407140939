import { Flex, Typography } from "antd";
import React from "react";
import EPLBlackLogo from "../../assets/EPL Black Text Logo.svg";
import RegisterLeftImage from "../../assets/RegisterSideImage.svg";
import LoginLeftImage from "../../assets/LoginLeftImage.svg";

import classes from "./AuthContainer.module.css";

const AuthContainer = ({
  type,
  children,
  sideContent,
}: {
  type?: "LOGIN" | "REGISTER";
  children: React.ReactNode;
  sideContent?: React.ReactNode;
}) => {
  return (
    <div className={classes.authContainer}>
      <Flex vertical className={classes.authLeftContent}>
        <img src={EPLBlackLogo} className={classes.logo} />
        {type === "LOGIN" ? (
          <Typography className={classes.leftContentTitle}>
            Zeit sparen, <span className={classes.highlighedText}>Content</span>
            <br />
            besser steuern und
            <br />
            <span className={classes.highlighedText}>Reichweite</span> maximieren.
          </Typography>
        ) : type === "REGISTER" ? (
          <Typography className={classes.leftContentTitle}>
            Moin, dein
            <br />
            Onlinemarketing
            <br />
            <span className={classes.highlighedText}>startet jetzt.</span>
          </Typography>
        ) : null}
        {type ? (
          <img
            src={type === "LOGIN" ? LoginLeftImage : RegisterLeftImage}
            className={classes.leftContentImage}
            data-type={type}
          />
        ) : null}
        {sideContent}
      </Flex>
      <div className={classes.authRightContent}>{children}</div>
    </div>
  );
};

export default AuthContainer;
