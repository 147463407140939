import { Button, Col, Flex, Form, Input, Row, Typography, message } from "antd";
import { useMutation } from "react-query";

import { AuthApi } from "services/api";
import { useRouterContext } from "@pankod/refine-core";
import AuthContainer from "components/AuthContainer";
import { validateEmail } from "utils";
import { useTranslation } from "react-i18next";
import { ReactComponent as MailOutlined } from "../../assets/MailOutlined.svg";
import classes from "./ForgotPassword.module.css";

type FormData = {
  email: string;
};

const forgotPasswordInitialData = {
  email: "",
};

export default function ForgotPassword() {
  const { t } = useTranslation(["common", "pages"]);
  const [form] = Form.useForm();
  const { push } = useRouterContext().useHistory();

  const { mutate, isLoading } = useMutation(
    "reset-password",
    (data: FormData) => {
      return AuthApi.forgotPassword({
        email: data.email,
      });
    },
    {
      onSuccess: async () => {
        message.success("Passwort erfolgreich zurückgesetzt");
        push("/login");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <AuthContainer type="LOGIN">
      <Flex vertical align="center" gap={32}>
        <Flex vertical className={classes.forgotPasswordForm} gap={24}>
          <Row>
            <Typography className={classes.forgotPasswordFormTitle}>{t("pages:forgotPassword.title")}</Typography>
          </Row>
          <Row>
            <Typography className={classes.forgotPasswordFormSubtitle}>{t("pages:forgotPassword.subTitle")}</Typography>
          </Row>
        </Flex>
        <Form
          form={form}
          layout="vertical"
          initialValues={forgotPasswordInitialData}
          onFinish={mutate}
          onReset={() => {
            form.setFieldsValue(forgotPasswordInitialData);
            push("/login");
          }}
          className={classes.forgotPasswordForm}
          requiredMark={false}
          validateTrigger={["submit"]}
        >
          <Flex vertical gap={32}>
            <Flex vertical gap={16}>
              <Row>
                <Form.Item
                  label="E-Mail"
                  name="email"
                  className={classes.inputLabel}
                  rules={[
                    { required: true, message: t("validation.requiredField") },
                    {
                      validator: (_, value) => {
                        if (value?.length !== 0 && !validateEmail(value))
                          return Promise.reject(new Error(t("validation.invalidEmail")));
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    className={classes.input}
                    suffix={<MailOutlined className={classes.inputIcon} />}
                    placeholder="E-Mail"
                  />
                </Form.Item>
              </Row>
            </Flex>
            <Row gutter={10}>
              <Col span={12}>
                <Button disabled={isLoading} className={classes.backButton} type="default" htmlType="reset">
                  {t("buttons.back")}
                </Button>
              </Col>
              <Col span={12}>
                <Button loading={isLoading} className={classes.submitButton} type="primary" htmlType="submit">
                  {t("buttons.send")}
                </Button>
              </Col>
            </Row>
          </Flex>
        </Form>
      </Flex>
    </AuthContainer>
  );
}
