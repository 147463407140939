import { Row, Col, Button } from "antd";
import { useRouterContext } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";
import NotFoundImage from "../../assets/404.png";
import classes from "./NotFound.module.css";

export default function NotFound({ type = "page" }: { type?: "page" | "resource" }) {
  const { t } = useTranslation(["common", "pages"]);
  const { push } = useRouterContext().useHistory();
  const history = useRouterContext().useHistory();

  return (
    <div style={{ padding: "5rem", textAlign: "center" }}>
      <Row justify="center" align="middle">
        <Col span={12} style={{ textAlign: "left" }}>
          <span style={{ color: "var(--brand-primary)", fontSize: "18px", fontWeight: "bold" }}>
            {t("pages:notFound.error")}
          </span>
          <h2 style={{ fontSize: 40 }}>
            {type === "page" ? t("pages:notFound.pageTitle") : t("pages:notFound.resourceTitle")}
          </h2>
          {type === "page" ? <span style={{ fontSize: 20 }}>{t("pages:notFound.pageDescption")}</span> : null}
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <Button type="default" className={classes.cancelButton} onClick={() => history.goBack()}>
              {t("buttons.back")}
            </Button>
            <Button
              type="primary"
              danger
              className={classes.createButton}
              onClick={() => push(`/account/-/company/-/dashboard`)}
            >
              {t("buttons.home")}
            </Button>
          </div>
        </Col>
        <Col span={12}>
          <img style={{ width: "100%" }} src={NotFoundImage} alt="404" />
        </Col>
      </Row>
    </div>
  );
}
