import { ComponentType, MessageType, ValidationResult } from "pages/PostEditor/helpers/assetValidators";
import { Flex, Row } from "antd";
import React from "react";
import classes from "./FormComponent.module.css";

export type FormComponentProps = {
  showHeader?: boolean;
  title?: string;
  titleSuffix?: React.ReactNode;
  child: React.ReactNode;
  postHelpTexts?: ValidationResult[];
  componentType?: ComponentType;
};

const FormComponent = (props: FormComponentProps) => {
  const { title, child, postHelpTexts, showHeader = true, titleSuffix = null } = props;
  let allHelpTexts: ValidationResult[] = postHelpTexts ?? [];

  // If there is even one error, only show error for the rendering component
  const isError: boolean = allHelpTexts?.some((item) => item.messageType === MessageType.ERROR) || false;
  if (isError) {
    allHelpTexts = allHelpTexts?.filter((helpText) => helpText.messageType === MessageType.ERROR) || [];
  }

  const isSuccess: boolean = allHelpTexts?.some((item) => item.messageType === MessageType.SUCCESS) || false;

  // Validator can return duplicates since it assigns result per item, remove any duplicates from the array
  const uniqueValidations: ValidationResult[] =
    allHelpTexts?.filter((v, i, a) => a.findIndex((v2) => v2.message === v.message) === i) || [];

  return (
    <Flex vertical gap={20} align="flex-start" className={classes.mainContainer}>
      {showHeader ? (
        <Row justify="space-between" className={classes.formHeader}>
          <h2 className={classes.heading}>{title}</h2>
          {titleSuffix}
        </Row>
      ) : null}
      <div className={classes.formContent}>
        {uniqueValidations.length > 0 && (
          <div className={classes.validationContainer}>
            {isError && uniqueValidations.length > 0 ? (
              <div className={classes.errorDiv}>
                {uniqueValidations.map((item, i) => (
                  <div key={i}>
                    {/* If there is an error present in current FormComponent, do not show any success messages */}
                    <p className={classes.errorText}>{item.message}</p>
                  </div>
                ))}
              </div>
            ) : isSuccess && uniqueValidations.length > 0 ? (
              <div className={classes.successDiv}>
                {uniqueValidations.map((item, i) => (
                  <div key={i}>
                    {item.messageType === MessageType.ERROR ? (
                      <p className={classes.errorText}>{item.message}</p>
                    ) : item.messageType === MessageType.SUCCESS ? (
                      <p className={classes.successText}>{item.message}</p>
                    ) : (
                      <p className={classes.limitText}>{item.message}</p>
                    )}
                  </div>
                ))}
              </div>
            ) : uniqueValidations && uniqueValidations?.length > 0 ? (
              <div className={classes.warnDiv}>
                {uniqueValidations.map((item, i) => (
                  <div key={i}>
                    {item.messageType === MessageType.ERROR ? (
                      <p className={classes.errorText}>{item.message}</p>
                    ) : item.messageType === MessageType.SUCCESS ? (
                      <p className={classes.successText}>{item.message}</p>
                    ) : (
                      <p className={classes.limitText}>{item.message}</p>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
        {child}
      </div>
    </Flex>
  );
};

export default FormComponent;
