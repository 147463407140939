import { Button, Divider, Form, Input, Modal, Skeleton } from "antd";

import { useContext, useMemo } from "react";
import useMe from "hooks/useMe";
import usePostReviewComments from "hooks/PostReviewComment/usePostReviewComments";
import useCreatePostReviewComment from "hooks/PostReviewComment/useCreatePostReviewComment";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { convertUTCDateToLocal } from "utils/date";
import Comment from "components/Comment";
import usePostVersion from "hooks/posts/usePostVersion";
import { makeSubject } from "utils/access";
import { PostPublishingStatusType } from "types";
import { postStatusTranslated } from "pages/Planner/components/ListView";
import { postStatusColorMapping } from "utils";
import CustomAvatar from "components/CustomAvatar";
import FacebookColored from "../../assets/icons/socials/facebookColored.svg";
import InstagramColored from "../../assets/icons/socials/instagramColored.svg";
import classes from "./index.module.css";

const renderStatus = (status: PostPublishingStatusType) => {
  return (
    <span style={{ color: postStatusColorMapping[status], fontWeight: "bold", fontSize: 12 }}>
      {postStatusTranslated[status]}
    </span>
  );
};

const PostDetailsModal = ({
  postVersionId,
  onCancel,
  title,
  footerType,
  footer,
  ...rest
}: {
  postVersionId: string | undefined | null;
  onCancel: () => void;
  title?: React.ReactNode;
  footerType: "REVIEW" | "EDIT";
  footer?: React.ReactNode;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const { data: postVersion, isLoading } = usePostVersion({
    versionId: postVersionId ?? "",
  });

  const post = postVersion?.post;
  const assets = postVersion?.content?.assets ?? [];

  const { ability } = useContext(UserAccessPolicyContext);

  const canReadComments = ability.can("read", "PostReviewComment");
  const canEditPost =
    post &&
    ability.can(
      "update",
      makeSubject("Post", {
        accountId: post.accountId,
        channelId: post.channelId,
        companyId: post.companyId,
      }),
    );

  const canReviewPost =
    post &&
    ability.can(
      "review",
      makeSubject("PostVersion", { accountId: post.accountId, companyId: post.companyId, channelId: post.channelId }),
    );

  const previewDate = useMemo(() => {
    if (!postVersion) return t("common:placeholders.unknownDate");
    const date = postVersion.postedAt ?? postVersion?.scheduledAt ?? postVersion.createdAt;

    if (!date) return t("common:placeholders.unknownDate");

    return convertUTCDateToLocal(date).format("DD MMM YYYY, HH:mm");
  }, [postVersion, t]);

  const { data: me } = useMe();

  const {
    data: commentsData,
    hasNextPage,
    fetchNextPage,
  } = usePostReviewComments(
    { postId: postVersion?.postId ?? "", include: ["quotedComment", "user"] },
    { enabled: canReadComments },
  );
  const postHasComments = useMemo(() => commentsData?.pages.some((page) => page.items.length > 0), [commentsData]);

  const { mutate: createComment, isLoading: isCreatingComment } = useCreatePostReviewComment({
    onSuccess: () => {
      form.resetFields();
    },
  });

  const [form] = Form.useForm<{ message: string }>();

  return (
    <Modal
      {...rest}
      open={!!postVersionId}
      destroyOnClose
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      title={title}
      footer={
        !isLoading ? (
          (canEditPost && footerType === "EDIT") || (canReviewPost && footerType === "REVIEW") ? (
            footer
          ) : null
        ) : (
          <div className={classes.modalFooter}>
            <Skeleton.Button active={isLoading} size="large" shape="default" block />
          </div>
        )
      }
      styles={{
        content: {
          maxHeight: "85vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          scrollbarWidth: "none",
        },
        footer: {
          marginTop: "auto",
        },
      }}
    >
      <div>
        <div>
          <div className={classes.postPreview}>
            <div className={classes.postPreviewHeader}>
              <div className={classes.postPreviewUser}>
                <img
                  src={post?.channel.type === "FACEBOOK" ? FacebookColored : InstagramColored}
                  width={42}
                  alt="Company profile picture"
                />
                <div className={classes.postPreviewUserTextWrapper}>
                  {post ? <b>{post.channel.name}</b> : <Skeleton.Input />}
                  <span>
                    {!post ? (
                      <Skeleton.Input />
                    ) : post.publishingStatus === PostPublishingStatusType.SCHEDULED ? (
                      renderStatus(PostPublishingStatusType.SCHEDULED)
                    ) : (
                      renderStatus(post.publishingStatus as PostPublishingStatusType)
                    )}
                    <Divider type="vertical" />
                    {!post ? <Skeleton.Input /> : <span style={{ fontSize: 12 }}>{previewDate}</span>}
                  </span>
                </div>
              </div>
              <p className={classes.postPreviewBody}>{postVersion?.content?.body}</p>
            </div>
            {assets.length ? (
              <div className={classes.postPreviewAssetsContainer}>
                <Slider
                  dots
                  infinite
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  arrows
                  adaptiveHeight
                  className={classes.slider}
                >
                  {assets?.map((asset, i) => {
                    if (asset.type === "VIDEO") {
                      return (
                        <video controls key={i + asset.url} className={classes.previewAsset}>
                          <source src={asset.url} />
                        </video>
                      );
                    }
                    return <img src={asset.url} className={classes.previewAsset} key={i + asset.url} />;
                  })}
                </Slider>
              </div>
            ) : null}
          </div>
        </div>
        {canReadComments ? (
          <div className={classes.commentSection}>
            <span className={classes.commentSectionTitle}>{t("pages:comments.commentSectionTitle")}</span>
            <div className={classes.commentsWrapper}>
              {postHasComments ? (
                commentsData?.pages.map((page, pageIdx) =>
                  page.items.map((comment, idx) => {
                    return (
                      <>
                        <Comment key={comment.id} comment={comment} />
                        {pageIdx === commentsData.pages.length - 1 && idx === page.items.length - 1 ? null : (
                          <Divider className={classes.commentDivider} />
                        )}
                      </>
                    );
                  }),
                )
              ) : (
                <span style={{ margin: "0 auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                  <p style={{ color: "#000" }}>{t("pages:comments.noCommentsAvailableMessage")}</p>
                </span>
              )}
            </div>
            <Divider className={classes.commentDivider} />
            {hasNextPage ? (
              <Button
                className={classes.loadMoreCommentsButton}
                type="link"
                onClick={async () => {
                  await fetchNextPage();
                }}
              >
                {t("pages:comments.loadMoreCommentsButton")}
              </Button>
            ) : null}
            <Form
              className={classes.createCommentForm}
              onFinish={(values) => {
                createComment({
                  postVersionId: postVersion?.id ?? "",
                  message: values.message,
                });
              }}
              form={form}
              disabled={isCreatingComment}
            >
              <div className={classes.createCommentFormContainer}>
                <CustomAvatar src={me?.user_profile_pic} alt={`${me?.name ?? "User"}'s profile picture`}>
                  {me?.name?.[0] ?? "U"}
                </CustomAvatar>
                <div className={classes.createCommentInputWrapper}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("pages:comments.emptyCommentMessageError"),
                      },
                      {
                        max: 2400,
                        message: t("pages:comments.emptyCommentMessageMaxLenError"),
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea style={{ width: "100%" }} placeholder={t("pages:comments.commentsPlaceholder")} />
                  </Form.Item>
                </div>
              </div>
              <Button
                className={classes.createCommentButton}
                disabled={isCreatingComment}
                loading={isCreatingComment}
                htmlType="submit"
              >
                {t("pages:comments.addCommentButton")}
              </Button>
            </Form>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default PostDetailsModal;
