import { useContext, useMemo, useState } from "react";
import { Badge, Dropdown, message, Spin, Modal, Button, Flex } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { TODO_STATUS, Todo, PLATFORMS, TODO_PRIORITY } from "types";
import { TodosAPI } from "services/api";
import { convertUTCDateToLocal } from "utils/date";
import { UsePaginationState } from "hooks/usePagination";
import useCompanies from "hooks/company/useCompanies";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useTranslation } from "react-i18next";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import useCompany from "hooks/company/useCompany";
import CustomTable from "components/CustomTable";
import CustomBadge from "components/CustomBadge";
import FacebookIcon from "../../../assets/icons/socials/facebook.svg";
import InstagramIcon from "../../../assets/icons/socials/instagram.svg";
import { statusTranslated, priorityTranslated } from "../constants";
import classes from "./Table.module.css";

export const StatusIcon = ({ status }: { status: string | undefined }) => {
  return (
    <Badge
      status={status === TODO_STATUS.OPEN ? "error" : status === TODO_STATUS.IN_PROGRESS ? "warning" : "success"}
    />
  );
};

export type TodosTableFilters = {
  companyId?: string[] | null;
  status?: TODO_STATUS[];
  priority?: TODO_PRIORITY[];
};

export default function TodosTable({
  data,
  openModalHandler,
  setSelectedTodoId,
  invalidateData,
  paginationState,
  total,
  filters,
  setFilters,
}: {
  data: Todo[];
  invalidateData: () => void;
  openModalHandler: () => void;
  setSelectedTodoId: (todoId: string) => void;
  paginationState: UsePaginationState;
  total: number;
  filters: TodosTableFilters;
  setFilters: (filters: TodosTableFilters) => void;
}) {
  const { ability } = useContext(UserAccessPolicyContext);
  const [deleteTodoId, setDeleteTodoId] = useState<string>("");

  const { selectedCompanyId } = useSelectedAccount();

  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId } = useSelectedAccount({ companyRequired: false });
  const { data: companiesData } = useCompanies({ accountId: selectedAccountId }, { enabled: !selectedCompanyId });
  const { data: company } = useCompany(selectedCompanyId);

  const companies = companiesData?.items;

  const companyFilters = useMemo(() => {
    const filters =
      selectedCompanyId && company
        ? [{ text: company.name, value: company.id }]
        : companies?.map((company) => ({ text: company.name, value: company.id })) ?? [];
    filters.push({ text: "N/A", value: "None" });
    return filters;
  }, [companies, selectedCompanyId, company]);

  const { mutate: updateStatus, isLoading: isUpdateStatusLoading } = useMutation(
    "updateStatus",
    async ({
      todoId,
      accountId,
      companyId,
      status,
    }: {
      todoId: string;
      accountId: string;
      companyId?: string;
      status: TODO_STATUS;
    }) => await TodosAPI.updateStatus(todoId, status, accountId, companyId),
    {
      onSuccess: () => {
        message.success("Status erfolgreich aktualisiert");
        invalidateData();
      },
      onError: (error: ErrorEvent) => {
        message.error(error.message);
      },
    },
  );

  const { mutate: deleteTodo, isLoading: isDeleteTodoLoading } = useMutation(
    "deleteTodo",
    async ({ todoId }: { todoId: string }) => await TodosAPI.delete(todoId),
    {
      onSuccess: () => {
        message.success("Aufgabe erfolgreich gelöscht");
        invalidateData();
      },
      onError: (error: ErrorEvent) => {
        message.error(error.message);
      },
      onSettled: () => {
        setDeleteTodoId("");
      },
    },
  );

  const handleCloseModal = () => {
    setDeleteTodoId("");
  };

  const tableData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        canUpdate: ability.can(
          "update",
          makeSubject("Todo", {
            accountId: item.accountId,
            companyId: item.companyId,
            assignedToId: item.assignedTo,
            userId: item.createdBy,
          }),
        ),
        canDelete: ability.can(
          "delete",
          makeSubject("Todo", {
            accountId: item.accountId,
            companyId: item.companyId,
            assignedToId: item.assignedTo,
            userId: item.createdBy,
          }),
        ),
      })),
    [data, ability],
  );

  return (
    <Spin spinning={isUpdateStatusLoading || isDeleteTodoLoading}>
      <Modal
        onCancel={handleCloseModal}
        open={!!deleteTodoId}
        title={
          <div style={{ paddingRight: "10px" }}>
            <h3>Bist du dir sicher, dass du diese Aufgabe löschen möchtest? </h3>
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="default" className={classes.cancelButton} onClick={handleCloseModal}>
              Nein
            </Button>
            <Button
              type="primary"
              danger
              className={classes.createButton}
              onClick={() => deleteTodoId && deleteTodo({ todoId: deleteTodoId })}
              loading={isDeleteTodoLoading}
            >
              Ja
            </Button>
          </div>
        }
      />
      <CustomTable<(typeof tableData)[number]>
        defaultProps={{
          dataSource: tableData,
          className: classes.Table,
          onChange: (_, filters) => {
            setFilters(filters);
          },
          columns: [
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              className: classes.StatusColumn,
              render: (status: string, record) => (
                <Flex align="center" justify="space-between">
                  <CustomBadge
                    color={status === TODO_STATUS.DONE ? "green" : status === TODO_STATUS.IN_PROGRESS ? "gray" : "red"}
                    size="sm"
                  >
                    {statusTranslated[status] ?? status}
                  </CustomBadge>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "edit",
                          label: "Ansehen/Bearbeiten",
                          onClick: () => {
                            openModalHandler();
                            setSelectedTodoId(record.id);
                          },
                          disabled: !record.canUpdate,
                        },
                        {
                          key: "statusEdit",
                          label: "Status Bearbeiten",
                          children: Object.keys(statusTranslated).map((key) => ({
                            key,
                            label: statusTranslated[key],
                            onClick: () => {
                              updateStatus({
                                todoId: record.id,
                                status: key as TODO_STATUS,
                                accountId: record.accountId,
                                companyId: record.companyId,
                              });
                            },
                            disabled: key === status,
                          })),
                          disabled: !record.canUpdate,
                        },
                        {
                          key: "2",
                          label: "Löschen",
                          onClick: () => {
                            setDeleteTodoId(record.id);
                          },
                          disabled: !record.canDelete,
                        },
                      ],
                    }}
                  >
                    <EllipsisOutlined style={{ fontWeight: "bold" }} />
                  </Dropdown>
                </Flex>
              ),
              filteredValue: filters.status,
              filters: Object.keys(statusTranslated).map((key) => ({
                text: statusTranslated[key],
                value: key,
              })),
            },
            {
              title: "Aufgabe",
              dataIndex: "title",
              key: "title",
              width: 500,
              render: (title: string) => {
                return <div className={classes.TitleContainer}>{title}</div>;
              },
            },
            {
              title: "Priorität",
              dataIndex: "priority",
              key: "priority",
              render: (priority: string) => {
                return <span>{priorityTranslated[priority] ?? priority}</span>;
              },
              filteredValue: filters.priority,
              filters: Object.keys(priorityTranslated).map((key) => ({
                text: priorityTranslated[key],
                value: key,
              })),
            },
            {
              title: "Zugewiesen an",
              dataIndex: "assignedTo",
              key: "assignedTo",
              width: 250,
              render: (_, record: Todo) => {
                return <div className={classes.AssignedToColumn}>{record?.assignee?.name ?? ""}</div>;
              },
              sorter: (a: Todo, b: Todo) => {
                if (a.assignee && b.assignee) {
                  return a.assignee.name.localeCompare(b.assignee.name);
                } else {
                  return 0;
                }
              },
            },
            {
              title: t("pages:todos.companyLabel"),
              dataIndex: "companyId",
              key: "companyId",
              filters: companyFilters,
              filteredValue: filters.companyId,
              width: 250,
              render: (_, record: Todo) => {
                return <div>{record.company?.name ?? "N/A"}</div>;
              },
              sorter: (a: Todo, b: Todo) => {
                if (a.company && b.company) {
                  return a.company.name.localeCompare(b.company.name);
                } else {
                  return 0;
                }
              },
            },
            {
              title: t("pages:todos.assignedByLabel"),
              dataIndex: "creator",
              key: "creator",
              width: 250,
              render: (_, record: Todo) => {
                return <div className={classes.AssignedByColumn}>{record?.creator?.name ?? ""}</div>;
              },
              sorter: (a: Todo, b: Todo) => {
                if (a.creator && b.creator) {
                  return a.creator.name.localeCompare(b.creator.name);
                } else {
                  return 0;
                }
              },
            },
            {
              title: "Plattform",
              dataIndex: "platform",
              key: "platform",
              className: classes.PlatformColumn,
              render: (platform: string[]) => {
                return Object.keys(PLATFORMS).map((key) => {
                  if (platform.includes(key)) {
                    return (
                      <img
                        key={key}
                        src={key === PLATFORMS.FACEBOOK ? FacebookIcon : InstagramIcon}
                        alt={key}
                        className={classes.PlatformIcon}
                      />
                    );
                  }
                  return null;
                });
              },
            },

            {
              title: "Fällig am",
              dataIndex: "dueAt",
              className: classes.DueDateColumn,
              key: "dueAt",
              render: (dueAt: string) => {
                return <span>{convertUTCDateToLocal(dueAt).format("DD.MM.YYYY HH.mm[h]")}</span>;
              },
            },
          ],
        }}
        paginationProps={{
          ...paginationState,
          total,
        }}
      />
    </Spin>
  );
}
