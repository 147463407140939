import { useQuery } from "react-query";
import { checkAuth } from "utils/checkAuth";
import useMe from "./useMe";

const useIsLoggedIn = (params?: unknown) => {
  const {
    data: isLoggedIn,
    isLoading: isLoadingLoggedin,
    isError: isLoggedInError,
    isFetching: isFetchingLoggedin,
  } = useQuery({
    queryKey: ["useIsLoggedIn", params],
    queryFn: async () =>
      await checkAuth()
        .then(() => true)
        .catch(() => false),
    retry: false,
    refetchOnWindowFocus: true,
  });

  const {
    data,
    isLoading: isLoadingMe,
    isError: isMeError,
    isFetching: isFetchingMe,
  } = useMe(undefined, {
    retry: false,
    refetchOnWindowFocus: true,
    enabled: isLoggedIn,
  });

  return {
    isLoading: isLoadingLoggedin || isLoadingMe,
    isLoggedIn: !!data && isLoggedIn,
    isError: isMeError || isLoggedInError,
    isFetching: isFetchingMe || isFetchingLoggedin,
  };
};

export default useIsLoggedIn;
