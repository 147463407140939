import type { CookieConsentConfig } from "vanilla-cookieconsent";

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "left",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    // analytics: {
    //   autoClear: {
    //     cookies: [
    //       {
    //         name: /^(_ga|_gid)/,
    //       },
    //     ],
    //   },
    // },
  },

  language: {
    default: "de",

    translations: {
      de: {
        consentModal: {
          title: "Cookie-Zustimmung verwalten",
          description:
            "Wir respektieren Ihre Privatsphäre. Diese Website verwendet Cookies und ähnliche Technologien zur Optimierung des Online-Erlebnisses, zur Analyse des Nutzerverhaltens und für personalisierte Werbung. Sie können Ihre Einwilligung jederzeit anpassen. Ohne Zustimmung könnten jedoch einige Funktionen der Website eingeschränkt sein.",
          acceptAllBtn: "Alle Akzeptieren",
          // acceptNecessaryBtn: "Verwerfen",
          showPreferencesBtn: "Einstellungen",
          footer: `
            <a href="https://easypostlab.com/datenschutzerklaerung"
            rel="noreferrer noopener"
            target="_blank">Datenschutz</a>
            <a href="https://easypostlab.com/impressum"
            rel="noreferrer noopener"
            target="_blank">Impressum</a>
          `,
        },
        preferencesModal: {
          title: "Cookie-Zustimmung verwalten",
          acceptAllBtn: "Alle Akzeptieren",
          // acceptNecessaryBtn: "Verwerfen",
          savePreferencesBtn: "Einstellungen speichern",
          closeIconLabel: "Schließen",
          sections: [
            {
              description:
                "Wir respektieren Ihre Privatsphäre. Diese Website verwendet Cookies und ähnliche Technologien zur Optimierung des Online-Erlebnisses, zur Analyse des Nutzerverhaltens und für personalisierte Werbung. Sie können Ihre Einwilligung jederzeit anpassen. Ohne Zustimmung könnten jedoch einige Funktionen der Website eingeschränkt sein.",
            },
            {
              title: "Notwendige Cookies",
              description:
                "Die Speicherung technischer Daten oder der Zugriff darauf ist streng notwendig, um den von Ihnen angeforderten Dienst zu ermöglichen oder Nachrichtenübertragungen in elektronischen Kommunikationsnetzen zuverlässig durchzuführen.",
              linkedCategory: "necessary",
            },
            // {
            //   title: "Performance and Analytics cookies",
            // description:Technische Speicherung oder Zugriff werden genutzt, um Nutzerprofile für Werbezwecke zu erstellen und um das Nutzerverhalten auf dieser sowie über verschiedene Websites hinweg für Marketingzwecke zu analysieren.",
            //   linkedCategory: "analytics",
            //   cookieTable: {
            //     headers: {
            //       name: "Name",
            //       domain: "Service",
            //       description: "Description",
            //       expiration: "Expiration",
            //     },
            //     body: [
            //       {
            //         name: "_ga",
            //         domain: "Google Analytics",
            //         description: 'Cookie set by <a href="#das">Google Analytics</a>.',
            //         expiration: "Expires after 12 days",
            //       },
            //       {
            //         name: "_gid",
            //         domain: "Google Analytics",
            //         description: 'Cookie set by <a href="#das">Google Analytics</a>',
            //         expiration: "Session",
            //       },
            //     ],
            //   },
            // },
            // {
            //   title: "Cookie-Richtlinie",
            //   description:
            //     'Lesen Sie unsere <a href="#link">Cookie-Richtlinie</a> für weitere Informationen zu Cookies und deren Verwendung.',
            // },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
