import { PostApi, PostFormBody } from "services/api";
import { CreatePost, PostPublishingStatusType } from "types";

export const getPost = async (id: string) => {
  return await PostApi.getById(id);
};

export const createUpdatePost = async (localPost: CreatePost) => {
  let body: PostFormBody = {
    accountId: localPost.accountId,
    companyId: localPost.companyId,
    text: localPost.body,
    platform: localPost.platforms.map((p) => p.split("__")[0]),
    images: localPost.assets,
    publishingStatus: localPost.publishingStatus,
  };

  if (localPost.publishingStatus === PostPublishingStatusType.SCHEDULING) {
    body = {
      ...body,
      facebookScheduledAt: localPost.schedule?.facebookScheduledAt,
      instagramScheduledAt: localPost.schedule?.instagramScheduledAt,
    };
  }

  if (localPost.versionId) {
    return await PostApi.update(localPost.versionId, body, localPost.publishMobile ?? false);
  }

  return await PostApi.create(body, localPost.publishMobile ?? false);
};

export const checkScheduleStatus = (accountId: string, companyId: string) => {
  return PostApi.checkScheduleStatus(accountId, companyId);
};
