import { useEffect, useState } from "react";
import { Modal, Form, Button, Select, Input, Tabs } from "antd";
import { useQuery } from "react-query";
import { useRouterContext } from "@pankod/refine-core";
import { Account, ExistingModalProps, DescriptionOptionData } from "types";
import { CampaignApi } from "services/api";
import useQueryCacheData from "hooks/useQueryCacheData";
import classes from "./ExistingModal.module.css";

const { Option } = Select;
const { TabPane } = Tabs;

export default function ExistingModal({ visible, handleCloseModal, campaignList }: ExistingModalProps) {
  const [form] = Form.useForm();
  const { push } = useRouterContext().useHistory();
  const [campaignName, setCampaignName] = useState("");
  const [campaignType, setCampaignType] = useState("");
  const [adsetName, setAdsetName] = useState("");
  const [adsName, setAdsName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [adsetId, setAdsetId] = useState("");
  const [adsetType, setAdsetType] = useState("create-adset");
  const [adsetList, setAdsetList] = useState<DescriptionOptionData[]>([]);
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [activeTab, setActiveTab] = useState<string>("create-campaign");

  const { refetch } = useQuery(
    "adset-list-existing",
    () => {
      return CampaignApi.getAdsetList(accounts[0].id, "", "", campaignId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const adset = data.adsets.map((event) => ({
          label: event.name,
          value: event.id,
          description: `${event.id}•campaign: ${campaignName}`,
        }));
        setAdsetList(adset);
      },
    },
  );

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleCampaignSelect = (value: string) => {
    setCampaignId(value);
    const foundItem = campaignList.find((item) => item.value === value);
    if (foundItem) {
      setCampaignName(foundItem.label);
      setCampaignType(foundItem.type);
    }
  };

  const handleAdsetSelect = (value: string) => {
    setAdsetId(value);
  };

  const handleSubmit = () => {
    if (campaignId && adsetId && adsName) {
      push(`/user/campaign/${campaignType.toLowerCase()}/${campaignId}/adset/${adsetId}/ad`);
    } else if (campaignId && adsetId) {
      push(`/user/campaign/${campaignType.toLowerCase()}/${campaignId}/adset/${adsetId}/ad`);
    } else if (campaignId && adsetName) {
      push(`/user/campaign/${campaignType.toLowerCase()}/${campaignId}/adset`);
    } else {
      push(`/user/campaign/${campaignType.toLowerCase()}/${campaignId}/adset`);
    }
  };

  useEffect(() => {
    if (campaignId) {
      refetch();
    }
  }, [campaignId]);

  return (
    <div>
      <Modal
        className={classes.ExistingModal}
        width={550}
        style={{ borderRadius: 0, marginTop: 0 }}
        maskClosable={false}
        onCancel={handleCloseModal}
        open={visible}
        footer={null}
      >
        <Tabs
          className={classes.ExistingTabs}
          activeKey={activeTab}
          onChange={handleTabChange}
          style={{ marginTop: 40 }}
        >
          <TabPane tab="Neue Kampagne erstellen" key="create-campaign">
            <Button
              style={{ padding: 8 }}
              type="primary"
              onClick={() => {
                localStorage.removeItem("campaignId");
                localStorage.removeItem("adsetId");
                localStorage.removeItem("adsName");
                localStorage.removeItem("adsetName");
                push("/user/campaign/new");
              }}
            >
              Kampagne erstellen
            </Button>
          </TabPane>
          <TabPane tab="Neue Anzeigengruppe oder Anzeige" key="new-adset-ad">
            <Form form={form} layout="vertical">
              <Form.Item label="Kampagne" labelCol={{ offset: 0, span: 24 }} wrapperCol={{ offset: 0, span: 24 }}>
                <Select
                  placeholder="Bestehende Kampagne verwenden"
                  showSearch
                  allowClear
                  onClear={() => console.log("called", campaignId)}
                  value={campaignId}
                  onChange={handleCampaignSelect}
                  notFoundContent={
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                      <span>
                        <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                        <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                      </span>
                    </div>
                  }
                >
                  {campaignList.map((option, index) => (
                    <Option value={option.value}>
                      <div style={{ whiteSpace: "break-spaces" }}>
                        <h4>{option.label}</h4>
                        <span>{option.description}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {campaignId ? (
                <Form.Item
                  label="Anzeigengruppe"
                  style={{ marginLeft: 20 }}
                  labelCol={{ offset: 2, span: 23 }}
                  wrapperCol={{ offset: 1, span: 23 }}
                >
                  <Select
                    style={{ width: "100%", marginRight: 10, marginBottom: 10 }}
                    value={adsetType}
                    onSelect={(e) => setAdsetType(e)}
                  >
                    <Option value="create-adset">Anzeigengruppe erstellen</Option>
                    <Option value="existing-adset">Vorhandene Anzeigengruppe verwenden</Option>
                  </Select>
                  {adsetType === "create-adset" ? (
                    <Input
                      placeholder="Titel Anzeigengruppe"
                      className={classes.AdsetInput}
                      value={adsetName}
                      onChange={(e) => {
                        setAdsetName(e.target.value);
                      }}
                    />
                  ) : (
                    <Select
                      showSearch
                      allowClear
                      notFoundContent={
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                          <span>
                            <img
                              src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                              alt="emptyImage"
                            />
                            <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                          </span>
                        </div>
                      }
                      placeholder="Vorhandene Anzeigengruppe verwenden"
                      value={adsetId}
                      onChange={handleAdsetSelect}
                    >
                      {adsetList.map((option) => (
                        <Option value={option.value}>
                          <div style={{ whiteSpace: "break-spaces" }}>
                            <h4>{option.label}</h4>
                            <span>{option.description}</span>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
              {adsetId && adsetType !== "create-adset" ? (
                <Form.Item
                  label="Werbung"
                  style={{ marginLeft: 40 }}
                  labelCol={{ offset: 2, span: 23 }}
                  wrapperCol={{ offset: 1, span: 23 }}
                >
                  <Input
                    className={classes.AdsetInput}
                    style={{ width: "40%", marginBottom: 8 }}
                    value="Werbung erstellen"
                    disabled
                  />
                  {/* <Option value='create-adset'></Option> */}

                  <Input
                    placeholder="Anzeigentitel"
                    className={classes.AdsetInput}
                    value={adsName}
                    onChange={(e) => {
                      setAdsName(e.target.value);
                    }}
                  />
                </Form.Item>
              ) : null}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="default" className={classes.cancelButton} onClick={() => handleCloseModal()}>
                  Abbrechen
                </Button>
                <Button
                  type={campaignId ? "primary" : "default"}
                  className={classes.createButton}
                  disabled={campaignId === ""}
                  onClick={handleSubmit}
                >
                  Weiter
                </Button>
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}
