import React, { useMemo, useState } from "react";
import { Card, Divider, Select, Spin } from "antd";
import Chart from "react-apexcharts";
import ChartStat from "pages/ChartStat";
import { mergeClasses } from "utils/mergeClasses";
import { useTranslation } from "react-i18next";
import { ApexOptions } from "apexcharts";
import {
  FacebookConsumptionMetrics,
  FacebookMetrics,
  InsightDatePreset,
  InsightResponse,
  InstagramMetrics,
  MetricPeriod,
} from "types";
import useSelectedAccount from "hooks/useSelectedAccount";
import useInsights, { GroupedInsights, groupInsightsByMonth } from "hooks/Insight/useInsights";
import dayjs from "dayjs";
import ReachChart from "components/ReachChart";
import useInsightConsumption from "hooks/Insight/useInsightConsumption";
import formatNumber from "utils/formatNumber";
import { ReactComponent as FacebookGray } from "../../../assets/FacebookGray.svg";
import { ReactComponent as InstagramGray } from "../../../assets/InstagramGray.svg";
import { ReactComponent as RevenueIcon } from "../../../assets/Revenue2.svg";
import StatChartCard, { createStatChartCardOptions } from "../components/StatChartCard";
import classes from "./index.module.css";

const FbCard = ({
  children,
  title,
  subtitle,
  loading,
}: React.PropsWithChildren & {
  title: string;
  subtitle: string;
  loading: boolean;
}) => {
  return (
    <Card className={mergeClasses(classes.halfScreenCard, classes.fbCard)}>
      <Spin spinning={loading}>
        <div className={classes.fbCardWrapper}>
          <div className={classes.fbCardHeader}>
            <strong>{title}</strong>
            <p>{subtitle}</p>
          </div>
          <div className={classes.fbCardContent}>{children}</div>
        </div>
      </Spin>
    </Card>
  );
};

const currYear = dayjs().year();
const lastYear = currYear - 1;
const OverviewChart = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();
  const [datePreset, setDatePreset] = useState(InsightDatePreset.this_year);

  const { data, isLoading: isLoadingInsights } = useInsights({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [
      FacebookMetrics.page_follows,
      FacebookMetrics.page_impressions_unique,
      FacebookMetrics.page_impressions_paid_unique,
    ],
    instagram_metrics: [InstagramMetrics.impressions, InstagramMetrics.follower_count, InstagramMetrics.reach],
    date_preset: datePreset,
    period: MetricPeriod.day,
  });
  const stats = useMemo(() => (data ? groupInsightsByMonth(data) : undefined), [data]);

  const { categories, reachOnFb, reachOnIg, paidReach } = useMemo(() => {
    const categories: string[] = [];
    const reachOnFb: number[] = [];
    const reachOnIg: number[] = [];
    const paidReach: number[] = [];

    for (let i = 0; i < 12; i++) {
      let monthDate = dayjs().set("month", i);
      if (datePreset === InsightDatePreset.last_year) {
        monthDate = monthDate.set("year", monthDate.year() - 1);
      }

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthReachOnFb =
        stats?.facebook.page_impressions_unique?.data.find((item) => item.month === month)?.value ?? 0;

      const monthReachOnIg = stats?.instagram.reach?.data.find((item) => item.month === month)?.value ?? 0;
      const monthPaidReach =
        stats?.facebook.page_impressions_paid_unique?.data.find((item) => item.month === month)?.value ?? 0;

      reachOnFb.push(monthReachOnFb);
      reachOnIg.push(monthReachOnIg);
      paidReach.push(monthPaidReach);
    }

    return { categories, reachOnFb, reachOnIg, paidReach };
  }, [stats, datePreset]);

  return (
    <section>
      <Card className={classes.postsCard}>
        <Spin spinning={isLoadingInsights}>
          <div className={classes.postsCardHeader}>
            <div className={classes.postsCardHeaderTitle}>
              <strong>{t("pages:statsOverview.postReachTitle")}</strong>
              <p>{t("pages:statsOverview.postReachSubtitle")}</p>
            </div>
            <Select
              className={classes.postsCardSelect}
              value={datePreset}
              onChange={setDatePreset}
              defaultValue={InsightDatePreset.this_year}
              options={[
                {
                  label: `${t("common:labels.currYear")} (${currYear})`,
                  value: InsightDatePreset.this_year,
                },
                {
                  label: `${t("common:labels.lastYear")} (${lastYear})`,
                  value: InsightDatePreset.last_year,
                },
              ]}
            />
          </div>
          <Divider style={{ marginTop: 10, marginBottom: 20 }} />
          <div className={classes.postsCardContent}>
            <div className={classes.postsCardStats}>
              <ChartStat
                icon={<FacebookGray />}
                text={t("pages:dashboard.reachOnFbLabel")}
                value={stats?.fbReach ?? 0}
              />
              <ChartStat
                icon={<InstagramGray />}
                text={t("pages:dashboard.reachOnIgLabel")}
                value={stats?.igReach ?? 0}
              />
              <ChartStat
                icon={<RevenueIcon />}
                text={t("pages:dashboard.paidReachLabel")}
                value={stats?.paidReach ?? 0}
              />
            </div>
            <ReachChart categories={categories} paidReach={paidReach} reachOnFb={reachOnFb} reachOnIg={reachOnIg} />
          </div>
        </Spin>
      </Card>
    </section>
  );
};

const pieChartColors = [
  "hsla(16, 94%, 88%, 1)",
  "hsla(17, 92%, 63%, 1)",
  "hsla(16, 84%, 51%, 1)",
  "hsla(16, 89%, 42%, 1)",
];

const PieCard = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const { data: consumptionData, isLoading: isLoadingConsumptionData } = useInsightConsumption({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [FacebookConsumptionMetrics.page_consumptions_by_consumption_type],
  });

  const piechartData = useMemo(() => {
    let linkClicks = 0;
    let otherClicks = 0;
    let photoViews = 0;
    let videoPlays = 0;

    if (
      consumptionData?.facebook?.page_consumptions_by_consumption_type &&
      Array.isArray(consumptionData.facebook.page_consumptions_by_consumption_type)
    ) {
      for (const item of consumptionData.facebook.page_consumptions_by_consumption_type) {
        linkClicks += item?.value?.["link clicks"] ?? 0;
        otherClicks += item?.value?.["other clicks"] ?? 0;
        photoViews += item?.value?.["photo view"] ?? 0;
        videoPlays += item?.value?.["video play"] ?? 0;
      }
    }

    const items = [
      {
        value: photoViews,
        name: "Bilder",
        color: pieChartColors[0],
      },
      {
        value: videoPlays,
        name: "Videos",
        color: pieChartColors[1],
      },
      {
        value: linkClicks,
        name: "Link Klicks",
        color: pieChartColors[2],
      },
      {
        value: otherClicks,
        name: "Other Klicks",
        color: pieChartColors[3],
      },
    ];

    const series = items.map((item) => item.value);
    const labels = items.map((item) => item.name);

    return {
      chartData: {
        series,
        chart: {
          width: "100%",
          type: "pie",
          toolbar: {
            show: false,
          },
        },
        labels,
        legend: {
          show: false,
        },
        grid: {
          padding: {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          },
        },
        colors: pieChartColors,
      } satisfies ApexOptions,
      isEmpty: series.every((item) => item === 0),
      items,
    };
  }, [consumptionData]);

  return (
    <Card className={classes.pieCard}>
      <Spin spinning={isLoadingConsumptionData}>
        <div className={classes.pieCardWrapper}>
          <div className={classes.pieCardHeader}>
            <p className={classes.pieCardTitle}>{t("pages:statsOverview.reachByMediaText")}</p>
          </div>
          <div className={classes.pieCardContentWrapper}>
            <div className={classes.pieCardContent}>
              <div className={classes.pieCardChart}>
                {!piechartData.isEmpty ? (
                  <Chart
                    series={piechartData.chartData.series}
                    options={piechartData.chartData}
                    type="pie"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                    <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                  </span>
                )}
              </div>
              <ul className={classes.pieCardLegend}>
                {piechartData.items.map(({ name, color, value }) => {
                  return (
                    <li>
                      <p>
                        <span style={{ background: color }} className={classes.pieCardLegendDot} />
                        {name}
                      </p>
                      <strong>{formatNumber(value, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Spin>
    </Card>
  );
};

const createBarChartOptions = ({ series }: { series: ApexOptions["series"] }) => {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["hsla(214, 100%, 57%, 1)"],
    series,
    legend: {
      show: false,
    },
  } satisfies ApexOptions;
};

const FbPostReachBarCard = ({
  stats,
  loading,
}: {
  data?: InsightResponse;
  stats?: GroupedInsights;
  loading: boolean;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const chartOptions = useMemo(() => {
    const currMonth = dayjs().month();
    const currYear = dayjs().year();

    const currQStartMonth = dayjs().subtract(3, "month").month();
    const currQStartYear = dayjs().subtract(3, "month").year();
    const lastQStartMonth = dayjs().subtract(6, "month").month();
    const lastQStartYear = dayjs().subtract(6, "month").year();

    let currQuarterData = 0;
    let lastQuarterData = 0;

    if (stats?.facebook.page_impressions_unique) {
      for (const item of stats.facebook.page_impressions_unique.data) {
        if (
          item.year >= lastQStartYear &&
          item.year <= currQStartYear &&
          item.month >= lastQStartMonth &&
          item.month <= currQStartMonth
        ) {
          lastQuarterData += item.value;
        }

        if (
          item.year >= currQStartYear &&
          item.year <= currYear &&
          item.month >= currQStartMonth &&
          item.month <= currMonth
        ) {
          currQuarterData += item.value;
        }
      }
    }

    return createBarChartOptions({
      series: [
        {
          data: [
            {
              x: "90 Tage davor",
              y: currQuarterData,
            },
            {
              x: "Letzten 90 Tage",
              y: lastQuarterData,
            },
          ],
        },
      ],
    });
  }, [stats]);

  return (
    <FbCard
      title={t("pages:statsOverview.fbPostReachTitle")}
      subtitle={t("pages:statsOverview.fbPostReachSubtitle")}
      loading={loading}
    >
      <Chart series={chartOptions.series} options={chartOptions} type="bar" width="100%" height="100%" />
    </FbCard>
  );
};

const IgEngagementBarCard = ({
  stats,
  loading,
}: {
  data?: InsightResponse;
  stats?: GroupedInsights;
  loading: boolean;
}) => {
  const { t } = useTranslation(["common", "pages"]);

  const chartOptions = useMemo(() => {
    const currMonth = dayjs().month();
    const currYear = dayjs().year();

    const currQStartMonth = dayjs().subtract(3, "month").month();
    const currQStartYear = dayjs().subtract(3, "month").year();
    const lastQStartMonth = dayjs().subtract(6, "month").month();
    const lastQStartYear = dayjs().subtract(6, "month").year();

    let currQuarterData = 0;
    let lastQuarterData = 0;

    if (stats?.instagram.impressions) {
      for (const item of stats.instagram.impressions.data) {
        if (
          item.year >= lastQStartYear &&
          item.year <= currQStartYear &&
          item.month >= lastQStartMonth &&
          item.month <= currQStartMonth
        ) {
          lastQuarterData += item.value;
        }

        if (
          item.year >= currQStartYear &&
          item.year <= currYear &&
          item.month >= currQStartMonth &&
          item.month <= currMonth
        ) {
          currQuarterData += item.value;
        }
      }
    }

    return createBarChartOptions({
      series: [
        {
          data: [
            {
              x: "90 Tage davor",
              y: currQuarterData,
            },
            {
              x: "Letzten 90 Tage",
              y: lastQuarterData,
            },
          ],
        },
      ],
    });
  }, [stats]);

  return (
    <FbCard
      title={t("pages:statsOverview.igEngagementTitle")}
      subtitle={t("pages:statsOverview.igEngagementSubtitle")}
      loading={loading}
    >
      <Chart series={chartOptions.series} options={chartOptions} type="bar" width="100%" height="100%" />
    </FbCard>
  );
};

const StatsOverview = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const { data, isLoading: isLoadingInsights } = useInsights({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [
      FacebookMetrics.page_follows,
      FacebookMetrics.page_impressions_unique,
      FacebookMetrics.page_impressions_paid_unique,
    ],
    instagram_metrics: [InstagramMetrics.impressions, InstagramMetrics.follower_count, InstagramMetrics.reach],
    date_preset: InsightDatePreset.this_year,
    period: MetricPeriod.day,
  });
  const stats = useMemo(() => (data ? groupInsightsByMonth(data) : undefined), [data]);

  const fbFollowersData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      const monthDate = dayjs().set("month", i);

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.facebook.page_follows?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "blue",
        categories,
        data: monthlyData,
        name: t("pages:statsOverview.fbFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.facebook.page_follows?.total ?? 0,
    };
  }, [stats, t]);

  const igFollowersData = useMemo(() => {
    const categories: string[] = [];
    const monthlyData: number[] = [];

    for (let i = 0; i < 12; i++) {
      const monthDate = dayjs().set("month", i);

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthData = stats?.instagram.follower_count?.data.find((item) => item.month === month)?.value ?? 0;

      monthlyData.push(monthData);
    }

    return {
      chartData: createStatChartCardOptions({
        color: "red",
        categories,
        data: monthlyData,
        name: t("pages:statsOverview.igFollowersLabel"),
        yaxisText: t("pages:statsOverview.followersLabel"),
      }),
      total: stats?.instagram.follower_count?.total ?? 0,
    };
  }, [stats, t]);

  return (
    <div className={classes.container}>
      <OverviewChart />
      <section className={classes.followersSection}>
        <StatChartCard
          icon={<FacebookGray />}
          title={t("pages:statsOverview.fbFollowersLabel")}
          stat={fbFollowersData.total}
          loading={isLoadingInsights}
        >
          <Chart
            series={fbFollowersData.chartData.series}
            options={fbFollowersData.chartData}
            type="line"
            width="100%"
            height="100%"
          />
        </StatChartCard>
        <StatChartCard
          icon={<InstagramGray />}
          title={t("pages:statsOverview.igFollowersLabel")}
          stat={igFollowersData.total}
          loading={isLoadingInsights}
        >
          <Chart
            series={igFollowersData.chartData.series}
            options={igFollowersData.chartData}
            type="line"
            width="100%"
            height="100%"
          />
        </StatChartCard>
      </section>
      <section className={classes.fbCardSection}>
        <FbPostReachBarCard data={data} stats={stats} loading={isLoadingInsights} />
        <IgEngagementBarCard data={data} stats={stats} loading={isLoadingInsights} />
      </section>
      <section>
        <PieCard />
      </section>
    </div>
  );
};

export default StatsOverview;
