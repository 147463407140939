import { useQuery } from "react-query";
import { InsightApi } from "services/api";
import { InsightQueryKeys } from "services/query-keys";
import {
  FacebookPageDemographicMetrics,
  InsightDatePreset,
  InstagramInsightTimeframe,
  InstagramPageDemographicMetrics,
  InstaInsightBreakdown,
  MetricPeriod,
  UseCustomQueryOptions,
} from "types";

export type TInsightDemographicParams = {
  accountId: string;
  companyId: string;
  date_preset: InsightDatePreset;
  since?: Date;
  until?: Date;
  period: MetricPeriod;
  facebook_metrics?: FacebookPageDemographicMetrics[];
  instagram_metrics?: InstagramPageDemographicMetrics[];
  instaInsightBreakdown: InstaInsightBreakdown;
  instaInsightTimeframe: InstagramInsightTimeframe;
};

type InsightDemographicResponse = Awaited<ReturnType<typeof InsightApi.getDemographic>>;

const useInsightDemographic = <TSelectData = InsightDemographicResponse>(
  filters: TInsightDemographicParams,
  options?: UseCustomQueryOptions<InsightDemographicResponse, TSelectData>,
) => {
  return useQuery({
    queryKey: InsightQueryKeys.getInsightDemographic(filters),
    queryFn: async () => {
      return InsightApi.getDemographic(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default useInsightDemographic;
