import { Button, message, Tabs } from "antd";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams, useSearchParams } from "@pankod/refine-react-router-v6";
import { POST_EDITING_STATUS, PostPublishingStatusType } from "types";
import { useContext, useMemo } from "react";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { usePagination } from "hooks/usePagination";
import { postEditingStatusTranslated } from "pages/Planner/components/ListView";
import NotAllowed from "components/NotAllowed";
import PostDetailsModal from "components/PostDetailsModal";
import { makeSubject } from "utils/access";
import useReviewPost from "hooks/posts/useReviewPost";
import usePostVersion from "hooks/posts/usePostVersion";
import usePostVersions from "hooks/posts/usePostVersions";
import { AxiosError } from "axios";
import PageHeading from "components/PageHeading";
import CustomAvatar from "components/CustomAvatar";
import CustomBadge, { CustomBadgeColor } from "components/CustomBadge";
import CustomTable from "components/CustomTable";
import FacebookColored from "../../assets/icons/socials/facebookColored.svg";
import InstagramColored from "../../assets/icons/socials/instagramColored.svg";
import classes from "./index.module.css";

const postEditingStatusBadgeColorMapping: { [key: string]: CustomBadgeColor } = {
  [POST_EDITING_STATUS.APPROVED]: "green", //Green
  [POST_EDITING_STATUS.IN_PROGRESS]: "yellow", //Yellow
  [POST_EDITING_STATUS.IN_REVIEW]: "yellow", //Yellow
  [POST_EDITING_STATUS.REJECTED]: "red", //Red
};

const ApprovalCenterTable = ({ editingStatuses }: { editingStatuses: POST_EDITING_STATUS[] }) => {
  const { t } = useTranslation(["common", "pages"]);
  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();

  const { ability, isLoading: isLoadingPermission } = useContext(UserAccessPolicyContext);
  const canReadPosts = ability.can("review", "PostVersion");

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPostVersionId = searchParams.get("postVersionId");

  const { data: postVersion } = usePostVersion({
    versionId: currentPostVersionId ?? "",
  });
  const post = postVersion?.post;

  const setCurrentPostVersionId = (id: string | undefined) => {
    const newSearchParams = new URLSearchParams({ ...searchParams });
    if (id) {
      newSearchParams.set("postVersionId", id);
    } else {
      newSearchParams.delete("postVersionId");
    }
    setSearchParams(newSearchParams);
  };

  const { data: postVersions, isLoading: isLoadingPosts } = usePostVersions(
    {
      accountId: selectedAccountId,
      companyId: selectedCompanyId,
      requiresReview: true,
      isCurrentVersion: true,
      editingStatuses,
      publishingStatuses: [PostPublishingStatusType.SCHEDULED],
    },
    {
      enabled: canReadPosts,
    },
  );

  const handleCancel = () => {
    setCurrentPostVersionId(undefined);
  };

  const canReviewPost = ability.can(
    "review",
    post
      ? makeSubject("PostVersion", { accountId: post.accountId, companyId: post.companyId, channelId: post.channelId })
      : "PostVersion",
  );
  const {
    mutate: reviewPost,
    isLoading: isReviewingPost,
    variables,
  } = useReviewPost({
    onSuccess: (_, variables) => {
      handleCancel();
      if (variables?.editingStatus === POST_EDITING_STATUS.APPROVED) {
        message.success(t("pages:approvalCenter.postApprovalSuccessMessage"));
      } else {
        message.success(t("pages:approvalCenter.postRejectionSuccessMessage"));
      }
    },
    onError: (err, vars) => {
      if (vars.editingStatus === POST_EDITING_STATUS.APPROVED) {
        message.error(t("pages:approvalCenter.postApprovalErrorMessage"));
      } else {
        message.error(t("pages:approvalCenter.postRejectionErrorMessage"));
      }
      if (err instanceof AxiosError) {
        console.error(err.response?.data?.message ?? err.message);
      } else {
        console.error(err);
      }
    },
  });

  const isApprovingPost = isReviewingPost && variables?.editingStatus === POST_EDITING_STATUS.APPROVED;
  const isRejectingPost = isReviewingPost && variables?.editingStatus === POST_EDITING_STATUS.REJECTED;

  const isScheduledInThePast = useMemo(
    () => (postVersion?.scheduledAt ? new Date(postVersion.scheduledAt) < new Date() : false),
    [postVersion?.scheduledAt],
  );

  const paginationState = usePagination(10);

  return (
    <div>
      {canReadPosts ? (
        <>
          <CustomTable
            defaultProps={{
              className: classes.approvalCenterTable,
              loading: isLoadingPosts || isLoadingPermission,
              dataSource: postVersions,
              columns: [
                {
                  title: t("pages:approvalCenter.postLabel"),

                  render: (_, record) => {
                    const primeAsset = record.content?.assets[0];

                    return (
                      <div className={classes.motivContainer}>
                        {record.thumbnailUrl ? (
                          <img src={record.thumbnailUrl} alt="motiv" className={classes.motivImage} />
                        ) : primeAsset ? (
                          primeAsset?.url.toLowerCase().endsWith(".mp4") ? (
                            <video src={primeAsset.url} />
                          ) : (
                            <img src={primeAsset.url} alt="motiv" className={classes.motivImage} />
                          )
                        ) : null}
                        <span className={classes.motivText}>{record.content?.body}</span>
                      </div>
                    );
                  },
                },
                {
                  title: t("pages:approvalCenter.statusLabel"),

                  render: (_, record) => {
                    return (
                      <CustomBadge color={postEditingStatusBadgeColorMapping[record.editingStatus]}>
                        {postEditingStatusTranslated[record.editingStatus]}
                      </CustomBadge>
                    );
                  },
                },
                {
                  title: t("pages:approvalCenter.platformLabel"),

                  render: (_, record) => {
                    return (
                      <div className={classes.platformContainer}>
                        <img
                          className={classes.platformIcon}
                          src={record?.post?.channel?.type === "FACEBOOK" ? FacebookColored : InstagramColored}
                          alt={record?.post?.channel?.type === "FACEBOOK" ? "Facebook Logo" : "Instagram Logo"}
                        />
                        {record.post?.channel?.name}
                      </div>
                    );
                  },
                },
                {
                  title: t("pages:approvalCenter.createdByLabel"),

                  render: (_, record) => {
                    return (
                      <div className={classes.createdByContainer}>
                        <CustomAvatar
                          src={record.creator?.user_profile_pic}
                          alt={`${record.creator?.name ?? "User"}'s profile picture`}
                          size="large"
                        >
                          {record.creator?.name[0] ?? "E"}
                        </CustomAvatar>
                        {record.creator?.name ?? t("common:placeholders.unknownUser")}
                      </div>
                    );
                  },
                },
                {
                  title: t("pages:approvalCenter.viewPostLabel"),

                  render: (_, record) => {
                    return (
                      <Button onClick={() => setCurrentPostVersionId(record.id)}>
                        {t("pages:approvalCenter.viewPostButton")}
                      </Button>
                    );
                  },
                },
              ],
            }}
            paginationProps={{
              ...paginationState,

              total: postVersions?.length,
            }}
          />
          <PostDetailsModal
            postVersionId={currentPostVersionId}
            onCancel={handleCancel}
            title={<span className={classes.modalTitle}>{t("pages:approvalCenter.approvalModalTitle")}</span>}
            footerType="REVIEW"
            footer={
              post?.publishingStatus === PostPublishingStatusType.PUBLISHED ? null : (
                <div className={classes.modalFooter}>
                  <Button
                    className={classes.modalFooterButton}
                    type="default"
                    onClick={() => {
                      if (!postVersion) return;
                      reviewPost({
                        postVersionId: postVersion.id,
                        editingStatus: POST_EDITING_STATUS.REJECTED,
                      });
                    }}
                    disabled={
                      !canReviewPost ||
                      isReviewingPost ||
                      !postVersion ||
                      postVersion.editingStatus === POST_EDITING_STATUS.REJECTED ||
                      isScheduledInThePast
                    }
                    loading={isRejectingPost}
                  >
                    {t("pages:approvalCenter.rejectPostButton")}
                  </Button>
                  <Button
                    className={classes.modalFooterButton}
                    type="primary"
                    onClick={() => {
                      if (!post) return;
                      reviewPost({
                        postVersionId: postVersion.id,
                        editingStatus: POST_EDITING_STATUS.APPROVED,
                      });
                    }}
                    disabled={
                      !canReviewPost ||
                      isReviewingPost ||
                      !post ||
                      postVersion.editingStatus === POST_EDITING_STATUS.APPROVED ||
                      isScheduledInThePast
                    }
                    loading={isApprovingPost}
                  >
                    {t("pages:approvalCenter.approvePostButton")}
                  </Button>
                </div>
              )
            }
          />
        </>
      ) : (
        <NotAllowed />
      )}
    </div>
  );
};

const ApprovalCenter = () => {
  const { t } = useTranslation(["common", "pages"]);

  const navigate = useNavigate();

  const params = useParams<{ tab: string }>();

  const { pathPrefix } = useSelectedAccount();

  return (
    <div className="container">
      <PageHeading className={classes.titleSection}>
        <PageHeading.Title className={classes.title}>{t("pages:approvalCenter.title")}</PageHeading.Title>
        <PageHeading.Subtitle className={classes.subtitle}>{t("pages:approvalCenter.subtitle")}</PageHeading.Subtitle>
      </PageHeading>
      <Tabs
        defaultActiveKey="pending"
        activeKey={params.tab}
        onChange={(key) => navigate(`${pathPrefix}/approval-center/${key}`)}
        items={[
          {
            key: "pending",
            label: t("pages:approvalCenter.approvalPendingPostsLabel"),
            children: <ApprovalCenterTable editingStatuses={[POST_EDITING_STATUS.IN_REVIEW]} />,
          },
          {
            key: "approved",
            label: t("pages:approvalCenter.approvedPostsLabel"),
            children: <ApprovalCenterTable editingStatuses={[POST_EDITING_STATUS.APPROVED]} />,
          },

          {
            key: "rejected",
            label: t("pages:approvalCenter.rejectedPostsLabel"),
            children: <ApprovalCenterTable editingStatuses={[POST_EDITING_STATUS.REJECTED]} />,
          },
        ]}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default ApprovalCenter;
