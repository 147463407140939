import { Upload, UploadFile } from "antd";
import classes from "./Uploader.module.css";

export type UploaderProps = {
  title?: string;
  accept: string;
  multiple: boolean;
  beforeUpload: (file: UploadFile) => boolean;
  onFilesSelected: (fileList: any) => void;
  content: React.ReactElement;
  error?: string;
};

const Uploader = (props: UploaderProps) => {
  const { title, accept, multiple, beforeUpload, onFilesSelected, content, error } = props;
  return (
    <div>
      {title && <p>{title}</p>}
      <Upload.Dragger
        accept={accept}
        multiple={multiple}
        beforeUpload={beforeUpload}
        onChange={({ fileList }) => {
          onFilesSelected(fileList);
        }}
      >
        {content}
      </Upload.Dragger>
      {error && <p className={classes.errorText}>{error}</p>}
      {!error && <br />}
    </div>
  );
};

export default Uploader;
