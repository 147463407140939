import { Button, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useRouterContext } from "@pankod/refine-core";
import FB from "services/meta";
import useSelectedAccount from "hooks/useSelectedAccount";
import { makeSubject } from "utils/access";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { useContext } from "react";
import NotAllowed from "components/NotAllowed";
import InstagramIcon from "../../../../assets/icons/socials/instagram.svg";
import FacebookIcon from "../../../../assets/icons/socials/facebook.svg";
import classes from "./AddProfile.module.css";

const channels = [
  {
    key: "facebook",
    name: "FACEBOOK",
    text: "Businesskonto",
    icon: <img src={FacebookIcon} alt="facebook" className={classes.FacebookIcon} />,
  },
  {
    key: "instagram",
    name: "INSTAGRAM",
    text: "Businesskonto",
    icon: <img src={InstagramIcon} alt="instagram" className={classes.InstagramIcon} />,
  },
];

export default function AddProfile() {
  const { push } = useRouterContext().useHistory();

  const { pathPrefix } = useSelectedAccount();
  const { selectedAccountId: accountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);

  const canManageChannels = ability.can("manage", makeSubject("Channel", { accountId }));

  return (
    <div className="container">
      <Row justify="space-between" align="middle" className={classes.AddProfileHeader}>
        <h1 className="heading">Profil hinzufügen</h1>
        <Button type="link" className={classes.AddProfileButton} onClick={() => push(`${pathPrefix}/settings/profile`)}>
          <LeftOutlined />
          Zurück zu den Profilen
        </Button>
      </Row>
      {canManageChannels ? (
        <>
          <p className={classes.AddProfileText}>
            Bitte wähle eines der folgenden Netzwerke, logge Dich dort ein und wähle das Profil, das Du mit EASYPOSTLAB
            verbinden und verwalten möchtest.
          </p>

          <div className={classes.SocialContainer}>
            {channels.map((channel) => (
              <div
                className={classes.SocialItem}
                onClick={async () => {
                  await FB.login({ type: channel.key as "facebook" | "instagram" });
                }}
                tabIndex={0}
                role="button"
                onKeyDown={async () => {
                  await FB.login({ type: channel.key as "facebook" | "instagram" });
                }}
              >
                {channel.icon}
                <div>
                  <h3>{channel.name}</h3>
                  <p>{channel.text}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <NotAllowed />
      )}
    </div>
  );
}
