import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";

export type TFindAllChannelsParams = {
  accountId: string;
};

const useChannels = <TReturn = Awaited<ReturnType<typeof AccountApi.getChannels>>>(
  filters: TFindAllChannelsParams,
  options: {
    enabled: boolean;
    selectFn?: (res: Awaited<ReturnType<typeof AccountApi.getChannels>>) => TReturn;
  } = { enabled: true },
) => {
  return useQuery({
    queryKey: AccountQueryKeys.getChannels(filters),
    queryFn: async () => {
      return AccountApi.getChannels(filters);
    },
    select: options?.selectFn,

    initialData: [],
    initialDataUpdatedAt: 0,
    enabled: !!filters.accountId && options.enabled,
  });
};

export default useChannels;
