import StatCard from "components/StatCard";
import { Card, Divider, Spin } from "antd";
import { mergeClasses } from "utils/mergeClasses";
import CustomBadge from "components/CustomBadge";
import ChartStat from "pages/ChartStat";
import { useTranslation } from "react-i18next";
import formatNumber from "utils/formatNumber";
import useInsights, { groupInsightsByMonth } from "hooks/Insight/useInsights";
import useSelectedAccount from "hooks/useSelectedAccount";
import {
  FacebookMetrics,
  FacebookPostMetrics,
  InsightDatePreset,
  InstagramMetrics,
  InstagramPostMetrics,
  MetricPeriod,
} from "types";
import { useMemo } from "react";
import dayjs from "dayjs";
import usePostStats from "hooks/posts/usePostStats";
import useInsightPosts from "hooks/Insight/useInsightPosts";
import ReachChart from "components/ReachChart";
import { formatDate } from "utils/date";
import { ReactComponent as YellowLoader } from "../../assets/YellowLoader.svg";
import { ReactComponent as CheckCircle } from "../../assets/CheckCircle.svg";
import { ReactComponent as PlusCircle } from "../../assets/PlusCircle.svg";
import { ReactComponent as FacebookGray } from "../../assets/FacebookGray.svg";
import { ReactComponent as InstagramGray } from "../../assets/InstagramGray.svg";
import { ReactComponent as RevenueIcon } from "../../assets/Revenue2.svg";
import classes from "./index.module.css";

const PostCardItem = ({
  icon,
  title,
  value,
  color,
}: {
  icon: React.ReactNode;
  title: string;
  value: number;
  color: "blue" | "yellow" | "green";
}) => {
  const formattedValue = useMemo(
    () => formatNumber(value, { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
    [value],
  );

  return (
    <div className={classes.postsCardItem} data-color={color}>
      <div className={classes.postsCardItemIcon} data-color={color}>
        {icon}
      </div>

      <div className={classes.postsCardItemText}>
        <p>{title}</p>
        <strong>{formattedValue}</strong>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { selectedAccountId, selectedCompanyId } = useSelectedAccount();
  const { data: insightPosts, isLoading: isLoadingInsightPosts } = useInsightPosts(
    {
      accountId: selectedAccountId,
      companyId: selectedCompanyId,
      facebook_metrics: [FacebookPostMetrics.post_engaged_users, FacebookPostMetrics.post_impressions_unique],
      instagram_metrics: [InstagramPostMetrics.impressions, InstagramPostMetrics.reach],
    },
    {
      select: (data) =>
        data.items.map((post) => {
          const allInsights = {} as {
            [k in FacebookPostMetrics | InstagramPostMetrics]?: number;
          };

          for (const insight of post.insights) {
            let total = 0;
            for (const value of insight.values) {
              total += value.value;
            }
            allInsights[insight.name as keyof typeof allInsights] = total;
          }

          return {
            ...post,
            formattedCreated: formatDate(post.createdAt),
            allInsights,
          };
        }),
    },
  );

  const { data, isLoading: isLoadingInsights } = useInsights({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
    facebook_metrics: [
      FacebookMetrics.page_fan_adds,
      FacebookMetrics.page_follows,
      FacebookMetrics.page_impressions_unique,
      FacebookMetrics.page_impressions_paid_unique,
    ],
    instagram_metrics: [InstagramMetrics.impressions, InstagramMetrics.follower_count, InstagramMetrics.reach],
    date_preset: InsightDatePreset.this_year,
    period: MetricPeriod.day,
  });

  const stats = useMemo(() => (data ? groupInsightsByMonth(data) : undefined), [data]);

  const { data: postStats, isLoading: isLoadingPostStats } = usePostStats({
    accountId: selectedAccountId,
    companyId: selectedCompanyId,
  });

  const prevMonthStats = useMemo(() => {
    return {
      newLikes:
        (stats?.facebook.page_fan_adds?.data.at(-2)?.value ?? 0) +
        (stats?.instagram.impressions?.data.at(-2)?.value ?? 0),
      newFollowers:
        (stats?.facebook.page_follows?.data.at(-2)?.value ?? 0) +
        (stats?.instagram.follower_count?.data.at(-2)?.value ?? 0),
      pplReached:
        (stats?.facebook.page_impressions_unique?.data.at(-2)?.value ?? 0) +
        (stats?.instagram.reach?.data.at(-2)?.value ?? 0),
      fbReach: stats?.facebook.page_impressions_unique?.data.at(-2)?.value ?? 0,
      igReach: stats?.instagram.reach?.data.at(-2)?.value ?? 0,
      paidReach: stats?.facebook.page_impressions_paid_unique?.data.at(-2)?.value ?? 0,
    };
  }, [stats]);

  const currMonthStats = useMemo(() => {
    return {
      newLikes:
        (stats?.facebook.page_fan_adds?.data.at(-1)?.value ?? 0) +
        (stats?.instagram.impressions?.data.at(-1)?.value ?? 0),
      newFollowers:
        (stats?.facebook.page_follows?.data.at(-1)?.value ?? 0) +
        (stats?.instagram.follower_count?.data.at(-1)?.value ?? 0),
      pplReached:
        (stats?.facebook.page_impressions_unique?.data.at(-1)?.value ?? 0) +
        (stats?.instagram.reach?.data.at(-1)?.value ?? 0),
      fbReach: stats?.facebook.page_impressions_unique?.data.at(-1)?.value ?? 0,
      igReach: stats?.instagram.reach?.data.at(-1)?.value ?? 0,
      paidReach: stats?.facebook.page_impressions_paid_unique?.data.at(-1)?.value ?? 0,
    };
  }, [stats]);

  const { categories, reachOnFb, reachOnIg, paidReach } = useMemo(() => {
    const categories: string[] = [];
    const reachOnFb: number[] = [];
    const reachOnIg: number[] = [];
    const paidReach: number[] = [];

    for (let i = 0; i < 12; i++) {
      const monthDate = dayjs().set("month", i);

      categories.push(monthDate.format("MMMM YYYY"));

      const month = i + 1;

      const monthReachOnFb =
        stats?.facebook.page_impressions_unique?.data.find((item) => item.month === month)?.value ?? 0;

      const monthReachOnIg = stats?.instagram.reach?.data.find((item) => item.month === month)?.value ?? 0;
      const monthPaidReach =
        stats?.facebook.page_impressions_paid_unique?.data.find((item) => item.month === month)?.value ?? 0;

      reachOnFb.push(monthReachOnFb);
      reachOnIg.push(monthReachOnIg);
      paidReach.push(monthPaidReach);
    }

    return { categories, reachOnFb, reachOnIg, paidReach };
  }, [stats]);

  return (
    <div className={mergeClasses("container", classes.page)}>
      <section className={classes.statCardSection}>
        <StatCard
          title={t("pages:dashboard.newLikesLabel")}
          stat={currMonthStats.newLikes}
          prevMonthStat={prevMonthStats.newLikes}
          loading={isLoadingInsights}
        />
        <StatCard
          title={t("pages:dashboard.newFollowersLabel")}
          stat={currMonthStats.newFollowers}
          prevMonthStat={prevMonthStats.newFollowers}
          loading={isLoadingInsights}
        />
        <StatCard
          title={t("pages:dashboard.newPeopleReachedLabel")}
          stat={currMonthStats.pplReached}
          prevMonthStat={prevMonthStats.pplReached}
          loading={isLoadingInsights}
        />
      </section>
      <section className={classes.midSection}>
        <Card className={classes.postsCard}>
          <Spin spinning={isLoadingPostStats}>
            <div className={classes.postsCardWrapper}>
              <div className={classes.postsCardTitleContainer}>
                <p>{t("pages:dashboard.statsOverviewTitle")}</p>
              </div>
              <Divider style={{ margin: "8px 0px 22px 0px" }} />
              <div className={classes.postsCardContent}>
                <PostCardItem
                  icon={<YellowLoader />}
                  title={t("pages:dashboard.newPostsLabel")}
                  value={postStats?.publishedPostCount ?? 0}
                  color="yellow"
                />
                <PostCardItem
                  icon={<CheckCircle stroke="#039855" />}
                  title={t("pages:dashboard.publicPostsLabel")}
                  value={postStats?.scheduledPostCount ?? 0}
                  color="green"
                />
                <PostCardItem
                  icon={<PlusCircle />}
                  title={t("pages:dashboard.totalPostsLabel")}
                  value={postStats?.totalPostCount ?? 0}
                  color="blue"
                />
              </div>
            </div>
          </Spin>
        </Card>
        <Card className={classes.currContentCard}>
          <Spin spinning={isLoadingInsightPosts}>
            <div className={classes.currContentCardWrapper}>
              <div className={classes.currContentCardTitle}>
                <strong>{t("pages:dashboard.currentContentTitle")} </strong>
                <CustomBadge color="orange" size="sm">
                  {t("pages:dashboard.last7DaysLabel")}
                </CustomBadge>
              </div>
              {insightPosts?.length ? (
                <table className={classes.currContentTable}>
                  <thead>
                    <tr>
                      <th />
                      <th />
                      <th className={classes.reachColumn}>{t("pages:dashboard.reachLabel")}</th>
                      <th className={classes.engagementColumn}>{t("pages:dashboard.engagementLabel")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insightPosts?.map((item) => {
                      const primeAsset = item.currentVersion.content?.assets[0];

                      const reach = formatNumber(
                        //If fb
                        item.allInsights.post_impressions_unique ??
                          //if insta
                          item.allInsights.reach ??
                          0,
                        {
                          notation: "compact",
                          maximumTruncatedDigits: 2,
                        },
                      );
                      const engagement = formatNumber(
                        //if fb
                        item.allInsights.post_engaged_users ??
                          //if insta
                          item.allInsights.impressions ??
                          0,
                        {
                          notation: "compact",
                          maximumTruncatedDigits: 2,
                        },
                      );

                      return (
                        <tr>
                          <td style={{ paddingRight: "38px" }}>
                            <div className={classes.currContentItemImg}>
                              {item.currentVersion.thumbnailUrl ? (
                                <img
                                  src={item.currentVersion.thumbnailUrl}
                                  alt="motiv"
                                  className={classes.motivImage}
                                />
                              ) : primeAsset ? (
                                primeAsset?.url.toLowerCase().endsWith(".mp4") ? (
                                  <video src={primeAsset.url} />
                                ) : (
                                  <img src={primeAsset.url} alt="motiv" className={classes.motivImage} />
                                )
                              ) : (
                                <span style={{ textAlign: "center" }}>{t("pages:dashboard.noPreviewPlaceholder")}</span>
                              )}
                            </div>
                          </td>
                          <td style={{ paddingRight: "20px", width: "50%" }}>
                            <div className={classes.currContentItemContent}>
                              <p>{item.currentVersion.content?.body}</p>
                              <div className={classes.currContentItemFooter}>
                                {item.channel.type === "FACEBOOK" ? <FacebookGray /> : <InstagramGray />}

                                <p>{item.formattedCreated}</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ paddingRight: "24px" }}>
                            <div className={classes.currContentItemStat}>
                              <strong>{reach ?? 0}</strong>
                            </div>
                          </td>
                          <td>
                            <div className={classes.currContentItemStat}>
                              <strong>{engagement}</strong>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div style={{ flex: "1 1 0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                    <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
                  </span>
                </div>
              )}
            </div>
          </Spin>
        </Card>
      </section>

      <section>
        <Card className={classes.chartSectionCard}>
          <Spin spinning={isLoadingInsights}>
            <div className={classes.chartSectionCardWrapper}>
              <div className={classes.chartSectionCardHeader}>
                <div className={classes.chartSectionStats}>
                  <ChartStat
                    icon={<FacebookGray />}
                    text={t("pages:dashboard.reachOnFbLabel")}
                    value={stats?.fbReach ?? 0}
                  />
                  <ChartStat
                    icon={<InstagramGray />}
                    text={t("pages:dashboard.reachOnIgLabel")}
                    value={stats?.igReach ?? 0}
                  />
                  <ChartStat
                    icon={<RevenueIcon />}
                    text={t("pages:dashboard.paidReachLabel")}
                    value={stats?.paidReach ?? 0}
                  />
                </div>
              </div>
              <div className={classes.chartSectionCardContent}>
                <ReachChart categories={categories} paidReach={paidReach} reachOnFb={reachOnFb} reachOnIg={reachOnIg} />
              </div>
            </div>
          </Spin>
        </Card>
      </section>
    </div>
  );
};
export default Dashboard;
