import { Button, Collapse, Divider, Form, Input } from "antd";
import { Post, POST_EDITING_STATUS } from "types";

import usePostReviewComments from "hooks/PostReviewComment/usePostReviewComments";
import { useContext, useMemo } from "react";

import Comment from "components/Comment";
import useMe from "hooks/useMe";
import useCreatePostReviewComment from "hooks/PostReviewComment/useCreatePostReviewComment";
import { useTranslation } from "react-i18next";
import { UserAccessPolicyContext } from "context/UserAccessContext";

import { makeSubject } from "utils/access";
import CustomAvatar from "components/CustomAvatar";
import classes from "./index.module.css";

const CommentSection = ({ post }: { post: Post | undefined }) => {
  const { t } = useTranslation(["common", "pages"]);

  const { ability } = useContext(UserAccessPolicyContext);
  const canReadComments = ability.can(
    "read",
    post
      ? makeSubject("PostReviewComment", {
          accountId: post.accountId,
          companyId: post.companyId,
          channelId: post.channelId,
        })
      : "PostReviewComment",
  );

  const {
    data: commentsData,
    hasNextPage,
    fetchNextPage,
  } = usePostReviewComments({ postId: post?.id ?? "", include: ["quotedComment", "user"] });
  const postHasComments = useMemo(() => commentsData?.pages.some((page) => page.items.length > 0), [commentsData]);

  const [form] = Form.useForm<{ message: string }>();

  const { mutate: createComment, isLoading: isCreatingComment } = useCreatePostReviewComment({
    onSuccess: () => {
      form.resetFields();
    },
  });

  const { data: me } = useMe();

  if (
    !canReadComments ||
    !post ||
    !post.requiresReview ||
    post.currentVersion.editingStatus === POST_EDITING_STATUS.IN_PROGRESS
  )
    return null;

  return (
    <div className={classes.commentSectionContainer}>
      <Collapse className={classes.collapseContainer}>
        <Collapse.Panel
          header={
            post
              ? post.currentVersion.editingStatus === POST_EDITING_STATUS.APPROVED
                ? t("pages:posts.postApprovedStatusTitle")
                : post.currentVersion.editingStatus === POST_EDITING_STATUS.REJECTED
                  ? t("pages:posts.postRejectedStatusTitle")
                  : t("pages:posts.postInReviewStatusTitle")
              : "Loading..."
          }
          key="1"
        >
          <div className={classes.reviewerContainer}>
            <span className={classes.reviewerTitle}>{t("pages:posts.reviewerTitle")}</span>
            <div className={classes.reviewerBody}>
              <CustomAvatar
                size="small"
                src={post.currentVersion.reviewer?.user_profile_pic}
                alt="reviewer profile picture"
              >
                {post.currentVersion.reviewer?.name[0] ?? "U"}
              </CustomAvatar>
              <span className={classes.reviewerName}>
                {post.currentVersion.reviewer?.name ?? t("common:placeholders.unknownUser")}{" "}
              </span>
            </div>
          </div>
          <div className={classes.commentSection}>
            <span className={classes.commentSectionTitle}>{t("pages:comments.commentSectionTitle")}</span>
            <div className={classes.commentsWrapper}>
              {postHasComments ? (
                commentsData?.pages.map((page, pageIdx) =>
                  page.items.map((comment, idx) => {
                    return (
                      <>
                        <Comment key={comment.id} comment={comment} />
                        {pageIdx === commentsData.pages.length - 1 && idx === page.items.length - 1 ? null : (
                          <Divider className={classes.commentDivider} />
                        )}
                      </>
                    );
                  }),
                )
              ) : (
                <span style={{ margin: "0 auto", display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
                  <p style={{ color: "#000" }}>{t("pages:comments.noCommentsAvailableMessage")}</p>
                </span>
              )}
            </div>
            <Divider className={classes.commentDivider} />
            {hasNextPage ? (
              <Button
                className={classes.loadMoreCommentsButton}
                type="link"
                onClick={async () => {
                  await fetchNextPage();
                }}
              >
                {t("pages:comments.loadMoreCommentsButton")}
              </Button>
            ) : null}
            <Form
              className={classes.createCommentForm}
              onFinish={(values) => {
                createComment({
                  postVersionId: post?.currentVersion.id ?? "",
                  message: values.message,
                });
              }}
              form={form}
              disabled={isCreatingComment}
            >
              <div className={classes.createCommentFormContainer}>
                <CustomAvatar src={me?.user_profile_pic} alt={`${me?.name ?? "User"}'s profile picture`}>
                  {me?.name?.[0] ?? "U"}
                </CustomAvatar>
                <div className={classes.createCommentInputWrapper}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("pages:comments.emptyCommentMessageError"),
                      },
                      {
                        max: 2400,
                        message: t("pages:comments.emptyCommentMessageMaxLenError"),
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea style={{ width: "100%" }} placeholder={t("pages:comments.commentsPlaceholder")} />
                  </Form.Item>
                </div>
              </div>
              <Button
                className={classes.createCommentButton}
                disabled={isCreatingComment}
                loading={isCreatingComment}
                htmlType="submit"
              >
                {t("pages:comments.addCommentButton")}
              </Button>
            </Form>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default CommentSection;
