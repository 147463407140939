import { useQuery } from "react-query";
import { InsightApi } from "services/api";
import { InsightQueryKeys } from "services/query-keys";
import { FacebookPostMetrics, InstagramPostMetrics, UseCustomQueryOptions } from "types";

export type TGetInsightPostsParams = {
  accountId: string;
  companyId: string;
  facebook_metrics: FacebookPostMetrics[];
  instagram_metrics: InstagramPostMetrics[];
  startDate?: Date | string;
  endDate?: Date | string;
  skip?: number;
  take?: number;
};

type GetInsightPostsResult = Awaited<ReturnType<typeof InsightApi.getInsightPosts>>;

const useInsightPosts = <TSelectData = GetInsightPostsResult>(
  filters: TGetInsightPostsParams,
  options?: UseCustomQueryOptions<GetInsightPostsResult, TSelectData>,
) => {
  return useQuery({
    queryKey: InsightQueryKeys.getInsightPosts(filters),
    queryFn: async () => {
      return InsightApi.getInsightPosts(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialData: {
      items: [],
      total: 0,
    },
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default useInsightPosts;
