import { Tabs, TabsProps } from "antd";
import PageHeading from "components/PageHeading";
import { mergeClasses } from "utils/mergeClasses";
import { useNavigate, useParams } from "@pankod/refine-react-router-v6";
import useSelectedAccount from "hooks/useSelectedAccount";
import StatsOverview from "./StatsOverview";
import StatsReach from "./StatsReach";
import StatsTargetGroup from "./StatsTargetGroup";
import classes from "./index.module.css";
import StatsContent from "./StatsContent";

const items: TabsProps["items"] = [
  {
    key: "overview",
    label: "Übersicht",
    children: <StatsOverview />,
  },
  {
    key: "reach",
    label: "Reichweite",
    children: <StatsReach />,
  },
  {
    key: "target-group",
    label: "Zielgruppe",
    children: <StatsTargetGroup />,
  },
  {
    key: "content",
    label: "Content",
    children: <StatsContent />,
  },
];

const Statistics = () => {
  const { tab } = useParams<{ tab: string }>();

  const { pathPrefix } = useSelectedAccount();

  const navigate = useNavigate();

  return (
    <div className={mergeClasses("container", classes.page)}>
      <PageHeading>
        <PageHeading.Title>Statistik</PageHeading.Title>
        <PageHeading.Subtitle>
          Die wichtigsten Trends für Inhalte, die du kürzlich erstellt und geteilt hast
        </PageHeading.Subtitle>
      </PageHeading>
      <div>
        <Tabs
          items={items}
          activeKey={tab}
          onChange={(key) => navigate(`${pathPrefix}/statistics/${key}`)}
          defaultActiveKey="overview"
          destroyInactiveTabPane
        />
      </div>
    </div>
  );
};

export default Statistics;
