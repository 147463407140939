import { useQuery, UseQueryOptions } from "react-query";
import { TodosAPI } from "services/api";
import { TodoQueryKeys } from "services/query-keys";
import { TODO_PRIORITY, TODO_STATUS } from "types";

export type TGetTodosParams = {
  accountId?: string;
  companyIds?: string[] | null;
  status?: TODO_STATUS[];
  priority?: TODO_PRIORITY[];
  contains?: string;
  assignee?: string;
  skip?: number;
  take?: number;
};

type GetTodosResult = Awaited<ReturnType<typeof TodosAPI.list>>;

const useTodos = (filters: TGetTodosParams, options?: UseQueryOptions<GetTodosResult>) => {
  return useQuery({
    queryKey: TodoQueryKeys.list(filters),
    queryFn: async () => {
      return TodosAPI.list(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialData: options?.initialData ?? {
      todos: [],
      count: {
        OPEN: 0,
        IN_PROGRESS: 0,
        DONE: 0,
      },
      total: 0,
    },
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId,
  });
};

export default useTodos;
