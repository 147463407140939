import { AuthProvider } from "@pankod/refine-core";
import { AuthApi } from "services/api";
import { parseJwt } from "utils/jwt";
import { auth } from "config/firebase";
import { checkAuth } from "utils/checkAuth";

export enum SigninProvider {
  EMAIL_PASSWORD = "EMAIL_PASSWORD",
  GOOGLE = "GOOGLE",
  MICROSOFT = "MICROSOFT",
  APPLE = "APPLE",
}

const authProvider: AuthProvider = {
  login: async (data) => {
    const response = await AuthApi.login(data);
    if (response) {
      const decodedUserInfo = parseJwt(response.result.access_token);
      localStorage.setItem("EPL_USER_INFO", JSON.stringify(decodedUserInfo));
      return {
        success: true,
        response,
      };
    }
    return {
      success: false,
    };
  },
  logout: async () => {
    localStorage.removeItem("EPL_USER_INFO");
    if (auth.currentUser) {
      await auth.signOut();
    }
    await AuthApi.logout();
    //Clearing again as a sanity check
    localStorage.removeItem("EPL_USER_INFO");
    window.location.href = "/";
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth,
  getPermissions: () => Promise.resolve(),
  // getUserIdentity: async () => {
  //     const token = localStorage.getItem('EPL_TOKEN');
  //     if (!token) {
  //         return Promise.reject();
  //     }
  //     return Promise.resolve({
  //         id: '1'
  //     });
  // },
};

export default authProvider;
