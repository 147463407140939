import { Account, AccountDetail, CreatePost, Post } from "types";
import { GlobalOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Spin } from "antd";
import useQueryCacheData from "hooks/useQueryCacheData";
import { useState } from "react";
import { useQuery } from "react-query";
import { AccountApi } from "services/api";
import { getBase64 } from "utils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import like from "../../../assets/like.svg";
import comment from "../../../assets/comment.svg";
import share from "../../../assets/share.svg";
import save from "../../../assets/save.svg";
import preview from "../../../assets/preview.png";
import FacebookLogo from "../../../assets/icons/socials/facebook.svg";
import InstagramLogo from "../../../assets/icons/socials/instagram.svg";
import classes from "./Preview.module.css";

export type PostPreviewProps = {
  post?: Post;
  localPost: CreatePost;
};

const PostPreview = (props: PostPreviewProps) => {
  const { localPost } = props;
  const [accountPic, setAccountPic] = useState<string>();
  const accounts = useQueryCacheData<Account[]>("accounts");

  const { data: images, isLoading } = useQuery<string[]>(["preview-images", localPost.assets], () =>
    Promise.all(
      localPost.assets.map((image) =>
        image.originFileObj ? getBase64(image.originFileObj) : image.url ? image.url : preview,
      ),
    ),
  );

  useQuery<AccountDetail>(["account", accounts[0].id], () => AccountApi.getAccountDetails(accounts[0].id), {
    enabled: !!accounts[0].id,
    onSuccess: (data) => {
      setAccountPic(data.account_profile_pic);
    },
    refetchOnWindowFocus: true,
  });

  return (
    <div className={classes.previewContainer}>
      <Typography.Text className={classes.previewTitle}>VORSCHAU</Typography.Text>
      <Row className={classes.previewFlexContainer} gutter={40.24}>
        {(localPost.platforms.length === 0 || localPost.platforms.find((p) => p.includes("FACEBOOK"))) && (
          <Col span={24} className={classes.previewContentContainer}>
            <p className={classes.mediaPreviewTitle}>Facebook News Feed-Vorschau</p>
            <div className={classes.preview} data-testid="post-facebook-preview">
              <div className={classes.previewHeader}>
                <img src={accountPic ?? FacebookLogo} className={classes.fbImg} alt="profile-img" />
                <div className={classes.previewHeaderInfo}>
                  <p>Dein Konto</p>
                  <p>
                    Gerade eben
                    <GlobalOutlined style={{ marginLeft: "5px", fontSize: "10px" }} />
                  </p>
                </div>
              </div>

              {localPost.body ? (
                <Typography.Paragraph
                  className={classes.previewText}
                  ellipsis={{
                    rows: 3,
                  }}
                >
                  {localPost.body}
                </Typography.Paragraph>
              ) : (
                <div className={classes.skeletonContainer}>
                  <div className={classes.skeleton} />
                  <div className={`${classes.skeleton} ${classes.short}`} />
                </div>
              )}

              {localPost.assets.length ? (
                <Spin spinning={isLoading}>
                  <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1} className={classes.slider}>
                    {images?.map((file, i) => {
                      if (file.startsWith("data:video/mp4") || file.toLowerCase().endsWith(".mp4")) {
                        return (
                          <video controls key={i + file}>
                            <source src={file} />
                          </video>
                        );
                      }
                      return <img src={file} className={classes.previewImg} key={i + file} />;
                    })}
                  </Slider>
                </Spin>
              ) : (
                <img src={preview} className={classes.previewImg} alt="preview-img" />
              )}

              <Row className={classes.previewFooter} justify="space-between">
                <div className={classes.previewFooterItem}>
                  <img src={like} alt="like" />
                  <span>Gefällt mir</span>
                </div>
                <div className={classes.previewFooterItem}>
                  <img src={comment} alt="comment" />
                  <span>Kommentieren</span>
                </div>
                <div className={classes.previewFooterItem}>
                  <img src={share} alt="share" />
                  <span>Teilen</span>
                </div>
              </Row>
            </div>
          </Col>
        )}
        {(localPost.platforms.length === 0 || localPost.platforms.find((p) => p.includes("INSTAGRAM"))) && (
          <Col span={24} className={classes.previewContentContainer}>
            <p className={classes.mediaPreviewTitle}>Instagram News Feed-Vorschau</p>
            <div className={classes.preview} data-testid="post-instagram-preview">
              <div className={classes.previewHeader}>
                <img src={accountPic ?? InstagramLogo} className={classes.instaImg} alt="profile-img" />
                <div className={classes.previewHeaderInfo}>
                  <p>Dein Konto</p>
                </div>
              </div>

              {localPost.assets.length ? (
                <Spin spinning={isLoading}>
                  <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1} className={classes.slider}>
                    {images?.map((file, i) => {
                      if (file.startsWith("data:video/mp4") || file.toLowerCase().endsWith(".mp4")) {
                        return (
                          <video controls key={i + file}>
                            <source src={file} />
                          </video>
                        );
                      }
                      return <img src={file} className={classes.previewImg} key={i + file} />;
                    })}
                  </Slider>
                </Spin>
              ) : (
                <img src={preview} className={classes.previewImg} alt="preview-img" />
              )}

              {localPost.body ? (
                <Typography.Paragraph
                  className={classes.previewText}
                  ellipsis={{
                    rows: 3,
                  }}
                >
                  {localPost.body}
                </Typography.Paragraph>
              ) : (
                <div className={classes.skeletonContainer}>
                  <div className={classes.skeleton} />
                  <div className={`${classes.skeleton} ${classes.short}`} />
                </div>
              )}

              <Row className={classes.previewFooter} justify="space-between">
                <Row>
                  <div className={classes.previewFooterItem}>
                    <img src={like} alt="like" />
                  </div>
                  <div className={classes.previewFooterItem}>
                    <img src={comment} alt="comment" />
                  </div>
                </Row>
                <div className={classes.previewFooterItem}>
                  <img src={save} alt="save" />
                </div>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PostPreview;
