import { useState, useMemo, useContext } from "react";
import { Col, Drawer, Row, Button } from "@pankod/refine-antd";
import { useAuthenticated, useRouterContext } from "@pankod/refine-core";
import { Alert, Divider, Flex, Menu, MenuProps } from "antd";
import { MenuOutlined, CloseOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { ButtonProps, DevicePlatform } from "types";
import LogoutModal from "pages/LogoutModal";
import Loader from "components/Loader";

import { Link, Navigate } from "@pankod/refine-react-router-v6";
import useSelectedAccount from "hooks/useSelectedAccount";
import { AccountContextProvider } from "context/AccountContext";
import useCompanies from "hooks/company/useCompanies";
import { UserAccessPolicyContext, UserAccessPolicyContextProvider } from "context/UserAccessContext";
import InactiveSubscription from "components/SubscriptionExpired";

import { makeSubject } from "utils/access";
import useChannels from "hooks/useChannels";
import useMe from "hooks/useMe";
import useUnreadNotificationsCount from "hooks/Notification/useUnreadNotificationsCount";
import NotificationModal from "pages/NotificationModal/NotificationModal";

import { useTranslation } from "react-i18next";

import useAdmin from "hooks/useAdmin";
import useAccount from "hooks/useAccount";
import CustomAvatar from "components/CustomAvatar";

import { ReactComponent as EPLBlackLogo } from "../../assets/EPL Black Text Logo.svg";
import { ReactComponent as EPLMobileLogo } from "../../assets/logo-mobile.svg";
import EPLlogo from "../../assets/EPLlogo.png";
import "../../styles/menu.css";
import ProfileIcon from "../../assets/user.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/Sidebar/dashboard.svg";
import { ReactComponent as EditorICon } from "../../assets/icons/Sidebar/editor.svg";
import { ReactComponent as PlannerIcon } from "../../assets/icons/Sidebar/planner.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/icons/Sidebar/statistics.svg";
import { ReactComponent as TodosIcon } from "../../assets/icons/Sidebar/todos.svg";
import { ReactComponent as Bell } from "../../assets/Bell.svg";
import { ReactComponent as Settings } from "../../assets/Settings.svg";
import { ReactComponent as CheckCircle } from "../../assets/CheckCircle.svg";
import { ReactComponent as Logout } from "../../assets/Logout.svg";
import { ReactComponent as Users } from "../../assets/Users.svg";
import { ReactComponent as CoinStack } from "../../assets/CoinStack.svg";
import classes from "./UserContainer.module.css";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function HamBurgerNavComponent({ text, testId }: { text: string; testId: string }) {
  return (
    <div className={classes.hamburgerMenuItemContainer} data-testid={testId}>
      <span>{text}</span>
    </div>
  );
}

const navigate = (prefix: string, key: string, isPlatformAdmin: boolean) => {
  if (isPlatformAdmin) {
    switch (key) {
      case "dashboard":
        return `/admin/dashboard`;
      case "users":
        return `/admin/users/users`;
      case "revenue":
        return `/admin/revenue`;
      default:
        return `/admin/dashboard`;
    }
  }

  let suffix = "/dashboard";

  switch (key) {
    case "dashboard":
      suffix = "/dashboard";
      break;
    case "posts":
      suffix = "/posts/new";
      break;
    case "planer":
      suffix = "/planer";
      break;
    case "statistics":
      suffix = "/statistics/overview";
      break;
    case "todos":
      suffix = "/todos/my";
      break;
    case "profile":
      suffix = "/profile";
      break;
    case "settings":
      suffix = "/settings";
      break;
    case "campaign":
      suffix = "/campaign/dashboard";
      break;
    case "approval-center":
      suffix = "/approval-center/pending";
      break;
    default:
      suffix = "/dashboard";
  }

  return `${prefix}${suffix}`;
};

function SideBarButtons({ buttonNames, handleButtonClick, mode = "desktop" }: ButtonProps) {
  return (
    <>
      {buttonNames.map((name) => (
        <div key={name} style={{ fontSize: "12px", marginBottom: 5 }}>
          <Button
            className={classes.sidebarButtonLink}
            type="link"
            style={{
              background: "none",
              padding: 0,
              fontWeight: "bold",
              color: mode === "mobile" ? "white" : "black",
            }}
            onClick={() => handleButtonClick(name)}
          >
            {name}
          </Button>
          {name === "FAQ" && (
            <hr
              style={{
                margin: "10px 0",
                border: "none",
                borderTop: `1px solid ${mode === "mobile" ? "white" : "black"}`,
                width: "150px",
              }}
            />
          )}
        </div>
      ))}
    </>
  );
}

const ProfileLink = ({ mode = "desktop", to }: { to: string; mode?: "mobile" | "desktop" }) => {
  return (
    <div style={{ fontSize: "12px", marginBottom: 5 }}>
      <Link
        to={to}
        style={{
          background: "none",
          padding: 0,
          fontWeight: "bold",
          color: mode === "mobile" ? "white" : "black",
          fontSize: "14px",
        }}
      >
        Benutzer
      </Link>
    </div>
  );
};

const buttonNames = ["Abmelden", "FAQ", "Impressum", "Datenschutz", "AGB"];

const UserContainerContent = ({
  children,
  sidebarVisible = true,
  navbarVisible = true,
}: {
  children: React.ReactNode;
  sidebarVisible?: boolean;
  navbarVisible?: boolean;
}) => {
  const { t } = useTranslation(["common", "pages"]);
  const { ability } = useContext(UserAccessPolicyContext);
  const { isPlatformAdmin } = useAdmin();

  const { push } = useRouterContext().useHistory();
  const currentPath = window.location.pathname;
  const subPath = (isPlatformAdmin ? currentPath.split("/")[2] : currentPath.split("/")[5]) ?? "";

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  const handleLogoutModal = () => {
    setOpenHamburgerMenu(false);
    setIsLogoutVisible(!isLogoutVisible);
  };

  const handleButtonClick = (name: string) => {
    if (name === "Abmelden") {
      handleLogoutModal();
    }
    if (name === "FAQ") {
      window.open("https://easypostlab.com/faq", "_blank");
    }
    if (name === "Impressum") {
      window.open("https://easypostlab.com/impressum", "_blank");
    }
    if (name === "AGB") {
      window.open("https://easypostlab.com/agb", "_blank");
    }
    if (name === "Datenschutz") {
      window.open("https://easypostlab.com/datenschutzerklaerung", "_blank");
    }
  };
  const { isSuccess, isLoading, isFetching } = useAuthenticated();

  const { selectedAccountId, selectedCompanyId, pathPrefix, pathWithoutPrefix } = useSelectedAccount({
    companyRequired: false,
  });

  const { data: selectedAccount, isLoading: isLoadingSelectedAccount } = useAccount(selectedAccountId);

  const canManageChannels = ability.can("manage", makeSubject("Channel", { accountId: selectedAccountId }));
  const canReviewPost = ability.can("review", "PostVersion");

  const { data: channels, isLoading: isLoadingChannels } = useChannels(
    { accountId: selectedAccountId },
    { enabled: canManageChannels && isSuccess },
  );

  const { data: me } = useMe(selectedAccountId);

  const { data: companies } = useCompanies(
    { accountId: selectedAccountId },
    {
      selectFn: (res) => res.items,
      enabled: true,
    },
  );

  const hamburgerMenuItems: MenuItem[] = useMemo(
    () =>
      isPlatformAdmin
        ? [
            getItem(<HamBurgerNavComponent text="Dashboard" testId="menu-admin-dashboard-link" />, "dashboard"),
            getItem(<HamBurgerNavComponent text="Konten" testId="menu-admin-users-link" />, "users"),
            getItem(<HamBurgerNavComponent text="Umsatz" testId="menu-admin-revenue-link" />, "revenue"),
          ]
        : [
            getItem(<HamBurgerNavComponent text="Dashboard" testId="menu-dashboard-link" />, "dashboard"),
            getItem(<HamBurgerNavComponent text="Post verfassen" testId="menu-create-post-link" />, "posts"),
            getItem(<HamBurgerNavComponent text="Planer" testId="menu-planner-link" />, "planer"),
            // getItem(
            //     <HamBurgerNavComponent text='Nachrichten' testId='menu-messages-link' />,
            //     'messages',
            //     <SendOutlined />,
            // ),
            // getItem(
            //     <HamBurgerNavComponent text='Kalender' testId='menu-calendar-link' />,
            //     'calendar',
            //     <CalendarOutlined />,
            // ),
            // getItem(<HamBurgerNavComponent text="Werbeanzeigen" testId="menu-ads-link" />, "campaign"),
            getItem(<HamBurgerNavComponent text="Aufgaben" testId="menu-todos-link" />, "todos"),
            getItem(<HamBurgerNavComponent text="Statistik" testId="menu-statistics-link" />, "statistics"),
            // getItem(<HamBurgerNavComponent text="Benutzer" testId="menu-users-link" />, "profile"),
            // getItem(<HamBurgerNavComponent text='TEAM' testId='menu-team-link' />, 'team', <TeamOutlined />),
            ...(canReviewPost
              ? [
                  getItem(
                    <HamBurgerNavComponent text="Freigabecenter" testId="menu-approval-center-link" />,
                    "approval-center",
                  ),
                ]
              : []),
          ],
    [canReviewPost, isPlatformAdmin],
  );

  const { data: unreadNotificationCountData } = useUnreadNotificationsCount({
    accountId: selectedAccountId,
    platform: DevicePlatform.desktop,
    companyId: selectedCompanyId,
  });
  const unreadNotificationCount = unreadNotificationCountData?.unreadCount ?? 0;

  const selectedCompany = useMemo(
    () => companies?.find((comp) => comp.id === selectedCompanyId),
    [selectedCompanyId, companies],
  );

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const links = useMemo(
    () =>
      isPlatformAdmin
        ? [
            {
              label: "Dashboard",
              icon: DashboardIcon,
              to: `/admin/dashboard`,
              key: "dashboard",
            },
            {
              label: "Konten",
              icon: Users,
              to: `/admin/users/users`,
              key: "users",
            },
            {
              label: "Umsatz",
              icon: CoinStack,
              to: `/admin/revenue`,
              key: "revenue",
            },
          ]
        : [
            {
              label: "Dashboard",
              icon: DashboardIcon,
              to: `${pathPrefix}/dashboard`,
              key: "dashboard",
            },
            {
              label: "Post verfassen",
              icon: EditorICon,
              to: `${pathPrefix}/posts/new`,
              key: "posts",
            },
            {
              label: "Planer",
              icon: PlannerIcon,
              to: `${pathPrefix}/planer`,
              key: "planer",
            },
            {
              label: "Aufgaben",
              icon: TodosIcon,
              to: `${pathPrefix}/todos/my`,
              key: "todos",
            },
            {
              label: "Statistik",
              icon: StatisticsIcon,
              to: `${pathPrefix}/statistics/overview`,
              key: "statistics",
            },
            ...(canReviewPost
              ? [
                  {
                    label: "Freigabecenter",
                    icon: CheckCircle,
                    to: `${pathPrefix}/approval-center/pending`,
                    key: "approval-center",
                  },
                ]
              : []),
          ],
    [pathPrefix, canReviewPost, isPlatformAdmin],
  );

  const selectAccountUrl = useMemo(
    () => `${pathPrefix}/select-account?${pathWithoutPrefix ? new URLSearchParams({ to: pathWithoutPrefix }) : ""}`,
    [pathPrefix, pathWithoutPrefix],
  );

  if (isLoading || isFetching || isLoadingSelectedAccount) {
    return <Loader />;
  }

  if (selectedAccount && !isLoadingChannels && canManageChannels && channels && channels.length === 0) {
    return <Navigate to="/onboarding?step=1" replace />;
  }

  if (selectedAccount && (!selectedAccount.accessExpiresAt || new Date(selectedAccount.accessExpiresAt) < new Date())) {
    return <InactiveSubscription />;
  }

  return (
    <>
      {isLogoutVisible && <LogoutModal visible={isLogoutVisible} handleCloseModal={handleLogoutModal} />}
      <Row
        className={classes.ConsumerDashboardContainer}
        style={{ marginLeft: sidebarVisible ? undefined : "0px" }}
        data-drawer-open={openHamburgerMenu}
      >
        {sidebarVisible && (
          <Drawer
            visible
            placement="left"
            className={classes.SidebarDrawer}
            mask={false}
            autoFocus={false}
            style={{ width: "300px", textAlign: "left", color: "#000", borderRight: "1px solid #DFE0E1" }}
            bodyStyle={{
              width: "100%",
              backgroundColor: "#F8F9FA",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              overflowX: "hidden",
              padding: "32px 0",
            }}
            closable={false}
          >
            <div style={{ textAlign: "center", display: "flex", gap: "24px", flexDirection: "column" }}>
              <Link to={isPlatformAdmin ? "/admin/dashboard" : `${pathPrefix}/dashboard`}>
                <EPLBlackLogo className={classes.desktopLogo} />
                <EPLMobileLogo className={classes.mobileLogo} fill={undefined} />
              </Link>
              <div className={classes.sidebarContainer}>
                {links.map((link) => {
                  const isActive = subPath === link.key;

                  return (
                    <Link to={link.to} className={classes.sidebarLink} key={link.key} data-active={isActive}>
                      <link.icon stroke={undefined} />
                      {link.label}
                    </Link>
                  );
                })}
              </div>
            </div>
            <Row style={{ padding: "0 32px" }}>
              <Flex vertical gap="8px">
                <a className={classes.sidebarSubLink} href="https://easypostlab.com/faq" target="_blank">
                  FAQ
                </a>

                <a className={classes.sidebarSubLink} href="https://easypostlab.com/impressum" target="_blank">
                  Impressum
                </a>
                <a className={classes.sidebarSubLink} href="https://easypostlab.com/agb/" target="_blank">
                  AGB
                </a>
              </Flex>
              <Divider style={{ margin: "24px 0", background: "hsl(220, 16, 48, 10%)" }} />
              <div className={classes.sidebarSignoutSection}>
                <div className={classes.sidebarSignoutUserDetails}>
                  <strong>{me?.name ?? t("common:placeholders.unknownUser")}</strong>
                  <span>{me?.email ?? t("common:placeholders.unknownEmail")}</span>
                </div>
                <Button className={classes.sidebarSignoutButton} type="ghost" onClick={() => handleLogoutModal()}>
                  <Logout />
                </Button>
              </div>
            </Row>
          </Drawer>
        )}
        {selectedAccount &&
          (!selectedAccount?.accessExpiresAt || new Date(selectedAccount.accessExpiresAt) < new Date()) && (
            <Alert
              message="Bitte fügen Sie Ihrem Konto eine aktualisierte Zahlungsmethode hinzu."
              type="info"
              showIcon
              banner
            />
          )}
        <Col className={classes.ConsumerDashboardMainbar}>
          {navbarVisible && (
            <Row className={classes.navbar}>
              <Row className={classes.navbarContent} align="middle">
                <div className={classes.partOne}>
                  <Flex align="center" gap="8px">
                    <Link to={isPlatformAdmin ? `/admin/dashboard` : `${pathPrefix}/dashboard`}>
                      <EPLMobileLogo className={classes.mobileLogo} fill={undefined} />
                    </Link>
                    {isPlatformAdmin ? null : (
                      <>
                        <Link className={`${classes.navbarLink} ${classes.accountSelectLink}`} to={selectAccountUrl}>
                          <UserSwitchOutlined style={{ fontSize: "1.25rem" }} />
                          <span>
                            <strong>{t("pages:account.nameLabel")}</strong>: {selectedAccount?.name ?? "N/A"}
                          </span>
                          <Divider orientation="center" type="vertical" />
                          <span>
                            <strong>Unternehmen</strong>: {selectedCompany?.name ?? "N/A"}
                          </span>
                        </Link>
                      </>
                    )}
                  </Flex>
                </div>
                <div className={classes.partTwo}>
                  {isNotificationModalOpen ? (
                    <NotificationModal
                      isOpen={isNotificationModalOpen}
                      closeModal={() => setIsNotificationModalOpen(false)}
                    />
                  ) : null}
                  {isPlatformAdmin ? null : (
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                      <Link
                        to={`${pathPrefix}/settings/admin`}
                        className={classes.navbarButton}
                        data-active={subPath === "settings"}
                      >
                        <Settings stroke={undefined} />
                      </Link>
                      <Button
                        className={classes.navbarButton}
                        onClick={() => setIsNotificationModalOpen(true)}
                        type="ghost"
                      >
                        <Bell stroke={undefined} />
                        {unreadNotificationCount ? <span className={classes.notificationDot} /> : null}
                      </Button>

                      <Link className={classes.navbarLink} to={`${pathPrefix}/user/profile`}>
                        <CustomAvatar
                          src={me?.user_profile_pic ?? ProfileIcon}
                          alt={`${me?.name ?? "User"}'s profile picture`}
                          size="large"
                        >
                          {me?.name?.[0] ?? "U"}
                        </CustomAvatar>
                      </Link>
                    </div>
                  )}
                </div>

                <Col className={classes.hamburgerMenu}>
                  {isPlatformAdmin ? null : (
                    <>
                      <Link
                        to={`${pathPrefix}/settings/admin`}
                        className={classes.navbarButton}
                        data-active={subPath === "settings"}
                      >
                        <Settings stroke={undefined} />
                      </Link>
                      <Button
                        className={classes.navbarButton}
                        onClick={() => setIsNotificationModalOpen(true)}
                        type="ghost"
                      >
                        <Bell stroke={undefined} />
                        {unreadNotificationCount ? <span className={classes.notificationDot} /> : null}
                      </Button>
                    </>
                  )}
                  <button
                    style={{ all: "unset" }}
                    onClick={() => setOpenHamburgerMenu(true)}
                    disabled={!sidebarVisible}
                  >
                    <MenuOutlined style={{ color: "#667085" }} />
                  </button>
                </Col>
              </Row>
              {openHamburgerMenu && (
                <Row className={classes.hamburgerMenuContainer}>
                  <div className={classes.hamburgerNavBar}>
                    <div className={classes.partOne}>
                      <div>
                        <img className={classes.hamburgerImage} src={EPLlogo} alt="Logo" />
                      </div>
                    </div>
                    <div>
                      <CloseOutlined
                        width={50}
                        onClick={() => setOpenHamburgerMenu(false)}
                        className={classes.closeButton}
                      />
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className={classes.mobileAccountSelectContainer}>
                      <Link to={selectAccountUrl} className={classes.mobileAccountSelect}>
                        <div className={classes.mobileAccountSelectAvatar}>
                          {selectedAccount?.account_profile_pic ? (
                            <img src={selectedAccount.account_profile_pic} alt={"Account's profile picture"} />
                          ) : (
                            selectedAccount?.name[0] ?? "U"
                          )}
                        </div>
                        <div className={classes.mobileAccountSelectTextWrapper}>
                          <strong>{selectedAccount?.name}</strong>
                          <span>{selectedCompany?.name}</span>
                        </div>

                        <UserSwitchOutlined style={{ fontSize: "1.25rem", alignSelf: "center", marginLeft: "auto" }} />
                      </Link>
                    </div>
                    <Menu
                      mode="inline"
                      className={classes.mobileMenuContainer}
                      selectedKeys={[subPath]}
                      items={hamburgerMenuItems}
                      onClick={(e) => {
                        setOpenHamburgerMenu(false);
                        push(navigate(pathPrefix, e.key.toString(), isPlatformAdmin));
                      }}
                    />
                  </div>

                  <Row style={{ marginLeft: "30px", marginBottom: 5 }}>
                    <div>
                      <ProfileLink to={`${pathPrefix}/user/profile`} mode="mobile" />
                      <SideBarButtons buttonNames={buttonNames} handleButtonClick={handleButtonClick} mode="mobile" />
                    </div>
                  </Row>
                </Row>
              )}
            </Row>
          )}
          {children}
        </Col>
      </Row>
    </>
  );
};

export default function UserContainer({
  children,
  sidebarVisible = true,
  navbarVisible = true,
  accountRequired = true,
  companyRequired = true,
  adminOnly = false,
}: {
  children: React.ReactNode;
  sidebarVisible?: boolean;
  navbarVisible?: boolean;

  accountRequired?: boolean;
  companyRequired?: boolean;
  adminOnly?: boolean;
}) {
  return (
    <AccountContextProvider accountRequired={accountRequired} companyRequired={companyRequired} adminOnly={adminOnly}>
      <UserAccessPolicyContextProvider>
        <UserContainerContent sidebarVisible={sidebarVisible} navbarVisible={navbarVisible}>
          {children}
        </UserContainerContent>
      </UserAccessPolicyContextProvider>
    </AccountContextProvider>
  );
}
