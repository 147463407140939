import { createContext } from "react";
import { notificationProvider } from "@pankod/refine-antd";
import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import "@pankod/refine-antd/dist/styles.min.css";
import Login from "pages/Login";
import Register from "pages/Register";
import UserContainer from "containers/User";
import Dashboard from "pages/Dashboard";
import { message } from "antd";
import Settings from "pages/Settings";
import AddProfile from "pages/Settings/Profile/Add";
import PostEditor from "pages/PostEditor";
import PlannerScreen from "pages/Planner";
import authProvider from "authProvider";
import Campaign from "pages/Campaign";
import CampaignAds from "pages/Campaign/components/CreateCampaign/CampaignAds";
import CreateCampaign from "pages/Campaign/components/CreateCampaign";
import CheckUserInvite from "pages/CheckUserInvite";
import Statistics from "pages/Statistics";
import Onboarding from "pages/Onboarding";
import ResetPassword from "pages/ResetPassword";
import ForgotPassword from "pages/ForgotPassword";
import NotFound from "pages/NotFound";
import UnauthenticatedOnly from "containers/UnauthenticatedOnly";
import ProfileScreen from "pages/Profile";
import CampaignAdSet from "pages/Campaign/components/CreateCampaign/CampaignAdSet";
import Todos from "pages/Todos";
import CookieConsent from "components/CookieConsent";
import SelectAccount from "pages/SelectAccount";
import CompanySettings from "pages/CompanySettings";
import "./plugins/i18n/i18n";
import ApprovalCenter from "pages/ApprovalCenter";
import AdminDashboard from "pages/AdminDashboard";
import AdminUsers from "pages/AdminUsers";
import AdminRevenue from "pages/AdminRevenue";
import AuthenticatedOnly from "containers/AuthenticatedOnly";

message.config({
  top: 50,
});

export const UserContext = createContext<any>({});

function App() {
  return (
    <>
      <Refine
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              path: "/",
              element: (
                <UnauthenticatedOnly>
                  <Login />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/login",
              element: (
                <UnauthenticatedOnly>
                  <Login />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/register",
              element: (
                <UnauthenticatedOnly>
                  <Register />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/register/:token",
              element: (
                <UnauthenticatedOnly>
                  <Register />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/onboarding",
              element: (
                <AuthenticatedOnly>
                  <Onboarding />
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/invite/:token",
              element: <CheckUserInvite />,
            },
            {
              path: "/forgot-password",
              element: (
                <UnauthenticatedOnly>
                  <ForgotPassword />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/reset-password/:token",
              element: (
                <UnauthenticatedOnly>
                  <ResetPassword />
                </UnauthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/select-account",
              element: (
                <AuthenticatedOnly>
                  <UserContainer
                    accountRequired={false}
                    companyRequired={false}
                    sidebarVisible={false}
                    navbarVisible={false}
                  >
                    <SelectAccount />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/dashboard",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Dashboard />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/dashboard/:token",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Dashboard />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/dashboard/:token/expired",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Dashboard />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/posts/new",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <PostEditor />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/posts/:id",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <PostEditor />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/settings",
              element: (
                <AuthenticatedOnly>
                  <UserContainer companyRequired={false}>
                    <Settings />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/settings/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer companyRequired={false}>
                    <Settings />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/settings/profile/new",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <AddProfile />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/planer",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <PlannerScreen />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/planer/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <PlannerScreen />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/user/profile",
              element: (
                <AuthenticatedOnly>
                  <UserContainer accountRequired={false} companyRequired={false}>
                    <ProfileScreen />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/company-settings",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CompanySettings />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/statistics",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Statistics />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/statistics/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Statistics />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/:tab/dashboard",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <Campaign />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/new",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CreateCampaign />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/:category",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CreateCampaign />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/:category/:campaignId/adset",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CampaignAdSet />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/:campaignId/adset/:adsetId",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CampaignAdSet />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/:category/:id/adset/:adsetId/ad",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CampaignAds />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/campaign/:id/adset/:adsetId/ad/:adId",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <CampaignAds />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/todos/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer companyRequired={false}>
                    <Todos />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/account/:account/company/:company/approval-center/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer>
                    <ApprovalCenter />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/admin/dashboard",
              element: (
                <AuthenticatedOnly>
                  <UserContainer accountRequired={false} companyRequired={false} adminOnly>
                    <AdminDashboard />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/admin/users",
              element: (
                <AuthenticatedOnly>
                  <UserContainer accountRequired={false} companyRequired={false} adminOnly>
                    <AdminUsers />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/admin/users/:tab",
              element: (
                <AuthenticatedOnly>
                  <UserContainer accountRequired={false} companyRequired={false} adminOnly>
                    <AdminUsers />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "/admin/revenue",
              element: (
                <AuthenticatedOnly>
                  <UserContainer accountRequired={false} companyRequired={false} adminOnly>
                    <AdminRevenue />
                  </UserContainer>
                </AuthenticatedOnly>
              ),
            },
            {
              path: "*",
              element: <NotFound />,
            },
          ],
        }}
        authProvider={authProvider}
        DashboardPage={() => (
          <UnauthenticatedOnly>
            <Login />
          </UnauthenticatedOnly>
        )}
        notificationProvider={notificationProvider}
        catchAll={<NotFound />}
        dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
        resources={[{ name: "posts" }]}
      />
      <CookieConsent />
    </>
  );
}

export default App;
