import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useRouterContext } from "@pankod/refine-core";
import { Button, Divider, Flex, Form, Input, Row, Typography, message } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";

import authProvider, { SigninProvider } from "authProvider";
import { AccountApi, AuthApi, CompanyApi, UserApi } from "services/api";
import { validateEmail, validatePassword } from "utils";
import SingleSignOn from "components/SSO";
import { Link } from "@pankod/refine-react-router-v6";
import AuthContainer from "components/AuthContainer";
import OTPInput from "components/OTPInput";
import { useTranslation } from "react-i18next";
import { ReactComponent as MailOutlined } from "../../assets/MailOutlined.svg";
import classes from "./Login.module.css";

type LoginFormData = {
  email: string;
  password: string;
};

const loginFormInitialValues: LoginFormData = {
  email: "",
  password: "",
};

const renderEyeIcon = (visible: boolean) => {
  return visible ? (
    <EyeOutlined data-testid="login-pwd-eye-btn" style={{ fontSize: 20 }} />
  ) : (
    <EyeInvisibleOutlined data-testid="login-pwd-eye-btn" style={{ fontSize: 20 }} />
  );
};

export default function Login() {
  const { t } = useTranslation(["pages", "common"]);
  const [form] = Form.useForm();
  const [showOtpInput, setShowOtpInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const { push } = useRouterContext().useHistory();

  const handleRedirect = async (isAdmin?: boolean) => {
    if (isAdmin) {
      push(`/admin/dashboard`);
      return;
    }

    const accounts = await AccountApi.getList();
    const me = await UserApi.getMe();

    const firstAccount = accounts?.[0];

    const firstCompany =
      firstAccount && me
        ? (await CompanyApi.list({ accountId: firstAccount.id, userIds: [me.id] }))?.items?.[0]
        : undefined;

    if (!firstAccount || !firstCompany) {
      push("/account/-/company/-/select-account");
      return;
    }

    push(`/account/${firstAccount.id}/company/${firstCompany.id}/dashboard`);
  };

  const { mutate, isLoading } = useMutation(
    "login",
    (data: LoginFormData | { access_token: string; signinProvider: SigninProvider }) => {
      if ("access_token" in data) {
        return authProvider.login(data);
      }
      return authProvider.login(data);
    },
    {
      onSuccess: async (data) => {
        form.setFieldsValue(loginFormInitialValues);
        await handleRedirect(
          (
            data as {
              success: boolean;
              response?: {
                message: string;
                result: {
                  isAdmin: boolean;
                };
              };
            }
          ).response?.result.isAdmin ?? false,
        );
      },
      onError: (error: Error, variables: LoginFormData | { access_token: string; signinProvider: SigninProvider }) => {
        if (error.message.includes("Invalid Credentials")) {
          // eslint-disable-next-line
          error.message = t("pages:login.invalidCredentials");
        }
        message.error(error.message);
        // TODO remove this hacky way of checking for otp verification
        if (error.message.includes("nicht") && error.message.includes("verifiziert") && "email" in variables) {
          setEmail(variables.email);
          setShowOtpInput(true);
        }
      },
    },
  );

  return (
    <AuthContainer type="LOGIN">
      {showOtpInput ? (
        <OTPInput
          email={email}
          next={async () => {
            await handleRedirect();
          }}
          verify={AuthApi.verifyOtp}
        />
      ) : (
        <Flex vertical align="center" gap={32}>
          <Flex vertical>
            <Row>
              <Typography className={classes.loginFormTitle}>{t("pages:login.title")}</Typography>
            </Row>
            <Row>
              <Typography className={classes.loginFormSubtitle}>
                {t("pages:login.gotoRegisterLabel")}{" "}
                <Link to="/register" className={classes.link}>
                  {t("pages:login.registerRedirectButton")}
                </Link>
              </Typography>
            </Row>
          </Flex>
          <Form
            form={form}
            layout="vertical"
            initialValues={loginFormInitialValues}
            onFinish={mutate}
            className={classes.loginForm}
            requiredMark={false}
            validateTrigger={["submit"]}
          >
            <Flex vertical gap={32}>
              <Flex vertical gap={16}>
                <Row>
                  <Form.Item
                    label="E-Mail"
                    name="email"
                    className={classes.inputLabel}
                    rules={[
                      { required: true, message: t("common:validation.requiredField") },
                      {
                        validator: (_, value) => {
                          if (value?.length !== 0 && !validateEmail(value))
                            return Promise.reject(new Error(t("common:validation.invalidEmail")));
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      className={classes.input}
                      suffix={<MailOutlined className={classes.inputIcon} />}
                      placeholder="E-Mail"
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    label="Passwort"
                    name="password"
                    className={classes.inputLabel}
                    rules={[
                      { required: true, message: t("common:validation.requiredField") },
                      { min: 8, message: t("pages:login.passwordLengthValidationMessage") },
                      {
                        validator: (_, value) => {
                          if (value?.length !== 0 && !validatePassword(value))
                            return Promise.reject(new Error(t("pages:login.invalidPasswordValidationMessage")));
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password
                      className={classes.input}
                      suffix={<EyeOutlined />}
                      placeholder="Passwort"
                      iconRender={renderEyeIcon}
                    />
                  </Form.Item>
                </Row>
              </Flex>
              <Flex vertical gap={26}>
                <Row>
                  <Button loading={isLoading} className={classes.submitBtn} type="primary" htmlType="submit">
                    {t("pages:login.buttonLabel")}
                  </Button>
                </Row>
                <Row justify="center">
                  <Typography className={classes.forgotPasswordLabel}>
                    {t("pages:login.forgotPasswordLabel")}{" "}
                    <Link to="/forgot-password" className={classes.link}>
                      {t("pages:login.forgotPasswordRedirectButton")}
                    </Link>
                  </Typography>
                </Row>
              </Flex>
            </Flex>
          </Form>
          <Flex vertical gap={24} className={classes.ssoContainer}>
            <Row align="middle">
              <Divider className={classes.divider}>{t("common:labels.or")}</Divider>
            </Row>
            <SingleSignOn mutate={mutate} />
          </Flex>
        </Flex>
      )}
    </AuthContainer>
  );
}
