import { AccountContext } from "context/AccountContext";
import { useContext } from "react";

const useSelectedAccount = <TAccRequired extends boolean = true, TCompanyRequired extends boolean = true>(options?: {
  accountRequired?: TAccRequired;
  companyRequired?: TCompanyRequired;
}) => {
  const ctx = useContext(AccountContext);

  const selectedAccountId = ctx?.selectedAccount;
  const selectedCompanyId = ctx?.selectedCompany;

  if (!ctx) throw new Error("use must be used within a AccountContextProvider");

  if (options?.accountRequired && !ctx.accountRequired)
    throw new Error(
      "Cannot enforce account required without `accountRequired` set to true in the page's AccountContextProvider",
    );

  if (options?.companyRequired && !ctx.companyRequired)
    throw new Error(
      "Cannot enforce company required without `companyRequired` set to true in the page's AccountContextProvider",
    );

  return {
    selectedAccountId: selectedAccountId as TAccRequired extends true ? string : string | undefined,
    selectedCompanyId: selectedCompanyId as TCompanyRequired extends true ? string : string | undefined,
    pathPrefix: ctx.pathPrefix,
    /**
     * If on a route like `/account/:accountId/company/:companyId/:suffix`
     * returns the `/:suffix`.
     * Examples: `/account/1/company/2` returns `/2`
     * `/home` returns `/home`
     * @param currentPath
     */
    pathWithoutPrefix: ctx.pathWithoutPrefix,
    isAdminOnlyRoute: ctx.isAdminOnlyRoute,
  };
};

export default useSelectedAccount;
