export const IMG_FORMATS = ["image/jpeg"];
export const IMG_SIZE_LIMIT = 8 * 1024 * 1024; // 8MB

export const FB_PNG_RECC_SIZE_MB = 1;
export const FB_PNG_RECC_SIZE_LIMIT = FB_PNG_RECC_SIZE_MB * 1024 * 1024; // 8MB
export const FB_IMG_SIZE_MB = 4;
export const FB_IMG_SIZE_LIMIT = FB_IMG_SIZE_MB * 1024 * 1024; // 8MB
export const IG_IMG_SIZE_MB = 8;
export const IG_IMG_SIZE_LIMIT = IG_IMG_SIZE_MB * 1024 * 1024; // 8MB
export const FB_VIDEO_SIZE_MB = 100;
export const FB_VIDEO_SIZE_LIMIT = FB_VIDEO_SIZE_MB * 1024 * 1024; // 100MB
export const IG_VIDEO_SIZE_MB = 100;
export const IG_VIDEO_SIZE_LIMIT = IG_VIDEO_SIZE_MB * 1024 * 1024; // 100MB

export const FB_BODY_CHAR_LIMIT = 63206;
export const IG_BODY_CHAR_LIMIT = 2200;
export const FB_BODY_CHAR_TEXT = "63.206";
export const IG_BODY_CHAR_TEXT = "2.200";

export enum SupportedPlatforms {
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
}
export enum MediaTypes {
  JPEG = "image/jpeg",
  PNG = "image/png",
  MP4 = "video/mp4",
}
