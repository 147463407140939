import { Modal, Form, message, Select, Row, Button } from "antd";
import { AxiosError } from "axios";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import useUpdateCompanyUsers from "hooks/company/useUpdateCompanyUsers";
import useAccountUsers from "hooks/useAccountUsers";
import useSelectedAccount from "hooks/useSelectedAccount";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeSubject } from "utils/access";

export default function AddUserModal({
  visible,
  setVisible,
  companyId,
  userIdsToExclude = [],
}: {
  companyId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userIdsToExclude?: string[];
}) {
  const { t } = useTranslation(["common", "pages"]);
  const [userIdsToAdd, setUserIdsToAdd] = useState<string[]>([]);
  const { selectedAccountId } = useSelectedAccount();
  const { ability } = useContext(UserAccessPolicyContext);
  const canViewUsers = ability.can("read", makeSubject("User", { accountId: selectedAccountId }));

  const { data, isLoading: isFetchingUsers } = useAccountUsers(
    {
      accountId: selectedAccountId,
    },
    {
      enabled: canViewUsers,
    },
  );

  const users = useMemo(
    () => data?.flatMap((item) => item.user).filter((user) => !userIdsToExclude.includes(user.id)),
    [data, userIdsToExclude],
  );

  const { mutate: updateCompanyUsers, isLoading: isAddingUser } = useUpdateCompanyUsers({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      setUserIdsToAdd([]);
      setVisible(false);
      message.success("Benutzer erfolgreich zum Unternehmen hinzugefügt.");
    },
  });

  const handleCancel = () => {
    setVisible(false);
    setUserIdsToAdd([]);
  };

  return (
    <Modal title={t("pages:user.addButton")} open={visible} footer={null} onCancel={handleCancel} destroyOnClose>
      <Form layout="vertical">
        <Form.Item label={t("pages:user.choosePlaceholder")} name="userIdsToAdd">
          <Select
            loading={isFetchingUsers}
            mode="multiple"
            placeholder={t("pages:user.choosePlaceholder")}
            value={userIdsToAdd}
            onChange={(value: string[]) => setUserIdsToAdd([...value])}
            data-testid="user-dropdown"
          >
            {users?.map((user) => (
              <Select.Option key={user.id} value={user.id} data-testid={`user-${user.id}`}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row justify="end">
          <Button onClick={handleCancel} style={{ marginRight: 10 }}>
            {t("buttons.discard")}
          </Button>
          <Button
            type="primary"
            onClick={() => updateCompanyUsers({ companyId, userIds: userIdsToAdd, action: "add" })}
            loading={isAddingUser}
            disabled={!userIdsToAdd.length || isFetchingUsers}
            data-testid="user-invite-btn"
          >
            {t("buttons.add")}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
