import { Avatar } from "antd";
import React from "react";
import { mergeClasses } from "utils/mergeClasses";
import classes from "./index.module.css";

const CustomAvatar = React.forwardRef(
  (
    {
      children,
      className,
      src,
      alt,
      ...rest
    }: Omit<React.ComponentPropsWithoutRef<typeof Avatar>, "icon"> & {
      src?: string | null;
      alt?: string;
    },
    ref: React.Ref<HTMLElement>,
  ) => {
    return (
      <Avatar
        {...rest}
        className={mergeClasses(classes.avatar, className)}
        ref={ref}
        icon={src ? <img src={src} alt={alt ?? "Avatar image"} /> : undefined}
      >
        {children}
      </Avatar>
    );
  },
);

export default CustomAvatar;
