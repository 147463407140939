import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Row, Button, DatePicker, Table, message, Switch, Badge, Spin, Tooltip, Typography, Dropdown } from "antd";
import { CopyOutlined, DeleteOutlined, FormOutlined, PlusCircleOutlined, RocketOutlined } from "@ant-design/icons";
import { Account } from "types";
import { useMutation, useQuery } from "react-query";
import useQueryCacheData from "hooks/useQueryCacheData";
import locale from "antd/es/date-picker/locale/de_DE";
import { useRouterContext } from "@pankod/refine-core";
import { CampaignApi } from "services/api";
import DeleteModal from "pages/Planner/components/DeleteModal";
import MeatBallIcon from "assets/meatball.svg";
import createFloatButton from "assets/CreateFloatButton.svg";
import classes from "./CampaignDashboard.module.css";
import "dayjs/locale/de";
import "../../../../../styles/checkbox.css";

dayjs.locale("de");

const localeTable = {
  emptyText: (
    <span>
      <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
      <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
    </span>
  ),
};

const { RangePicker } = DatePicker;
const { Text } = Typography;

type DataType = {
  id: string;
  campaignName: string;
  status: string;
  bidStrategy: string;
  budget?: string;
  results: string;
  range: string;
  impression: string;
};

export default function CampaignDashboard() {
  const { push } = useRouterContext().useHistory();
  const [startDate, setStartDate] = useState(dayjs().date(1).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [tableData, setTableData] = useState<DataType[] | []>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[] | []>([]);
  const [selectedSwitch, setSelectedSwitch] = useState<string | null>(null);

  console.log("abcd");

  const { mutate, isLoading: isStatusUpdating } = useMutation(
    "campaign-update",
    (status: "ACTIVE" | "PAUSED") => {
      const body = {
        accountId: accounts[0].id,
        status,
      };
      return CampaignApi.updateCampaignStatus(body, selectedSwitch || "");
    },
    {
      onSuccess: () => {
        message.success("Status aktualisiert");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const handleSwitchChange = (id: string) => {
    const dataIndex = tableData.findIndex((item) => item.id === id);

    if (dataIndex !== -1) {
      const updatedTableData = [...tableData];

      updatedTableData[dataIndex].status = updatedTableData[dataIndex].status === "ACTIVE" ? "PAUSED" : "ACTIVE";
      mutate(updatedTableData[dataIndex].status === "ACTIVE" ? "ACTIVE" : "PAUSED");
      setTableData(updatedTableData);
    }
  };

  const columns = [
    // {
    //     title: <Checkbox disabled />,
    //     dataIndex: 'id',
    //     key: 'radio',
    //     render: (id: string) => (
    //         <Checkbox
    //             className={classes.DashboardCheckbox}
    //             onChange={() => handleCheckboxChange(id)}
    //             checked={selectedCheckbox === id}
    //         />
    //     ),
    // },
    {
      title: "Aus/Ein",
      dataIndex: "id",
      key: "toggle",
      width: 70,
      render: (status: string, record: DataType) => (
        <Switch
          style={{ width: 50 }}
          className={classes.DashboardSwitch}
          checked={record.status === "ACTIVE"}
          onChange={() => {
            if (selectedSwitch !== record.id) {
              setSelectedSwitch(record.id);
            } else {
              handleSwitchChange(record.id);
            }
          }}
        />
      ),
    },
    {
      title: "Kampagnenname",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status: string) =>
        status === "ACTIVE" ? <Badge status="success" text="Aktiv" /> : <Badge status="error" text="Deaktiv" />,
    },
    {
      title: "Gebotsstrategie",
      dataIndex: "bidStrategy",
      key: "bidStrategy",
      render: (bidStrategy: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {bidStrategy ? <Text>{bidStrategy}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (budget: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {budget ? <Text>{budget}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Ergebnisse",
      dataIndex: "results",
      key: "results",
      render: (results: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {results ? <Text>{results}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Reichweite",
      dataIndex: "range",
      key: "range",
      render: (range: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {range ? <Text>{range}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Impressionen",
      dataIndex: "impression",
      key: "impression",
      render: (impression: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {impression ? <Text>{impression}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
  ];

  const { isLoading, refetch, isFetching } = useQuery(
    "campaign-list",
    () => {
      return CampaignApi.getCampaignList(
        accounts[0].id,
        startDate,
        dayjs(endDate).add(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      );
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const eventPost = data.campaigns.map((event) => ({
          id: event.id,
          campaignName: event.name,
          status: event.status,
          bidStrategy: "",
          budget: event.spend_cap?.toString(),
          results: "",
          range: "",
          impression: "",
        }));
        setTableData(eventPost);
      },
    },
  );

  const handleDeleteModal = () => {
    if (deleteModalVisible) {
      refetch();
    }
    setDeleteModalVisible(!deleteModalVisible);
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    handleSwitchChange(selectedSwitch || "");
    // eslint-disable-next-line
  }, [selectedSwitch]);

  const handleDateChange = (values: any, formatString: [string, string]) => {
    if (values && values.length === 2) {
      const startDateString = dayjs(formatString[0], "DD.MM.YYYY").format("YYYY-MM-DD");
      const endDateString = dayjs(formatString[1], "DD.MM.YYYY").format("YYYY-MM-DD");
      setStartDate(startDateString);
      setEndDate(endDateString);
    }
  };

  return (
    <div>
      <div className={classes.DashboardContainer}>
        <Row className={classes.topButton}>
          <Tooltip color="#000" placement="bottom" title="Funktionalität nicht verfügbar">
            <Button type="primary" disabled className={classes.publishButton}>
              <RocketOutlined className={classes.iconSize} />
              <span className={classes.buttonTitle}>Veröffentlichen</span>
            </Button>
          </Tooltip>
        </Row>
        {deleteModalVisible && (
          <DeleteModal
            visible={deleteModalVisible}
            handleCloseModal={handleDeleteModal}
            currentText="Kampagne"
            currentIds={selectedRows}
            setSelectedCheckbox={setSelectedRows}
          />
        )}
        <Row className={classes.CampaignFilters} justify="space-between">
          <Row style={{ gap: 5, marginBottom: 5 }}>
            <Button
              data-testid="ads-campaign-create-btn"
              className={classes.createButton}
              type="primary"
              onClick={() => {
                localStorage.removeItem("adsName");
                localStorage.removeItem("adsetName");
                push("/user/campaign/new");
              }}
            >
              <PlusCircleOutlined className={classes.iconSize} />
              Erstellen
            </Button>
            <Tooltip color="#000" placement="bottom" title="Funktionalität nicht verfügbar">
              <Button disabled className={classes.duplicateButton}>
                <CopyOutlined className={classes.disabledIconsize} />
                Duplizieren
              </Button>
            </Tooltip>
            <Button
              className={classes.editButton}
              disabled={selectedRows.length === 0 || selectedRows.length > 1}
              onClick={() => {
                push(`/user/campaign/${selectedRows[0]}`);
              }}
            >
              <FormOutlined
                className={
                  selectedRows.length === 0 || selectedRows.length > 1 ? classes.disabledIconsize : classes.iconSize
                }
              />
              Bearbeiten
            </Button>
            <Button className={classes.deleteButton} disabled={selectedRows.length === 0} onClick={handleDeleteModal}>
              <DeleteOutlined className={selectedRows.length === 0 ? classes.disabledIconsize : classes.iconSize} />
              Löschen
            </Button>
          </Row>
          <RangePicker
            allowClear={false}
            format="DD.MM.YYYY"
            className={classes.datePicker}
            locale={locale}
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
          />
        </Row>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "Duplicate",
                label: (
                  <Tooltip color="#000" placement="bottom" title="Funktionalität nicht verfügbar">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CopyOutlined className={classes.disabledIconsize} />
                      <span style={{ color: "#000", marginLeft: 5 }}>Duplizieren</span>
                    </div>
                  </Tooltip>
                ),
              },
              {
                key: "Edit",
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormOutlined
                      className={
                        selectedRows.length === 0 || selectedRows.length > 1
                          ? classes.disabledIconsize
                          : classes.iconSize
                      }
                    />
                    <span style={{ color: "#000", marginLeft: 5 }}>Bearbeiten</span>
                  </div>
                ),
                onClick: () => {
                  push(`/user/campaign/${selectedRows[0]}`);
                },
              },
              {
                key: "Delete",
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutlined
                      className={selectedRows.length === 0 ? classes.disabledIconsize : classes.iconSize}
                    />
                    <span style={{ color: "#000", marginLeft: 5 }}>Löschen</span>
                  </div>
                ),
                onClick: handleDeleteModal,
              },
            ],
          }}
        >
          <img src={MeatBallIcon} className={classes.meatBallIcon} alt="create button" />
        </Dropdown>
      </div>
      <Spin spinning={isLoading || isFetching || isStatusUpdating}>
        <Table
          className={classes.table}
          dataSource={tableData}
          columns={columns}
          rowKey="id"
          locale={localeTable}
          pagination={{
            showSizeChanger: false,
            position: ["bottomCenter"],
          }}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys as string[]);
            },
          }}
        />
      </Spin>
      <div className={classes.CreateFloatButton}>
        <img
          onClick={() => {
            localStorage.removeItem("adsName");
            localStorage.removeItem("adsetName");
            push("/user/campaign/new");
          }}
          src={createFloatButton}
        />
      </div>
    </div>
  );
}
