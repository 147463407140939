import { useEffect, useRef, useState } from "react";
import { Input, Select, Checkbox, Button, message, Col, Form, Alert } from "antd";
import { useMutation, useQuery } from "react-query";
import { CampaignApi } from "services/api";
import useQueryCacheData from "hooks/useQueryCacheData";
import { Account, DescriptionOptionData, CampaignFormData } from "types/data";
import countriesNames from "pages/Campaign/countries";
import { SelectValue } from "antd/lib/select";
import { useRouterContext } from "@pankod/refine-core";
import classes from "./CampaignForm.module.css";
import "../../../../../styles/selector.css";

const { Option } = Select;

type Location = {
  value: string;
  label: string;
};

type SpecialAdCategories = {
  label: string;
  description: string;
  value: string;
};

const categoryType: { [key: string]: string } = {
  OUTCOME_AWARENESS: "Bekanntheit",
  OUTCOME_TRAFFIC: "Traffic",
  OUTCOME_ENGAGEMENT: "Interaktionen",
  OUTCOME_LEADS: "Leads",
  OUTCOME_SALES: "Umsatz",
};

const bookingOptions: DescriptionOptionData[] = [{ label: "Auktion", value: "AUCTION" }];

const categoryOptions: SpecialAdCategories[] = [
  { label: "None", description: "", value: "NONE" },
  {
    label: "Kredite",
    description: "Werbeanzeigen für Kreditkartenangebot oder Fahrzeugkredit Langzeitfinanzierungen o. A.",
    value: "CREDIT",
  },
  {
    label: "Jobangebote",
    description: "Werbeanzeigen für Jobangebote, Praktika, professionelle Zertifizierungsprogramme o. A",
    value: "EMPLOYMENT",
  },
  {
    label: "Wohnraum",
    description: "Werbeanzeigen für Immobilienangebote, Eigenheimversicher Hypothekenkrdite o. A",
    value: "HOUSING",
  },
  {
    label: "Wahlwerbung / Politisch oder gesellschaftlich relevante 1",
    description: "Werbeanzeigen zu gesellschaftlich relevanten Themen und Wsowie für Politiker oder Wahlkampagnen",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
];

const goalOptions: DescriptionOptionData[] = [
  { label: "Bekanntheit", value: "OUTCOME_AWARENESS" },
  { label: "Traffic", value: "OUTCOME_TRAFFIC" },
  { label: "Interaktionen", value: "OUTCOME_ENGAGEMENT" },
  { label: "Leads", value: "OUTCOME_LEADS" },
  { label: "Umsatz", value: "OUTCOME_SALES" },
];
const convertToProperCase = (str: string) => {
  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
};

const getCountryNames = (countries: string[]) => {
  let countryNames: Location[] = [];
  // eslint-disable-next-line
  countries.map((country) => {
    countryNames = [...countryNames, { value: country, label: countriesNames[country] }];
  });
  return countryNames;
};

export default function CampaignForm() {
  const [form] = Form.useForm();
  const params = useRouterContext().useParams<{ category: string; campaignId: string }>();
  const [showBudget, setShowBudget] = useState<boolean>(false);
  const category = goalOptions.find((item) => item.label === convertToProperCase(params.category))?.value;
  const mounted = useRef(false);
  const [options, setOptions] = useState<Location[]>([]);
  const [dummyOptions, setDummyOptions] = useState<Location[]>([]);
  const { push } = useRouterContext().useHistory();
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [campaignId, setCampaignId] = useState<string>("");
  const [objective, setObjective] = useState<string>(category || "");
  const [buyingType, setBuyingType] = useState<string>("AUCTION");
  const [campaignName, setCampaignName] = useState<string>(`Neue Kampagne für ${convertToProperCase(params.category)}`);
  const [countries, setCountries] = useState<string[]>([]);
  const [specialAdCategory, setSpecialAdCategory] = useState<string>("NONE");
  const [countryName, setCountryName] = useState<string>("");
  const [spendingLimit, setspendingLimit] = useState<string>("1");
  const [status, setStatus] = useState<string>("ACTIVE");
  const [campaignFormData, setCampaignFormData] = useState<CampaignFormData>({
    accountId: accounts ? accounts[0].id : "",
    name: campaignName,
    objective,
    status,
    special_ad_categories: [specialAdCategory],
    special_ad_category_country: countries,
    buying_type: buyingType,
    spend_cap: parseFloat(spendingLimit) * 100,
  });

  const {
    isLoading: isSearchLoading,
    refetch,
    isFetching: isSearchFetching,
  } = useQuery("getlocation", () => CampaignApi.getLocationOnSearch(accounts[0].id, countryName), {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const newOptions: Location[] = [];
      for (let i = 0; i < data.length; i += 1) {
        newOptions.push({
          value: data[i].country_code,
          label: data[i].name,
        });
      }
      setOptions(newOptions);
    },
    onError(error) {
      console.log(error);
    },
  });

  const { refetch: getCampaignFetch } = useQuery(
    "getCampaign",
    () => CampaignApi.getCampaignDetails(accounts[0].id, params.category),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        form.setFieldValue("campaignName", data.name);
        if (data.spend_cap !== undefined && data.spend_cap !== null) {
          form.setFieldValue("spendingLimit", (data.spend_cap / 100).toString());
          setspendingLimit((data.spend_cap / 100).toString());
          setShowBudget(true);
        } else {
          setShowBudget(false);
        }
        setObjective(data.objective);
        setStatus(data.status);
        setBuyingType(data.buying_type);
        setSpecialAdCategory(data.special_ad_categories[0]);
        const countryOptions = getCountryNames(data.special_ad_category_country);
        setCountries(data.special_ad_category_country);
        setOptions(countryOptions);
        setDummyOptions(countryOptions);
        setCampaignFormData({
          accountId: data.accountId,
          buying_type: data.buying_type,
          name: data.name,
          objective: data.objective,
          special_ad_categories: data.special_ad_categories,
          special_ad_category_country: data.special_ad_category_country,
          status: data.status,
          ...(data.spend_cap && {
            spend_cap: data.spend_cap,
          }),
        });
      },
      onError(error) {
        console.log(error);
      },
    },
  );

  const { mutate, isLoading } = useMutation(
    "campaign",
    (data: CampaignFormData) => {
      if (!specialAdCategory || !objective) {
        throw new Error("Bitte alle Felder ausfüllen");
      }
      if (showBudget && (parseFloat(spendingLimit) < 1 || spendingLimit === "")) {
        throw new Error("Ausgabenlimit für Kampagne hinzufügen darf nicht weniger als 1 betragen.");
      }
      if (showBudget) {
        if (params.category.length > 14) {
          return CampaignApi.updateCampaign(data, params.category);
        }
        return CampaignApi.createCampaign(data);
      }
      // eslint-disable-next-line
      const { spend_cap, ...formDataWithoutBudget } = data;
      if (params.category.length > 14) {
        return CampaignApi.updateCampaign(formDataWithoutBudget, params.category);
      }
      return CampaignApi.createCampaign(formDataWithoutBudget);
    },
    {
      onSuccess: (data) => {
        if (params.category.includes("-")) {
          message.success("Kampagne aktualisiert");
          push("/user/campaign/dashboard");
        } else {
          setCampaignId(data.id);
          message.success("Kampagne erstellt.");
          push(`/user/campaign/${params.category}/${data.id}/adset`);
        }
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (mounted.current) {
      timeout = setTimeout(() => {
        if (accounts) {
          refetch();
        }
      }, 1000);
    }
    mounted.current = true;

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryName]);

  useEffect(() => {
    if (localStorage.getItem("campaignId") && accounts[0].id) {
      const id: string = localStorage.getItem("campaignId") || "";
      setCampaignId(id);
      getCampaignFetch();
    }
    if (params.category.length > 14) {
      getCampaignFetch();
    }
    // eslint-disable-next-line
  }, []);

  const handleCategoryOptionChange = (value: SelectValue) => {
    if (typeof value === "string") {
      if (value === "NONE") {
        setCountries([]);
        setCampaignFormData((prevFormData: CampaignFormData) => ({
          ...prevFormData,
          special_ad_category_country: [],
        }));
      }
      setSpecialAdCategory(value);
      setCampaignFormData((prevFormData: CampaignFormData) => ({
        ...prevFormData,
        special_ad_categories: [value],
      }));
    }
  };
  const handleBookingOptionChange = (value: SelectValue) => {
    if (typeof value === "string") {
      setBuyingType(value);
    }
  };
  const handleGoalOptionChange = (value: SelectValue) => {
    const foundItem = goalOptions.find((item) => item.value === (value as string));
    if (typeof value === "string" && foundItem) {
      if (params.category.length < 14) {
        push(`/user/campaign/${foundItem.label.toLowerCase()}`);
      }
      setObjective(value);
      form.setFieldValue("campaignName", `Neue Kampagne für ${convertToProperCase(foundItem.label)}`);
    }
  };

  const handleChange = (value: string[]) => {
    const dummyItem = options.find((item) => item.value === value[value.length - 1]);
    if (dummyItem && !dummyOptions.includes(dummyItem)) {
      setDummyOptions((prevDummyOptions) => [...prevDummyOptions, dummyItem]);
    }
    let updatedCountry: string[] = [];
    let updatedCountryLabels: string[] = [];
    // eslint-disable-next-line
    value.map((option, index) => {
      const foundItem =
        index !== value.length - 1
          ? dummyOptions.find((item) => item.value === option)
          : options.find((item) => item.value === option);
      if (foundItem) {
        updatedCountry = [...updatedCountry, foundItem.value];
        updatedCountryLabels = [...updatedCountryLabels, foundItem.label];
      }
    });
    setCountries(updatedCountry);
    setCampaignFormData((prevFormData: CampaignFormData) => ({
      ...prevFormData,
      special_ad_category_country: updatedCountry,
    }));
  };

  useEffect(() => {
    form.validateFields();
  }, [showBudget]);

  return (
    <div style={{ color: "black" }}>
      <h2 style={{ fontWeight: "bold" }}>
        {params.category.length > 14 ? categoryType[objective] : params.category.toUpperCase()}
      </h2>
      <Form
        form={form}
        labelAlign="left"
        labelWrap
        colon={false}
        onFinish={() => {
          mutate(campaignFormData);
        }}
        labelCol={{
          lg: { offset: 0, span: 6 },
          md: { offset: 0, span: 24 },
          sm: { offset: 0, span: 24 },
          xs: { offset: 0, span: 24 },
        }}
        wrapperCol={{
          lg: { span: 18, offset: 1 },
          md: { span: 24, offset: 0 },
          sm: { span: 24, offset: 0 },
          xs: { span: 24, offset: 0 },
        }}
      >
        <Col lg={14} md={24} sm={24} xs={24}>
          <Form.Item
            label="Kampagnenname"
            name="campaignName"
            initialValue={campaignName}
            rules={[
              {
                required: true,
                message: "Bitte geben Sie den Kampagnennamen ein",
              },
            ]}
          >
            <Input
              data-testid="ads-campaign-name"
              className={classes.campaignInput}
              type="default"
              // value={campaignName}
              onChange={(e) => {
                // setCampaignName(e.target.value);
                setCampaignFormData((prevFormData: CampaignFormData) => ({
                  ...prevFormData,
                  name: e.target.value,
                }));
              }}
            />
          </Form.Item>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Spezielle Anzeigenkategorien</h2>
            <span style={{ marginTop: "10px" }}>
              Bitte gib an, ob deine Anzeigen im Zusammenhang mit Krediten, Jobs oder Wohnraum bzw. gesellschaftlichen
              Themen, Wahlen oder Politik stehen. Die Anforderungen für Anzeigen sind von Land zu Land unterschiedlich.
            </span>
          </div>
          <Form.Item label="Kategorien" style={{ marginTop: 25 }} initialValue={specialAdCategory}>
            <Select
              data-testid="ads-campaign-categories-dropdown"
              className={classes.categoryInput}
              value={specialAdCategory}
              onChange={handleCategoryOptionChange}
            >
              {categoryOptions.map((option, index) => (
                <Option value={option.value} data-testid={`ads-campaign-categories-options-${index}`}>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{option.label}</h4>
                    <p>{option.description}</p>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          {specialAdCategory === "NONE" ? (
            ""
          ) : (
            <Form.Item label="Länder" style={{ marginTop: 25 }} initialValue={countries}>
              <Select
                showSearch
                mode="multiple"
                size="middle"
                value={countries}
                placeholder="Anzeigenkategorien Länder"
                onChange={handleChange}
                onSearch={(e) => {
                  setCountryName(e);
                }}
                filterOption={(input, option) => {
                  const label = typeof option?.key === "string" ? option.key : "";
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                style={{ width: "100%" }}
                notFoundContent={null}
              >
                {isSearchLoading || isSearchFetching ? (
                  <span>searching...</span>
                ) : (
                  options.map((option, index) => (
                    <Option key={option.label} value={option.value}>
                      <div>
                        <h4>{option.label}</h4>
                      </div>
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          )}
          <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Kampagnendetails</h2>
          <Form.Item label="Buchungsart" style={{ marginTop: 25 }} initialValue={buyingType}>
            <Select
              data-testid="ads-campaign-bookingType-dropdown"
              className={classes.categoryInput}
              value={buyingType}
              onChange={handleBookingOptionChange}
            >
              {bookingOptions.map((option, index) => (
                <Option data-testid={`ads-campaign-bookingType-options-${index}`} value={option.value}>
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{option.label}</h4>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Kampagnenziel" style={{ marginTop: 25 }} initialValue={objective}>
            <Select
              data-testid="ads-campaign-goal-dropdown"
              className={classes.categoryInput}
              value={objective}
              onChange={handleGoalOptionChange}
            >
              {goalOptions.map((option, index) => (
                <Option
                  disabled={option.value !== objective}
                  // className={option.value !== objective ? classes.disabledOption : classes.enabledOption}
                  data-testid={`ads-campaign-goal-options-${index}`}
                  value={option.value}
                >
                  <div style={{ whiteSpace: "break-spaces" }}>
                    <h4>{option.label}</h4>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <h2 style={{ fontWeight: "bold", marginTop: 25 }}>Ausgabenlimit der Kampagne</h2>
          {(parseFloat(spendingLimit || "") < 1 || spendingLimit === "") && showBudget ? (
            <Alert message="Ausgabenlimit für Kampagne hinzufügen darf nicht weniger als 1 betragen" banner closable />
          ) : null}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Form.Item
              data-testid="ads-campaign-spending-limit-input"
              name="spendingLimit"
              label={
                <>
                  <Checkbox
                    data-testid="ads-campaign-spending-limit-checkbox"
                    checked={showBudget}
                    onClick={() => setShowBudget(!showBudget)}
                    style={{ marginRight: 10 }}
                  />
                  Ausgabenlimit für Kampagne hinzufügen
                </>
              }
              rules={[
                {
                  required: showBudget,
                  message: "Bitte geben Sie den Ausgabenlimit für Kampagne hinzufügen ein",
                },
              ]}
              style={{ width: "100%" }}
              labelCol={{
                lg: { offset: 0, span: 6 },
                md: { offset: 1, span: 24 },
                sm: { offset: 1, span: 24 },
                xs: { offset: 1, span: 24 },
              }}
              wrapperCol={{
                lg: { span: 16, offset: 2 },
                md: { span: 24, offset: 0 },
                sm: { span: 24, offset: 0 },
                xs: { span: 24, offset: 0 },
              }}
              initialValue={spendingLimit}
            >
              <Input
                value={spendingLimit}
                className={classes.spendingInput}
                disabled={!showBudget}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const isValidInput = /^\d+(\.\d{0,2})?$/.test(inputValue);

                  if (isValidInput || inputValue === "") {
                    form.setFieldValue("spendingLimit", inputValue);
                    setspendingLimit(inputValue);
                    setCampaignFormData((prevFormData) => ({
                      ...prevFormData,
                      spend_cap: parseFloat(inputValue) * 100,
                    }));
                  }
                }}
                prefix="€"
                type="number"
              />
            </Form.Item>
          </div>
          <div style={{ position: "absolute", right: 0, marginTop: 10 }}>
            <Button
              type="default"
              data-testid="ads-campaign-back-btn"
              className={classes.createButton}
              onClick={() => {
                if (params.campaignId) {
                  push("/user/campaign/dashboard");
                } else {
                  push("/user/campaign/new");
                }
              }}
            >
              Zurück
            </Button>
            <Button
              data-testid="ads-campaign-weiter-btn"
              className={classes.furtherButton}
              loading={isLoading}
              type="primary"
              htmlType="submit"
            >
              Weiter
            </Button>
          </div>
        </Col>
      </Form>
    </div>
  );
}
