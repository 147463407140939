/* Creating an axios instance with the baseURL and timeout values from the .env file. */
import axios, { AxiosRequestConfig } from "axios";

const getConfig = () => {
  return axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    // timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '5000', 10), // env value is a string so we need to convert it to a number
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export default {
  async get<T>(url: string, params?: AxiosRequestConfig) {
    const request = getConfig();

    try {
      const response = await request.get<T>(url, params);
      return response;
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
  async post<T = any>(url: string, data?: unknown, params?: AxiosRequestConfig) {
    const request = getConfig();

    try {
      const response = await request.post<T>(url, data, params);
      return response;
    } catch (error: any) {
      if ([401, 403, 400, 409].includes(error.response.status) && error.response.data.message) {
        error.message = error.response.data.message;
        return Promise.reject(error);
      }

      error.message = "An error occured!";
      return Promise.reject(error);
    }
  },
  async put<T = any>(url: string, data?: unknown, params?: AxiosRequestConfig) {
    const request = getConfig();

    try {
      const response = await request.put<T>(url, data, params);
      return response;
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
  async patch<T = any>(url: string, data?: unknown, params?: AxiosRequestConfig) {
    const request = getConfig();

    try {
      const response = await request.patch<T>(url, data, params);
      return response;
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
  async delete<T = any>(url: string, params?: AxiosRequestConfig) {
    const request = getConfig();

    try {
      const response = await request.delete<T>(url, params);
      return response;
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
};
