import { useTranslation } from "react-i18next";
import { Tabs, TabsProps } from "antd";
import { useNavigate, useParams } from "@pankod/refine-react-router-v6";
import PageHeading from "components/PageHeading";
import UserManagement from "./UserManagement";
import CompanyManagement from "./CompanyManagement";

import classes from "./index.module.css";

const items: TabsProps["items"] = [
  {
    key: "users",
    label: "Alle Benutzer",
    children: <UserManagement />,
  },
  {
    key: "companies",
    label: "Alle Unternehmen",
    children: <CompanyManagement />,
  },
];

const AdminUsers = () => {
  const { t } = useTranslation(["common", "pages"]);

  const { tab } = useParams();

  const navigate = useNavigate();

  return (
    <div className={`container ${classes.page}`}>
      <PageHeading>
        <PageHeading.Title>{t("pages:adminUsers.title")}</PageHeading.Title>
        <PageHeading.Subtitle>{t("pages:adminUsers.subtitle")}</PageHeading.Subtitle>
      </PageHeading>
      <section>
        <Tabs
          items={items}
          activeKey={tab}
          onChange={(key) => navigate(`/admin/users/${key}`)}
          defaultActiveKey="users"
          destroyInactiveTabPane
        />
      </section>
    </div>
  );
};

export default AdminUsers;
