import { useQuery } from "react-query";
import { InsightApi } from "services/api";
import { InsightQueryKeys } from "services/query-keys";
import {
  FacebookMetrics,
  InsightData,
  InsightDatePreset,
  InsightResponse,
  InstagramMetrics,
  MetricPeriod,
  UseCustomQueryOptions,
} from "types";

export type TGetInsightsParams = {
  accountId: string;
  companyId: string;
  date_preset?: InsightDatePreset;
  period: MetricPeriod;
  since?: Date | string;
  until?: Date | string;
  facebook_metrics: FacebookMetrics[];
  instagram_metrics: InstagramMetrics[];
};

export type MonthlyInsightData = Omit<InsightData, "day">;

export const groupInsightsByMonth = (data: InsightResponse) => {
  const clonedData = structuredClone(data) as {
    facebook: { [k in FacebookMetrics]?: { data: MonthlyInsightData[]; total: number } };
    instagram: { [k in InstagramMetrics]?: { data: MonthlyInsightData[]; total: number } };
    newLikes: number;
    newFollowers: number;
    pplReached: number;
    fbReach: number;
    igReach: number;
    paidReach: number;
  };
  for (const [key, value] of Object.entries(data.facebook)) {
    const monthlyData = [] as MonthlyInsightData[];
    for (const item of value) {
      let currMonth = monthlyData.find((m) => m.month === item.month && m.year === item.year);
      if (!currMonth) {
        currMonth = {
          month: item.month,
          year: item.year,
          value: item.value,
        };
        monthlyData.push(currMonth);
      } else {
        currMonth.value += item.value;
      }
    }
    let total = 0;

    if (key !== FacebookMetrics.page_follows) {
      for (const item of monthlyData) {
        total += item.value;
      }
    } else {
      total = monthlyData.at(-1)?.value ?? 0;
    }

    clonedData.facebook[key as keyof typeof clonedData.facebook] = {
      data: monthlyData,
      total,
    };
  }
  for (const [key, value] of Object.entries(data.instagram)) {
    const monthlyData = [] as MonthlyInsightData[];
    let total = 0;
    for (const item of value) {
      total += item.value;
      let currMonth = monthlyData.find((m) => m.month === item.month && m.year === item.year);
      if (!currMonth) {
        currMonth = {
          month: item.month,
          year: item.year,
          value: item.value,
        };
        monthlyData.push(currMonth);
      } else {
        currMonth.value += item.value;
      }
    }
    clonedData.instagram[key as keyof typeof clonedData.instagram] = {
      data: monthlyData,
      total,
    };
  }
  const { facebook, instagram } = clonedData;

  clonedData.newLikes = (facebook.page_fan_adds?.total ?? 0) + (instagram.impressions?.total ?? 0);
  clonedData.newFollowers = (facebook.page_follows?.total ?? 0) + (instagram.follower_count?.total ?? 0);
  clonedData.pplReached = (facebook.page_impressions_unique?.total ?? 0) + (instagram.reach?.total ?? 0);
  clonedData.fbReach = facebook.page_impressions_unique?.total ?? 0;
  clonedData.igReach = instagram.reach?.total ?? 0;
  clonedData.paidReach = facebook.page_impressions_paid_unique?.total ?? 0;
  return clonedData;
};

export type GroupedInsights = ReturnType<typeof groupInsightsByMonth>;

const useInsights = <TSelectData = InsightResponse>(
  filters: TGetInsightsParams,
  options?: UseCustomQueryOptions<InsightResponse, TSelectData>,
) => {
  return useQuery({
    queryKey: InsightQueryKeys.getInsights(filters),
    queryFn: async () => {
      return InsightApi.getInsights(filters);
    },
    ...options,
    //Cache for 5 minutes
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: (options?.enabled ?? true) && !!filters.accountId && !!filters.companyId,
  });
};

export default useInsights;
