import { useEffect, useState } from "react";
import {
  Row,
  Button,
  DatePicker,
  Table,
  TableProps,
  message,
  Switch,
  Badge,
  Tooltip,
  Typography,
  Spin,
  Dropdown,
} from "antd";
import { Account, ValueOptionData } from "types";
import { CopyOutlined, DeleteOutlined, FormOutlined, PlusCircleOutlined, RocketOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { useRouterContext } from "@pankod/refine-core";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useQueryCacheData from "hooks/useQueryCacheData";
import { CampaignApi } from "services/api";
import MeatBallIcon from "assets/meatball.svg";
import createFloatButton from "assets/CreateFloatButton.svg";
import locale from "antd/es/date-picker/locale/de_DE";
import DeleteModal from "pages/Planner/components/DeleteModal";
import ExistingModal from "../../Modals/ExistingModal";
import classes from "../CampaignDashboard/CampaignDashboard.module.css";
import CloneModal from "../../Modals/CloneModal";
import "dayjs/locale/de";

dayjs.locale("de_DE");
const localeTable = {
  emptyText: (
    <span>
      <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="emptyImage" />
      <p style={{ color: "#000" }}>Keine Daten vorhanden</p>
    </span>
  ),
};

const { Text } = Typography;

enum Status {
  Aktiv = "Aktiv",
  Inaktiv = "Inaktiv",
}
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const categoryType: { [key: string]: string } = {
  OUTCOME_AWARENESS: "Bekanntheit",
  OUTCOME_TRAFFIC: "Traffic",
  OUTCOME_ENGAGEMENT: "Interaktionen",
  OUTCOME_LEADS: "Leads",
  OUTCOME_SALES: "Umsatz",
};

type DataType = {
  id: string;
  campaignName: string;
  status: string;
  bidStrategy: string;
  budget: string;
  results: string;
  lastMinuteChange: string;
};

export default function AdsDashboard() {
  const { push } = useRouterContext().useHistory();
  const [startDate, setStartDate] = useState(dayjs().date(1).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [tableData, setTableData] = useState<DataType[] | []>([]);
  const [campaignId, setCampaignId] = useState<string>("");
  const [adsetId, setAdsetId] = useState<string>("");
  const [adCreativeId, setAdCreativeId] = useState<string>("");
  const accounts = useQueryCacheData<Account[]>("accounts");
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [campaignList, setCampaignList] = useState<ValueOptionData[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedSwitch, setSelectedSwitch] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const { mutate, isLoading: isStatusUpdating } = useMutation(
    "ad-update",
    (status: "ACTIVE" | "PAUSED") => {
      const body = {
        accountId: accounts[0].id,
        status,
      };
      return CampaignApi.updateAdStatus(body, selectedSwitch || "");
    },
    {
      onSuccess: () => {
        message.success("Status aktualisiert");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const handleSwitchChange = (id: string) => {
    const dataIndex = tableData.findIndex((item) => item.id === id);

    if (dataIndex !== -1) {
      const updatedTableData = [...tableData];

      updatedTableData[dataIndex].status = updatedTableData[dataIndex].status === "ACTIVE" ? "PAUSED" : "ACTIVE";
      mutate(updatedTableData[dataIndex].status === "ACTIVE" ? "ACTIVE" : "PAUSED");
      setTableData(updatedTableData);
    }
  };

  const columns: TableProps<DataType>["columns"] = [
    // {
    //     title: <Checkbox disabled />,
    //     dataIndex: 'id',
    //     key: 'radio',
    //     render: (id: string) => (
    //         <Checkbox
    //             onChange={() => handleCheckboxChange(id)}
    //             checked={selectedCheckbox === id}
    //         />
    //     ),
    // },
    {
      title: "Aus/Ein",
      dataIndex: "id",
      key: "toggle",
      width: 70,
      render: (status: string, record: DataType) => (
        <Switch
          style={{ width: 50 }}
          className={classes.DashboardSwitch}
          checked={record.status === "ACTIVE"}
          onChange={() => {
            if (selectedSwitch !== record.id) {
              setSelectedSwitch(record.id);
            } else {
              handleSwitchChange(record.id);
            }
          }}
        />
      ),
    },
    {
      title: "Kampagnenname",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status: string) =>
        status === "ACTIVE" ? <Badge status="success" text="Aktiv" /> : <Badge status="error" text="Deaktiv" />,
    },
    {
      title: "Gebotsstrategie",
      dataIndex: "bidStrategy",
      key: "bidStrategy",
      render: (bidStrategy: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {bidStrategy ? <Text>{bidStrategy}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (budget: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {budget ? <Text>{budget}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Ergebnisse",
      dataIndex: "results",
      key: "results",
      render: (results: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {results ? <Text>{results}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
    {
      title: "Letzte bedeutete Änd.",
      dataIndex: "lastMinuteChange",
      key: "lastMinuteChange",
      render: (lastMinuteChange: string) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {lastMinuteChange ? <Text>{lastMinuteChange}</Text> : <Text style={{ fontSize: 30 }}>-</Text>}
        </div>
      ),
    },
  ];

  const { refetch: getAdsetFetch } = useQuery("getAdset", () => CampaignApi.getAdsetDetails(accounts[0].id, adsetId), {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setCampaignId(data.campaignId);
    },
    onError(error) {
      console.log(error);
    },
  });

  const { refetch } = useQuery(
    "ads-list",
    () => {
      return CampaignApi.getAdsList(
        accounts[0].id,
        startDate,
        dayjs(endDate).add(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      );
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const eventPost = data.ads.map((event) => ({
          id: event.id,
          campaignName: event.name,
          campaignId: event.campaignId,
          status: event.status,
          bidStrategy: "",
          budget: "",
          results: "",
          lastMinuteChange: "",
        }));
        setTableData(eventPost);
      },
    },
  );

  const { refetch: getAdDataFetch } = useQuery(
    "getIds",
    () => {
      return CampaignApi.getAdData(accounts[0].id, selectedRows[0] || "");
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAdsetId(data.adsetId);
        setAdCreativeId(data.creativeId);
      },
    },
  );

  const { refetch: isCampaignRefetch } = useQuery(
    "campaign-list",
    () => {
      return CampaignApi.getCampaignList(
        accounts[0].id,
        startDate,
        dayjs(endDate).add(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      );
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const campaign = data.campaigns.map((event) => ({
          label: event.name,
          value: event.id,
          description: `${event.status}•${event.id}•${categoryType[event.objective]}`,
          type: categoryType[event.objective],
        }));
        setCampaignList(campaign);
      },
    },
  );

  useEffect(() => {
    refetch();
    isCampaignRefetch();
    getAdDataFetch();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    getAdsetFetch();
    // eslint-disable-next-line
  }, [adsetId]);

  useEffect(() => {
    if (selectedRows.length === 1) {
      getAdDataFetch();
    }
    // eslint-disable-next-line
  }, [selectedRows]);

  useEffect(() => {
    handleSwitchChange(selectedSwitch || "");
    // eslint-disable-next-line
  }, [selectedSwitch]);

  const handleDateChange = (values: any, formatString: [string, string]) => {
    if (values && values.length === 2) {
      const startDateString = dayjs(formatString[0], "DD.MM.YYYY").format("YYYY-MM-DD");
      const endDateString = dayjs(formatString[1], "DD.MM.YYYY").format("YYYY-MM-DD");
      setStartDate(startDateString);
      setEndDate(endDateString);
    }
  };

  const handleExistingModal = () => {
    setShowExistingModal(!showExistingModal);
  };

  const handleCloneModal = () => {
    setShowCloneModal(!showCloneModal);
  };

  const handleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
    isCampaignRefetch();
  };

  return (
    <div>
      <div className={classes.DashboardContainer}>
        <Row className={classes.topButton}>
          <Tooltip color="#000" placement="bottom" title="Funktionalität nicht verfügbar">
            <Button type="primary" disabled className={classes.publishButton}>
              <RocketOutlined className={classes.iconSize} />
              <span className={classes.buttonTitle}>Veröffentlichen</span>
            </Button>
          </Tooltip>
        </Row>
        {showExistingModal && (
          <ExistingModal
            visible={showExistingModal}
            handleCloseModal={handleExistingModal}
            campaignList={campaignList}
          />
        )}
        <CloneModal
          visible={showCloneModal}
          handleCloseModal={handleCloneModal}
          campaignList={campaignList}
          adId={selectedRows[0]}
        />
        {deleteModalVisible && (
          <DeleteModal
            visible={deleteModalVisible}
            handleCloseModal={handleDeleteModal}
            currentText="Ad"
            currentIds={selectedRows}
            setSelectedCheckbox={setSelectedRows}
          />
        )}
        <Row className={classes.CampaignFilters} justify="space-between">
          <Row style={{ gap: 5, marginBottom: 5 }}>
            <Button
              className={classes.createButton}
              type="primary"
              onClick={() => {
                setShowExistingModal(true);
              }}
            >
              <PlusCircleOutlined className={classes.iconSize} />
              Erstellen
            </Button>{" "}
            <Button
              className={classes.editButton}
              disabled={selectedRows.length === 0 || selectedRows.length > 1}
              onClick={() => handleCloneModal()}
              style={{ width: 200 }}
            >
              <CopyOutlined
                className={
                  selectedRows.length === 0 || selectedRows.length > 1 ? classes.disabledIconsize : classes.iconSize
                }
              />
              Duplizieren
            </Button>
            <Button
              className={classes.editButton}
              disabled={selectedRows.length === 0 || selectedRows.length > 1}
              onClick={() => {
                push(`/user/campaign/${campaignId}/adset/${adsetId}/ad/${selectedRows[0]}`);
              }}
              style={{ width: 200 }}
            >
              <FormOutlined
                className={
                  selectedRows.length === 0 || selectedRows.length > 1 ? classes.disabledIconsize : classes.iconSize
                }
              />
              Bearbeiten
            </Button>
            <Button className={classes.deleteButton} disabled={selectedRows.length === 0} onClick={handleDeleteModal}>
              <DeleteOutlined className={selectedRows.length === 0 ? classes.disabledIconsize : classes.iconSize} />
              Löschen
            </Button>
          </Row>
          <RangePicker
            format="DD.MM.YYYY"
            className={classes.datePicker}
            locale={locale}
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
          />
        </Row>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "Duplicate",
                label: (
                  <Tooltip color="#000" placement="bottom" title="Funktionalität nicht verfügbar">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CopyOutlined className={classes.disabledIconsize} />
                      <span style={{ color: "#000", marginLeft: 5 }}>Duplizieren</span>
                    </div>
                  </Tooltip>
                ),
              },
              {
                key: "Edit",
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormOutlined
                      className={
                        selectedRows.length === 0 || selectedRows.length > 1
                          ? classes.disabledIconsize
                          : classes.iconSize
                      }
                    />
                    <span style={{ color: "#000", marginLeft: 5 }}>Bearbeiten</span>
                  </div>
                ),
                onClick: () => {
                  push(`/user/campaign/${campaignId}/adset/${adsetId}/ad/${selectedRows[0]}`);
                },
              },
              {
                key: "Delete",
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutlined
                      className={selectedRows.length === 0 ? classes.disabledIconsize : classes.iconSize}
                    />
                    <span style={{ color: "#000", marginLeft: 5 }}>Löschen</span>
                  </div>
                ),
                onClick: handleDeleteModal,
              },
            ],
          }}
        >
          <img src={MeatBallIcon} className={classes.meatBallIcon} alt="create button" />
        </Dropdown>
      </div>
      <Spin spinning={isStatusUpdating}>
        <Table
          locale={localeTable}
          className={classes.table}
          dataSource={tableData}
          columns={columns}
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            position: ["bottomCenter"],
          }}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys as string[]);
            },
          }}
        />
      </Spin>
      <div className={classes.CreateFloatButton}>
        <img
          onClick={() => {
            setShowExistingModal(true);
          }}
          src={createFloatButton}
        />
      </div>
    </div>
  );
}
