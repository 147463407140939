import { createContext, useState } from "react";

type AuthContextType = {
  currAccountId: string;
  setCurrAccountId: React.Dispatch<React.SetStateAction<string>>;
  currCompanyId: string;
  setCurrCompanyId: React.Dispatch<React.SetStateAction<string>>;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [currAccountId, setCurrAccountId] = useState<string>("");
  const [currCompanyId, setCurrCompanyId] = useState<string>("");

  return (
    <AuthContext.Provider
      value={{
        currAccountId,
        setCurrAccountId,
        currCompanyId,
        setCurrCompanyId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
