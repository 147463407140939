import { message, Popconfirm } from "antd";
import { usePagination } from "hooks/usePagination";
import { convertUTCDateToLocal } from "utils/date";
import { useMemo, useState } from "react";
import useCompanies from "hooks/company/useCompanies";
import SearchBar from "components/SearchBar";
import useDeleteCompany from "hooks/company/useDeleteCompany";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import CustomTable from "components/CustomTable";
import IconButton from "components/IconButton/IconButton";
import { ReactComponent as DeleteIcon } from "../../../assets/DeleteIcon.svg";
import sharedClasses from "../shared.module.css";
import classes from "./index.module.css";

const CompanyManagement = () => {
  const paginationState = usePagination(10);

  const [searchText, setSearchText] = useState("");

  const { data, isLoading } = useCompanies({
    skip: paginationState.skip,
    take: paginationState.take,
    contains: searchText ? searchText : undefined,
    include: ["account"],
  });

  const allCompanies = data?.items;

  const { t } = useTranslation(["common", "pages"]);

  const companies = useMemo(
    () =>
      allCompanies?.map((company) => {
        const latestPost = company.posts?.at(-1);

        return {
          ...company,
          key: company.id,
          lastPost: latestPost?.currentVersion.postedAt
            ? convertUTCDateToLocal(latestPost.currentVersion.postedAt).format("DD.MM.YYYY HH.mm[h]")
            : undefined,
          formattedCreatedAt: convertUTCDateToLocal(company.createdAt).format("DD.MM.YYYY HH.mm[h]"),
        };
      }) ?? [],
    [allCompanies],
  );

  const { mutate: deleteCompany, isLoading: isDeletingCompany } = useDeleteCompany({
    onError: (e) => {
      if (e instanceof AxiosError) {
        const errMsg = e.response?.data?.message;

        if (!errMsg || typeof errMsg !== "string") return;
        message.error(errMsg);
      }
    },
    onSuccess: () => {
      message.success(t("pages:companies.deleteSuccessMessage"));
    },
  });

  return (
    <div>
      <div className={sharedClasses.tableSearch}>
        <SearchBar
          setText={(value) => {
            setSearchText(value);
          }}
          paginationState={paginationState}
        />
      </div>
      <CustomTable
        headerPrefix={<CustomTable.Title level={3}>{t("pages:adminUsers.companiesTableTitle")}</CustomTable.Title>}
        defaultProps={{
          dataSource: companies,
          loading: isLoading || isDeletingCompany,
          columns: [
            {
              title: t("pages:adminUsers.companyNameLabel"),
              render: (_, record) => <span>{record.name}</span>,
            },

            {
              title: t("pages:adminUsers.accountNameLabel"),
              render: (_, record) => <span>{record.account?.name ?? "Unknown Account"}</span>,
            },
            {
              title: t("pages:adminUsers.createdAtLabel"),
              render: (_, record) => <span>{record.formattedCreatedAt}</span>,
            },
            {
              title: t("pages:adminUsers.lastPostLabel"),
              render: (_, record) => <p>{record.lastPost}</p>,
            },
            {
              title: t("common:labels.actions"),
              render: (_, record) => (
                <div>
                  <Popconfirm
                    title={t("pages:adminUsers.deleteCompanyConfirmation")}
                    onConfirm={() => {
                      deleteCompany({ companyId: record.id, forceDelete: true });
                    }}
                    okButtonProps={{
                      className: classes.confirmDeleteCompanyButton,
                    }}
                    placement="top"
                  >
                    <IconButton alt="Delete company" svgColorProp="fill" disabled={isDeletingCompany}>
                      <DeleteIcon fill={undefined} />
                    </IconButton>
                  </Popconfirm>
                </div>
              ),
            },
          ],
        }}
        paginationProps={{ ...paginationState, total: data?.total ?? 0 }}
      />
    </div>
  );
};

export default CompanyManagement;
