import React, { createContext, useMemo } from "react";
import useMe from "hooks/useMe";
import { createPrismaAbility } from "@casl/prisma";
import { CaslPrismaAbility, UserAccessPolicyContextType } from "types/access";
import useSelectedAccount from "hooks/useSelectedAccount";
import { Navigate } from "@pankod/refine-react-router-v6";
import useAdmin from "hooks/useAdmin";

export const UserAccessPolicyContext = createContext<UserAccessPolicyContextType>({
  ability: createPrismaAbility<CaslPrismaAbility>(),
  isLoading: true,
});

export const UserAccessPolicyContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { selectedAccountId: accountId, isAdminOnlyRoute } = useSelectedAccount();
  const { data: me, isLoading } = useMe(accountId);

  const { isPlatformAdmin } = useAdmin();

  const ability = useMemo(() => {
    const freshAbility = createPrismaAbility<CaslPrismaAbility>();
    if (me) {
      freshAbility.update(JSON.parse(me.abilityRules));
    }
    return freshAbility;
  }, [me]);

  if (isAdminOnlyRoute && !isLoading && !isPlatformAdmin) {
    return <Navigate to="/account/-/company/-/select-account" replace />;
  }

  return <UserAccessPolicyContext.Provider value={{ ability, isLoading }}>{children}</UserAccessPolicyContext.Provider>;
};
