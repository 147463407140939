import { UserAccessPolicy, UserAccessPolicyType } from "types";

export const isCompanyAllowedInPolicy = (policyType: UserAccessPolicyType): boolean => {
  return ![
    UserAccessPolicyType.MANAGE_ALL,
    UserAccessPolicyType.MANAGE_ACCOUNT,
    UserAccessPolicyType.VIEW_ACCOUNT,
  ].includes(policyType);
};

export const isChannelAllowedInPolicy = (policyType: UserAccessPolicyType): boolean => {
  return ![
    UserAccessPolicyType.MANAGE_ALL,
    UserAccessPolicyType.MANAGE_ACCOUNT,
    UserAccessPolicyType.VIEW_ACCOUNT,
    UserAccessPolicyType.MANAGE_USER,
    UserAccessPolicyType.VIEW_USER,
    UserAccessPolicyType.MANAGE_TODO,
    UserAccessPolicyType.VIEW_TODO,
    UserAccessPolicyType.MANAGE_COMPANY,
    UserAccessPolicyType.VIEW_COMPANY,
  ].includes(policyType);
};

export const isPolicyTypeValid = (policyType: UserAccessPolicyType): policyType is UserAccessPolicyType => {
  return Object.values(UserAccessPolicyType).includes(policyType);
};

export const isAccessPolicyValid = (
  policy: UserAccessPolicy,
): { success: true; data: UserAccessPolicy } | { success: false; errors: { field: string; errMessage: string }[] } => {
  let success = true;
  const errors: { field: string; errMessage: string }[] = [];
  if (!policy.accountId) {
    success = false;
    errors.push({
      field: "accountId",
      errMessage: "Bitte wähle ein gültiges Konto aus.", // Please select a valid account.
    });
  }

  if (!policy.userId) {
    success = false;
    errors.push({
      field: "userId",
      errMessage: "Bitte wähle einen gültigen Benutzer aus.", // Please select a valid user.
    });
  }

  if (!policy.policyType || !isPolicyTypeValid(policy.policyType)) {
    success = false;
    errors.push({
      field: "policyType",
      errMessage: "Bitte wähle eine gültige Richtlinie aus.", // Please select a valid policy
    });
  }

  const { policyType, companyId = null, channelId = null } = policy;

  if (policyType && !isCompanyAllowedInPolicy(policyType) && companyId) {
    success = false;
    errors.push({
      field: "companyId",
      errMessage: "Diese Richtlinie unterstützt keinen Zugang auf Unternehmensebene.", // This policy does not support company level access
    });
  }

  if (policyType && !isChannelAllowedInPolicy(policyType) && channelId) {
    success = false;
    errors.push({
      field: "channelId",
      errMessage: "Diese Richtlinie unterstützt keinen Zugang auf Kanalebene.", // This policy does not support channel level access
    });
  }

  return success ? { success, data: policy } : { success, errors };
};
