import useAdmin from "hooks/useAdmin";
import { useQuery, UseQueryOptions } from "react-query";
import { PaymentApi } from "services/api";
import { PaymentQueryKeys } from "services/query-keys";

export type TListInvoicesParams = { startDate?: Date | string; endDate?: Date | string };

type ListInvoicesResult = Awaited<ReturnType<typeof PaymentApi.listCheckoutInvoices>>;

const usePaymentInvoices = (filters?: TListInvoicesParams, options?: UseQueryOptions<ListInvoicesResult>) => {
  const { isPlatformAdmin } = useAdmin();

  return useQuery({
    queryKey: PaymentQueryKeys.listInvoices(filters),
    queryFn: async () => {
      return PaymentApi.listCheckoutInvoices(filters);
    },
    ...options,
    //Cache for 1 minute
    initialData: options?.initialData ?? [],
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: isPlatformAdmin && (options?.enabled ?? true),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
  });
};

export default usePaymentInvoices;
