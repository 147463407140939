import { message, Popconfirm, Spin, Tooltip } from "antd";
import { CheckOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { NotificationData, NotificationStatusType, NotificationType } from "types";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { convertUTCDateToLocal } from "utils/date";
import { useContext, useMemo } from "react";
import useSelectedAccount from "hooks/useSelectedAccount";
import { UserAccessPolicyContext } from "context/UserAccessContext";
import { makeSubject } from "utils/access";
import { PostApi, PostReviewCommentApi } from "services/api";
import { CaslPrismaAbility } from "types/access";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import ReadMessage from "../../../assets/ReadMessage.svg";
import UnreadMessage from "../../../assets/UnreadMessage.svg";
import DeleteIcon from "../../../assets/DeleteIcon.svg";
import classes from "./NotificationModalItem.module.css";

type NotificationItemProps = {
  toggleSelection: (state: boolean, id: string) => void;
  notification: NotificationData;
  isSelected: boolean;
  updateStatusFn: (status: NotificationStatusType, id: string, hideSuccessMessage?: boolean) => void;
  closeModal: () => void;
  notifcationsBeingUpdated?: string[];
};

export const NotificationListItem = (props: NotificationItemProps) => {
  const { toggleSelection, notification, updateStatusFn, isSelected, closeModal, notifcationsBeingUpdated } = props;

  const { ability } = useContext(UserAccessPolicyContext);
  const { pathPrefix } = useSelectedAccount();
  const navigate = useNavigate();

  const { mutateAsync: getRedirectUrl, isLoading: isLoadingRedirectUrl } = useMutation({
    mutationFn: async ({
      notification,
      ability,
      pathPrefix,
    }: {
      notification: NotificationData;
      ability: CaslPrismaAbility;
      pathPrefix: string;
    }) => {
      let suffix;
      if (notification.type === NotificationType.USER_CREATED) {
        suffix = "/settings/admin";
      } else if (notification.type === NotificationType.ACCOUNT_BILLING_DUE) {
        suffix = "/settings/general";
      } else if (
        notification.type === NotificationType.POST_FAILED ||
        notification.type === NotificationType.POST_SCHEDULED ||
        notification.type === NotificationType.POST_PUBLISHED
      ) {
        suffix = `/planer?postVersionId=${notification.sourceId}`;
      } else if (
        notification.type === NotificationType.POST_APPROVED ||
        notification.type === NotificationType.POST_REJECTED
      ) {
        if (!notification.sourceId) return;

        const postVersion = await PostApi.versionDetails({
          versionId: notification.sourceId ?? "",
        });

        if (
          postVersion.post.requiresReview &&
          ability.can(
            "review",
            makeSubject("PostVersion", {
              accountId: postVersion.post.accountId,
              companyId: postVersion.post.companyId,
              channelId: postVersion.post.channelId,
            }),
          )
        ) {
          suffix = `/approval-center/${notification.type === NotificationType.POST_APPROVED ? "approved" : "rejected"}?postVersionId=${notification.sourceId}`;
        } else {
          suffix = `/planer?postVersionId=${notification.sourceId}`;
        }
      } else if (notification.type === NotificationType.POST_REVIEW_COMMENT) {
        if (!notification.sourceId) return;
        const comment = await PostReviewCommentApi.details({
          commentId: notification.sourceId ?? "",
          include: ["post"],
        });

        if (!comment.post) return;

        if (
          comment.post.requiresReview &&
          ability.can(
            "review",

            makeSubject("PostVersion", {
              accountId: comment.post.accountId,
              companyId: comment.post.companyId,
              channelId: comment.post.channelId,
            }),
          )
        ) {
          suffix = `/approval-center/pending?postVersionId=${comment.postVersionId}`;
        } else {
          suffix = `/planer?postVersionId=${comment.postVersionId}`;
        }
      }

      if (!suffix) return;

      return `${pathPrefix}${suffix}`;
    },
    onSuccess: (data, { notification }) => {
      //Early return if no redirect url was generated or if the notification is read
      if (!data || isRead) return;

      updateStatusFn(NotificationStatusType.READ, notification.id, true);
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        message.error(error.response?.data?.message ?? error.message);
      } else {
        console.error(error);
      }
    },
  });

  const isRead = useMemo(
    () =>
      notification.userStatuses.length > 0 &&
      notification.userStatuses.every((n) => n.status === NotificationStatusType.READ),
    [notification.userStatuses],
  );

  const spinning = useMemo(
    () => notifcationsBeingUpdated?.includes(notification.id) ?? false,
    [notifcationsBeingUpdated, notification.id],
  );

  return (
    <Spin spinning={spinning}>
      <div
        className={classes.notificationCard}
        key={notification.id}
        style={{ cursor: "pointer", ...(isSelected && { background: "#ccc" }) }}
        onClick={async (e) => {
          if (isLoadingRedirectUrl) return;
          // Hacky fix to prevent clicking on the action buttons from closing the modal
          if (
            e.target instanceof HTMLElement &&
            (e.target.classList.contains(classes.notificationActionButton) ||
              e.target.classList.contains(classes.notificationActionIcon) ||
              e.target.classList.contains("ant-popover"))
          )
            return;

          const redirectUrl = await getRedirectUrl({
            notification,
            ability,
            pathPrefix,
          }).catch((error) => {
            if (error instanceof AxiosError) {
              message.error(error.response?.data?.message ?? error.message);
            } else {
              console.error(error);
            }
          });
          if (!redirectUrl) return;
          navigate(redirectUrl);
          closeModal();
        }}
        data-read={isRead}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleSelection(!isSelected, notification.id);
          }}
          className={classes.notificationCardImageContainer}
          data-selected={isSelected}
        >
          <CheckOutlined className={classes.notificationCheckmarkicon} data-selected={isSelected} />
          <img src={notification.imageUrl} style={{ borderRadius: "5px" }} data-selected={isSelected} />
        </div>
        <div className={classes.notificationCardBody}>
          <h5 className={classes.notificationMessage}>{notification.message}</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <h6 style={{ marginBottom: "0", flexBasis: "50%" }} className={classes.notificationCreatedAt}>
              {convertUTCDateToLocal(notification.createdAt).format("DD.MM.YYYY HH.mm[h]")}
            </h6>
            <div style={{ flexBasis: "50%", display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
              {isRead ? (
                <Tooltip placement="top" title="Als ungelesen markieren" arrow={false}>
                  <button
                    type="button"
                    className={classes.notificationActionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStatusFn(NotificationStatusType.UNREAD, notification.id);
                    }}
                  >
                    <img src={UnreadMessage} className={classes.notificationActionIcon} />
                    <p className="sr-only">Als ungelesen markieren</p>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Als gelesen markieren" arrow={false}>
                  <button
                    type="button"
                    className={classes.notificationActionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStatusFn(NotificationStatusType.READ, notification.id);
                    }}
                  >
                    <img src={ReadMessage} className={classes.notificationActionIcon} />
                    <p className="sr-only">Als gelesen markieren</p>
                  </button>
                </Tooltip>
              )}
              <Popconfirm
                title="Lösche die Benachrichtigung"
                description="Bist du sicher, dass du diese Benachrichtigung löschen möchtest?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                okText="Ja"
                cancelText="Nein"
                okButtonProps={{ type: "primary", danger: true, className: classes.confirmDeleteNotificationButton }}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  updateStatusFn(NotificationStatusType.DELETE, notification.id);
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                className={classes.popover}
                overlayClassName={classes.deleteNotificationConfirmationOverlay}
              >
                <Tooltip placement="top" title="Löschen" arrow={false}>
                  <button type="button" className={`${classes.notificationActionButton}`}>
                    <img src={DeleteIcon} className={classes.notificationActionIcon} />
                    <p className="sr-only">Benachrichtigung löschen</p>
                  </button>
                </Tooltip>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
