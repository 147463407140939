import { useQuery, UseQueryOptions } from "react-query";
import { UserApi } from "services/api";
import { AuthQueryKeys } from "services/query-keys";

type GetMeResult = Awaited<ReturnType<typeof UserApi.getMe>>;

const useMe = (accountId?: string, options?: UseQueryOptions<GetMeResult>) => {
  return useQuery({
    ...options,
    queryKey: AuthQueryKeys.me(accountId),
    queryFn: () => UserApi.getMe(accountId),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
    staleTime: options?.staleTime ?? Infinity,
  });
};

export default useMe;
