import { useEffect, useState } from "react";
import { Input, Select, Button, Radio, Form, Space, Tooltip } from "antd";
import { SelectValue } from "antd/es/select";
import { PhoneOutlined, LinkOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import useQueryCacheData from "hooks/useQueryCacheData";
import type { RadioChangeEvent } from "antd";
import { OptionData, Account, GOALS, CONVERSIONS } from "types";
import { CampaignApi } from "services/api";
import { ConversionProps } from "types/props";
import LeadsModal from "../LeadsModal";
import classes from "./CallToAction.module.css";

const RadioGroup = Radio.Group;

const countryCodes: { [key: string]: string } = {
  "Albanien (+355)": "+355",
  "Andorra (+376)": "+376",
  "Österreich (+43)": "+43",
  "Weißrussland (+375)": "+375",
  "Belgien (+32)": "+32",
  "Bosnien und Herzegowina (+387)": "+387",
  "Bulgarien (+359)": "+359",
  "Kroatien (+385)": "+385",
  "Zypern (+357)": "+357",
  "Tschechien (+420)": "+420",
  "Dänemark (+45)": "+45",
  "Estland (+372)": "+372",
  "Finnland (+358)": "+358",
  "Frankreich (+33)": "+33",
  "Deutschland (+49)": "+49",
  "Griechenland (+30)": "+30",
  "Ungarn (+36)": "+36",
  "Indien (+91)": "+91",
  "Island (+354)": "+354",
  "Irland (+353)": "+353",
  "Italien (+39)": "+39",
  "Kosovo (+383)": "+383",
  "Lettland (+371)": "+371",
  "Liechtenstein (+423)": "+423",
  "Litauen (+370)": "+370",
  "Luxemburg (+352)": "+352",
  "Malta (+356)": "+356",
  "Moldawien (+373)": "+373",
  "Monaco (+377)": "+377",
  "Montenegro (+382)": "+382",
  "Niederlande (+31)": "+31",
  "Nordmazedonien (+389)": "+389",
  "Norwegen (+47)": "+47",
  "Polen (+48)": "+48",
  "Portugal (+351)": "+351",
  "Rumänien (+40)": "+40",
  "Russland (+7)": "+7",
  "San Marino (+378)": "+378",
  "Serbien (+381)": "+381",
  "Slowakei (+421)": "+421",
  "Slowenien (+386)": "+386",
  "Spanien (+34)": "+34",
  "Schweden (+46)": "+46",
  "Schweiz (+41)": "+41",
  "Ukraine (+380)": "+380",
  "Vereinigte Staaten von Amerika (+1)": "+1",
  "Vereinigtes Königreich (+44)": "+44",
  "Vatikanstadt (+379)": "+379",
};

export default function CallToAction({
  form,
  carouselUpload,
  category,
  currentConversion,
  leadFormId,
  setLeadFormId,
  welcomeMessage,
  setWelcomeMessage,
  countryCode,
  setCountryCode,
}: ConversionProps) {
  const [templateOption, setTemplateOption] = useState<SelectValue | undefined>(undefined);
  const [showLeadsModal, setShowLeadsModal] = useState(false);
  const [enableWebsiteCta, setEnableWebsiteCta] = useState(true);
  const [enableCallCta, setEnableCallCta] = useState(false);
  const [leadsForm, setLeadsForm] = useState<{ id: string; name: string }[]>([]);
  const templateOptions: OptionData[] = [{ label: "Vorlage 120323 (Textvorschau von Speicherung)" }];
  const accounts = useQueryCacheData<Account[]>("accounts");

  const { refetch: fetchLeads } = useQuery("getLeads", () => CampaignApi.getLeadsFormList(accounts[0].id), {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setLeadsForm(data);
    },
    onError(error) {
      console.log(error);
    },
  });

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === "none") {
      setLeadFormId("");
    } else {
      setLeadFormId(e.target.value);
    }
  };

  const handleCountryCodeChange = (code: string) => {
    setCountryCode(code);
  };

  const handleLeadsModal = () => {
    setShowLeadsModal(!showLeadsModal);
  };

  useEffect(() => {
    if (category === GOALS.LEADS) {
      fetchLeads();
    }
    // eslint-disable-next-line
  }, [leadFormId]);

  useEffect(() => {
    if (enableCallCta) {
      form.setFieldValue("link", "");
      form.setFieldValue("linkCaption", "");
    } else {
      form.setFieldValue("telefon", "");
      setCountryCode("+49");
    }
    // eslint-disable-next-line
  }, [enableCallCta, enableWebsiteCta]);

  return (
    <div>
      {showLeadsModal && (
        <LeadsModal setLeadFormId={setLeadFormId} visible={showLeadsModal} handleCloseModal={handleLeadsModal} />
      )}
      {(currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED) && (
        <>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Zielort</h2>
            <span style={{ marginTop: "10px" }}>
              Wähle die Medien, Texte und das Ziel für deine Anzeige aus. Du kannst deine Medien und Texte auch für jede
              Platzierung anpassen.
            </span>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Radio
              style={{ display: "block" }}
              checked={enableWebsiteCta}
              onClick={() => {
                setEnableCallCta(!enableCallCta);
                setEnableWebsiteCta(!enableWebsiteCta);
              }}
            >
              <LinkOutlined style={{ marginRight: 8 }} />
            </Radio>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                Website
                <br />
                Zu deiner Website weiterleiten
              </span>
              {enableWebsiteCta && (
                <>
                  <Form.Item
                    name="link"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Website-URL
                        <Tooltip
                          trigger="hover"
                          className={classes.TooltipText}
                          placement="right"
                          style={{
                            color: "#000 !important",
                            background: "#fff",
                          }}
                          title="Geben Sie die URL der zu besuchenden Website ein"
                        >
                          <InfoCircleOutlined
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Bitte geben Sie den Webadresse ein",
                      },
                      {
                        validator(rule, value, callback) {
                          if (value && !/^https?:\/\//i.test(value)) {
                            callback(
                              "Die von Ihnen eingegebene URL führt nicht zu einer Website. Bitte geben Sie eine gültige URL ein",
                            );
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      className={classes.textInput}
                      placeholder="http://www.example.com/page"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue("link", e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="linkCaption"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Angezeigter Link"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (value && !/^https?:\/\//i.test(value)) {
                            callback(
                              "Der eingegebene Display-Link ist ungültig. Bitte überprüfe deinen Display-Link und versuche es noch einmal",
                            );
                          } else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Geben den Link ein, wie er in deiner Werbeanzeige erscheinen"
                      className={classes.textInput}
                      onChange={(e) => {
                        form.setFieldValue("linkCaption", e.target.value);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
          {carouselUpload ||
          ((category === GOALS.LEADS || category === GOALS.SALES || category === GOALS.INTERACTIONS) &&
            (currentConversion === CONVERSIONS.WEBSITE || currentConversion === CONVERSIONS.UNDEFINED)) ? null : (
            <div
              style={{
                marginTop: 25,
                display: "flex",
              }}
            >
              <Radio
                style={{ display: "block" }}
                checked={enableCallCta}
                onClick={() => {
                  setEnableCallCta(!enableCallCta);
                  setEnableWebsiteCta(!enableWebsiteCta);
                }}
              >
                <PhoneOutlined style={{ marginRight: 8 }} />
              </Radio>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  Anruf
                  <br />
                  Ermögliche Nutzern, dich direkt anzurufen.
                </span>
                {enableCallCta && (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 10,
                        width: "50%",
                      }}
                    >
                      <Form.Item label="Land" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Select value={countryCode} onChange={handleCountryCodeChange}>
                          {Object.entries(countryCodes).map(([country, code]) => (
                            <option key={code} value={code}>
                              {country}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Form.Item
                        name="telefon"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Telefonnummer"
                        rules={[
                          {
                            validator(rule, value, callback) {
                              if (value) {
                                const phoneNumber = value.replace(/[\s\D]/g, "");
                                if (/^\d{6,}$/.test(phoneNumber)) {
                                  callback();
                                } else {
                                  callback("Gib eine gültige Telefonnummer an");
                                }
                              } else {
                                callback();
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Gib eine Telefonnummer"
                          className={classes.phoneInput}
                          onChange={(e) => {
                            form.setFieldValue("telefon", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {currentConversion === CONVERSIONS.PHONE_CALL && (
        <div
          style={{
            marginTop: 25,
            display: "flex",
          }}
        >
          <Radio style={{ display: "block" }} checked>
            <PhoneOutlined style={{ fontSize: 24, margin: "0 10px" }} />
          </Radio>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              Anruf
              <br />
              Ermögliche Nutzern, dich direkt anzurufen.
            </span>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 10,
                  width: "50%",
                }}
              >
                <Form.Item label="Land" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select value={countryCode} onChange={handleCountryCodeChange}>
                    {Object.entries(countryCodes).map(([country, code]) => (
                      <option key={code} value={code}>
                        {country}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Form.Item
                  name="telefon"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Telefonnummer"
                  rules={[
                    {
                      validator(rule, value, callback) {
                        if (value) {
                          const phoneNumber = value.replace(/[\s\D]/g, "");
                          if (/^\d{6,}$/.test(phoneNumber)) {
                            callback();
                          } else {
                            callback("Gib eine gültige Telefonnummer an");
                          }
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Gib eine Telefonnummer"
                    className={classes.phoneInput}
                    onChange={(e) => {
                      form.setFieldValue("telefon", e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentConversion === CONVERSIONS.MESSENGER && (
        <>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Nachrichtenvorlage</h2>
            <span style={{ marginTop: 10, color: "black" }}>
              Ermutige Personen dazu, im Messenger eine Unterhaltung mit deinem Unternehmen zu starten, nachdem sie auf
              deine Werbeanzeige klicken. Wir zeigen deine Werbung den richtigen Personen, um dir dabei zu helfen, dein
              Optimierungsziel zu erreichen.
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "self-start",
              marginTop: 25,
            }}
          >
            <Radio style={{ display: "block" }} checked>
              Unterhaltungen beginnen Füge Aufforderungen hinzu, auf die Interessenten tippen können, um eine
              Unterhaltung mit deinem Unternehmen zu beginnen.
            </Radio>
          </div>
          <div style={{ marginTop: 25 }}>
            <Input
              className={classes.textInput}
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
              // suffix={<UserAddOutlined />}
              // addonAfter={<UserAddOutlined />}
            />
          </div>
        </>
      )}
      {(currentConversion === CONVERSIONS.LEAD_FORM_MESSENGER || currentConversion === CONVERSIONS.ON_AD) && (
        <>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>
              {currentConversion === CONVERSIONS.ON_AD ? "Instant-Formulare" : "Instant-Formulare und Messenger"}
            </h2>
            <span style={{ marginTop: "10px" }}>
              Wenn Nutzer auf deine Anzeige klicken, leiten wir sie zu einem Formular oder automatisierten Chat im
              Messenger, je nachdem, wo sie am ehesten interagieren.
            </span>
            <br />
            <span>
              Erstelle neue Fragen oder verwende ein bestehendes Formular, das mit dem Messenger kompatibel ist.
            </span>
            <br />
            <Button className={classes.leadsButton} type="default" onClick={() => setShowLeadsModal(true)}>
              Neues Formular erstellen
            </Button>
          </div>
          <br />
          <div>
            <span>Verwende eine bestehende Vorlage, die du bereits erstellt hast.</span>
            <div
              style={{
                background: "rgb(220, 217, 217)",
                padding: 10,
                marginTop: 5,
              }}
            >
              <RadioGroup onChange={onChange}>
                <Space direction="vertical">
                  {leadsForm.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.name}
                    </Radio>
                  ))}
                  <Radio key={leadsForm.length + 1} value="none">
                    None
                  </Radio>
                </Space>
              </RadioGroup>
            </div>
          </div>
        </>
      )}
      {currentConversion === CONVERSIONS.ON_VIDEO && (
        <>
          <div style={{ marginTop: 25 }}>
            <h2 style={{ fontWeight: "bold" }}>Zielort</h2>
            <span style={{ marginTop: "10px" }}>
              Wähle die Medien, Texte und das Ziel für deine Anzeige aus. Du kannst deine Medien und Texte auch für jede
              Platzierung anpassen.
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Radio style={{ display: "block" }} checked>
              <LinkOutlined style={{ marginRight: 8 }} />
            </Radio>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                Website
                <br />
                Zu deiner Website weiterleiten
              </span>
              <Form.Item
                name="link"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Website-URL
                    <Tooltip
                      trigger="hover"
                      className={classes.TooltipText}
                      placement="right"
                      style={{
                        color: "#000 !important",
                        background: "#fff",
                      }}
                      title="Geben Sie die URL der zu besuchenden Website ein"
                    >
                      <InfoCircleOutlined
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Bitte geben Sie den Webadresse ein",
                  },
                  {
                    validator(rule, value, callback) {
                      if (value && !/^https?:\/\//i.test(value)) {
                        callback(
                          "Die von Ihnen eingegebene URL führt nicht zu einer Website. Bitte geben Sie eine gültige URL ein",
                        );
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input
                  className={classes.textInput}
                  placeholder="http://www.example.com/page"
                  onChange={(e) => {
                    form.setFieldValue("link", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="linkCaption"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Angezeigter Link"
                rules={[
                  {
                    validator(rule, value, callback) {
                      if (value && !/^https?:\/\//i.test(value)) {
                        callback(
                          "Der eingegebene Display-Link ist ungültig. Bitte überprüfe deinen Display-Link und versuche es noch einmal",
                        );
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Geben den Link ein, wie er in deiner Werbeanzeige erscheinen"
                  className={classes.textInput}
                  onChange={(e) => {
                    form.setFieldValue("linkCaption", e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
