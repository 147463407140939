import { useQuery, UseQueryOptions } from "react-query";
import { AccountApi } from "services/api";
import { AccountQueryKeys } from "services/query-keys";

export type TGetAccountUsersParams = {
  accountId: string;
  companyId?: string;
};

type ListInvoicesResult = Awaited<ReturnType<typeof AccountApi.getUsers>>;

const useAccountUsers = (filters: TGetAccountUsersParams, options?: UseQueryOptions<ListInvoicesResult>) => {
  return useQuery({
    queryKey: AccountQueryKeys.getUsers(filters),
    queryFn: async () => {
      return AccountApi.getUsers(filters);
    },
    ...options,
    //Cache for 1 minute
    initialData: options?.initialData ?? [],
    staleTime: options?.staleTime ?? 300_000,
    initialDataUpdatedAt: options?.initialDataUpdatedAt ?? 0,
    enabled: (options?.enabled ?? true) && !!filters.accountId,
  });
};

export default useAccountUsers;
